import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  AiAnalysis,
  CloudSystem,
  ContinuosDevelopment,
  OfflineOperations,
  PhoneBusiness,
} from "../../assets/images";
import useTabs from "../../hooks/useTabs";
import {
  ChartPieSlice,
  CloudArrowUp,
  CloudSlash,
  DeviceMobile,
  TrendUp,
} from "../common/Icons";

const SecureCloudSystem = () => (
  <TabContentWrapper
    title="Secure Cloud System"
    description="Say goodbye to bulky devices, maintenance hassles, and data backup fees. Leave it all for the TagDev team; we store your data in the cloud to keep it safe and available, allowing you access anywhere, anytime, and from any device."
    imageSrc={CloudSystem}
  />
);

const ContinuosOperations = () => (
  <TabContentWrapper
    title="Continuous Operations Even When Offline"
    description="In the offline mode from TagDev, your business continues even when the internet goes down. Never worry and keep receiving your customers' orders, these orders will be synced automatically when the POS (Point of Sale) goes online again."
    imageSrc={OfflineOperations}
  />
);

const AiDataAnalysis = () => (
  <TabContentWrapper
    title="AI Data Analysis"
    description="The best business decisions come from accurate reporting and sound data analysis. TagDev provides you with AI important performance reports that enable you to track your sales and get valuable information that helps you in making informed decisions."
    imageSrc={AiAnalysis}
  />
);

const ContinuosDevelopments = () => (
  <TabContentWrapper
    title="Continuous Development"
    description="Nothing stops our constant desire to develop and improve. We guarantee you the continuous updating of your system. We listen to our customers and develop our system in line with their needs and aspirations."
    imageSrc={ContinuosDevelopment}
  />
);

const ViaPhone = () => (
  <TabContentWrapper
    title="Manage Business Via Phone"
    description="Easily track sales, manage inventory, report daily and add items—all from your phone."
    imageSrc={PhoneBusiness}
  />
);

const tabs = [
  {
    title: "Secured Cloud System",
    content: SecureCloudSystem,
    icon: CloudArrowUp,
  },
  {
    title: "Continuous Operation Offline",
    content: ContinuosOperations,
    icon: CloudSlash,
  },
  { title: "AI Data Analysis", content: AiDataAnalysis, icon: ChartPieSlice },
  {
    title: "Continuous Development",
    content: ContinuosDevelopments,
    icon: TrendUp,
  },
  { title: "Manage via Phone", content: ViaPhone, icon: DeviceMobile },
];

const TabsSection = () => {
  const { TabHeader, TabContent } = useTabs(0, tabs);
  const texts = [
    "Optimizing Operation ...",
    "Growth ...",
    "POS Technology ...",
    "Simplify Sales ...",
    "Manage Inventory ...",
  ];

  return (
    <div className="py-16 1xl:px-29 px-4">
      <h2 className="text-text-primary font-semibold xs:text-5xl text-3xl xs:leading-wider text-center tab-section-heading">
        Tag Dev Your Personal
        <br /> Assistant To <br />
        <AnimatedText texts={texts} />
      </h2>
      <div className="w-fit mx-auto md:mt-14 mt-10">
        <TabHeader padding="py-3 xl:px-14 px-6" showHeading={false} />
        <TabContent />
      </div>
    </div>
  );
};

export default TabsSection;

const TabContentWrapper = ({ title, description, imageSrc }) => (
  <div className="grid lg:grid-cols-2 grid-cols-1 mt-8 gap-5">
    <div className="flex flex-col justify-between">
      <div className="space-y-2 max-w-lg pt-8">
        <h3 className="text-text-primary font-semibold text-2x8">{title}</h3>
        <p className="text-text-secondary text-base">{description}</p>
      </div>
    </div>
    <div className="xl:min-h-72">
      <img
        src={imageSrc}
        alt={title}
        className="xs:h-auto h-64 mx-auto"
        loading="lazy"
      />
    </div>
  </div>
);

const AnimatedText = ({ texts }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const currentText = texts[index % texts.length];
    if (displayedText.length < currentText.length) {
      const timeoutId = setTimeout(
        () => setDisplayedText(currentText.slice(0, displayedText.length + 1)),
        100
      );
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setDisplayedText("");
        setIndex((index + 1) % texts.length);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [displayedText, index, texts]);

  useEffect(() => {
    const blinkInterval = setInterval(() => setBlink((prev) => !prev), 500);
    return () => clearInterval(blinkInterval);
  }, []);

  return (
    <span className="inline-block">
      <motion.span
        className="gradient-text"
        animate={{ opacity: [0, 1], transition: { duration: 0.5 } }}
      >
        {displayedText}
      </motion.span>
      <motion.span style={{ visibility: blink ? "visible" : "hidden" }}>
        |
      </motion.span>
    </span>
  );
};
