import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { PdfIcon, Plus } from "../../../../../common/Icons/fontawesome";
import {
  CustomButton,
  InputGroup,
  Loader,
  SelectGroup,
} from "../../../../../common/components";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import {
  addPurchaseInvoice,
  getAllPurchaseInvoices,
  getSinglePurchaseInvoice,
  updatePurchaseInvoice,
} from "../../../../../../features/Invoices/PurchaseInvoices/PurchaseInvoiceSlice";
import AddNewSupplier from "./AddNewSupplier";
import { getAllItems } from "../../../../../../features/Items/ItemsSlice";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../../../common/utils/showToast.util";
import { CrossIcon, FileIcon } from "../../../../../common/Icons";
import { uploadSingleFile } from "../../../../../../features/UploadFile/UploadFileSlice";

// Validation schema for add/edit sale invoice form
const addInvoiceSchema = z.object({
  supplierid: z.coerce.number().int().min(1, {
    message: "client_dashboard.invoices.purchase.validations.supplierid_error",
  }),
  store: z.coerce.number().int().min(1, {
    message: "client_dashboard.invoices.purchase.validations.store_error",
  }),
  date: z
    .string()
    .min(1, "client_dashboard.invoices.purchase.validations.date_error"),
  paymentmethod: z
    .string()
    .min(
      1,
      "client_dashboard.invoices.purchase.validations.paymentmethod_error"
    ),
  paymentStatus: z
    .string()
    .min(
      1,
      "client_dashboard.invoices.purchase.validations.paymentStatus_error"
    ),
  items: z.array(
    z.object({
      itemid: z.coerce
        .number()

        .min(1, "client_dashboard.invoices.purchase.validations.item_error"),
      quantity: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.invoices.purchase.validations.only_numbers_error",
        })
        .min(1, "client_dashboard.invoices.purchase.validations.quantity_error")
        .max(1000000, {
          message:
            "client_dashboard.invoices.purchase.validations.quantity_error_max",
        })
        .default(1),
      unitprice: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.invoices.purchase.validations.unitprice_error"
        ),

      beforetax: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.invoices.purchase.validations.beforetax_error"
        ),
      taxamount: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.invoices.purchase.validations.taxamount_error"
        ),
      subtotal: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.invoices.purchase.validations.subtotal_error"
        ),
      note: z
        .string()
        .min(1, "client_dashboard.invoices.purchase.validations.note_error"),
    })
  ),
});

const AddInvoice = forwardRef(
  (
    {
      purchaseInvoice = null,
      itemList,
      storesList,
      suppliersList,
      setIsFormSubmitting,
      itemListLoading,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const modalState = useSelector((state) => state.modal);
    const selectedItemRef = useRef(null);
    const invoiceId = purchaseInvoice?.id;

    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);

    const [filePreview, setFilePreview] = useState(
      purchaseInvoice &&
        purchaseInvoice?.attachment?.name && {
          type: purchaseInvoice?.attachment?.url?.split(".")?.pop(),
          image: purchaseInvoice?.attachment?.url,
          name: purchaseInvoice?.attachment?.name,
          size: purchaseInvoice?.attachment?.size,
        }
    );
    const [imageUploadError, setImageUploadError] = useState("");
    const [isNewSupplier, setIsNewSupplier] = useState(false);

    const dispatch = useDispatch();

    const defaultValues = useMemo(
      () => ({
        date: purchaseInvoice?.supplyDate ?? "",
        paymentmethod: purchaseInvoice?.paymentMethod ?? "",
        paymentStatus: purchaseInvoice?.paymentStatus ?? "",
        supplierid: purchaseInvoice?.suppliers?.id ?? "",
        store: purchaseInvoice?.store?.id ?? "",
        "items.0.itemid":
          purchaseInvoice?.purchaseInvoiceItem?.variableItem?.id ||
          purchaseInvoice?.purchaseInvoiceItem?.item?.id,
        "items.0.quantity": purchaseInvoice?.purchaseInvoiceItem?.quantity ?? 1,
        "items.0.numberunit":
          purchaseInvoice?.purchaseInvoiceItem?.quantity ?? 1,
        "items.0.beforetax":
          purchaseInvoice?.purchaseInvoiceItem?.beforeTax ?? "",
        "items.0.unitprice":
          purchaseInvoice?.purchaseInvoiceItem?.unitPrice ?? "",
        "items.0.taxamount":
          purchaseInvoice?.purchaseInvoiceItem?.taxAmount ?? "",
        "items.0.subtotal":
          purchaseInvoice?.purchaseInvoiceItem?.subTotal ?? "",
        "items.0.note": purchaseInvoice?.purchaseInvoiceItem?.note ?? "",
      }),
      [purchaseInvoice]
    );

    // Form handling using react-hook-form
    const {
      register,
      handleSubmit,
      control,
      setValue,
      watch,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange",
      resolver: zodResolver(addInvoiceSchema),
      defaultValues: {
        items: [{}],
      },
    });

    useEffect(() => {
      // Set the default values for each field to edit supplier using 'setValue' method of react-hook-form
      if (purchaseInvoice) {
        defaultValues &&
          Object.keys(defaultValues).forEach((key) =>
            setValue(key, defaultValues[key])
          );
      }
    }, [purchaseInvoice, setValue, defaultValues, itemList]);

    useEffect(() => {
      setIsFormSubmitting(!isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const addItem = () => {
      append({});
    };

    const { fields, append } = useFieldArray({
      control: control,
      name: "items",
    });

    let selectedItem = watch("items");

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          name: "Dummy",
          supplyDate: data?.date,
          paymentMethod: data?.paymentmethod,
          paymentStatus: data?.paymentStatus,
          attachment: {
            name: uploadFileData?.uploadSingleFile?.data?.name,
            url: uploadFileData?.uploadSingleFile?.data?.url,
            size: uploadFileData?.uploadSingleFile?.data?.size,
          },
          storeId: +data?.store,
          supplierId: +data?.supplierid,
          invoiceItem: {
            quantity:
              selectedItemRef?.current?.type === "RAW"
                ? +selectedItem[0]?.numberunit
                : +data?.items[0]?.quantity,
            unitPrice: +data?.items[0]?.unitprice,
            beforeTax: +data?.items[0]?.beforetax,
            taxAmount: +data?.items[0]?.taxamount,
            subTotal: +data?.items[0]?.subtotal,
            note: data?.items[0]?.note,
            itemId:
              selectedItemRef?.current?.type === "VARIABLE"
                ? selectedItemRef?.current?.id
                : data?.items[0]?.itemid,
            vItemId: data?.items[0]?.itemid,
          },
        };

        if (modalState?.type === "edit") {
          return dispatch(
            updatePurchaseInvoice({
              invoiceId: purchaseInvoice?.id,
              payload: payload,
              successCallBack: onPurchaseInvoiceUpdate,
            })
          );
        } else {
          return dispatch(
            addPurchaseInvoice({
              payload,
              successCallBack: onPurchaseInvoiceUpdate,
            })
          );
        }
      }),
    }));

    const onPurchaseInvoiceUpdate = () => {
      if (modalState?.type === "duplicate") {
        showToast("Invoice duplicated successfully!");
      }
      if (modalState?.type === "add") {
        showToast("Invoice created successfully!");
      }
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      const payloadItems = {
        page: 1,
        pageSize: 1000,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };

      dispatch(getAllItems({ payload: payloadItems }));
      dispatch(getAllPurchaseInvoices({ payload }));
      if (invoiceId) {
        dispatch(getSinglePurchaseInvoice({ invoiceId: invoiceId }));
      }
      dispatch(closeModal());
    };

    const selectedStore = watch("store");

    const storeId = selectedStore;

    useEffect(() => {
      const payload = {
        page: 1,
        pageSize: 1000,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {
          stores: { id: storeId },
        },
        attributes: {},
      };
      if (storeId) dispatch(getAllItems({ payload }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeId]);

    // const isVariableItem = itemList?.find(item => )

    useEffect(() => {
      selectedItem.map((s, i) => {
        const curItem = itemList.find(
          (r) => (r?.vItemId || r.id) === +s.itemid
        );

        if (!curItem) return null;
        selectedItemRef.current = curItem;
        if (+s.itemid === +(curItem?.vItemId || curItem.id)) {
          setValue(`items.${[i]}.itemid`, curItem?.vItemId || curItem.id);
          setValue(`items.${[i]}.unitprice`, curItem.perUnitPrice);
          setValue("availableQuantity", curItem.quantity);
          setValue(`items.${[i]}.unitmeasure`, curItem?.measureOfUnit);
        }
        return null;
      });
      setValue(
        "items.[0].beforetax",
        selectedItemRef &&
          selectedItemRef?.current &&
          (
            (selectedItemRef?.current?.type === "RAW"
              ? +selectedItem[0].numberunit
              : +selectedItem[0].quantity) * +selectedItem[0].unitprice
          )?.toFixed(2)
      );
      setValue(
        "items.[0].taxamount",
        (selectedItem[0]?.beforetax * 0.15)?.toFixed(2)
      );
      setValue(
        "items.[0].subtotal",
        (+selectedItem[0]?.beforetax + +selectedItem[0]?.taxamount)?.toFixed(2)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedItem)]);

    const handleImageChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        // Check file type
        const validTypes = ["image/jpeg", "image/png", "application/pdf"]; // Include PDF type
        if (!validTypes.includes(file.type)) {
          setImageUploadError(
            "client_dashboard.management.settings.profile.validations.img_err_type"
          );
          return;
        }
        const formImgData = new FormData();

        // Update state and dispatch if all validations pass
        setImageUploadError(""); // Clear any existing error
        formImgData.append("file", file);
        if (file.type.startsWith("image")) {
          // If it's an image file, set file preview
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            setFilePreview(img);
          };
        }
        setFilePreview(file);
        dispatch(uploadSingleFile({ payload: formImgData }));
      } else {
        setImageUploadError("Uploading an image is mandatory.");
      }
    };

    return (
      <React.Fragment>
        {/* supplier Selection */}
        <SelectGroup
          labelText={t("client_dashboard.invoices.purchase.supplier")}
          htmlFor="supplierid"
          inputName="supplierid"
          options={[
            {
              value: "",
              label: t("client_dashboard.invoices.purchase.choose_supplier"),
            },
            ...suppliersList?.map((option) => ({
              value: +option?.id,
              label: option?.name,
            })),
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        {/* Display supplier Details */}
        {isNewSupplier && (
          <AddNewSupplier setIsNewSupplier={setIsNewSupplier} />
        )}
        {!isNewSupplier && (
          <CustomButton
            text={t("client_dashboard.invoices.purchase.add_new_supplier")}
            icon={<Plus color="text-blue-sky" />}
            width="w-fit"
            bgColor="bg-transparent"
            className="shadow-none"
            textColor="text-text-link"
            onClick={() => setIsNewSupplier(true)}
          />
        )}
        {/* Store Selection */}
        <SelectGroup
          labelText={t("client_dashboard.invoices.purchase.store")}
          htmlFor="store"
          inputName="store"
          options={[
            {
              value: "",
              label: t("client_dashboard.invoices.purchase.select_store"),
            },
            ...storesList?.map((option) => ({
              value: +option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />

        {/* Date */}
        <InputGroup
          labelText={t("client_dashboard.invoices.purchase.supply_date")}
          inputType="date"
          htmlFor="date"
          inputName="date"
          register={register}
          errors={errors}
        />

        {/* Payment Method */}
        <SelectGroup
          labelText={t("client_dashboard.invoices.purchase.payment_method")}
          htmlFor="paymentmethod"
          inputName="paymentmethod"
          options={paymentMethods}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        {/* Payment Status */}
        <SelectGroup
          labelText={t("client_dashboard.invoices.purchase.payment_status")}
          htmlFor="paymentStatus"
          inputName="paymentStatus"
          options={[
            {
              value: "",
              label: t(
                "client_dashboard.invoices.purchase.select_payment_status"
              ),
            },
            { label: "Fully Paid", value: "Fully Paid" },
            { label: "Partially Paid", value: "Partially Paid" },
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        {uploadFileData?.uploadSingleFile?.isLoading ? (
          <Loader />
        ) : filePreview ? (
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              {filePreview?.type === "application/pdf" ||
              filePreview?.type === "pdf" ? (
                <PdfIcon />
              ) : (
                <img
                  src={
                    filePreview?.image ||
                    uploadFileData?.uploadSingleFile?.data?.url
                  }
                  alt=""
                  className="w-24 h-16"
                />
              )}
              <div className="flex flex-col justify-between">
                <span>
                  {filePreview?.name ||
                    uploadFileData?.uploadSingleFile?.data?.name}
                </span>
                <span>
                  {filePreview?.size ||
                    uploadFileData?.uploadSingleFile?.data?.size}{" "}
                  KB
                </span>
              </div>
            </div>
            <span
              className="cursor-pointer"
              onClick={() => {
                setFilePreview(null);
              }}
            >
              <CrossIcon />
            </span>
          </div>
        ) : (
          <CustomButton
            text="Attach File"
            bgColor="bg-transparent"
            textColor="tex-text-primary"
            border="border"
            icon={<FileIcon />}
            className="relative"
          >
            <input
              type="file"
              className="absolute opacity-0 inset-0 cursor-pointer"
              onChange={handleImageChange}
            />
          </CustomButton>
        )}
        {imageUploadError && (
          <p className="text-red-500 text-sm">{t(imageUploadError)}</p>
        )}
        {/* attach file */}

        {/* Item Details */}
        <hr className="bg-border-primary" />
        <h6 className="text-text-primary font-semibold text-base">
          {t("client_dashboard.invoices.purchase.item_details")}
        </h6>
        {/* Display item fields */}
        {fields?.map((item, index) => (
          <div className="space-y-3" key={item.id}>
            <SelectGroup
              labelText={t("client_dashboard.invoices.purchase.item")}
              htmlFor={`itemid-${index}`}
              inputName={`items.${index}.itemid`}
              isLoading={itemListLoading}
              options={[
                {
                  value: "",
                  label: t("client_dashboard.invoices.sales.select_item"),
                },
                ...itemList?.map((option) => ({
                  value: option?.vItemId || option?.id,
                  label: option?.slug || option?.englishName,
                })),
              ]}
              register={register}
              errors={errors}
              className="rtl:bg-left"
            />
            {/* available quantity */}
            <InputGroup
              labelText={t(
                "client_dashboard.invoices.purchase.available_quantity"
              )}
              htmlFor={`available quantity`}
              inputName={`availableQuantity`}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            {/* Unit of Measure */}
            {selectedItemRef?.current?.type === "RAW" && (
              <>
                <InputGroup
                  labelText={t(
                    "client_dashboard.inventory.stocktaking.unitmeasure"
                  )}
                  htmlFor={`unitmeasure-${index}`}
                  inputName={`items.${index}.unitmeasure`}
                  register={register}
                  errors={errors}
                  className="pointer-events-none rtl:bg-left"
                  bgColor="!bg-surface-gray"
                  disabled={true}
                />
                <InputGroup
                  labelText={t(
                    "client_dashboard.inventory.inventory_transfer.number_unit"
                  )}
                  htmlFor={`numberunit-${index}`}
                  inputName={`items.${index}.numberunit`}
                  placeholder={t(
                    "client_dashboard.inventory.inventory_transfer.enter_number_unit"
                  )}
                  register={register}
                  errors={errors}
                />
              </>
            )}
            {/* quantity */}
            {selectedItemRef &&
              selectedItemRef?.current &&
              selectedItemRef?.current?.type !== "RAW" && (
                <InputGroup
                  labelText={t("client_dashboard.invoices.purchase.quantity")}
                  htmlFor={`quantity-${index}`}
                  inputName={`items.${index}.quantity`}
                  placeholder={t(
                    "client_dashboard.invoices.purchase.enter_quantity"
                  )}
                  register={register}
                  errors={errors}
                />
              )}
            {/* Unit price */}
            <InputGroup
              labelText={t("client_dashboard.invoices.purchase.unit_price")}
              htmlFor={`unitprice-${index}`}
              inputName={`items.${index}.unitprice`}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            <InputGroup
              labelText={t("client_dashboard.invoices.purchase.before_tax")}
              htmlFor={`beforetax-${index}`}
              inputName={`items.${index}.beforetax`}
              placeholder="Enter Before Tax Price"
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            <InputGroup
              labelText={t("client_dashboard.invoices.purchase.tax_amount")}
              htmlFor={`taxamount-${index}`}
              inputName={`items.${index}.taxamount`}
              placeholder="Enter Tax Amount"
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />

            {/* sub total */}
            <InputGroup
              htmlFor={`subtotal-${index}`}
              inputName={`items.${index}.subtotal`}
              labelText={t("client_dashboard.invoices.purchase.subtotal")}
              placeholder="Enter Subtotal"
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            {/* note */}
            <InputGroup
              htmlFor={`note-${index}`}
              inputName={`items.${index}.note`}
              labelText={t("client_dashboard.invoices.purchase.note")}
              placeholder={t("client_dashboard.invoices.purchase.enter_note")}
              register={register}
              errors={errors}
            />
          </div>
        ))}
        {/* Button to add a new item */}
        {!fields?.length > 0 && (
          <CustomButton
            text="Add Details"
            icon={<Plus color="text-blue-sky" />}
            width="w-fit"
            bgColor="bg-transparent"
            className="shadow-none"
            textColor="text-text-link"
            onClick={addItem}
          />
        )}
      </React.Fragment>
    );
  }
);

export default AddInvoice;

const paymentMethods = [
  {
    value: "",
    label: "client_dashboard.invoices.purchase.select_payment_method",
  },
  { label: "Cash", value: "Cash" },
  { label: "Mada", value: "Mada" },
  { label: "Visa", value: "Visa" },
  { label: "Bank transfer", value: "Bank transfer" },
];
