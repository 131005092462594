import { useNavigate } from "react-router-dom";
import { bussniessAccount } from "../../../../features/Auth/AuthSlice";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { getUser, setUser } from "../../../common/utils/users.utils";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../../context/UserContext";

export const useCreateAccountForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateUser, setUserProfile, setToken } = useUserContext();
  const [id, setId] = useState(""); // State to store the retrieved email

  // validation schema for business info
  const businessInfoSchema = z.object({
    companyname: z
      .string()
      .min(
        3,
        "client_dashboard.management.settings.business_info.validations.companyname_error"
      )
      .max(
        100,
        "client_dashboard.management.settings.business_info.validations.companyname_error_max"
      ),
    companyaddress: z
      .string()
      .min(
        3,
        "client_dashboard.management.settings.business_info.validations.companyaddress_error"
      )
      .max(
        100,
        "client_dashboard.management.settings.business_info.validations.companyaddress_error_max"
      ),
    website: z.string().optional(),
    commercialregister: z
      .string()
      .min(
        3,
        "client_dashboard.management.settings.business_info.validations.commercialregister_error"
      )
      .max(
        100,
        "client_dashboard.management.settings.business_info.validations.commercialregister_error_max"
      ),
    vatnumber: z
      .string()
      .length(
        15,
        "client_dashboard.management.settings.business_info.validations.vatnumber_error"
      ),
    businesstype: z
      .string()
      .min(
        1,
        "client_dashboard.management.settings.business_info.validations.businesstype_error"
      ),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(businessInfoSchema),
  });

  // Callback function to handle navigation
  const handleBack = () => {
    navigate("/complete-account");
  };

  useEffect(() => {
    const storedUserData = getUser("user");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      const userId = parsedUserData?.user?.id;
      if (userId) {
        setId(userId); // Set email in state
      }
    }
  }, []);
  /**
   * Handles changes in the file input field for image upload.
   * Sets the image preview and updates formData with the selected file.
   *
   * @param {object} event - The event object from the file input field.
   */

  /**
   * Handles form submission.
   * Creates FormData object with form data and navigates to the next page.
   *
   * @param {object} data - Form data collected from the form fields.
   */

  const onSubmit = (data) => {
    // Fetch user data from local storage

    // Construct the payload with the userId from local storage

    const payload = {
      companyName: data?.companyname,
      companyAddress: data?.companyaddress,
      website: data?.website,
      commercialRegister: data?.commercialregister,
      vatNumber: data?.vatnumber,
      typeOfBusiness: data?.businesstype,
      userId: id,
    };

    return dispatch(
      bussniessAccount({ payload, successCallBack: businessProfileRouter })
    );
  };

  // Callback function to handle navigation after successful account creation
  const businessProfileRouter = (data) => {
    if (data?.data?.Succeeded) {
      const userData = data.data.data;
      updateUser(userData);
      setUserProfile(userData?.profile);
      setToken(userData?.token);
      navigate("/set-plan");
    }
  };
  /**
   * Custom hook for managing the create business account form.
   *
   * @returns {Object} An object containing methods and states for form handling.
   * {
   *   onSubmit: Function - Handles form submission,
   *   handleBack: Function - Handles navigation to the previous step,
   *   handleSubmit: Function from react-hook-form - Handles form validation and submission,
   *   register: Function from react-hook-form - Registers form inputs for validation and usage,
   *   errors: Object - Holds form validation errors from react-hook-form,
   * }
   * Usage Example:
   * Accesses form handling methods:
   * const { onSubmit, handleBack, handleSubmit, register, errors } = useCreateAccountForm();
   *
   * onSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * handleBack:
   *  Example usage: <button onClick={handleBack}>Back</button>
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * register:
   *  Example usage: <input {...register("companyName", { required: true })} />
   * errors:
   *  Example usage: {errors.companyName && <span>Company name is required</span>}
   */
  return {
    onSubmit,
    handleBack,
    handleSubmit,
    register,
    errors,
    isSubmitting,
    isValid,
  };
};

export default useCreateAccountForm;
