/**
 * Manages state for uploading files in the Redux store.
 * Defines initial state and handles file upload using async thunk.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import uploadFileService from "./UploadFileService";
import { showToast } from "../../components/common/utils/showToast.util";

// Initial state for the upload file slice in the Redux store
const initialState = {
  uploadSingleFile: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Async thunk to handle uploading a single file.
 * Dispatches actions to update state based on the upload process.
 *
 * @param {object} payload - The file data to be uploaded.
 * @param {object} thunkAPI - Redux thunk API for dispatching actions.
 * @returns {Promise} A promise resolving to uploaded data or handling errors.
 */
export const uploadSingleFile = createAsyncThunk(
  "uploadSingleFile",
  async ({ payload }, thunkAPI) => {
    try {
      // Call the uploadSingleFile function from the uploadFileService
      const response = await uploadFileService.uploadSingleFile(payload);

      // Check if the upload succeeded
      if (response.Succeeded) {
        return response.data;
      } else {
        console.log(response);
        showToast(response?.message);
        return thunkAPI.rejectWithValue(response); // Reject and handle errors
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error }); // Reject and handle errors
    }
  }
);

/**
 * Creates a slice of the Redux store for uploading files.
 * Handles actions for different stages of file upload (pending, fulfilled, rejected).
 */
export const uploadFileSlice = createSlice({
  name: "uploadFile",
  initialState,
  reducers: {}, // No additional reducers defined here
  extraReducers: (builder) => {
    builder
      // Action cases for the uploadSingleFile async thunk
      .addCase(uploadSingleFile.pending, (state) => {
        // Set state while file upload is pending
        state.uploadSingleFile.isLoading = true;
        state.uploadSingleFile.message = "";
        state.uploadSingleFile.isError = false;
        state.uploadSingleFile.isSuccess = false;
        state.uploadSingleFile.data = null;
      })
      .addCase(uploadSingleFile.fulfilled, (state, action) => {
        // Set state when file upload is successful
        state.uploadSingleFile.isLoading = false;
        state.uploadSingleFile.isSuccess = true;
        state.uploadSingleFile.data = action.payload; // Update state with uploaded data
      })
      .addCase(uploadSingleFile.rejected, (state, action) => {
        // Set state when file upload is rejected or encounters an error
        state.uploadSingleFile.message = action.payload?.message;
        state.uploadSingleFile.isLoading = false;
        state.uploadSingleFile.isError = true;
        state.uploadSingleFile.data = null;
      });
  },
});

// Export the reducer from the slice to be used in the Redux store
export default uploadFileSlice.reducer;
