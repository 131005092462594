import Layout from "../Layout/Layout";
import Expense from "./Expense/Expense";
import NetIncome from "./NetIncome/NetIncome";
import SalesAndCost from "./SalesAndCost/SalesAndCost";
import SalesByCashier from "./SalesByCashier/SalesByCashier";
import SalesByCategories from "./SalesByCategories/SalesByCategories";
import SalesByItems from "./SalesByItems/SalesByItems";
import SalesByStores from "./SalesByStores/SalesByStores";

const Dashbaord = () => {
  return (
    <Layout>
      <div>
        <div className="flex justify-between items-center ">
          <h2 className="text-lg font-semibold text-text-primary">Overview</h2>
        </div>{" "}
        <div>
          <SalesAndCost />
        </div>
        <div className="flex flex-row gap-4 mt-4 w-full ">
          <div className="w-1/2 border rounded-lg p-3  overflow-auto">
            <NetIncome />
          </div>
          <div className="w-1/2  overflow-auto border rounded-lg p-3">
            <Expense />
          </div>{" "}
        </div>
        <div>
          <SalesByItems />
        </div>
        <div>
          <div className="flex flex-row gap-4 mt-4 w-full ">
            <div className="w-1/2 border rounded-lg p-3 overflow-auto">
              <SalesByCategories />
            </div>
            <div className="w-1/2  overflow-auto border rounded-lg p-3">
              <SalesByStores />
            </div>{" "}
          </div>
        </div>
        <div className="mt-4">
          <SalesByCashier />
        </div>
      </div>
    </Layout>
  );
};
export default Dashbaord;
