import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
  Card,
} from "../../../../../common/components";
import { CrossIcon } from "../../../../../common/Icons";
import { Plus } from "../../../../../common/Icons/fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { setVariableOptions } from "../../../../../../features/ItemsModal/ItemsModalSlice";
import { useTranslation } from "react-i18next";
/**
 * VariableItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the VariableItems component.
 */

const VariableItems = forwardRef(
  ({ storesList, categoryList, setIsSubmitting }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { variableOptions } = useSelector((state) => state.itemsModal);
    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);

    // validation schema for add/edit variable item form
    const variableItemSchema = z.object({
      englishname: z
        .string({
          required_error:
            "client_dashboard.directory.items.variable_item.validations.englishname_required_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.variable_item.validations.englishname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.variable_item.validations.englishname_error_max"
        ),
      arabicname: z
        .string({
          required_error:
            "client_dashboard.directory.items.variable_item.validations.arabicname_required_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.variable_item.validations.arabicname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.variable_item.validations.arabicname_error_max"
        ),
      description: z
        .string({
          required_error:
            "client_dashboard.directory.items.variable_item.validations.description_required_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.variable_item.validations.description_error_min"
        )
        .max(
          500,
          "client_dashboard.directory.items.variable_item.validations.description_error_max"
        ),
      store: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.variable_item.validations.store_required_error"
        ),
      category: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.variable_item.validations.category_required_error"
        ),
      variables: z.array(
        z.object({
          variablename: z
            .string()
            .min(
              1,
              "client_dashboard.directory.items.variable_item.validations.variablename_required_error"
            ),
          values: z.array(
            z.object({
              value: z
                .string()
                .min(
                  1,
                  "client_dashboard.directory.items.variable_item.validations.value_required_error"
                ),
            })
          ),
        })
      ),
    });

    // Form handling using react-hook-form
    /**
     * Object destructuring to manage form functionality with react-hook-form.
     * Provides functions and objects for form input validation and usage.
     *
     * @property {Function} register - Function to register form inputs for validation and usage.
     * @property {Function} handleSubmit - Function to handle form submission.
     * @property {Object} formState - Object holding form validation errors.
     * @property {Function} setValues - Function to set form input values.
     */
    const {
      register,
      handleSubmit,
      control,
      trigger,
      getValues,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange", // Setting the form mode to trigger validation on input change
      resolver: zodResolver(variableItemSchema),
      defaultValues: {
        englishname: variableOptions?.englishName ?? "",
        arabicname: variableOptions?.arabicName ?? "",
        description: variableOptions?.description ?? "",
        store: variableOptions?.storeId ?? "",
        itemcost: variableOptions?.itemCost ?? "",
        category: variableOptions?.categoryId ?? "",
        saleprice: variableOptions?.salePrice ?? "",
        variables:
          variableOptions?.variableItem?.length > 0
            ? variableOptions?.variableItem?.map((item) => ({
                variablename: item?.variablename,
                values: item?.values?.map((value) => ({
                  value: value?.value,
                })),
              }))
            : [
                {
                  variablename: "",
                  edit: true,
                  values: [
                    {
                      value: "",
                    },
                  ],
                },
              ],
      },
    });

    useEffect(() => {
      setIsSubmitting(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const {
      fields,
      append: appendVariable,
      remove: removeVariable,
      update,
    } = useFieldArray({
      control: control,
      name: "variables",
    });

    // function to add another variable field
    const addVariable = async () => {
      // Trigger validation for all fields in the form
      const isValid = await trigger("variables");

      // Check if the form is valid
      if (isValid) {
        // If the form is valid, append a new item to the form array
        appendVariable({
          variablename: "",
          edit: true,
          values: [
            {
              value: "",
            },
          ],
        });
      }
    };

    const handleDone = async (i) => {
      // Trigger validation for all fields in the form
      const isValid = await trigger("variables");

      // Check if the form is valid
      if (isValid) {
        // If the form is valid, append a new item to the form array
        update(i, {
          variablename: getValues().variables[i].variablename,
          edit: false,
          values: getValues().variables[i].values,
        });
      }
    };

    const handleEdit = (i) => {
      update(i, {
        edit: true,
        variablename: getValues().variables[i]?.variablename,
        values: getValues().variables[i].values,
      });
    };

    const { variables } = getValues();

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          type: "VARIABLE",
          image: uploadFileData?.uploadSingleFile?.data?.url,
          englishName: data?.englishname,
          arabicName: data?.arabicname,
          description: data?.description,
          storeId: +data?.store,
          categoryId: +data?.category,
          variableItem: variables,
        };
        dispatch(setVariableOptions(payload));
      }),
    }));

    const allVariableNames = fields?.map((field) => field?.variablename);

    return (
      <React.Fragment>
        <CustomFileUploader
          isLoading={uploadFileData?.uploadSingleFile?.isLoading}
        />
        {/* name */}
        <InputGroup
          labelText={"client_dashboard.directory.items.variable_item.item_name"}
          htmlFor="englishname"
          inputName="englishname"
          placeholder={
            "client_dashboard.directory.items.variable_item.enter_item_name"
          }
          register={register}
          errors={errors}
        />
        {/* arabic name */}
        <InputGroup
          labelText={
            "client_dashboard.directory.items.variable_item.arabic_name"
          }
          htmlFor="arabicname"
          inputName="arabicname"
          placeholder={
            "client_dashboard.directory.items.variable_item.enter_arabic_name"
          }
          register={register}
          errors={errors}
        />
        {/* description */}
        <InputGroup
          labelText={
            "client_dashboard.directory.items.variable_item.description"
          }
          htmlFor="description"
          inputName="description"
          placeholder={
            "client_dashboard.directory.items.variable_item.enter_description"
          }
          register={register}
          errors={errors}
        />
        {/* store */}
        <SelectGroup
          labelText={"client_dashboard.directory.items.variable_item.store"}
          htmlFor="store"
          inputName="store"
          className="rtl:bg-left"
          options={[
            {
              value: "",
              label:
                "client_dashboard.directory.items.variable_item.select_store",
            },
            ...storesList?.map((option) => ({
              value: option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
        />
        {/* category */}
        <SelectGroup
          labelText={"client_dashboard.directory.items.variable_item.category"}
          htmlFor="category"
          inputName="category"
          className="rtl:bg-left"
          options={[
            {
              value: "",
              label:
                "client_dashboard.directory.items.variable_item.select_category",
            },
            ...categoryList?.map((option) => ({
              value: option?.id,
              label: option?.englishName,
            })),
          ]}
          register={register}
          errors={errors}
        />

        {fields.map((field, i) => (
          <div key={field.variablename + i} className="space-y-3">
            {!field.edit ? (
              <Card className="flex flex-col space-y-3 px-4 py-3" key={i}>
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-lg">
                    {variables[i].variablename}
                  </span>
                  <CustomButton
                    text="buttons.edit"
                    width="w-fit"
                    bgColor="bg-trasnparent"
                    border="border"
                    textColor="text-text-primary"
                    icon
                    padding="py-2 px-3"
                    onClick={() => handleEdit(i)}
                  />
                </div>
                <div className="flex items-center gap-3">
                  {variables[i].values.map((value, valIndex) => (
                    <span
                      className="bg-surface-gray py-1 px-2 rounded-3xl text-xs font-medium to-text-secondary"
                      key={value + valIndex}
                    >
                      {value.value}
                    </span>
                  ))}
                </div>
              </Card>
            ) : (
              <div className="space-y-3">
                {" "}
                <div className="flex items-center justify-between">
                  <span className="space-y-3 font-semibold to-text-primary text-sm">
                    {t(
                      "client_dashboard.directory.items.variable_item.variable"
                    )}{" "}
                    #{i + 1}{" "}
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => removeVariable(i)}
                  >
                    <CrossIcon />
                  </span>
                </div>
                {/* variable name */}
                <SelectGroup
                  htmlFor={`variablename-${i}`}
                  inputName={`variables.${i}.variablename`}
                  className="rtl:bg-left"
                  labelText={
                    "client_dashboard.directory.items.variable_item.variable_name"
                  }
                  options={["Size", "Color", "Type", "Material"]
                    .filter(
                      (option) =>
                        !allVariableNames.includes(option) ||
                        option === field.variablename
                    )
                    .map((option) => ({ value: option, label: option }))}
                  placeholder={
                    "client_dashboard.directory.items.variable_item.select_variable_name"
                  }
                  register={register}
                  errors={errors}
                />
                <Card className="space-y-3">
                  {field.values?.map((val, valueIndex) => (
                    <React.Fragment
                      key={`variables.${i}.values.${valueIndex}.value`}
                    >
                      <div className="flex gap-3 items-center">
                        <InputGroup
                          labelText={`${t(
                            "client_dashboard.directory.items.variable_item.value"
                          )} #${valueIndex + 1}`}
                          htmlFor={`value-${valueIndex}`}
                          inputName={`variables.${i}.values.${valueIndex}.value`}
                          placeholder={
                            "client_dashboard.directory.items.variable_item.enter_value"
                          }
                          register={register}
                          errors={errors}
                          className="w-full"
                        />
                        {field?.values?.length > 1 && (
                          <span
                            className="block w-fit cursor-pointer ml-auto"
                            onClick={() => {
                              update(i, {
                                ...getValues().variables[i],
                                values: getValues().variables[i].values.filter(
                                  (_, id) => valueIndex !== id
                                ),
                              });
                            }}
                          >
                            <CrossIcon />
                          </span>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </Card>
                <div className="flex items-center gap-3">
                  <CustomButton
                    text={"buttons.done"}
                    width="w-fit"
                    bgColor="bg-trasnparent"
                    border="border"
                    textColor="text-text-primary"
                    icon
                    onClick={() => handleDone(i)}
                  />
                  <CustomButton
                    text={"buttons.add_value"}
                    textColor="text-white"
                    bgColor="bg-black-pearl"
                    width="w-fit"
                    onClick={() => {
                      update(i, {
                        ...getValues().variables[i],
                        values: getValues().variables[i].values.concat({
                          value: "",
                        }),
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        <CustomButton
          text={
            "client_dashboard.directory.items.variable_item.add_another_variable"
          }
          icon={<Plus color="text-blue-sky" />}
          width="w-fit"
          bgColor="bg-transparent"
          className="shadow-none"
          textColor="text-text-link"
          onClick={addVariable}
        />
      </React.Fragment>
    );
  }
);

export default VariableItems;

// sample usage
// import VariableItems from "./path/to/VariableItems.jsx";

// <VariableItems />
