import { useEffect, useState } from "react";

export const useDropdownPosition = (isOpen, triggerRef, dropdownRef) => {
  const [position, setPosition] = useState("bottom");

  useEffect(() => {
    const handlePosition = () => {
      if (triggerRef.current && dropdownRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - triggerRect.bottom;
        const spaceAbove = triggerRect.top;

        if (
          spaceBelow < dropdownRect.height &&
          spaceAbove > dropdownRect.height
        ) {
          setPosition("top");
        } else {
          setPosition("bottom");
        }
      }
    };

    if (isOpen) {
      handlePosition();
      window.addEventListener("resize", handlePosition);
      window.addEventListener("scroll", handlePosition);
    }

    return () => {
      window.removeEventListener("resize", handlePosition);
      window.removeEventListener("scroll", handlePosition);
    };
  }, [isOpen, triggerRef, dropdownRef]);

  return position;
};
