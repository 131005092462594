import React from "react";
import { ArrowDown, ArrowUp, CheckIcon } from "../../Icons";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";

const MultiSelecCheckbox = ({
  value,
  onChange,
  options = [],
  placeholder = "",
}) => {
  return (
    <Listbox
      value={value}
      onChange={(e) => {
        const newValue = value?.includes(e)
          ? value.filter((v) => v !== e) // Remove if already selected
          : [...value, e]; // Add if not already selected
        onChange(newValue); // Update the form value
      }}
    >
      {({ open }) => (
        <div className="relative min-w-36">
          <Listbox.Button className="ps-3 pe-2 py-2 border border-border-primary rounded-lg appearance-none w-full">
            <div className="flex items-center justify-between">
              <span>
                {value?.length > 0
                  ? `${value?.length}/${options?.length} Selected`
                  : `Select ${placeholder}`}
              </span>
              {open ? <ArrowUp /> : <ArrowDown />}
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto bg-white border border-gray-300 rounded max-h-60">
              {options?.map((option) => (
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  as={Fragment}
                >
                  {({ active, selected }) => (
                    <li
                      className={`flex items-center px-4 py-2 cursor-pointer select-none ${
                        active ? "bg-blue-500 text-white" : "text-gray-900"
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={value?.includes(option.value)}
                        onChange={() => {}}
                        className={`form-checkbox ${
                          selected
                            ? "text-blue-600"
                            : "text-text-gray !focus:outline-none focus:ring-0 ring-0 outline-none"
                        } rounded`}
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent Listbox onClick from firing
                          const newValue = value?.includes(option.value)
                            ? value.filter((v) => v !== option.value)
                            : [...value, option.value];
                          onChange(newValue);
                        }}
                      />
                      <span className={`ml-2 ${selected ? "font-medium" : ""}`}>
                        {option?.label}
                      </span>
                      {selected && (
                        <CheckIcon className="h-5 w-5 ml-auto text-blue-500" />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
export default MultiSelecCheckbox;
