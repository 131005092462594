import React from "react";
import { CustomButton } from "../common/components";
import { AppleStore, ArrowRight, GooglePlay, LogoGram } from "../common/Icons";
import { Facebook, Instagram, Twitter, Youtube } from "../../assets/images";
import { LanguageSwitcher } from "../common/components/LanguageSwitcher/LanguageSwitcher";
import { Link } from "react-router-dom";

const Footer = ({ className }) => {
  return (
    <footer
      className={`footer-landing-page pt-24 pb-12 xl:px-29 px-6 m-4 !rounded-4xl ${className}`}
    >
      <div className="flex flex-col text-center items-center">
        <LogoGram className="w-24 h-24" />
        <h3 className="text-white md:text-5xl text-3xl font-semibold mt-4">
          Grow Your Business With TagDev
        </h3>
        <p className="text-white font-medium text-base opacity-70 mt-4">
          Contact us to try the system no payment is required until i’ts trial
          period expires
        </p>
        <div className="flex items-center gap-3 w-fit mx-auto mt-4">
          <CustomButton text="Get Started" width="w-fit" />
          <CustomButton
            text="Let's Talk"
            textColor="text-text-primary"
            border="border"
            width="w-fit"
            icon={<ArrowRight stroke />}
            iconPosition="after"
            padding="px-4 py-2"
          />
        </div>
        {/* social icons */}
        <div className="flex items-center gap-x-5 gap-y-3 mt-8">
          {socialIcons.map((item) => (
            <img src={item.icon} alt={item.alt} key={item.alt} />
          ))}
        </div>
      </div>
      {/* main links */}
      <div className="grid md:grid-cols-5 xs:grid-cols-3 grid-cols-2 py-12 mt-8 md:gap-1 gap-4">
        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">
            Business Type
          </h5>
          <ul className="text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
              Restaurant
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Coffee Shop
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Shop
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Supermarket
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Food Trucks
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Gift Shops
            </li>
            <li className="text-white text-base font-normal opacity-75" i>
              Hairdressers
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Car Services
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Express Service
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">Company</h5>
          <ul className="text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
              About Us
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Careers
            </li>
            <li className="text-white text-base font-normal opacity-75">
              Shop
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/faqs">FAQ</Link>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">Links</h5>
          <ul className="text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/features">Features</Link>
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <h5 className="text-base text-white font-semibold mb-3">Legal</h5>
          <ul className="text-left space-y-3">
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/terms">Terms of Service</Link>
            </li>
            <li className="text-white text-base font-normal opacity-75">
              <Link to="/privacy-policy">Privacy and Policy</Link>
            </li>
          </ul>
        </div>

        <div className="flex-col space-y-8 xs:flex hidden">
          <div>
            <h5 className="text-base text-white font-semibold mb-3">
              Download Our POS App
            </h5>
            <div className="flex items-center gap-3">
              <AppleStore />
              <GooglePlay />
            </div>
          </div>
          <div>
            <h5 className="text-base text-white font-semibold mb-3">
              Download Our Dashboard App
            </h5>
            <div className="flex items-center gap-3">
              <AppleStore />
              <GooglePlay />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col space-y-8 xs:hidden flex pb-12">
        <div>
          <h5 className="text-base text-white font-semibold mb-3">
            Download Our POS App
          </h5>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
        <div>
          <h5 className="text-base text-white font-semibold mb-3">
            Download Our Dashboard App
          </h5>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
      </div>
      <hr className="bg-text-gray" />
      {/* copyright */}
      <div className="flex items-center justify-between border-t border-text-gray py-8">
        <p className="text-sm text-white">
          Copyright © 2023 TagDev. All rights reserved.
        </p>
        <LanguageSwitcher
          position="bottom-full end-0"
          className="text-white"
          globeColor="white"
        />
      </div>
    </footer>
  );
};

export default Footer;

const socialIcons = [
  { alt: "Facebook", icon: Facebook },
  { alt: "Instagram", icon: Instagram },
  { alt: "Twitter", icon: Twitter },
  { alt: "Youtube", icon: Youtube },
];
