import "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getClientSalesByCategories } from "../../../../../features/ClientDashboard/ClientDashboardSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { MultiSelecCheckbox } from "../../../../common/components";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";
import { getAllCategories } from "../../../../../features/Categories/CategoriesSlice";

const SalesByCategories = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // January 1st of the current year
  const [endDate, setEndDate] = useState(new Date());
  // get category list for all categories from the store
  const { data: categoryList } = useSelector(
    (state) => state.categories.getAllCategories
  );
  const { data: sales } = useSelector(
    (state) => state.clientDashboard.getClientSalesByCategories
  );

  const categoryNames = sales?.map((category) => category?.englishName);

  const totalSales = sales?.map((category) => category?.totalSales);

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   */
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { selectedCategories: [] } });

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCategories({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCategories = watch("selectedCategories");

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
      ids: selectedCategories,
    };
    dispatch(getClientSalesByCategories({ payload }));
  }, [startDate, endDate, dispatch, selectedCategories]);

  const data = {
    labels: categoryNames,
    datasets: [
      {
        label: " ",
        data: totalSales, // Replace with actual data
        backgroundColor: [
          "#5BBAC0", // category 1
          "#DC67CE", // category 2
          "#1D96FF", // category 3
          "#53B1FD", // category 4
          "#2BE3B7", // category 5
        ],
        borderColor: ["#5BBAC0", "#DC67CE", "#1D96FF", "#53B1FD", "#2BE3B7"],
        borderWidth: 4,
        barPercentage: 0.5,
        // To display circle before each category, we use a pointStyle in the dataset
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  };

  const options = {
    indexAxis: "y", // Horizontal bar chart
    elements: {
      bar: {
        borderWidth: 2,
      },
      point: {
        pointStyle: "circle",
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        beginAtZero: true,
      },
      y: {
        grid: {
          display: false, // Hide grid lines
        },
      },
    },
  };

  return (
    <div className="relative">
      <h2 className="font-semibold text-xl text-center">Sales by Categories</h2>
      <div className="flex items-center justify-between mt-2">
        <DateRangePicker
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          initialSelectedDates={[startDate, endDate]}
          dateFormat="mmm dd"
        />

        <span className="flex justify-between gap-2">
          <Controller
            control={control}
            name="selectedCategories"
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <MultiSelecCheckbox
                  options={categoryList?.map((option) => ({
                    value: option?.id,
                    label: option?.englishName,
                  }))}
                  placeholder="Categories"
                  error={error}
                  value={value}
                  onChange={(val) => {
                    setValue("selectedCategories", val, {
                      shouldValidate: true,
                    });
                  }}
                />
              );
            }}
          />
        </span>
      </div>
      <div className="h-64">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default SalesByCategories;
