import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import SelectGroup from "../../../../../common/components/SelectDropdown/SelectDropdown";
import { useForm } from "react-hook-form";
import { Card, CustomButton } from "../../../../../common/components";
import { useDispatch } from "react-redux";
import {
  addCustomer,
  getAllCustomers,
} from "../../../../../../features/Customers/CustomersSlice";
import { useTranslation } from "react-i18next";

/**
 * AddNewCustomer component.
 * Renders a form to add or edit a customer
 *
 * @returns {JSX.Element} JSX code for rendering the AddNewCustomer component
 */

const AddNewCustomer = ({ setIsNewCustomer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // validation schema for add/edit customer form

  const addNewCustomer = z.object({
    name: z
      .string()
      .min(3, "client_dashboard.directory.customer.validations.name_error")
      .max(
        100,
        "client_dashboard.directory.customer.validations.name_error_max"
      ),
    email: z
      .string()
      .optional()
      .refine(
        (email) => email === "" || z.string().email().safeParse(email).success,
        {
          message:
            "client_dashboard.directory.customer.validations.email_error_valid",
        }
      ),
    phonenumber: z
      .string()
      .refine((phoneNumber) => phoneNumber.startsWith("+"), {
        message: "client_dashboard.directory.customer.validations.phone_error",
      }),
    gender: z
      .string()
      .min(1, "client_dashboard.directory.customer.validations.gender_error"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(addNewCustomer),
  });

  const onSubmit = async (data) => {
    const payload = {
      name: data?.name,
      email: data?.email,
      phoneNumber: data?.phonenumber,
      gender: data?.gender,
    };

    return dispatch(
      addCustomer({
        payload,
        successCallBack: onCustomerUpdate,
      })
    );
  };

  const onCustomerUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
    setIsNewCustomer(false);
  };

  return (
    <Card padding="p-3" className="space-y-3">
      <h6 className="to-text-primary text-base font-semibold">
        {t("client_dashboard.invoices.sales.add_new_customer")}
      </h6>
      {/* name */}
      <InputGroup
        labelText={t("client_dashboard.directory.customer.customer_name")}
        htmlFor="name"
        inputName="name"
        placeholder={t("client_dashboard.directory.customer.enter_name")}
        register={register}
        errors={errors}
      />
      {/* email */}
      <InputGroup
        labelText={t("client_dashboard.directory.customer.email")}
        htmlFor="email"
        inputName="email"
        placeholder={t("client_dashboard.directory.customer.enter_email")}
        register={register}
        errors={errors}
      />
      {/* phone */}
      <InputGroup
        labelText={t("client_dashboard.directory.customer.phone_number")}
        htmlFor="phonenumber"
        inputName="phonenumber"
        placeholder={t("client_dashboard.directory.customer.enter_phone")}
        register={register}
        errors={errors}
      />
      {/* gender */}
      <SelectGroup
        labelText={t("client_dashboard.directory.customer.select_gender")}
        htmlFor="gender"
        inputName="gender"
        options={[
          {
            value: "",
            label: t("client_dashboard.directory.customer.select_gender"),
          },
          {
            value: "MALE",
            label: t("client_dashboard.directory.customer.male"),
          },
          {
            value: "FEMALE",
            label: t("client_dashboard.directory.customer.female"),
          },
        ]}
        register={register}
        errors={errors}
        className="rtl:bg-left"
      />
      <div className="flex items-center gap-2">
        <CustomButton
          text={t("buttons.cancel")}
          width="w-fit"
          onClick={() => setIsNewCustomer(false)}
          bgColor="bg-transparent"
          textColor="text-text-primary"
          border="border"
        />
        <CustomButton
          text={t("client_dashboard.invoices.sales.add_customer")}
          width="w-fit min-w-16"
          onClick={handleSubmit(onSubmit)}
          border="border"
          disabled={isSubmitting}
        />
      </div>
    </Card>
  );
};

export default AddNewCustomer;
