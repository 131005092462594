import { useTranslation } from "react-i18next";
import { usePosSaveOrder } from "./usePosSaveOrder";
import CustomPosTable from "../../../common/components/PosTable/PosTable";
import CustomAdminModel from "../../../common/components/AdminModal/CustomAdminModal";
import CustomerOrderDetail from "../CustomerOrderDetail.jsx/CustomerOrderDetail";

function PosSaveOrder({ selectedItem, setSelectedItem, isCheckoutModalOpen, setIsCheckoutModalOpen }) {
  const {
    isOpen,
    headers,
    tableBodySaved,
    closeModal,
    openModal,
    dropdownOptionsWithActions,
    orderListLoading,
    fullName,
    branchName,
    currentDate
  } = usePosSaveOrder({ selectedItem, setSelectedItem, isCheckoutModalOpen, setIsCheckoutModalOpen });
  const invoiceNumber = selectedItem?.invoiceNumber;

  const { t } = useTranslation();

  return (
    <div className="p-3 bg-white">
      <h2 className="text-lg font-semibold text-text-primary">
        {t("pos_dashboard.saved")}
      </h2>
      <div className="font-medium min-h-[280px] max-h-[280px] overflow-auto">
        <CustomPosTable
          tableHeader={headers}
          tableBody={tableBodySaved}
          dropdownOptions={dropdownOptionsWithActions}
          setSelectedItem={(order) => setSelectedItem(order)}
          showActionsColumn={true}
          openModal={openModal}
          loading={orderListLoading}
        />
      </div>
      <CustomAdminModel
      title={invoiceNumber ? ` #${invoiceNumber}` : "Saved Order Details"}
      isOpen={isOpen}
        onClose={closeModal}
        width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
        statusText={{ value: "Saved", show: true }}
        subtitleText={{
          value: `${currentDate} · ${branchName} · ${fullName}`,
          show: true,
        }}
      >
      <CustomerOrderDetail selectedItem={selectedItem} setIsCheckoutModalOpen={setIsCheckoutModalOpen} closeModal={closeModal} isCheckoutModalOpen={isCheckoutModalOpen}  />
      </CustomAdminModel>
    </div>
  );
}

export default PosSaveOrder;
