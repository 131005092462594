import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import usePosNavbar from "./usePosNavbar";
import {
  BoxesIcon,
  DeviceTable,
  DrawerIcon,
  GreenIndicator,
  LogoGram,
  ReceiptIcon,
  SettingsIcon,
  SignalIcon,
  SignoutIcon,
} from "../../../common/Icons";
import Setting from "../../PosSettings/Setting";
import DefaultImg from "../../../../assets/images/DefaultImg.png";
import { LanguageSwitcher } from "../../../common/components/LanguageSwitcher/LanguageSwitcher";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const navbarItems = [
  {
    label: 'pos_dashboard.home_button',
    icon: BoxesIcon,
    link: "/pos/dashboard",
  },
  {
    label: 'pos_dashboard.order_button',
    icon: ReceiptIcon,
    link: "/pos/order",
  },
  {
    label: 'pos_dashboard.table_button',
    icon: DeviceTable,
    link: "/pos/table",
  },
  {
    label: 'pos_dashboard.drawer_button',
    icon: DrawerIcon,
    link: "/pos/drawer",
  },
];

/**
 * Navbar component for the Point of Sale (POS) interface.
 * @returns {JSX.Element} JSX element representing the PosNavbar component.
 */
function PosNavbar() {
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("/pos/dashboard");
  const { pathname } = useLocation();
  const handleSetActiveTab = (tabName) => {
    setActiveTab(tabName);
  };
  const { t } = useTranslation();

  const { name, storeName, profilePic } = usePosNavbar();

  useEffect(() => {
    setActiveTab(pathname);
  }, [pathname]);

  return (
    <>
      <nav className="px-6 py-3 border-b border-border-primary flex justify-between items-center w-auto sticky top-0 z-10 bg-white">
        {/* logo and brand */}
        <div className="flex items-center space-x-3">
          <LogoGram />
          <span className="text-sm font-medium text-text-primary py-2 px-3 border border-border-primary rounded-lg">
            <h5>{storeName ? storeName : "Store Name"}</h5>
          </span>
        </div>
        {/* nav links */}
        <ul className="list-none flex items-center space-x-3">
          {navbarItems?.map((item) => (
            <li
              key={item.link}
              className={`flex items-center gap-1 py-2 px-3 text-sm font-normal ${activeTab === item.link
                ? "bg-gray-100 rounded-lg font-medium text-blue-500"
                : "text-text-secondary"
              }`}
            >
              <Link
                to={item.link}
                className="flex items-center gap-1"
                onClick={() => handleSetActiveTab(item.link)}
              >
                <item.icon
                  strokeColor={activeTab === item.link ? "#0086FF" : "#1A1A1A"}
                />
                {t(item.label)}
              </Link>
            </li>
          ))}
        </ul>
        {/* profile status */}
        <div className="flex items-center space-x-3">
          <p className="text-green-600 text-sm ml-1">{t("pos_dashboard.online")}</p><SignalIcon />
          <button
            onClick={() => setSettingsModalOpen(true)}
            className="focus:outline-none"
          >
            <SettingsIcon />
          </button>
          {/* profile dropdown */}
          <Menu as="div" className="relative">
            <MenuButton className="flex items-center">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-10 w-10 rounded-full bg-gray-50"
                alt="Profile"
                src={profilePic || DefaultImg}
              />
            </MenuButton>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-2 max-w-60 min-w-52 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-4 py-3 flex flex-col gap-3">
                  <div className="flex items-center">
                    <img
                      className="h-10 w-10 rounded-full"
                      alt="Profile"
                      src={profilePic || DefaultImg}
                    />
                    <div className="ml-2 mr-2">
                      <p className="text-sm font-medium text-gray-900">{name}</p>
                    </div>
                    <GreenIndicator className="" />
                  </div>
                  <MenuItem>
                    {({ active }) => (
                      <div className={`ml-2 mr-4 px-2 rounded-lg py-2 border ${active ? 'bg-gray-100' : ''}`}>
                        <LanguageSwitcher />
                      </div>
                    )}
                  </MenuItem>
                  <hr className="" />
                  {userNavigation?.map((item) => (
                    <MenuItem key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                        >
                          <SignoutIcon className="inline-block w-5 h-5 mr-1" /> {t("buttons.signout")}
                        </Link>
                      )}
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </nav>
      {isSettingsModalOpen && (
        <Setting
          isModalOpen={isSettingsModalOpen}
          setModalOpen={setSettingsModalOpen}
        />
      )}
    </>
  );
}

export default PosNavbar;

const userNavigation = [{ name: "Sign out", href: "/pos/login" }];
