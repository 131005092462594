import React, { useEffect, useMemo } from "react";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import SelectGroup from "../../../../../common/components/SelectDropdown/SelectDropdown";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import MultiSelect from "../../../../../common/components/MultiSelect/MultiSelect";
import { format } from "date-fns";

/**
 * AddEditUser component.
 * Renders a form to add or edit a user
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditUser component
 */

const AddEditPromotions = ({
  storesList,
  user,
  register,
  errors,
  watch,
  setValue,
  itemList,
  setIsInputChanged,
  control,
}) => {
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => ({
      name: user?.name ?? "",
      startDate: user?.startDate
        ? format(new Date(user?.startDate), "yyyy-MM-dd")
        : "",
      endDate: user?.endDate
        ? format(new Date(user?.endDate), "yyyy-MM-dd")
        : "",
      type: user?.type ?? "",
      value: user?.value ?? "",
      store: user?.store?.id ?? "",
      selectedItems:
        user?.items?.map((item) => ({
          value: item.id,
          label: item.englishName,
        })) ?? [],
      allproduct: user?.isAllItem ?? false,
    }),
    [user]
  );

  useEffect(() => {
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [user, setValue, defaultValues]);

  const currentValues = watch();

  useEffect(() => {
    setIsInputChanged(
      Object.keys(defaultValues).some(
        (key) =>
          JSON.stringify(defaultValues[key]) !==
          JSON.stringify(currentValues[key])
      )
    );
  }, [currentValues, defaultValues]);

  const isAllProductChecked = watch("allproduct");

  return (
    <React.Fragment>
      <InputGroup
        labelText={t("client_dashboard.management.promotions.english_name")}
        htmlFor="name"
        inputName="name"
        placeholder={t(
          "client_dashboard.management.promotions.enter_english_name"
        )}
        register={register}
        errors={errors}
      />
      <div className="flex flex-row justify-between gap-4  ">
        <InputGroup
          labelText={t("client_dashboard.management.promotions.start_date")}
          htmlFor="startDate"
          inputName="startDate"
          inputType="date"
          register={register}
          errors={errors}
          className="w-1/2"
        />
        <InputGroup
          labelText={t("client_dashboard.management.promotions.end_date")}
          htmlFor="endDate"
          inputName="endDate"
          inputType="date"
          register={register}
          errors={errors}
          className="w-1/2"
        />
      </div>
      <SelectGroup
        labelText={t("client_dashboard.management.promotions.promotion_type")}
        htmlFor="type"
        inputName="type"
        options={types?.map((type) => ({
          value: type.value,
          label: t(type.label),
        }))}
        register={register}
        errors={errors}
      />
      <InputGroup
        labelText={t("client_dashboard.management.promotions.promotion_value")}
        htmlFor="value"
        inputName="value"
        placeholder={t(
          "client_dashboard.management.promotions.validations.enter_promotion_value"
        )}
        register={register}
        errors={errors}
      />
      <SelectGroup
        labelText={t("client_dashboard.management.promotions.promotion_store")}
        htmlFor="store"
        inputName="store"
        options={[
          {
            value: "",
            label: t("client_dashboard.management.promotions.promotion_store"),
          },
          ...storesList?.map((option) => ({
            value: option?.id,
            label: option?.branchName,
          })),
        ]}
        register={register}
        errors={errors}
        className="rtl:bg-left"
      />
      <p className="text-sm font-medium">
        {t("client_dashboard.management.promotions.promotion_select_item")}
      </p>
      <Controller
        control={control}
        name="selectedItems"
        className="rtl:bg-left"
        render={({ field: { value }, fieldState: { error } }) => (
          <MultiSelect
            options={[
              ...itemList?.map((option) => ({
                value: option?.vItemId || option?.id,
                label: option?.slug || option?.englishName,
              })),
            ]}
            placeholder={t(
              "client_dashboard.management.promotions.promotion_select_item"
            )}
            error={error}
            value={value}
            onChange={(val) => {
              setValue("selectedItems", val, { shouldValidate: true });
            }}
            isDisabled={isAllProductChecked}
          />
        )}
      />
      <div className="flex items-center gap-2">
        <InputGroup
          inputName="allproduct"
          inputType="checkbox"
          register={register}
          errors={errors}
          className="!leading-none"
          defaultChecked={isAllProductChecked}
        />
        <label
          htmlFor="allproduct"
          className="block text-sm font-normal text-text-primary leading-none"
        >
          {t("client_dashboard.management.promotions.promotion_all_product")}
        </label>
      </div>
    </React.Fragment>
  );
};

export default AddEditPromotions;

const types = [
  { value: "", label: "Select Type" },
  { value: "PERCENTAGE", label: "Percentage" },
  { value: "FIXED_AMOUNT", label: "Fixed Amount" },
];
