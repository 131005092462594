import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { InputGroup } from "../../../../../common/components";
import { useDispatch } from "react-redux";
import {
  addPaymentMethods,
  getPaymentMethods,
} from "../../../../../../features/Payments/PaymentsSlice";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";

const Points = forwardRef(({ setIsSubmitting }, ref) => {
  const dispatch = useDispatch();
  // validation schema for card payment
  const pointsPaymentSchema = z.object({
    valuePerPoint: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Value per point is required!"),
    pointPerValue: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Point per value is required!"),
    minimumReplacementApplication: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Minimum replacement application is required!"),
    minimumReplacementPoints: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Minimum replacement points is required!"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(pointsPaymentSchema),
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  // Expose handleSubmit function and isSubmitting state to parent component
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit((data) => {
      const payload = {
        paymentType: "POINTS",
        paymentPoints: {
          valuePerPoint: data?.valuePerPoint,
          pointPerValue: data?.pointPerValue,
          minReplacementApplication: data?.minimumReplacementApplication,
          minReplacementPoints: data?.minimumReplacementPoints,
        },
      };

      return dispatch(
        addPaymentMethods({
          payload: payload,
          successCallBack: onAddPaymentMethod,
        })
      );
    }),
  }));

  const onAddPaymentMethod = (data) => {
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  return (
    <div className="space-y-3">
      <InputGroup
        extraLabel="”Each 1 Saudi riyal value equals how many points ”"
        inputName="valuePerPoint"
        labelText="Value Per Point"
        htmlfor="valuePerPoint"
        placeholder="Enter Value Per Point"
        register={register}
        errors={errors}
      />
      <InputGroup
        extraLabel="“Each 1 Point equals how many Saudi Riyal “"
        inputName="pointPerValue"
        labelText="Point Per Value"
        htmlfor="pointPerValue"
        placeholder="Enter Point Per Value"
        register={register}
        errors={errors}
      />
      <InputGroup
        extraLabel="“How much is the lowest invoice value accepted”"
        inputName="minimumReplacementApplication"
        labelText="Minimum Replacement Application"
        htmlfor="minimumReplacementApplication"
        placeholder="Enter Minimum Replacement Application"
        register={register}
        errors={errors}
      />
      <InputGroup
        extraLabel="“How much is the lowest invoice value accepted”"
        inputName="minimumReplacementPoints"
        labelText="Minimum Replacement Points"
        htmlfor="minimumReplacementPoints"
        placeholder="Enter Minimum Replacement Points"
        register={register}
        errors={errors}
      />
    </div>
  );
});

export default Points;
