import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  BoxesIcon,
  HomeIcon,
  HarddriveIcon,
  InvoiceIcon,
  ReportIcon,
  StackIcon,
  MessagesIcon,
  CrossIcon,
  LogoLg,
  ArrowDown,
  ArrowUp,
} from "../../../common/Icons";
import { CustomButton } from "../../../common/components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../../context/UserContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
/**
 * Sidebar component.
 * Renders a collapsible sidebar with navigation links and a trial plan section.
 *
 * @param {Object} props - Props containing sidebarOpen and setSidebarOpen functions.
 * @returns {JSX.Element} JSX code for rendering the Sidebar component.
 */
const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  const { user } = useUserContext();
  const userRole = user?.role;

  const navigation = roleBasedNavigation[userRole];

  // useEffect(() => {
  //   document.documentElement.dir = "rtl";
  // }, []);

  // handle the active navigation link
  const isActive = (href) => {
    return location.pathname === href;
  };
  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  //  handle dropdown state when the url path changes
  useEffect(() => {
    const activeDropdown = navigation?.findIndex((item) =>
      item?.children
        ? item?.children?.some((child) =>
            location?.pathname?.includes(child?.href)
          )
        : location?.pathname?.includes(item.href)
    );

    setOpenDropdown(activeDropdown);
  }, [location?.pathname]);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <CrossIcon stroke />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-linear-soft backdrop-blur-1xl px-4 pb-4">
                  <div className="flex py-3 shrink-0 justify-center items-center border-b border-neutral-200 -mx-4">
                    <LogoLg />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-1">
                      {navigation?.map((item, index) => (
                        <li key={item.name}>
                          {item.hasChild ? (
                            <Fragment>
                              <button
                                onClick={() => handleDropdownToggle(index)}
                                className={classNames(
                                  openDropdown === index
                                    ? "text-text-link"
                                    : "text-text-secondary hover:text-text-link hover:bg-white",
                                  "group flex items-center w-full gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  strokeColor={
                                    openDropdown === index && "#0086FF"
                                  }
                                />
                                {t(item.name)}
                                {openDropdown === index ? (
                                  <ArrowUp className="ml-auto" />
                                ) : (
                                  <ArrowDown className="ml-auto" />
                                )}
                              </button>
                              {/* submenu */}
                              {openDropdown === index && (
                                <ul className="flex items-start w-full flex-col dropdown-ul">
                                  {item?.children?.map((submenuItem) => (
                                    <li
                                      className="w-full"
                                      key={submenuItem.name}
                                    >
                                      <Link
                                        to={submenuItem.href}
                                        className={classNames(
                                          isActive(submenuItem.href)
                                            ? "bg-white text-text-link shadow-sm"
                                            : "text-text-secondary hover:text-text-link hover:bg-white",
                                          "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                                        )}
                                      >
                                        {t(submenuItem.name)}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Fragment>
                          ) : (
                            <Link
                              to={item.href}
                              className={classNames(
                                isActive(item.href)
                                  ? "bg-white text-text-link shadow-sm"
                                  : "text-text-secondary hover:text-text-link hover:bg-white",
                                "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                strokeColor={item.current && "#0086FF"}
                              />
                              {t(item.name)}
                            </Link>
                          )}
                        </li>
                      ))}
                      <li className="mt-auto p-4 bg-white rounded-lg shadow-md">
                        <h5 className="text-sm to-text-primary font-medium">
                          {t("client_dashboard.trial_info.days_left")}
                        </h5>
                        <p className="to-text-secondary font-normal text-xs mt-2">
                          {t("client_dashboard.trial_info.plan_info")}
                        </p>
                        {/* progress */}
                        <div className="rounded-2xl h-2 w-full relative bg-surface-gray my-4">
                          <div className="rounded-2xl absolute bg-linear-primary inset-0 w-2/3"></div>
                        </div>
                        {/* upgrade button */}
                        <CustomButton
                          text="client_dashboard.trial_info.upgrade_button"
                          bgColor="bg-black-pearl"
                          textColor="text-white"
                          className="!text-sm"
                        />
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[265px] lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-linear-soft backdrop-blur-1xl px-4 pb-4">
          <div className="flex py-3 shrink-0 justify-center items-center border-b border-neutral-200 -mx-4">
            <LogoLg />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-1">
              {navigation?.map((item, index) => (
                <li key={item.name}>
                  {item.hasChild ? (
                    <Fragment>
                      <button
                        onClick={() => handleDropdownToggle(index)}
                        className={classNames(
                          openDropdown === index
                            ? "text-text-link"
                            : "text-text-secondary hover:text-text-link hover:bg-white",
                          "group flex items-center w-full gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          strokeColor={openDropdown === index && "#0086FF"}
                        />

                        {t(item.name)}
                        {openDropdown === index ? (
                          <ArrowUp className="ml-auto" />
                        ) : (
                          <ArrowDown className="ml-auto" />
                        )}
                      </button>
                      {/* submenu */}
                      {openDropdown === index && (
                        <ul className="flex items-start w-full flex-col dropdown-ul">
                          {item?.children?.map((submenuItem) => (
                            <li className="w-full" key={submenuItem.name}>
                              <Link
                                to={submenuItem.href}
                                className={classNames(
                                  isActive(submenuItem.href)
                                    ? "bg-white text-text-link shadow-sm"
                                    : "text-text-secondary hover:text-text-link hover:bg-white",
                                  "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                                )}
                              >
                                {t(submenuItem.name)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Fragment>
                  ) : (
                    <Link
                      to={item.href}
                      className={classNames(
                        isActive(item.href)
                          ? "bg-white text-text-link shadow-sm"
                          : "text-text-secondary hover:text-text-link hover:bg-white",
                        "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                      )}
                    >
                      <item.icon
                        strokeColor={isActive(item.href) && "#0086FF"}
                      />
                      {t(item.name)}
                    </Link>
                  )}
                </li>
              ))}
              <li className="mt-auto p-4 bg-white rounded-lg shadow-md">
                <h5 className="text-sm to-text-primary font-medium">
                  {t("client_dashboard.trial_info.days_left")}
                </h5>
                <p className="to-text-secondary font-normal text-xs mt-2">
                  {t("client_dashboard.trial_info.plan_info")}
                </p>
                {/* progress */}
                <div className="rounded-2xl h-2 w-full relative bg-surface-gray my-4">
                  <div className="rounded-2xl absolute bg-linear-primary inset-0 w-2/3"></div>
                </div>
                {/* upgrade button */}
                <CustomButton
                  text="client_dashboard.trial_info.upgrade_button"
                  bgColor="bg-black-pearl"
                  textColor="text-white"
                  className="!text-sm"
                />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

// Sample Usage:
// import Sidebar from "path/to/Sidebar";
// <Sidebar sidebarOpen={true} setSidebarOpen={() => {}} />

const roleBasedNavigation = {
  CLIENT: [
    {
      name: "client_dashboard.overview",
      href: "/dashboard",
      icon: BoxesIcon,
      hasChild: false,
    },
    {
      name: "client_dashboard.messages",
      href: "#",
      icon: MessagesIcon,
      hasChild: false,
    },
    {
      name: "client_dashboard.invoices.name",
      href: "#",
      icon: InvoiceIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.invoices.sales.name",
          href: "/invoices/sales",
        },
        {
          name: "client_dashboard.invoices.purchase.name",
          href: "/invoices/purchase",
        },
        {
          name: "client_dashboard.invoices.expense.name",
          href: "/invoices/expense",
        },
      ],
    },
    {
      name: "client_dashboard.inventory.name",
      href: "#",
      icon: HomeIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.inventory.inventory_transfer.name",
          href: "/inventory/inventory-transfer",
        },
        {
          name: "client_dashboard.inventory.stocktaking.name",
          href: "/inventory/stocktaking",
        },
        {
          name: "client_dashboard.inventory.inventory_transaction.name",
          href: "/inventory/inventory-transaction",
        },
      ],
    },
    {
      name: "client_dashboard.directory.name",
      href: "#",
      icon: StackIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.directory.customer.name",
          href: "/directory/customers",
        },
        {
          name: "client_dashboard.directory.supplier.name",
          href: "/directory/supplier",
        },
        {
          name: "client_dashboard.directory.items.name",
          href: "/directory/items",
        },
        {
          name: "client_dashboard.directory.category.name",
          href: "/directory/categories",
        },
      ],
    },
    {
      name: "client_dashboard.report.name",
      href: "#",
      icon: ReportIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.report.sales",
          href: "/report/sales",
        },
        {
          name: "client_dashboard.report.purchase",
          href: "/report/purchase",
        },
        {
          name: "client_dashboard.report.taxes",
          href: "/report/taxes",
        },
        {
          name: "client_dashboard.report.profit_loss",
          href: "/report/profitloss",
        },
        {
          name: "client_dashboard.report.expenses",
          href: "/report/expense",
        },
        {
          name: "client_dashboard.report.inventory",
          href: "/report/inventory",
        },
      ],
    },
    {
      name: "client_dashboard.management.name",
      href: "#",
      icon: HarddriveIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.management.setting",
          href: "/management/settings",
        },
        {
          name: "client_dashboard.management.users.name",
          href: "/management/users",
        },
        {
          name: "client_dashboard.management.stores.name",
          href: "/management/stores",
        },
        {
          name: "client_dashboard.management.payments",
          href: "/management/payments",
        },
        {
          name: "client_dashboard.management.promotions.name",
          href: "/management/promotions",
        },
      ],
    },
  ],
  "SYSTEM MANAGER": [
    {
      name: "client_dashboard.overview",
      href: "/dashboard",
      icon: BoxesIcon,
      hasChild: false,
    },
    {
      name: "client_dashboard.messages",
      href: "#",
      icon: MessagesIcon,
      hasChild: false,
    },
    {
      name: "client_dashboard.invoices.name",
      href: "#",
      icon: InvoiceIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.invoices.sales.name",
          href: "/invoices/sales",
        },
        {
          name: "client_dashboard.invoices.purchase.name",
          href: "/invoices/purchase",
        },
        {
          name: "client_dashboard.invoices.expense.name",
          href: "/invoices/expense",
        },
      ],
    },
    {
      name: "client_dashboard.inventory.name",
      href: "#",
      icon: HomeIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.inventory.inventory_transfer.name",
          href: "/inventory/inventory-transfer",
        },
        {
          name: "client_dashboard.inventory.stocktaking.name",
          href: "/inventory/stocktaking",
        },
        {
          name: "client_dashboard.inventory.inventory_transaction.name",
          href: "/inventory/inventory-transaction",
        },
      ],
    },
    {
      name: "client_dashboard.directory.name",
      href: "#",
      icon: StackIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.directory.customer.name",
          href: "/directory/customers",
        },
        {
          name: "client_dashboard.directory.supplier.name",
          href: "/directory/supplier",
        },
        {
          name: "client_dashboard.directory.items.name",
          href: "/directory/items",
        },
        {
          name: "client_dashboard.directory.category.name",
          href: "/directory/categories",
        },
      ],
    },
    {
      name: "client_dashboard.report.name",
      href: "#",
      icon: ReportIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.report.sales",
          href: "/report/sales",
        },
        {
          name: "client_dashboard.report.purchase",
          href: "/report/purchase",
        },
        {
          name: "client_dashboard.report.taxes",
          href: "/report/taxes",
        },
        {
          name: "client_dashboard.report.profit_loss",
          href: "/report/profitloss",
        },
        {
          name: "client_dashboard.report.expenses",
          href: "/report/expense",
        },
        {
          name: "client_dashboard.report.inventory",
          href: "/report/inventory",
        },
      ],
    },
    {
      name: "client_dashboard.management.name",
      href: "#",
      icon: HarddriveIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.management.setting",
          href: "/management/settings",
        },
        {
          name: "client_dashboard.management.users.name",
          href: "/management/users",
        },
        {
          name: "client_dashboard.management.stores.name",
          href: "/management/stores",
        },
        {
          name: "client_dashboard.management.payments",
          href: "/management/payments",
        },
        {
          name: "client_dashboard.management.promotions.name",
          href: "/management/promotions",
        },
      ],
    },
  ],
  "SALES MANAGER": [
    {
      name: "client_dashboard.overview",
      href: "/dashboard",
      icon: BoxesIcon,
      hasChild: false,
    },

    {
      name: "client_dashboard.invoices.name",
      href: "#",
      icon: InvoiceIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.invoices.sales.name",
          href: "/invoices/sales",
        },
        {
          name: "client_dashboard.invoices.purchase.name",
          href: "/invoices/purchase",
        },
        {
          name: "client_dashboard.invoices.expense.name",
          href: "/invoices/expense",
        },
      ],
    },

    {
      name: "client_dashboard.report.name",
      href: "#",
      icon: ReportIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.report.sales",
          href: "/report/sales",
        },
        {
          name: "client_dashboard.report.purchase",
          href: "/report/purchase",
        },
        {
          name: "client_dashboard.report.taxes",
          href: "/report/taxes",
        },
        {
          name: "client_dashboard.report.profit_loss",
          href: "/report/profitloss",
        },
        {
          name: "client_dashboard.report.expenses",
          href: "/report/expense",
        },
        {
          name: "client_dashboard.report.inventory",
          href: "/report/inventory",
        },
      ],
    },
    {
      name: "client_dashboard.management.name",
      href: "#",
      icon: HarddriveIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.management.promotions.name",
          href: "/management/promotions",
        },
      ],
    },
  ],
  CASHIER: [
    {
      name: "client_dashboard.invoices.name",
      href: "#",
      icon: InvoiceIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.invoices.sales.name",
          href: "/invoices/sales",
        },
      ],
    },
    {
      name: "client_dashboard.inventory.name",
      href: "#",
      icon: HomeIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.inventory.inventory_transfer.name",
          href: "/inventory/inventory-transfer",
        },
        {
          name: "client_dashboard.inventory.stocktaking.name",
          href: "/inventory/stocktaking",
        },
        {
          name: "client_dashboard.inventory.inventory_transaction.name",
          href: "/inventory/inventory-transaction",
        },
      ],
    },
    {
      name: "client_dashboard.directory.name",
      href: "#",
      icon: StackIcon,
      hasChild: true,
      children: [
        {
          name: "client_dashboard.directory.customer.name",
          href: "/directory/customers",
        },
      ],
    },
  ],
};
