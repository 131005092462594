import React from "react";
import ProfileLayout from "../Layout";
import useCreateAccountForm from "./useCompleteProfile";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import CustomButton from "../../../common/components/Button/Button";
import { User } from "../../../common/Icons/fontawesome";
import { useTranslation } from "react-i18next";
import { UploadIcon } from "../../../common/Icons";
import { useSelector } from "react-redux";
import { Loader } from "../../../common/components";

/**
 * Component for creating a user account.
 * Utilizes useCreateAccountForm for form handling and state management.
 */
export const CreateAccount = () => {
  const { t } = useTranslation();
  const uploadFileData = useSelector((state) => state.uploadFile);
  // Destructuring methods and states from the useCreateAccountForm hook.
  const {
    imagePreview,
    handleImageChange,
    handleSubmit,
    onSubmit,
    register,
    errors,
    isSubmitting,
    imageUploadError,
  } = useCreateAccountForm();

  return (
    <ProfileLayout activePage={t("stepper.complete_profile")}>
      <div className="max-w-[480px] mx-auto py-8">
        {/* heading */}
        <h2 className="text-3xl text-center font-semibold mb-3 text-text-primary mt-1">
          {t("create_account.heading")}
        </h2>{" "}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 !mt-6">
          {/* upllaod image */}
          {uploadFileData?.uploadSingleFile?.isLoading ? (
            <div className="w-24 h-24 flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden mb-2">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Uploaded"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <User />
                )}
              </div>
              <label className="flex items-center gap-2  hover:underline bg-white text-black font-medium text-[14px] py-1 px-2 rounded border border-gray-300 hover:bg-gray-200 cursor-pointer">
                <UploadIcon />
                {imagePreview ? (
                  <span>{t("create_account.change_picture")}</span>
                ) : (
                  <span>{t("create_account.upload_picture")}</span>
                )}
                <input
                  type="file"
                  className="hidden"
                  {...register("userpic", {
                    onChange: (event) => {
                      handleImageChange(event);
                    },
                  })}
                />
              </label>
              {<p className="text-red-500 text-sm">{t(imageUploadError)}</p>}

              <p className="text-xs text-gray-500  mt-2">
                {t("create_account.image_upload_info")}
              </p>
            </div>
          )}

          {/* name */}
          <InputGroup
            htmlfor="name"
            labelText={t("create_account.fullname")}
            inputName="fullName"
            placeholder={t("create_account.enter_name")}
            inputType="text"
            register={register}
            errors={errors}
          />

          {/* Arabic Name */}
          <InputGroup
            htmlfor="arabic name"
            labelText={t("create_account.arabicname")}
            inputName="arabicName"
            placeholder={t("create_account.enter_arabic_name")}
            inputType="text"
            register={register}
            errors={errors}
          />

          {/* Phone Number */}
          <InputGroup
            htmlfor="phone number"
            labelText={t("create_account.phonenumber")}
            inputName="phoneNumber"
            placeholder={t("create_account.enter_phone_number")}
            inputType="phoneNumber"
            register={register}
            errors={errors}
          />

          <CustomButton
            text={t("create_account.continue")}
            isLoading={isSubmitting}
            type="submit"
          />
        </form>
      </div>
    </ProfileLayout>
  );
};
