import React, { useEffect } from "react";
import { Card, CustomButton, Loader } from "../../../../common/components";
import { PhoneIcon } from "../../../../common/Icons";

import {
  getSingleSubscriptions,
  deleteSubscription,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { DefaultImg } from "../../../../../assets/images";

function CancelSubscription({ selectedItem, closeModal ,subscriptionDetailId,selectedTab}) {
  const dispatch = useDispatch();
  const subscriptionId = selectedItem?.id??subscriptionDetailId;

  useEffect(() => {
    if (subscriptionId) dispatch(getSingleSubscriptions({ subscriptionId, isFreeTrial: selectedTab === 'FreeTrial' ? true : false }));
  }, [dispatch, subscriptionId]);

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getSingleSubscriptions);
  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const PrivideBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const subTotal = singleSubscriptiondata?.subTotal ?? "-";
  const plan = singleSubscriptiondata?.plan_tenure ?? "-";
  const startDate = singleSubscriptiondata?.startDate ?? "-";
  const endDate = singleSubscriptiondata?.endDate ?? "-";

  const handleConfirm = () => {
    dispatch(
      deleteSubscription({ subscriptionId, successCallBack: handleSuccess })
    );
  };
  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };
  if (subscriptionLoading) {
    return (
      <div className="mx-60 mt-12">
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-4">
      <Card className="!bg-surface-gray ">
        <p className="text-base font-normal text-text-primary">
          Are you sure you want to cancel {profile?.fullName}
          's subscribtion? This action cannot be undone.
        </p>
      </Card>
      <div className="flex items-center mt-4 ">
        <img
          src={profile?.profilePic || DefaultImg}
          alt="Pic"
          className="inline-block h-12 w-12 rounded-full border mr-3"
        />

        <div className="flex-grow ">
          <p className="text-text-primary text-lg font-semibold">
            {profile?.fullName}
          </p>
          <div className="flex items-center text-text-gray font-normal mt-2">
            <PhoneIcon className="mr-2" />{" "}
            <span className="pl-2 text-text-primary  text-sm font-normal">
              {profile?.phoneNumber}
            </span>
          </div>
        </div>
      </div>
      <p className="mt-4 text-base font-normal text-text-primary">
        Plan Detail
      </p>
      <div className="bg-white  mt-4 border rounded-lg p-4">
        <div className="flex gap-36 mb-4">
          <div>
            <p className="text-text-primary font-semibold">Advance Plan</p>
            <p className="text-text-secondary">
              {subTotal} SAR ({plan})
            </p>
          </div>
          <div>
            <p className="text-text-primary font-semibold">Provider</p>
            <p className="text-text-secondary">{PrivideBy}</p>
          </div>
        </div>
        <div className="flex gap-40">
          <div>
            <p className="text-text-primary font-semibold">Start Date</p>
            <p className="text-text-secondary">{startDate}</p>
          </div>
          <div>
            <p className="text-text-primary font-semibold ml-12">End Date</p>
            <p className="text-text-secondary ml-12">{endDate}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 pt-4">
        <CustomButton
          width="20px"
          text="Cancel"
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          onClick={closeModal}
          className="hover:!bg-gray-200"

        />
        <CustomButton
          type="button"
          width="20px"
          text="Confirm"
          bgColor="bg-danger"
          textColor="text-white"
          onClick={handleConfirm}
        />
      </div>
    </div>
  );
}

export default CancelSubscription;
