import React, { useState } from "react";
import { Card, Loader } from "../../../../common/components";
import useClientProfile from "./useClientProfile";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";

function ClientProfile() {
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // January 1st of the current year
  const [endDate, setEndDate] = useState(new Date());

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  const { cardsData, cardListLoading, statsConfig } = useClientProfile(
    startDate,
    endDate
  );

  const safeCardsData = cardsData || {};
  const dynamicStats = Object.entries(safeCardsData).map(([key, value]) => ({
    ...statsConfig[key],
    title: safeCardsData[key]?.title || statsConfig[key]?.title, // Use title from API response if available
    value: key === "totalCustomerSales" ? `${value} SAR` : value, // Append SAR to the value for totalCustomerSales
  }));

  if (cardListLoading) {
    return (
      <div className=" items-center flex px-[700px] py-12">
        <Loader />
      </div>
    );
  }

  if (!dynamicStats.length) {
    return (
      <div className="text-center py-10 border rounded-lg text-text-primary">
        No data available
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-end mb-2">
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
          }}
        />
      </div>
      <div className="grid grid-cols-3 gap-3 rounded-lg">
        {dynamicStats.map((stat, index) => (
          <Card key={index} className="flex gap-5 items-start !py-2 border">
            <div className="text-blue-700">
              {stat?.icon && <stat.icon className="h-6 w-6" />}
            </div>
            <div>
              <p className="text-sm font-medium text-gray-600">{stat?.title}</p>
              <p className="text-lg font-semibold">
                {stat?.value ? stat.value : "0"}
              </p>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default ClientProfile;
