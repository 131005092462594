import React from "react";
import { Card, CustomButton } from "../../../../../common/components";
import { EyeIcon, PencilIcon, TrashIcon } from "../../../../../common/Icons";
import _ from "lodash";

const PaymentsTable = ({ handleIconAction, paymentMethodsList }) => {
  const renderIcons = (item) => {
    switch (item?.type) {
      case "POINTS":
      case "CARD":
        return icons[0]?.map((iconObject, idx) => (
          <CustomButton
            icon={iconObject.icon}
            className="!shadow-none"
            key={idx}
            onClick={() =>
              handleIconAction(
                item,
                item?.id,
                item.type,
                iconObject.action,
                idx
              )
            }
          />
        ));
      case "CASH":
        return (
          <CustomButton
            icon={<TrashIcon />}
            className="!shadow-none"
            onClick={() =>
              handleIconAction(item, item?.id, item.type, "delete")
            }
          />
        );
      case "DELIVERY_COMPANIES":
        return (
          <CustomButton
            icon={<EyeIcon />}
            className="!shadow-none"
            onClick={() => handleIconAction(item, item?.id, item.type, "edit")}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      {paymentMethodsList?.map((item, index) => (
        <Card
          padding="py-1 px-3"
          className="flex items-center justify-between"
          key={index}
        >
          <h6 className="text-base font-medium to-text-primary">
            {item?.type
              .split("_")
              .map((word) => _.capitalize(word))
              .join(" ")}
          </h6>
          <div className="flex items-center">{renderIcons(item, index)}</div>
        </Card>
      ))}
    </div>
  );
};

export default PaymentsTable;

const icons = [
  [
    { icon: <PencilIcon />, action: "edit" },
    { icon: <EyeIcon />, action: "view" },
    { icon: <TrashIcon />, action: "delete" },
  ],
];
