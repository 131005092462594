import React from "react";
import { CheckMark } from "../../../common/Icons";
import CustomButton from "../../../common/components/Button/Button";
import Footer from "../../../common/components/Footer/Footer";
import Navbar from "../../../common/components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

function FinishScreen() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };
  return (
    <div className="flex flex-col min-h-screen justify-between overflow-hidden">
      <Navbar />

      <div className="w-full flex flex-col justify-between items-center">
        <div className="lg:max-w-[480px] mx-auto flex flex-col items-center justify-center">
          {/* tick icon */}
          <CheckMark className="mx-auto" />
          {/* heading */}
          <h2 className="text-3xl font-semibold my-3 text-center text-text-primary">
            Great! You’ve Completed The Registration
          </h2>
          <p className="text-text-secondary text-base font-normal text-center">
            Welcome to TagDev! Get ready to embark on a journey of seamless
            experiences and exciting opportunities.
          </p>
          <CustomButton
            text="Start Use TagDev"
            type="submit"
            className="mt-6"
            onClick={handleButtonClick}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FinishScreen;
