import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrossIcon, TrashIcon } from "../../Icons";
import CustomButton from "../Button/Button";
import { closeModal } from "../../../../features/Modal/ModalSlice";
import { classNames } from "../../../Admin/AdminDashboard/SuperAdminLayout/AdminSetting/SettingSideBar/SettingSideBar";

// import { classNames } from "../../../../../Admin/AdminDashboard/AdminSetting/SettingSideBar/SettingSideBar";

/**
 * CustomModal component.
 * Renders a modal UI with a customizable title, body, and footer.
 *
 * @param {Object} props - Props for the CustomModal component.
 * @param {JSX.Element} props.children - Content to be rendered inside the modal body.
 * @param {string} [props.border="border-t"] - Border style for the modal.
 * @param {function} props.onAdd - Function to handle the "Add" button click.
 * @param {function} props.onDelete - Function to handle the "Delete" button click.
 * @param {function} props.onEdit - Function to handle the "Edit" button click.
 * @returns {JSX.Element} JSX code for rendering the CustomModal component.
 */

const RenderButtons = (
  structure = [],
  border,
  btnProps = {},
  callback = {}
) => {
  return (
    <div
      className={`px-6 !mt-2 flex items-center py-3 border-border-primary ${border} justify-between`}
    >
      {structure.map((row, index) => (
        <div
          key={index}
          className={classNames(
            "flex flex-1 items-center justify-center gap-2",
            index === 0 && "!justify-start",
            index === structure.length - 1 && "!justify-end"
          )}
        >
          {row.map((item, i) => (
            <CustomButton
              key={i + 1}
              text={item}
              width="w-fit"
              padding="py-2 px-3"
              className="leading-none"
              border="border"
              type="submit"
              {...btnProps[item]}
              onClick={(...e) => {
                callback["on" + item] && callback["on" + item]?.(...e);
                btnProps[item]?.onClick?.(...e);
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const defaultValues = {
  buttons: {
    delete: [[], ["Dismiss", "Delete"]],
    edit: [["Delete"], ["Save"]],
    add: [[], ["Cancel", "Add"]],
    create: [[], ["Cancel", "Create"]],
    detail: [["Delete"], ["Edit"]],
  },
};

const CustomModal = ({
  border = "border-t",
  children,
  onAdd,
  onEdit,
  onDelete,
  loading,
  isInputChanged = true,
  buttons = {},
  btnProps,
  actions,
}) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const defaultButtonProps = {
    Add: {
      onClick: onAdd,
      disabled: loading || !isInputChanged,
    },
    Create: {
      onClick: onAdd,
      disabled: loading || !isInputChanged,
    },
    Delete: {
      icon: modalState?.type === "delete" ? null : <TrashIcon />,
      bgColor: modalState?.type === "delete" && "!bg-red-500",
      textColor:
        modalState?.type === "delete" ? "text-white" : "text-text-primary",
      onClick: onDelete,
    },
    Edit: {
      bgColor: "bg-transparent",
      textColor: "text-text-primary",
      onClick: onEdit,
    },
    Save: {
      onClick: onAdd,
      disabled: loading || !isInputChanged,
    },
    Cancel: {
      bgColor: "bg-transparent",
      textColor: "text-text-primary",
      onClick: handleCloseModal,
    },
    Dismiss: {
      bgColor: "bg-transparent",
      textColor: "text-text-primary",
      onClick: handleCloseModal,
    },
    Duplicate: {
      bgColor: "bg-transparent",
      textColor: "text-text-primary",
    },
    Return: {
      bgColor: "bg-transparent",
      textColor: "text-text-primary",
    },
  };
  return (
    modalState?.isOpen && (
      <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/50 backdrop-blur-[2px] flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
        <div className="bg-white rounded-lg max-w-[700px] w-full">
          {/* header */}
          <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
            <h6 className="font-semibold text-text-primary text-xl !capitalize">
              {modalState.title}
            </h6>
            <CustomButton
              bgColor="bg-transparent"
              icon={<CrossIcon />}
              width="w-fit"
              border="border"
              onClick={handleCloseModal}
            />
          </div>
          {/* body */}
          <div className="p-5 space-y-3">{children}</div>
          {/* footer */}
          {RenderButtons(
            buttons[modalState?.type] ||
              defaultValues.buttons[modalState?.type],
            border,
            btnProps || defaultButtonProps,
            actions,
            defaultButtonProps
          )}
        </div>
      </div>
    )
  );
};

export default CustomModal;

// sample usage
/*
import CustomModal from "./path/to/customModal.jsx"
<CustomModal onEdit={handleDelete} 
 onDelete = { handleDelete } 
  onEdit = { handleEdit } 
  border = "border border-t" >
  {modal body}
 </CustomModal >
*/
