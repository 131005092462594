import React, { Fragment } from "react";
import { AppleStore, GooglePlay, RightArrow } from "../../../common/Icons";
import { usePosLogin } from "./usePosLogin";
import Navbar from "../../../common/components/Navbar/Navbar";
import CustomButton from "../../../common/components/Button/Button";
import Footer from "../../../common/components/Footer/Footer";
import { PosLoginBgImage } from "../../../../assets/images";
import { Menu, MenuButton, MenuItem } from "@headlessui/react";
import { LanguageSwitcher } from "../../../common/components/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const PosLogin = () => {
  const {
    storeCode,
    fastAccessCode,
    handleKeypadPress,
    isFastAccess,
    setIsFastAccess,
    submitFastAccessCode,
    submitStoreCode,
    handleButtonClick,
    errorMessage,
  } = usePosLogin();
  const KeypadButton = ({ valueKey }) => {
    const { t } = useTranslation();
    return (
      <button
        onClick={() => handleKeypadPress(t(valueKey), isFastAccess)}
        className="flex items-center justify-center h-20 text-text-primary bg-gray-200 text-2xl font-normal py-6 hover:bg-gray-300"
      >
        {t(valueKey)}
      </button>
    );
  };
  return (
    <div className="flex min-h-screen">
      {/* image side */}
      <div className="w-2/4 bg-bright-cerulean pb-10 lg:block  flex flex-col">
        {/* language dropdown */}
        <div className="  mt-3  w-fit pl-32 rtl:pr-12">
          <Menu as="div" className="">
            <MenuButton className="flex items-center">
              <MenuItem className="absolute px-4 end-0  z-10 mt-2.5 min-w-[120px] max-w-[120px] origin-top-right rounded-md bg-white py-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <LanguageSwitcher />
              </MenuItem>
            </MenuButton>
          </Menu>
        </div>
        {/* image */}
        <div>
          <img
            src={PosLoginBgImage}
            alt="LoginImage"
            className="ml-10 mt-20 mb-4  h-[620px] left-24 right-0  w-[100%]"
          />
        </div>
        <div className="flex flex-col  items-center   ">
          <span className="text-white text-lg font-medium mb-3">
            {t("pos_dashboard.download_our_app")}
          </span>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
      </div>

      {/* code form */}
      <div className="w-2/4  flex flex-col justify-between lg:px-0 px-4 z-10 relative bg-white">
        <div>
          <Navbar
            isFastAccess={isFastAccess}
            handleBackEvent={() => setIsFastAccess(false)}
          />{" "}
          <div className="lg:max-w-[350px] mx-auto mt-1">
            <h2 className="text-3xl font-semibold text-text-primary text-center">
              {isFastAccess
                ? t("pos_dashboard.enter_fast_access_code")
                : t("pos_dashboard.enter_store_code")}
            </h2>
            {/* form */}
            <div className="w-full">
              <>
                <div
                  className={`grid gap-4 mt-2 ${
                    isFastAccess ? "grid-cols-4" : "grid-cols-5"
                  }`}
                >
                  {(isFastAccess ? fastAccessCode : storeCode).map(
                    (num, index) => (
                      <div
                        key={index}
                        className="h-16 border rounded text-2xl text-black font-medium flex items-center justify-center"
                      >
                        {num}
                      </div>
                    )
                  )}
                </div>
                {errorMessage && (
                  <div className="text-red-500 text-center mt-4">
                    {errorMessage}
                  </div>
                )}

                <div className="grid grid-cols-3 gap-0 mt-4">
                  {[
                    "one",
                    "two",
                    "three",
                    "four",
                    "five",
                    "six",
                    "seven",
                    "eight",
                    "nine",
                    "dot",
                    "zero",
                    "backspace",
                  ].map((key) => (
                    <KeypadButton key={key} valueKey={`pos_dashboard.${key}`} /> // Pass translation key as prop
                  ))}
                </div>
                <CustomButton
                  text={t("pos_dashboard.confirm")}
                  onClick={
                    isFastAccess ? submitFastAccessCode : submitStoreCode
                  }
                  className="mt-4"
                />
              </>
              {/* Toggle between the different login forms */}
              <div className="text-center mt-12 mb-20 flex flex-col">
                <CustomButton
                  text={t("pos_dashboard.use_email_password")}
                  onClick={handleButtonClick}
                  bgColor="bg-transparent"
                  textColor="text-text-link"
                  borderRadius="none"
                  hoverBgColor="text-blue-800"
                  icon={<RightArrow />}
                  iconPosition="after"
                  className="!shadow-none"
                />
              </div>
              </div>
              <span className="text-sm text-text-primary font-normal justify-center items-center  flex mt-8">
        {t("footer.copyright")} © 2023 TagDev.
      </span>
              </div>
          </div>
         
      </div>
    </div>
  );
};

export default PosLogin;
