import { useState } from "react";
import { ArrowDown, ArrowUp } from "../../../../common/Icons";
import {
  CustomTable,
  MultiSelecCheckbox,
  SelectGroup,
} from "../../../../common/components";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import Layout from "../../Layout/Layout";
import useSales from "./useSales";

/**
 * Sales Reports List component
 * Renders table of Stores list
 *
 * @returns {JSX.Element} JSX code for rendering the Sales Reports list table
 */

export default function Sales() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    handleSubmit,
    setValue,
    saleReports,
    storesList,
    Controller,
    control,
    itemList,
    categoryList,
    cashierList,
  } = useSales(startDate, endDate);

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text="Export"
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          padding="py-2 px-3"
          icon={<ArrowUp className="min-w-6 min-h-6 pb-3" />}
        />
      </div>
      {/* filters */}

      <div className="flex items-center justify-between mt-3">
        <div className="flex items-center gap-3">
          {storesList?.length > 0 && (
            <SelectGroup
              className="min-w-33 "
              htmlFor="store"
              inputName="store"
              options={[
                { value: "", label: "All Store" },
                ...storesList?.map((option) => ({
                  value: option?.id,
                  label: option?.branchName,
                })),
              ]}
              onChange={(e) => {
                setValue("store", e.target.value);
                handleSubmit();
              }}
            />
          )}
          {cashierList?.length > 0 && (
            <SelectGroup
              className="min-w-36 "
              htmlFor="cashier"
              inputName="cashier"
              options={[
                { value: "", label: "All Cashier" },
                ...cashierList?.map((option) => ({
                  value: option?.id,
                  label: option?.fullName,
                })),
              ]}
            />
          )}
          {categoryList?.length > 0 && (
            <SelectGroup
              className="min-w-36 "
              htmlFor="category"
              inputName="category"
              options={[
                { value: "", label: "All Category" },
                ...categoryList?.map((option) => ({
                  value: option?.id,
                  label: option?.englishName,
                })),
              ]}
            />
          )}
          <div className="pt-1">
            <Controller
              control={control}
              name="selectedItems"
              render={({ field: { value }, fieldState: { error } }) => {
                return (
                  <MultiSelecCheckbox
                    options={itemList?.map((option) => ({
                      value: option?.vItemId || option?.id,
                      label: option?.slug || option?.englishName,
                    }))}
                    placeholder="Items"
                    error={error}
                    value={value}
                    onChange={(val) => {
                      setValue("selectedItems", val, { shouldValidate: true });
                    }}
                  />
                );
              }}
            />
          </div>
          {storesList?.length > 0 && (
            <SelectGroup
              className="min-w-36 "
              htmlFor="store"
              inputName="store"
              options={[
                { value: "", label: "All Payment" },
                ...storesList?.map((option) => ({
                  value: option?.id,
                  label: option?.branchName,
                })),
              ]}
            />
          )}
        </div>
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
          }}
        />
      </div>
      {/* data */}
      <CustomTable tableHeader={tableHeader} tableBody={[saleReports] ?? []} />
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.report.name", href: "#", current: false },
  { name: "client_dashboard.report.sales", href: "#", current: true },
];
const tableHeader = [
  { label: "", key: "Sale Invoice", type: "default" },
  { label: "Refundable sales", key: "refundAbleSales" },
  { label: "Total Discount", key: "totalDiscount" },
  {
    label: "Net sales",
    key: "netSales",
  },
  {
    label: "Cost Of Goods sold",
    key: "costOfGoodsSold",
  },
  {
    label: "Gross Margin",
    key: "grossMargin",
  },
  {
    label: "Sales VAT",
    key: "salesTax",
  },
  {
    label: "Sales After VAT",
    key: "salesAfterTax",
  },
  {
    label: "Sold Quantity",
    key: "soldQuantity",
  },
];
