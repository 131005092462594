import React from "react";
import InputLabel from "../InputLabel/InputLabel";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";

/**
 * SelectGroup component.
 * Renders a group of select elements with a label, dropdown with an arrow icon, error message, and optional children.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.htmlfor - The HTML for attribute.
 * @param {string} props.labelText - The text for the input label.
 * @param {string} props.inputName - The name of the input element.
 * @param {Object[]} props.options - Array of options for the select element.
 * @param {Object} props.register - The input registration object from React Hook Form.
 * @param {Object} props.errors - The error object from React Hook Form.
 * @param {string} props.value - The value of the input element.
 * @param {Function} props.onChange - The function to handle input changes.
 * @param {string} props.className - The additional CSS classes for the input.
 * @param {React.ReactNode} props.children - Optional children components.
 * @returns {JSX.Element} JSX code for rendering the InputGroup component.
 */

const SelectGroup = ({
  htmlfor = "",
  labelText = "",
  inputName = "",
  options = [],
  register = () => {},
  errors = {},
  value,
  onChange,
  className,
  defaultValue,
  bgColor,
  disabled = false,
  placeholder = "",
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`relative space-y-1  ${className}`}>
      <InputLabel htmlFor={htmlfor} text={labelText} />
      <div className="inline-block relative w-full">
        {isLoading ? (
          <Loader />
        ) : (
          <select
            name={inputName}
            className={`block w-full px-4 py-2 text-text-primary bg-white border border-border-primary rounded-lg appearance-none focus:border-border-primary focus:ring-0 focus:outline-none ${className} ${bgColor} ${
              _.get(errors, inputName) ? "border-red-500" : ""
            }`}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            {...register(inputName)}
          >
            {placeholder && (
              <option value="" disabled selected>
                {t(placeholder)}
              </option>
            )}
            {options &&
              options?.length > 0 &&
              options?.map((option) => (
                <option key={option?.value} value={option?.value}>
                  {t(option?.label)}
                </option>
              ))}
          </select>
        )}
      </div>

      {_.get(errors, inputName) && (
        <p className="text-red-500 text-sm">
          {t(_.get(errors, inputName).message)}
        </p>
      )}
    </div>
  );
};

export default SelectGroup;

// Sample Usage:
// <SelectGroup
//   labelText="Type of your business"
//   inputName="typeOfBusiness"
//   register={register}
//   errors={errors}
//   options={[
//     { value: "", label: "Select Business Type" },
//     { value: "COFFEE_SHOP", label: "Coffee shop" },
//     { value: "RESTAURANT", label: "Restaurant" },
//     { value: "RETAIL_SHOP", label: "Retail Shop" }
//   ]}
// />
