import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../common/components";
import { useSplitByItem } from "./useSplitByItem";

function SplitByCard() {
  const { t } = useTranslation();
  const {
    paymentMethodsList,
    paymentMethodsListLoading,
    total,
    taxAmount,
    totalAmount,
    discountAmount,
    handleSelectionChange,
    selectedCard,
  } = useSplitByItem();

  if (paymentMethodsListLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const cardMethods = paymentMethodsList?.filter(
    (method) => method.type === "CARD" && method.paymentCard
  );

  return (
    <div className="bg-white rounded-lg ">
      <span className="mb-4 text-sm font-medium">
        {t("pos_dashboard.choose_card")}
      </span>

      <fieldset className="h-[170px] my-2 overflow-auto">
        {cardMethods?.map((method) =>
          method?.paymentCard?.cards?.map((card) => (
            <div
              key={card.name}
              className="flex items-center justify-between mb-2  border p-3 rounded-lg"
            >
              <label
                htmlFor={card.name}
                className="flex items-center gap-2 text-sm font-medium cursor-pointer"
              >
                <img src={card.image} alt={card.name} className="h-6 w-6 mr-3" />
                {card.name}
              </label>
              <input
                id={card.name}
                name="paymentMethod"
                type="radio"
                value={card.name}
                checked={selectedCard === card.name}
                onChange={() => handleSelectionChange(card.name)}
                className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                style={{ accentColor: "blue" }}
              />
            </div>
          ))
        )}
      </fieldset>
      <div className="bg-white  rounded-lg   p-2  my-4 w-full">
        <hr className="border mb-2" />
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.sub_total")}
          </span>
          <span className="text-sm font-medium">{totalAmount || 0} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.discount")}
          </span>
          <span className="text-sm font-medium">
            {discountAmount || 0} SAR
          </span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.vat")}
          </span>
          <span className="text-sm font-medium">{taxAmount || 0} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {" "}
            {t("pos_dashboard.total")}
          </span>
          <span className="text-sm font-medium">{total || 0} SAR</span>
        </div>
        <hr className="border " />
      </div>
    </div>
  );
}

export default SplitByCard;
