import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteItem,
  getAllItems,
  getSingleItem,
} from "../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../features/ItemsModal/ItemsModalSlice";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { getAllCategories } from "../../../../../features/Categories/CategoriesSlice";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling items related functionalities.
 *
 * @returns {Object}
 *   itemList: Array containing item information for all items.
 *   isLoading: Data loading state for all items.
 *   singleItem: Object containing item information.
 *   singleItemLoading: Data loading state for single item information.
 */

const useItemsList = (itemId, vItemId) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.search);

  // get store list for all stores from the redux store
  const { data: storesList } = useSelector(
    (state) => state.stores.getAllStores
  );
  // get category list for all categories from the store
  const { data: categoryList } = useSelector(
    (state) => state.categories.getAllCategories
  );

  // get single item information from the store
  const { data: singleItem, isLoading: singleItemLoading } = useSelector(
    (state) => state.items.getSingleItem
  );
  // get item list for all items from the store
  const { data: itemList, isLoading: itemListLoading } = useSelector(
    (state) => state.items.getAllItems
  );

  const simpleItemDetail = singleItem
    ? {
        "Item Name": singleItem?.englishName,
        Description: singleItem?.description,
        "Store Name": singleItem?.stores?.branchName,
        "Item Category": singleItem?.category?.englishName,
        Quantity: singleItem?.quantity,
        "Item Cost": `${singleItem?.itemCost} SAR`,
        "Sale Price(Without Tax)": `${singleItem?.salePrice} SAR`,
      }
    : null;

  const rawItemDetail = singleItem
    ? {
        "Item Name": singleItem?.englishName,
        Description: singleItem?.description,
        "Store Name": singleItem?.stores?.branchName,
        Quantity: singleItem?.quantity,
        "Item Cost": `${singleItem?.itemCost} SAR`,
        "Item Category": singleItem?.category?.englishName,
        "Sale Price(Without Tax)": `${singleItem?.salePrice} SAR`,
        "Unit Of Measure": singleItem?.measureOfUnit,
        "Number Of Unit Of Measure": singleItem?.noMeasureOfUnit,
      }
    : null;

  const variableItemDetail = singleItem
    ? {
        "Item Name": singleItem?.slug ?? "-",
        "Cost Price": `${singleItem?.itemCost} SAR` ?? "-",
        "Sale Price": `${singleItem?.salePrice} SAR` ?? "-",
        Quantity: singleItem?.quantity ?? "-",
        Description: singleItem?.description ?? "-",
        "Store Name": singleItem?.stores?.branchName ?? "-",
        "Item Category": singleItem?.category?.englishName ?? "-",
      }
    : null;

  const compositingItemDetail = singleItem?.compositingItem
    ? {
        "Item Name": singleItem?.englishName,
        Description: singleItem?.description,
        "Store Name": singleItem?.stores?.branchName,
        "Item Category": singleItem?.category?.englishName,
        "Sale Price(Without Tax)": `${singleItem?.salePrice} SAR`,
        "Raw Items": singleItem?.compositingItem?.flatMap((item) => [
          { name: "Name Of Raw Item", value: item.rawItem?.englishName },
          { name: "Unit Of Measure", value: item.rawItem?.measureOfUnit },
          {
            name: "Item Cost",
            value: `${(
              item?.noMeasureOfUnit * item?.rawItem?.perUnitPrice
            ).toFixed(2)} SAR`,
          },
        ]),

        "Total Cost": singleItem?.compositingItem
          ?.reduce(
            (acc, item) =>
              acc + +(item?.noMeasureOfUnit * item?.rawItem?.perUnitPrice),
            0
          )
          .toFixed(2),
      }
    : null;

  const itemImage = singleItem?.image;

  /**
   * Handles the api call for getting all items
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["englishName", "category.englishName"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
      periodId: 0,
    };
    dispatch(getAllItems({ payload }));
  }, [currentPage, query, dispatch]);

  useEffect(() => {
    if (itemId || vItemId) {
      dispatch(
        getSingleItem({
          itemId,
          vItemId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, vItemId]);

  const onItemUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    dispatch(closeModal());
  };

  const handleDelete = () => {
    dispatch(
      deleteItem({
        itemId,
        vItemId,
        successCallBack: onItemUpdate,
      })
    );
  };

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!storesList) dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!categoryList) dispatch(getAllCategories({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    itemListLoading,
    singleItemLoading,
    itemList,
    singleItem,
    categoryList,
    storesList,
    onItemUpdate,
    handleDelete,
    simpleItemDetail,
    compositingItemDetail,
    variableItemDetail,
    rawItemDetail,
    itemImage,
    currentPage,
    setCurrentPage,
  };
};

export default useItemsList;
// Sample Usage:
// const { simplifiedItemList, itemListLoading, singleItemLoading, itemDetail, register, errors, handleSubmit, setValue, deleteItem } = useItemsList(selectedItemId);
