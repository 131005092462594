import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import { useForm } from "react-hook-form";
import { Card, CustomButton } from "../../../../../common/components";
import { useDispatch } from "react-redux";

import {
  addSupplier,
  getAllSuppliers,
} from "../../../../../../features/Suppliers/SuppliersSlice";
import { useTranslation } from "react-i18next";

/**
 * AddNewSupplier component.
 * Renders a form to add or edit a supplier
 *
 * @returns {JSX.Element} JSX code for rendering the AddNewSupplier component
 */

const AddNewSupplier = ({ setIsNewSupplier }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // validation schema for add/edit supplier form
  const addEditSupplierSchema = z.object({
    englishname: z
      .string()
      .min(3, "client_dashboard.directory.supplier.validations.englishname")
      .max(
        100,
        "client_dashboard.directory.supplier.validations.name_error_max"
      ),

    email: z
      .string()
      .min(10, "client_dashboard.directory.supplier.validations.email_error")
      .email(
        "client_dashboard.directory.supplier.validations.email_error_valid"
      ),
    phonenumber: z
      .string()
      .min(3, "client_dashboard.directory.supplier.validations.phone_error")
      .max(
        20,
        "client_dashboard.directory.supplier.validations.phone_error_max"
      )
      .regex(
        /^\+\d+$/,
        "client_dashboard.directory.supplier.validations.phone_error_regex"
      ),
    address: z
      .string()
      .min(4, "client_dashboard.directory.supplier.validations.error_address")
      .max(
        100,
        "client_dashboard.directory.supplier.validations.error_address_max"
      ),
    company: z
      .string()
      .min(4, "client_dashboard.directory.supplier.validations.company_error")
      .max(
        100,
        "client_dashboard.directory.supplier.validations.company_error_max"
      ),

    vatnumber: z
      .string()
      .length(15, "client_dashboard.directory.supplier.validations.vat_error"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(addEditSupplierSchema),
  });

  const onSubmit = async (data) => {
    const payload = {
      name: data?.englishname,
      companyName: data?.company,
      email: data?.email,
      phoneNumber: data?.phonenumber,
      address: data?.address,
      vatNumber: data?.vatnumber?.toString(),
      gender: "MALE",
    };

    return dispatch(
      addSupplier({
        payload,
        successCallBack: onSupplierUpdate,
      })
    );
  };

  const onSupplierUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllSuppliers({ payload }));
    setIsNewSupplier(false);
  };

  return (
    <Card padding="p-3" className="space-y-3">
      <h6 className="to-text-primary text-base font-semibold">
        {t("client_dashboard.invoices.purchase.add_new_supplier")}
      </h6>
      {/* name */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.english_name")}
        htmlFor="englishname"
        inputName="englishname"
        placeholder={t(
          "client_dashboard.directory.supplier.enter_english_name"
        )}
        register={register}
        errors={errors}
      />
      {/*company Name */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.company_name")}
        htmlFor="company"
        inputName="company"
        placeholder={t(
          "client_dashboard.directory.supplier.enter_company_name"
        )}
        register={register}
        errors={errors}
      />
      {/* email */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.email")}
        htmlFor="email"
        inputName="email"
        placeholder={t("client_dashboard.directory.supplier.enter_email")}
        register={register}
        errors={errors}
      />
      {/* phone */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.phone_number")}
        htmlFor="phonenumber"
        inputName="phonenumber"
        placeholder={t("client_dashboard.directory.supplier.enter_phone")}
        register={register}
        errors={errors}
      />
      {/* Address */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.enter_address")}
        htmlFor="address"
        inputType="textarea"
        placeholder={t("client_dashboard.directory.supplier.enter_addreess")}
        inputName="address"
        register={register}
        errors={errors}
        className="my-4"
      />

      {/* VAT */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.vat_number")}
        htmlFor="vatnumber"
        inputName="vatnumber"
        placeholder={t("client_dashboard.directory.supplier.enter_vatnumber")}
        register={register}
        errors={errors}
      />
      <div className="flex items-center gap-2">
        <CustomButton
          text={t("buttons.cancel")}
          width="w-fit"
          onClick={() => setIsNewSupplier(false)}
          bgColor="bg-transparent"
          textColor="text-text-primary"
          border="border"
        />
        <CustomButton
          text={t("client_dashboard.invoices.purchase.add_supplier")}
          width="w-fit"
          onClick={handleSubmit(onSubmit)}
          border="border"
          disabled={isSubmitting}
        />
      </div>
    </Card>
  );
};

export default AddNewSupplier;
