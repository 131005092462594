import React, { useState } from "react";

import { Radio, RadioGroup } from "@headlessui/react";
import {
  ChoosePlanBottomLines,
  ChoosePlanTopLines,
  PointArrow,
} from "../assets/images";
import Footer from "../components/LandingPage/Footer";
import Navbar from "../components/LandingPage/Navbar";
import {
  CheckCircle,
  GlobeWhite,
  GraphIcon,
  RocketsIcon,
} from "../components/common/Icons";
import { classNames } from "../helpers/generateClassNames";
import { CustomButton } from "../components/common/components";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [frequency, setFrequency] = useState(
    frequencies.find((f) => f.value === "annually")
  );

  return (
    <React.Fragment>
      <div className="w-full faqpagehero 1xl:px-29 px-4 relative">
        <Navbar />
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
            Fully Managed Your Store.
          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
            The World's Best POS System, With Fast & Friendly Support
          </p>
        </div>
        <PricingToggle frequency={frequency} setFrequency={setFrequency} />
        <PricingCards frequency={frequency} />
      </div>
      {/* footer */}
      <Footer className="mt-20" />
    </React.Fragment>
  );
};

export default Pricing;

const PricingToggle = ({ frequency, setFrequency }) => {
  return (
    <div className="w-fit mx-auto text-center space-y-3 md:mt-20 mt-14 relative min-w-72">
      <img
        src={PointArrow}
        alt="Arrow"
        className="absolute -right-12 top-5 md:block hidden"
      />
      <h2 className="text-2xl font-semibold text-text-primary">
        Choose The Plan
      </h2>
      <div className="w-fit mx-auto !mt-1 md:block hidden">
        <img src={ChoosePlanTopLines} alt="Lines" />
      </div>
      <fieldset aria-label="Payment frequency" className="!mt-1">
        <RadioGroup
          value={frequency}
          onChange={setFrequency}
          className="flex w-fit max-h-16 overflow-hidden items-center mx-auto gap-2 !mt-1 border border-border-primary p-1 rounded-full bg-white"
        >
          {frequencies.map((option) => (
            <Radio
              key={option.value}
              value={option}
              as="button"
              className={({ checked }) =>
                classNames(
                  "rounded-full h-12 text-sm font-medium flex items-center gap-3 px-3",
                  checked ? "bg-blue-500 text-white px-6" : "text-text-primary"
                )
              }
            >
              {option.label}
              {option.value === "annually" &&
                frequency.value === "annually" && (
                  <span className="bg-info-50 text-text-link rounded-full py-2 px-3 font-medium text-xs">
                    {option.discount}
                  </span>
                )}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
      <div className="w-fit ml-auto px-1 -mt-2 md:block hidden">
        <img src={ChoosePlanBottomLines} alt="Lines" />
      </div>
    </div>
  );
};

function PricingCards({ frequency }) {
  return (
    <div className="isolate mt-10 grid grid-cols-1 gap-5 lg:grid-cols-3">
      {tiers.map((tier) => (
        <div
          key={tier.id}
          className={tier.mostPopular && "price-card flex flex-col"}
        >
          {tier.mostPopular && (
            <span className="text-white text-center mx-auto w-fit text-sm font-medium pb-1">
              Popular
            </span>
          )}
          <div
            className={classNames(
              !tier.mostPopular && "ring-1 ring-border-primary",
              "rounded-3xl p-5 bg-white h-full"
            )}
          >
            <div className="flex flex-col h-full">
              <div className="space-y-4">
                <div className="rounded-lg bg-surface-primary p-3 w-fit">
                  {<tier.icon className="!fill-white" />}
                </div>
                <h3
                  id={tier.id}
                  className="text-lg font-semibold text-text-primary"
                >
                  {tier.name}
                </h3>

                <p className="flex items-baseline gap-x-2">
                  <span className="font-semibold text-text-primary text-3xl leading-snug">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="font-medium text-text-secondary text-xl">
                    {frequency.value === "annually"
                      ? tier.priceSuffix?.monthly
                      : "/month"}
                  </span>
                </p>
                <div>
                  {" "}
                  <Link to="/signup">
                    <CustomButton
                      text="Choose Plan"
                      icon={!tier.mostPopular}
                      border={
                        !tier.mostPopular && "border border-border-primary"
                      }
                      textColor={
                        !tier.mostPopular ? "text-text-primary" : "text-white"
                      }
                    />
                  </Link>
                </div>
              </div>

              <ul className="space-y-3 bg-neutral-50 rounded-xl p-3 mt-4 h-full">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckCircle
                      className="h-6 w-5 flex-none !fill-surface-primary"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

const frequencies = [
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually", discount: "20% off" },
];
const tiers = [
  {
    name: "Basic Plan",
    id: "tier-basic-plan",
    href: "#",
    price: { monthly: "85 SAR", annually: "850 SAR" },
    priceSuffix: { monthly: "85 SAR/month" },
    features: [
      "Cloud Point of Sale",
      "Dashboard Analytics Overview",
      "Items & Category Directory: simple & Variable items",
      "Unlimited Invoices",
      "Sales Invoices Management",
      "Customer Management",
      "Advanced Reporting",
      "User Management: 1 User only",
      "Store Management: 1 Store only",
      "24/7 Support",
    ],
    mostPopular: false,
    icon: GlobeWhite,
  },
  {
    name: "Professional Plan",
    id: "tier-professinoal-plan",
    href: "#",
    price: { monthly: "110 SAR", annually: "1100 SAR" },
    priceSuffix: { monthly: "110 SAR/month" },
    features: [
      "Everything in Basic Plan",
      "Items & Category Directory: simple & Variable & Compositing & Raw items",
      "Stocktaking Management",
      "Promotions",
      "Users Management",
    ],
    mostPopular: true,
    icon: GraphIcon,
  },
  {
    name: "Enterprise Plan",
    id: "tier-enterprise-plan",
    href: "#",
    price: { monthly: "160 SAR", annually: "1600 SAR" },
    priceSuffix: { monthly: "160 SAR/month" },
    features: [
      "Everything in Professional Plan",
      "Inventory Transaction Management",
      "Table Management",
      "Loyalty Points",
      "AI Report",
      "Add More Branch",
    ],
    mostPopular: false,
    icon: RocketsIcon,
  },
];
