import api from "../../services/api";

/**
 * Creates inventory transfer API endpoints based on the specified base path.
 * @param {string} basePath - The base path for inventory transfer endpoints.
 * @returns {Object} Object containing inventory transfer API methods.
 */
const createInventoryTransferApi = (basePath) => ({
  /**
   * Retrieves all inventory transfers.
   * @param {object} payload - Payload containing query parameters.
   * @param {number} payload.page - Current page.
   * @param {number} payload.pageSize - Number of items per page.
   * @param {string} payload.sortColumn - Column ID for sorting inventory transfers.
   * @param {string} payload.order - Order for sorting inventory transfers by ascending or descending.
   * @param {object} payload.condition - Additional conditions for filtering.
   * @param {object} payload.attributes - Additional attributes for customization.
   * @returns {Promise} A Promise that resolves to the inventory transfer response.
   */
  getAllInventoryTransfers: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * Retrieves a single inventory transfer.
   * @param {string} inventoryTransferId - ID of the inventory transfer.
   * @returns {Promise} A Promise that resolves to the inventory transfer response.
   */
  getSingleInventoryTransfer: async (inventoryTransferId) => {
    const response = await api().get(`${basePath}/${inventoryTransferId}`);
    return response;
  },

  /**
   * Updates a single inventory transfer.
   * @param {string} inventoryTransferId - ID of the inventory transfer.
   * @param {object} payload - Payload containing updated data.
   * @returns {Promise} A Promise that resolves to the inventory transfer response.
   */
  updateInventoryTransfer: async (inventoryTransferId, payload) => {
    const response = await api().put(
      `${basePath}/${inventoryTransferId}`,
      payload
    );
    return response;
  },

  /**
   * Adds an inventory transfer.
   * @param {object} payload - Payload containing data for the new inventory transfer.
   * @returns {Promise} A Promise that resolves to the inventory transfer response.
   */
  addInventoryTransfer: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * Deletes an inventory transfer.
   * @param {string} inventoryTransferId - ID of the inventory transfer to be deleted.
   * @returns {Promise} A Promise that resolves to the deletion response.
   */
  deleteInventoryTransfer: async (inventoryTransferId) => {
    const response = await api().delete(`${basePath}/${inventoryTransferId}`);
    return response;
  },
  /**
   * undo a deleted inventory transfer.
   * @param {string} inventoryTransferId - ID of the inventory transfer to be deleted.
   * @returns {Promise} A Promise that resolves to the deletion response.
   */
  undoInventoryTransfer: async (inventoryTransferId) => {
    const response = await api().get(
      `${basePath}/inventory-transfer/${inventoryTransferId}`
    );
    return response;
  },
});

// Export the createInventoryTransferApi

/**
 * Example usage:
 * import createInventoryTransferApi from './path/to/api/inventoryTransferApi';
 *
 * const inventoryTransferApi = createInventoryTransferApi('inventory-transfer');
 *
 * const getAllInventoryTransfers = async (payload) => {
 *   try {
 *     const response = await inventoryTransferApi.getAllInventoryTransfers(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createInventoryTransferApi;
