import React, { useState } from "react";

import { Radio, RadioGroup } from "@headlessui/react";
import {
  DirectoryFeature,
  InventoryFeature,
  InvoicesFeature,
  ReportFeature,
} from "../assets/images";
import Footer from "../components/LandingPage/Footer";
import Navbar from "../components/LandingPage/Navbar";
import { GlobeWhite } from "../components/common/Icons";
import { Card } from "../components/common/components";
import { classNames } from "../helpers/generateClassNames";

const Features = () => {
  const [currentFeature, setCurrentFeature] = useState(
    featureTabs.find((f) => f.value === "inventory")
  );

  return (
    <React.Fragment>
      <div className="w-full faqpagehero 1xl:px-29 px-4 relative">
        <Navbar />
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
            Features
          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
            Unlock possibilities with a user-friendly interface and cutting-edge
            functionality.
          </p>
        </div>
        <FeaturesToggle
          currentFeature={currentFeature}
          setCurrentFeature={setCurrentFeature}
        />
        <FeatureCards currentFeature={currentFeature} />
      </div>
      {/* footer */}
      <Footer className="mt-20" />
    </React.Fragment>
  );
};

export default Features;

const FeaturesToggle = ({ currentFeature, setCurrentFeature }) => {
  return (
    <div className="w-fit mx-auto text-center space-y-3 mt-16 relative md:min-w-72">
      <fieldset aria-label="Feature" className="!mt-1">
        <RadioGroup
          value={currentFeature}
          onChange={setCurrentFeature}
          className="flex w-fit max-h-16 overflow-hidden items-center mx-auto gap-2 !mt-1 border border-border-primary p-1 rounded-full bg-surface-gray"
        >
          {featureTabs.map((option) => (
            <Radio
              key={option.value}
              value={option}
              as="button"
              className={({ checked }) =>
                classNames(
                  "rounded-full md:h-12 h-10 text-sm font-medium flex items-center md:gap-3 gap-2 xs:px-3 px-1",
                  checked
                    ? "bg-blue-500 text-white xs:px-3 px-2"
                    : "text-text-primary"
                )
              }
            >
              {option.label}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
    </div>
  );
};

function FeatureCards({ currentFeature }) {
  // Find the feature information corresponding to the current feature selected
  const featureDetails = featuresInfo.find(
    (feature) => feature.heading.toLowerCase() === currentFeature.value
  );

  return (
    <React.Fragment>
      <Card padding="md:p-8 p-4" className="grid grid-cols-12 mt-10 gap-6">
        <div className="lg:col-span-7 col-span-12 lg:order-1 order-2">
          <h5 className="text-text-primary font-semibold text-2xl">
            {featureDetails.heading}
          </h5>
          <ol className="list-decimal pl-5 space-y-4 mt-6">
            {featureDetails.featurePoints.map((point, index) => (
              <li key={index}>
                <h2 className="text-base text-text-primary font-semibold">
                  {point.point}
                </h2>
                <ul className="list-disc mt-3 text-base text-text-secondary pl-1 tracking-tight">
                  {point.subPoints.map((subPoint, subIndex) => (
                    <li key={subIndex}>{subPoint}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ol>
        </div>
        <div className="lg:col-span-5 col-span-12 lg:order-2 order-1">
          <img
            src={featureDetails.featureImage}
            className="lg:ml-auto mx-auto"
            alt={featureDetails.heading}
          />
        </div>
      </Card>
    </React.Fragment>
  );
}

const featureTabs = [
  { value: "inventory", label: "Inventory" },
  { value: "invoices", label: "Invoices" },
  { value: "directory", label: "Directory" },
  { value: "report", label: "Report" },
];

const featuresInfo = [
  {
    heading: "Inventory",
    id: "feature-inventory",
    href: "#",
    featurePoints: [
      {
        point: "Inventory Transfer",
        subPoints: [
          "Track and record the movement of inventory between different locations or stores.",
          "Automated updating of stock levels across locations upon transfer.",
          "Generate transfer reports for accountability and record-keeping.",
        ],
      },
      {
        point: "Stocktaking",
        subPoints: [
          "Facilitate periodic physical counts of inventory to ensure accuracy.",
          "Compare physical counts with system records to identify discrepancies.",
          "Update inventory levels based on actual stocktaking results.",
        ],
      },
      {
        point: "Inventory Transaction",
        subPoints: [
          "Monitor all inventory-related transactions including sales, purchases, and returns.",
          "Real-time updating of inventory levels after each transaction.",
          "Detailed transaction history for each inventory item.",
        ],
      },
    ],
    featureImage: InventoryFeature,
  },
  {
    heading: "Invoices",
    id: "feature-invoices",
    href: "#",
    featurePoints: [
      {
        point: "Sales Invoices",
        subPoints: [
          "Create and manage sales invoices with ease.",
          "Track payments, partial payments, and overdue invoices.",
          "Customize invoice templates for branding purposes.",
        ],
      },
      {
        point: "Purchase Invoices",
        subPoints: [
          "Record and manage purchase orders and invoices from suppliers.",
          "Track payment statuses and due dates.",
          "Integration with inventory for automatic stock level updates upon purchase.",
        ],
      },
      {
        point: "Expense Invoices",
        subPoints: [
          "Log and categorize business expenses.",
          "Manage recurring expenses and one-time payments.",
          "Attach supporting documents to expense records.",
        ],
      },
    ],
    featureImage: InvoicesFeature,
  },
  {
    heading: "Directory",
    id: "feature-directory",
    href: "#",
    featurePoints: [
      {
        point: "Customer Directory",
        subPoints: [
          "Maintain a detailed database of customer information.",
          "Track customer purchase history and preferences.",
          "Segment customers for targeted marketing and promotions.",
        ],
      },
      {
        point: "Supplier Directory",
        subPoints: [
          "Manage supplier contact information and transaction history.",
          "Evaluate supplier performance based on delivery times, prices, and quality.",
        ],
      },
      {
        point: "Items Directory",
        subPoints: [
          "Catalogue all items with descriptions, images, and barcodes.",
          "Define item categories for easy management and reporting.",
        ],
      },
      {
        point:
          "Category Directory (Simple Item, Variable Item, Compositing Item, & Raw Item)",
        subPoints: [
          "Organize inventory items into logical categories.",
          "Customizable categories to suit different types of merchandise.",
          "Analyze sales and stock levels by category.",
        ],
      },
    ],
    featureImage: DirectoryFeature,
  },
  {
    heading: "Report",
    id: "feature-report",
    href: "#",
    featurePoints: [
      {
        point: "Sales Reports",
        subPoints: [
          "Detailed analysis of sales performance over time.",
          "Breakdown by item, category, cashier, and time period.",
          "Identify best-selling products.",
        ],
      },
      {
        point: "Purchase Reports",
        subPoints: [
          "Track purchasing trends and expenses.",
          "Forecast future inventory needs based on historical data.",
        ],
      },
      {
        point: "Taxes Reports",
        subPoints: [
          "Automate tax calculations based on sales data.",
          "Generate tax reports for compliance and filing.",
        ],
      },
      {
        point: "Profit & Loss Reports",
        subPoints: [
          "Comprehensive overview of revenues, costs, and expenses.",
          "Identify profitable and unprofitable areas of the business.",
          "Periodic comparison for financial health assessment.",
        ],
      },
      {
        point: "Expense Reports",
        subPoints: [
          "Categorize and track all business expenses.",
          "Monitor spending patterns and identify cost-saving opportunities.",
          "Facilitate budget management and financial planning.",
        ],
      },
      {
        point: "Inventory Reports",
        subPoints: [
          "Monitor stock levels, turnover rates, and order history.",
          "Reports on stock aging and shelf life.",
          "Analyze inventory efficiency and optimize stock levels.",
        ],
      },
    ],
    featureImage: ReportFeature,
  },
];
