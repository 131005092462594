import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import {
  ChocolateBoxCard,
  ChocolateBoxCardMobile,
  NetIncomeCard,
  NetIncomeCardMobile,
  PosMainCard,
  PosMainCardMobile,
  SalesAndCostCard,
  SalesAndCostCardMobile,
} from "../../assets/images";
import { ArrowRight } from "../common/Icons";
import { CustomButton } from "../common/components";
import Navbar from "./Navbar";

export const slideInFromLeft = {
  hidden: { x: "-100vw", opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.7, ease: "easeOut" },
  },
};

export const slideInFromRight = {
  hidden: { x: "100vw", opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: "easeOut" },
  },
};

export const slideInFromTop = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.7, ease: "easeOut" },
  },
};

export const fadeIn = {
  zoomout: { scale: 0.9 },
  zoomin: {
    scale: 1,
    transition: { duration: 0.6, ease: "easeOut" },
  },
};

const HeroSection = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-full landingpagehero 1xl:px-29 px-4 relative overflow-x-hidden">
      <Navbar />
      {/* caption */}
      <div className="text-center xs:max-w-2xl mx-auto space-y-5">
        <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
          Work With <span className="text-gradient">The Best POS System </span>{" "}
          For Your Business
        </h1>
        <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
          Overcome all challenges in the retail sector with TAG Dev, offering
          customized technology solutions to improve the performance of various
          types of businesses.
        </p>
        <div className="flex items-center gap-3 w-fit mx-auto">
          <CustomButton text="Get Started" width="w-fit" />
          <CustomButton
            text="Let's Talk"
            textColor="text-text-primary"
            border="border"
            width="w-fit"
            icon={<ArrowRight stroke />}
            iconPosition="after"
            padding="px-4 py-2"
          />
        </div>
      </div>
      {/* image desktop */}
      <div className="xl:mx-auto md:mx-20 lg:mt-16 mt-12 lg:w-fit relative md:block hidden">
        <div className="relative">
          <motion.img
            initial="zoomout"
            variants={fadeIn}
            animate={hasScrolled && "zoomin"}
            src={PosMainCard}
            alt="Tablet"
            ref={ref}
          />
        </div>
        <motion.img
          src={ChocolateBoxCard}
          alt="Chocolate Box"
          className="absolute -right-14 top-10 z-10"
          initial="hidden"
          variants={slideInFromTop}
          animate={hasScrolled && "visible"}
        />
        <motion.img
          src={NetIncomeCard}
          alt="Net Income Card"
          className="absolute -right-14 bottom-1/4 z-10"
          initial="hidden"
          variants={slideInFromRight}
          animate={hasScrolled && "visible"}
        />
        <motion.img
          src={SalesAndCostCard}
          alt="Sales and cost"
          className="absolute -left-14 top-1/3 z-10"
          initial="hidden"
          variants={slideInFromLeft}
          animate={hasScrolled && "visible"}
        />
      </div>
      {/* image mobile */}
      <div className="mt-12 mx-auto w-fit relative md:hidden block">
        <motion.img
          initial="zoomout"
          variants={fadeIn}
          animate={hasScrolled && "zoomin"}
          src={PosMainCardMobile}
          alt="Tablet"
          ref={ref}
        />
        <motion.img
          src={ChocolateBoxCardMobile}
          alt="Chocolate Box"
          className="absolute -right-4 top-10 z-10"
          initial="hidden"
          variants={slideInFromTop}
          animate={hasScrolled && "visible"}
        />
        <motion.img
          src={NetIncomeCardMobile}
          alt="Net Income Card"
          className="absolute -right-4 bottom-1/4 z-10"
          initial="hidden"
          variants={slideInFromRight}
          animate={hasScrolled && "visible"}
        />
        <motion.img
          src={SalesAndCostCardMobile}
          alt="Sales and cost"
          className="absolute -left-4 top-1/3 z-10"
          initial="hidden"
          variants={slideInFromLeft}
          animate={hasScrolled && "visible"}
        />
      </div>
    </div>
  );
};

export default HeroSection;
