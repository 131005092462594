import React, { useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
} from "../../../../../common/components";
import { CrossIcon, QrCodeIcon } from "../../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllItems,
  getSingleItem,
  updateItem,
} from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/ItemsModal/ItemsModalSlice";
import { Plus } from "../../../../../common/Icons/fontawesome";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const EditCompositingItem = ({
  storesList,
  categoryList,
  selected,
  rawItems,
}) => {
  const dispatch = useDispatch();
  // get uploaded file from the redux store data
  const uploadFileData = useSelector((state) => state.uploadFile);
  const { title, isModalOpen } = useSelector((state) => state.itemsModal);
  const itemId = selected?.id;
  const vItemId = selected?.type === "VARIABLE" && selected?.vItemId;

  // Schema for the main form data
  const compositingItemSchema = z.object({
    englishname: z.string().min(3, "English Name is required!"),
    arabicname: z.string().min(3, "Arabic Name is required!"),
    description: z.string().min(1, "Description is required!"),
    store: z.coerce.number().min(1, "Store is required!"),
    category: z.coerce.number().min(1, "Category is required!"),
    saleprice: z.coerce.number().min(1, "Sale Price is required!"),
    totalcost: z.coerce.number().min(1, "Total Cost is required!"),
    compositingItem: z.array(
      z.object({
        itemcost: z.coerce.number().min(1, "Item Cost is required!"),
        rawitem: z.coerce.number().min(1, "Raw Item is required!"),
        unitmeasure: z.string().optional(),
        perunitprice: z.coerce.number().min(1, "Per Unit Price is required!"),
        numberunit: z.coerce.number().min(1, "Number of unit is required!"),
      })
    ),
  });
  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(compositingItemSchema),
    defaultValues: {
      compositingItem: selected?.compositingItem?.map((item) => ({
        rawitem: +item?.rawItem?.id,
        unitmeasure: item?.rawItem?.measureOfUnit,
        perunitprice: item?.rawItem?.perUnitPrice,
        numberunit: item?.noMeasureOfUnit,
        itemcost: item?.noMeasureOfUnit * item?.rawItem?.perUnitPrice,
      })),
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: "compositingItem",
  });

  const defaultValues = useMemo(
    () => ({
      englishname: selected?.englishName ?? "",
      arabicname: selected?.arabicName ?? "",
      description: selected?.description ?? "",
      store: selected?.stores?.id ?? "",
      itemcost: selected?.itemCost ?? "",
      category: selected?.category?.id ?? "",
      saleprice: selected?.salePrice ?? "",
    }),
    [selected]
  );

  useEffect(() => {
    // set the default values for each field to edit selected using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [selected, setValue, defaultValues]);

  const selectedItem = watch("compositingItem");

  useEffect(() => {
    selectedItem.map((s, i) => {
      const rawItem = rawItems.find((r) => r.id === +s.rawitem);
      if (!rawItem) return null;
      if (s.perunitprice !== rawItem.perUnitPrice)
        update(i, {
          rawitem: rawItem.id,
          unitmeasure: rawItem.measureOfUnit,
          perunitprice: rawItem.perUnitPrice,
          // itemcost: rawItem.perUnitPrice * +s?.numberunit,
        });
      setValue(
        `compositingItem.${i}.itemcost`,
        rawItem.perUnitPrice * +s?.numberunit
      );
      return null;
    });

    const totalItemCost = selectedItem.reduce((acc, s) => acc + s.itemcost, 0);
    setValue("totalcost", totalItemCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedItem)]);

  const onSubmit = (data) => {
    const payload = {
      type: "COMPOSITING",
      image: uploadFileData?.uploadSingleFile?.data?.url || selected?.image,
      englishName: data?.englishname,
      arabicName: data?.arabicname,
      description: data?.description,
      storeId: +data?.store,
      categoryId: +data?.category,
      itemCost: +data?.totalcost,
      salePrice: +data?.saleprice,
      compositingItem: data?.compositingItem?.map((item) => ({
        noMeasureOfUnit: item?.numberunit,
        rawItemId: item?.rawitem,
      })),
    };

    return dispatch(
      updateItem({
        itemId: selected?.id,
        payload: payload,
        successCallBack: onItemUpdate,
      })
    );
  };

  const onItemUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    dispatch(
      getSingleItem({
        itemId,
        vItemId,
      })
    );
    dispatch(closeModal());
  };

  const addComposit = () => {
    append({
      rawitem: "",
      unitmeasure: "",
      perunitprice: "",
      numberunit: "",
      itemcost: "",
    });
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5 space-y-3">
              <CustomFileUploader
                isLoading={uploadFileData?.uploadSingleFile?.isLoading}
                selected={selected ? selected?.image : null}
              />
              {/* name */}
              <InputGroup
                labelText="Item Name"
                htmlFor="englishname"
                inputName="englishname"
                placeholder="Enter Item Name"
                register={register}
                errors={errors}
              />
              {/* arabic name */}
              <InputGroup
                labelText="Arabic Item Name"
                htmlFor="arabicname"
                inputName="arabicname"
                placeholder="Enter Arabic Item Name"
                register={register}
                errors={errors}
              />
              {/* description */}
              <InputGroup
                labelText="Description"
                htmlFor="description"
                inputName="description"
                placeholder="Enter Description"
                register={register}
                errors={errors}
              />
              {/* store */}
              <SelectGroup
                labelText="Select Store"
                htmlFor="store"
                inputName="store"
                options={[
                  { value: "", label: "All Store" },
                  ...storesList?.map((option) => ({
                    value: option?.id,
                    label: option?.branchName,
                  })),
                ]}
                register={register}
                errors={errors}
              />
              {/* category */}
              <SelectGroup
                labelText="Select Category"
                htmlFor="category"
                inputName="category"
                options={[
                  { value: "", label: "Select Category" },
                  ...categoryList?.map((option) => ({
                    value: option?.id,
                    label: option?.englishName,
                  })),
                ]}
                register={register}
                errors={errors}
              />

              {/* sale price */}
              <InputGroup
                labelText="Sale Price (without tax)"
                htmlFor="saleprice"
                inputName="saleprice"
                placeholder="Enter Sale Price"
                register={register}
                errors={errors}
              />
              <h6 className="text-text-primary font-semibold text-base">
                Compositing Product
              </h6>

              {fields.map((item, index) => (
                <div className="space-y-3" key={item.id}>
                  <div className="flex items-center justify-between">
                    <span className="font-semibold to-text-primary text-sm">
                      Composition {index + 1}
                    </span>
                    {fields?.length > 1 && (
                      <span
                        className="cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <CrossIcon />
                      </span>
                    )}
                  </div>

                  <SelectGroup
                    labelText="Raw Items"
                    htmlFor={`rawitem-${index}`}
                    inputName={`compositingItem.${index}.rawitem`}
                    options={[
                      { value: "", label: "Select Raw Items" },
                      ...rawItems?.map((option) => ({
                        value: option?.id,
                        label: option?.englishName,
                      })),
                    ]}
                    register={register}
                    errors={errors}
                  />

                  <InputGroup
                    labelText="Unit of Measure"
                    htmlFor={`unitmeasure-${index}`}
                    inputName={`compositingItem.${index}.unitmeasure`}
                    register={register}
                    errors={errors}
                    className="pointer-events-none"
                    bgColor="!bg-surface-gray"
                  />
                  <InputGroup
                    labelText="Per Unit Price"
                    htmlFor={`perunitprice-${index}`}
                    inputName={`compositingItem.${index}.perunitprice`}
                    register={register}
                    errors={errors}
                    className="pointer-events-none"
                    bgColor="!bg-surface-gray"
                  />

                  <InputGroup
                    labelText="Number Unit of Measure"
                    htmlFor={`numberunit-${index}`}
                    inputName={`compositingItem.${index}.numberunit`}
                    placeholder="Enter Number Unit of Measure"
                    register={register}
                    errors={errors}
                  />
                  <InputGroup
                    labelText="Item Cost"
                    htmlFor={`itemcost-${index}`}
                    inputName={`compositingItem.${index}.itemcost`}
                    register={register}
                    errors={errors}
                    className="pointer-events-none"
                    bgColor="!bg-surface-gray"
                    disabled={true}
                  />
                  <CustomButton
                    text="Create SKU Number"
                    icon={<QrCodeIcon />}
                    textColor="text-text-primary"
                    border="border"
                  />
                </div>
              ))}

              <CustomButton
                text="Add Another Composition"
                icon={<Plus color="text-blue-sky" />}
                width="w-fit"
                bgColor="bg-transparent"
                className="shadow-none"
                textColor="text-text-link"
                onClick={addComposit}
              />
              {/* total cost */}
              <InputGroup
                labelText="Total Cost"
                htmlFor="totalcost"
                inputName="totalcost"
                register={register}
                errors={errors}
                className="pointer-events-none"
                bgColor="!bg-surface-gray"
              />
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                text={"Save"}
                disabled={isSubmitting}
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditCompositingItem;

// sample usage
// import EditCompositingItem from "./path/to/EditCompositingItem.jsx";

// <EditCompositingItem />
