import React, { Suspense, lazy, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../../common/Icons/fontawesome";
import { EditIcon, EyeIcon, TrashIcon } from "../../../../../common/Icons";
import {
  Loader,
  Breadcrumb,
  CustomButton,
  CustomTable,
  DetailCard,
} from "../../../../../common/components";
import Layout from "../../../Layout/Layout";
import useUsersList from "./useUsersList";
import AddEditUser from "../AddEditUser/AddEditUser";
import PaginationBar from "../../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";

// import dynamic components with react lazy components
const CustomModal = lazy(() =>
  import("../../../../../common/components/CustomModel/CustomModel")
);

/**
 * User List component
 * Renders table of users list
 *
 * @returns {JSX.Element} JSX code for rendering the user list table
 */

export default function UsersList() {
  const [isInputChanged, setIsInputChanged] = useState();
  const [selectedItem, setSelectedItem] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalState = useSelector((state) => state.modal);

  const {
    userList,
    userDetail,
    register,
    errors,
    handleSubmit,
    singleUserLoading,
    setValue,
    userListLoading,
    isSubmitting,
    storesList,
    watch,
    deleteUser,
    currentPage,
    setCurrentPage,
    clearErrors,
    control,
  } = useUsersList(selectedItem?.id);

  const handleViewUser = (type) => {
    dispatch(
      openModal({
        title: `User ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleUserActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type} User`,
        type: type,
      })
    );
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text={"client_dashboard.management.users.add_user"}
          width="w-fit"
          bgColor="!bg-surface-primary"
          className="hover:!text-white"
          icon={<Plus color="white" />}
          onClick={() => {
            handleUserActions("add");
          }}
        />
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* user list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={userList ?? []}
          setSelectedItem={setSelectedItem}
          loading={userListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewUser("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleUserActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleUserActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={userList ? userList?.length : 0}
        />
      </div>
      {/* modal */}
      <Suspense fallback={<Loader />}>
        <CustomModal
          onAdd={handleSubmit}
          onEdit={() => handleUserActions("edit")}
          onDelete={() => deleteUser()}
          loading={isSubmitting}
          isInputChanged={isInputChanged}
        >
          {/* conditionally render modal for different user actions */}
          {(modalState?.type === "edit" || modalState?.type === "add") && (
            <AddEditUser
              user={selectedItem ? selectedItem : null}
              register={register}
              errors={errors}
              setValue={setValue}
              storesList={storesList ?? []}
              watch={watch}
              control={control}
              setIsInputChanged={setIsInputChanged}
            />
          )}
          {/* detail modal */}
          {modalState?.type === "detail" &&
            (singleUserLoading ? (
              <Loader />
            ) : (
              <DetailCard detail={userDetail} />
            ))}
          {/* delete modal */}
          {modalState?.type === "delete" && (
            <p className="text-text-primary text-base font-normal">
              Are you sure you want to delete this user?{" "}
            </p>
          )}
        </CustomModal>
      </Suspense>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.management.name", href: "#", current: false },
  { name: "client_dashboard.management.users.name", href: "#", current: true },
];
// table headings for users table

const tableHeader = [
  { label: "Name", key: "fullName" },
  { label: "Email", key: "email" },
  { label: "POS Fast Access Code", key: "fastAccessCode" },
  {
    label: "Roles",
    key: "role",
    bgColorKey: {
      "SALES MANAGER": "bg-success-50 text-positive-600",
      CASHIER: "bg-danger-100 text-danger-600",
      "SYSTEM MANAGER": "bg-info-50 text-link",
    },
  },
  { label: "Access to Dashboard", key: "accessToDashboard" },
  { label: "Access to POS", key: "accessToPos" },
];
