import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  addStore,
  deleteStore,
  getAllStores,
  getSingleStore,
  updateStore,
} from "../../../../../../features/Stores/StoresSlice";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import { generatePayloadCondition } from "../../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling stores related functionalities

 /**
   *
   * @returns {Object}
   *   storesList: Array containing store information for all stores
   *   isLoading: Data loading state for all stores
   *   singleStore: Object contiaining store information
   *   singleStoreLoading: Data loading state for singel store information
 */
const useStoresList = (storeId) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { query } = useSelector((state) => state.search);

  const [currentPage, setCurrentPage] = useState(1);

  // get single store information from the store
  const { data: singleStore, isLoading: singleStoreLoading } = useSelector(
    (state) => state.stores.getSingleStore
  );
  // get store list for all stores from the redux store
  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.stores.getAllStores
  );

  // validation schema for add/edit store form
  const addEditStoreSchema = z.object({
    branchname: z
      .string()
      .min(3, "client_dashboard.management.stores.validations.branchname_error")
      .max(100, "client_dashboard.management.stores.validations.max_100"),
    streetname: z
      .string()
      .min(3, "client_dashboard.management.stores.validations.streetname_error")
      .max(100, "client_dashboard.management.stores.validations.max_100"),
    districtname: z
      .string()
      .min(
        3,
        "client_dashboard.management.stores.validations.districtname_error"
      )
      .max(100, "client_dashboard.management.stores.validations.max_100"),
    city: z
      .string()
      .min(3, "client_dashboard.management.stores.validations.cityname_error")
      .max(100, "client_dashboard.management.stores.validations.max_100"),
    zipcode: z.coerce
      .number({
        invalid_type_error:
          "client_dashboard.management.stores.validations.only_numbers_error",
      })
      .int()
      .min(100, {
        message:
          "client_dashboard.management.stores.validations.zipcode_error_min",
      })
      .max(999999999999, {
        message:
          "client_dashboard.management.stores.validations.zipcode_error_max",
      }),
    buildingno: z.coerce
      .number({
        invalid_type_error:
          "client_dashboard.management.stores.validations.only_numbers_error",
      })
      .int()
      .min(100, {
        message:
          "client_dashboard.management.stores.validations.buildingno_error",
      })
      .max(9999, {
        message:
          "client_dashboard.management.stores.validations.buildingno_error_max",
      }),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,

    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(addEditStoreSchema),
  });

  /**
   * Handler function for form submission to update store information.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   *                        Example: Branch name, city, zip code etc
   */

  const onSubmit = async (data) => {
    const payload = {
      branchName: data?.branchname,
      streetName: data?.streetname,
      buildingNumber: +data?.buildingno,
      districtName: data?.districtname,
      city: data?.city,
      zipCode: +data?.zipcode,
      isAccessToDashboard: true,
      isAccessToPos: true,
    };
    if (storeId && modalState?.type === "edit")
      return dispatch(
        updateStore({
          storeId,
          payload,
          successCallBack: onStoreUpdate,
        })
      );
    else {
      return dispatch(
        addStore({
          payload,
          successCallBack: onStoreUpdate,
        })
      );
    }
  };

  /**
   * This function is called when the store is updated successfully!
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  const onStoreUpdate = (data) => {
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStores({ payload }));
    dispatch(closeModal());
  };

  /**
   * This function is used to delete a store
   *
   *  @param {object} storeId The is of the store to delete
   *  @param {function} successCallBack This function will be called on the success of delete store function
   */

  const deleteStoreItem = () => {
    if (storeId)
      dispatch(deleteStore({ storeId, successCallBack: onStoreUpdate }));
  };

  /**
   * Handles the api call for getting a specific store
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} storeId id of the store
   */

  useEffect(() => {
    if (storeId) dispatch(getSingleStore({ storeId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);

  /**
   *  Extracting storeDetail object from singleStore object for store detail modal
   * @returns {Object}
   * 
   *   name: Name of the store
   *   email: Email of the store
   *   POS Fast Access Code: POS code of the store
   *   role: Role of the store
   *  Access to Dashboard: store access to Dashboard
      Access to POS: store access to POS
 */
  const storeDetail = singleStore
    ? {
        Name: singleStore.branchName,
        "Street Name": singleStore?.streetName,
        "Building NO": singleStore?.buildingNumber,
        "District Name": singleStore?.districtName,
        City: singleStore?.city,
        "ZIP Code": singleStore?.zipCode,
      }
    : null;

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["branchName", "storeCode"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllStores({ payload }));
  }, [currentPage, query, dispatch]);

  /**
   *
   * @returns {Object}
   * {
   *   simplifiedStoresList: Object containing only required store information for all stores
   *   isLoading: Data loading state for all stores
   *   singlestore: Object contiaining store information
   *   singleStoreLoading: Data loading state for singel store information
   *   storeDetail: Objecct cotnaining only required store information
   *   register: Function provided by react-hook-form to register input fields,
   *   handleSubmit: Function provided by react-hook-form to handle form submission,
   *   errors: Object Function provided by react-hook-form to handle validation errors,
   *   setValue: Object Function provided by react-hook-form to handle value changes,
   * }
   * Usage Example:
   * Accesses the form handling functions, error.
   * const { storesList, isLoading } = useStoresList();
   *
   * storesList:
   *  Example usage: {simplifiedStoresList && simplifiedStoresList.map(store => store.name)}
   * isLoading::
   *  Example usage: {isLoading  && <Loader />}
   * singleStoreLoading::
   *  Example usage: {singleStoreLoading  && <Loader />}
   * storeDetail:
   *  Example usage: {storeDetail && storeDetail.map(store => store.name)}
   * regiser:
   *  Example usage: <input {...register("email", { required: true })}
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.email && <span>Email is required</span>}
   */
  return {
    storesList,
    storeListLoading,
    singleStoreLoading,
    storeDetail,
    singleStore,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    deleteStoreItem,
    isSubmitting,
    watch,
    currentPage,
    setCurrentPage,
    clearErrors,
  };
};

export default useStoresList;
