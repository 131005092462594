import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTopAccountManager } from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { formatDateTime } from "../../../../../../helpers/dateFormatter";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useEmployessPerformance = (startDate, endDate) => {
  const dispatch = useDispatch();

  const { data: salesData, isLoading: cardListLoading } = useSelector(
    (state) => state?.dashboard?.getTopAccountManager
  );

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
    };
    dispatch(getTopAccountManager({ payload }));
  }, [startDate, endDate, dispatch]);

  return {
    salesData,
    cardListLoading,
  };
};

export default useEmployessPerformance;
