import React from "react";
import useChooseDiscount from "./useChooseDiscount";
import { Card, CustomButton, Loader } from "../../../common/components";
import { useTranslation } from "react-i18next";

function ChooseDiscount({ onSave, onCancel, selectedItems }) {
  const {
    togglePromotionSelection,
    selectedPromotion,
    promotionList,
    promotionListLoading,
    renderItemNames,
  } = useChooseDiscount();
  const { t } = useTranslation();

  // Function to handle saving promotions
  const handleSavePromotions = () => {
    const promotion = promotionList.find((p) => p.id === selectedPromotion);
    if (promotion) {
      onSave({
        name: promotion.name,
        value: promotion.value,
        promotionId: promotion.id,
      });
    }
  };

  return (
    <div className="p-3">
      <div className="font-semibold text-sm mb-4 px-1">
        {" "}
        {t("pos_dashboard.choose_promotion")}
      </div>
      <div className="h-[400px] overflow-auto grayScrollNoBg pr-1  p-3">
        {promotionListLoading ? (
          <Loader />
        ) : (
          promotionList &&
          promotionList.map((promotion) => (
            <Card
              key={promotion.id}
              className="mb-3 border flex justify-between items-center !p-3 w-full bg-white shadow-none cursor-pointer"
              onClick={() => togglePromotionSelection(promotion.id)}
            >
              <div className="flex items-center w-full">
                <input
                  type="radio"
                  name="promotionSelection"
                  value={promotion.id}
                  checked={selectedPromotion === promotion.id}
                  onChange={() => togglePromotionSelection(promotion.id)}
                  className="mr-8 ml-4"
                  style={{ accentColor: "blue" }}
                />
                <div className="w-full">
                  <div className="flex items-center justify-between w-full">
                    <span className="font-medium text-lg ">
                      {promotion.name}
                    </span>
                    <span className="text-sm font-medium items-end text-text-primary">
                      -{promotion.value + " SAR"}
                    </span>
                  </div>

                  <div className="text-sm text-text-primary">
                    <div className="text-sm text-text-primary">
                      {promotion.isAllItem
                        ? "All Item"
                        : renderItemNames(promotion.items)}
                    </div>
                    <div className="text-sm text-text-primary">
                      {promotion.startDate} | {promotion.endDate}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))
        )}
      </div>{" "}
      <div className="flex justify-end mt-4 gap-2">
        <CustomButton
          width="20px"
          text={t("pos_dashboard.cancel")}
          onClick={onCancel}
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          hoverBgColor="bg-gray-200"
        />
        <CustomButton
          type="submit"
          width="20px"
          text={t("pos_dashboard.save_promotion")}
          onClick={handleSavePromotions}
          bgColor="bg-sky-blue"
          textColor="text-white"
        />
      </div>
    </div>
  );
}

export default ChooseDiscount;
