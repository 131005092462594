import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { getAllItems } from "../../../../../features/Items/ItemsSlice";
import {
  deleteExpenseInvoice,
  getAllExpenseInvoices,
  getSingleExpenseInvoice,
} from "../../../../../features/Invoices/ExpenseInvoices/ExpenseInvoiceSlice";
import { getAllCategories } from "../../../../../features/Categories/CategoriesSlice";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { ImageIcon, PdfIcon } from "../../../../common/Icons/fontawesome";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling expense invoices related functionalities
 *
 * @returns {Object}
 *   expenseInvoicesList: Array containing expense invoices information for all invoices
 *   expenseInvoicesListLoading: Data loading state for all expense invoices
 *   singleExpenseInvoice: Object containing expense invoice information
 *   singleExpenseInvoiceLoading: Data loading state for single expense invoice information
 */
const useExpenseInvoiceList = (invoiceId, activeTab) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.search);

  // get item list for all items from the store
  const { data: itemList } = useSelector((state) => state.items.getAllItems);
  // get store list for all stores from the redux store
  const { data: storesList } = useSelector(
    (state) => state.stores.getAllStores
  );
  // get single expense invoice information from the store
  const { data: singleInvoice, isLoading: singleExpenseInvoiceLoading } =
    useSelector((state) => state.expenseInvoices.getSingleExpenseInvoice);
  // get expense invoices list for all invoices from the store
  const { data: expenseInvoicesList, isLoading: expenseInvoicesListLoading } =
    useSelector((state) => state.expenseInvoices.getAllExpenseInvoices);

  /**
   *  Extracting singleExpenseInvoice object from singleInvoice object for invoice detail modal
   * @returns {Object}
   *
   */
  const singleExpenseInvoice = singleInvoice
    ? {
        "Expense Name": singleInvoice?.name ?? "-",
        "Invoice Date": singleInvoice?.supplyDate ?? "-",
        Store: singleInvoice?.store?.branchName ?? "-",
        "Payment Method": singleInvoice?.paymentMethod ?? "-",
        "Payment Status": singleInvoice?.paymentStatus ?? "-",
      }
    : null;
  /**
   *  Extracting expenseDetails object from singleInvoice object for invoice detail modal
   * @returns {Object}
   *
   */
  let fileType = singleInvoice?.attachment?.name?.split(".").pop();

  const FileIcon = {
    pdf: PdfIcon,
    jpeg: ImageIcon,
    jpg: ImageIcon,
  }[fileType];

  const AttachmentComponent = () => (
    <div className="flex items-center gap-3">
      <div className="flex items-center justify-center w-10 h-10 bg-slate-200 rounded-lg">
        {FileIcon && <FileIcon />}
      </div>
      <div className="flex flex-col">
        <span>{singleInvoice && singleInvoice?.attachment?.name}</span>
        <span>{singleInvoice && singleInvoice?.attachment?.size} KB</span>
      </div>
    </div>
  );
  const expenseDetails = singleInvoice
    ? {
        "Expense Category":
          singleInvoice?.expenseInvoiceItem?.expenseCategory ?? "-",
        Amount: `${singleInvoice?.expenseInvoiceItem?.amount} SAR` ?? "-",
        "Before Tax":
          `${singleInvoice?.expenseInvoiceItem?.beforeTax} SAR` ?? "-",
        "Tax Amount":
          `${singleInvoice?.expenseInvoiceItem?.taxAmount} SAR` ?? "-",
        Total: `${singleInvoice?.expenseInvoiceItem?.total} SAR` ?? "-",
        Attachment: singleInvoice?.attachment?.name
          ? [{ Comp: () => <AttachmentComponent /> }]
          : "-",
        Note: singleInvoice?.expenseInvoiceItem?.note ?? "-",
      }
    : null;

  useEffect(() => {
    let searchParams = ["invoiceNumber", "createdBy"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(
        searchParams,
        query,
        activeTab?.title === "All" ? {} : { paymentStatus: activeTab.title }
      ),
      attributes: {},
    };
    dispatch(getAllExpenseInvoices({ payload }));
  }, [activeTab.title, currentPage, query, dispatch]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(getSingleExpenseInvoice({ invoiceId: invoiceId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  /**
   * Handles the api call for getting all categories
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting categories
   *    order: order for sorting categories by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCategories({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all items
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 100,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteInvoice = () => {
    if (invoiceId) {
      dispatch(
        deleteExpenseInvoice({
          invoiceId: invoiceId,
          successCallBack: onInvoiceSuccess,
        })
      );
    }
  };

  const onInvoiceSuccess = () => {
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllExpenseInvoices({ payload }));
    dispatch(closeModal());
  };

  return {
    expenseInvoicesList,
    expenseInvoicesListLoading,
    singleExpenseInvoiceLoading,
    singleExpenseInvoice,
    itemList,
    expenseDetails,
    storesList,
    singleInvoice,
    currentPage,
    setCurrentPage,
    deleteInvoice,
  };
};

export default useExpenseInvoiceList;
