import React from "react";
import { Badge } from "../../../common/components/Badge/Badge";
import CustomSearch from "../../../common/components/CustomSearch/CustomSearch";
import useCategoriesList from "./useCategories";
import { Loader } from "../../../common/components";
import { useTranslation } from "react-i18next";

const Categories = ({ onCategorySelected }) => {
  const {
    categoryListLoading,
    handleSearchChange,
    searchTerm,
    filteredCategories,
  } = useCategoriesList();
  const handleClickCategory = (categoryId) => {
    onCategorySelected(categoryId);
  };
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold text-text-primary">
          {t("pos_dashboard.categories")} (
          {categoryListLoading ? "-" : filteredCategories?.length})
        </h2>
        <CustomSearch
          placeholder={t("pos_dashboard.Search_item")}
          inputName="search"
          value={searchTerm}
          onChange={handleSearchChange}
          paddingY="py-1"
        />
      </div>

      {categoryListLoading ? (
        <Loader />
      ) : filteredCategories?.length === 0 ? (
        <p className="p-8">No categories found.</p>
      ) : (
        <div className=" overflow-auto h-[130px] grayScrollNoBg pr-2">
          <div className="grid xl:grid-cols-3 grid-cols-2 gap-3 mt-3">
            {filteredCategories?.map((category, index) => (
              <div key={index} className="w-full">
                <div
                  onClick={() => handleClickCategory(category.id)}
                  className={`flex flex-col p-3 gap-3 rounded-xl shadow text-center hover:opacity-90 cursor-pointer transition duration-300 ease-in-out ${category.bgClass}`}
                >
                
                  <div className="flex justify-between items-center w-full">
                    <h6 className={`${category?.textClass}`}>
                      {category?.name}
                    </h6>
                    <Badge bgColor="bg-black/20" className="text-white">
                      {category?.count}
                    </Badge>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Categories;
