import React, { useEffect } from "react";
import useStorage from "../../components/common/utils/localStorage.util";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    useStorage.clearStorage();
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default Logout;
