import React, { useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { InputGroup, CustomButton } from "../../../../../common/components";
import { CrossIcon } from "../../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import {
  getPaymentMethods,
  addPaymentMethods,
  getSinglePaymentMethod,
} from "../../../../../../features/Payments/PaymentsSlice";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const EditCardMethod = ({ selected, cardsList, selectedCards }) => {
  const dispatch = useDispatch();
  const selectedCardValues = useMemo(
    () => new Set(selectedCards ? Object.values(selectedCards) : []),
    [selectedCards]
  );

  // validation schema for card payment
  const cardPaymentSchema = z
    .object({
      // Dynamically create the schema for each card
      ...cardsList?.reduce((acc, card) => {
        acc[card.cardName] = z.boolean();
        return acc;
      }, {}),
    })
    .refine(
      (data) => {
        // Check if at least one card is selected
        return Object.values(data).some((value) => value);
      },
      {
        message: "Please select at least one card to continue!",
        path: ["cardSelection"], // Specify a path for the error
      }
    );

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(cardPaymentSchema),
    defaultValues: useMemo(
      () =>
        cardsList?.reduce((acc, card) => {
          acc[card.cardName] = selectedCardValues.has(card.cardName); // Checks if the cardName is in the set of selected card values
          return acc;
        }, {}),
      [cardsList, selectedCardValues]
    ),
  });

  const { title, isModalOpen } = useSelector((state) => state.paymentsModal);

  useEffect(() => {
    // Dynamically set the default values for checkboxes
    cardsList.forEach((card) => {
      setValue(card.cardName, selectedCardValues.has(card.cardName));
    });
  }, [cardsList, selectedCardValues, setValue]);

  const onSubmit = (data) => {
    const selectedCards = Object.keys(data)
      .filter((key) => data[key])
      .map((key) => ({
        cardName: key,
        cardImage: `path/to/${key.toLowerCase().replace(/\s/g, "")}/image`,
      }));

    const payload = {
      paymentType: "CARD",
      paymentCard: selectedCards,
    };

    return dispatch(
      addPaymentMethods({
        id: selected.id,
        type: "update",
        payload: payload,
        successCallBack: onAddPaymentMethod,
      })
    );
  };

  const onAddPaymentMethod = (data) => {
    dispatch(getSinglePaymentMethod({ id: selected?.id }));
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-2 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5 space-y-3">
              <div className="space-y-6">
                <p className="font-medium text-sm to-text-primary !mb-8">
                  Choose method allowed in your system
                </p>
                {cardsList?.map((card) => (
                  <div className="flex items-center gap-2" key={card.cardName}>
                    <img
                      src={card.cardImage}
                      alt={card.cardName}
                      width={25}
                      loading="lazy"
                    />
                    <InputGroup
                      inputType="checkbox"
                      inputName={card.cardName}
                      labelText={card.cardName}
                      htmlFor={card.cardName}
                      register={register}
                      errors={errors}
                      className="flex justify-between items-center !mt-0 w-full"
                    />
                  </div>
                ))}
                {errors.cardSelection && (
                  <p className="text-red-500 text-sm">
                    {errors.cardSelection.message}
                  </p>
                )}
              </div>
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                text={"Save"}
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditCardMethod;

// sample usage
// import EditCardMethod from "./path/to/EditCardMethod.jsx";

// <EditCardMethod />
