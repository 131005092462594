import { useState } from "react";
import { useForm } from "react-hook-form";
import { createClientSubscription } from "../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useUserContext } from "../../../../context/UserContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useSetPlan = () => {
  const [error, setError] = useState({});

  // State to keep track of the number of branches
  const [showFinish, setShowFinish] = useState(false);
  const { user } = useUserContext();

  const validateCreditCardNumber = (number) => {
    // Same implementation as provided before
    // Make sure it handles the validation logic correctly
    const trimmed = number.replace(/\s+/g, "");
    if (trimmed.length < 13 || trimmed.length > 19) return false;

    let sum = 0;
    let shouldDouble = false;
    for (let i = trimmed.length - 1; i >= 0; i--) {
      let digit = parseInt(trimmed.charAt(i));
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
  };

  const setPlanSchema = z.object({
    cardNumber: z
      .string()
      .min(8, { message: "Card number must be at least 8 digits long!" })
      .max(19, { message: "Card number must be no more than 19 digits long!" }),
    // .refine(validateCreditCardNumber, {
    //   message: "Invalid credit card number!",
    // }),
    cvv: z
      .string()
      .min(3, { message: "CVV must be at least 3 digits long!" })
      .max(4, { message: "CVV must be no more than 4 digits long!" }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(setPlanSchema),
  });

  const [selectedPlan, setSelectedPlan] = useState("Basic");
  const [activeTab, setActiveTab] = useState("MONTHLY");
  const [branchCount, setBranchCount] = useState(1); // Default branch count is set to 1
  const planId =
    selectedPlan === "Basic" ? 1 : selectedPlan === "Professional" ? 2 : 3;
  let price, additionalBranchPrice, discountPrice;
  additionalBranchPrice = (activeTab === "MONTHLY" ? 71 : 850) * branchCount;
  discountPrice = 0;

  switch (activeTab) {
    case "MONTHLY":
      switch (selectedPlan) {
        case "Basic":
          price = 84;
          break;
        case "Professional":
          price = 118;
          break;
        case "Enterprise":
          price = 180;
          break;
        default:
          price = 0;
      }
      break;
    case "ANNUAL":
      switch (selectedPlan) {
        case "Basic":
          price = 1008;
          break;
        case "Professional":
          price = 1416;
          break;
        case "Enterprise":
          price = 2160;
          break;
        default:
          price = 0;
      }
      discountPrice = Math.floor(0.2 * (price + additionalBranchPrice)); // Applying a 20% discount for annual payments
      break;
    default:
      price = 0;
  }

  const totalPriceBeforeTax = price + additionalBranchPrice - discountPrice;
  const tax = Math.floor(totalPriceBeforeTax * 0.15); // Applying 15% tax on the total price before tax
  const totalPrice = totalPriceBeforeTax + tax;

  // Function to trigger the finish screen display
  const handleButtonClick = (response) => {
    setShowFinish(true);
  };

  function extractErrorMessages(errorObject) {
    const errorMessages = [];
    if (errorObject && typeof errorObject === "object" && errorObject.errors) {
      for (const [key, messages] of Object.entries(errorObject.errors)) {
        // Assuming each field could have more than one error message
        messages.forEach((message) => {
          errorMessages.push(`${key}: ${message}`);
        });
      }
    }
    return errorMessages;
  }

  /**
   * Handles form submission.
   * Validates image upload, creates FormData object with form data, and dispatches the createAccount action.
   * @param {object} data - Form data collected from the form fields.
   */
  const onSubmit = async (data) => {
    try {
      const apiUrl = "https://api.moyasar.com/v1/payments";
      const userId = user?.id;
      const metadata = {
        userId: userId,
        plan_tenure: activeTab,
        planId,
        branches: branchCount,
        totalBeforeTax: totalPriceBeforeTax,
        totalDiscount: discountPrice,
        taxAmount: tax,
        subTotal: totalPrice,
      };
      const paymentData = {
        amount: totalPrice,
        currency: "SAR",
        description: "amount to be paid for. It is optional",
        callback_url: "http://localhost:3000/thank-you",
        source: {
          type: "creditcard",
          name: user?.profile?.fullName,
          number: data?.cardNumber,
          cvc: data?.cvv,
          month: "1",
          year: "26",
        },
      };
      const username = "sk_test_C8fHbZ9baJbNtfnp67wiZsdK5btpccqqNW6uyNTt";
      const password = "";
      const credentials = `${username}:${password}`;
      const encodedCredentials = btoa(credentials);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${encodedCredentials}`,
        },
        body: JSON.stringify({ metadata, ...paymentData }),
      });

      if (!response.ok) {
        setError(await response.json());

        throw new Error("Failed to make payment");
      }

      const responseData = await response.json();
      console.log(responseData);
      const transactionUrl = responseData.source.transaction_url;
      window.location.href = transactionUrl;
    } catch (error) {
      console.error(`Failed to make payment: ${error}`);
    }
  };
  let apiError = extractErrorMessages(error);

  /**
   * Custom hook for managing the plan selection and branch count functionality.
   *
   * @returns {Object} An object containing methods and states for plan selection and branch count handling.
   * {
   *   register: Function - Registers form inputs for validation and usage with react-hook-form,
   *   handleSubmit: Function - Handles form submission using react-hook-form,
   *   onSubmit: Function - Handles the actual form submission event,
   *   increment: Function - Increments the branch count,
   *   decrement: Function - Decrements the branch count,
   *   selectPlan: Function - Selects a plan,
   *   handleButtonClick: Function - Handles the logic to be executed when a button is clicked, e.g., to show a finish screen,
   *   errors: Object - Holds form validation errors from react-hook-form,
   *   branchCount: Number - The current count of branches,
   *   selectedPlan: String|null - The currently selected plan,
   *   showFinish: Boolean - State to control the display of the finish screen.
   * }
   * Usage Example:
   * Accesses form handling methods and plan selection functionality:
   * const { register, handleSubmit, onSubmit, increment, decrement, selectPlan, handleButtonClick, errors, branchCount, selectedPlan, showFinish } = useSetPlan();
   *
   * register:
   *  Example usage: <input {...register("fullName", { required: true })} />
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * increment:
   *  Example usage: <button onClick={increment}>+</button>
   * decrement:
   *  Example usage: <button onClick={decrement}>-</button>
   * selectPlan:
   *  Example usage: <div onClick={() => selectPlan('Basic')}>Basic Plan</div>
   * handleButtonClick:
   *  Example usage: <button onClick={handleButtonClick}>Finish</button>
   * errors:
   *  Example usage: {errors.fullName && <span>Full name is required</span>}
   * branchCount:
   *  Example usage: <span>{branchCount}</span>
   * selectedPlan:
   *  Example usage: {selectedPlan && <div>Selected Plan: {selectedPlan}</div>}
   * showFinish:
   *  Example usage: {showFinish && <FinishScreen />}
   */

  return {
    onSubmit,
    handleSubmit: handleSubmit(onSubmit),
    register,
    errors,
    showFinish,
    handleButtonClick,
    isSubmitting,
    setBranchCount,
    branchCount,
    setActiveTab,
    activeTab,
    setSelectedPlan,
    selectedPlan,
    additionalBranchPrice,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    discountPrice,
    error,
    apiError,
  };
};

export default useSetPlan;
