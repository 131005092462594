import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscription,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useEffect, useState } from "react";
import { getAllStaffUsers } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

export const useAddNewSubscription = ({ closeModal }) => {
  const dispatch = useDispatch();
  const businessInfoSchema = z.object({
    companyname: z
      .string()
      .min(3, {
        message:
          "Company name is required and should be at least 3 characters!",
      })
      .max(100, { message: "Company name should not exceed 100 characters!" }),
    companyaddress: z
      .string()
      .min(3, {
        message:
          "Company address is required and should be at least 3 characters!",
      })
      .max(100, {
        message: "Company address should not exceed 100 characters!",
      }),
    fullname: z
      .string()
      .min(3, {
        message: "Full name is required and should be at least 3 characters!",
      })
      .max(100, { message: "Full name should not exceed 100 characters!" }),
    arabicname: z
      .string()
      .min(3, {
        message: "Arabic name is required and should be at least 3 characters!",
      })
      .max(100, { message: "Arabic name should not exceed 100 characters!" }),
    website: z
      .string()
      .min(3, "website is required!")
      .max(100, { message: "Website should not exceed 100 characters!" }),
    commercialregistration: z
      .string()
      .min(3, {
        message:
          "Commercial registration is required and should be at least 3 characters!",
      })
      .max(100, {
        message: "Commercial registration should not exceed 100 characters!",
      }),
    vat: z
      .string()
      .length(15, { message: "VAT Number must be 15 digits long." }),
    subscriptionprovider: z.coerce
      .number()
      .min(1, {
        message:
          "Subscription provider is required and should be a valid number!",
      }),
    phonenumber: z
      .string()
      .min(3, { message: "Phone Number must be at least 3 digits long!" })
      .max(20, { message: "Phone Number should not exceed 20 digits!" })
      .regex(/^\+\d+$/, {
        message:
          "Phone Number must start with + and be followed by numeric digits!",
      }),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters long!" })
      .regex(/^(?=.*[A-Z])/, {
        message: "Password must contain an uppercase letter!",
      })
      .regex(/[!@#$%^&*(),.?":{}|<>]/, {
        message: "Password must contain a special character!",
      })
      .regex(/\d/, { message: "Password must contain a number!" }),
    startDate: z.string().min(1, { message: "Start date is required!" }),
    email: z
      .string()
      .email({ message: "Invalid email format!" })
      .max(100, { message: "Email should not exceed 100 characters!" }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      startDate: new Date().toISOString().substring(0, 10), // setting default date to current date in YYYY-MM-DD format
    },
    resolver: zodResolver(businessInfoSchema),
  });
  const [selectedPlan, setSelectedPlan] = useState("Basic");
  const [activeTab, setActiveTab] = useState("MONTHLY");
  const [branchCount, setBranchCount] = useState(0);
  const planId =
    selectedPlan === "Basic" ? 1 : selectedPlan === "Professional" ? 2 : 3;

  let price, additionalBranchPrice, discountPrice;
  additionalBranchPrice = (activeTab === "MONTHLY" ? 70 : 850) * branchCount;
  discountPrice = 0;

  if (activeTab === "MONTHLY") {
    switch (selectedPlan) {
      case "Basic":
        price = 84;
        break;
      case "Professional":
        price = 110;
        break;
      case "Enterprise":
        price = 180;
        break;
    }
  } else if (activeTab === "ANNUAL") {
    switch (selectedPlan) {
      case "Basic":
        price = 1008;
        break;
      case "Professional":
        price = 1416;
        break;
      case "Enterprise":
        price = 2160;
        break;
    }
    discountPrice = Math.floor(0.2 * (price + additionalBranchPrice)); // Applying a 20% discount for annual payments
  }

  const totalPriceBeforeTax = price + additionalBranchPrice - discountPrice;
  const tax = Math.floor(totalPriceBeforeTax * 0.15); // Applying 15% tax on the total price before tax
  const totalPrice = totalPriceBeforeTax + tax;
  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, []);
  const { data: staffUser } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );

  const staffUserOptions = [
    { value: "", label: "Select Account Manager" },
    ...(staffUser
      ?.filter((user) => user.role === "ACCOUNT_MANAGER")
      .map((user) => ({
        value: user.id,
        label: user.fullName,
      })) || []),
  ];

  const onSubmit = (data) => {
    const subscriptionData = {
      englishName: data.fullname,
      arabicName: data.arabicname,
      phoneNumber: data.phonenumber,
      email: data.email,
      password: data.password,
      companyName: data.companyname,
      companyAddress: data.companyaddress,
      website: data.website,
      commercialRegistration: data.commercialregistration,
      vatNumber: parseInt(data.vat, 10),
      planId: planId,
      planTenure: activeTab,
      startDate: data.startDate,
      branches: branchCount,
      managedBy: parseInt(data.subscriptionprovider, 10),
      totalBeforeTax: totalPriceBeforeTax,
      totalDiscount: discountPrice,
      vatAmount: tax,
      subTotal: totalPrice,
    };

    dispatch(
      createSubscription({
        payload: subscriptionData,
        successCallBack: handleSuccess,
      })
    );
  };
  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };
  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isSubmitting,
    staffUserOptions,
    selectedPlan,
    setSelectedPlan,
    activeTab,
    setActiveTab,
    branchCount,
    setBranchCount,
    totalPrice,
    tax,
    price,
    totalPriceBeforeTax,
    additionalBranchPrice,
    discountPrice,
  };
};

export default useAddNewSubscription;
