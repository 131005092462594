import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../../features/Categories/CategoriesSlice";
import { getAllItems, getPosItems } from "../../../../features/Items/ItemsSlice";

/**
 * Custom hook for handling categories related functionalities.
 *
 * @returns {Object}
 *   categoryList: Array containing category information for all categories
 *   isLoading: Data loading state for all categories
 *   singleCategory: Object containing category information
 *   singleCategoryLoading: Data loading state for single category information
 */
const useCategoriesList = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const { data: categoryList, isLoading: categoryListLoading } = useSelector(
    (state) => state?.categories?.getAllCategories
  );
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const id = storedUserData?.user?.store?.id;

  useEffect(() => {
    // Payload for categories remains unchanged
    const categoryPayload = {
      page: 1,
      pageSize: 1000,
      sortColumn: 'id',
      order: {
        id: 'DESC',
      },
      condition: {
        itemDetails: {
          stores: {id: id},
        },
      },
      attributes: {},
    }
    if (!categoryList) dispatch(getAllCategories({ payload: categoryPayload }));
  }, [dispatch, categoryList]);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }
  const bgClasses = [
    "bg-sky-blue",
    "bg-bright-cerulean",
    "bg-aqua-marine",
    "bg-surface-gray",
    "bg-info-300",
    "bg-black-pearl",
  ];
  const textClasses = [
    "text-white",
    "text-white",
    "text-black",
    "text-black",
    "text-white",
    "text-white",
  ];

  useEffect(() => {
    if (!categoryListLoading && categoryList) {
      const initialFilter = debouncedSearchTerm
        ? categoryList.filter((category) =>
          category.englishName
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase())
        )
        : categoryList;
      const filtered = initialFilter?.map((category, index) => ({
        name: category.englishName || "-",
        id: category.id || "-",
        count: category?.itemDetailsCount || "0",
        icon: category?.image,
        bgClass: bgClasses[index % bgClasses.length],
        textClass: textClasses[index % textClasses.length],
      }));
      setFilteredCategories(filtered);
    }
  }, [categoryList, categoryListLoading, debouncedSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return {
    categoryList,
    categoryListLoading,
    handleSearchChange,
    filteredCategories,
    searchTerm,
  };
};
export default useCategoriesList;
