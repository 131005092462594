import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import CustomDatePicker from "../../../../../common/components/CustomDatePicker/CustomDatePicker";
import useEmployessPerformance from "./useEmployessPerformance";
import { Loader } from "../../../../../common/components";

const EmployessPerformance = () => {
  const [startDate, setStartDate] = useState(new Date(2000, 0, 1));
  const [endDate, setEndDate] = useState(new Date(2024, 5, 24));
  const { salesData, cardListLoading } = useEmployessPerformance(
    startDate,
    endDate
  );

  const filteredData = salesData?.filter((item) => item.managerId !== null);
  const hasData = filteredData && filteredData?.length > 0;

  const data = {
    labels: hasData
      ? filteredData.map((item) => item.managerName)
      : ["No Data"],
    datasets: [
      {
        label: " ",
        data: hasData
          ? filteredData.map((item) => item.subscriptionCount)
          : [0],
        backgroundColor: [
          "#5BBAC0",
          "#DC67CE",
          "#1D96FF",
          "#53B1FD",
          "#2BE3B7",
        ],
        borderColor: ["#5BBAC0", "#DC67CE", "#1D96FF", "#53B1FD", "#2BE3B7"],
        borderWidth: 4,
        barPercentage: 0.4,
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: hasData,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
      y: {
        barPercentage: 0.8,
        categoryPercentage: 0.6,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      {cardListLoading ? (
        <div className="items-center flex justify-center py-44">
          <Loader />
        </div>
      ) : (
        <>
          <p className="flex justify-center mt-2 text-text-primary font-medium text-base">
            Top Account Managers
          </p>
          <div className="flex justify-end mb-2">
            <div className="max-w-60 mt-2 items-end flex">
              <CustomDatePicker
                initialStartDate={startDate}
                initialEndDate={endDate}
                onChange={(selection) => {
                  if (selection) {
                    setStartDate(selection.startDate);
                    setEndDate(selection.endDate);
                  }
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <Bar data={data} options={options} />
          </div>
        </>
      )}
    </div>
  );
};

export default EmployessPerformance;
