/**
 * InputLabel component.
 * Renders a label for an input field.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.className - Additional CSS classes for the label.
 * @param {string} props.htmlfor - The HTML for attribute.
 * @param {string} props.text - The text to be displayed as the label.
 * @returns {JSX.Element} JSX code for rendering the InputLabel component.
 */

import { useTranslation } from "react-i18next";

const InputLabel = ({
  className = "",
  htmlfor = "",
  text = "",
  extraLabel = "",
}) => {
  const { t } = useTranslation();
  return (
    <label
      htmlFor={htmlfor}
      className={`block text-sm font-medium text-text-primary ${className}`}
    >
      {t(text)}
      {extraLabel && (
        <span className="text-neutral-400 text-xs ml-1">{extraLabel}</span>
      )}
    </label>
  );
};

export default InputLabel;

// Sample Usage:
// import InputLabel from '.path/InputLabel';
// <InputLabel
//   htmlFor="email"
//   text="Email"
// />
