/**
 * helper function to check any changes in input fields

 /**
   * @returns {Boolean}
 **/

export const checkInputChanges = (defaultValues, currentValues) => {
  return Object.keys(defaultValues).some(
    (key) =>
      JSON.stringify(defaultValues[key]) !== JSON.stringify(currentValues[key])
  );
};

// sample usage

// import checkInputChanges from "./path/to/checkInputChanges"
//useEffect(() => {
// Check if the current state is different from the initial state
// setIsInputChanged(checkInputChanges(currentValues, defaultValues));
// eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [currentValues]);
