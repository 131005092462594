import React from "react";
import { useTranslation } from "react-i18next";
import { usePosProcessingOrder } from "./usePosProcessingOrder";
import CustomPosTable from "../../../common/components/PosTable/PosTable";
import CustomAdminModal from "../../../common/components/AdminModal/CustomAdminModal";
import CustomerOrderDetail from "../CustomerOrderDetail.jsx/CustomerOrderDetail";
import CustomSearch from "../../../common/components/CustomSearch/CustomSearch";

function PosProcessingOrder({
  selectedItem,
  setSelectedItem,
  isCheckoutModalOpen,
  setIsCheckoutModalOpen,
}) {
  const {
    isOpen,
    headers,
    tableBody,
    closeModal,
    openModal,
    fullName,
    branchName,
    currentDate,
    handleTabChange,
    filterStatus,
    dropdownOptionsWithActions,
    orderListLoading,
    setSearchText, 
    duplicateOrder,
    refundOrder
  } = usePosProcessingOrder(setSelectedItem);
  const invoiceNumber = selectedItem?.invoiceNumber;

  const { t } = useTranslation();
  const tabOptions = ["All", "On Process", "Refund", "Completed"];

  return (
    <div className="p-3 bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text-primary">
          {t("pos_dashboard.orders")}
        </h2>
        <span className="flex justify-between gap-2">
          <CustomSearch
            placeholder={t("pos_dashboard.Search_item")}
            inputName="search"
            paddingY="py-2"
            className="!w-12"
            onChange={(e) => setSearchText(e.target.value)} 
          />
        </span>
      </div>

      <div className="flex mb-2">
        {tabOptions?.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabChange(tab)}
            className={`px-4 py-2 ${
              filterStatus === "All" && tab === "All"
                ? "text-blue-500 border-b-2 border-blue-400"
                : filterStatus === tab.replace(" ", "_").toUpperCase()
                ? "text-blue-500 border-b-2 border-blue-400"
                : "text-gray-500"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="font-medium min-h-[280px] max-h-[280px] overflow-auto">
        <CustomPosTable
          tableHeader={headers}
          tableBody={tableBody}
          loading={orderListLoading}
          showActionsColumn={true}
          openModal={openModal}
          dropdownOptions={dropdownOptionsWithActions}
          setSelectedItem={(order) => setSelectedItem(order)}
        />
      </div>
      <CustomAdminModal
        title={invoiceNumber ? ` #${invoiceNumber}` : "Order Details"}
        isOpen={isOpen}
        onClose={closeModal}
        width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
        subtitleText={{
          value: `${currentDate} · ${branchName} · ${fullName}`,
          show: true,
        }}
      >
        <CustomerOrderDetail
          selectedItem={selectedItem}
          setIsCheckoutModalOpen={setIsCheckoutModalOpen}
          closeModal={closeModal}
          isCheckoutModalOpen={isCheckoutModalOpen}
          duplicateOrder={duplicateOrder}
          refundOrder={refundOrder}

        />
      </CustomAdminModal>
    </div>
  );
}

export default PosProcessingOrder;
