import React, { useState } from "react";
import { Card, CustomButton } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import {
  addPosOrder,
  handleCheckoutData,
} from "../../../../../features/PosOrder/PosOrderSlice";
import { useDispatch } from "react-redux";

function SaveOrder({
  activeTab,
  selectedItems,
  selectedPromotions,
  selectedCustomer,
  selectedTables,
  vat,
  grandTotal,
  subtotal,
  toggleSaveOrderModal,
  updateItemTotal,
  storeId,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSaveOrder = () => {
    const payload = {
      tableId: selectedTables?.id || undefined,
      customerId: selectedCustomer?.id,
      storeId,
      orderType: activeTab?.value,
      orderedItems: selectedItems.map((s) => ({
        quantity: +s?.quantity || 1,
        note: s?.note || "",
        // "promotionId": 1,
        itemId: s?.id,
        vItemId: s?.vItemId || undefined,
      })),
    };
    dispatch(addPosOrder({ payload, successCallBack: handleSuccess }));
  };
  const handleSuccess = (response) => {
    dispatch(handleCheckoutData(response?.data?.data));
    toggleSaveOrderModal();
  };
  const [showNotes, setShowNotes] = useState({}); // Use an object to track notes for each item

  const toggleNotes = (id) => {
    setShowNotes((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="p-3">
      <div className="font-semibold text-sm">Order Detail </div>
      {/* Order Details */}
      {selectedItems && selectedItems?.length > 0 ? (
        <>
          {/* Order Details */}
          <Card className="mb-4">
          <div className="flex flex-col h-36 overflow-auto max-h-[200px] min-h-[12px] pr-1 grayScrollNoBg">
          {selectedItems.map((item) => {
            const quantity = Math.abs(item?.quantity ?? 1);  // Ensure quantity is non-negative
            const pricePerUnit = Math.abs(item?.price ?? item?.salePrice ?? 0);  // Ensure price per unit is non-negative
            const totalPrice = quantity * pricePerUnit;
        
            return (
              <div
                key={item.id}
                className="flex justify-between items-center py-2"
              >
                <div className="flex items-center">
                  <img
                    src={item?.image ?? item?.item?.image}
                    alt={item?.englishName ?? item?.item?.englishName}
                    className="h-12 w-12 object-cover mr-2"
                  />
                  <div>
                    <div className="font-semibold text-text-primary">
                      {item?.englishName ?? item?.item?.englishName}
                    </div>
                    <div className="text-gray-600 text-sm">
                      {`${quantity} x - ${
                        item?.selections
                          ? Object.values(item?.selections).join(", ")
                          : "item"
                      }`}
                    </div>
                    {item.note && (
                      <div className="flex flex-col items-start ">
                        <button
                          className="text-text-link text-sm font-medium px-1"
                          onClick={() => toggleNotes(item.id)}
                        >
                          {showNotes[item.id] ? "Hide Notes" : "See Notes"}
                        </button>
                        {showNotes[item.id] && (
                          <div className="flex items-center gap-2">
                            <textarea
                              placeholder="No Note"
                              className="w-32 h-8 rounded border"
                              value={item.note}
                              readOnly
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-text-primary text-sm font-semibold">
                  {totalPrice.toFixed(2)} SAR
                </div>
              </div>
            );
          })}
        </div>
        
          </Card>
          {/* Additional Details and Customer Info */}
        </>
      ) : (
        <div className="text-center font-semibold text-red-500 my-4 border p-4 rounded-lg">
          Please Add Item to Save Order
        </div>
      )}

      {/* Customer */}
      <Card className="mb-4 p-3">
        <div className="font-semibold mb-2 text-sm">
          {t("pos_dashboard.customer")}
        </div>
        <div className="text-gray-600">{selectedCustomer?.name ?? "-"}</div>
      </Card>

      {/* Service Type */}
      <Card className="mb-4 p-4">
        <div className="font-semibold mb-2 text-sm">
          {t("pos_dashboard.service_type")}
        </div>
        <div className="text-gray-600">{activeTab?.label}</div>
      </Card>

      {/* Table Type */}
      {activeTab?.value !== "TAKEAWAY" && (
        <Card className="mb-4 p-4">
          <div className="font-semibold mb-2 text-sm">
            {t("pos_dashboard.selected_tables")}
          </div>
          <div className="text-gray-600">
            Table No:{selectedTables?.position}
          </div>
        </Card>
      )}

      {/* Payment Method*/}
      <Card className="mb-4 p-4">
        <div className="font-semibold mb-2 text-sm">
          {t("pos_dashboard.payments")}{" "}
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <span>{t("pos_dashboard.sub_total")}</span>
          <span>{subtotal.toFixed(2)} SAR</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span>{t("pos_dashboard.discount_applied")}</span>
          <span>10 SAR</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span>{t("pos_dashboard.vat")}</span>
          <span>{vat.toFixed(2)} SAR</span>
        </div>
        <div className="flex justify-between font-semibold text-sm">
          <span>{t("pos_dashboard.total")}</span>
          <span>{grandTotal.toFixed(2)} SAR</span>
        </div>
      </Card>
      <div className="flex justify-end mt-2 gap-2">
        <CustomButton
          width="20px"
          text={t("pos_dashboard.cancel")}
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          hoverBgColor="bg-gray-200"
          onClick={() => toggleSaveOrderModal()}
        />
        <CustomButton
          type="submit"
          width="20px"
          text={t("pos_dashboard.save_order")}
          onClick={handleSaveOrder}
          bgColor="bg-sky-blue"
          textColor="text-white"
        />
      </div>
    </div>
  );
}

export default SaveOrder;
