/**
 * Manages state for categories.
 * Defines initial state for each order action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showToast } from "../../components/common/utils/showToast.util";
import createPosOrdersApi from "./PosOrderService";

/**
 * Initial state structure defining various properties related to order processes.
 * Each property represents a specific order action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllPosOrders, getSinglePosOrder, updatePosOrder, addPosOrder, and deletePosOrder
 * store data, error, success, loading, and message status for corresponding order actions.
 */
const initialState = {
  orderCheckoutData: null,
  orderDuplicateData: null,

  getAllPosOrders: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSinglePosOrder: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updatePosOrder: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addPosOrder: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deletePosOrder: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  checkOutOrder: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  returnPosOrder: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the order process.
 * These action types are prefixed with the 'categories' base path for better organization.
 */
const BASE = "orders";

export const ActionTypes = {
  GET_ALL_POS_ORDER: `${BASE}/get-all`, // Action type for get all categories
  ADD_POS_ORDER: `${BASE}`, // Action type for add order
};

// Creating an instance of the categories service with a base URL 'categories'
const posOrderService = createPosOrdersApi("orders");

/**
 * Initiates the getAllPosOrders process for a order.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting categories
 *    order: order for sorting categories by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllPosOrders.
 */
export const getAllPosOrders = createAsyncThunk(
  ActionTypes.GET_ALL_POS_ORDER,

  async ({ payload }, thunkAPI) => {
    const response = await posOrderService.getAllPosOrders(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getSinglePosOrder process for a order.
 * @param {object} orderId - id of the order
 * @param {function} successCallBack - Callback function upon successful getSinglePosOrder.
 */
export const getSinglePosOrder = createAsyncThunk(
  `${BASE}/itemId`,

  async ({ itemId, successCallBack }, thunkAPI) => {
    try {
      const response = await posOrderService.getSinglePosOrder(itemId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Successfull!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the updatePosOrder process for a order.
 * @param {object} orderId - id of the order
 * @param {object} payload - order data to update
 * @param {function} successCallBack - Callback function upon successful updatePosOrder.
 */
export const updatePosOrder = createAsyncThunk(
  `${BASE}/orderId`,

  async ({ orderId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posOrderService.updatePosOrder(orderId, payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Order updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPosOrder process for a order.
 * @param {object} payload - order data to add
 * {
  "name": "order Name",
  "description": "order Description"
}
 * @param {function} successCallBack - Callback function upon successful addPosOrder.
 */
export const addPosOrder = createAsyncThunk(
  ActionTypes.ADD_POS_ORDER,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posOrderService.addPosOrder(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Order added successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deletePosOrder process for a order.
 * @param {object} orderId - id of the order
 * @param {function} successCallBack - Callback function upon successful deletePosOrder.
 */
export const deletePosOrder = createAsyncThunk(
  `${BASE}/orderId`,

  async ({ orderId, successCallBack }, thunkAPI) => {
    try {
      const response = await posOrderService.deletePosOrder(orderId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("order deleted successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const checkOutOrder = createAsyncThunk(
  `/checkout`,
  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posOrderService.checkOutOrder(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Order Payed successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

export const returnPosOrder = createAsyncThunk(
  "returnPosOrder",

  async ({ orderId, successCallBack }, thunkAPI) => {
    try {
      const response = await posOrderService.returnPosOrder(orderId);
      if (response?.data?.Succeeded) {
        showToast("Order Returned Successfully !");
        successCallBack(response);
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);
/**
 * Creates a slice for categories related state management.
 * - `name`: Name of the slice (categories)
 * - `initialState`: Initial state defining properties for various order actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllPosOrders, updatePosOrder etc).
 */
export const posOrderSlice = createSlice({
  name: "posOrder",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllPosOrders action.
     */
    reset: (state) => {
      state.getAllPosOrders = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSinglePosOrder = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.checkOutOrder = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.returnPosOrder = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
    handleCheckoutData(state, action) {
      state.orderCheckoutData = action.payload || {};
    },
    handleDuplicateData(state, action) {
      state.orderDuplicateData = action.payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllPosOrders action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllPosOrders.pending, (state) => {
        state.getAllPosOrders.isLoading = true;
        state.getAllPosOrders.message = "";
        state.getAllPosOrders.isError = false;
        state.getAllPosOrders.isSuccess = false;
        state.getAllPosOrders.data = null;
      })
      /**
       * Updates the state when getAllPosOrders action is fulfilled/successful.
       * Updates loading and success flags and sets getAllPosOrders data with the payload.
       */
      .addCase(getAllPosOrders.fulfilled, (state, action) => {
        state.getAllPosOrders.isLoading = false;
        state.getAllPosOrders.isSuccess = true;
        state.getAllPosOrders.data = action.payload;
      })
      /**
       * Updates the state when getAllPosOrders action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllPosOrders.rejected, (state, action) => {
        state.getAllPosOrders.message = action.payload?.message;
        state.getAllPosOrders.isLoading = false;
        state.getAllPosOrders.isError = true;
        state.getAllPosOrders.data = null;
      })
      /**
       * Updates the state while the getSinglePosOrder action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSinglePosOrder.pending, (state) => {
        state.getSinglePosOrder.isLoading = true;
        state.getSinglePosOrder.message = "";
        state.getSinglePosOrder.isError = false;
        state.getSinglePosOrder.isSuccess = false;
        state.getSinglePosOrder.data = null;
      })
      /**
       * Updates the state when getSinglePosOrder action is fulfilled/successful.
       * Updates loading and success flags and sets getSinglePosOrder data with the payload.
       */
      .addCase(getSinglePosOrder.fulfilled, (state, action) => {
        state.getSinglePosOrder.isLoading = false;
        state.getSinglePosOrder.isSuccess = true;
        state.getSinglePosOrder.data = action.payload;
      })
      /**
       * Updates the state when getSinglePosOrder action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSinglePosOrder.rejected, (state, action) => {
        state.getSinglePosOrder.message = action.payload?.message;
        state.getSinglePosOrder.isLoading = false;
        state.getSinglePosOrder.isError = true;
        state.getSinglePosOrder.data = null;
      })
      /**
       * Updates the state while the addPosOrder action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addPosOrder.pending, (state) => {
        state.addPosOrder.isLoading = true;
        state.addPosOrder.message = "";
        state.addPosOrder.isError = false;
        state.addPosOrder.isSuccess = false;
        state.addPosOrder.data = null;
      })
      /**
       * Updates the state when addPosOrder action is fulfilled/successful.
       * Updates loading and success flags and sets addPosOrder data with the payload.
       */
      .addCase(addPosOrder.fulfilled, (state, action) => {
        state.addPosOrder.isLoading = false;
        state.addPosOrder.isSuccess = true;
        state.addPosOrder.data = action.payload;
      })
      /**
       * Updates the state when addPosOrder action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addPosOrder.rejected, (state, action) => {
        state.addPosOrder.message = action.payload?.message;
        state.addPosOrder.isLoading = false;
        state.addPosOrder.isError = true;
        state.addPosOrder.data = null;
      })
      .addCase(checkOutOrder.pending, (state) => {
        state.checkOutOrder.isLoading = true;
        state.checkOutOrder.message = "";
        state.checkOutOrder.isError = false;
        state.checkOutOrder.isSuccess = false;
        state.checkOutOrder.data = null;
      })
      /**
       * Updates the state when checkOutOrder action is fulfilled/successful.
       * Updates loading and success flags and sets checkOutOrder data with the payload.
       */
      .addCase(checkOutOrder.fulfilled, (state, action) => {
        state.checkOutOrder.isLoading = false;
        state.checkOutOrder.isSuccess = true;
        state.checkOutOrder.data = action.payload;
      })
      /**
       * Updates the state when checkOutOrder action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(checkOutOrder.rejected, (state, action) => {
        state.checkOutOrder.message = action.payload?.message;
        state.checkOutOrder.isLoading = false;
        state.checkOutOrder.isError = true;
        state.checkOutOrder.data = null;
      })

      .addCase(returnPosOrder.pending, (state) => {
        state.returnPosOrder.isLoading = true;
        state.returnPosOrder.message = "";
        state.returnPosOrder.isError = false;
        state.returnPosOrder.isSuccess = false;
        state.returnPosOrder.data = null;
      })
      /**
       * Updates the state when returnPosOrder action is fulfilled/successful.
       * Updates loading and success flags and sets returnPosOrder data with the payload.
       */
      .addCase(returnPosOrder.fulfilled, (state, action) => {
        state.returnPosOrder.isLoading = false;
        state.returnPosOrder.isSuccess = true;
        state.returnPosOrder.data = action.payload;
      })
      /**
       * Updates the state when returnPosOrder action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(returnPosOrder.rejected, (state, action) => {
        state.returnPosOrder.message = action.payload?.message;
        state.returnPosOrder.isLoading = false;
        state.returnPosOrder.isError = true;
        state.returnPosOrder.data = null;
      });
  },
});

/**
 * Destructures the reset action from the categoriesSlice actions.
 * - `reset`: Action function to reset the categories related state.
 */
export const { reset, handleCheckoutData, handleDuplicateData } =
  posOrderSlice.actions;

/**
 * Exports the default reducer generated by categoriesSlice.
 * This reducer handles state updates for categories related actions.
 */
export default posOrderSlice.reducer;
