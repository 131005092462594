import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../../../features/Customers/CustomersSlice";
import { checkOutOrder } from "../../../../../features/PosOrder/PosOrderSlice";

export const useSplitEqually = (
  setCheckOutVisible,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen
) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef();

  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );

  const { data: paymentMethodsList, isLoading: paymentMethodsListLoading } =
    useSelector((state) => state.payments.getPaymentMethods);

  const orderCheckoutData = useSelector(
    (state) => state.posOrder.orderCheckoutData
  );
  const orderType = orderCheckoutData?.orderType;
  const discountAmount = Number(orderCheckoutData?.discountAmount) || 0;
  const totalAmount = Number(orderCheckoutData?.totalAmount)?.toFixed() || 0;
  let taxAmount = 0.15 * Number(totalAmount);
  const amount = Number(totalAmount) + Number(taxAmount);
  const total = Number(amount) - Number(discountAmount);
  const selectedCustomerId = selectedCustomer?.id;
  const pricePerParts = orderCheckoutData?.checkout?.perPartPrice;
  const totalParts = orderCheckoutData?.checkout?.splitByEqually?.totalParts;
  const remainingParts =
    orderCheckoutData?.checkout?.splitByEqually?.remainingParts;
  const paidParts = orderCheckoutData?.checkout?.splitByEqually?.paidParts;
  const checkoutType = orderCheckoutData?.checkout?.checkoutType;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
  }, [dispatch]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsOpen(false);
  };

  const handleSelectionChange = (value) => {
    setSelectedCard(value);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  const [parts, setParts] = useState([
    { name: "Part 1", price: total, selected: false },
  ]);

  const addPart = useCallback(() => {
    setParts((currentParts) => {
      const newPrice = (total / (currentParts.length + 1)).toFixed(2);
      const newParts = currentParts.map((part) => ({
        ...part,
        price: parseFloat(newPrice),
      }));
      newParts.push({
        name: `Part ${currentParts.length + 1}`,
        price: parseFloat(newPrice),
        selected: false,
      });
      return newParts;
    });
  }, [total]);

  const deletePart = useCallback(
    (index) => {
      setParts((currentParts) => {
        if (currentParts.length <= 1) return currentParts;
        const newParts = currentParts.filter((_, i) => i !== index);
        const newPrice = (totalAmount / newParts.length).toFixed(2);
        return newParts.map((part) => ({
          ...part,
          price: parseFloat(newPrice),
        }));
      });
    },
    [totalAmount]
  );

  const togglePartSelection = useCallback((index) => {
    setParts((currentParts) =>
      currentParts.map((part, i) => ({
        ...part,
        selected: i === index ? !part.selected : part.selected,
      }))
    );
  }, []);

  // Calculate the total of selected parts
  const selectedTotal = parts.reduce(
    (total, part) => total + (part.selected ? part.price : 0),
    0
  );

  const handleCheckout = () => {
    const payload = {
      paymentMethod: selectedPaymentMethod.toUpperCase() ?? "",
      orderId: orderCheckoutData?.id,
      checkoutType: "SPLIT_EQUALLY",
      cardType: selectedCard,
      customerPointsid: selectedCustomerId,
      totalParts: parts.length,
      paidParts: parts.filter((part) => part.selected).length,
    };
    dispatch(
      checkOutOrder({ payload, successCallBack: successCheckoutCallback })
    );
  };

  const successCheckoutCallback = (response) => {
    setOrderCheckoutPaid(response?.data?.data);
    setIsCheckoutModalOpen(true);
    setCheckOutVisible(false);
  };

  return {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    customerList,
    customerListLoading,
    setIsOpen,
    isOpen,
    selectedCustomer,
    setSelectedCustomer,
    handleSelectCustomer,
    dropdownRef,
    paymentMethodsListLoading,
    paymentMethodsList,
    parts,
    togglePartSelection,
    addPart,
    deletePart,
    selectedTotal,
    total,
    totalAmount,
    orderType,
    discountAmount,
    taxAmount,
    handleSelectionChange,
    selectedCard,
    setSelectedCard,
    handleCheckout,
    remainingParts,
    paidParts,
    totalParts,
    pricePerParts,
    checkoutType,
  };
};
