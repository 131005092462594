import React, { useEffect, useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
  Card,
  Loader,
} from "../../../../../common/components";
import { ArrowLeft, CrossIcon } from "../../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  setVariableOptions,
} from "../../../../../../features/ItemsModal/ItemsModalSlice";
import VariableOptionsForm from "./VariableOptionsForm";
import { useTranslation } from "react-i18next";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const EditVariableItems = ({
  storesList,
  categoryList,
  selected,
  singleItem,
  isLoading,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const variableOptionFormRef = useRef();
  const uploadFileData = useSelector((state) => state.uploadFile);

  // get uploaded file from the redux store data
  const { title, isModalOpen } = useSelector((state) => state.itemsModal);
  const defaultValueRef = useRef({
    variables: Object.entries(singleItem?.variablePairs || {})?.map(
      ([item, val]) => ({
        variablename: item,
        values: val.map((value) => ({
          value: value,
        })),
      })
    ),
  });

  // validation schema for add/edit variable item form
  const variableItemSchema = z.object({
    englishname: z.string().min(3, "English Name is required!"),
    arabicname: z.string().min(3, "Arabic Name is required!"),
    description: z.string().min(1, "Description is required!"),
    store: z.coerce.number().min(1, "Store is required!"),
    category: z.coerce.number().min(1, "Category is required!"),
    variables: z.array(
      z.object({
        variablename: z.string().min(1, "Variable Name is required!"),
        values: z.array(
          z.object({
            value: z.string().min(1, "Value is required!"),
          })
        ),
      })
    ),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(variableItemSchema),
    defaultValues: defaultValueRef,
  });

  const {
    fields,
    remove: removeVariable,
    update,
  } = useFieldArray({
    control: control,
    name: "variables",
  });
  const defaultValues = useMemo(
    () => ({
      englishname: selected?.englishName ?? "",
      arabicname: selected?.arabicName ?? "",
      description: selected?.description ?? "",
      store: selected?.stores?.id ?? "",
      itemcost: selected?.itemCost ?? "",
      category: selected?.category?.id ?? "",
      saleprice: selected?.salePrice ?? "",
      variables: Object.entries(singleItem?.variablePairs || {})?.map(
        ([item, val]) => ({
          variablename: item,
          values: val.map((value) => ({
            value: value,
          })),
        })
      ),
    }),
    [selected, singleItem]
  );

  useEffect(() => {
    // set the default values for each field to edit selected using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [selected, setValue, defaultValues]);

  const handleDone = async (i) => {
    // Trigger validation for all fields in the form
    const isValid = await trigger("variables");

    // Check if the form is valid
    if (isValid) {
      // If the form is valid, append a new item to the form array
      update(i, {
        variablename: getValues().variables[i].variablename,
        edit: false,
        values: getValues().variables[i].values,
      });
    }
  };

  const handleEdit = (i) => {
    update(i, {
      edit: true,
      variablename: getValues().variables[i]?.variablename,
      values: getValues().variables[i].values,
    });
  };

  const handleBack = () => {
    setActiveStep(1);
  };

  const { variables } = getValues();

  const onSubmit = async (data) => {
    const payload = {
      type: "VARIABLE",
      image: singleItem?.image,
      englishName: data?.englishname,
      arabicName: data?.arabicname,
      description: data?.description,
      storeId: +data?.store,
      categoryId: +data?.category,
      variableItem: variables,
    };
    return dispatch(setVariableOptions(payload));
  };

  const handleUpdateItem = () => {
    if (
      variableOptionFormRef &&
      variableOptionFormRef.current &&
      variableOptionFormRef.current.handleSubmit
    ) {
      variableOptionFormRef.current.handleSubmit();
    }
  };

  const handleNext = async () => {
    await handleSubmit(async (data) => await onSubmit(data))();
    setActiveStep(2);
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              {/* header */}
              {activeStep !== 1 && (
                <CustomButton
                  icon={<ArrowLeft />}
                  padding="p-2"
                  border="border"
                  width="w-fit"
                  onClick={handleBack}
                />
              )}
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => {
                  dispatch(setVariableOptions({}));
                  dispatch(closeModal());
                }}
              />
            </div>
            {/* body */}
            {isLoading ? (
              <div className="p-5">
                <Loader />
              </div>
            ) : (
              <div className="p-5 space-y-3">
                {activeStep === 2 && (
                  <VariableOptionsForm
                    ref={variableOptionFormRef}
                    type="update"
                    singleItem={singleItem}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
                {activeStep === 1 && (
                  <form className="space-y-3">
                    <CustomFileUploader
                      isLoading={uploadFileData?.uploadSingleFile?.isLoading}
                      selected={selected ? selected?.image : null}
                    />
                    <InputGroup
                      labelText={
                        "client_dashboard.directory.items.variable_item.item_name"
                      }
                      htmlFor="englishname"
                      inputName="englishname"
                      placeholder={
                        "client_dashboard.directory.items.variable_item.enter_item_name"
                      }
                      register={register}
                      errors={errors}
                    />
                    {/* arabic name */}
                    <InputGroup
                      labelText={
                        "client_dashboard.directory.items.variable_item.arabic_name"
                      }
                      htmlFor="arabicname"
                      inputName="arabicname"
                      placeholder={
                        "client_dashboard.directory.items.variable_item.enter_arabic_name"
                      }
                      register={register}
                      errors={errors}
                    />
                    {/* description */}
                    <InputGroup
                      labelText={
                        "client_dashboard.directory.items.variable_item.description"
                      }
                      htmlFor="description"
                      inputName="description"
                      placeholder={
                        "client_dashboard.directory.items.variable_item.enter_description"
                      }
                      register={register}
                      errors={errors}
                    />
                    {/* store */}
                    <SelectGroup
                      labelText={
                        "client_dashboard.directory.items.variable_item.store"
                      }
                      htmlFor="store"
                      inputName="store"
                      className="rtl:bg-left"
                      options={[
                        {
                          value: "",
                          label:
                            "client_dashboard.directory.items.variable_item.select_store",
                        },
                        ...storesList?.map((option) => ({
                          value: option?.id,
                          label: option?.branchName,
                        })),
                      ]}
                      register={register}
                      errors={errors}
                    />
                    {/* category */}
                    <SelectGroup
                      labelText={
                        "client_dashboard.directory.items.variable_item.category"
                      }
                      htmlFor="category"
                      inputName="category"
                      className="rtl:bg-left"
                      options={[
                        {
                          value: "",
                          label:
                            "client_dashboard.directory.items.variable_item.select_category",
                        },
                        ...categoryList?.map((option) => ({
                          value: option?.id,
                          label: option?.englishName,
                        })),
                      ]}
                      register={register}
                      errors={errors}
                    />

                    {fields.map((field, i) => (
                      <div key={field.variablename + i} className="space-y-3">
                        {!field.edit ? (
                          <Card
                            className="flex flex-col space-y-3 px-4 py-3"
                            key={i}
                          >
                            <div className="flex justify-between items-center">
                              <span className="font-semibold text-lg">
                                {variables[i].variablename}
                              </span>
                              <CustomButton
                                text="Edit"
                                width="w-fit"
                                bgColor="bg-trasnparent"
                                border="border"
                                textColor="text-text-primary"
                                icon
                                padding="py-2 px-3"
                                onClick={() => handleEdit(i)}
                              />
                            </div>
                            <div className="flex items-center gap-3">
                              {(variables[i]?.values || []).map(
                                (value, valIndex) => (
                                  <span
                                    className="bg-surface-gray py-1 px-2 rounded-3xl text-xs font-medium to-text-secondary"
                                    key={value + valIndex}
                                  >
                                    {value.value}
                                  </span>
                                )
                              )}
                            </div>
                          </Card>
                        ) : (
                          <div className="space-y-3">
                            {" "}
                            <div className="flex items-center justify-between">
                              <span className="space-y-3 font-semibold to-text-primary text-sm">
                                {t(
                                  "client_dashboard.directory.items.variable_item.variable"
                                )}{" "}
                                #{i + 1}{" "}
                              </span>
                              {!defaultValueRef.current.variables?.[i] && (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => removeVariable(i)}
                                >
                                  <CrossIcon />
                                </span>
                              )}
                            </div>
                            {/* variable name */}
                            <SelectGroup
                              htmlFor={`variablename-${i}`}
                              inputName={`variables.${i}.variablename`}
                              labelText={
                                "client_dashboard.directory.items.variable_item.variable_name"
                              }
                              options={[
                                {
                                  value: "",
                                  label:
                                    "client_dashboard.directory.items.variable_item.select_variable_name",
                                },
                                { value: "size", label: "Size" },
                                { value: "color", label: "Color" },
                                { value: "type", label: "Type" },
                                { value: "material", label: "Material" },
                              ]}
                              register={register}
                              errors={errors}
                            />
                            <Card className="space-y-3">
                              {(field?.values || [])?.map((val, valueIndex) => {
                                const isEditable = !(
                                  defaultValueRef.current.variables[i]?.values
                                    .length > valueIndex
                                );
                                return (
                                  <React.Fragment
                                    key={`variables.${i}.values.${valueIndex}.value`}
                                  >
                                    <div className="flex gap-3 items-center">
                                      <InputGroup
                                        labelText={`${t(
                                          "client_dashboard.directory.items.variable_item.value"
                                        )} #${valueIndex + 1}`}
                                        htmlFor={`value-${valueIndex}`}
                                        inputName={`variables.${i}.values.${valueIndex}.value`}
                                        placeholder={
                                          "client_dashboard.directory.items.variable_item.enter_value"
                                        }
                                        register={register}
                                        errors={errors}
                                        className="w-full"
                                        disabled={!isEditable}
                                      />
                                      {field?.values?.length > 1 && (
                                        <span
                                          className="block w-fit cursor-pointer ml-auto"
                                          onClick={() => {
                                            update(i, {
                                              ...getValues().variables[i],
                                              values: getValues().variables[
                                                i
                                              ].values.filter(
                                                (_, id) => valueIndex !== id
                                              ),
                                            });
                                          }}
                                        >
                                          <CrossIcon />
                                        </span>
                                      )}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </Card>
                            <div className="flex items-center gap-3">
                              <CustomButton
                                text="buttons.done"
                                width="w-fit"
                                bgColor="bg-trasnparent"
                                border="border"
                                textColor="text-text-primary"
                                icon
                                onClick={() => handleDone(i)}
                              />
                              <CustomButton
                                text="buttons.add_value"
                                textColor="text-white"
                                bgColor="bg-black-pearl"
                                width="w-fit"
                                onClick={() => {
                                  update(i, {
                                    ...getValues().variables[i],
                                    values: getValues().variables[
                                      i
                                    ]?.values.concat({
                                      value: "",
                                    }),
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                    {/* <CustomButton
                    text="Add Another Variable"
                    icon={<Plus color="text-blue-sky" />}
                    width="w-fit"
                    bgColor="bg-transparent"
                    className="shadow-none"
                    textColor="text-text-link"
                    onClick={addVariable}
                  /> */}
                  </form>
                )}
              </div>
            )}

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"buttons.cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              {activeStep === 1 ? (
                <CustomButton
                  onClick={handleNext}
                  text={"Next"}
                  width="w-fit"
                  padding="py-2 px-3"
                  className="leading-none"
                />
              ) : (
                <CustomButton
                  onClick={handleUpdateItem}
                  disabled={isSubmitting}
                  text={"buttons.save"}
                  width="w-fit"
                  padding="py-2 px-3"
                  className="leading-none"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditVariableItems;

// sample usage
// import EditVariableItems from "./path/to/EditVariableItems.jsx";

// <EditVariableItems />
