import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleSubscriptions,
  addBranches,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
/**
 * Custom hook for managing subscription-related functionality.
 * Provides methods for opening/closing modals, accessing table headers and body data, and handling dropdown options.
 * @returns {Object} An object containing:
 *   - dropdownOptions: Array of dropdown options for managing subscriptions.
 *   - headers: Array of table headers for subscription data.
 *   - tableBody: Array of objects representing subscription data for the table body.
 *   - closeModal: Function to close the currently active modal.
 *   - openModal: Function to open a modal with specific item and modal type.
 *   - selectedItem: Currently selected item for modal operations.
 *   - activeModal: Type of the currently active modal.
 *   - openAddNewSubscriptionModal: Function to open the modal for adding a new subscription.
 */
export const useAddBranch = ({
  selectedItem,
  closeModal,
  subscriptionDetailId,
  selectedTab,
}) => {
  const dispatch = useDispatch();
  const [branchCount, setBranchCount] = useState(1);
  const [branchPrice] = useState(850);
  const discount = 100;
  const subscriptionId = selectedItem?.id ?? subscriptionDetailId;
  const incrementBranch = () => setBranchCount(branchCount + 1);
  const decrementBranch = () => {
    if (branchCount > 1) {
      setBranchCount(branchCount - 1);
    }
  };
  const subtotal = branchCount * branchPrice;

  useEffect(() => {
    if (subscriptionId)
      dispatch(
        getSingleSubscriptions({
          subscriptionId,
          isFreeTrial: selectedTab === "FreeTrial" ? true : false,
        })
      );
  }, [dispatch, subscriptionId]);

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getSingleSubscriptions);
  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const PrivideBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const branches = singleSubscriptiondata?.branches ?? "-";
  const subTotal = singleSubscriptiondata?.subTotal ?? "-";
  const plan = singleSubscriptiondata?.plan_tenure ?? "-";

  const handleConfirmClick = () => {
    const payload = {
      totalBranches: branchCount,
      totalDiscount: discount,
      subTotal: subtotal,
    };
    // Include the subscriptionId in the action payload structure
    dispatch(
      addBranches({ subscriptionId, payload, successCallBack: handleSuccess })
    );
  };
  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };

  return {
    subtotal,
    incrementBranch,
    decrementBranch,
    branchCount,
    branchPrice,
    singleSubscriptiondata,
    plan,
    PrivideBy,
    branches,
    subTotal,
    subscriptionLoading,
    discount,
    handleConfirmClick,
    closeModal,
    profile,
  };
};
