import api from "../../services/api";

/**
 * Creates stores API endpoints based on the specified base path.
 * @param {string} basePath - The base path for authentication endpoints.
 * @returns {Object} Object containing stores API methods.
 */
const createStoresApi = (basePath) => ({
  /**
   * get all stores
   * @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   * @returns {Promise} A Promise that resolves to the store response.
   */
  getAllStores: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * get single store
   * @param {object} storeId storeId to retrieve a store
   * @returns {Promise} A Promise that resolves to the store response.
   */
  getSingleStore: async (storeId) => {
    const response = await api().get(`${basePath}/${storeId}`);
    return response;
  },

  /**
   * update a single store
   * @param {object} storeId id of the store
   * @returns {Promise} A Promise that resolves to the store response.
   */
  updateStore: async (storeId, payload) => {
    const response = await api().put(`${basePath}/${storeId}`, payload);
    return response;
  },

  /**
   * add a store
   * @param {object} payload
   *    branchName: name of the branch,
        streetName: street name of the store
        buildingNumber: build number of the store,
        districtName: district name,
        city: city name of the store,
        zipCode: zip code,
        isAccessToDashboard: access to dashboard,
        isAccessToPos: access to pos
   * @returns {Promise} A Promise that resolves to the store response.
   */
  addStore: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * delete a store against the specified id
   * @param {object} storeId id of the store
   * @returns {Promise} A Promise that resolves to the store response.
   */
  deleteStore: async (storeId) => {
    const response = await api().delete(`${basePath}/${storeId}`);
    return response;
  },
  /**
   * undo a deleted store against the specified id
   * @param {object} storeId id of the store
   * @returns {Promise} A Promise that resolves to the store response.
   */
  undoStore: async (storeId) => {
    const response = await api().get(`${basePath}/undo-store/${storeId}`);
    return response;
  },
});

// Export the createStoresApi

/**
 * Example usage:
 * import createStoresApi from './path/to/api/posstoresApi';
 *
 * const storesApi = createStoresApi('stores');
 *
 * const getAllStores = async (payload) => {
 *   try {
 *     const response = await storesApi.addStore(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createStoresApi;
