import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  setActiveStep,
  setSelectedOption,
  setVariableOptions,
  updateTitle,
} from "../../../../../features/ItemsModal/ItemsModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import {
  Breadcrumb,
  CustomButton,
  CustomTable,
  InputGroup,
} from "../../../../common/components";
import Layout from "../../Layout/Layout";
import useItemsList from "./useItemsList";
import {
  ArchiveBox,
  ArrowLeft,
  BoundingBox,
  CodeSandBox,
  CrossIcon,
  EditIcon,
  EyeIcon,
  FluentBoxIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import {
  SimpleItems,
  VariableItems,
  RawItems,
  CompositingItems,
} from "./ItemTypes";
import EditSimpleItem from "./ItemTypes/EditSimpleItem";
import ShowItemDetail from "./ShowItemDetail";
import EditRawItems from "./ItemTypes/EditRawItems";
import DeleteItem from "./DeleteItem";
import EditVariableItems from "./ItemTypes/EditVariableItem";
import EditCompositingItem from "./ItemTypes/EditCompositingItem";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import VariableOptionsForm from "./ItemTypes/VariableOptionsForm";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

/**
 * Items List component
 * Renders table of items list
 *
 * @returns {JSX.Element} JSX code for rendering the items list table
 */

export default function ItemsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { activeStep, selectedOption, type, title, isModalOpen } = useSelector(
    (state) => state.itemsModal
  );

  const itemId = selectedItem?.id;
  const vItemId = selectedItem?.type === "VARIABLE" && selectedItem?.vItemId;

  const {
    itemListLoading,
    itemList,
    categoryList,
    storesList,
    handleDelete,
    simpleItemDetail,
    compositingItemDetail,
    variableItemDetail,
    rawItemDetail,
    itemImage,
    currentPage,
    setCurrentPage,
    singleItem,
    singleItemLoading,
  } = useItemsList(itemId, vItemId);

  // Refs to access handleSubmit functions of child components
  const simpleItemRef = useRef();
  const variableItemRef = useRef();
  const variableOptionFormRef = useRef();
  const rawItemRef = useRef();
  const compositingItemRef = useRef();

  // Function to handle form submission for different item types
  const handleSubmitForms = (type) => {
    const refs = {
      SIMPLE: simpleItemRef,
      VARIABLE: variableItemRef,
      RAW: rawItemRef,
      COMPOSITING: compositingItemRef,
    }[type];

    if (
      variableOptionFormRef &&
      variableOptionFormRef.current &&
      variableOptionFormRef.current.handleSubmit
    ) {
      variableOptionFormRef.current.handleSubmit();
    }

    // Check if the ref exists and has a current property
    if (refs && refs.current && refs.current.handleSubmit) {
      // Call the handleSubmit method if available
      refs.current.handleSubmit();
    }
  };

  const handleViewItem = (type) => {
    dispatch(
      openModal({
        title: `Item ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleItemsActions = (type) => {
    setIsSubmitting(false);
    dispatch(
      openModal({
        title: `${type} Item`,
        type: type,
        editable: true,
        activeStep: 1,
      })
    );
  };

  /**
   * Handle option change for item type.
   *
   * @param {string} item - Selected item type.
   */
  const handleOptionChange = (item) => {
    dispatch(setSelectedOption(item));
  };

  /**
   * Handle moving to the next step.
   */
  const handleNext = async () => {
    if (
      selectedOption === "VARIABLE" &&
      variableItemRef?.current?.handleSubmit
    ) {
      await variableItemRef.current.handleSubmit();
      await dispatch(setActiveStep(activeStep + 1));
      dispatch(updateTitle(`Add ${selectedOption} Item`));
    } else {
      dispatch(setActiveStep(activeStep + 1));
      dispatch(updateTitle(`Add ${selectedOption} Item`));
    }
  };

  /**
   * Handle moving back to the previous step.
   */
  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
    dispatch(updateTitle("Add Item"));
  };

  const rawItems = useMemo(
    () => itemList?.filter((item) => item?.type === "RAW"),
    [itemList]
  );

  const handleEdit = (type) => {
    dispatch(
      openModal({
        title: `${type} Item`,
        type: type,
        editable: true,
      })
    );
  };

  const itemDetail = {
    SIMPLE: simpleItemDetail,
    RAW: rawItemDetail,
    VARIABLE: variableItemDetail,
    COMPOSITING: compositingItemDetail,
  }[selectedItem?.type];

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={t("buttons.export")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() => handleExport("item-details/export-items", "items")}
          />
          <CustomButton
            text={t("buttons.print")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint("item-details/print-item-details-invoice", "Items")
            }
          />
          <CustomButton
            text="Add Item"
            width="w-fit"
            bgColor="!bg-surface-primary"
            hoverTextColor="text-white"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => handleItemsActions("add")}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* items list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={itemList}
          setSelectedItem={setSelectedItem}
          loading={itemListLoading}
          searchParams={["englishName"]}
          callback={(item) => ({
            ...item,
            itemDetails: {
              name: item?.slug ?? item?.englishName,
              image: item?.image,
            },
          })}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewItem("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleEdit("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleItemsActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={itemList ? itemList?.length : 0}
        />
      </div>

      {/* conditionally render modal for different item actions */}

      {isModalOpen && type === "add" && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {activeStep !== 1 && (
                  <CustomButton
                    icon={<ArrowLeft />}
                    text=""
                    padding="p-2"
                    border="border"
                    width="w-fit"
                    onClick={handleBack}
                  />
                )}
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => {
                  dispatch(setVariableOptions({}));
                  dispatch(closeModal());
                }}
              />
            </div>
            {/* body */}
            {type === "add" && (
              <div className="p-5 space-y-3">
                {activeStep === 1 && (
                  <div className="flex flex-col items-center justify-center space-y-3">
                    <h4 className="text-lg font-semibold to-text-primary text-center !my-3">
                      What kind of item?
                    </h4>
                    {itemTypes?.map((item, index) => (
                      <div
                        key={item?.value}
                        className={`p-4 flex items-center border gap-4 rounded-lg relative max-w-[400px] w-full ${
                          selectedOption === item.value &&
                          "border-sky-blue bg-info-50"
                        } `}
                      >
                        <div
                          onClick={() => handleOptionChange(item?.value)}
                          className="absolute inset-0 bg-transparent z-20 cursor-pointer"
                        />

                        <item.icon stroke={selectedOption === item.value} />

                        <InputGroup
                          inputName="simple"
                          labelText={item?.name}
                          inputType="radio"
                          className="flex items-center justify-between w-full !space-y-0"
                          padding="p-0"
                          checked={selectedOption === item?.value}
                          onChange={handleOptionChange}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {activeStep === 2 && (
                  <form className="space-y-3">
                    {selectedOption === "SIMPLE" && (
                      <SimpleItems
                        ref={simpleItemRef}
                        storesList={storesList ?? []}
                        categoryList={categoryList ?? []}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                    {selectedOption === "VARIABLE" && (
                      <VariableItems
                        ref={variableItemRef}
                        storesList={storesList ?? []}
                        categoryList={categoryList ?? []}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                    {selectedOption === "COMPOSITING" && (
                      <CompositingItems
                        ref={compositingItemRef}
                        storesList={storesList ?? []}
                        categoryList={categoryList ?? []}
                        itemList={itemList ?? []}
                        rawItems={rawItems ?? []}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                    {selectedOption === "RAW" && (
                      <RawItems
                        ref={rawItemRef}
                        storesList={storesList}
                        categoryList={categoryList}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                  </form>
                )}
                {activeStep === 3 && (
                  <VariableOptionsForm
                    type="add"
                    ref={variableOptionFormRef}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </div>
            )}

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              {type !== "delete" ? (
                <CustomButton
                  text={
                    [
                      "Next",
                      selectedOption === "VARIABLE" && "Next",
                      "Add Item",
                    ].filter(Boolean)[activeStep - 1]
                  }
                  disabled={isSubmitting}
                  width="w-fit"
                  padding="py-2 px-3"
                  onClick={
                    [
                      handleNext,
                      selectedOption === "VARIABLE" && handleNext,
                      () => handleSubmitForms(selectedOption),
                    ].filter(Boolean)[activeStep - 1]
                  }
                />
              ) : (
                <CustomButton
                  width="w-fit"
                  padding="py-2 px-3"
                  className="leading-none"
                  bgColor={
                    type === "delete" ? "bg-red-500" : "bg-surface-primary"
                  }
                  type="submit"
                  text="Delete"
                  onClick={handleDelete}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {type === "edit" && selectedItem?.type === "SIMPLE" && (
        <EditSimpleItem
          storesList={storesList}
          categoryList={categoryList}
          selected={selectedItem}
          setIsSubmitting={setIsSubmitting}
        />
      )}

      {type === "edit" && selectedItem?.type === "RAW" && (
        <EditRawItems
          storesList={storesList}
          categoryList={categoryList}
          selected={selectedItem}
        />
      )}
      {type === "edit" && selectedItem?.type === "VARIABLE" && (
        <EditVariableItems
          storesList={storesList}
          categoryList={categoryList}
          selected={selectedItem ? selectedItem : null}
          singleItem={singleItem}
          isLoading={singleItemLoading}
        />
      )}
      {type === "edit" && selectedItem?.type === "COMPOSITING" && (
        <EditCompositingItem
          rawItems={rawItems ?? []}
          storesList={storesList}
          categoryList={categoryList}
          selected={selectedItem}
        />
      )}
      {type === "detail" && (
        <ShowItemDetail
          itemDetail={itemDetail}
          itemImage={itemImage}
          isLoading={singleItemLoading}
        />
      )}
      {type === "delete" && <DeleteItem selected={selectedItem} />}
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.directory.name", href: "#", current: false },
  { name: "client_dashboard.directory.items.name", href: "#", current: true },
];

const tableHeader = [
  { label: "Item", key: "itemDetails" },
  { label: "Category", key: "category.englishName" },
  { label: "Store", key: "stores.branchName" },
  { label: "Available Qty", key: "quantity" },
  {
    label: "Sale Price",
    key: "salePrice",
    type: "cost",
  },
  {
    label: "Type",
    key: "type",
    bgColorKey: {
      SIMPLE: "bg-success-50 text-positive-600",
      VARIABLE: "bg-warning-100 text-warning-700",
      RAW: "bg-danger-100 text-danger-600",
      COMPOSITING: "bg-info-50 text-link",
    },
  },
];

const itemTypes = [
  { name: "Simple Item", value: "SIMPLE", icon: FluentBoxIcon },
  { name: "Variable Item", value: "VARIABLE", icon: BoundingBox },
  { name: "Compositing Item", value: "COMPOSITING", icon: CodeSandBox },
  { name: "Raw Item", value: "RAW", icon: ArchiveBox },
];

// sample usage
// import ItemsList from "./path/to/ItemsList.jsx";

// <ItemsList />
