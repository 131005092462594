import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Loader, SelectGroup } from "../../../../common/components";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { getClientExpense } from "../../../../../features/ClientDashboard/ClientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

function Expense() {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // January 1st of the current year
  const [endDate, setEndDate] = useState(new Date());
  const { data: expense, isLoading } = useSelector(
    (state) => state.clientDashboard.getClientExpense
  );

  const expenseOfAllTime = expense?.calculatingTotalExpenses ?? 0;
  const expenseThisYear = expense?.currentYearDataTotalExpenses ?? 0;

  const labels = Object.keys(expense?.expenseByYear || {});
  const dataset = Object.values(expense?.expenseByYear || {});

  const { register, watch } = useForm();

  const selectedDuration = watch("selectedDuration"); // Watching specific field

  useEffect(() => {
    if (selectedDuration) {
      const yearsBack = parseInt(selectedDuration);
      setStartDate(new Date(currentYear - yearsBack, 0, 1));
      setEndDate(new Date());
    }
  }, [selectedDuration]);

  /**
   * Handles the api call for getting all sales and cost
   * This function can be asynchronous for tasks like API calls.
   *
   *   @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
   */
  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
    };
    dispatch(getClientExpense({ payload }));
  }, [startDate, endDate, dispatch]);
  // Data for the chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Net Income",
        data: dataset,
        fill: false,
        borderColor: "rgba(39, 123, 245, 0.8)",
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  };

  // Options for the chart
  const options = {
    scales: {
      x: {
        grid: {
          display: false, // This will remove the vertical grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [5, 5], // This adds dashed horizontal lines, if you want them
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

    maintainAspectRatio: false, // This will allow the chart to fit within the given height and width
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-text-primary">Expense</h2>
        <SelectGroup
          htmlFor="selectedDuration"
          inputName="selectedDuration"
          width="w-fit"
          register={register}
          options={duration}
          textColor="text-text-primary"
          className="!py-1 min-w-36"
        />
      </div>
      <div className="flex justify-between items-center bg-white mt-2 ">
        <div>
          <h4 className="text-sm font-normal text-text-primary">
            Expense All of Time{" "}
          </h4>
          <span className="text-2xl font-semibold flex items-center">
            {isLoading ? <Loader margin="mt-2" /> : `${expenseOfAllTime} SAR`}
          </span>
        </div>
        <div className="px-4">
          <h4 className="text-sm font-normal text-text-primary">
            Expense This Year{" "}
          </h4>
          <span className="text-2xl font-semibold flex items-center">
            {isLoading ? <Loader margin="mt-2" /> : `${expenseThisYear} SAR`}
          </span>
        </div>
      </div>
      <div className="container mx-auto py-2">
        <div className="flex justify-center">
          {/* Set a specific height here if needed */}
          <div className="w-full" style={{ height: "300px" }}>
            <Line data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expense;

const duration = [
  { value: "1", label: "Last 1 Year" },
  { value: "2", label: "Last 2 Year" },
  { value: "3", label: "Last 3 Year" },
  { value: "4", label: "Last 4 Year" },
  { value: "5", label: "Last 5 Year" },
  { value: "6", label: "Last 6 Year" },
];
