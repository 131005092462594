import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import PosLogin from "./pages/auth/PosLogin";
import ForgetPassword from "./pages/auth/forget-password/forget-password";
import ResetPassword from "./pages/auth/reset-password/reset-password";
import { Provider } from "react-redux";
import store from "./store/store";
import Signup from "./components/Signup/Signup";
import ConfirmEmail from "./components/ConfirmEmail/ConfirmEmail";
import { CreatePassword } from "./components/Signup/ClientProfile/CreatePassword/CreatePassword";
import { CreateAccount } from "./components/Signup/ClientProfile/CompleteProfile/CompleteProfile";
import { BusinessProfile } from "./components/Signup/ClientProfile/BusinessProfile/BusinessProfile";
import { SetPlan } from "./components/Signup/ClientProfile/SetPlan/SetPlan";
import PosHome from "./components/Pos/PosHome";
import AdminLogin from "./components/Admin/AdminLogin/AdminLogin";
import PosLoginEmail from "./components/Pos/PosLogin/PosLoginEmail/PosLoginEmail";
import Clients from "./components/Admin/AdminDashboard/ManageClients/Clients/Clients";
import AdminLayout from "./components/Admin/AdminDashboard/SuperAdminLayout/AdminLayout/Layout";
import ClientDetail from "./components/Admin/AdminDashboard/ManageClients/Clients/DetailOfClients/ClientDetail";
import CustomersList from "./components/Client/Dashboard/Directory/Customers/CustomersList";
import CategoriesList from "./components/Client/Dashboard/Directory/Categories/CategoriesList";
import SuppliersList from "./components/Client/Dashboard/Directory/Suppliers/SuppliersList";
import ItemsList from "./components/Client/Dashboard/Directory/Items/ItemsList";
import { showToast } from "./components/common/utils/showToast.util";
import NotFoundPage from "./components/common/components/ErrorPage/ErrorPage";
import StocktakingList from "./components/Client/Dashboard/Inventory/Stocktaking/StocktakingList";
import {
  Settings,
  PromotionsList,
  UsersList,
  StoresList,
} from "./components/Client/Dashboard/Management";
import InventoryTransferList from "./components/Client/Dashboard/Inventory/InventoryTransfer/InventoryTransferList";
import SaleInvoicesList from "./components/Client/Dashboard/Invoices/SaleInvoices/SaleInvoicesList";
import PurchaseInvoicesList from "./components/Client/Dashboard/Invoices/PurchaseInvoice/PurchaseInvoiceList";
import ExpenseInvoiceList from "./components/Client/Dashboard/Invoices/ExpenseInvoice/ExpenseInvoiceList";
import PosLayout from "./layout";
import PosOrder from "./components/Pos/PosOrder/PosOrder";
import PosTable from "./components/Pos/PosTable/PosTable";
import PosDrawer from "./components/Pos/PosDrawer/PosDrawer";
import InventoryTransactions from "./components/Client/Dashboard/Inventory/InventoryTransactions/InventoryTransactions";
import PosStores from "./components/Pos/PosLogin/PosStores/PosStores";
import { UserProvider, useUserContext } from "./context/UserContext";
import Dashbaord from "./components/Client/Dashboard/ClientDashboard/Dashboard";
import AdminDashboard from "./pages/AdminDashboard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PaymentsList from "./components/Client/Dashboard/Management/Payments/PaymentsList/PaymentsList";
import {
  Sales as SalesReports,
  ProfitLoss,
  Taxes,
  Expenses,
  Purchase,
  Inventory,
} from "./components/Client/Dashboard/Reports";

import AdminSales from "./components/Admin/AdminDashboard/AdminSales/AdminSales";
import ClientSalesDetail from "./components/Admin/AdminDashboard/AdminSales/ClientSalesDetail/ClientSalesDetail";
import ActivateAccount from "./components/Admin/AdminLogin/StaffUser/ActivateAccount";
import HomePage from "./pages/HomePage";
import SubscriptionClientDetail from "./components/Admin/AdminDashboard/Subscription/SubscriptionClientDetail/SubscribtionClientDetail";
import Subscription from "./components/Admin/AdminDashboard/Subscription/Subscribtion";
import FinishScreen from "./components/Signup/ClientProfile/FinishScreen/FinishScreen";
import SetStaffPassword from "./components/Admin/AdminLogin/StaffUser/SetStaffPassword/SetStaffPassword";
import Faqs from "./pages/Faqs";
import ContactUs from "./pages/ContactUs";
import Pricing from "./pages/Pricing";
import { useResetHtmlStyles } from "./hooks/useResetHtmlStyles";
import Features from "./pages/Features";
import Terms from "./pages/Terms";
import AppProvider from "./Providers/AppProvder";
import PrivacyPolicy from "./pages/PrivacyPolicy";
const PrivateRoutes = ({ allowedRoles }) => {
  const { user } = useUserContext();
  const userRole = user?.role;
  // let auth = { token: userDetails?.token };

  // Check if the user has at least one allowed role
  const hasAllowedRole = allowedRoles?.some((role) => userRole?.includes(role));

  if (!hasAllowedRole) {
    // Show a toast message when the user doesn't have access
    showToast("You don't have permission to access this page!", "error");
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

/**
 * Layout component.
 * Provides the overall structure of the application layout including routes and store provider.
 *
 * @returns {JSX.Element} JSX code for rendering the application layout.
 */

function Layout() {
  return (
    <div className="min-h-screen flex flex-col">
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/business-profile" element={<BusinessProfile />} />
        <Route path="/set-plan" element={<SetPlan />} />
        <Route path="/thank-you" element={<FinishScreen />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/features" element={<Features />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/activate-account" element={<ActivateAccount />} />
        <Route path="/set-staff-password" element={<SetStaffPassword />} />

        {/* pos */}
        <Route path="/pos" element={<PosLogin />} />
        <Route path="/pos/login" element={<PosLoginEmail />} />
        <Route path="/pos/stores" element={<PosStores />} />
        {/* client */}
        <Route
          element={
            <PrivateRoutes
              allowedRoles={[
                "CLIENT",
                "CASHIER",
                "SALES MANAGER",
                "SYSTEM MANAGER",
              ]}
            />
          }
        >
          <Route path="/dashboard" element={<Dashbaord />} />
          <Route path="/invoices/sales" element={<SaleInvoicesList />} />
          <Route path="/invoices/purchase" element={<PurchaseInvoicesList />} />
          <Route path="/invoices/expense" element={<ExpenseInvoiceList />} />
          <Route path="/inventory/stocktaking" element={<StocktakingList />} />
          <Route
            path="/inventory/inventory-transfer"
            element={<InventoryTransferList />}
          />
          <Route
            path="/inventory/inventory-transaction"
            element={<InventoryTransactions />}
          />
          <Route path="/management/settings" element={<Settings />} />
          <Route path="/management/promotions" element={<PromotionsList />} />
          <Route path="/management/users" element={<UsersList />} />
          <Route path="/management/stores" element={<StoresList />} />
          <Route path="/management/stores" element={<StoresList />} />
          <Route path="/management/payments" element={<PaymentsList />} />
          <Route path="/directory/customers" element={<CustomersList />} />
          <Route path="/directory/supplier" element={<SuppliersList />} />
          <Route path="/directory/categories" element={<CategoriesList />} />
          <Route path="/directory/items" element={<ItemsList />} />
          <Route path="/report/sales" element={<SalesReports />} />
          <Route path="/report/purchase" element={<Purchase />} />
          <Route path="/report/profitloss" element={<ProfitLoss />} />
          <Route path="/report/taxes" element={<Taxes />} />
          <Route path="/report/expense" element={<Expenses />} />
          <Route path="/report/inventory" element={<Inventory />} />
        </Route>
        <Route
          element={
            <PrivateRoutes
              allowedRoles={["SUPER_ADMIN", "ACCOUNT_MANAGER", "ACCOUNTING"]}
            />
          }
        >
          {/*admin*/}
          <Route path="" element={<AdminLayout />}>
            <Route path="/admin/sales" element={<AdminSales />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/clients" element={<Clients />} />
            <Route path="/admin/subscriptions" element={<Subscription />} />
            <Route
              path="/admin/subscriptions/details/:id"
              element={<SubscriptionClientDetail />}
            />
            <Route
              path="/admin/clients/details/:id"
              element={<ClientDetail />}
            />
            <Route
              path="/admin/sales/details/:id"
              element={<ClientSalesDetail />}
            />
          </Route>
        </Route>
        <Route path="" element={<PosLayout />}>
          <Route path="/pos/dashboard" element={<PosHome />} />
          <Route path="/pos/order" element={<PosOrder />} />
          <Route path="/pos/table" element={<PosTable />} />

          <Route path="/pos/drawer" element={<PosDrawer />} />
        </Route>

        {/*admin*/}
        <Route path="/admin/login" element={<AdminLogin />} />
      </Routes>
    </div>
  );
}

/**
 * App component.
 * Sets up the router and includes the Layout component to render the application.
 *
 * @returns {JSX.Element} JSX code for rendering the App component.
 */

function App() {
  useResetHtmlStyles();
  return (
    <Router>
      <AppProvider>
        <Layout />
      </AppProvider>
    </Router>
  );
}

export default App;
