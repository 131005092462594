import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryTransfers } from "../../../../../features/InventoryTransfer/InventoryTransferSlice";
import { getAllStocktakings } from "../../../../../features/Stocktaking/StocktakingSlice";
import { getAllSaleInvoices } from "../../../../../features/Invoices/SaleInvoices/SaleInvoicesSlice";
import { getAllPurchaseInvoices } from "../../../../../features/Invoices/PurchaseInvoices/PurchaseInvoiceSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling inventory transactions related functionalities
 *
 * @returns {Object}
 *   inventoryTransferList: Object containing inventory transfer list
 */
const useInventoryTransactions = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.search);

  const [currentPage, setCurrentPage] = useState(1);

  // get inventory transfer list for all transfers from the store
  const {
    data: inventoryTransferList,
    isLoading: inventoryTransferListLoading,
  } = useSelector((state) => state.inventoryTransfer.getAllInventoryTransfers);

  // get stocktaking list for all stocktaking from the store
  const { data: stocktakingList, isLoading: stocktakingListLoading } =
    useSelector((state) => state.stocktaking.getAllStocktakings);

  // get sale invoices list for all invoices from the store
  const { data: saleInvoicesList, isLoading: saleInvoicesListLoading } =
    useSelector((state) => state.saleInvoices.getAllSaleInvoices);

  // get purchase invoices list for all invoices from the store
  const { data: purchaseInvoicesList, isLoading: purchaseInvoicesListLoading } =
    useSelector((state) => state.purchaseInvoices.getAllPurchaseInvoices);

  const inventory_transferTitle =
    "client_dashboard.inventory.inventory_transaction.inventory_transfer";
  const stocktakingTitle =
    "client_dashboard.inventory.inventory_transaction.stocktaking";
  const sale_invoicesTitle =
    "client_dashboard.inventory.inventory_transaction.sale_invoices";
  const purchase_invoicesTitle =
    "client_dashboard.inventory.inventory_transaction.purchase_invoices";

  const dataDetails = {
    [inventory_transferTitle]: (item) => ({
      "Store From": item?.storeFrom?.branchName,
      "Store To": item?.storeTo?.branchName,
      date: item?.date,
      Item: item?.transferredItems[0]?.itemDetail?.englishName,
      "Unit Of Measure": item?.transferredItems[0]?.itemDetail?.measureOfUnit,
      "Number Of Measure":
        item?.transferredItems[0]?.itemDetail?.noMeasureOfUnit,
      "Total Quantity": item?.transferredItems[0]?.itemDetail?.quantity,
      Quntity: item?.transferredItems[0]?.quantity,
      Description: item?.transferredItems[0]?.itemDetail?.description,
    }),
    [stocktakingTitle]: (item) => ({
      "Count Name": item.countName,
      Description: item.description,
      Store: item.store?.branchName,
      "Total Cost": `$ ${item.totalCost}`,
      Date: formatDateTime(item.date, "mmm dd, yyyy"),
    }),
    [sale_invoicesTitle]: (item) => ({
      Customer: item?.customer?.name,
      "Daily Order": item?.dailyOrderNumber,
      "Invoice Date": item?.saleDate,
      Store: item?.store?.branchName,
      "Payment Method": item?.paymentMethod,
    }),
    [purchase_invoicesTitle]: (item) => ({
      invoiceNumber: item?.invoiceNumber,
      Supplier: item?.suppliers?.name,
      "Invoice Date": item?.supplyDate,
      Store: item?.store?.branchName,
      "Payment Method": item?.paymentMethod,
      "Payment Status": item?.paymentStatus,
      Attachment: item?.attachment?.url,
    }),
  };

  const tableHeader = {
    [inventory_transferTitle]: [
      { label: "Date", key: "date", type: "date" },
      { label: "Created by", key: "createdBy" },
      { label: "Store(From)", key: "storeFrom.branchName" },
      { label: "Store(To)", key: "storeTo.branchName" },
      { label: "Item", key: "transferredItems[0].itemDetail.englishName" },
      { label: "Quantity", key: "transferredItems[0].quantity" },
    ],
    [stocktakingTitle]: [
      { label: "Date/Time", key: "date" },
      { label: "Created By", key: "createdBy" },
      { label: "Count Name", key: "countName" },
      { label: "Store", key: "store.branchName" },
      { label: "Total Cost", key: "totalCost" },
      { label: "Quantity", key: "quantity" },
    ],
    [sale_invoicesTitle]: [
      { label: "Invoice No", key: "invoiceNumber" },
      { label: "Daily Order No", key: "dailyOrderNumber" },
      { label: "Date", key: "saleDate" },
      { label: "Created by", key: "createdBy" },
      { label: "Customer", key: "customer.name" },
      { label: "Store", key: "store.branchName" },
      { label: "Before Tax", key: "saleInvoiceItem.beforeTax" },
      { label: "Total", key: "saleInvoiceItem.subTotal" },
      {
        label: "Status",
        key: "status",
        bgColorKey: {
          Valid: "bg-success-50 text-positive-600",
          Return: "bg-danger-100 text-danger-600",
        },
      },
      { label: "Payment Method", key: "paymentMethod" },
    ],
    [purchase_invoicesTitle]: [
      { label: "Invoice No", key: "invoiceNumber" },
      { label: "Date", key: "supplyDate" },
      { label: "Created by", key: "createdBy" },
      { label: "Supplier", key: "suppliers.name" },
      { label: "Store", key: "store.branchName" },
      { label: "Before Tax", key: "purchaseInvoiceItem.beforeTax" },
      { label: "Total", key: "purchaseInvoiceItem.subTotal" },
      {
        label: "Status",
        key: "paymentStatus",
        bgColorKey: {
          "Fully Paid": "bg-success-50 text-positive-600",
          "Partially Paid": "bg-danger-100 text-danger-600",
        },
      },
      { label: "Payment Method", key: "paymentMethod" },
    ],
  };

  const itemDetails = {
    [inventory_transferTitle]: (item) => ({}),
    [stocktakingTitle]: (item) => ({
      Item: item?.itemDetails?.englishName,
      Quantity: item?.itemDetails?.quantity,
      "Cost Price": `$ ${item?.itemDetails?.itemCost}`,
      Note: item?.note,
    }),
    [sale_invoicesTitle]: (item) => ({
      Item: item?.saleInvoiceItem?.item?.englishName,
      "Unit Price": `$ ${item?.saleInvoiceItem?.unitPrice}`,
      Quantity: item?.saleInvoiceItem?.quantity,
      "Before Tax": `$ ${item?.saleInvoiceItem?.beforeTax}`,
      "Tax Amount": `$ ${item?.saleInvoiceItem?.taxAmount}`,
      Subtotal: `$ ${item?.saleInvoiceItem?.subTotal}`,
    }),
    [purchase_invoicesTitle]: (item) => ({
      Item: item?.purchaseInvoiceItem?.item?.englishName,
      "Unit Price": `$ ${item?.purchaseInvoiceItem?.unitPrice}`,
      Quantity: item?.purchaseInvoiceItem?.quantity,
      "Before Tax": `$ ${item?.purchaseInvoiceItem?.beforeTax}`,
      "Tax Amount": `$ ${item?.purchaseInvoiceItem?.taxAmount}`,
      Subtotal: `$ ${item?.purchaseInvoiceItem?.subTotal}`,
    }),
  };

  const itemImage = {
    [inventory_transferTitle]: (item) => ({}),
    [stocktakingTitle]: (item) => item.itemDetails?.item?.image,
    [sale_invoicesTitle]: (item) => item.saleInvoiceItem?.item?.image,
    [purchase_invoicesTitle]: (item) => item.purchaseInvoiceItem?.item?.image,
  };

  /**
   * Handles the api call for getting all inventory transfers
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting inventory transfers
   *    order: order for sorting inventory transfers by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = [
      "createdBy",
      "storeTo.branchName",
      "storeFrom.branchName",
    ];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllInventoryTransfers({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, query, dispatch]);

  /**
   * Handles the api call for getting all stocktaking
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stocktaking
   *    order: order for sorting stocktaking by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["createdBy", "countName"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllStocktakings({ payload }));
  }, [currentPage, query, dispatch]);

  /**
   * Handles the api call for getting all sale invoices
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting sale invoices
   *    order: order for sorting sale invoices by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["invoiceNumber", "createdBy"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllSaleInvoices({ payload }));
  }, [currentPage, query, dispatch]);

  /**
   * Handles the api call for getting all purchase invoices
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting purchase invoices
   *    order: order for sorting purchase invoices by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["createdBy", "invoiceNumber"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllPurchaseInvoices({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, query]);

  return {
    inventoryTransferList,
    inventoryTransferListLoading,
    stocktakingListLoading,
    saleInvoicesListLoading,
    purchaseInvoicesList,
    purchaseInvoicesListLoading,
    stocktakingList,
    saleInvoicesList,
    inventory_transferTitle,
    stocktakingTitle,
    sale_invoicesTitle,
    purchase_invoicesTitle,
    dataDetails,
    tableHeader,
    itemDetails,
    itemImage,
    currentPage,
    setCurrentPage,
  };
};

export default useInventoryTransactions;
