import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendOtp, verifyOtp } from "../../features/Auth/AuthSlice";
import { getUser } from "../common/utils/users.utils";
import { useNavigate } from "react-router-dom";

export const useConfirmEmail = () => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState(""); // State to store email
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * Custom hook for retrieving query parameters from the URL.
   */

  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   */

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  // Initial setup to get the email from localStorage
  // Initial setup to get the email from localStorage
  useEffect(() => {
    const storedUserData = getUser("user");
    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      const userEmail = parsedData?.user?.email; // Accessing the email from the user object
      if (userEmail) {
        setEmail(userEmail); // Set email in state
      }
    }
  }, []);

  /**
   * Handles input change events for OTP fields.
   * Updates the code state and focuses on the next input field if needed.
   * @param {object} element - The DOM element of the input field.
   * @param {number} index - The index of the input field in the OTP code array.
   */
  const handleInputChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  /**
   * Handles form submission.
   * Concatenates OTP inputs into a single code and dispatches verification action.
   * @param {object} data - Form data submitted by the user.
   */
  const onSubmit = (data) => {
    setIsSubmitted(true);
    const otpCode = parseInt(
      `${data.code0}${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}`
    );
    const payload = {
      email: email,
      otpCode: otpCode,
    };
    return dispatch(
      verifyOtp({ payload, successCallBack: confirmEmailRouter })
    );
  };

  /**
   * Callback function executed upon successful OTP verification.
   * Navigates to the create-password page with the user's email.
   * @param {object} data - Response data from the OTP verification process.
   */
  const confirmEmailRouter = (data) => {
    if (data?.data?.Succeeded) {
      const userData = data.data.data;
      getUser("user", JSON.stringify(userData));
      navigate("/create-password");

      const storedUserData = getUser("user");
      if (storedUserData) {
        const parsedUserData = JSON.parse(storedUserData);
        if (parsedUserData?.email) {
          setEmail(parsedUserData.email);
        }
      }
    }
  };

  const resendOtp = () => {
    if (email) {
      const payload = {
        email: email,
      };
      dispatch(sendOtp({ payload }));
    } else {
      console.error("Email is not available for resending OTP");
    }
  };

  return {
    code,
    isSubmitted,
    setIsSubmitted,
    register,
    handleSubmit,
    errors,
    handleInputChange,
    onSubmit,
    email,
    resendOtp,
    isSubmitting,
  };
};
