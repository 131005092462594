import React, { useEffect, useState } from "react";
import CustomAdminTable from "../../../../common/components/AdminTable/CustomAdminTable";
import CustomSearch from "../../../../common/components/CustomSearch/CustomSearch";
import EditClient from "./Edit Clients/EditClient";
import DeleteClient from "./DeleteClient/DeleteClient";
import useClients from "./useClients";
import { CustomButton, SelectGroup } from "../../../../common/components";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { ArrowDown, UploadIcon } from "../../../../common/Icons";
import useDebounce from "../../../../../hooks/useDebounce";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "../../../../../features/GlobalSearch/GlobalSearchSlice";
import { handleExport } from "../../../../../helpers/handleExport";
import { ROLE } from "../../../../../enums/role";

function Clients() {
  const {
    setClientsList,
    clientData,
    handleRowClick,
    tableHeaders,
    isModalOpen,
    setModalOpen,
    isDeleteModalOpen,
    setDeleteModalOpen,
    transformData,
    clientsList,
    clientsDropdownOptions,
    setUpdateClient,
    currentPage,
    setCurrentPage,
    staffUserOptions,
    PlanTypes,
    SortfromLatest,
    setSelectedStaffUser,
    setSelectedPlanType,
    storedUserData,
    setSelectedSort,
  } = useClients();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const dispatch = useDispatch();

  // Effect to dispatch the debounced search value
  useEffect(() => {
    dispatch(setSearchQuery(debouncedSearchValue));
  }, [debouncedSearchValue, dispatch]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <div className="">
      <div className="flex items-center space-x-4 sticky ">
        <div className="flex items-center flex-grow">
          <form className="relative flex " action="#" method="GET">
            <CustomSearch
              placeholder="Search..."
              inputName="search"
              paddingY="py-2"
              onChange={handleInputChange}
            />
          </form>
          <div className="flex items-center flex-grow gap-2 ml-2">
            <div className="flex items-center flex-grow gap-2">
              <SelectGroup
                htmlFor="planType"
                inputName="planType"
                width="w-fit"
                options={PlanTypes}
                textColor="text-text-primary"
                className="!py-2 min-w-32"
                onChange={(e) => {
                  setSelectedPlanType(e.target.value);
                }}
              />
              {storedUserData?.user?.role !== ROLE.ACCOUNT_MANAGER && (
                <SelectGroup
                  htmlFor="subscriptionProvider"
                  inputName="subscriptionProvider"
                  width="w-fit"
                  options={staffUserOptions}
                  textColor="text-text-primary"
                  className="!py-2 min-w-60"
                  onChange={(e) => {
                    setSelectedStaffUser(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <SelectGroup
          htmlFor="sort"
          inputName="sort"
          width="w-fit"
          options={SortfromLatest}
          textColor="text-text-primary"
          className="!py-2 min-w-44"
          onChange={(e) => {
            setSelectedSort(e.target.value);
          }}
        />
        <CustomButton
          text="Export"
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          icon={<UploadIcon />}
          onClick={() => handleExport("user/export-clients", "Clients List")}
        />
      </div>
      <div className="h-[calc(110vh_-_28vh)] flex flex-col justify-between">
        <CustomAdminTable
          tableBody={transformData(clientsList)}
          handleRowClick={handleRowClick}
          headers={tableHeaders}
          showSecondLastColumn={false}
          showImages={true}
          dropdownOptions={clientsDropdownOptions}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={clientsList ? clientsList?.length : 0}
        />
      </div>

      {isModalOpen && (
        <EditClient
          clientId={clientData?.id}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          setUpdateClient={setUpdateClient}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteClient
          isDeleteModalOpen={isDeleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          data={clientData}
          setAllData={setClientsList}
        />
      )}
    </div>
  );
}

export default Clients;
