import React from "react";
import Footer from "../components/LandingPage/Footer";
import Navbar from "../components/LandingPage/Navbar";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <div className="w-full 1xl:px-29 px-4 relative">
        <Navbar />
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
            Privacy policy
          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
            Please carefully read these Terms and Conditions (“Terms “) before
            using or accessing any product or services offered by Tag Dev
          </p>
        </div>

        <p className="text-text-primary text-lg font-medium mt-10">
          The Tag Dev Terms of Service Agreement (“Agreement”) dictate the legal
          agreement between you; the person, organization, or individual persons
          acting on behalf of that person or organization (“you”, “your”, “User”
          or “Users”) and Tag Dev (“Tag Dev”, “We” or “Us”), governing Tag Dev
          Applications (“App” or “Apps”), the Tag Dev website, any other
          services or products offered in any form (Software, Hardware or
          Service), or rights of use offered, collectively referred to as
          (“Services”).
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User hereby accepts and agrees to all of the Terms of the
          Agreement for access to, or use of, Tag Dev Services (access and/or
          use subject to your agreed service package). If you do not agree to
          these Terms then you may not avail any of the Services offered by Tag
          Dev.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          By accessing or using the Services, downloading the App or ordering
          any of the Services, you are accepting and agreeing to the Terms and
          are legally binding yourself or the entity you represent to this
          Agreement. The Terms apply to all users of the Services, all users
          refers to but is not limited to sellers, buyers, or contributors of
          content or services
        </p>
        <h2 className="text-xl font-bold text-text-primary my-3">AMENDMENTS</h2>
        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev is at liberty to change or make amendments these Terms at any
          given time. Notification of the changes will be sent to you through
          email or through a message when you next use the Services. If you
          continue to avail the Services after changes to the Terms then your
          access and use of the Services will be in accordance to the latest
          Agreement.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          We are also at liberty to change, add or remove, any features,
          functionalities or offered facilities, and resources, from the
          Services at any given time. You may be required to update, or install
          the latest version of the App or upgrade the hardware in order to
          avail any changes or additions in the Services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          These Terms in no way guarantee that the Services will always be
          available. Tag Dev is also not bound by these Terms to provide support
          and maintenance for the Services, or to continue to offer them. We may
          altogether cease to provide all or some of the Services at any given
          time
        </p>

        {/*  */}
        <h2 className="text-xl font-bold text-text-primary my-3">
          DEFINITIONS
        </h2>
        <p className="text-text-primary text-lg font-medium mt-4">
          “Tag Dev Account” an account created to access the Tag Dev Services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          “Intellectual Property” refers to any and all copyright, trademark,
          service mark, patent, trade dress, mask work right, rights of
          publicity, goodwill, moral rights, trade secret rights, and other
          rights regarding intellectual property that currently exist or exist
          in the future.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          “Content” refers to any text, images, graphics, photos, audio, video,
          information about locations and every other form of data used.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          “User Content” refers to Content submitted by the User for the purpose
          of (but not limited to) marketing, offering and selling their products
          and services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          “User Data” refers to the Content submitted or uploaded by the User to
          or through Tag Dev Services. These include but are not limited to
          reviews, comments, personal information, messages and information
          provided to your account for public and private use.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          “Product List” a list of items, in correspondence with the type of
          business, the User offers, markets, sells and can be bought, through
          the Services or the use of the Services.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          Tag Dev ACCOUNT
        </h2>
        <p className="text-text-primary text-lg font-medium mt-4">
          In order to access and avail Tag Dev services you will need to create
          a Tag Dev Account. For that you must be of legal age and have the
          necessary authority to accept these Terms in order to access and use
          the Services. You must also guarantee that the information provided
          (both personal and relating to your business) is accurate, up to date
          and complete, and you must update any changes, or amendments that may
          arise, in the information you previously provided.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          If you open a Tag Dev Account for another entity (such as a
          company/organization or another person), then you guarantee that you
          are the authorized representative of that entity and you carry the
          authority to bind that entity to this Agreement on their behalf.
        </p>

        {/*  */}
        <h2 className="text-xl font-bold text-text-primary my-3">
          ACCOUNT PAYMENT
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          Subscribing for a Tag Dev Account means that you have authorized us to
          deduct payment for your subscription period in the beginning of that
          subscription. Tag Dev will also continue to deduct payment for each
          period that follows the first subscription period, in the beginning of
          that particular subscription period.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User agrees that Tag Dev will charge all applicable due charges,
          through the form of payment chosen by the User at the time of
          subscribing to the Tag Dev Account. Tag Dev may use a third party
          vendor to act as a medium for business settlements, card transactions
          or any form of payment services. The User agrees to comply to the
          terms and conditions of the aforementioned third party for all related
          services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User also authorizes Tag Dev and the third party vendor to share
          any information of the User that is that is required to complete the
          payment transactions (eg: personal information, credit card
          information and financial information).
        </p>
        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">FREE TRIAL</h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          For Users who would like to try out Tag Dev Services, Tag Dev offers a
          Free Trial of 14 days for a specified list of services. If the User
          opts to continue using the Services after the trial period ends, then
          the User must provide valid information for the chosen payment method,
          for the payment of any charges that can be applied to the subscription
          the User has opted for.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev is not responsible for any notification prior to the
          expiration of the free trial. Tag Dev also reserves the right to
          change or terminate your free trial at any point in time, without
          notification. All charges and fees are exclusive of all taxes and the
          User agrees that Tag Dev is not responsible for any claimed issues or
          penalty by any tax authority.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          TERMINATION OF ACCOUNT
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          If you fail to comply to the Terms then Tag Dev reserves the right to
          terminate your account, remove or change the content, or cancel your
          order of Services, at its sole discretion. Tag Dev also reserves the
          right to terminate your account and remove all concerned data if your
          account has remained inactive for a period of 180 days consecutively.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev is at liberty to terminate or suspend all or some activities
          on your Tag Dev Account without any prior notice or explanation for
          reasons including, violation of any of the Terms or policies. Upon
          termination of the Agreement the license to use the Services will end
          and the User has to immediately cease the use of any of the Services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          After termination, the User will be given 30 days from the termination
          date to extract their User Content and User Data. After this time
          slot, Tag Dev is at liberty to delete the User Content and User with
          out any liability to the User or any third party.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          ACCOUNT LIABILITY
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          For users who observe Tag Dev policies, we provide a means to conduct
          business and transactions through buying, selling and marketing
          certain products. But Tag Dev is in no way liable for the nature of
          transaction between buyer and seller, the authenticity, quality and
          safety of the products listed by the seller, or the reviews of said
          products.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev is also not responsible for the completion of any transaction
          between buyers and sellers. Tag Dev will not conduct any investigation
          into the accuracy of the information provided by the Users or the
          Users themselves. All activities that occur under a Tag Dev Account
          will be the sole responsibility of the User and the User must
          immediately notify in case of unauthorized use or if there is
          indication of any breach of security in their Tag Dev Account. The
          User is also responsible for maintaining and securing the
          confidentiality of your account information and password.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User agrees to abide by the laws of the country (or countries)
          that their business resides in regards to their activities using the
          Tag Dev Account or the Services. The User agrees to refrain from
          activities that are, or can be considered illegal, misleading,
          fraudulent, or questionable, or using content that may be considered
          inappropriate or defamatory, or use the Services in a manner that is
          harmful to Tag Dev systems, security or other Users. The User agrees
          to not violate any third party’s rights, cause an infringement of any
          third party’s intellectual property or misuse internal secrets of a
          third party, through their use of the Services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User also agrees to refrain from collecting information and data
          from our Services, systems or servers and the user agrees to refrain
          from attempts at capturing or deciphering any transmissions
          communicated to and from our servers. The User must not use
          information or data of another User to any third party for any reason
          or purpose (including commercial or marketing purposes) unless the
          other User has agreed to beforehand. The User is forbidden from using
          any automated methods or bots for logging into their Tag Dev Account.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          CANCELLATION OF ACCOUNT
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          The User is at liberty to cancel their subscription of Tag Dev
          Services at their will. You must be logged into your account in order
          to cancel your subscription and if your subscription is cancelled
          before the end of the term you have paid for then you may avail the
          Services till the end of that term, after which your subscription will
          not be renewed and your Tag Dev Account will be cancelled. You will
          not be provided any refund of the subscription period you want to
          revoke (unless we have agreed to prior to the cancellation).
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Upon the full cancellation of your account the following will take
          place:
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Your account will be deactivated <br />
          Your account data will be deleted and Tag Dev is not responsible for
          the recovery of any material, content or information. <br /> Your
          rights to our Services granted under this Agreement will cease.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          AVAILABILITY
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev does not guarantee that any of the Services will always be
          available or that User will have uninterrupted, timely and secure
          access to the Services. The Services may be upgraded, changed,
          interrupted or discontinued at any time without prior notice.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev also does not guarantee that the Services will always be
          reliable and the quality of products, services or information obtained
          through the Services will be according to your expectations. Tag Dev
          also does not guarantee that any error that may occur will be
          addressed and corrected.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          CHANGING YOUR SUBSCRIPTION TYPE
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          The User is at liberty to scale up or down from their subscription
          package from their accounts. Your changes to your subscription will
          take place immediately, if you have scaled down then there will be no
          refunds on the unused balance of the lower subscription package.
          However, scaling down from a higher subscription package to a lower
          one may result in a loss of access to some extent and/or a loss of
          certain features which may lead to loss of content. Tag Dev is not
          liable for any loss of content, access or features that may result
          from a change in the subscription package.
          <br />
          If you have scaled up then the balance already paid will be considered
          as part of the higher subscription package and Tag Dev will
          immediately charge any remaining balance of the new subscription
          package from your preferred method of payment.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          USER CONTENT AND USER DATA
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          The User guarantees that they carry the authority to use the Content
          or grant permission of its use. The User assumes all responsibility
          for their content and for the risks involving the validity,
          authenticity, quality and reliability of the Content, or anyone’s
          opinion on its accuracy. For any content that makes the User
          personally identifiable, Tag Dev will not take any liability. The User
          may not in any way imply or claim that Tag Dev has in any way
          endorsed, supported or sponsored the Content.
        </p>
        <p>
          It is not always possible for the Content to be removed once
          published. Tag Dev will assume no responsibility for any Content
          posted or provided by you or any third party. For every item or
          service you offer in your business, you guarantee that the selling,
          procuring and marketing of said item or service has been done legally
          and the description provided is accurate. Your Product List may
          include textual descriptions, images and other form of graphics
          pertinent to the sale of that product.
        </p>
        <p>
          Tag Dev is also not responsible for the safety and confidentiality of
          the Content and is not liable to any damages or loss that may occur to
          the Content and User Data. Tag Dev has no responsibility to provide
          you with a replica or backup of your Content or Data. Tag Dev also has
          the authority to monitor, remove, edit or change any activity or
          Content and User Data, or use it for advertising purposes at their
          discretion without any prior notification.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">SECURITY</h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev offers you security measures to prevent loss of data or
          unauthorized alteration or access of personal information. Tag Dev ,
          however, does not guarantee that breach of security, that occurs
          through the breaking of such measures, will not take place. The User
          acknowledges that they are providing their personal information and
          data at their own risk.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          THIRD PARTY SUPPLIERS
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          The User allows Tag Dev to use or integrate Third Party Vendors to
          provide any service or product that may be deemed as a requirement for
          the provision of Our Services. The User agrees to abide by the Terms
          of Service of these third party vendors and the User agrees to not
          hold Tag Dev responsible for any action taken by the third party
          vendor, even when that act may result in any kind of loss or damage,
          including but not limited to; personal injury, property damage (even
          to your residense) or loses to business matters, products or services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          You also give Tag Dev the authority to share, exchange and control
          your data (both personal and business related) with these third party
          vendors in order to provide you with our Services. The information
          shared with third party vendors, upon the exchange will come under the
          Terms and Conditions and Privacy Policy of that third party that are
          separate from Tag Dev’s Terms and Conditions and Privacy Policy.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User acknowledges that the third party products and services
          integrated into the Services are not governed by Tag Dev and this
          Agreement does not apply to third party services and products (unless
          specified otherwise).
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The Tag Dev Services may contain links to third party sites and
          advertisements and should you click on these links, Tag Dev will not
          notify you that you have become subject to the terms and conditions
          and privacy policies of that third party and have left the
          jurisdiction of this Agreement. Such third party sites and
          advertisements do not come under the governance of Tag Dev and are not
          the responsibility of Tag Dev . Such content is displayed only as a
          convenience and is not reviewed, warranted, monitored, endorsed or
          represented by Tag Dev . Such links are used at the Users own risk.
          The User is advised to review the Terms and Conditions and the Privacy
          Policies of such third party sites, applications and adverts, and make
          the appropriate investigation before proceeding with any agreement or
          transaction.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          INTELLECTUAL PROPERTY
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          None of Tag Dev's Intellectual Property or registered trademarks may
          be used in any form, in association with other trademarks, products or
          services or in any manner that may create confusion in regards to Tag
          Dev Services, products, legal rights or any other offerings or
          representations. The User agrees to acknowledge Tag Dev’s ownership of
          its Intellectual Property rights that exist in Tag Dev Services. The
          User agrees not to take action in any way that is inconsistent with,
          or challenges such ownership.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev lists under its Intellectual Property and registered
          trademarks, the following items: all Tag Dev graphics including but
          not limited to: logos, buttons, icons, designs, page headers, scripts
          and service names, the Tag Dev name, brand and title. Tag Dev’s
          trademarks may not be used in any way or form, as part of domain names
          of any email addresses or websites. The User agrees to not publish or
          use Tag Dev’s Intellectual Property, brand and any such material, that
          falls under the ownership of Tag Dev, unless a written agreement has
          been made with Tag Dev beforehand.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The User data and information, collected through the User’s use of the
          Services falls under the jurisdiction of a royalty-free license that
          the User agrees to grant Tag Dev, for an unlimited period of time.
          This data includes User feedback and reviews regarding the Services.
          Under this license, Tag Dev may use the User Data and information and
          may modify, or incorporate your feedback or comments, for commercial
          purposes and other use. Tag Dev agrees to keep the User anonymous and
          to use User Data collectively with that of other Tag Dev Users.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          DISCLAIMER OF WARRANTY
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          ALL OF THE CONTENT, MATERIAL, PRODUCTS (INCLUDING HARDWARE AND
          SOFTWARE) AND SERVICES OFFERED BY Tag Dev ARE PROVIDED ON AN “AS IS”
          AND “AS AVAILABLE” BASIS AND THIER USE IS AT THE SOLE RISK OF THE
          USER. Tag Dev PROVIDES NO WARRANTY OF ANY KIND (EVEN IMPLIED) ON THE
          OPERATION OF THE SERVICES (BOTH HARDWARE AND SOFTWARE) OR THE DATA,
          INFORMATION, CONTENT, ANY OTHER FORM OF MATERIAL OR PRODUCTS
          (INCLUDING SOFTWARE AND HARDWARE) AND SERVICES OFFERED OR MADE
          AVAILABLE TO YOU. Tag Dev DISCLAIMS ALL WARRANTIES (EVEN IMPLIED)
          INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE, FITNESS OF; PRODUCT
          TO BE SOLD AND BOUGHT, OR FOR ANY PARTICULAR PURPOSE, OR THAT ANY
          CONTENT, MATERIAL, PRODUCT (INCLUDING SOFTWARE AND HARDWARE), SERVICE
          OR COMMUNICATION FROM Tag Dev (THEIR WEBSITE, SERVERS OR APPLICATIONS)
          WILL BE FREE OF VIRUSES OR HARMFUL ITEMS.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          TAG DEV IS NOT LIABLE FOR ANY DAMAGES OR LOSSES THAT MAY OCCUR TO
          PROFITS, BUSINESS, GOODWILL, DATA OR ANY OTHER FORM OF INTANGIBLE OR
          TANGIBLE LOSS, FROM THE USE OF THE SERVICES OR FROM ANY CONTENT,
          MATERIAL OR INFORMATION OFFERED OR PROVIDED TO YOU BY TAG DEV THROUGH
          THE TAG DEV SERVICES.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          TAG DEV IS ALSO NOT LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT FROM
          FAILURE IN PERFORMANCE, ERRORS, VIRUSES, INTERRUPTIONS IN SERVICE OR
          COMMUNICATIONS, DELAYS IN TRANSMISSIONS, DELETION OF DATA OR
          INFORMATION, OR DEFECTS. THESE MAY OR MAY NOT BE THE RESULT OF
          ENVIRONMENTAL ISSUES, UNAUTHORIZED ACCESS, THEFT OR DESTRUCTION OF TAG
          DEV DOCUMENTATION, DATA AND/OR SERVICES AND PRODUCTS, WHETHER
          DIRECTLY, INDIRECTLY, ACCIDENTALLY, PURPOSEFULLY OR DONE IN ANY OTHER
          FORM, FOR ANY OTHER REASON, EVEN IN THE CASE OF Tag Dev STATING THE
          POSSIBILITY OF THE OCCURANCE OF SUCH CASES.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          USER AGREES TO INDEMNIFY AND HOLD TAG DEV AND ALL PARENT, AFFILIATES,
          SUBSIDAIRES, OFFICERS, EXECUTIVES, DIRECTORS AND EMPLOYEES HARMLESS
          FROM ANY CLAIM, LIABILITY, CHARGES, DAMAGES, LOSSES, PENALTIES,
          INTEREST, TAX ASSESSMENTS OR EXPENSES THAT MAY ARISE DUE TO ANY
          ACTION, INVESTIGATION OR AUDIT BY A THIRD PARTY OR PERSON FOR REASONS
          INCLUDING BUT NOT LIMITED TO:
          <br />
          <ul className="list-disc pl-4">
            <li>
              Any breach (even alleged) of this Agreement, the terms and
              policies defined in this Agreement, or documents incorporated by
              reference in this Agreement
            </li>
            <li>
              Your violation of any law, rule or regulation of your country
            </li>
            <li>
              Your violation of a third partys rights including, but not limited
              to, right of privacy, publicity or Intellectual Property.
            </li>
            <li>Your use of, or in ability to use, Tag Dev Services</li>
          </ul>
          <br />
          THIS DISCLAIMER HOLDS, TO THE FULL EXTENT PERMITTED BY APPLICABLE BY
          LAW, UNLESS SPECIFIED IN WRITING.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          RIGHTS AND LIMITATIONS
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev may provide technical support and assure that the
          functionality of the Services is adequate by monitoring the devices,
          computers, and systems (collectively “Devices”) that may contain Tag
          Dev software and applications or are connected to the Tag Dev network,
          through the use of certain tools and utilities included in Tag Dev
          software and applications, that allow Tag Dev remote access to such
          Devices.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The user grants Tag Dev the rights to use these tools and utilities
          and also allows Tag Dev to log into these Devices and modify and
          manipulate the data for the purposes of improving or maintaining the
          functionality of the Services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The user grants Tag Dev the rights to use these tools and utilities
          and also allows Tag Dev to log into these Devices and modify and
          manipulate the data for the purposes of improving or maintaining the
          functionality of the Services.
          <br />
          <ul className="list-disc pl-8 mt-3">
            <li>
              The amount of sale transactions from the Users Account per month
            </li>
            <li>The quantity of storage required for the User data</li>
            <li>
              The number of times the User page is accessed within a 5 minute
              period
            </li>
            <li>
              The number of times and API (Application Programming Interface) is
              called within a 5 minute period
            </li>
            <li>
              The bandwidth the user takes up within the span of one day (24
              hours)
            </li>
            <li>The number of outlets, customers and products</li>
            <li>The number of support tickets opened per month</li>
          </ul>
          <br />
          If Tag Dev does not immediately enforce regulations or take action for
          any breach of the Terms, then that does not in any way mean that the
          Terms or rights have been waived, nor does it mean that you do not
          have to follow those policies and Terms. Tag Dev is not at liberty to,
          but will endeavor to notify the Users of any limitations to their
          services, should the need arise.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">
          TERMS FOR APPLE STORE DOWNLOADS
        </h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          The following Terms apply to those users who have obtained their Tag
          Dev Application(s) from the Apple App Store. 1. The User must comply
          to the Terms of Service of the Apple App Store including Usage Rules.
          2. This Agreement only dictates the Terms between You, the User and
          Tag Dev and does not reflect Apples Terms and Conditions in any way.
          Apple has no obligation to provide any maintenance or support to you
          in relation to the App. 3. Tag Dev is the sole entity responsible for
          the App, it’s maintenance and contents (subject to these Terms). If
          the App warranty (as per these Terms) is invoked due to any failure or
          issue in the App, then User may notify Apple for a refund. Beyond
          this, as permitted by applicable law, Apple has no other warranty
          obligations 4. Apple has no liability for any claims over the App in
          regards to compliance issues in legal or regulatory policies or
          infringement of any rights or intellectual property 5. Once the User
          accepts these Terms, Apple and its subsidiaries have a right to
          enforce these Terms against the User.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The user grants Tag Dev the rights to use these tools and utilities
          and also allows Tag Dev to log into these Devices and modify and
          manipulate the data for the purposes of improving or maintaining the
          functionality of the Services.
        </p>
        <p className="text-text-primary text-lg font-medium mt-4">
          The user grants Tag Dev the rights to use these tools and utilities
          and also allows Tag Dev to log into these Devices and modify and
          manipulate the data for the purposes of improving or maintaining the
          functionality of the Services.
          <br />
          <ul className="list-disc pl-8 mt-3">
            <li>
              The amount of sale transactions from the Users Account per month
            </li>
            <li>The quantity of storage required for the User data</li>
            <li>
              The number of times the User page is accessed within a 5 minute
              period
            </li>
            <li>
              The number of times and API (Application Programming Interface) is
              called within a 5 minute period
            </li>
            <li>
              The bandwidth the user takes up within the span of one day (24
              hours)
            </li>
            <li>The number of outlets, customers and products</li>
            <li>The number of support tickets opened per month</li>
          </ul>
          <br />
          If Tag Dev does not immediately enforce regulations or take action for
          any breach of the Terms, then that does not in any way mean that the
          Terms or rights have been waived, nor does it mean that you do not
          have to follow those policies and Terms. Tag Dev is not at liberty to,
          but will endeavor to notify the Users of any limitations to their
          services, should the need arise.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">FEES</h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          The charges for using the Services (“Fees”) have been dictated on the
          Tag Dev website and may be subject to change, atTag Dev’s discretion.
          Any changes in the Fees will be notified to the User atleast 30 days
          prior. The Fees are to be deducted in advance of every subscription
          period (with a subscription period lasting only a month or for an
          entire year, depending on the chosen plan). The Fees are
          non-refundable unless agreed to otherwise beforehand or unless
          required by law.
          <br />
          <br />
          By providing an appropriate payment method such as a credit card, you
          guarantee that you are authorized to use that mode of payment and you
          authorize us, or any third party associated with us for this purpose,
          to immediately deduct the charges due to Tag Dev for the Services with
          the requirement of any further consent or notice. The User is at
          liberty to update or change their payment information from their Tag
          Dev Account whenever they please. If your payment method proves
          invalid or unacceptable then your access to the Services may be
          terminated or suspended.
        </p>

        {/*  */}

        <h2 className="text-xl font-bold text-text-primary my-3">TAXES</h2>

        <p className="text-text-primary text-lg font-medium mt-4">
          Tag Dev Fees do not include Taxes unless specified otherwise and all
          payments will be made to Tag Dev without the reduction of any taxes or
          interest. If any of the Services or payment for the Services are
          subject to Tax in accordance with the applicable law then the User is
          solely responsible for the payment of that Tax and any related penalty
          to the appropriate tax authority. The User will indemnify and hold Tag
          Dev and its affiliates harmless against any such claims or expenses
          that may incur in relation to such taxes
        </p>
      </div>
      {/* footer */}
      <Footer className="mt-20" />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
