import React from "react";
import { CustomButton } from "../../../../common/components";
import { ArrowDown } from "../../../../common/Icons";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the chart components you need
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 10, // set checkbox width
        boxHeight: 10, // set checkbox height
        usePointStyle: true, // use circle instead of square
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        drawBorder: false, // This will hide the border
      },
    },
  },
};

const data = {
  labels: ["May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Cashier 1",
      data: [50, 50, 50, 50, 50, 50, 50, 50],
      backgroundColor: "#53B1FD",
      borderRadius: 8,
    },
    {
      label: "Cashier 2",
      data: [65, 65, 65, 65, 65, 65, 65, 65],
      backgroundColor: "#2BE3B7",
      borderRadius: 8,
    },
    {
      label: "Cashier 3",
      data: [75, 75, 75, 75, 75, 75, 75, 75],
      backgroundColor: "#92E4FE",
      borderRadius: 8,
    },
    {
      label: "Cashier 4",
      data: [90, 84, 80, 75, 80, 90, 83, 90],
      backgroundColor: "#2E90FA",
      borderRadius: 8,
    },
    {
      label: "Cashier 5",
      data: [50, 53, 53, 56, 54, 51, 65, 65],
      backgroundColor: "#5BBAC0",
      borderRadius: 8,
    },
    {
      label: "Cashier 6",
      data: [43, 43, 43, 43, 43, 43, 43, 43],
      backgroundColor: "#6FFFBA",
      borderRadius: 8,
    },
  ],
};

function SalesByCashier() {
  return (
    <div className="my-2 border rounded-lg  p-3">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-text-primary">
          Sales by Cashier
        </h2>
        <span className="flex justify-between gap-2">
          <CustomButton
            icon={<ArrowDown />}
            width="w-fit"
            border="border"
            text="Last  6 Months"
            padding="px-2 py-1"
            textColor="text-text-primary"
            iconPosition="after"
          />
        </span>
      </div>{" "}
      <div className="w-auto h-auto">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}

export default SalesByCashier;
