import { useEffect } from "react";

export const useResetHtmlStyles = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "style"
        ) {
          const element = mutation.target;
          // Reset specific styles if they are not as expected
          if (element.style.overflow === "hidden") {
            element.style.overflow = ""; // Reset to default or a specific value
          }
          if (element.style.paddingRight === "4px") {
            element.style.paddingRight = ""; // Reset to default or a specific value
          }
        }
      });
    });

    const htmlElement = document.documentElement;
    observer.observe(htmlElement, {
      attributes: true,
      attributeFilter: ["style"],
    });

    return () => observer.disconnect(); // Clean up observer when the component is unmounted
  }, []);
};

// Then use this hook in your main App component or wherever makes sense
