import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { getAllItems } from "../../../../../features/Items/ItemsSlice";
import {
  getAllSaleInvoices,
  getSingleSaleInvoice,
} from "../../../../../features/Invoices/SaleInvoices/SaleInvoicesSlice";
import { getAllCustomers } from "../../../../../features/Customers/CustomersSlice";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling sale invoices related functionalities
 *
 * @returns {Object}
 *   saleInvoicesList: Array containing sale invoices information for all invoices
 *   saleInvoicesListLoading: Data loading state for all sale invoices
 *   singleSaleInvoice: Object containing sale invoice information
 *   singleSaleInvoiceLoading: Data loading state for single sale invoice information
 */
const useSaleInvoicesList = (invoiceId, activeTab) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.search);

  // get item list for all items from the store
  const { data: itemList, isLoading: itemListLoading } = useSelector(
    (state) => state.items.getAllItems
  );
  // get customer list for all customers from the store
  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );
  // get store list for all stores from the redux store
  const { data: storesList } = useSelector(
    (state) => state.stores.getAllStores
  );
  // get single sale invoice information from the store
  const { data: singleInvoice, isLoading: singleSaleInvoiceLoading } =
    useSelector((state) => state.saleInvoices.getSingleSaleInvoice);
  // get sale invoices list for all invoices from the store
  const { data: saleInvoicesList, isLoading: saleInvoicesListLoading } =
    useSelector((state) => state.saleInvoices.getAllSaleInvoices);

  /**
   *  Extracting singleSaleInvoice object from singleInvoice object for invoice detail modal
   * @returns {Object}
   *
   */
  const singleSaleInvoice = singleInvoice
    ? {
        Customer: singleInvoice?.customer?.name,
        "Daily Order": singleInvoice?.dailyOrderNumber,
        "Invoice Date": singleInvoice?.saleDate,
        Store: singleInvoice?.store?.branchName,
        "Payment Method": singleInvoice?.paymentMethod,
      }
    : null;
  /**
   *  Extracting itemDetail object from singleInvoice object for invoice detail modal
   * @returns {Object}
   *
   */
  const itemDetail = singleInvoice
    ? {
        Item:
          singleInvoice?.saleInvoiceItem?.slug ??
          singleInvoice?.saleInvoiceItem?.item?.englishName,
        "Unit Price": `${singleInvoice?.saleInvoiceItem?.unitPrice} SAR`,
        Quantity: singleInvoice?.saleInvoiceItem?.quantity,
        "Before Tax": `${singleInvoice?.saleInvoiceItem?.beforeTax} SAR`,
        "Tax Amount": `${singleInvoice?.saleInvoiceItem?.taxAmount} SAR`,
        Subtotal: `${singleInvoice?.saleInvoiceItem?.subTotal} SAR`,
      }
    : null;

  const itemImage = singleInvoice?.saleInvoiceItem?.item?.image;

  useEffect(() => {
    let searchParams = [
      "invoiceNumber",
      "createdBy",
      "customer.name",
      "store.branchName",
    ];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(
        searchParams,
        query,
        activeTab.title === "All"
          ? {}
          : activeTab.title === "POS Transaction"
          ? { isPosInvoice: true }
          : { paymentStatus: "Paid" }
      ),

      attributes: {},
    };
    dispatch(getAllSaleInvoices({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab.title, currentPage, query]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(getSingleSaleInvoice({ invoiceId: invoiceId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  /**
   * Handles the api call for getting all customers
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting customers
   *    order: order for sorting customers by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!customerList) dispatch(getAllCustomers({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all items
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let data = saleInvoicesList?.map((invoice) => ({
  //   "Invoice No": invoice.invoiceNumber,
  //   "Daily Order No": invoice.dailyOrderNumber ?? "_",
  //   Date: invoice.saleDate ?? "_",
  //   "Created by": invoice.createdBy ?? "_",
  //   Customer: invoice.customer ? invoice.customer.name : "_",
  //   Store: invoice.store ? invoice.store.branchName : "_",
  //   "Before Tax": invoice.saleInvoiceItem
  //     ? invoice.saleInvoiceItem.beforeTax
  //     : "_",
  //   Total: invoice.saleInvoiceItem ? invoice.saleInvoiceItem.subTotal : "_",
  //   Status: invoice.status ?? "_",
  //   "Payment Method": invoice.paymentMethod ?? "_",
  // }));

  // const exportToExcel = () => {
  //   const wscols = [
  //     { wch: 15 }, // "Invoice No" column width
  //     { wch: 15 }, // "Daily Order No" column width
  //     { wch: 20 }, // "Date" column width
  //     { wch: 20 }, // "Created by" column width
  //     { wch: 30 }, // "Customer" column width
  //     { wch: 30 }, // "Store" column width
  //     { wch: 20 }, // "Before Tax" column width
  //     { wch: 20 }, // "Total" column width
  //     { wch: 20 }, // "Status" column width
  //     { wch: 20 }, // "Payment Method" column width
  //   ];
  //   const worksheet = XLSX.utils.json_to_sheet(data, {
  //     header: [
  //       "Invoice No",
  //       "Daily Order No",
  //       "Date",
  //       "Created by",
  //       "Customer",
  //       "Store",
  //       "Before Tax",
  //       "Total",
  //       "Status",
  //       "Payment Method",
  //     ],
  //   });
  //   worksheet["!cols"] = wscols;

  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(blob, `${"abc"}.xlsx`);
  // };

  return {
    saleInvoicesList,
    saleInvoicesListLoading,
    singleSaleInvoiceLoading,
    singleSaleInvoice,
    customerListLoading,
    customerList,
    itemList,
    itemDetail,
    itemImage,
    storesList,
    singleInvoice,
    currentPage,
    setCurrentPage,
    itemListLoading,
  };
};

export default useSaleInvoicesList;
