import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import {
  addCustomer,
  deleteCustomer,
  getAllCustomers,
  getSingleCustomer,
  updateCustomer,
} from "../../../../../features/Customers/CustomersSlice";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling customers related functionalities.
 *
 * @returns {Object}
 *   customerList: Array containing customer information for all customers.
 *   isLoading: Data loading state for all customers.
 *   singleCustomer: Object containing customer information.
 *   singleCustomerLoading: Data loading state for single customer information.
 */
const useCustomersList = (customerId) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { query } = useSelector((state) => state.search);

  // get single customer information from the store
  const { data: singleCustomer, isLoading: singleCustomerLoading } =
    useSelector((state) => state.customers.getSingleCustomer);
  // get customer list for all customers from the store
  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );

  const customerDetail = singleCustomer
    ? {
        Customer: singleCustomer?.name,
        Gender: singleCustomer?.gender,
        "Phone Number": singleCustomer?.phoneNumber,
        Email: singleCustomer?.email,
        "Last Order": singleCustomer?.lastOrder ?? "",
        "Total Sales": singleCustomer?.totalSales ?? "",
        Points: singleCustomer?.points ?? "",
      }
    : null;

  // validation schema for add/edit customer form
  const addEditCustomerSchema = z.object({
    name: z
      .string()
      .min(3, "client_dashboard.directory.customer.validations.name_error")
      .max(
        100,
        "client_dashboard.directory.customer.validations.name_error_max"
      ),
    email: z
      .string()
      .min(10, "client_dashboard.directory.customer.validations.email_error")
      .email(
        "client_dashboard.directory.customer.validations.email_error_valid"
      ),
    phonenumber: z.string().refine(
      (phoneNumber) => {
        return phoneNumber.startsWith("+");
      },
      {
        message: "client_dashboard.directory.customer.validations.phone_error",
      }
    ),
    gender: z
      .string()
      .min(1, "client_dashboard.directory.customer.validations.gender_error"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(addEditCustomerSchema),
  });

  const onSubmit = async (data) => {
    const payload = {
      name: data?.name,
      email: data?.email,
      phoneNumber: data?.phonenumber,
      gender: data?.gender,
    };
    if (customerId && modalState?.type === "edit") {
      return dispatch(
        updateCustomer({
          customerId,
          payload,
          successCallBack: onCustomerUpdate,
        })
      );
    } else {
      return dispatch(
        addCustomer({
          payload,
          successCallBack: onCustomerUpdate,
        })
      );
    }
  };

  const onCustomerUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
    dispatch(closeModal());
  };

  /**
   * Handles the api call for getting all customers
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting customers
   *    order: order for sorting customers by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["name", "phoneNumber", "email"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),

      attributes: {},
    };

    dispatch(getAllCustomers({ payload }));
  }, [currentPage, dispatch, query]);

  const deleteCustomerEntry = () => {
    if (customerId) {
      dispatch(
        deleteCustomer({ customerId, successCallBack: onCustomerUpdate })
      );
    }
  };

  useEffect(() => {
    if (customerId) {
      dispatch(getSingleCustomer({ customerId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  // let data = customerList?.map((customer) => ({
  //   Name: customer?.date,
  //   "Phone Number": customer?.phoneNumber ?? "_",
  //   Email: customer?.email ?? "_",
  //   "Last Order": customer?.lastOrder ?? "_",
  //   "Total Sales": customer?.totalSales ?? "_",
  //   Points: customer?.points ?? "_",
  // }));

  // const exportToExcel = () => {
  //   const wscols = [
  //     { wch: 15 },
  //     { wch: 15 },
  //     { wch: 20 },
  //     { wch: 20 },
  //     { wch: 30 },
  //     { wch: 30 },
  //   ];
  //   const worksheet = XLSX.utils.json_to_sheet(data, {
  //     header: [
  //       "Name",
  //       "Phone Number",
  //       "Email",
  //       "Last Order",
  //       "Total Sales",
  //       "Points",
  //     ],
  //   });
  //   worksheet["!cols"] = wscols;

  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  //   saveAs(blob, `${"abc"}.xlsx`);
  // };

  return {
    customerList,
    customerListLoading,
    singleCustomerLoading,
    customerDetail,
    register,
    errors,
    handleSubmission: handleSubmit(onSubmit),
    setValue,
    deleteCustomer: deleteCustomerEntry,
    isSubmitting,
    watch,
    currentPage,
    setCurrentPage,
    clearErrors,
  };
};

export default useCustomersList;
