import { useState } from "react";
import { ArrowUp } from "../../../../common/Icons";
import { CustomTable, SelectGroup } from "../../../../common/components";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import Layout from "../../Layout/Layout";
import usePurchase from "./usePurchase";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";

/**
 * Purchase Reports List component
 * Renders table of Stores list
 *
 * @returns {JSX.Element} JSX code for rendering the Purchase Reports list table
 */

export default function Purchase() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    storesList,
    purchaseReports,
    handleSubmit,
    setValue,
    suppliersList,
    purchaseInvoicesList,
  } = usePurchase(startDate, endDate);

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text="Export"
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          padding="py-2 px-3"
          icon={<ArrowUp className="min-w-6 min-h-6 pb-3" />}
        />
      </div>
      {/* filters */}

      <div className="flex items-center justify-between mt-3">
        <form className="flex items-center gap-3">
          <SelectGroup
            className="max-w-44 min-w-44"
            htmlFor="store"
            inputName="store"
            placeholder="All Store"
            options={storesList?.map((option) => ({
              value: option?.id,
              label: option?.branchName,
            }))}
            onChange={(e) => {
              setValue("store", e.target.value);
              handleSubmit();
            }}
          />
          <SelectGroup
            className="max-w-44 min-w-44"
            htmlFor="supplier"
            inputName="supplier"
            placeholder="All Supplier"
            options={suppliersList?.map((option) => ({
              value: option?.id,
              label: option?.name,
            }))}
            onChange={(e) => {
              setValue("supplier", e.target.value);
              handleSubmit();
            }}
          />
          <SelectGroup
            className="max-w-44 min-w-44"
            htmlFor="createdBy"
            inputName="createdBy"
            placeholder="CreatedBy"
            options={purchaseInvoicesList?.map((option) => ({
              value: option?.createdBy,
              label: option?.createdBy,
            }))}
            onChange={(e) => {
              setValue("createdBy", e.target.value);
              handleSubmit();
            }}
          />
        </form>
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
            handleSubmit();
          }}
        />
      </div>
      {/* data */}
      <CustomTable
        tableHeader={tableHeader}
        tableBody={[purchaseReports] ?? []}
      />
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.report.name", href: "#", current: false },
  { name: "client_dashboard.report.purchase", href: "#", current: true },
];
const tableHeader = [
  { label: "", key: "Purchase Invoice", type: "default" },
  { label: "Total Purchases", key: "purchasesBeforeTax" },
  { label: "Total VAT", key: "purchasesTax" },
  {
    label: "Purchases After VAT",
    key: "purchasesAfterTax",
  },
];
