import React from 'react'
import LoginView from '../../Login/LoginView'
import LoginForm from '../../Login/LoginForm'

function AdminLogin() {
  return (
    <LoginView>
    <LoginForm userType='admin'/>
    </LoginView>
  )
}

export default AdminLogin
