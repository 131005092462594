import { useDispatch, useSelector } from "react-redux";
import { getAllClientSales } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { useForm } from "react-hook-form";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { useEffect } from "react";

/**
 * Custom hook for managing the create account form.
 * @returns {Object} An object containing methods and states for form handling and image upload validation.
 */
export const useClientSale = (startDate, endDate) => {
  const dispatch = useDispatch();

  const { data: salesData, isLoading: cardListLoading } = useSelector(
    (state) => state?.dashboard?.getAllClientSales
  );

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
    };
    dispatch(getAllClientSales({ payload }));
  }, [startDate, endDate, dispatch]);

  return {
    salesData,
    cardListLoading,
  };
};

export default useClientSale;
