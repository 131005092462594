import React from "react";

import { Link } from "react-router-dom";
import {
  CaretRight,
  GmailIcon,
  PhoneIcon,
  VerticalDots,
} from "../../../../common/Icons";
import { Card, CustomButton, Dropdown, Loader } from "../../../../common/components";
import ChangeSubscription from "../ChangeSubscription/ChangeSubscription";
import ExtendSubscription from "../ExtendSubscription/ExtendSubscription";
import CustomAdminModel from "../../../../common/components/AdminModal/CustomAdminModal";
import AddNewSubscription from "../AddNewSubscription/AddNewSubscription";
import CancelSubscription from "../CancelSubscription/CancelSubscription";
import { useSubscribtionClientDetail } from "./useSubscribtionClientDetail";
import AddBranch from "../AddBranch/AddBranch";
import { DefaultImg } from "../../../../../assets/images";

function SubscriptionClientDetail(selectedTab) {
  const {
    toggleDropdown,
    dropdownVisible,
    dropdownRef,
    subscribtiondropdownOptions,
    activeModal,
    closeModal,
    endDate,
    startDate,
    plan,
    subTotal,
    PrivideBy,
    status,
    selectedItem,
    profile,
    businessDetail,
    email,
    subscriptionLoading
  } = useSubscribtionClientDetail(selectedTab);
  if (subscriptionLoading) {
    return <div className="mx-80 mt-20">
    <Loader />
    </div>; 
  }
  return (
    <div>
      <div className="flex items-center space-x-2">
        <Link
          to="/admin/subscriptions"
          className="text-text-gray font-normal text-sm hover:text-sky-blue"
        >
          Subscription
        </Link>{" "}
        <CaretRight />
        <span className="text-text-primary font-medium text-sm">
          {profile?.fullName??"-"}
        </span>
      </div>
      <Card className="mt-4">
        <div className="flex items-center ">
          <img
            src={profile?.profilePic || DefaultImg}
            alt="Pic"
            className="inline-block h-14 w-14 rounded-full mr-6"
          />

          <div className="flex-grow">
            <p className="text-text-primary  text-lg font-semibold">
              {profile?.fullName}( {profile?.arabicName??"-"})
            </p>
            <div className="flex items-center text-text-gray font-normal mt-2">
              <GmailIcon className="mr-2 " />{" "}
              <span className="pl-2 text-text-primary  text-sm font-normal">
                {email??"-"}
              </span>
            </div>{" "}
            <div className="flex items-center text-text-gray font-normal mt-2">
              <PhoneIcon className="mr-2" />{" "}
              <span className="pl-2 text-text-primary  text-sm font-normal">
                {profile?.phoneNumber??"-"}
              </span>
            </div>
          </div>
        </div>
        <div className="pt-4 ">
          <span className="text-text-primary font-normal text-base ">
            Business Info
          </span>
          <Card className="p-4 mt-4">
            <div className="grid grid-cols-3 gap-2">
              <div>
                <p className="font-normal text-text-gray text-sm">
                  Company Name
                </p>
                <p className="font-medium text-text-primary text-base">
                  {businessDetail?.companyName??"-"}
                </p>
              </div>
              <div>
                <p className="font-normal text-text-gray text-sm">Address</p>
                <p className="font-medium text-text-primary text-base">
                  {businessDetail?.companyAddress??"-"}
                </p>
              </div>
              <div className="pl-12">
                <p className="font-normal text-text-gray text-sm ">
                  Commercial Register
                </p>
                <p className="font-medium text-text-primary text-base">
                  {businessDetail?.commercialRegister??"-"}
                </p>
              </div>
              <div>
                <p className="font-normal text-text-gray text-sm">Website</p>
                <Link
                  href={businessDetail?.website??"-"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >
                  {businessDetail?.website??"-"}
                </Link>
              </div>

              <div>
                <p className="font-normal text-text-gray text-sm">VAT</p>
                <p className="font-medium text-text-primary text-base">
                  {businessDetail?.vatNumber??"-"}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </Card>
      <p className="text-text-primary font-normal text-base ml-4 my-4">Plan</p>
      <Card className="p-4 ">
        <div className="grid grid-cols-4 gap-2">
          <div>
            <p className="font-normal text-text-gray text-sm">Advance Plan</p>
            <p className="font-medium text-text-primary text-base">
              {subTotal??"-"}({plan})
            </p>
          </div>
          <div>
            <p className="font-normal text-text-gray text-sm">
              Account Manager
            </p>
            <p className="font-medium text-text-primary text-base">
              {PrivideBy??"-"}
            </p>
          </div>
          <div className="pl-6">
          <p className="font-normal text-text-gray text-sm">Status</p>
          <p
            className={`font-medium text-text-primary text-base border w-fit p-1 rounded-lg ${
              status === "ACTIVE"
                ? "bg-green-200 text-green-600"
                : "bg-red-300 text-red-500"
            }`}
          >
            {status === "ACTIVE" ? "Active" : "Inactive"}
          </p>
        </div>
        
        <div className="pl-1 relative">
        <CustomButton
          icon={<VerticalDots />}
          width="w-fit"
          padding="px-2 py-2"
          bgColor="bg-gray-400"
          hoverBgColor="bg-gray-800"
          className="!border"
          onClick={toggleDropdown}
        />
        {dropdownVisible && (
          <div
            ref={dropdownRef}
            className="absolute right-40 bottom-full mb-2  bg-white shadow-lg rounded z-10"
          >
            <Dropdown options={subscribtiondropdownOptions} />
          </div>
        )}
      </div>

          <div>
            <p className="font-normal text-text-gray text-sm">Start Date</p>
            <p>{startDate??"-"}</p>
          </div>

          <div>
            <p className="font-normal text-text-gray text-sm">End Date</p>
            <p className="font-medium text-text-primary text-base">
              {" "}
              {endDate??"-"}{" "}
            </p>
          </div>
        </div>
      </Card>
      {activeModal === "AddBranches" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[650px]"
          title="Add Branch"
        >
          <AddBranch closeModal={closeModal} selectedItem={selectedItem} />
        </CustomAdminModel>
      )}

      {activeModal === "ChangeSubscription" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          onClose={closeModal}
          title="Change Subscribtion"
        >
          <ChangeSubscription
            closeModal={closeModal}
            selectedItem={selectedItem}
          />
        </CustomAdminModel>
      )}
      {activeModal === "ExtendSubscription" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Extend Subscribtion"
        >
          <ExtendSubscription
            closeModal={closeModal}
            selectedItem={selectedItem}
          />
        </CustomAdminModel>
      )}
      {activeModal === "AddNewSubscription" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Add New Subscribtion"
        >
          <AddNewSubscription
            closeModal={closeModal}
            selectedItem={selectedItem}
          />
        </CustomAdminModel>
      )}

      {activeModal === "CancelSubscription" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[500px]"
          onClose={closeModal}
          title="Cancel Subscribtion"
        >
          <CancelSubscription
            closeModal={closeModal}
            selectedItem={selectedItem}
          />
        </CustomAdminModel>
      )}
    </div>
  );
}

export default SubscriptionClientDetail;
