/**
 * Manages state for customers.
 * Defines initial state for each customer action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createCustomersApi from "./CustomersService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to customer processes.
 * Each property represents a specific customer action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllCustomers, getSingleCustomer, updateCustomer, addCustomer, and deleteCustomer
 * store data, error, success, loading, and message status for corresponding customer actions.
 */
const initialState = {
  getAllCustomers: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleCustomer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateCustomer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addCustomer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteCustomer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  exportCustomer: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the customer process.
 * These action types are prefixed with the 'customers' base path for better organization.
 */
const BASE = "customers";

export const ActionTypes = {
  GET_ALL_CUSTOMERS: `${BASE}/get-all`, // Action type for get all customers
  ADD_CUSTOMER: `${BASE}`, // Action type for add customer
  EXPORT_CUSTOMER: `${BASE}/export-customer`, // Action type for export customer
};

// Creating an instance of the customers service with a base URL 'customers'
const customersService = createCustomersApi("customers");

/**
 * Initiates the getAllCustomers process for a customer.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting customers
 *    order: order for sorting customers by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllCustomers.
 */
export const getAllCustomers = createAsyncThunk(
  ActionTypes.GET_ALL_CUSTOMERS,

  async ({ payload }, thunkAPI) => {
    const response = await customersService.getAllCustomers(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getSingleCustomer process for a customer.
 * @param {object} customerId - id of the customer
 * @param {function} successCallBack - Callback function upon successful getSingleCustomer.
 */
export const getSingleCustomer = createAsyncThunk(
  `${BASE}/customerId`,

  async ({ customerId }, thunkAPI) => {
    const response = await customersService.getSingleCustomer(customerId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updateCustomer process for a customer.
 * @param {object} customerId - id of the customer
 * @param {object} payload - customer data to update
 * @param {function} successCallBack - Callback function upon successful updateCustomer.
 */
export const updateCustomer = createAsyncThunk(
  `${BASE}/customerId`,

  async ({ customerId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await customersService.updateCustomer(
        customerId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Customer updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addCustomer process for a customer.
 * @param {object} payload - customer data to add
 * {
  "name": "John Doe",
  "email": "customer@gmail.com",
  "phoneNumber": "+9232478788",
  "gender": "MALE"
}
 * @param {function} successCallBack - Callback function upon successful addCustomer.
 */
export const addCustomer = createAsyncThunk(
  ActionTypes.ADD_CUSTOMER,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await customersService.addCustomer(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Customer added successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteCustomer process for a customer.
 * @param {object} customerId - id of the customer
 * @param {function} successCallBack - Callback function upon successful deleteCustomer.
 */
export const deleteCustomer = createAsyncThunk(
  `${BASE}/customerId`,

  async ({ customerId, successCallBack }, thunkAPI) => {
    try {
      const response = await customersService.deleteCustomer(customerId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Customer deleted successfully!", "success", async () => {
          const undoResponse = await customersService.undoCustomer(customerId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllCustomers({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteCustomer process for a customer.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting customers
 *    order: order for sorting customers by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful deleteCustomer.
 */
export const exportCustomer = createAsyncThunk(
  ActionTypes.EXPORT_CUSTOMER,

  async ({ payload, successCallBack, config }, thunkAPI) => {
    try {
      const response = await customersService.exportCustomer(payload, config);
      if (response?.data?.Succeeded) {
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for customers related state management.
 * - `name`: Name of the slice (customers)
 * - `initialState`: Initial state defining properties for various customer actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllCustomers, updateCustomer etc).
 */
export const customersSlice = createSlice({
  name: "customers",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllCustomers action.
     */
    reset: (state) => {
      state.getAllCustomers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleCustomer = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllCustomers action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllCustomers.pending, (state) => {
        state.getAllCustomers.isLoading = true;
        state.getAllCustomers.message = "";
        state.getAllCustomers.isError = false;
        state.getAllCustomers.isSuccess = false;
        state.getAllCustomers.data = null;
      })
      /**
       * Updates the state when getAllCustomers action is fulfilled/successful.
       * Updates loading and success flags and sets getAllCustomers data with the payload.
       */
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.getAllCustomers.isLoading = false;
        state.getAllCustomers.isSuccess = true;
        state.getAllCustomers.data = action.payload;
      })
      /**
       * Updates the state when getAllCustomers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.getAllCustomers.message = action?.payload?.message;
        state.getAllCustomers.isLoading = false;
        state.getAllCustomers.isError = true;
        state.getAllCustomers.data = null;
      })
      /**
       * Updates the state while the getSingleCustomer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleCustomer.pending, (state) => {
        state.getSingleCustomer.isLoading = true;
        state.getSingleCustomer.message = "";
        state.getSingleCustomer.isError = false;
        state.getSingleCustomer.isSuccess = false;
        state.getSingleCustomer.data = null;
      })
      /**
       * Updates the state when getSingleCustomer action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleCustomer data with the payload.
       */
      .addCase(getSingleCustomer.fulfilled, (state, action) => {
        state.getSingleCustomer.isLoading = false;
        state.getSingleCustomer.isSuccess = true;
        state.getSingleCustomer.data = action.payload;
      })
      /**
       * Updates the state when getSingleCustomer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleCustomer.rejected, (state, action) => {
        state.getSingleCustomer.message = action.payload?.message;
        state.getSingleCustomer.isLoading = false;
        state.getSingleCustomer.isError = true;
        state.getSingleCustomer.data = null;
      })
      /**
       * Updates the state while the addCustomer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addCustomer.pending, (state) => {
        state.addCustomer.isLoading = true;
        state.addCustomer.message = "";
        state.addCustomer.isError = false;
        state.addCustomer.isSuccess = false;
        state.addCustomer.data = null;
      })
      /**
       * Updates the state when addCustomer action is fulfilled/successful.
       * Updates loading and success flags and sets addCustomer data with the payload.
       */
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.addCustomer.isLoading = false;
        state.addCustomer.isSuccess = true;
        state.addCustomer.data = action.payload;
      })
      /**
       * Updates the state when addCustomer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addCustomer.rejected, (state, action) => {
        state.addCustomer.message = action.payload?.message;
        state.addCustomer.isLoading = false;
        state.addCustomer.isError = true;
        state.addCustomer.data = null;
      })
      /**
       * Updates the state while the exportCustomer action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(exportCustomer.pending, (state) => {
        state.exportCustomer.isLoading = true;
        state.exportCustomer.message = "";
        state.exportCustomer.isError = false;
        state.exportCustomer.isSuccess = false;
        state.exportCustomer.data = null;
      })
      /**
       * Updates the state when exportCustomer action is fulfilled/successful.
       * Updates loading and success flags and sets exportCustomer data with the payload.
       */
      .addCase(exportCustomer.fulfilled, (state, action) => {
        state.exportCustomer.isLoading = false;
        state.exportCustomer.isSuccess = true;
        state.exportCustomer.data = action.payload;
      })
      /**
       * Updates the state when exportCustomer action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(exportCustomer.rejected, (state, action) => {
        state.exportCustomer.message = action.payload?.message;
        state.exportCustomer.isLoading = false;
        state.exportCustomer.isError = true;
        state.exportCustomer.data = null;
      });
  },
});

/**
 * Destructures the reset action from the customersSlice actions.
 * - `reset`: Action function to reset the customers related state.
 */
export const { reset } = customersSlice.actions;

/**
 * Exports the default reducer generated by customersSlice.
 * This reducer handles state updates for customers related actions.
 */
export default customersSlice.reducer;
