import React, { useEffect, useRef, useState } from "react";
import { PlusCircle, PlusIcon } from "../../../common/Icons";
import { CustomButton } from "../../../common/components";
import { Menu } from "@headlessui/react";
import VariableSelector from "../../../common/components/VariableSelector/VariableSelector";

export const ItemCard = ({
  layout,
  englishName,
  salePrice,
  image,
  color,
  type,
  id,
  variablePairs,
  variableItem,
  selectedItems,
  setSelectedItems,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const divRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const checkDropdownPosition = () => {
      if (!divRef.current) return;

      const dropdownHeight = dropdownRef.current ? dropdownRef.current.offsetHeight : 0;
      const buttonRect = divRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;


      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setOpenUpwards(true);
      } else {
        setOpenUpwards(false);
      }
    };

    window.addEventListener('resize', checkDropdownPosition);
    checkDropdownPosition();

    return () => window.removeEventListener('resize', checkDropdownPosition);
  }, [menuOpen]);

  const handleButtonClick = (e) => {
    if (type !== "VARIABLE") {
      e.preventDefault();
      setSelectedItems(prev => {
        // Find if the item already exists in the array
        const existingItemIndex = prev.findIndex(item => item.id === id);
        if (existingItemIndex > -1) {
          // Update the existing item's quantity
          const updatedItems = [...prev];
          updatedItems[existingItemIndex] = {
            ...updatedItems[existingItemIndex],
            quantity: updatedItems[existingItemIndex].quantity + 1
          };
          return updatedItems;
        } else {
          // Add new item if it doesn't exist
          return [...prev, {
            id,
            image,
            englishName,
            salePrice,
            quantity: 1  
          }];
        }
      });
    } else {
      setMenuOpen(true);
    }
  };

  const handleDataFromVariableSelector = (data) => {
    setSelectedItems(prev => {
      // Find if the item already exists in the array
      const existingItemIndex = prev.findIndex(item => item.id === id);
      if (existingItemIndex > -1) {
        // Update the existing item's quantity
        const updatedItems = [...prev];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: updatedItems[existingItemIndex].quantity + 1
        };
        return updatedItems;
      } else {
        // Add new item if it doesn't exist
        return [...prev, {
          ...data,
          id,
          image,
          englishName,
          salePrice,
          quantity: 1  // Start with a quantity of 1
        }];
      }
    });
    setMenuOpen(false);
  };

  return (
    <React.Fragment>
      <div className="rounded-lg border">
        {layout === "grid" && (
          <img
            src={image}
            alt={englishName}
            className="w-full h-32 object-cover"
          />
        )}
        <div className="flex justify-between items-center p-3">
          <div className="flex-grow relative">
            <div className="relative pl-5">
              <p className={`absolute left-0 top-0 bottom-0 ${color} w-1`}></p>
              <h3 className="text-sm font-semibold text-neutral-900">
                {englishName}
              </h3>
            </div>
            <div className="flex justify-between pl-5 mt-2">
              <div className="flex flex-row ">
                <p className="text-xs text-text-link font-medium">
                  {salePrice} SAR
                </p>
              </div>

              <div className="relative inline-block text-left">
                <Menu as="div" className="relative">
                  <Menu.Button
                    icon={<PlusIcon />}
                    onClick={handleButtonClick}
                    width="w-fit"
                    ref={divRef}
                    border="border"
                    textColor="text-text-primary"
                  ><PlusIcon />
                  </Menu.Button>
                  {menuOpen && (
                    <Menu.Items
                      ref={dropdownRef}
                      className={`origin-top-right absolute right-[-12px] ${openUpwards ? "bottom-16" : "top-[20px]"
                        } min-w-60 max-w-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
                    >
                      <div className="px-4 py-2">
                        <span className="text-sm font-semibold text-text-primary mb-1">{englishName}</span>
                        <VariableSelector
                          options={variablePairs}
                          variableItem={variableItem}
                          onSelectionComplete={handleDataFromVariableSelector}
                        />
                      </div>
                    </Menu.Items>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
