import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleStocktaking,
  getAllStocktakings,
  deleteStocktaking,
} from "../../../../../features/Stocktaking/StocktakingSlice";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { getAllItems } from "../../../../../features/Items/ItemsSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { showToast } from "../../../../common/utils/showToast.util";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling stocktakings related functionalities
 *
 * @returns {Object}
 *   stocktakingList: Array containing stocktaking information for all stocktakings
 *   isLoading: Data loading state for all stocktakings
 *   singleStocktaking: Object containing stocktaking information
 *   singleStocktakingLoading: Data loading state for single stocktaking information
 */
const useStocktakingList = (stocktakingId) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { query } = useSelector((state) => state.search);

  const [currentPage, setCurrentPage] = useState(1);

  // get single stocktaking information from the store
  const { data: singleStocktaking, isLoading: singleStocktakingLoading } =
    useSelector((state) => state.stocktaking.getSingleStocktaking);
  // get stocktaking list for all stocktaking from the store
  const { data: stocktakingList, isLoading: stocktakingListLoading } =
    useSelector((state) => state.stocktaking.getAllStocktakings);
  // get store list for all stores from the redux store
  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.stores.getAllStores
  );
  // get item list for all items from the store
  const { data: itemList, isLoading: itemListLoading } = useSelector(
    (state) => state.items.getAllItems
  );

  useEffect(() => {
    if (stocktakingId) dispatch(getSingleStocktaking({ stocktakingId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stocktakingId]);

  const stocktakingDetail = singleStocktaking
    ? {
        "Count Name": singleStocktaking?.countName,
        Description: singleStocktaking?.description,
        Store: singleStocktaking?.store?.branchName,
        "Total Cost": `${singleStocktaking?.totalCost} SAR`,
        Date: formatDateTime(singleStocktaking?.date, "mmm dd, yyyy"),
      }
    : null;

  const itemDetail = singleStocktaking
    ? {
        Item:
          singleStocktaking?.slug ||
          singleStocktaking?.itemDetails?.englishName,
        Quantity:
          singleStocktaking?.variableItem?.quantity ||
          singleStocktaking?.itemDetails?.quantity,
        "Cost Price": `${
          singleStocktaking?.variableItem?.costPrice ||
          singleStocktaking?.itemDetails?.itemCost
        } SAR`,
        Note: singleStocktaking?.note,
      }
    : null;

  const itemImage = singleStocktaking?.itemDetails?.image;

  useEffect(() => {
    let searchParams = ["createdBy", "countName"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllStocktakings({ payload }));
  }, [currentPage, query, dispatch]);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!storesList) dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteStock = () => {
    if (stocktakingId)
      dispatch(
        deleteStocktaking({
          stocktakingId,
          successCallBack: onStocktakingUpdate,
        })
      );
  };

  const onStocktakingUpdate = (data) => {
    if (modalState?.type === "duplicate") {
      showToast("Stocktaking duplicated successfully!");
    }
    if (modalState?.type === "add") {
      showToast("Stocktaking created successfully!");
    }
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    const payloadItems = {
      page: currentPage,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };

    dispatch(getAllItems({ payload: payloadItems }));
    dispatch(getAllStocktakings({ payload }));
    dispatch(closeModal());
  };

  return {
    stocktakingList,
    stocktakingListLoading,
    singleStocktakingLoading,
    stocktakingDetail,
    deleteStock,
    storesList,
    storeListLoading,
    itemList,
    itemDetail,
    itemImage,
    currentPage,
    setCurrentPage,
    itemListLoading,
  };
};

export default useStocktakingList;
