import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion, easeOut } from "framer-motion";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { classNames } from "../../helpers/generateClassNames";
import { Card, CustomButton } from "../common/components";
import { LanguageSwitcher } from "../common/components/LanguageSwitcher/LanguageSwitcher";
import {
  ArrowDown,
  ArrowRight,
  CarServiceIcon,
  CoffeeIcon,
  CrossIcon,
  DashboardIcon,
  ExpressIcon,
  FoodTruckIcon,
  GiftShopIcon,
  HairDresserIcon,
  HamburgerIcon,
  Logo,
  PosIcon,
  RestaurantIcon,
  ShopIcon,
  SuperMarketIcon,
} from "../common/Icons";
import { Globe } from "../../assets/images/svgs";

export default function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDropdownToggle = (itemName) => {
    setDropdownStates((prev) => ({
      ...prev,
      [itemName]: !prev[itemName], // Toggle the specific dropdown's state
    }));
  };

  return (
    <Disclosure
      as="nav"
      className={`inset-x-0 transition-transform duration-1000 z-[99999] ${
        isSticky
          ? "lg:fixed bg-white shadow-lg z-50"
          : "fixed mx-4 1xl:mx-29 top-10"
      }`}
    >
      {({ open }) => (
        <>
          {/* desktop */}
          <div
            className={`px-2 sm:px-4 bg-white relative landingpagenav ${
              isSticky
                ? "lg:rounded-t-2xl lg:rounded-none rounded-2xl"
                : "rounded-2xl"
            }`}
          >
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden z-10">
                {/* Mobile menu button*/}
                <DisclosureButton
                  as="div"
                  className="relative inline-flex items-center justify-center rounded-md"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <CustomButton
                      bgColor="bg-transparent"
                      icon={<CrossIcon className="w-6 h-6" />}
                      width="w-fit"
                      border="border"
                      padding="p-2"
                    />
                  ) : (
                    <CustomButton
                      bgColor="bg-transparent"
                      icon={<HamburgerIcon className="w-6 h-6" />}
                      width="w-fit"
                      border="border"
                      padding="p-2"
                    />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    <Logo />
                  </Link>
                </div>
                <div className="hidden xl:ml-20 ml-4 lg:flex items-center w-full justify-between">
                  <div className="flex xl:space-x-10 lg:space-x-6 space-x-4">
                    {navigation.map((item) => {
                      return (
                        item.name !== "ENG" && (
                          <span
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              "lg:text-base text-sm cursor-pointer group/navigation font-medium text-text-primary flex items-center gap-2 relative"
                            )}
                          >
                            <Link to={item.href}>{item.name}</Link>{" "}
                            {item.hasChild && (
                              <ArrowDown className="group-hover/navigation:rotate-180 transition-transform duration-300 mt-1" />
                            )}
                            {item.hasChild && (
                              <div className="bg-transparent absolute top-full pt-8 1xl:-start-4 -start-28 lg:min-w-[800px] 1xl:min-w-[950px] hidden group-hover/navigation:block z-10">
                                <div className="bg-white rounded-2xl landingpagenav p-3">
                                  <div className="grid grid-cols-3 w-full gap-2 2xl:gap-4">
                                    {item.children &&
                                      item?.children.map((child) => (
                                        <Link
                                          to={child.href}
                                          key={child.heading}
                                        >
                                          <div className="flex items-center min-w-64 1xl:min-w-72 group hover:outline outline-1 outline-border-primary 1xl:gap-3 gap-2 1xl:px-3 px-2 1xl:py-2 py-1 rounded-lg transition duration-300">
                                            <div className="rounded-lg group-hover:bg-icon-badge bg-surface-gray w-14 h-14 1xl:h-12 1xl:w-14 1xl:max-w-36 1xl:max-h-14 flex items-center justify-center transition duration-200">
                                              <child.icon className="group-hover:fill-white fill-text-primary transition duration-200" />
                                            </div>
                                            <div className="flex flex-col items-start">
                                              <p className="text-text-primary text-sm font-medium">
                                                {child.heading}
                                              </p>
                                              <Link className="text-text-link font-medium text-sm items-center gap-2 hidden group-hover:flex !mt-1 transition duration-200">
                                                <span>Read More</span>{" "}
                                                <ArrowRight className="w-5 h-5 mt-px" />
                                              </Link>
                                              {/* <p className="text-text-secondary text-xs line-clamp-2">
                                            {child.text}
                                          </p> */}
                                            </div>
                                          </div>
                                        </Link>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </span>
                        )
                      );
                    })}
                  </div>
                  {/* actions */}
                  <div className="flex items-center lg:gap-3 gap-2">
                    <LanguageSwitcher className="lg:space-x-1 font-semibold" />
                    <div className="relative cursor-pointer group/signinBtn ml-2">
                      <CustomButton
                        text="Sign In"
                        icon
                        width="w-fit"
                        textColor="text-text-primary"
                        border="border"
                        padding="lg:px-4 lg:py-3 py-2 px-3"
                      />
                      <div className="bg-transparent absolute right-0 min-w-fit pt-6 hidden group-hover/signinBtn:block">
                        <div className="bg-white rounded-2xl landingpagenav p-4">
                          <div className="flex items-center gap-4">
                            {signinNavigation.map((item) => (
                              <Link to={item.href} key={item.heading}>
                                <div className="flex items-center min-w-80 group hover:outline outline-1 outline-border-primary gap-3 px-3 py-2 rounded-lg transition duration-300">
                                  <div className="rounded-lg group-hover:bg-icon-badge bg-surface-gray w-28 h-14 flex items-center justify-center transition duration-200">
                                    <item.icon className="group-hover:fill-white fill-text-primary transition duration-200" />
                                  </div>
                                  <div className="flex flex-col space-y-2">
                                    <p className="text-text-primary text-sm font-semibold">
                                      {item.heading}
                                    </p>
                                    <p className="text-text-secondary text-xs">
                                      {item.text}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <CustomButton
                      text="Get Started"
                      width="w-fit"
                      bgColor="bg-black-pearl"
                      padding="lg:px-4 lg:py-3 py-2 px-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* mobile navigation */}
            <Transition
              show={open}
              enter="transition ease-out duration-200"
              enterFrom="transform origin-top-left scale-0 opacity-0"
              enterTo="transform origin-top-left scale-100 opacity-100"
              leave="transition ease-in duration-200"
              leaveFrom="transform origin-top-left scale-100 opacity-100"
              leaveTo="transform origin-top-left scale-0 opacity-0"
            >
              <DisclosurePanel className="lg:hidden z-10 relative">
                <div className="pb-3 pt-2 bg-white inset-x-0 space-y-3">
                  {navigation.map((item) => (
                    <div key={item.name}>
                      <Link to={item.href}>
                        <span
                          className="flex items-center justify-between px-3 py-2 text-base font-medium cursor-pointer"
                          onClick={() =>
                            item.hasChild && handleDropdownToggle(item.name)
                          }
                        >
                          {item.name === "ENG" ? (
                            <span className="lg:hidden items-center gap-2 flex">
                              <img src={item.icon} alt="Globe" />
                              {item.name}
                            </span>
                          ) : (
                            item.name
                          )}
                          {item.hasChild && (
                            <ArrowDown
                              className={`transition-transform duration-300 ${
                                dropdownStates[item.name] ? "-rotate-90" : ""
                              }`}
                            />
                          )}
                        </span>
                      </Link>
                      {item.hasChild && (
                        <AnimatePresence>
                          {dropdownStates[item.name] && (
                            <motion.div
                              static
                              initial={{ opacity: 0, y: -24 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -24 }}
                              transition={{ duration: 0.2, ease: easeOut }}
                              className="origin-top"
                            >
                              {item.name === "ENG" ? (
                                <LanguageSwitcher mobileNav />
                              ) : (
                                <Card padding="xss:p-2 p-0" className="!mt-3">
                                  <div className="grid md:grid-cols-3 grid-cols-2 w-full xss:gap-4 gap-2">
                                    {item.children &&
                                      item.children.map((child) => (
                                        <Link
                                          to={child.href}
                                          key={child.heading}
                                        >
                                          <div className="flex items-center lg:min-w-64 xs:min-w-56 w-fit group hover:outline outline-1 outline-border-primary xss:gap-2 gap-1 px-2 py-2 xss:pr-5 rounded-lg transition duration-300">
                                            <div className="rounded-lg group-hover:bg-icon-badge bg-surface-gray w-10 h-10 xs:w-14 xs:h-14 max-w-14 max-h-14 flex items-center justify-center transition duration-200">
                                              <child.icon className="group-hover:fill-white fill-text-primary transition duration-200 xss:w-auto w-7" />
                                            </div>
                                            <div className="flex flex-col items-start">
                                              <p className="text-text-primary xss:text-sm text-xs font-medium">
                                                {child.heading}
                                              </p>
                                              <Link className="text-text-link whitespace-nowrap font-medium xs:text-sm text-xs items-center xs:gap-2 hidden group-hover:flex !mt-1 transition duration-200">
                                                <span>Read More</span>
                                                <ArrowRight className="xss:w-5 w-3 h-3 xss:h-5 mt-px" />
                                              </Link>
                                            </div>
                                          </div>
                                        </Link>
                                      ))}
                                  </div>
                                </Card>
                              )}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      )}
                    </div>
                  ))}

                  <div className="px-2 !mt-6">
                    {/* actions */}
                    <div className="flex items-center gap-3 mt-6">
                      <CustomButton
                        text="Sign In"
                        icon
                        textColor="text-text-primary"
                        border="border"
                        padding="lg:px-4 xs:py-3 py-2 px-3"
                        className="relative cursor-pointer group/signinBtn"
                      >
                        <div className="bg-transparent absolute xs:start-0 -start-5 bottom-full text-left min-w-fit hidden group-hover/signinBtn:block">
                          <div className="bg-white rounded-2xl landingpagenav md:p-4 p-2">
                            <div className="flex md:flex-row flex-col items-center md:gap-4 gap-2">
                              {signinNavigation.map((item) => (
                                <Link to={item.href} key={item.heading}>
                                  <div className="flex items-center md:min-w-80 xs:min-w-72 min-w-[280px] group hover:outline outline-1 outline-border-primary md:gap-3 gap-2 px-3 py-2 rounded-lg transition duration-300">
                                    <div className="rounded-lg group-hover:bg-icon-badge bg-surface-gray md:min-w-16 min-w-12 md:h-14 h-10 flex items-center justify-center transition duration-200">
                                      <item.icon className="group-hover:fill-white fill-text-primary transition duration-200" />
                                    </div>
                                    <div className="flex flex-col md:space-y-2 space-y-1">
                                      <p className="text-text-primary text-sm font-semibold">
                                        {item.heading}
                                      </p>
                                      <p className="text-text-secondary text-xs">
                                        {item.text}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      </CustomButton>

                      <CustomButton
                        text="Get Started"
                        bgColor="bg-black-pearl"
                        padding="lg:px-4 xs:py-3 py-2 px-3"
                      />
                    </div>
                  </div>
                </div>
              </DisclosurePanel>
            </Transition>
          </div>
        </>
      )}
    </Disclosure>
  );
}

const navigation = [
  { name: "Features", href: "/features", hasChild: false },
  { name: "Pricing", href: "/pricing" },
  {
    name: "Business Type",
    href: "#",
    hasChild: true,
    children: [
      {
        heading: "Restaurant",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: RestaurantIcon,
      },
      {
        heading: "Coffee Shop",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: CoffeeIcon,
      },
      {
        heading: "Shop",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: ShopIcon,
      },
      {
        heading: "Super Market",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: SuperMarketIcon,
      },
      {
        heading: "Food Truck",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: FoodTruckIcon,
      },
      {
        heading: "Gift Shops",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: GiftShopIcon,
      },
      {
        heading: "Hairdressers",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: HairDresserIcon,
      },
      {
        heading: "Car Services",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: CarServiceIcon,
      },
      {
        heading: "Express Service",
        text: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit",
        href: "",
        icon: ExpressIcon,
      },
    ],
  },
  { name: "FAQ", href: "/faqs", hasChild: false },
  { name: "Contact Us", href: "/contact-us", hasChild: false },
  { name: "ENG", href: "#", hasChild: true, icon: Globe },
];

const signinNavigation = [
  {
    heading: "Sign in to Dashboard",
    text: "Start managing and controlling your branches through our platform",
    href: "/login",
    icon: DashboardIcon,
  },
  {
    heading: "Sign in to POS",
    text: "Start your dealings with your customers through our point of sale platform",
    href: "/pos",
    icon: PosIcon,
  },
];
