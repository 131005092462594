import React, { useState } from "react";
import { Card, CustomButton, Loader } from "../../../../common/components";
import { MinusIcon, PhoneIcon, PlusIcon } from "../../../../common/Icons";
import { useAddBranch } from "./useAddBranch";
import { DefaultImg } from "../../../../../assets/images";

function AddBranch({ selectedItem, closeModal,subscriptionDetailId ,selectedTab}) {
  const {
    subtotal,
    incrementBranch,
    decrementBranch,
    branchCount,
    branchPrice,
    plan,
    PrivideBy,
    branches,
    subTotal,
    subscriptionLoading,
    discount,
    handleConfirmClick,
    profile,
  } = useAddBranch({ selectedItem, closeModal,subscriptionDetailId ,selectedTab});
  if (subscriptionLoading) {
    return (
      <div className="mx-60 mt-12">
        <Loader />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div className="flex items-center ">
        <img
          src={profile?.profilePic || DefaultImg}
          alt="Pic"
          className="inline-block h-14 w-14 rounded-full border mr-3"
        />

        <div className="flex-grow">
          <p className="text-text-primary text-lg font-semibold">
          {profile?.fullName??"-"}
          </p>
          <div className="flex items-center text-text-gray font-normal mt-2">
            <PhoneIcon className="mr-2" />{" "}
            <span className="pl-2 text-text-primary  text-sm font-normal">
              {profile?.phoneNumber ?? "-"}
            </span>
          </div>
        </div>
      </div>
      <p className="mt-4 text-base font-normal">Plan Detail</p>
      <Card className="mt-4">
        <div className="md:flex gap-16">
          <div>
            <p className=" text-base font-medium text-text-primary">
              Advance Plan
            </p>
            <p className="text-base font-normal text-text-secondary">
              {subTotal} SAR ({plan})
            </p>
          </div>
          <div>
            <p className="text-base text-text-primary font-medium">Provider</p>
            <p className="text-base font-normal text-text-secondary">
              {PrivideBy ?? "-"}
            </p>
          </div>
          <div>
            <p className="text-base  text-text-primary font-medium">Branch</p>
            <p className="text-base font-normal text-text-secondary">
              {branches ?? "-"}
            </p>
          </div>
        </div>
      </Card>
      <div className="mt-4 border rounded-lg p-4">
        <div className=" flex items-center justify-between">
          <p className="text-base font-semibold">Add Branch</p>
          <div className="flex items-center">
            <CustomButton
              onClick={decrementBranch}
              icon={<MinusIcon />}
              hoverBgColor="bg-gray-100"
              bgColor="!bg-gray-100"
              width="w-6"
              border="none"
              padding="p-0"
              className="inline-block  ml-2 shadow-none !h-6"
            />

            <span className="text-base font-semibold mx-2">{branchCount}</span>

            <CustomButton
              onClick={incrementBranch}
              icon={<PlusIcon />}
              hoverBgColor="bg-gray-100"
              bgColor="!bg-gray-100"
              width="w-6"
              border="none"
              padding="p-0"
              className="inline-block   shadow-none !h-6"
            />
          </div>
        </div>
        <span className=" text-sm font-normal text-text-secondary ">
          {branchPrice} SAR
        </span>
      </div>

      {/* Summary Section */}
      <Card className="mt-4">
        <p className="text-lg font-semibold mb-3">Summary</p>

        <div className="">
          <div className="flex justify-between items-center ">
            <p className="text-sm font-normal text-text-secondary">
              Total Branch
            </p>
            <p className="text-base font-semibold">{branchCount}</p>
          </div>
          <div className="flex justify-between items-center mt-2">
            <p className="text-lg font-semibold">Subtotal</p>
            <p className="text-lg font-semibold text-text-link">
              {subtotal.toFixed(2)} SAR
            </p>
          </div>
        </div>
      </Card>
      <div className="flex justify-end space-x-2 pt-4 ">
        <CustomButton
          width="20px"
          text="Cancel"
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          onClick={closeModal}
          className="hover:!bg-gray-200"
        />
        <CustomButton
          type="button"
          width="20px"
          text="Confirm"
          textColor="text-white"
          onClick={handleConfirmClick}
        />
      </div>
    </div>
  );
}

export default AddBranch;

// <div className="flex justify-between items-center ">
//   <p className="text-sm font-normal text-text-secondary">
//     Total Discount
//   </p>
//   <p className="text-base font-semibold">{discount} SAR</p>
// </div>
