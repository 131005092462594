import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleClientProfile } from "../../../../../../features/Clients/clientSlice";
import { getSingleClientSale } from "../../../../../../features/AdminSales/AdminSalesSlice";
import { DeleteIcon, PencilIcon, Printer } from "../../../../../common/Icons";
import { changeStatus } from "../../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";

export const useUpdateProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [clientDetails, setClientDetails] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState({
    isToggle: false,
    type: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const openModal = (item, modalType) => {
    setSelectedItem(item);
    setActiveModal(modalType);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible({ isToggle: false, type: "" });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setActiveModal(null);
    setSelectedItem(null);
  };

  const toggleDropdown = (type) => {
    setDropdownVisible((prev) => ({ isToggle: !prev.isToggle, type: type }));
  };

  const handleEdit = (user) => {
    setModalOpen(true);
    setSelectedItem(user);
  };

  const handlePrint = () => {
    // Print logic
  };

  const handleDelete = (user) => {
    setDeleteModalOpen(true);
    setSelectedItem(user);
  };

  const dropdownOptions = [
    { label: "Edit", action: handleEdit, icon: <PencilIcon /> },
    { label: "Print", action: handlePrint, icon: <Printer /> },
    { label: "Delete", action: handleDelete, icon: <DeleteIcon /> },
  ];

  const subscriptions = clientDetails?.subscriptions ?? [];
  const subscriptionDetailId = clientDetails?.subscription?.id;
  const lastSubscription = subscriptions[subscriptions.length - 1] || {};

  const subscribtiondropdownOptions = [
    {
      label: "Change Subscribtion",
      value: "change",
      textColor: "text-text-primary",
      action: () => openModal(lastSubscription, "ChangeSubscribtion"),
    },
    {
      label: "Add Branches",
      value: "add",
      textColor: "text-text-primary",
      action: () => openModal(lastSubscription, "AddBranches"),
    },
    {
      label: "Change Status",
      value: "change",
      textColor: "text-text-primary",
      // action: () => openModal(lastSubscription, "ExtendSubscribtion"),
    },
    {
      label: "Extend Subscribtion",
      value: "extend",
      textColor: "text-text-primary",
      action: () => openModal(lastSubscription, "ExtendSubscribtion"),
    },
    {
      label: "Cancel Subscribtion",
      value: "cancel",
      textColor: "text-danger",
      action: () => openModal(lastSubscription, "CancelSubscribtion"),
    },
  ];

  useEffect(() => {
    dispatch(
      getSingleClientProfile({
        profileId: id,
        successCallBack: onGetSingleClientProfileSuccess,
      })
    );
  }, [id, dispatch]);

  const onGetSingleClientProfileSuccess = (response) => {
    setClientDetails(response);
  };

  const { data: singlesale, isLoading: singleSaleLoading } = useSelector(
    (state) => state.adminsales.getSingleClientSale
  );

  useEffect(() => {
    if (id) dispatch(getSingleClientSale({ id }));
  }, [id, dispatch]);

  return {
    clientDetails,
    dropdownOptions,
    toggleDropdown,
    isDeleteModalOpen,
    isModalOpen,
    dropdownVisible,
    setDeleteModalOpen,
    dropdownRef,
    setModalOpen,
    subscribtiondropdownOptions,
    activeModal,
    closeModal,
    singleSaleLoading,
    singlesale,
    selectedItem,
    id,
    storedUserData,
    subscriptionDetailId,
  };
};

export default useUpdateProfile;
