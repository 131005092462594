import api from "../../../services/api";

/**
 * Creates invoices API endpoints based on the specified base path.
 * @param {string} basePath - The base path for invoices endpoints.
 * @returns {Object} Object containing invoices API methods.
 */
const createSaleInvoicesAPi = (basePath) => ({
  /**
   * Retrieves all invoices.
   * @param {object} payload - Payload containing query parameters.
   * @param {number} payload.page - Current page.
   * @param {number} payload.pageSize - Number of items per page.
   * @param {string} payload.sortColumn - Column ID for sorting invoices.
   * @param {string} payload.order - Order for sorting invoices by ascending or descending.
   * @param {object} payload.condition - Additional conditions for filtering.
   * @param {object} payload.attributes - Additional attributes for customization.
   * @returns {Promise} A Promise that resolves to the invoices response.
   */
  getAllSaleInvoices: async (payload) => {
    const response = await api().post(`${basePath}/get-all`, payload);
    return response;
  },
  /**
   * Retrieves a single invoice.
   * @param {string} invoiceId - ID of the invoice.
   * @returns {Promise} A Promise that resolves to the invoice response.
   */
  getSingleSaleInvoice: async (invoiceId) => {
    const response = await api().get(`${basePath}/${invoiceId}`);
    return response;
  },

  /**
   * Updates a single invoice.
   * @param {string} invoiceId - ID of the invoice.
   * @param {object} payload - Payload containing updated data.
   * @returns {Promise} A Promise that resolves to the invoice response.
   */
  updateSaleInvoice: async (invoiceId, payload) => {
    const response = await api().put(`${basePath}/${invoiceId}`, payload);
    return response;
  },

  /**
   * Adds an invoice.
   * @param {object} payload - Payload containing data for the new invoice.
   * @returns {Promise} A Promise that resolves to the invoice response.
   */
  addSaleInvoice: async (payload) => {
    const response = await api().post(`${basePath}`, payload);
    return response;
  },

  /**
   * Deletes an invoice.
   * @param {string} invoiceId - ID of the invoice to be deleted.
   * @returns {Promise} A Promise that resolves to the deletion response.
   */
  deleteSaleInvoice: async (invoiceId) => {
    const response = await api().delete(`${basePath}/${invoiceId}`);
    return response;
  },
  /**
   * Retrieves all sales.
   */
  getclientsSales: async (payload) => {
    const response = await api().post(`${basePath}/get-clients-sales`, payload);
    return response;
  },
});

// Export the createSaleInvoicesAPi

/**
 * Example usage:
 * import createSaleInvoicesAPi from './path/to/api/invoicesApi';
 *
 * const invoicesApi = createSaleInvoicesAPi('invoices');
 *
 * const getAllSaleInvoices = async (payload) => {
 *   try {
 *     const response = await invoicesApi.getAllSaleInvoices(payload);
      // Handle response
 *   } catch (error) {
      // Handle error
 *   }
 * };
 */

export default createSaleInvoicesAPi;
