import React from "react";

import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./global.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Gleap from "gleap";
import { CheckCircleError, CheckCircleTick } from "./components/common/Icons";

const root = ReactDOM.createRoot(document.getElementById("root"));
Gleap.setLanguage("ar");
Gleap.initialize(process.env.REACT_APP_GLEAP_API_KEY);

root.render(
  <React.StrictMode>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      zIndex="99999"
      // limit={1}
      icon={({ type }) => {
        if (type === "success") return <CheckCircleTick />;
        if (type === "error") return <CheckCircleError />;
      }}
    />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
