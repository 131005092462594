import { useState } from "react";
import { ArrowUp } from "../../../../common/Icons";
import { Card, SelectGroup } from "../../../../common/components";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import Layout from "../../Layout/Layout";
import useTaxes from "./useTaxes";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";

/**
 * Stores List component
 * Renders table of Stores list
 *
 * @returns {JSX.Element} JSX code for rendering the store list table
 */

export default function Taxes() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { handleSubmit, setValue, storesList, taxReports } = useTaxes(
    startDate,
    endDate
  );

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text="Export"
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          padding="py-2 px-3"
          icon={<ArrowUp className="min-w-6 min-h-6 pb-3" />}
        />
      </div>
      {/* filters */}

      <div className="flex items-center justify-between mt-3">
        {storesList?.length > 0 && (
          <SelectGroup
            className="min-w-44"
            htmlFor="store"
            inputName="store"
            options={[
              { value: "", label: "All Store" },
              ...storesList?.map((option) => ({
                value: option?.id,
                label: option?.branchName,
              })),
            ]}
            onChange={(e) => {
              setValue("store", e.target.value);
              handleSubmit();
            }}
          />
        )}
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
            handleSubmit();
          }}
        />
      </div>
      {/* data */}
      <div className="mt-6 flow-root">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="flex flex-col  gap-4">
            <div className="overflow-hidden border rounded-md border-gray-300">
              <table className="min-w-full border-collapse [&_th]:border-r [&_th:last-child]:border-r-0 [&_th]:border-b  [&_th]:border-gray-300 [&_td]:border-r [&_td]:border-b [&_td]:border-gray-300 ">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className={`py-2 text-start text-sm font-semibold text-text-secondary pr-3 rounded-tl-[9px]`}
                    ></th>
                    <th
                      scope="col"
                      className={`py-2 text-center text-sm font-semibold text-text-secondary px-3`}
                    >
                      Amount (SAR)
                    </th>
                    <th
                      scope="col"
                      className={`py-2 text-start text-sm font-semibold text-text-secondary px-3`}
                    >
                      VAT Amount (SAR)
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td
                      className="relative w-40 whitespace-nowrap py-4 pl-3 !border-b-0 pr-4 text-sm font-medium"
                      rowSpan={2}
                    >
                      VAT on Purchase
                    </td>
                    <td>
                      <div className="flex p-4">
                        <span className="flex-[1_1_45%]">
                          Standard rated domestic purchase (15%)
                        </span>
                        <span className="flex-[1_1_55%]">
                          {taxReports?.taxAmountOnPurchase}
                        </span>
                      </div>
                    </td>
                    <td
                      rowSpan={2}
                      className="font-semibold w-40 text-center !border-r-0 !border-b-0"
                    >
                      {taxReports?.taxAmountOnPurchase}
                    </td>
                  </tr>
                  <tr>
                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-end"></td> */}
                    <td className="!border-b-0">
                      <div className="flex p-4">
                        <span className="flex-[1_1_45%] font-semibold">
                          Total Purchase
                        </span>
                        <span className="flex-[1_1_55%] font-semibold">
                          {taxReports?.purchases}
                        </span>
                      </div>
                    </td>
                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-end"></td> */}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="overflow-hidden border rounded-md border-gray-300">
              <table className="min-w-full border-collapse [&_th]:border-r [&_th:last-child]:border-r-0 [&_th]:border-b  [&_th]:border-gray-300 [&_td]:border-r [&_td]:border-b [&_td]:border-gray-300 ">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className={`py-2 text-start text-sm font-semibold text-text-secondary pr-3 rounded-tl-[9px]`}
                    ></th>
                    <th
                      scope="col"
                      className={`py-2 text-center text-sm font-semibold text-text-secondary px-3`}
                    >
                      Amount (SAR)
                    </th>
                    <th
                      scope="col"
                      className={`py-2 text-start text-sm font-semibold text-text-secondary px-3`}
                    >
                      VAT Amount (SAR)
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td
                      className="relative w-40 whitespace-nowrap py-4 pl-3 !border-b-0 pr-4 text-sm font-medium"
                      rowSpan={2}
                    >
                      VAT on Sales
                    </td>
                    <td>
                      <div className="flex p-4">
                        <span className="flex-[1_1_45%]">
                          Standard rated sales (15%)
                        </span>
                        <span className="flex-[1_1_55%]">
                          {taxReports?.taxAmountOnSales}
                        </span>
                      </div>
                    </td>
                    <td
                      rowSpan={2}
                      className="font-semibold w-40 text-center !border-r-0 !border-b-0"
                    >
                      {taxReports?.taxAmountOnSales}
                    </td>
                  </tr>
                  <tr>
                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-end"></td> */}
                    <td className="!border-b-0">
                      <div className="flex p-4">
                        <span className="flex-[1_1_45%] font-semibold">
                          Total Sales
                        </span>
                        <span className="flex-[1_1_55%] font-semibold">
                          {taxReports?.sales}
                        </span>
                      </div>
                    </td>
                    {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-end"></td> */}
                  </tr>
                </tbody>
              </table>
            </div>
            <Card
              padding="py-3 px-4"
              className="flex items-center justify-between !rounded-md"
            >
              <h6 className="to-text-primary text-base font-semibold">
                Total VAT due for current period
              </h6>
              <span className="to-text-link text-lg font-semibold">
                {taxReports?.taxAmount}
              </span>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.report.name", href: "#", current: false },
  { name: "client_dashboard.report.taxes", href: "#", current: true },
];
