import React, { useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
} from "../../../../../common/components";
import { CrossIcon, QrCodeIcon } from "../../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllItems,
  getSingleItem,
  updateItem,
} from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/ItemsModal/ItemsModalSlice";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const EditRawItems = ({ storesList, categoryList, selected }) => {
  const dispatch = useDispatch();
  // get uploaded file from the redux store data
  const uploadFileData = useSelector((state) => state.uploadFile);
  const itemId = selected?.id;
  const vItemId = selected?.type === "VARIABLE" && selected?.vItemId;

  // validation schema for add/edit raw item form
  const rawItemSchema = z.object({
    englishname: z.string().min(3, "English Name is required!"),
    arabicname: z.string().min(3, "Arabic Name is required!"),
    description: z.string().min(1, "Description is required!"),
    store: z.coerce.number().min(1, "Store is required!"),
    itemcost: z.coerce.number().min(1, "Item cost is required!"),
    saleprice: z.coerce.number().min(1, "Sale Price is required!"),
    category: z.coerce.number().min(1, "Category is required!"),
    unitmeasure: z.string().min(1, "Unit of measure is required!"),
    numberunit: z.coerce.number().min(1, "Number of unit is required!"),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(rawItemSchema),
  });

  const { title, isModalOpen } = useSelector((state) => state.itemsModal);

  const defaultValues = useMemo(
    () => ({
      englishname: selected?.englishName ?? "",
      arabicname: selected?.arabicName ?? "",
      description: selected?.description ?? "",
      store: selected?.stores?.id ?? "",
      itemcost: selected?.itemCost ?? "",
      category: selected?.category?.id ?? "",
      saleprice: selected?.salePrice ?? "",
      unitmeasure: selected?.measureOfUnit ?? "",
      numberunit: selected?.noMeasureOfUnit ?? "",
    }),
    [selected]
  );

  useEffect(() => {
    // set the default values for each field to edit selected using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [selected, setValue, defaultValues]);

  const onSubmit = (data) => {
    const payload = {
      type: "RAW",
      image: uploadFileData?.uploadSingleFile?.data?.url || selected?.image,
      englishName: data?.englishname,
      arabicName: data?.arabicname,
      description: data?.description,
      storeId: +data?.store,
      categoryId: +data?.category,
      itemCost: +data?.itemcost,
      salePrice: +data?.saleprice,
      rawItem: {
        measureOfUnit: data?.unitmeasure,
        noMeasureOfUnit: data?.numberunit,
      },
    };

    return dispatch(
      updateItem({
        itemId: selected?.id,
        payload: payload,
        successCallBack: onItemUpdate,
      })
    );
  };

  const onItemUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    dispatch(
      getSingleItem({
        itemId,
        vItemId,
      })
    );
    dispatch(closeModal());
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5 space-y-3">
              <CustomFileUploader
                isLoading={uploadFileData?.uploadSingleFile?.isLoading}
                selected={selected ? selected?.image : null}
              />
              {/* name */}
              <InputGroup
                labelText="Item Name"
                htmlFor="englishname"
                inputName="englishname"
                placeholder="Enter Item Name"
                register={register}
                errors={errors}
              />
              {/* arabic name */}
              <InputGroup
                labelText="Arabic Item Name"
                htmlFor="arabicname"
                inputName="arabicname"
                placeholder="Enter Arabic Item Name"
                register={register}
                errors={errors}
              />
              {/* description */}
              <InputGroup
                labelText="Description"
                htmlFor="description"
                inputName="description"
                placeholder="Enter Description"
                register={register}
                errors={errors}
              />
              {/* store */}
              <SelectGroup
                labelText="Select Store"
                htmlFor="store"
                inputName="store"
                options={[
                  { value: "", label: "All Store" },
                  ...storesList?.map((option) => ({
                    value: option?.id,
                    label: option?.branchName,
                  })),
                ]}
                register={register}
                errors={errors}
              />
              {/* item cost */}
              <InputGroup
                labelText="Item Cost"
                htmlFor="itemcost"
                inputName="itemcost"
                placeholder="Enter Item Cost"
                register={register}
                errors={errors}
              />
              {/* sale price */}
              <InputGroup
                labelText="Sale Price"
                htmlFor="saleprice"
                inputName="saleprice"
                placeholder="Enter Sale Price"
                register={register}
                errors={errors}
              />
              {/* category */}
              <SelectGroup
                labelText="Select Category"
                htmlFor="category"
                inputName="category"
                options={[
                  { value: "", label: "Select Category" },
                  ...categoryList?.map((option) => ({
                    value: option?.id,
                    label: option?.englishName,
                  })),
                ]}
                register={register}
                errors={errors}
              />
              {/* Unit of Measure */}
              <SelectGroup
                labelText="Unit of Measure"
                htmlFor="unitmeasure"
                inputName="unitmeasure"
                options={unitMeasures}
                register={register}
                errors={errors}
              />
              {/* Number Unit of Measure*/}
              <InputGroup
                labelText="Number Unit of Measure"
                htmlFor="numberunit"
                inputName="numberunit"
                placeholder="Enter Number Unit of Measure"
                register={register}
                errors={errors}
              />

              <CustomButton
                text="Create SKU Number"
                icon={<QrCodeIcon />}
                textColor="text-text-primary"
                border="border"
              />
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                text={"Save"}
                disabled={isSubmitting}
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditRawItems;
const unitMeasures = [
  { value: "", label: "Select Unit of Measure" },
  { value: "kg", label: "Kg" },
  { value: "m", label: "Meter" },
  { value: "cm", label: "Cm" },
  { value: "mm", label: "Millimeters" },
  { value: "in", label: "Inch" },
  { value: "m2", label: "Square meters" },
  { value: "m3", label: "Cubic meters" },
  { value: "t", label: "Ton" },
  { value: "g", label: "Gram" },
  { value: "oz", label: "Ounce" },
  { value: "lb", label: "Pound" },
  { value: "unit", label: "Unit" },
  { value: "pc", label: "Piece" },
  { value: "box8", label: "Box of 8" },
  { value: "box12", label: "Box of 12" },
  { value: "box15", label: "Box of 15" },
  { value: "box24", label: "Box of 24" },
  { value: "box40", label: "Box of 40" },
  { value: "box48", label: "Box of 48" },
  { value: "hr", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "month", label: "Month" },
  { value: "report", label: "Report" },
];

// sample usage
// import EditRawItems from "./path/to/EditRawItems.jsx";

// <EditRawItems />
