import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllPromotions } from "../../../../features/Promotions/PromotionsSlice";

/**
 * Custom hook for handling customers related functionalities.
 *
 * @returns {Object}
 *   customerList: Array containing customer information for all customers.
 *   isLoading: Data loading state for all customers.
 *   singleCustomer: Object containing customer information.
 *   singleCustomerLoading: Data loading state for single customer information.
 */
const useChooseDiscount = () => {
  const dispatch = useDispatch();
  const [selectedPromotion, setSelectedPromotion] = useState(null); // Changed to manage a single ID
  const { data: promotionList, isLoading: promotionListLoading } = useSelector(
    (state) => state.promotion.getAllPromotions
  );
  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };

    if (!promotionList) {
      dispatch(getAllPromotions({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItemNames = (items) => {
    // Check if items is an array and has length
    if (Array.isArray(items) && items.length > 0) {
      // Map through items to get englishName and join with comma if multiple
      return items.map((item) => item.englishName).join(", ");
    } else if (items) {
      // If items is not an array but an object
      return items.englishName;
    }
    return "No items";
  };

  const onSubmit = async (data) => {};

  const togglePromotionSelection = (promotionId) => {
    setSelectedPromotion(promotionId); // Directly set the selected promotion ID
  };

  /**
   * Handles the api call for getting all customers
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting customers
   *    order: order for sorting customers by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  return {
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    isSubmitting,
    watch,
    togglePromotionSelection,
    selectedPromotion, 
    promotionList,
    promotionListLoading,
    renderItemNames,
  };
};

export default useChooseDiscount;
