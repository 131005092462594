import React from "react";
import {
  Card,
  CurrencySign,
  PaymentSucces,
  Truck,
  VerticalBlackCoin,
} from "../../../../common/Icons";
import { CustomButton } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import moment from "moment/moment";

function PaymentSuccess({ handleClose, orderCheckoutPaid }) {
  const discountAmount = Number(orderCheckoutPaid?.order?.discountAmount) || 0;
  const totalAmount = Number(orderCheckoutPaid?.order?.totalAmount) || 0;
  let taxAmount = 0.15 * Number(totalAmount);
  const amount = Number(totalAmount) + Number(taxAmount);
  const total = Number(amount) - Number(discountAmount);
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const store = storedUserData?.user?.store ?? "-";
  const fullName = storedUserData?.user?.fullName ?? "-";
  const paymentMethod = orderCheckoutPaid?.paymentMethod;
  const checkoutType = orderCheckoutPaid?.checkoutType;
  const paidParts = orderCheckoutPaid?.checkout?.splitByEqually?.paidParts;
  const updatedAt =
    format(
      new Date(),
      "MM/dd/yyyy HH:mm"
    );
  let paymentIcon, paymentText;
  if (paymentMethod === "CARD") {
    paymentIcon = <Card />;
    paymentText = "Card";
  } else if (paymentMethod === "POINTS") {
    paymentIcon = <VerticalBlackCoin />;
    paymentText = "Points";
  } else if (paymentMethod === "CASH") {
    paymentIcon = <CurrencySign />;
    paymentText = "Card";
  } else if (paymentMethod === "DELIVERY_COMPANIES") {
    paymentIcon = <Truck />;
    paymentText = "Delivery Company";
  }

  const renderOrderDetails = () => {
    if (checkoutType === "FULL_PAY") {
      // Show all items
      return orderCheckoutPaid?.order?.orderedItems.map((item) => (
        <div className="flex justify-between pb-2" key={item.id}>
          <span className="text-sm font-medium">{item.item.englishName}</span>
          <span className="text-sm font-normal text-text-gray">
            x{item.quantity} - Simple
          </span>
          <span className="text-sm font-medium">
            {item.quantity * item.item.salePrice} SAR
          </span>
        </div>
      ));
    } else if (checkoutType === "SPLIT_BY_ITEM") {
      // Filter items by paymentStatus
      return orderCheckoutPaid?.order?.orderedItems
        ?.filter((item) => item.paymentStatus === "paid")
        ?.map((item) => {
          const totalPrice = item.quantity * item?.item?.salePrice;
          return (
            <div className="flex justify-between pb-2" key={item.id}>
              <span className="text-sm font-medium">{item.item.englishName}</span>
              <span className="text-sm font-normal text-text-gray">
                x{item.quantity} -{" "}
                {item.item.type === "SIMPLE"
                  ? "Simple"
                  : `${item.quantity} x ${item.vItem?.variations?.Type ?? ""}-${item.vItem?.variations?.Material ?? ""}-${item.vItem?.variations?.Color ?? ""}-${item.vItem?.variations?.Size ?? ""}`}
              </span>
              <span className="text-sm font-medium">{totalPrice} SAR</span>
            </div>
          );
        });
    } else if (checkoutType === "SPLIT_EQUALLY") {
      // Show paid parts
      return (
        <>
          <div className="text-sm font-medium ">{paidParts ?? "Paid By Parts"}</div>
        </>
      );
    }
    return null;
  };
  

  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center ">
      <div className="bg-white p-4 rounded w-[460px] ">
        <div className="justify-center items-center flex">
          <PaymentSucces />
        </div>
        <span className=" flex items-center justify-center  text-lg font-medium">
          {t("pos_dashboard.payment_successfull")}
        </span>
        <span className=" flex items-center justify-center mb-2 text-lg font-medium">
          {store?.branchName}
        </span>
        <span className=" flex items-center text-text-gray  justify-center mb-4 text-sm font-normal">
          {fullName}, {store?.streetName}, {store?.districtName},{" "}
          {store?.buildingNumber}, {store?.city}
        </span>
        <div className="bg-white  rounded-lg mt-5  p-2 w-full">
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.order_no")}{" "}
            </span>
            <span className="text-sm font-medium">
              #{orderCheckoutPaid?.order?.dailyOrderNumber ?? "-"}
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.date")}{" "}
            </span>
            <span className="text-sm font-medium">
              <span className="text-sm font-medium">{updatedAt}</span>
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium">
              {t("pos_dashboard.time")}{" "}
            </span>
            <span className="text-sm font-medium">
              <span className="text-sm font-medium">{moment()?.format('hh:mm a')}</span>
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.service")}{" "}
            </span>
            <span className="text-sm font-medium">Dine In - Table No. 7</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.created_by")}{" "}
            </span>
            <span className="text-sm font-medium">{fullName}</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.customer")}{" "}
            </span>
            <span className="text-sm font-medium">
              {orderCheckoutPaid?.customer?.name ?? "-"}
            </span>
          </div>
          <hr className="border mb-2" />
          <div className="flex justify-between pb-2  pt-4">
            <span className="text-sm font-normal text-text-gray ">
              {t("pos_dashboard.order_detail")}
            </span>
          </div>
          <div className="h-auto overflow-auto">{renderOrderDetails()}</div>

          <hr className="border mb-2" />
          <div className="flex justify-between pb-2  pt-4">
            <span className="text-sm font-normal text-text-gray ">
              {t("pos_dashboard.payments")}
            </span>
          </div>
          <div className="flex justify-between pb-2 items-center">
            <span className="text-sm font-medium">
              {t("pos_dashboard.payment_method")}{" "}
            </span>
            <div className="flex items-center">
              {paymentIcon}
              <span className="text-sm font-medium ml-1">{paymentText}</span>
            </div>
          </div>

          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.sub_total")}{" "}
            </span>
            <span className="text-sm font-medium">
              {totalAmount.toFixed(2) ?? "-"} SAR
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.discount_applied")}{" "}
            </span>
            <span className="text-sm font-medium">
              {discountAmount.toFixed(2) ?? "0"} SAR
            </span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.vat")}{" "}
            </span>
            <span className="text-sm font-medium">{taxAmount.toFixed(2) ?? "0"} SAR</span>
          </div>
          <div className="flex justify-between pb-2">
            <span className="text-sm font-medium ">
              {t("pos_dashboard.total")}{" "}
            </span>
            <span className="text-sm font-medium">{total.toFixed(2) ?? "-"} SAR</span>
          </div>
        </div>
        <hr className="border" />
        <div className="w-full pt-2">
          <CustomButton
            text={t("pos_dashboard.done")}
            type="button"
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
