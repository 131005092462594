import React, {
  Suspense,
  lazy,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import {
  Loader,
  Breadcrumb,
  CustomButton,
  CustomTable,
  DetailCard,
} from "../../../../common/components";
import Layout from "../../Layout/Layout";
import useSaleInvoicesList from "./useSaleInvoicesList";
import AddInvoice from "./AddInvoice/AddInvoice";
import {
  CopyIcon,
  EyeIcon,
  PrinterIcon,
  UploadIcon,
} from "../../../../common/Icons";
import useTabs from "../../../../../hooks/useTabs";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

// import dynamic components with react lazy components
const CustomModal = lazy(() =>
  import("../../../../common/components/CustomModel/CustomModel")
);

/**
 * Sale Invoices List component
 * Renders table of sale invoices list
 *
 * @returns {JSX.Element} JSX code for rendering the sale invoices list table
 */
export default function SaleInvoicesList() {
  const addEditSaleInvoiceRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const modalState = useSelector((state) => state.modal);

  const tabs = [
    {
      title: t("client_dashboard.invoices.sales.tabs.all"),
      content: () => (
        <DefaultTable
          saleInvoicesList={saleInvoicesList}
          saleInvoicesListLoading={saleInvoicesListLoading}
        />
      ),
    },
    {
      title: t("client_dashboard.invoices.sales.tabs.paid"),
      content: () => (
        <DefaultTable
          saleInvoicesList={saleInvoicesList}
          saleInvoicesListLoading={saleInvoicesListLoading}
        />
      ),
    },
    {
      title: t("client_dashboard.invoices.sales.tabs.pos_transaction"),
      content: () => (
        <DefaultTable
          saleInvoicesList={saleInvoicesList}
          saleInvoicesListLoading={saleInvoicesListLoading}
        />
      ),
    },
  ];

  const { TabContent, TabHeader, activeTab } = useTabs(0, tabs);

  const {
    saleInvoicesList,
    saleInvoicesListLoading,
    singleSaleInvoice,
    customerList,
    itemList,
    singleSaleInvoiceLoading,
    itemDetail,
    itemImage,
    storesList,
    singleInvoice,
    currentPage,
    setCurrentPage,
    itemListLoading,
  } = useSaleInvoicesList(selectedItem?.id, activeTab);

  const DefaultTable = useCallback(
    ({ saleInvoicesList, saleInvoicesListLoading }) => (
      <CustomTable
        tableHeader={tableHeader}
        tableBody={saleInvoicesList ?? []}
        setSelectedItem={setSelectedItem}
        loading={saleInvoicesListLoading}
        dropdownOptions={[
          {
            text: t("buttons.show_details"),
            icon: <EyeIcon />,
            onClick: () => handleViewInvoice("detail"),
          },
          {
            text: t("buttons.print"),
            icon: <PrinterIcon className="w-6" />,
            onClick: () => handleViewInvoice("detail"),
          },
          {
            text: t("buttons.duplicate"),
            icon: <CopyIcon className="w-6" />,
            onClick: () => handleSaleInvoiceActions("duplicate"),
          },
          {
            text: t("buttons.return"),
            onClick: () => handleViewInvoice("detail"),
          },
        ]}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saleInvoicesList, saleInvoicesListLoading]
  );

  const invoiceRef = useRef();
  invoiceRef.current = singleInvoice;

  const handleSaleInvoiceActions = (type) => {
    if (type === "create") setSelectedItem(null);
    dispatch(
      openModal({
        title: `${type} Invoice`,
        type: type,
      })
    );
  };

  const handleViewInvoice = (type) => {
    dispatch(
      openModal({
        title: invoiceRef?.current && (
          <div>
            {invoiceRef.current?.invoiceNumber}
            <br />
            <span className="to-text-secondary font-normal text-base">
              Created By {invoiceRef?.current?.createdBy}
            </span>
          </div>
        ),

        type: type,
        editable: true,
      })
    );
  };

  const handleFormSubmission = () => {
    if (
      addEditSaleInvoiceRef?.current &&
      addEditSaleInvoiceRef?.current?.handleSubmit
    ) {
      addEditSaleInvoiceRef?.current?.handleSubmit();
    }
  };

  const filteredItems = useMemo(
    () => itemList?.filter((item) => item?.type !== "COMPOSITING"),
    [itemList]
  );

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={t("buttons.export")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport("sale-invoices/export-sale-invoice", "SaleInvoices")
            }
          />

          <CustomButton
            text={t("buttons.print")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint("sale-invoices/print-sale-invoice", "Sale Invoice")
            }
          />
          <CustomButton
            text={t("client_dashboard.invoices.sales.create_invoice")}
            width="w-fit"
            border="border"
            hoverTextColor="white"
            bgColor="!bg-surface-primary"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handleSaleInvoiceActions("create");
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        <div className="space-y-3 mt-4">
          <TabHeader position="start" />
          {TabContent()}
        </div>
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={saleInvoicesList ? saleInvoicesList?.length : 0}
        />
      </div>

      {/* modal */}
      <Suspense fallback={<Loader />}>
        <CustomModal
          loading={!isFormSubmitting}
          buttons={{
            create: [[], ["Cancel", "Create"]],
            duplicate: [[], ["Cancel", "Create"]],
            detail: [[], ["Return", "Duplicate", "Print"]],
          }}
          actions={{
            onCreate: () => {
              handleFormSubmission();
            },
            onDuplicate: () => {
              handleSaleInvoiceActions("duplicate");
            },

            onPrint: handlePrint,
          }}
        >
          {/* conditionally render modal for different sale invoice actions */}
          {(modalState?.type === "duplicate" ||
            modalState?.type === "create") && (
            <AddInvoice
              ref={addEditSaleInvoiceRef}
              saleInvoice={selectedItem ? selectedItem : null}
              customerList={customerList ?? []}
              itemList={filteredItems ?? []}
              storesList={storesList ?? []}
              setIsFormSubmitting={setIsFormSubmitting}
              itemListLoading={itemListLoading}
            />
          )}
          {/* detail modal */}
          {modalState?.type === "detail" &&
            (singleSaleInvoiceLoading ? (
              <Loader />
            ) : (
              <DetailCard
                detail={singleSaleInvoice}
                otherDetail={itemDetail}
                otherDetailTitle="Item Details"
                image={itemImage}
              />
            ))}
        </CustomModal>
      </Suspense>
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.invoices.name", href: "#", current: false },
  { name: "client_dashboard.invoices.sales.name", href: "#", current: true },
];

// table headings for sale invoices table
const tableHeader = [
  { label: "Invoice No", key: "invoiceNumber" },
  { label: "Daily Order No", key: "dailyOrderNumber" },
  { label: "Date", key: "saleDate" },
  { label: "Created by", key: "createdBy" },
  { label: "Customer", key: "customer.name" },
  { label: "Store", key: "store.branchName" },
  { label: "Before Tax", key: "saleInvoiceItem.beforeTax", type: "cost" },
  { label: "Total", key: "saleInvoiceItem.subTotal", type: "cost" },
  {
    label: "Status",
    key: "status",
    bgColorKey: {
      Valid: "bg-success-50 text-positive-600",
      Return: "bg-danger-100 text-danger-600",
    },
  },
  { label: "Payment Method", key: "paymentMethod" },
];
