import React, { useEffect } from "react";
import {
  OncePlaceItem1,
  OncePlaceItem2,
  OncePlaceItem3,
  OncePlaceItem4,
  OncePlaceItem5,
} from "../../assets/images";

const OnePlace = () => {
  useEffect(() => {
    const scrollContainer = document.getElementById("scroll-container");

    const showScrollbar = () => {
      scrollContainer.style.overflowX = "auto";
    };

    const hideScrollbar = () => {
      scrollContainer.style.overflowX = "hidden";
    };

    scrollContainer.addEventListener("mouseenter", showScrollbar);
    scrollContainer.addEventListener("mouseleave", hideScrollbar);

    // Clean up the event listeners on component unmount
    return () => {
      scrollContainer.removeEventListener("mouseenter", showScrollbar);
      scrollContainer.removeEventListener("mouseleave", hideScrollbar);
    };
  }, []);

  return (
    <div className="py-16">
      <div className="space-y-3 text-center 1xl:px-29 px-4">
        <h2 className="text-text-primary font-semibold xs:text-5xl text-3xl xs:leading-wider">
          Manage Everything
          <br /> in One Place
        </h2>
        <p className="text-text-secondary font-medium text-base">
          Your All-in-One POS Solution – Elevate your retail efficiency and
          growth
        </p>
      </div>
      <div className="xs:block hidden  1xl:px-29 px-4">
        {" "}
        <div className="grid xs:grid-cols-2 grid-cols-1 gap-2 mx-auto w-fit mt-12">
          <img src={OncePlaceItem1} alt="Item" />
          <img src={OncePlaceItem2} alt="Item" />
        </div>
        <div className="grid xs:grid-cols-3 grid-cols-2 gap-2 w-fit mx-auto mt-2">
          <img src={OncePlaceItem3} alt="Item" />
          <img src={OncePlaceItem4} alt="Item" />
          <img src={OncePlaceItem5} alt="Item" />
        </div>
      </div>

      <div
        id="scroll-container"
        className="xs:hidden flex mt-8 gap-2 ml-4 scroller overflow-x-auto"
      >
        <img src={OncePlaceItem1} alt="Item" className="w-19/20 h-64" />
        <img src={OncePlaceItem2} alt="Item" className="w-19/20 h-64" />
        <img src={OncePlaceItem3} alt="Item" className="w-19/20 h-64" />
        <img src={OncePlaceItem4} alt="Item" className="w-19/20 h-64" />
        <img src={OncePlaceItem5} alt="Item" className="w-19/20 h-64" />
      </div>
    </div>
  );
};

export default OnePlace;
