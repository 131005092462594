import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStaffUsers,
  deleteStaffUser,
} from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { generatePayloadCondition } from "../../../../../../helpers/generatePayloadCondition";

export const useAdminUsers = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateClient, setUpdateClient] = useState(false);
  const { query } = useSelector((state) => state.search);
  let searchParams = ["email", "fullName"];

  const dispatch = useDispatch();
  const { data: staffUser, isLoading: staffUseroading } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );

  useEffect(() => {
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, [currentPage, dispatch, updateClient, query]);

  useEffect(() => {}, [searchQuery]);

  const handleDelete = (id) => {
    dispatch(
      deleteStaffUser({
        id,
      })
    )
      .then(() => {
        setUpdateClient((prev) => !prev);
        closeModal();
      })
      .catch((error) => {
        console.error("Update profile failed:", error);
      });
    closeModal();
  };

  const filteredUsers = staffUser?.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const openModal = (user, modalType) => {
    setSelectedItem(user);
    setActiveModal(modalType);
  };

  const closeModal = () => {
    setActiveModal(null);
    setSelectedItem(null);
  };

  const openAddNewUser = () => {
    setActiveModal("AddNewUser");
  };

  const tableBody = filteredUsers?.map((user) => ({
    phoneno: user.phoneNumber,
    fullName: user.fullName,
    email: user.email,
    role: user.role === "ACCOUNT_MANAGER" ? "Account Manager" : "Accounting",
    id: user.id,
  }));

  const headers = [
    { label: "Full Name", key: "fullName" },
    { label: "Phone No", key: "phoneno" },
    { label: "Email", key: "email" },
    { label: "Role", key: "role" },
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return {
    headers,
    tableBody,
    closeModal,
    openModal,
    selectedItem,
    activeModal,
    openAddNewUser,
    staffUseroading,
    handleDelete,
    setSelectedItem,
    currentPage,
    setCurrentPage,
    handleSearchChange,
    setUpdateClient,
  };
};
