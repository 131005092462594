import axios from "axios";
import { getAccessToken } from "../components/common/utils/access-token.util";
import { showToast } from "../components/common/utils/showToast.util";

/**
 * Function to create an Axios instance for API calls with specified headers and authorization.
 *
 * @param {Object} headers - The headers to be included in the API requests.
 * @returns {Object} Axios instance configured with headers and authorization.
 */
const api = (token, headers = null) => {
  let header = headers;
  const accessToken = getAccessToken();

  // Setting default headers if not provided
  if (!header) {
    header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "ngrok-skip-browser-warning": "true",
    };
  }

  // Creating Axios instance
  const apiSet = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_MAIN_URL,
    headers: accessToken
      ? { ...header, Authorization: `Bearer ${accessToken}` }
      : header,
  });

  // Interceptors for handling API responses and errors
  apiSet.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error) => {
      // Handling network errors
      if (error.message === "Network Error") {
        showToast(error.message, "error");
        throw error;
      }

      // Handling unauthorized access
      if (error.response.status === 401 && typeof window === "object") {
        localStorage.removeItem("token");
        window.location.href = "/";
      }

      // Handling error messages
      let { message } = error.response.data;

      if (!message) {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
      return error.response;
    }
  );

  return apiSet;
};

export default api;

// Sample Usage:
// import api from '.path/api';
// const apiInstance = api({ 'Custom-Header': 'value' });
// apiInstance.get('/endpoint');
