import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { CrossIcon, TrashIcon } from "../../../../../common/Icons";
import {
  Card,
  CustomButton,
  InputGroup,
} from "../../../../../common/components";

import {
  addPaymentMethods,
  getPaymentMethods,
} from "../../../../../../features/Payments/PaymentsSlice";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import { Plus } from "../../../../../common/Icons/fontawesome";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const EditDeliveryCompanies = ({ selected }) => {
  const dispatch = useDispatch();
  const { title, isModalOpen } = useSelector((state) => state.paymentsModal);

  // validation schema for card payment
  const deliveryNotesSchema = z.object({
    companies: z.array(
      z.object({
        companyName: z
          .string()
          .min(3, "Company name must be at least 3 characters long!")
          .max(30, "Maximum 30 characters are allowed!"),
        feePercentage: z.coerce
          .number({ invalid_type_error: "Only numbers are allowed!" })
          .int()
          .min(1, { message: "Fee percentage is required!" }),
        amountAdded: z.coerce
          .number({ invalid_type_error: "Only numbers are allowed!" })
          .int()
          .min(1, { message: "Amount added is required!" }),
      })
    ),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(deliveryNotesSchema),
    defaultValues: {
      companies: selected?.paymentDeliveryNotes?.map((val) => ({
        companyName: val?.delivery?.companyName ?? "",
        feePercentage: val?.delivery?.feePercentage ?? "",
        amountAdded: val?.delivery?.amountAdded ?? "",
        edit: false,
      })),
    },
  });

  const onSubmit = () => {
    const payload = {
      paymentType: "DELIVERY_COMPANIES",
      paymentDeliveryNotes: companies?.map((company) => ({
        companyName: company?.companyName,
        feePercentage: +company?.feePercentage,
        amountAdded: +company?.amountAdded,
      })),
    };

    return dispatch(
      addPaymentMethods({
        id: selected?.id,
        type: "update",
        payload: payload,
        successCallBack: onAddPaymentMethod,
      })
    );
  };

  const onAddPaymentMethod = (data) => {
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  const {
    fields,
    append,
    update,
    remove: removeCompany,
  } = useFieldArray({
    control: control,
    name: "companies",
  });

  const handleAppend = async () => {
    // Trigger validation for all fields in the form
    const isValid = await trigger();

    // Check if the form is valid
    if (isValid) {
      // If the form is valid, append a new item to the form array
      append({
        companyName: "",
        feePercentage: "",
        amountAdded: "",
        edit: true,
      });
    }
  };

  const handleEdit = (i) => {
    update(i, {
      companyName: fields[i].companyName,
      feePercentage: fields[i].feePercentage,
      amountAdded: fields[i].amountAdded,
      edit: true,
    });
  };

  const { companies } = getValues();

  const handleSave = (i) => {
    update(i, {
      companyName: getValues().companies[i].companyName,
      feePercentage: getValues().companies[i].feePercentage,
      amountAdded: getValues().companies[i].amountAdded,
      edit: false,
    });
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5 space-y-3">
              {fields?.map((val, i) => (
                <React.Fragment key={val.id}>
                  {!val.edit ? (
                    <Card padding="px-4 py-3 !mb-5">
                      <div className="grid grid-cols-12">
                        <div className="col-span-8">
                          <div className="grid grid-cols-12 space-y-3">
                            <div className="col-span-full flex flex-col">
                              <h6 className="to-text-secondary text-xs">
                                Delivery Company Name
                              </h6>
                              <span className="to-text-primary text-sm font-medium">
                                {companies[i].companyName}
                              </span>
                            </div>
                            <div className="col-span-6 flex flex-col">
                              <h6 className="to-text-secondary text-xs">
                                Fee Percentage
                              </h6>
                              <span className="to-text-primary text-sm font-medium">
                                {companies[i].feePercentage} %
                              </span>
                            </div>
                            <div className="col-span-6 flex flex-col">
                              <h6 className="to-text-secondary text-xs">
                                Amount Deducted
                              </h6>
                              <span className="to-text-primary text-sm font-medium">
                                {companies[i].amountAdded} SAR
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-4 flex flex-col justify-between items-end">
                          <CustomButton
                            width="w-fit"
                            className="leading-none !shadow-none !text-xs"
                            padding="p-1"
                            textColor="text-text-primary"
                            text="Delete"
                            onClick={() => removeCompany(i)}
                            icon={<TrashIcon />}
                          />
                          <CustomButton
                            onClick={() => handleEdit(i)}
                            className="leading-none !shadow-none !text-xs"
                            text="Edit"
                            width="w-fit"
                            bgColor="bg-trasnparent"
                            border="border"
                            textColor="text-text-primary"
                            icon
                            padding="py-2 px-3"
                          />
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <div className="space-y-4" key={val.id}>
                      <div className="flex items-center justify-between">
                        <span className="to-text-primary font-semibold">
                          Company #{i + 1}
                        </span>
                        {fields?.length > 1 && (
                          <span
                            className="cursor-pointer"
                            onClick={() => removeCompany(i)}
                          >
                            <CrossIcon />
                          </span>
                        )}
                      </div>
                      <InputGroup
                        inputName={`companies.${i}.companyName`}
                        htmlfor="companyName"
                        labelText="Delivery Company Name"
                        placeholder="Enter Delivery Company Name"
                        register={register}
                        errors={errors}
                      />
                      <InputGroup
                        inputName={`companies.${i}.feePercentage`}
                        htmlfor="feePercentage"
                        labelText="Fee Percentage"
                        placeholder="Enter Fee Percentage"
                        register={register}
                        errors={errors}
                      />
                      <InputGroup
                        inputName={`companies.${i}.amountAdded`}
                        htmlfor="amountAdded"
                        labelText="Amount Added"
                        placeholder="Enter Amount Added"
                        register={register}
                        errors={errors}
                      />

                      <CustomButton
                        text={"Save"}
                        width="w-fit"
                        onClick={() => handleSave(i)}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
              <CustomButton
                text={
                  fields?.length > 0 ? "Add Another Company" : "Add Company"
                }
                icon={<Plus color="text-blue-sky" />}
                width="w-fit"
                bgColor="bg-transparent"
                className="shadow-none"
                textColor="text-text-link"
                onClick={handleAppend}
              />
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                text={"Save"}
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditDeliveryCompanies;

// sample usage
// import EditDeliveryCompanies from "./path/to/EditDeliveryCompanies.jsx";

// <EditDeliveryCompanies />
