import React, { Suspense, lazy, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import {
  Loader,
  Breadcrumb,
  CustomButton,
  CustomTable,
  DetailCard,
} from "../../../../common/components";
import Layout from "../../Layout/Layout";
import useExpenseInvoiceList from "./useExpenseInvoice";
import AddInvoice from "./AddInvoice/AddInvoice";
import {
  CopyIcon,
  EditIcon,
  EyeIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import useTabs from "../../../../../hooks/useTabs";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

// import dynamic components with react lazy components
const CustomModal = lazy(() =>
  import("../../../../common/components/CustomModel/CustomModel")
);

/**
 * Expense Invoices List component
 * Renders table of expense invoices list
 *
 * @returns {JSX.Element} JSX code for rendering the expense invoices list table
 */
export default function ExpenseInvoiceList() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const addEditExpenseInvoiceRef = useRef();

  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const tabs = [
    {
      title: "All",
      content: () => <DefaultTable expenseInvoicesList={expenseInvoicesList} />,
    },
    {
      title: "Fully Paid",
      content: () => <DefaultTable expenseInvoicesList={expenseInvoicesList} />,
    },
    {
      title: "Partially Paid",
      content: () => <DefaultTable expenseInvoicesList={expenseInvoicesList} />,
    },
  ];

  const { TabContent, TabHeader, activeTab } = useTabs(0, tabs);

  const {
    expenseInvoicesList,
    expenseInvoicesListLoading,
    singleExpenseInvoice,
    itemList,
    singleExpenseInvoiceLoading,
    expenseDetails,
    storesList,
    singleInvoice,
    currentPage,
    setCurrentPage,
    deleteInvoice,
  } = useExpenseInvoiceList(selectedItem?.id, activeTab);

  const invoiceRef = useRef();
  invoiceRef.current = singleInvoice;

  const DefaultTable = useCallback(
    ({ expenseInvoicesList }) => (
      <CustomTable
        key="CustomTable"
        tableHeader={tableHeader}
        tableBody={expenseInvoicesList}
        setSelectedItem={setSelectedItem}
        loading={expenseInvoicesListLoading}
        dropdownOptions={[
          {
            text: t("buttons.show_details"),
            icon: <EyeIcon />,
            onClick: () => handleViewInvoice("detail"),
          },
          {
            text: t("buttons.print"),
            icon: <PrinterIcon className="w-6" />,
            onClick: () => handleViewInvoice("detail"),
          },
          {
            text: t("buttons.duplicate"),
            icon: <CopyIcon className="w-6" />,
            onClick: () => handleExpenseInvoiceActions("duplicate"),
          },
          {
            text: t("buttons.edit"),
            icon: <EditIcon className="w-6" />,
            onClick: () => handleExpenseInvoiceActions("edit"),
          },
          {
            text: t("buttons.delete"),
            textColor: "text-red-500",
            icon: <TrashIcon />,
            onClick: () => handleExpenseInvoiceActions("delete"),
          },
        ]}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expenseInvoicesListLoading]
  );

  const handleExpenseInvoiceActions = (type) => {
    if (type === "create") setSelectedItem(null);

    dispatch(
      openModal({
        title: `${type} Invoice`,
        type: type,
      })
    );
  };

  const handleViewInvoice = (type) => {
    dispatch(
      openModal({
        title: invoiceRef?.current && (
          <div>
            {invoiceRef.current?.invoiceNumber}
            <br />
            <span className="to-text-secondary font-normal text-base">
              Created By {invoiceRef?.current?.createdBy}
            </span>
          </div>
        ),

        type: type,
        editable: true,
      })
    );
  };

  const handleFormSubmission = () => {
    if (
      addEditExpenseInvoiceRef?.current &&
      addEditExpenseInvoiceRef?.current?.handleSubmit
    ) {
      addEditExpenseInvoiceRef?.current?.handleSubmit();
    }
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={t("buttons.export")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport(
                "expense-invoices/export-expense-invoice",
                "ExpenseInvoices"
              )
            }
          />
          <CustomButton
            text={t("buttons.print")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint(
                "expense-invoices/print-expense-invoice",
                "ExpenseInvoice"
              )
            }
          />
          <CustomButton
            text={t("client_dashboard.invoices.expense.create_invoice")}
            width="w-fit"
            border="border"
            bgColor="!bg-surface-primary"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handleExpenseInvoiceActions("create");
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* expense invoices list table */}
        <div className="space-y-3 mt-4">
          <TabHeader position="start" />
          {TabContent()}
        </div>
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={expenseInvoicesList ? expenseInvoicesList?.length : 0}
        />
      </div>
      {/* modal */}
      <Suspense fallback={<Loader />}>
        <CustomModal
          loading={!isFormSubmitting}
          buttons={{
            create: [[], ["Cancel", "Create"]],
            duplicate: [[], ["Cancel", "Create"]],
            detail: [[], ["Return", "Duplicate", "Print"]],
            delete: [[], ["Dismiss", "Delete"]],
            edit: [["Delete"], ["Save"]],
          }}
          actions={{
            onCreate: () => {
              handleFormSubmission();
            },
            onDuplicate: () => {
              handleExpenseInvoiceActions("duplicate");
            },
            onDelete: () => {
              deleteInvoice();
            },
            onSave: () => {
              handleFormSubmission();
            },
            onPrint: () => {
              handlePrint(
                "expense-invoices/print-expense-invoice",
                "ExpenseInvoice"
              );
            },
          }}
        >
          {/* conditionally render modal for different expense invoice actions */}
          {(modalState?.type === "duplicate" ||
            modalState?.type === "create" ||
            modalState?.type === "edit") && (
            <AddInvoice
              ref={addEditExpenseInvoiceRef}
              expenseInvoice={selectedItem ? selectedItem : null}
              itemList={itemList ?? []}
              storesList={storesList ?? []}
              setIsFormSubmitting={setIsFormSubmitting}
            />
          )}
          {/* detail modal */}
          {modalState?.type === "detail" &&
            (singleExpenseInvoiceLoading ? (
              <Loader />
            ) : (
              <DetailCard
                detail={singleExpenseInvoice}
                otherDetail={expenseDetails}
                otherDetailTitle="Expense Details"
              />
            ))}
          {/* delte modal */}
          {modalState?.type === "delete" && (
            <p className="text-text-primary text-base font-normal">
              Are you sure you want to delete this invoice?{" "}
            </p>
          )}
        </CustomModal>
      </Suspense>
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.invoices.name", href: "#", current: false },
  { name: "client_dashboard.invoices.expense.name", href: "#", current: true },
];

// table headings for expense invoices table
const tableHeader = [
  { label: "Invoice No", key: "invoiceNumber" },
  { label: "Date", key: "supplyDate" },
  { label: "Created by", key: "createdBy" },
  { label: "Expense Name", key: "name" },
  { label: "Store", key: "store.branchName" },
  { label: "Before Tax", key: "expenseInvoiceItem.beforeTax" },
  { label: "Total", key: "expenseInvoiceItem.total" },
  {
    label: "Status",
    key: "paymentStatus",
    bgColorKey: {
      "Fully Paid": "bg-success-50 text-positive-600",
      "Partially Paid": "bg-danger-100 text-danger-600",
    },
  },
  { label: "Payment Method", key: "paymentMethod" },
];
