import React, { useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  Card,
  CustomButton,
  InputGroup,
} from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentMethods,
  getSinglePaymentMethod,
  updatePaymentMethod,
} from "../../../../../../features/Payments/PaymentsSlice";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import { CrossIcon } from "../../../../../common/Icons";

const EditPointsMethod = ({ selected }) => {
  const { title, isModalOpen } = useSelector((state) => state.paymentsModal);
  const dispatch = useDispatch();
  // validation schema for card payment
  const pointsPaymentSchema = z.object({
    valuePerPoint: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Value per point is required!"),
    pointPerValue: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Point per value is required!"),
    minimumReplacementApplication: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Minimum replacement application is required!"),
    minimumReplacementPoints: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .min(1, "Minimum replacement points is required!"),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(pointsPaymentSchema),
  });

  const defaultValues = useMemo(
    () => ({
      valuePerPoint: selected?.paymentPoints?.valuePerPoint ?? "",
      pointPerValue: selected?.paymentPoints?.pointPerValue ?? "",
      minimumReplacementApplication:
        selected?.paymentPoints?.minReplacementApplication ?? "",
      minimumReplacementPoints:
        selected?.paymentPoints?.minReplacementPoints ?? "",
    }),
    [selected]
  );

  useEffect(() => {
    // set the default values for each field to edit selected using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [selected, setValue, defaultValues]);

  const onSubmit = (data) => {
    const payload = {
      paymentType: "POINTS",
      paymentPoints: {
        valuePerPoint: data?.valuePerPoint,
        pointPerValue: data?.pointPerValue,
        minReplacementApplication: data?.minimumReplacementApplication,
        minReplacementPoints: data?.minimumReplacementPoints,
      },
    };

    return dispatch(
      updatePaymentMethod({
        id: selected.id,
        payload: payload,
        successCallBack: onAddPaymentMethod,
      })
    );
  };

  const onAddPaymentMethod = (data) => {
    dispatch(getSinglePaymentMethod({ id: selected?.id }));
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5">
              <Card className="space-y-3" padding="p-3">
                <InputGroup
                  extraLabel="”Each 1 Saudi riyal value equals how many points ”"
                  inputName="valuePerPoint"
                  labelText="Value Per Point"
                  htmlfor="valuePerPoint"
                  placeholder="Enter Value Per Point"
                  register={register}
                  errors={errors}
                />
                <InputGroup
                  extraLabel="“Each 1 Point equals how many Saudi Riyal “"
                  inputName="pointPerValue"
                  labelText="Point Per Value"
                  htmlfor="pointPerValue"
                  placeholder="Enter Point Per Value"
                  register={register}
                  errors={errors}
                />
                <InputGroup
                  extraLabel="“How much is the lowest invoice value accepted”"
                  inputName="minimumReplacementApplication"
                  labelText="Minimum Replacement Application"
                  htmlfor="minimumReplacementApplication"
                  placeholder="Enter Minimum Replacement Application"
                  register={register}
                  errors={errors}
                />
                <InputGroup
                  extraLabel="“How much is the lowest invoice value accepted”"
                  inputName="minimumReplacementPoints"
                  labelText="Minimum Replacement Points"
                  htmlfor="minimumReplacementPoints"
                  placeholder="Enter Minimum Replacement Points"
                  register={register}
                  errors={errors}
                />
              </Card>
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                text={"Save"}
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditPointsMethod;
