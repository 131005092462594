import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import Loader from "../../../../common/components/Loader/Loader";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import CustomTable from "../../../../common/components/CustomTable/CustomTable";
import Layout from "../../Layout/Layout";
import CustomModal from "../../../../common/components/CustomModel/CustomModel";
import DetailCard from "../../../../common/components/DetailCard/DetailCard";
import useCustomersList from "./useCustomersList";
import AddEditCustomer from "./AddEditCustomer/AddEditCustomer";
import {
  EditIcon,
  EyeIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import { useTranslation } from "react-i18next";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

/**
 * customer List component
 * Renders table of customers list
 *
 * @returns {JSX.Element} JSX code for rendering the customer list table
 */

export default function CustomersList() {
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const { t } = useTranslation();

  const {
    customerList,
    singleCustomerLoading,
    customerDetail,
    register,
    errors,
    handleSubmission,
    setValue,
    deleteCustomer,
    customerListLoading,
    isSubmitting,
    watch,
    currentPage,
    setCurrentPage,
    clearErrors,
  } = useCustomersList(selectedItem?.id);

  const handleViewCustomer = (type) => {
    dispatch(
      openModal({
        title: `Customer ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleCustomerActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type} Customer`,
        type: type,
      })
    );
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text="buttons.export"
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport("customers/export-customer", "Customers.xlsx")
            }
          />
          <CustomButton
            text="buttons.print"
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint("customers/print-sale-invoice", "Customers")
            }
          />
          <CustomButton
            text={"client_dashboard.directory.customer.add_customer"}
            width="w-fit"
            bgColor="!bg-surface-primary"
            hoverTextColor="text-white"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handleCustomerActions("add");
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* customer list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={customerList}
          setSelectedItem={setSelectedItem}
          loading={customerListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewCustomer("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleCustomerActions("edit"),
            },

            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleCustomerActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={customerList ? customerList?.length : 0}
        />
      </div>

      {/* modal */}
      <CustomModal
        onAdd={() => handleSubmission()}
        onEdit={() => handleCustomerActions("edit")}
        onDelete={() => deleteCustomer()}
        loading={isSubmitting}
        isInputChanged={isInputChanged}
      >
        {/* conditionally render modal for different customer actions */}
        {(modalState?.type === "edit" || modalState?.type === "add") && (
          <AddEditCustomer
            customer={selectedItem ? selectedItem : null}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            setIsInputChanged={setIsInputChanged}
          />
        )}
        {/* detail modal */}
        {modalState?.type === "detail" &&
          (singleCustomerLoading ? (
            <Loader />
          ) : (
            <DetailCard detail={customerDetail} />
          ))}
        {/* delete modal */}
        {modalState?.type === "delete" && (
          <p className="text-text-primary text-base font-normal">
            {t("client_dashboard.directory.customer.sure")}{" "}
          </p>
        )}
      </CustomModal>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.directory.name", href: "#", current: false },
  {
    name: "client_dashboard.directory.customer.name",
    href: "#",
    current: true,
  },
];
// table headings for customers table
const tableHeader = [
  { label: "Name", key: "name" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Email", key: "email" },
  { label: "Last Order", key: "lastOrder", type: "date" },
  { label: "Total Sales", key: "totalSales" },
  { label: "Points", key: "points" },
];
