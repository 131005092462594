import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    lng: "en", // Default language
    resources: {
      en: {
        translation: {
          buttons: {
            add: "Add",
            save: "Save",
            export: "Export",
            signup_with_google: "Signup with Google",
            signup_with_facebook: "Signup with Facebook",
            or: "or",
            continue: "Continue",
            need_help: "Need Help?",
            save_changes: "Save Changes",
            cancel: "Cancel",
            reset: "Reset",
            delete: "Delete",
            dismiss: "Dismiss",
            signout: "Sign Out",
            send: "Send",
            show_details: "Show Details",
            edit: "Edit",
            duplicate: "Duplicate",
            print: "Print",
            return: "Return",
            drag_or_click: "Drag or click to upload",
            done: "Done",
            add_value: "Add value",
          },
          validations: {
            supported_format: "Supported format",
            unsupported_format:
              "Unsupported file format. Please select a JPG, JPEG, or PNG file.",
            file_size: "File is too large. Max size allowed is 10MB.",
          },
          login: {
            title: "Login",
            welcome: "Welcome Back! Sign in to access your account",
            login_with_google: "Login with Google",
            login_with_facebook: "Login with Facebook",
            or: "Or",
            email: "Email",
            email_placeholder: "Enter email",
            password: "Password",
            password_placeholder: "Enter password",
            remember_me: "Remember me",
            forgot_password: "Forgot password?",
            account_confirmation: "Don't have an account?",
            validations: {
              email_error: "Email is required!",
              email_error_invalid: "Please use a valid email address!",
              password_error: "Password is required!",
              password_error_min: "Password must be at least 8 characters long",
              password_error_uppercase:
                "Password must contain at least one uppercase letter",
              password_error_special_char:
                "Password must contain at least one special character",
              password_error_alphabet:
                "Password must contain at least one alphabet character",
            },
          },
          recover_account: {
            name: "Recover Account",
            description:
              "Enter the email address associated with your account and we'll send you a link to reset your password.",
            email: "Email",
            enter_email: "Enter Email",
          },
          signup: {
            title: "Sign Up",
            welcome: "Get started using TagDev",
            or: "or",
            email: "Email",
            email_placeholder: "Enter Email",
            already_have_account: "Already have an account?",
            agreement: "By creating an account, you agree to our",
            terms_and_conditions: "Terms and Conditions",
            privacy_notice: "Privacy Notice",
            and: "and",
          },
          confirm_email: {
            title: "Confirm Your Email",
            instructions:
              "We've sent an email with a code to {{email}}, please enter it below to create your account.",
            didnt_receive_code: "Didn’t receive the code?",
            resend_code: "Resend Code",
            image_heading: "Maximize Your Sales Momentum",
            image_description:
              "Elevate Your Business and Maximize Your Revenue Potential. Get Started Today!",
          },
          create_password: {
            title: "Create Your Password",
          },
          create_password_form: {
            new_password: "New Password",
            confirm_password: "Confirm Password",
            enter_new_password: "Enter New Password",
            enter_password_again: "Enter Password Again",
            enter_current_password: "Enter Current Password",
            use_at_least_8_characters: "Use at least 8 characters",
            use_mix_of_letters_numbers_special_chars:
              "Use a mix of letters, numbers, and special characters (e.g.: #$%)",
            create_password: "Create Password",
            current_password: "Current Password",
            save_changes: "Save Changes",
            email: " Email",
            enter_email: " Enter Email",
          },
          profile_layout: {
            start_create_account: "Start Creating Your Account",
            your_email: "Your Email",
          },
          stepper: {
            create_password: "Create Password",
            complete_profile: "Complete Profile",
            complete_business_profile: "Complete Business Profile",
            setup_your_plan: "Setup Your Plan",
          },
          create_account: {
            complete_profile: "Complete Profile",
            heading: "Complete Profile",
            upload_picture: "Upload Picture",
            change_picture: "Change Picture",
            fullname: "Full Name",
            enter_name: "Enter Full Name",
            arabicname: "Arabic Name",
            enter_arabic_name: "Enter Arabic Name",
            phonenumber: "Phone Number",
            enter_phone_number: "Enter Phone Number",
            continue: "Continue",
            image_upload_error: "Error uploading image",
            image_upload_info:
              "*png, .jpeg files up to 10 MB, at least 400px by 400px",
          },
          business_profile: {
            complete_business_profile: "Complete Business Profile",
            heading: "Complete Business Profile",
            company_info: "Company Info",
            company_name: "Company Name",
            enter_company_name: "Enter Company Name",
            company_address: "Company Address",
            enter_company_address: "Enter Company Address",
            website: "Website",
            commercial_register: "Commercial Register",
            enter_commercial_register: "Enter Commercial Register",
            vat_number: "VAT Number",
            enter_vat_number: "Enter VAT Number",
            business_type: "Type of your business",
            select_business_type: "Select Business Type",
            continue: "Continue",
            coffee_shop: "Coffee shop",
            restaurant: "Restaurant",
            shop: "Shop",
            super_market: "Super Market",
            food_truck: "Food Truck",
            gift_shop: "Gift Shop",
            hair_dressers: "Hair Dressers",
            car_services: "Car Services",
            express_services: "Express Services",
            delete_and_start: "Delete Data and Start Over",
            invoice_template: "Invoice Template",
            edit_template: "Edit Template of Invoice",
          },
          client_dashboard: {
            overview: "Overview",
            messages: "Messages",
            invoices: {
              name: "Invoices",
              sales: {
                name: "Sales",
                create_invoice: "Create Invoice",
                customer: "Customer",
                add_new_customer: "Add New Customer",
                add_customer: "Add Customer",
                select_customer: "Select Customer",
                store: "Store",
                select_store: "Select Store",
                sale_date: "Sale Date",
                payment_method: "Payment Method",
                select_payment_method: "Select Payment Method",
                item_details: "Item Details",
                item: "Item",
                select_item: "Select Item",
                available_quantity: "Available Quantity",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                unit_price: "Unit Price",
                before_vat: "Before VAT",
                vat_amount: "VAT Amount",
                total: "Total",
                note: "Note",
                enter_note: "Enter Note",
                tabs: {
                  all: "All",
                  paid: "Paid",
                  pos_transaction: "POS Transaction",
                },
                validations: {
                  customer_error: "Customer is required!",
                  store_error: "Store is required!",
                  date_error: "Date is required!",
                  paymentmethod_error: "Payment Method is required!",
                  item_error: "Item is required!",
                  quantity_error: "Quantity is required!",
                  unitprice_error: "Unit Price is required!",
                  beforetax_error: "Before Tax is required!",
                  taxamount_error: "Tax Amount is required!",
                  subtotal_error: "Subtotal is required!",
                  note_error: "Note is required!",
                },
              },
              purchase: {
                name: "Purchase",
                create_invoice: "Create Invoice",
                supplier: "Supplier",
                add_new_supplier: "Add New Supplier",
                add_supplier: "Add Supplier",
                choose_supplier: "Choose Supplier",
                store: "Store",
                select_store: "Select Store",
                supply_date: "Supply Date",
                payment_method: "Payment Method",
                select_payment_method: "Select Payment Method",
                payment_status: "Payment Status",
                select_payment_status: "Select Payment Status",
                item_details: "Item Details",
                item: "Item",
                select_item: "Select Item",
                available_quantity: "Available Quantity",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                unit_price: "Unit Price",
                before_tax: "Before Tax",
                tax_amount: "Tax Amount",
                subtotal: "Subtotal",
                note: "Note",
                enter_note: "Enter Note",
                tabs: {
                  all: "All",
                  fully_paid: "Fully Paid",
                  partially_paid: "Partially Paid",
                },
                validations: {
                  supplierid_error: "Supplier is required!",
                  store_error: "Store is required!",
                  date_error: "Date is required!",
                  paymentmethod_error: "Payment Method is required!",
                  paymentStatus_error: "Payment Status is required!",
                  item_error: "Item is required!",
                  quantity_error: "Quantity is required!",
                  quantity_error_max: "Maximum quantity allowed is 1,000,000!",
                  unitprice_error: "Unit Price is required!",
                  beforetax_error: "Before Tax is required!",
                  taxamount_error: "Tax Amount is required!",
                  subtotal_error: "Subtotal is required!",
                  note_error: "Note is required!",
                  only_numbers_error: "Only numbers are allowed!",
                },
              },
              expense: {
                name: "Expense",
                create_invoice: "Create Invoice",
                expense_name: "Expense Name",
                enter_expense_name: "Enter Expense Name",
                invoice_no: "Invoice Number",
                enter_invoice_no: "Enter Invoice Number",
                store: "Store",
                select_store: "Select Store",
                supply_date: "Supply Date",
                payment_method: "Payment Method",
                select_payment_method: "Select Payment Method",
                expense_details: "Expense Details",
                expense_category: "Expense Category",
                select_expense_category: "Select Expense Category",
                amount: "Amount",
                enter_amount: "Enter Amount",
                before_tax: "Before Tax",
                enter_before_tax_price: "Enter Before Tax Price",
                tax_amount: "Tax Amount",
                enter_tax_amount: "Enter Tax Amount",
                total: "Total",
                enter_total_amount: "Enter Total Amount",
                note: "Note",
                enter_note: "Enter Note",
                validations: {
                  expensename_error: "Expense Name is required!",
                  expensename_error_min:
                    "Expense Name must be at least 3 characters long",
                  expensename_error_max:
                    "Expense Name must be no longer than 100 characters",
                  invoicenumber_error: "Invoice Number is required!",
                  invoicenumber_error_invalid: "Only numbers are allowed!",
                  invoicenumber_error_min:
                    "Invoice Number must be at least 3 digits long!",
                  max_number_error: "Maximum number allowed is 1,000,000!",
                  store_error: "Store is required!",
                  date_error: "Date is required!",
                  paymentmethod_error: "Payment Method is required!",
                  expensecategory_error: "Expense Category is required!",
                  amount_error: "Amount is required!",
                  beforetax_error: "Before Tax is required!",
                  taxamount_error: "Tax Amount is required!",
                  total_error: "Total is required!",
                  note_error: "",
                },
              },
            },
            inventory: {
              name: "Inventory",
              inventory_transfer: {
                name: "Inventory Transfer",
                add_inventory: "Add Transfer",
                add_more: "Add More Item",
                storefrom: "Store (From)",
                select_storefrom: "Select Store(from)",
                storeto: "Store (To)",
                select_storeto: "Select Store(to)",
                date: "Date",
                item: "Item",
                select_item: "Select Item",
                unitmeasure: "Unit of Measure",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                total_quantity: "Total quantity",
                number_unit: "Number Unit of Measure",
                enter_number_unit: "Enter Number Unit Of Measure",
                description: "Description",
                enter_description: "Enter Description",
                validations: {
                  storeto_error: "Store to is required!",
                  storefrom_error: "Store from is required!",
                  date_error: "Date is required!",
                  itemid_error: "Item is required!",
                  unitmeasure_error: "Unit measure is required!",
                  numberunit_error: "Number of units is required!",
                  quantity_error: "Quantity is required!",
                  description_error: "Description is required!",
                },
              },
              stocktaking: {
                name: "Stocktaking",
                create_stocktaking: "Create Stocktaking",
                count_name: "Count Name",
                enter_count_name: "Enter Count Name",
                description: "Description",
                enter_description: "Enter Description",
                date: "Date",
                store: "Store",
                select_store: "Select Store",
                item_details: "Item Details",
                detail: "Detail",
                item: "Item",
                select_item: "Select Item",
                unitmeasure: "Total Number Unit of Measure",
                expected: "Expected",
                quantity: "Quantity",
                enter_quantity: "Enter Quantity",
                cost_price: "Cost Price",
                note: "Note",
                enter_note: "Enter Note",
                validations: {
                  countName_error: "Count Name is required!",
                  countName_error_max: "Maximum 100 characters are allowed!",
                  description_error: "Description is required!",
                  description_error_max: "Maximum 200 characters are allowed",
                  date_error: "Date is required!",
                  store_error: "Store is required!",
                  item_error: "Item is required!",
                  expected_error: "Expected value must be a number!",
                  quantity_error: "Quantity is required!",
                  quantity_error_max: "Maximum quantity allowed is 1,000,000!",
                  costPrice_error: "Cost Price must be a number!",
                  note_error: "",
                },
              },
              inventory_transaction: {
                name: "Inventory Transaction",
                inventory_transfer: "Inventory Transfer",
                stocktaking: "Stocktaking",
                sale_invoices: "Sale Invoices",
                purchase_invoices: "Purchase Invoices",
              },
            },
            directory: {
              name: "Directory",
              customer: {
                name: "Customer",
                add_customer: "Add Customer",
                customer_name: "Customer Name",
                enter_name: "Enter Customer Name",
                email: "Email",
                enter_email: "Enter Email",
                phone_number: "Phone Number",
                enter_phone: "Enter Phone Number",
                select_gender: "Select Gender",
                male: "Male",
                female: "Female",

                delete: "Customer deleted successfully!",
                sure: "Are you sure you want to delete this customer?",
                validations: {
                  name_error: "Full Name must be at least 3 characters long!",
                  name_error_max: "Maximum 100 characters are allowed!",
                  email_error: "Email address is required!",
                  email_error_valid: "Please use a valid email address!",
                  phone_error: "Phone number must start with '+'!",
                  gender_error: "Gender is required!",
                },
              },
              supplier: {
                name: "Suppliers",
                add_supplier: "Add Supplier",
                english_name: "English Name",
                company_name: "Company Name",
                arabic_name: "Arabic Name",
                enter_english_name: "Enter English Name",
                enter_company_name: "Enter Company Name",
                email: "Email",
                enter_email: "Enter Your Email",
                phone_number: "Phone Number",
                enter_address: "Address",
                enter_addreess: "Enter Your Address", // Note: This key is duplicated with a typo. Consider removing or correcting one.
                enter_phone: "Enter Your Phone Number",
                vat_number: "VAT Number",
                enter_vatnumber: "Enter VAT Number",
                delete: "The customer has been deleted successfully!",
                sure: "Are you sure you want to delete this customer?",
                validations: {
                  englishname:
                    "The full name must be at least 3 characters long!",
                  name_error_max: "A maximum of 100 characters is allowed!",
                  email_error: "Email is required!",
                  email_error_valid: "Please use a valid email address!",
                  phone_error: "Phone number must start with '+'!",
                  phone_error_max: "Phone number must not exceed 20 digits!",
                  phone_error_regex:
                    "Phone number must start with + followed by numbers!",
                  error_address: "Address is required",
                  error_address_max: "Address must not exceed 100 characters!",
                  company_error: "Company name is required!",
                  company_error_max:
                    "Company name must not exceed 100 characters!",
                  vat_error:
                    "VAT number must consist of exactly 15 characters.",
                },
              },
              items: {
                name: "Items",
                simple_item: {
                  name: "Simple Item",
                  english_name: "English Name",
                  enter_english_name: "Enter English Name",
                  arabic_name: "Arabic Name",
                  enter_arabic_name: "Enter Arabic Name",
                  description: "Description",
                  enter_description: "Enter Description",
                  select_store: "Select Store",
                  item_cost: "Item Cost",
                  enter_item_cost: "Enter Item Cost",
                  select_category: "Select Category",
                  sale_price: "Sale Price (without tax)",
                  enter_sale_price: "Enter Sale Price",
                  enter_barcode: "Enter Barcode",
                  create_barcode_num: "Generate Barcode Number",
                  validations: {
                    englishname_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_error: "Store is required!",
                    itemcost_error: "Item cost is required!",
                    itemcost_error_min: "Item cost must be at least 1!",
                    itemcost_error_max: "Maximum number allowed is 1,000,000!",
                    category_error: "Category is required!",
                    saleprice_error: "Sale Price is required!",
                    saleprice_error_min: "Sale Price must be at least 1!",
                    saleprice_error_max: "Maximum number allowed is 1,000,000!",
                  },
                },
                variable_item: {
                  name: "Variable Item",
                  item_name: "Item Name",
                  enter_item_name: "Enter Item Name",
                  arabic_name: "Arabic Name",
                  enter_arabic_name: "Item Arabic Name",
                  description: "Description",
                  enter_description: "Enter Item Description",
                  store: "Store",
                  select_store: "Select Store",
                  category: "Category",
                  select_category: "Select Category",
                  variables: "Variables",
                  variable: "Variable",
                  variable_name: "Variable Name",
                  select_variable_name: "Select Variable Name",
                  value: "Value",
                  enter_value: "Enter Value",
                  add_another_variable: "Add another Variable",
                  validations: {
                    englishname_required_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_required_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_required_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_required_error: "Store is required!",
                    category_required_error: "Category is required!",
                    variablename_required_error: "Variable Name is required!",
                    value_required_error: "Value is required!",
                  },
                },
                compositing_item: {
                  name: "Compositing Item",
                  item_name: "Item Name",
                  arabic_item_name: "Arabic Item Name",
                  description: "Description",
                  select_store: "Select Store",
                  select_category: "Select Category",
                  sale_price_without_tax: "Sale Price (without tax)",
                  raw_items: "Raw Items",
                  select_raw_items: "Select Raw Items",
                  unit_of_measure: "Unit of Measure",
                  per_unit_price: "Per Unit Price",
                  number_unit_of_measure: "Number Unit of Measure",
                  item_cost: "Item Cost",
                  total_cost: "Total Cost",
                  composition_label: "Composition",
                  add_another_composition: "Add Another Composition",
                  create_sku_number: "Create SKU Number",

                  enter_item_name: "Enter Item Name",
                  enter_arabic_item_name: "Enter Arabic Item Name",
                  enter_description: "Enter Description",
                  enter_sale_price: "Enter Sale Price",
                  enter_number_unit_of_measure: "Enter Number Unit of Measure",
                  validations: {
                    englishname_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_error: "Store is required!",
                    category_error: "Category is required!",
                    saleprice_error: "Sale Price is required!",
                    saleprice_error_min: "Sale Price must be at least 1!",
                    saleprice_error_max: "Maximum number allowed is 1,000,000!",
                    totalcost_error: "Total cost is required!",
                    totalcost_error_min: "Total cost must be at least 1!",
                    totalcost_error_max: "Maximum number allowed is 1,000,000!",
                    itemcost_error: "Item cost is required!",
                    itemcost_error_min: "Item cost must be at least 1!",
                    itemcost_error_max: "Maximum number allowed is 1,000,000!",
                    rawitem_error: "Raw Item is required!",
                    unitmeasure_error: "Unit measure is optional",
                    perunitprice_error: "Per unit price is required!",
                    perunitprice_error_min:
                      "Per unit price must be at least 1!",
                    perunitprice_error_max:
                      "Maximum number allowed is 1,000,000!",
                    numberunit_error: "Number of unit is required!",
                    numberunit_error_min: "Number of unit must be at least 1!",
                    numberunit_error_max:
                      "Maximum number allowed is 1,000,000!",
                    number_only_error: "Only numbers are allowed!",
                  },
                },

                raw_item: {
                  name: "Raw Item",
                  item_name: "Item Name",
                  enter_item_name: "Enter Item Name",
                  arabic_item_name: "Arabic Item Name",
                  enter_arabic_item_name: "Enter Arabic Item Name",
                  description: "Description",
                  enter_description: "Enter Description",
                  select_store: "Select Store",
                  item_cost: "Item Cost",
                  enter_item_cost: "Enter Item Cost",
                  sale_price: "Sale Price (without tax)",
                  enter_sale_price: "Enter Sale Price",
                  select_category: "Select Category",
                  unit_of_measure: "Unit of Measure",
                  number_unit_of_measure: "Number Unit of Measure",
                  enter_number_unit_of_measure: "Enter Number Unit of Measure",
                  create_sku_number: "Create SKU Number",
                  validations: {
                    englishname_required_error: "English Name is required!",
                    englishname_error_min:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "English Name must be no longer than 100 characters!",
                    arabicname_required_error: "Arabic Name is required!",
                    arabicname_error_min:
                      "Arabic Name must be at least 3 characters long!",
                    arabicname_error_max:
                      "Arabic Name must be no longer than 100 characters!",
                    description_required_error: "Description is required!",
                    description_error_min:
                      "Description must be at least 3 characters long!",
                    description_error_max:
                      "Description must be no longer than 500 characters!",
                    store_error_min: "Store is required!",
                    itemcost_number_only_error: "Only numbers are allowed!",
                    itemcost_error_min: "Item cost is required!",
                    itemcost_error_max: "Maximum number allowed is 1,000,000!",
                    saleprice_number_only_error: "Only numbers are allowed!",
                    saleprice_error_min: "Sale Price is required!",
                    saleprice_error_max: "Maximum number allowed is 1,000,000!",
                    category_error_min: "Category is required!",
                    unitmeasure_error_min: "Unit of measure is required!",
                    numberunit_number_only_error: "Only numbers are allowed!",
                    numberunit_error_min: "Number of unit is required!",
                    numberunit_error_max:
                      "Maximum number allowed is 1,000,000!",
                  },
                },
              },
              category: {
                name: "Category",
                add_category: "Add Category",
                english_name: "English Name",
                arabic_name: "Arabic Name",
                enter_english_name: "Enter English Name",
                enter_arabic_name: "Enter Arabic Name",
                view_this_category: "View This Category in POS",
                validations: {
                  englishname_error:
                    "English name must be at least 3 characters long!",
                  englishname_error_max: "Maximum 100 characters are allowed!",
                },
              },
            },
            report: {
              name: "Report",
              sales: "Sales",
              purchase: "Purchase",
              taxes: "Taxes",
              profit_loss: "Profit & Loss",
              expenses: "Expense",
              inventory: "Inventory",
            },
            management: {
              name: "Management",
              setting: "Setting",
              users: {
                name: "Users",
                add_user: "Add User",
                user_name: "Name",
                enter_name: "Enter Name",
                email: "Email",
                enter_email: "Enter Email",
                create_password: "Create Password",
                enter_password: "Enter Password",
                code: "POS Fast Access Code",
                enter_code: "Enter Code",
                access_to_dashboard: "Access to Dashboard",
                access_to_pos: "Access to POS",
                store: "Store",
                select_store: "Select Store",
                select_role: "Select Role",
                roles: {
                  cashier: "Cashier",
                  sales_manager: "Sales Manager",
                  system_manager: "System Manager",
                },
                validations: {
                  name_error: "Name must be at least 3 characters long!",
                  name_error_max: "Maximum 100 characters are allowed!",
                  email_error: "Email address is required!",
                  email_error_valid: "Please use a valid email address!",
                  password_error:
                    "Password must be at least 8 characters long!",
                  password_error_uppercase:
                    "Password must contain at least one uppercase letter!",
                  password_error_special:
                    "Password must contain at least one special character!",
                  password_error_numeric:
                    "Password must contain at least one numerical digit!",
                  role_error: "Role is required!",
                  store_error: "You must select at least one store!",
                  code_error: "Code must be a 4-digit number!",
                  only_numbers_error: "Only numbers are allowed!",
                },
              },
              stores: {
                name: "Stores",
                add_store: "Add Store",
                branch_name: "Name of Branch",
                store_name: "Store Name",
                enter_name: "Enter Name",
                store_address: "Store Address",
                street_name: "Street Name",
                enter_street_name: "Enter Street Name",
                building_no: "Building No",
                enter_building_no: "Enter Building No",
                district_name: "District Name",
                enter_district_name: "Enter District Name",
                city: "City",
                enter_city: "Enter City",
                zip_code: "ZIP Code",
                enter_zip_code: "Enter ZIP Code",
                validations: {
                  max_100: "Maximum 100 characters are allowed!",
                  branchname_error:
                    "Branch name must be at least 3 characters long!",
                  streetname_error:
                    "Street name must be at least 3 characters long!",
                  districtname_error:
                    "District name must be at least 3 characters long!",
                  cityname_error:
                    "City name must be at least 3 characters long!",
                  zipcode_error_min: "ZipCode is required!",
                  zipcode_error_max: "Maximum 12 digits are allowed!",
                  buildingno_error: "Building number is required!",
                  buildingno_error_max: "Maximum 4 digits are allowed!",
                  only_numbers_error: "Only numbers are allowed!",
                },
              },
              payments: "Payments",
              promotions: {
                name: "Promotions",
                add_promotions: "Add Promotions",
                enter_english_name: "Enter English Name",
                english_name: "English Name",
                start_date: "Start Date",
                end_date: "End Date",
                promotion_type: "Promotion Type",
                promotion_store: "Select Store",
                promotion_select_item: "Select Item",
                promotion_value: "Promotion Value",
                promotion_all_product: "All Product",
                promotion_product: "Product",
                select_type: "Select Type",
                validations: {
                  name_error_min:
                    "Promotion Name must be  more than 3 characters!",
                  name_error_max:
                    "Promotion Name must be no more than 100 characters!",
                  start_date_error: "Start Date is required!",
                  end_date_error: "End Date is required!",
                  type_error: "Type is required!",
                  value_error: "Value is required!",
                  store_error: "At least one store must be selected",
                  choose_error: "Choose Single Product or All Product",
                  select_product_error:
                    "You must select Single product or check 'All Product",
                  enter_promotion_value: "Enter Promotion Value",
                },
              },
              settings: {
                profile: {
                  name: "Profile",
                  profile_pic: "Profile Picture",
                  english_name: "English Name",
                  enter_english_name: "Enter English Name",
                  arabic_name: "Arabic Name",
                  enter_arabic_name: "Enter Arabic Name",
                  email: "Email",
                  enter_email: "Enter Email",
                  phone: "Phone",
                  enter_phone: "Enter Phone",
                  change_picture: "Change Picture",
                  supported_format: "Supported Format",
                  validations: {
                    englishname_error:
                      "English Name must be at least 3 characters long!",
                    englishname_error_max:
                      "Maximum 100 characters are allowed!",
                    arabicname_error:
                      "Arabic name must be at least 3 characters long!",
                    arabicname_error_max: "Maximum 100 characters are allowed!",
                    email_error: "Email is required!",
                    email_error_valid: "Please use a valid email address!",
                    phone_error: "Phone Number must be at least 3 digits long!",
                    phone_error_max:
                      "Phone Number should not exceed 20 digits!",
                    phone_error_format:
                      "Phone Number must start with + and be followed by numeric digits!",
                    img_error: "Image is required!",
                    img_error_dimensions:
                      "Image dimensions must be at least 400px by 400px!",
                    img_err_size: "File size must not exceed 10 MB!",
                    img_err_type:
                      "Invalid file type. Only PNG, JPEG and PDF are allowed!",
                    img_err_cumpolsory: "Uploading an image is mandatory!",
                  },
                },
                business_info: {
                  name: "Business Info",
                  validations: {
                    companyname_error:
                      "Company name must be at least 3 characters long!",
                    companyname_error_max:
                      "Maximum 100 characters are allowed!",
                    companyaddress_error:
                      "Company address must be at least 3 characters long!",
                    companyaddress_error_max:
                      "Maximum 100 characters are allowed!",
                    website_error: "Invalid website!",
                    commercialregister_error:
                      "Commercial register must be at least 3 characters long!",
                    commercialregister_error_max:
                      "Maximum 100 characters are allowed!",
                    vatnumber_error:
                      "VAT number must be exactly 15 digits long!",
                    businesstype_error: "Business type is required!",
                  },
                },
                subscription: "Subscription",
                password: {
                  name: "Password",
                  validations: {
                    currentpassword_error: "Current password is required!",
                    newpassword_error:
                      "Password must be at least 8 characters long!",
                    newpassword_error_uppercase:
                      "Password must contain at least one uppercase letter!",
                    newpassword_error_special:
                      "Password must contain at least one special character!",
                    newpassword_error_numeric:
                      "Password must contain at least one numerical digit!",
                    confirmpassword_error: "Confirm Password is required!",
                    passwords_match_error: "Passwords don't match!",
                  },
                },
              },
            },
            trial_info: {
              days_left: "Your trial will end in 7 days 🚀",
              plan_info: "Your Basic plan is a free trial.",
              upgrade_button: "Upgrade Plan",
            },
          },
          pos_dashboard: {
            home_button: "Home",
            order_button: "Order",
            table_button: "Table",
            drawer_button: "Drawer",
            categories: "Categories",
            Search_item: "Search item...",
            items: "Items",
            order_detail: "Order Detail",
            save_order: "Save Order",
            takeaway: "Takeaway",
            dine_in: "Dine In",
            no_item: "No Item Added Yet",
            empty_state_for_order:
              "There are currently no items to display in this table. You can add new items to populate this list",
            add_table: "Add Table",
            add_customer: "Add Customer",
            add_discount: "Add Discount",
            sub_total: "Sub Total",
            discount: "Discount",
            vat: "VAT",
            total: "Total",
            paynow: "Pay Now ",
            choose_promotion: "Choose Promotion",
            choose_discount: "Choose Discount",
            choose_customer: "Choose Customer",
            change: "Change",
            table_number: "Table Number",
            select_customer: "Selected Customer",
            choose_table: "Choose Table",
            available: "Avaialble",
            reserved: "Reserved",
            selected: "Selected",
            selected_tables: " Selected Table: ",
            none: "None",
            enter_fast_access_code: "Enter Fast Access Code",
            saved: "Saved",
            orders: "Orders",
            numbers: "Numbers",
            add_new_table: "Add New Table",
            reset_table: "Reset Table",
            no_table_added: "No Table Added",
            add_table_text:
              "Start to add your first table by clicking the button below",
            add_table_label: " What kind of table you want to add? *Select one",
            how_many: "How many ?",
            cancel: "Cancel",
            select_how_many_table: "Select how many Tables",
            one: "1",
            two: "2",
            three: "3",
            four: "4",
            five: "5",
            six: "6",
            seven: "7",
            eight: "8",
            nine: "9",
            zero: "0",
            backspace: "←",
            dot: ".",
            online: "Online",
            enter_store_code: "Enter Store Code",
            use_email_password: "Use Email & Password",
            confirm: "Confirm",
            login_account: "Login to Your Account",
            pos_email: "Email",
            pos_password: "Password",
            pos_remember_me: "Remember me",
            pos_login: "Login",
            use_code: "Use Code",
            copyright: "Copyright © 2023 TagDev",
            download_our_app: "Download Our App",
            login_to_your_store: "Login to your Store",
            are_you_sure_about_choosing_the_store:
              "Are you sure about choosing the",
            branch: "branch",
            question: "?",
            yes_choose: "Yes,Choose",
            Cancel: "Cancel",
            checkout: "Checkout",
            choose_payment_method: "Choose Payment Method",
            split_equally: "Split Equally",
            split_by_item: "Split By Item",
            full_pay: "Full Pay",
            cash: "Cash",
            card: "Card",
            points: "Points",
            delivery_order: "Delivery Order",
            fee_percentage: "Fee Percentage",
            amount_added: "Amount Added",
            choose_method: "Choose Method",
            choose_card: "Choose Card",
            selected_customer: "Select Customer",
            paying: "Paying",
            add_part: "Add Part",
            payment_method: "Payment Method",
            payments: "Payments",
            customer: "Customer",
            created_by: "Created by",
            service: "Service",
            time: "Time",
            date: "Date",
            order_no: "Order No",
            payment_successfull: "Payment Successful!",
            discount_applied: "Discount applied",
            done: "Done",
            service_type: "Service Type",
            save_promotion: "Save Promotion",
            add_new_customer: "Add New Customer",
            add_note: "Add Note",
            remove_note: "Remove Note",
            item: "Item",
            name: "Name",
            save_changes: "Save Changes",
            split_invoice: "Split Invoice",
            possibility_to_choose_table: "Possibility To Choose The Table",
            devices: "Devices",
            attendance_history: "Attendance History",
            terms_and_conditions: "Terms & Conditions",
            settings: "Settings",
            general: "General",
            language: "Language",
            select_gender: "Select Gender",
            phone_number: "Phone Number",
            enter_phone_number: "Enter Phone Number",
            customer_name: "Customer Name",
            enter_customer_name: "Enter Customer Name",
            save_customer: "Save Customer",
            email: "Email",
            enter_email: "Enter Email",
            gender_required: "Gender is required!",
            phone_number_format:
              "Phone Number must start with + and be followed by numeric digits!",
            phone_number_length: "Phone Number should not exceed 20 digits!",
            phone_number_min_length:
              "Phone Number must be at least 3 digits long!",
            invalid_email: "Please use a valid email address!",
            email_required: "Email address is required!",
            full_name_required: "Full Name is required!",
          },
          footer: {
            copyright: "Copyright",
          },
        },
      },
      ar: {
        translation: {
          pos_dashboard: {
            select_gender: "اختر الجنس",
            phone_number: "رقم الهاتف",
            enter_phone_number: "أدخل رقم الهاتف",
            customer_name: "اسم العميل",
            enter_customer_name: "أدخل اسم العميل",
            save_customer: "حفظ العميل",
            add_new_customer: "إضافة عميل جديد",
            email: "البريد الإلكتروني",
            enter_email: "أدخل البريد الإلكتروني",
            add_customer: "إضافة عميل",
            gender_required: "الجنس مطلوب!",
            phone_number_format: "يجب أن يبدأ رقم الهاتف بـ + ويتبعه أرقام!",
            phone_number_length: "يجب ألا يتجاوز طول رقم الهاتف 20 رقمًا!",
            phone_number_min_length:
              "يجب أن يكون رقم الهاتف على الأقل 3 أرقام!",
            invalid_email: "الرجاء استخدام عنوان بريد إلكتروني صالح!",
            email_required: "عنوان البريد الإلكتروني مطلوب!",
            full_name_required: "الاسم الكامل مطلوب!",
            general: "عام",
            language: "اللغة",
            save_promotion: "حفظ الترقية!",
            choose_promotion: "اختيار الترقية",
            add_note: "إضافة ملاحظة",
            remove_note: "إزالة الملاحظة",
            item: "العنصر",
            name: "اسم",
            save_changes: "حفظ التغييرات",
            split_invoice: "تقسيم الفاتورة",
            possibility_to_choose_table: "إمكانية اختيار الطاولة",
            devices: "الأجهزة",
            attendance_history: "سجل الحضور",
            terms_and_conditions: "الشروط والأحكام",
            settings: "الإعدادات",
            service_type: "نوع الخدمة",
            discount_applied: "تم تطبيق الخصم",
            done: "تم",
            payment_method: "طريقة الدفع",
            payments: "المدفوعات",
            order_detail: "تفاصيل الطلب",
            customer: "العميل",
            created_by: "تم الإنشاء بواسطة",
            service: "الخدمة",
            time: "الوقت",
            date: "التاريخ",
            order_no: "رقم الطلب",
            payment_successfull: "تمت عملية الدفع بنجاح!",
            add_part: "إضافة قسم",
            paying: "الدفع",
            selected_customer: "اختر العميل",
            fee_percentage: "نسبة الرسوم",
            amount_added: "المبلغ المضاف",
            choose_method: "اختر الطريقة",
            choose_customer: "اختر العميل",
            choose_card: "اختر البطاقة",
            cash: "نقداً",
            card: "بطاقة",
            points: "نقاط",
            delivery_order: "طلب التوصيل",
            choose_payment_method: "اختر طريقة الدفع",
            split_equally: "تقسيم بالتساوي",
            split_by_item: "تقسيم حسب العنصر",
            full_pay: "الدفع الكامل",
            checkout: "اتمام الشراء",
            yes_choose: "نعم، اختر",
            Cancel: "إلغاء",
            question: "؟",
            are_you_sure_about_choosing_the_store:
              "تسجيل الدخول إلى حسابك في المتجر",
            branch: "تسجيل الدخول إلى فرعك",
            login_to_your_store: "تسجيل الدخول إلى متجرك",
            download_our_app: "قم بتنزيل تطبيقنا",
            pos_login: "تسجيل الدخول",
            use_code: "استخدم الرمز",
            copyright: "حقوق النشر © ٢٠٢٣ TagDev",
            pos_remember_me: "تذكرني",
            pos_email: "البريد الإلكتروني",
            pos_password: "كلمة المرور",
            confirm: "تأكيد",
            use_email_password: "استخدم البريد الإلكتروني وكلمة المرور",
            home_button: "الرئيسية",
            order_button: "طلب",
            table_button: "طاولة",
            drawer_button: "درج",
            categories: "الفئات",
            Search_item: "ابحث عن عنصر...",
            items: "عناصر",
            save_order: "حفظ الطلب",
            takeaway: "طلب خارجي",
            dine_in: "تناول في المطعم",
            no_item: "لم يتم إضافة عناصر بعد",
            empty_state_for_order:
              "لا توجد عناصر لعرضها في هذا الجدول حالياً. يمكنك إضافة عناصر جديدة لملء هذه القائمة",
            add_table: "إضافة طاولة",
            add_discount: "إضافة خصم",
            sub_total: "المجموع الفرعي",
            discount: "خصم",
            vat: "ضريبة القيمة المضافة",
            total: "المجموع",
            paynow: "ادفع الآن",
            choose_discount: "اختر الخصم",
            change: "تغيير",
            table_number: "رقم الطاولة",
            select_customer: "العميل المحدد",
            choose_table: "اختر الطاولة",
            available: "متاحة",
            reserved: "محجوزة",
            selected: "مُحددة",
            selected_tables: " :الطاولات المُحددة",
            none: "بلا شيء",
            enter_fast_access_code: "أدخل رمز الوصول السريع",
            saved: "تم الحفظ",
            orders: "الطلبات",
            numbers: "أرقام",
            add_new_table: "إضافة جدول جديد",
            reset_table: "إعادة تعيين الجدول ",
            add_table_text: "لم يتم إضافة أي جدول",
            add_table_label: "ابدأ بإضافة جدولك الأول بالنقر على الزر أدناه",
            no_table_added: "لم يتم إضافة أي جدول",
            how_many: "كم عددهم؟",
            cancel: "إلغاء",
            select_how_many_table: "اختر عدد الجداول",
            one: "١",
            two: "٢",
            three: "٣",
            four: "٤",
            five: "٥",
            six: "٦",
            seven: "٧",
            eight: "٨",
            nine: "٩",
            zero: "٠",
            dot: ".",
            backspace: "←",
            online: "عبر الإنترنت",
            enter_store_code: "أدخل رمز المتجر",
            login_account: "تسجيل الدخول إلى حسابك",
          },

          buttons: {
            add: "إضافة",
            save: "حفظ",
            export: "تصدير",
            signup_with_google: "التسجيل بواسطة جوجل",
            signup_with_facebook: "التسجيل بواسطة فيسبوك",
            or: "أو",
            continue: "متابعة",
            need_help: "هل تحتاج إلى مساعدة؟",
            save_changes: "حفظ التغييرات",
            cancel: "إلغاء",
            reset: "إعادة تعيين",
            delete: "حذف",
            dismiss: "رفض",
            signout: "تسجيل الخروج",
            send: "إرسال",
            show_details: "إظهار التفاصيل",
            edit: "تعديل",
            duplicate: "نسخ",
            print: "طباعة",
            return: "العودة",
            drag_or_click: "اسحب أو انقر للتحميل",
            done: "تم",
            add_value: "إضافة قيمة",
          },
          validations: {
            supported_format: "التنسيق المدعوم",
            unsupported_format:
              "تنسيق الملف غير مدعوم. يرجى تحديد ملف بتنسيق JPG، JPEG، أو PNG",
            file_size:
              "حجم الملف كبير جدًا. الحجم الأقصى المسموح به هو 10 ميغابايت.",
          },
          login: {
            title: "تسجيل الدخول",
            welcome: "مرحبًا مرة أخرى! قم بتسجيل الدخول للوصول إلى حسابك",
            login_with_google: "تسجيل الدخول بواسطة جوجل",
            login_with_facebook: "تسجيل الدخول بواسطة فيسبوك",
            or: "أو",
            email: "البريد الإلكتروني",
            email_placeholder: "أدخل البريد الإلكتروني",
            password: "كلمة المرور",
            password_placeholder: "أدخل كلمة المرور",
            remember_me: "تذكرني",
            forgot_password: "هل نسيت كلمة المرور؟",
            account_confirmation: "ليس لديك حساب؟",
            validations: {
              email_error: "البريد الإلكتروني مطلوب!",
              email_error_invalid: "الرجاء استخدام عنوان بريد إلكتروني صالح!",
              password_error: "كلمة المرور مطلوبة!",
              password_error_min:
                "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
              password_error_uppercase:
                "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
              password_error_special_char:
                "يجب أن تحتوي كلمة المرور على رمز خاص واحد على الأقل",
              password_error_alphabet:
                "يجب أن تحتوي كلمة المرور على حرف أبجدي واحد على الأقل",
            },
          },
          recover_account: {
            name: "استعادة الحساب",
            description:
              "أدخل عنوان البريد الإلكتروني المرتبط بحسابك وسنرسل لك رابطًا لإعادة تعيين كلمة المرور الخاصة بك.",
            email: "البريد الإلكتروني",
            enter_email: "ادخل البريد الإلكتروني",
          },
          signup: {
            title: "التسجيل",
            welcome: "ابدأ باستخدام TagDev",
            or: "أو",
            email: "البريد الإلكتروني",
            email_placeholder: "أدخل البريد الإلكتروني",
            already_have_account: "هل لديك حساب بالفعل؟",
            agreement: "بإنشاء حساب، أنت توافق على",
            terms_and_conditions: "الشروط والأحكام",
            privacy_notice: "إشعار الخصوصية",
            and: "و",
          },
          confirm_email: {
            title: "تأكيد بريدك الإلكتروني",
            instructions:
              "لقد قمنا بإرسال رمز عبر البريد الإلكتروني إلى {{email}}، الرجاء إدخاله أدناه لإنشاء حسابك.",
            didnt_receive_code: "لم تتلقى الرمز؟",
            resend_code: "إعادة إرسال الرمز",
            image_heading: "قم بتعظيم زخم مبيعاتك",
            image_description:
              "قم برفع مستوى عملك وقم بتعظيم إمكانات إيراداتك. ابدأ اليوم!",
          },
          create_password: {
            title: "إنشاء كلمة المرور الخاصة بك",
          },
          create_password_form: {
            email: "البريد الإلكتروني",
            enter_email: "ادخل البريد الإلكتروني",
            new_password: "كلمة المرور الجديدة",
            confirm_password: "تأكيد كلمة المرور",
            enter_new_password: "أدخل كلمة المرور الجديدة",
            enter_password_again: "أدخل كلمة المرور مرة أخرى",
            enter_current_password: "أدخل كلمة السر الحالية",
            use_at_least_8_characters: "استخدم على الأقل 8 أحرف",
            use_mix_of_letters_numbers_special_chars:
              "استخدم مزيجًا من الأحرف والأرقام والرموز الخاصة (مثل: #$%)",
            create_password: "إنشاء كلمة المرور",
            current_password: "كلمة السر الحالية",
            save_changes: "حفظ التغييرات",
          },
          profile_layout: {
            start_create_account: "ابدأ في إنشاء حسابك",
            your_email: "بريدك الإلكتروني",
          },
          stepper: {
            create_password: "إنشاء كلمة المرور",
            complete_profile: "إكمال الملف الشخصي",
            complete_business_profile: "إكمال ملف العمل",
            setup_your_plan: "إعداد خطتك",
          },
          create_account: {
            complete_profile: "اكمل الملف الشخصي",
            heading: "اكمل الملف الشخصي",
            upload_picture: "تحميل الصورة",
            change_picture: "تغيير الصورة",
            fullname: "الاسم الكامل",
            enter_name: "أدخل الاسم الكامل",
            arabicname: "الاسم بالعربية",
            enter_arabic_name: "أدخل الاسم بالعربية",
            phonenumber: "رقم الهاتف",
            enter_phone_number: "أدخل رقم الهاتف",
            continue: "استمر",
            image_upload_error: "خطأ في تحميل الصورة",
            image_upload_info:
              "*ملفات png, .jpeg تصل إلى 10 ميجابايت، على الأقل 400px في 400px",
          },
          business_profile: {
            complete_business_profile: "ملف تعريف العمل الكامل",
            heading: "ملف تعريف العمل الكامل",
            company_info: "معلومات الشركة",
            company_name: "اسم الشركة",
            enter_company_name: "ادخل اسم الشركة",
            company_address: "عنوان الشركة",
            enter_company_address: "ادخل عنوان الشركة",
            website: "الموقع الإلكتروني",
            commercial_register: "السجل التجاري",
            enter_commercial_register: "ادخل السجل التجاري",
            vat_number: "رقم الضريبة على القيمة المضافة (VAT)",
            enter_vat_number: "ادخل رقم الضريبة على القيمة المضافة (VAT)",
            business_type: "نوع عملك",
            select_business_type: "اختر نوع العمل",
            continue: "استمرار",
            coffee_shop: "مقهى",
            restaurant: "مطعم",
            shop: "متجر",
            super_market: "سوبر ماركت",
            food_truck: "شاحنة طعام",
            gift_shop: "متجر هدايا",
            hair_dressers: "صالونات تصفيف الشعر",
            car_services: "خدمات السيارات",
            express_services: "خدمات سريعة",
            delete_and_start: "حذف البيانات والبدء من جديد",
            invoice_template: "قالب الفاتورة",
            edit_template: "تحرير قالب الفاتورة",
          },
          client_dashboard: {
            overview: "نظرة عامة",
            messages: "الرسائل",
            invoices: {
              name: "الفواتير",
              sales: {
                name: "المبيعات",
                create_invoice: "إنشاء فاتورة",
                customer: "العميل",
                add_new_customer: "إضافة عميل جديد",
                add_customer: "إضافة عميل",
                select_customer: "اختر العميل",
                store: "المتجر",
                select_store: "اختر المتجر",
                sale_date: "تاريخ البيع",
                payment_method: "طريقة الدفع",
                select_payment_method: "اختر طريقة الدفع",
                item_details: "تفاصيل العنصر",
                item: "العنصر",
                select_item: "اختر العنصر",
                available_quantity: "الكمية المتاحة",
                quantity: "الكمية",
                enter_quantity: "أدخل الكمية",
                unit_price: "سعر الوحدة",
                before_vat: "قبل الضريبة",
                vat_amount: "مبلغ الضريبة",
                total: "الإجمالي",
                note: "ملاحظة",
                enter_note: "أدخل الملاحظة",
                tabs: {
                  all: "الكل",
                  paid: "المدفوعة",
                  pos_transaction: "عملية نقطة البيع",
                },
                validations: {
                  customer_error: "العميل مطلوب!",
                  store_error: "المتجر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  paymentmethod_error: "طريقة الدفع مطلوبة!",
                  item_error: "العنصر مطلوب!",
                  quantity_error: "الكمية مطلوبة!",
                  unitprice_error: "سعر الوحدة مطلوب!",
                  beforetax_error: "الضريبة مطلوبة قبل الضريبة!",
                  taxamount_error: "مبلغ الضريبة مطلوب!",
                  subtotal_error: "المجموع الفرعي مطلوب!",
                  note_error: "الملاحظة مطلوبة!",
                },
              },

              purchase: {
                name: "الشراء",
                create_invoice: "إنشاء فاتورة",
                supplier: "المورّد",
                add_new_supplier: "إضافة مورّد جديد",
                add_supplier: "إضافة مورد",
                choose_supplier: "اختر المورّد",
                store: "المتجر",
                select_store: "اختر المتجر",
                supply_date: "تاريخ التوريد",
                payment_method: "طريقة الدفع",
                select_payment_method: "اختر طريقة الدفع",
                payment_status: "حالة الدفع",
                select_payment_status: "اختر حالة الدفع",
                item_details: "تفاصيل العنصر",
                item: "العنصر",
                select_item: "اختر العنصر",
                available_quantity: "الكمية المتوفرة",
                quantity: "الكمية",
                enter_quantity: "أدخل الكمية",
                unit_price: "سعر الوحدة",
                before_tax: "قبل الضريبة",
                tax_amount: "مبلغ الضريبة",
                subtotal: "المجموع الفرعي",
                note: "ملاحظة",
                enter_note: "أدخل الملاحظة",
                tabs: {
                  all: "الكل",
                  fully_paid: "مدفوع بالكامل",
                  partially_paid: "مدفوع جزئياً",
                },
                validations: {
                  supplierid_error: "المورّد مطلوب!",
                  store_error: "المتجر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  paymentmethod_error: "طريقة الدفع مطلوبة!",
                  paymentStatus_error: "حالة الدفع مطلوبة!",
                  item_error: "العنصر مطلوب!",
                  quantity_error: "الكمية مطلوبة!",
                  quantity_error_max:
                    "الحد الأقصى للكمية المسموح به هو 1،000،000!",
                  unitprice_error: "سعر الوحدة مطلوب!",
                  beforetax_error: "قبل الضريبة مطلوب!",
                  taxamount_error: "مبلغ الضريبة مطلوب!",
                  subtotal_error: "المجموع الفرعي مطلوب!",
                  note_error: "الملاحظة مطلوبة!",
                  only_numbers_error: "يُسمح فقط بالأرقام!",
                },
              },
              expense: {
                name: "المصروفات",
                create_invoice: "إنشاء فاتورة",
                expense_name: "اسم المصروف",
                enter_expense_name: "ادخل اسم المصروف",
                invoice_no: "رقم الفاتورة",
                enter_invoice_no: "ادخل رقم الفاتورة",
                store: "المتجر",
                select_store: "اختر المتجر",
                supply_date: "تاريخ التوريد",
                payment_method: "طريقة الدفع",
                select_payment_method: "اختر طريقة الدفع",
                expense_details: "تفاصيل المصروف",
                expense_category: "تصنيف المصروف",
                select_expense_category: "اختر تصنيف المصروف",
                amount: "المبلغ",
                enter_amount: "ادخل المبلغ",
                before_tax: "قبل الضريبة",
                enter_before_tax_price: "ادخل سعر قبل الضريبة",
                tax_amount: "مبلغ الضريبة",
                enter_tax_amount: "ادخل مبلغ الضريبة",
                total: "المجموع",
                enter_total_amount: "ادخل المجموع",
                note: "ملاحظة",
                enter_note: "ادخل ملاحظة",
                validations: {
                  expensename_error: "اسم المصروف مطلوب!",
                  expensename_error_min:
                    "يجب أن يكون اسم المصروف على الأقل 3 أحرف",
                  expensename_error_max: "يجب ألا يزيد اسم المصروف عن 100 حرف",
                  invoicenumber_error: "رقم الفاتورة مطلوب!",
                  invoicenumber_error_invalid: "يُسمح فقط بالأرقام!",
                  invoicenumber_error_min:
                    "يجب أن يكون رقم الفاتورة على الأقل 3 أرقام!",
                  max_number_error: "الحد الأقصى المسموح به هو 1،000،000!",
                  store_error: "المتجر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  paymentmethod_error: "طريقة الدفع مطلوبة!",
                  expensecategory_error: "تصنيف المصروف مطلوب!",
                  amount_error: "المبلغ مطلوب!",
                  beforetax_error: "المبلغ قبل الضريبة مطلوب!",
                  taxamount_error: "مبلغ الضريبة مطلوب!",
                  total_error: "المجموع مطلوب!",
                  note_error: "الملاحظة مطلوبة!",
                },
              },
            },
            inventory: {
              name: "المخزون",
              inventory_transfer: {
                name: "نقل المخزون",
                add_inventory: "إضافة نقل",
                add_more: "إضافة المزيد من العناصر",
                storefrom: "المتجر (من)",
                select_storefrom: "اختر المتجر (من)",
                storeto: "المتجر (إلى)",
                select_storeto: "اختر المتجر (إلى)",
                date: "التاريخ",
                item: "العنصر",
                select_item: "اختر العنصر",
                unitmeasure: "وحدة القياس",
                quantity: "الإجمالية",
                enter_quantity: "أدخل الكمية",
                total_quantity: "الكمية الإجمالية",
                number_unit: "عدد وحدات القياس",
                enter_number_unit: "ادخل عدد وحدات القياس",
                description: "الوصف",
                enter_description: "ادخل الوصف",

                validations: {
                  storeto_error: "المتجر المقصود (الوجهة) مطلوب!",
                  storefrom_error: "المتجر المصدر مطلوب!",
                  date_error: "التاريخ مطلوب!",
                  itemid_error: "العنصر مطلوب!",
                  unitmeasure_error: "وحدة القياس مطلوبة!",
                  numberunit_error: "عدد الوحدات مطلوب!",
                  quantity_error: "الكمية مطلوبة!",
                  description_error: "الوصف مطلوب!",
                },
              },
              stocktaking: {
                name: "الجرد",
                create_stocktaking: "إنشاء جرد",
                count_name: "اسم العد",
                enter_count_name: "ادخل اسم العد",
                description: "الوصف",
                enter_description: "ادخل الوصف",
                date: "التاريخ",
                store: "المتجر",
                select_store: "اختر المتجر",
                item_details: "تفاصيل العنصر",
                detail: "تفاصيل",
                item: "العنصر",
                select_item: "اختر العنصر",
                unitmeasure: "وحدة القياس",
                expected: "المتوقع",
                quantity: "الكمية",
                enter_quantity: "ادخل الكمية",
                cost_price: "سعر التكلفة",
                note: "ملاحظة",
                enter_note: "ادخل الملاحظة",
                validations: {
                  countName_error: "اسم العد مطلوب",
                  countName_error_max: "الحد الأقصى لعدد الأحرف هو 100",
                  description_error: "الوصف مطلوب!",
                  description_error_max: "الحد الأقصى لعدد الأحرف هو 200",
                  date_error: "التاريخ مطلوب!",
                  store_error: "المتجر مطلوب!",
                  item_error: "العنصر مطلوب!",
                  expected_error: "يجب أن تكون القيمة المتوقعة رقمًا",
                  quantity_error: "الكمية مطلوبة!",
                  quantity_error_max: "الحد الأقصى للكمية هو 1،000،000",
                  costPrice_error: "يجب أن يكون سعر التكلفة رقمًا",
                  note_error: "",
                },
              },

              inventory_transaction: {
                name: "عملية المخزون",
                inventory_transfer: "نقل المخزون",
                stocktaking: "جرد المخزون",
                sale_invoices: "فواتير البيع",
                purchase_invoices: "فواتير الشراء",
              },
            },
            directory: {
              name: "الدليل",
              customer: {
                add_customer: "إضافة عميل",
                name: "العميل",
                customer_name: "اسم العميل",
                enter_name: "ادخل اسم العميل",
                email: "البريد الإلكتروني",
                enter_email: "ادخل البريد الإلكتروني",
                phone_number: "رقم الهاتف",
                enter_phone: "ادخل رقم الهاتف",
                select_gender: "اختر الجنس",
                male: "ذكر",
                female: "أنثى",
                delete: "تم حذف العميل بنجاح!",
                sure: "هل أنت متأكد أنك تريد حذف هذا العميل؟",
                validations: {
                  name_error: "يجب أن يكون الاسم الكامل على الأقل 3 أحرف!",
                  name_error_max: "يسمح بحد أقصى 100 حرف!",
                  email_error: "البريد الإلكتروني مطلوب!",
                  email_error_valid: "يرجى استخدام عنوان بريد إلكتروني صالح!",
                  phone_error: "يجب أن يبدأ رقم الهاتف ب '+'!",
                  gender_error: "الجنس مطلوب!",
                },
              },
              supplier: {
                name: "الموردين",
                add_supplier: "إضافة مورد",
                english_name: "الاسم بالإنجليزية",
                company_name: "اسم الشركة",
                arabic_name: "الاسم بالعربية",
                enter_english_name: "ادخل الاسم بالإنجليزية",
                enter_company_name: "أدخل اسم الشركة",
                email: "البريد الإلكتروني",
                enter_email: "ادخل البريد الإلكتروني",
                phone_number: "رقم الهاتف",
                enter_address: "العنوان",
                enter_addreess: "العنوان",
                enter_phone: "ادخل رقم الهاتف",
                vat_number: "رقم الضريبة المضافة",
                enter_vatnumber: "ادخل رقم الضريبة المضافة",
                delete: "تم حذف العميل بنجاح!",
                sure: "هل أنت متأكد أنك تريد حذف هذا العميل؟",
                validations: {
                  englishname: "يجب أن يكون الاسم الكامل على الأقل 3 أحرف!",
                  name_error_max: "يسمح بحد أقصى 100 حرف!",
                  email_error: "البريد الإلكتروني مطلوب!",
                  email_error_valid: "يرجى استخدام عنوان بريد إلكتروني صالح!",
                  phone_error: "يجب أن يبدأ رقم الهاتف ب '+'!",
                  phone_error_max: "يجب ألا يتجاوز رقم الهاتف 20 رقمًا!",
                  phone_error_regex:
                    "يجب أن يبدأ رقم الهاتف بـ + ويتبعه أرقامًا!",
                  error_address: "العنوان مطلوب",
                  error_address_max: "يجب ألا يتجاوز العنوان 100 حرف!",
                  company_error: "اسم الشركة مطلوب!",
                  company_error_max: "يجب ألا يتجاوز اسم الشركة 100 حرف!",
                  vat_error:
                    "يجب أن يكون رقم ضريبة القيمة المضافة مكونًا من 15 حرفًا بالضبط.",
                },
              },
              items: {
                name: "العناصر",
                simple_item: {
                  name: "العنصر البسيط",
                  english_name: "الاسم بالإنجليزية",
                  enter_english_name: "أدخل الاسم بالإنجليزية",
                  arabic_name: "الاسم بالعربية",
                  enter_arabic_name: "أدخل الاسم بالعربية",
                  description: "الوصف",
                  enter_description: "أدخل الوصف",
                  select_store: "اختر المتجر",
                  item_cost: "تكلفة العنصر",
                  enter_item_cost: "أدخل تكلفة العنصر",
                  select_category: "اختر الفئة",
                  sale_price: "سعر البيع",
                  enter_sale_price: "أدخل سعر البيع",
                  enter_barcode: "أدخل الرمز الشريطي",
                  create_barcode_num: "إنشاء رقم الرمز الشريطي",
                  validations: {
                    englishname_error: "الاسم بالإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون الاسم بالإنجليزية على الأقل 3 أحرف!",
                    englishname_error_max:
                      "يجب ألا يزيد الاسم بالإنجليزية عن 100 حرف!",
                    arabicname_error: "الاسم بالعربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون الاسم بالعربية على الأقل 3 أحرف!",
                    arabicname_error_max:
                      "يجب ألا يزيد الاسم بالعربية عن 100 حرف!",
                    description_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف على الأقل 3 أحرف!",
                    description_error_max: "يجب ألا يزيد الوصف عن 500 حرف!",
                    store_error: "المتجر مطلوب!",
                    itemcost_error: "تكلفة العنصر مطلوبة!",
                    itemcost_error_min: "يجب أن تكون تكلفة العنصر على الأقل 1!",
                    itemcost_error_max: "العدد الأقصى المسموح به هو 1,000,000!",
                    category_error: "الفئة مطلوبة!",
                    saleprice_error: "سعر البيع مطلوب!",
                    saleprice_error_min: "يجب أن يكون سعر البيع على الأقل 1!",
                    saleprice_error_max:
                      "العدد الأقصى المسموح به هو 1,000,000!",
                  },
                },
                variable_item: {
                  name: "عنصر متغير",
                  item_name: "اسم العنصر",
                  enter_item_name: "أدخل اسم العنصر",
                  arabic_name: "الاسم بالعربي",
                  enter_arabic_name: "أدخل الاسم بالعربي",
                  description: "الوصف",
                  enter_description: "أدخل الوصف",
                  store: "المتجر",
                  select_store: "اختر المتجر",
                  category: "الفئة",
                  select_category: "اختر الفئة",
                  variables: "متغيرات",
                  variable: "متغير",
                  variable_name: "اسم المتغير",
                  select_variable_name: "اختر اسم المتغير",
                  value: "القيمة",
                  enter_value: "أدخل القيمة",
                  add_another_variable: "أضف متغيرًا آخر",
                  validations: {
                    englishname_required_error: "الاسم بالإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون الاسم بالإنجليزية ثلاثة أحرف على الأقل!",
                    englishname_error_max:
                      "يجب ألا يزيد الاسم بالإنجليزية عن 100 حرف!",
                    arabicname_required_error: "الاسم بالعربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون الاسم بالعربية ثلاثة أحرف على الأقل!",
                    arabicname_error_max:
                      "يجب ألا يزيد الاسم بالعربية عن 100 حرف!",
                    description_required_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف ثلاثة أحرف على الأقل!",
                    description_error_max: "يجب ألا يزيد الوصف عن 500 حرف!",
                    store_required_error: "المتجر مطلوب!",
                    category_required_error: "الفئة مطلوبة!",
                    variablename_required_error: "اسم المتغير مطلوب!",
                    value_required_error: "القيمة مطلوبة!",
                  },
                },
                compositing_item: {
                  name: "عنصر التركيب",
                  item_name: "اسم العنصر",
                  arabic_item_name: "اسم العنصر بالعربي",
                  description: "الوصف",
                  select_store: "اختر المتجر",
                  select_category: "اختر الفئة",
                  sale_price_without_tax: "سعر البيع (بدون ضريبة)",
                  raw_items: "العناصر الخام",
                  select_raw_items: "اختر العناصر الخام",
                  unit_of_measure: "وحدة القياس",
                  per_unit_price: "سعر الوحدة",
                  number_unit_of_measure: "عدد وحدات القياس",
                  item_cost: "تكلفة العنصر",
                  total_cost: "التكلفة الكلية",
                  composition_label: "التكوين",
                  add_another_composition: "أضف تكوين آخر",
                  create_sku_number: "إنشاء رقم SKU",
                  enter_item_name: "أدخل اسم العنصر",
                  enter_arabic_item_name: "أدخل اسم العنصر بالعربي",
                  enter_description: "أدخل الوصف",
                  enter_sale_price: "أدخل سعر البيع",
                  enter_number_unit_of_measure: "أدخل عدد وحدات القياس",

                  validations: {
                    englishname_error: "اسم اللغة الإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون اسم اللغة الإنجليزية على الأقل 3 أحرف!",
                    englishname_error_max:
                      "يجب ألا يتجاوز اسم اللغة الإنجليزية 100 حرف!",
                    arabicname_error: "اسم اللغة العربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون اسم اللغة العربية على الأقل 3 أحرف!",
                    arabicname_error_max:
                      "يجب ألا يتجاوز اسم اللغة العربية 100 حرف!",
                    description_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف على الأقل 3 أحرف!",
                    description_error_max: "يجب ألا يتجاوز الوصف 500 حرف!",
                    store_error: "المتجر مطلوب!",
                    category_error: "الفئة مطلوبة!",
                    saleprice_error: "سعر البيع مطلوب!",
                    saleprice_error_min: "يجب أن يكون سعر البيع على الأقل 1!",
                    saleprice_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    totalcost_error: "التكلفة الإجمالية مطلوبة!",
                    totalcost_error_min:
                      "يجب أن تكون التكلفة الإجمالية على الأقل 1!",
                    totalcost_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    itemcost_error: "تكلفة العنصر مطلوبة!",
                    itemcost_error_min: "يجب أن تكون تكلفة العنصر على الأقل 1!",
                    itemcost_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    rawitem_error: "العنصر الخام مطلوب!",
                    unitmeasure_error: "وحدة القياس اختيارية",
                    perunitprice_error: "سعر الوحدة مطلوب!",
                    perunitprice_error_min:
                      "يجب أن يكون سعر الوحدة على الأقل 1!",
                    perunitprice_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    numberunit_error: "عدد الوحدات مطلوب!",
                    numberunit_error_min:
                      "يجب أن يكون عدد الوحدات على الأقل 1!",
                    numberunit_error_max:
                      "الحد الأقصى للعدد المسموح به هو 1،000،000!",
                    number_only_error: "الأرقام فقط مسموح بها!",
                  },
                },
                raw_item: {
                  name: "العنصر الأساسي",
                  item_name: "اسم العنصر",
                  enter_item_name: "أدخل اسم العنصر",
                  arabic_item_name: "اسم العنصر بالعربي",
                  enter_arabic_item_name: "أدخل اسم العنصر بالعربي",
                  description: "الوصف",
                  enter_description: "أدخل الوصف",
                  select_store: "اختر المتجر",
                  item_cost: "تكلفة العنصر",
                  enter_item_cost: "أدخل تكلفة العنصر",
                  sale_price: "سعر البيع",
                  enter_sale_price: "أدخل سعر البيع",
                  select_category: "اختر الفئة",
                  unit_of_measure: "وحدة القياس",
                  number_unit_of_measure: "عدد وحدات القياس",
                  enter_number_unit_of_measure: "أدخل عدد وحدات القياس",
                  create_sku_number: "إنشاء رقم SKU",
                  validations: {
                    englishname_required_error: "الاسم بالإنجليزية مطلوب!",
                    englishname_error_min:
                      "يجب أن يكون الاسم بالإنجليزية ثلاثة أحرف على الأقل!",
                    englishname_error_max:
                      "يجب ألا يزيد الاسم بالإنجليزية عن 100 حرف!",
                    arabicname_required_error: "الاسم بالعربية مطلوب!",
                    arabicname_error_min:
                      "يجب أن يكون الاسم بالعربية ثلاثة أحرف على الأقل!",
                    arabicname_error_max:
                      "يجب ألا يزيد الاسم بالعربية عن 100 حرف!",
                    description_required_error: "الوصف مطلوب!",
                    description_error_min:
                      "يجب أن يكون الوصف ثلاثة أحرف على الأقل!",
                    description_error_max: "يجب ألا يزيد الوصف عن 500 حرف!",
                    store_error_min: "المتجر مطلوب!",
                    itemcost_number_only_error: "يسمح بالأرقام فقط!",
                    itemcost_error_min: "تكلفة العنصر مطلوبة!",
                    itemcost_error_max: "الحد الأقصى المسموح به هو 1,000,000!",
                    saleprice_number_only_error: "يسمح بالأرقام فقط!",
                    saleprice_error_min: "سعر البيع مطلوب!",
                    saleprice_error_max: "الحد الأقصى المسموح به هو 1,000,000!",
                    category_error_min: "الفئة مطلوبة!",
                    unitmeasure_error_min: "وحدة القياس مطلوبة!",
                    numberunit_number_only_error: "يسمح بالأرقام فقط!",
                    numberunit_error_min: "عدد الوحدات مطلوب!",
                    numberunit_error_max:
                      "الحد الأقصى المسموح به هو 1,000,000!",
                  },
                },
              },

              category: {
                name: "الفئة",
                add_category: "إضافة فئة",
                english_name: "الاسم بالإنجليزية",
                arabic_name: "الاسم بالعربية",
                enter_english_name: "ادخل الاسم بالإنجليزية",
                enter_arabic_name: "ادخل الاسم بالعربية",
                view_this_category: "عرض هذه الفئة في نقاط البيع",
                validations: {
                  englishname_error:
                    "يجب أن يكون الاسم بالإنجليزية على الأقل 3 أحرف!",
                  englishname_error_max: "يسمح بحد أقصى 100 حرف!",
                },
              },
            },
            report: {
              name: "تقرير",
              sales: "المبيعات",
              purchase: "الشراء",
              taxes: "الضرائب",
              profit_loss: "الربح والخسارة",
              expenses: "المصروفات",
              inventory: "المخزون",
            },
            management: {
              name: "الإدارة",
              setting: "الإعدادات",
              users: {
                name: "المستخدمين",
                add_user: "إضافة مستخدم",
                user_name: "الاسم",
                enter_name: "أدخل الاسم",
                email: "البريد الإلكتروني",
                enter_email: "أدخل البريد الإلكتروني",
                create_password: "إنشاء كلمة المرور",
                enter_password: "أدخل كلمة المرور",
                code: "رمز الوصول السريع لنقاط البيع",
                enter_code: "أدخل الرمز",
                access_to_dashboard: "الوصول إلى لوحة التحكم",
                access_to_pos: "الوصول إلى نقاط البيع",
                store: "المتجر",
                select_store: "اختر المتجر",
                select_role: "اختر الدور",
                roles: {
                  cashier: "أمين صندوق",
                  sales_manager: "مدير مبيعات",
                  system_manager: "مدير النظام",
                },
                validations: {
                  name_error: "!يجب أن يكون الاسم على الأقل 3 أحرف",
                  name_error_max: "!يسمح بحد أقصى 100 حرف",
                  email_error: "!البريد الإلكتروني مطلوب",
                  email_error_valid: "!يرجى استخدام عنوان بريد إلكتروني صالح",
                  password_error: "!يجب أن تكون كلمة المرور على الأقل 8 أحرف!",
                  password_error_uppercase:
                    "!يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
                  password_error_special:
                    "!يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
                  password_error_numeric:
                    "!يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
                  role_error: "!الدور مطلوب",
                  store_error: "يجب عليك اختيار متجر واحد على الأقل!",
                  code_error: "!يجب أن يكون الرمز رقمًا مكونًا من 4 أرقام",
                  only_numbers_error: "!يُسمح فقط بالأرقام",
                },
              },
              stores: {
                name: "المتاجر",
                add_store: "إضافة متجر",
                branch_name: "اسم الفرع",
                enter_name: "أدخل الاسم",
                store_name: "اسم متجر",
                store_address: "عنوان المتجر",
                street_name: "اسم الشارع",
                enter_street_name: "أدخل اسم الشارع",
                building_no: "رقم المبنى",
                enter_building_no: "أدخل رقم المبنى",
                district_name: "اسم الحي",
                enter_district_name: "أدخل اسم الحي",
                city: "المدينة",
                enter_city: "أدخل المدينة",
                zip_code: "الرمز البريدي",
                enter_zip_code: "أدخل الرمز البريدي",
                validations: {
                  max_100: "يسمح بحد أقصى 100 حرف!",
                  branchname_error: "يجب أن يكون اسم الفرع على الأقل 3 أحرف!",
                  streetname_error: "يجب أن يكون اسم الشارع على الأقل 3 أحرف!",
                  districtname_error: "يجب أن يكون اسم الحي على الأقل 3 أحرف!",
                  cityname_error: "يجب أن يكون اسم المدينة على الأقل 3 أحرف!",
                  zipcode_error_min: "الرمز البريدي مطلوب",
                  zipcode_error_max: "الحد الأقصى للأرقام 12",
                  buildingno_error: "رقم المبنى مطلوب!",
                  buildingno_error_max: "يسمح بحد أقصى 4 أرقام!",
                  only_numbers_error: "يُسمح فقط بالأرقام!",
                },
              },

              payments: "المدفوعات",
              promotions: {
                name: "العروض الترويجية",
                add_promotions: "إضافة عرض ترويجي",
                enter_english_name: "أدخل الاسم بالإنجليزية",
                english_name: "الاسم بالإنجليزية",
                start_date: "تاريخ البدء",
                end_date: "تاريخ الانتهاء",
                promotion_type: "نوع الترويج",
                promotion_store: "حدد المتجر",
                promotion_value: "قيمة الترويج",
                select_type: "اختر النوع",
                promotion_select_item: "اختر عنصر",
                promotion_all_product: "جميع المنتجات",
                promotion_product: "المنتج",
                validations: {
                  name_error_min: "يجب أن يكون اسم العرض أكثر من 3 أحرف!",
                  name_error_max: "يجب ألا يتجاوز اسم العرض 100 حرف!",
                  start_date_error: "تاريخ البدء مطلوب!",
                  end_date_error: "تاريخ الانتهاء مطلوب!",
                  type_error: "نوع الترويج مطلوب!",
                  value_error: "القيمة مطلوبة!",
                  store_error: "يجب اختيار متجر واحد على الأقل",
                  select_type: "",
                  choose_error: "اختر منتجًا واحدًا أو جميع المنتجات",
                  select_product_error:
                    "يجب عليك تحديد منتج واحد أو تحديد 'جميع المنتجات'",
                  enter_promotion_value: "أدخل قيمة العرض الترويجي",
                },
              },
              settings: {
                profile: {
                  name: "الملف الشخصي",
                  profile_pic: "صورة الملف الشخصي",
                  english_name: "الاسم بالإنجليزية",
                  enter_english_name: "ادخل الاسم بالإنجليزية",
                  arabic_name: "الاسم بالعربية",
                  enter_arabic_name: "ادخل الاسم بالعربية",
                  email: "البريد الإلكتروني",
                  enter_email: "ادخل البريد الإلكتروني",
                  phone: "رقم الهاتف",
                  enter_phone: "ادخل رقم الهاتف",
                  change_picture: "تغيير الصورة",
                  supported_format: "التنسيق المدعوم",
                  validations: {
                    englishname_error:
                      "يجب أن يكون الاسم بالإنجليزية على الأقل 3 أحرف",
                    englishname_error_max: "يسمح بحد أقصى 100 حرف",
                    arabicname_error:
                      "يجب أن يكون الاسم بالعربية على الأقل 3 أحرف",
                    arabicname_error_max: "يسمح بحد أقصى 100 حرف",
                    email_error: "البريد الإلكتروني مطلوب",
                    email_error_valid: "يرجى استخدام عنوان بريد إلكتروني صالح",
                    phone_error: "يجب أن يكون رقم الهاتف على الأقل 3 أرقام",
                    phone_error_max: "يجب ألا يتجاوز رقم الهاتف 20 رقمًا",
                    phone_error_format:
                      "يجب أن يبدأ رقم الهاتف ب '+' ويتبعه أرقام صحيحة",
                    img_error: "الصورة مطلوبة",
                    img_error_dimensions:
                      "أبعاد الصورة يجب أن تكون على الأقل 400 بكسل في 400 بكسل",
                    img_err_size: "يجب ألا يتجاوز حجم الملف 10 ميغابايت",
                    img_err_type:
                      "نوع الملف غير صالح. يُسمح فقط بالملفات من نوع PNG و JPEG",
                    img_err_cumpolsory: "يجب تحميل صورة",
                  },
                },
                business_info: {
                  name: "معلومات الشركة",
                  validations: {
                    companyname_error:
                      "اسم الشركة يجب أن يكون على الأقل 3 أحرف!",
                    companyname_error_max: "يسمح بحد أقصى 100 حرف!",
                    companyaddress_error:
                      "عنوان الشركة يجب أن يكون على الأقل 3 أحرف!",
                    companyaddress_error_max: "يسمح بحد أقصى 100 حرف!",
                    website_error: "موقع الويب غير صالح!",
                    commercialregister_error:
                      "سجل الشركات يجب أن يكون على الأقل 3 أحرف!",
                    commercialregister_error_max: "يسمح بحد أقصى 100 حرف!",
                    vatnumber_error:
                      "يجب أن يكون رقم الضريبة على القيمة المضافة بطول 15 حرفًا بالضبط.",
                    businesstype_error: "نوع العمل مطلوب!",
                  },
                },

                subscription: "الاشتراك",
                password: {
                  name: "كلمة المرور",
                  validations: {
                    currentpassword_error: "!كلمة المرور الحالية مطلوبة",
                    newpassword_error:
                      "!يجب أن تتكون كلمة المرور من 8 أحرف على الأقل",
                    newpassword_error_uppercase:
                      "!يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
                    newpassword_error_special:
                      "!يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
                    newpassword_error_numeric:
                      "!يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
                    confirmpassword_error: "!تأكيد كلمة المرور مطلوب",
                    passwords_match_error: "!كلمات المرور غير متطابقة",
                  },
                },
              },
            },
            trial_info: {
              days_left: "تنتهي فترة التجربة خلال 7 أيام 🚀",
              plan_info: "الخطة الأساسية مجانية لفترة تجربية.",
              upgrade_button: "ترقية الخطة",
            },
          },

          footer: {
            copyright: "حقوق النشر",
          },
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
