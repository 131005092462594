import React, {
  Suspense,
  lazy,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import {
  Loader,
  Breadcrumb,
  CustomButton,
  CustomTable,
  DetailCard,
} from "../../../../common/components";
import Layout from "../../Layout/Layout";
import usePurchaseInvoicesList from "./usePurchaseInvoiceList";
import AddInvoice from "./AddInvoice/AddInvoice";
import {
  CopyIcon,
  EditIcon,
  EyeIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import useTabs from "../../../../../hooks/useTabs";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

// import dynamic components with react lazy components
const CustomModal = lazy(() =>
  import("../../../../common/components/CustomModel/CustomModel")
);

/**
 * Purchase Invoices List component
 * Renders table of purchase invoices list
 *
 * @returns {JSX.Element} JSX code for rendering the purchase invoices list table
 */
export default function PurchaseInvoicesList() {
  const { t } = useTranslation();
  const addEditPurchaseInvoiceRef = useRef();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const tabs = [
    {
      title: "All",
      content: () => (
        <DefaultTable purchaseInvoicesList={purchaseInvoicesList} />
      ),
    },
    {
      title: "Fully Paid",
      content: () => (
        <DefaultTable purchaseInvoicesList={purchaseInvoicesList} />
      ),
    },
    {
      title: "Partially Paid",
      content: () => (
        <DefaultTable purchaseInvoicesList={purchaseInvoicesList} />
      ),
    },
  ];

  const { TabContent, TabHeader, activeTab } = useTabs(0, tabs);

  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    purchaseInvoicesList,
    purchaseInvoicesListLoading,
    singleInvoiceDetail,
    itemList,
    singlePurchaseInvoiceLoading,
    singleInvoice,
    itemDetail,
    itemImage,
    storesList,
    suppliersList,
    currentPage,
    setCurrentPage,
    deleteInvoice,
    itemListLoading,
  } = usePurchaseInvoicesList(selectedItem?.id, activeTab, setIsFormSubmitting);

  const invoiceRef = useRef();
  invoiceRef.current = singleInvoice;

  const DefaultTable = useCallback(
    ({ purchaseInvoicesList }) => (
      <CustomTable
        key="CustomTable"
        tableHeader={tableHeader}
        tableBody={purchaseInvoicesList}
        setSelectedItem={setSelectedItem}
        loading={purchaseInvoicesListLoading}
        dropdownOptions={[
          {
            text: t("buttons.show_details"),
            icon: <EyeIcon />,
            onClick: () => handleViewInvoice("detail"),
          },
          {
            text: t("buttons.print"),
            icon: <PrinterIcon className="w-6" />,
            onClick: () => handleViewInvoice("detail"),
          },
          {
            text: t("buttons.duplicate"),
            icon: <CopyIcon className="w-6" />,
            onClick: () => handlePurchaseInvoiceActions("duplicate"),
          },
          {
            text: t("buttons.edit"),
            icon: <EditIcon className="w-6" />,
            onClick: () => handlePurchaseInvoiceActions("edit"),
          },
          {
            text: t("buttons.delete"),
            textColor: "text-red-500",
            icon: <TrashIcon />,
            onClick: () => handlePurchaseInvoiceActions("delete"),
          },
        ]}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaseInvoicesListLoading]
  );

  const handlePurchaseInvoiceActions = (type) => {
    if (type === "create") setSelectedItem(null);
    dispatch(
      openModal({
        title: `${type} Invoice`,
        type: type,
      })
    );
  };

  const handleViewInvoice = (type) => {
    dispatch(
      openModal({
        title: invoiceRef?.current && (
          <div>
            {invoiceRef.current?.invoiceNumber}
            <br />
            <span className="to-text-secondary font-normal text-base">
              Created By {invoiceRef?.current?.createdBy}
            </span>
          </div>
        ),

        type: type,
        editable: true,
      })
    );
  };

  const handleFormSubmission = () => {
    if (
      addEditPurchaseInvoiceRef?.current &&
      addEditPurchaseInvoiceRef?.current?.handleSubmit
    ) {
      addEditPurchaseInvoiceRef?.current?.handleSubmit();
    }
  };

  const filteredItems = useMemo(
    () => itemList?.filter((item) => item?.type !== "COMPOSITING"),
    [itemList]
  );

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={t("buttons.export")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport(
                "purchase-invoices/export-purchase-invoice",
                "PurchaseInvoices"
              )
            }
          />
          <CustomButton
            text={t("buttons.print")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint(
                "purchase-invoices/print-purchase-invoice",
                "Purchase Invoice"
              )
            }
          />
          <CustomButton
            text={t("client_dashboard.invoices.purchase.create_invoice")}
            width="w-fit"
            border="border"
            bgColor="!bg-surface-primary"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handlePurchaseInvoiceActions("create");
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        <div className="space-y-3 mt-4">
          <TabHeader position="start" />
          {TabContent()}
        </div>
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={purchaseInvoicesList ? purchaseInvoicesList?.length : 0}
        />
      </div>

      {/* modal */}
      <Suspense fallback={<Loader />}>
        <CustomModal
          loading={!isFormSubmitting}
          buttons={{
            create: [[], ["Cancel", "Create"]],
            duplicate: [[], ["Cancel", "Create"]],
            detail: [["Delete"], ["Edit", "Duplicate", "Print"]],
            delete: [[], ["Dismiss", "Delete"]],
            edit: [["Delete"], ["Save"]],
          }}
          actions={{
            onCreate: () => {
              handleFormSubmission();
            },
            onDuplicate: () => {
              handlePurchaseInvoiceActions("duplicate");
            },
            onEdit: () => {
              handlePurchaseInvoiceActions("edit");
            },
            onSave: () => {
              handleFormSubmission();
            },
            onDelete: () => {
              deleteInvoice();
            },
            onPrint: handlePrint,
          }}
        >
          {/* conditionally render modal for different purchase invoice actions */}
          {(modalState?.type === "duplicate" ||
            modalState?.type === "create" ||
            modalState?.type === "edit") && (
            <AddInvoice
              ref={addEditPurchaseInvoiceRef}
              purchaseInvoice={selectedItem ? selectedItem : null}
              suppliersList={suppliersList ?? []}
              itemList={filteredItems ?? []}
              storesList={storesList ?? []}
              setIsFormSubmitting={setIsFormSubmitting}
              itemListLoading={itemListLoading}
            />
          )}
          {/* detail modal */}
          {modalState?.type === "detail" &&
            (singlePurchaseInvoiceLoading ? (
              <Loader />
            ) : (
              <DetailCard
                detail={singleInvoiceDetail}
                otherDetail={itemDetail}
                otherDetailTitle="Item Details"
                image={itemImage}
              />
            ))}
          {/* delte modal */}
          {modalState?.type === "delete" && (
            <p className="text-text-primary text-base font-normal">
              Are you sure you want to delete this invoice?{" "}
            </p>
          )}
        </CustomModal>
      </Suspense>
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.invoices.name", href: "#", current: false },
  { name: "client_dashboard.invoices.purchase.name", href: "#", current: true },
];

// table headings for purchase invoices table
const tableHeader = [
  { label: "Invoice No", key: "invoiceNumber" },
  { label: "Date", key: "supplyDate" },
  { label: "Created by", key: "createdBy" },
  { label: "Supplier", key: "suppliers.name" },
  { label: "Store", key: "store.branchName" },
  { label: "Before Tax", key: "purchaseInvoiceItem.beforeTax" },
  { label: "Total", key: "purchaseInvoiceItem.subTotal" },
  {
    label: "Status",
    key: "paymentStatus",
    bgColorKey: {
      "Fully Paid": "bg-success-50 text-positive-600",
      "Partially Paid": "bg-danger-100 text-danger-600",
    },
  },
  { label: "Payment Method", key: "paymentMethod" },
];
