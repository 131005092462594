import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { PhoneIcon } from "../../../../common/Icons";
import { Card, CustomButton, Loader } from "../../../../common/components"; // Ensure you have a Loader component
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import {
  extendSubscription,
  getAllSubscriptions,
  getSingleSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { DefaultImg } from "../../../../../assets/images";

const schema = z.object({
  endDate: z
    .string()
    .min(1, "Select date first")
    .refine((date) => new Date(date) > new Date(), "Date must be after today"),
});

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function ExtendSubscription({ selectedItem, closeModal,subscriptionDetailId,selectedTab }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
    mode: "onTouched",
  });

  const dispatch = useDispatch();
  const subscriptionId = selectedItem?.id??subscriptionDetailId;

  useEffect(() => {
    if (subscriptionId) dispatch(getSingleSubscriptions({ subscriptionId, isFreeTrial: selectedTab === 'FreeTrial' ? true : false }));
  }, [dispatch, subscriptionId]);

  const handleConfirm = (formData) => {
    const payload = {
      subscriptionId,
      payload: {
        extendDate: formData.endDate,
      },
      successCallBack: handleSuccess,
    };

    dispatch(extendSubscription(payload));
  };

  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getSingleSubscriptions);
  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const PrivideBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const subTotal = singleSubscriptiondata?.subTotal ?? "-";
  const plan = singleSubscriptiondata?.plan_tenure ?? "-";
  const startDate = singleSubscriptiondata?.startDate ?? "-";
  const endDate = singleSubscriptiondata?.endDate ?? "-";

  if (subscriptionLoading) {
    return <div className="mx-60 mt-12">
    <Loader />
    </div>; 
  }
  return (
    <div className="p-4">
      <div className="flex items-center">
        <img
          src={profile?.profilePic || DefaultImg}
          alt="Pic"
          className="inline-block h-12 w-12 rounded-full border mr-3"
        />

        <div className="flex-grow">
          <p className="text-text-primary text-lg font-semibold">
            {profile?.fullName}
          </p>
          <div className="flex items-center text-text-gray font-normal mt-2">
            <PhoneIcon className="mr-2" />{" "}
            <span className="pl-2 text-text-primary text-sm font-normal">
              {profile?.phoneNumber}
            </span>
          </div>
        </div>
      </div>
      <p className="mt-4 text-base font-normal text-text-primary">
        Current Plan
      </p>
      <div className="bg-white mt-2 mb-4 border rounded-lg p-4">
        <div className="flex gap-36 mb-4">
          <div>
            <p className="text-text-primary font-semibold">Advance Plan</p>
            <p className="text-text-secondary">
              {subTotal} SAR ({plan})
            </p>
          </div>
          <div>
            <p className="text-text-primary font-semibold">Provider</p>
            <p className="text-text-secondary">{PrivideBy}</p>
          </div>
        </div>
        <div className="flex gap-40">
          <div>
            <p className="text-text-primary font-semibold">Start Date</p>
            <p className="text-text-secondary">{formatDate(startDate)}</p>
          </div>
          <div>
            <p className="text-text-primary font-semibold ml-9">End Date</p>
            <p className="text-text-secondary ml-9">{formatDate(endDate)}</p>
          </div>
        </div>
      </div>
      <Card>
        <p className="text-text-primary font-semibold text-base">New Date</p>
        <div className="mt-4">
          <p className="text-text-primary font-normal text-base">
            End Date{" "}
          </p>
          <input
            className="w-full rounded-lg border-gray-200 mt-2"
            type="date"
            {...register("endDate")}
            min={new Date().toISOString().split("T")[0]} // Disallow past dates and today
          />
          {errors.endDate && (
            <p className="text-red-500">{errors.endDate.message}</p>
          )}
        </div>
      </Card>
      <div className="flex justify-end space-x-2 mt-4">
        <CustomButton
          text="Cancel"
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          width="20px"
          onClick={closeModal}
          className="hover:!bg-gray-200"

        />
        <CustomButton
          text="Confirm"
          width="20px"
          textColor="text-white"
          onClick={handleSubmit(handleConfirm)}
        />
      </div>
    </div>
  );
}

export default ExtendSubscription;
