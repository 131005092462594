import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import Loader from "../../../../common/components/Loader/Loader";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import CustomTable from "../../../../common/components/CustomTable/CustomTable";
import Layout from "../../Layout/Layout";
import useStocktakingList from "./useStocktakingList";
import AddEditStocktaking from "./AddEditStocktaking/AddEditStocktaking";
import CustomModal from "../../../../common/components/CustomModel/CustomModel";
import DetailCard from "../../../../common/components/DetailCard/DetailCard";
import {
  CopyIcon,
  EditIcon,
  EyeIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

/**
 * Stocktaking List component
 * Renders table of stocktaking list
 *
 * @returns {JSX.Element} JSX code for rendering the stocktaking list table
 */

export default function StocktakingList() {
  const { t } = useTranslation();
  const addEditStocktakingRef = useRef(null);
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    stocktakingList,
    stocktakingDetail,
    singleStocktakingLoading,
    stocktakingListLoading,
    storesList,
    itemList,
    itemDetail,
    itemImage,
    deleteStock,
    currentPage,
    setCurrentPage,
    itemListLoading,
  } = useStocktakingList(selectedItem?.id);

  const handleViewStocktaking = (type) => {
    dispatch(
      openModal({
        title: `Stocktaking ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleStocktakingActions = (type) => {
    if (type === "create") setSelectedItem(null);
    dispatch(
      openModal({
        title: `${type} Stocktaking`,
        type: type,
      })
    );
  };

  const filteredItems = useMemo(
    () => itemList?.filter((item) => item?.type !== "COMPOSITING"),
    [itemList]
  );

  const handleSubmit = () => {
    if (
      addEditStocktakingRef &&
      addEditStocktakingRef.current &&
      addEditStocktakingRef.current.handleSubmit
    ) {
      addEditStocktakingRef.current.handleSubmit();
    }
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={"buttons.export"}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport("stock-taking/export-stock-takings", "Stocktaking")
            }
          />
          <CustomButton
            text={"buttons.print"}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint(
                "stock-taking/print-stock-taking-invoice",
                "Stocktaking"
              )
            }
          />
          <CustomButton
            text={"client_dashboard.inventory.stocktaking.create_stocktaking"}
            width="w-fit"
            bgColor="!bg-surface-primary"
            hoverTextColor="text-white"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handleStocktakingActions("create");
            }}
          />
        </div>
      </div>

      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* stocktaking list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={stocktakingList ?? []}
          setSelectedItem={setSelectedItem}
          loading={stocktakingListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewStocktaking("detail"),
            },
            {
              text: t("buttons.print"),
              icon: <PrinterIcon className="w-6" />,
              onClick: () => handleViewStocktaking("detail"),
            },
            {
              text: t("buttons.duplicate"),
              icon: <CopyIcon className="w-6" />,
              onClick: () => handleStocktakingActions("duplicate"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleStocktakingActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleStocktakingActions("delete"),
            },
          ]}
        />

        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={stocktakingList ? stocktakingList.length : 0}
        />
      </div>

      {/* modal */}
      <CustomModal
        onDelete={deleteStock}
        loading={isSubmitting}
        isInputChanged={
          modalState?.type === "duplicate" ? "false" : isInputChanged
        }
        buttons={{
          create: [[], ["Cancel", "Create"]],
          duplicate: [[], ["Cancel", "Create"]],
          detail: [["Delete"], ["Edit", "Duplicate", "Print"]],
          edit: [["Delete"], ["Save"]],
        }}
        actions={{
          onCreate: () => {
            handleSubmit();
          },
          onEdit: () => {
            handleStocktakingActions("edit");
          },
          onDuplicate: () => {
            handleStocktakingActions("duplicate");
          },
          onPrint: () => {
            window.print();
          },
          onSave: () => {
            handleSubmit();
          },
        }}
      >
        {/* conditionally render modal for different stocktaking actions */}
        {(modalState?.type === "edit" ||
          modalState?.type === "create" ||
          modalState?.type === "duplicate") && (
          <AddEditStocktaking
            stocktaking={selectedItem ? selectedItem : null}
            storesList={storesList ?? []}
            itemList={filteredItems ?? []}
            setIsSubmitting={setIsSubmitting}
            setIsInputChanged={setIsInputChanged}
            ref={addEditStocktakingRef}
            itemListLoading={itemListLoading}
          />
        )}
        {/* detail modal */}
        {modalState?.type === "detail" &&
          (singleStocktakingLoading ? (
            <Loader />
          ) : (
            <DetailCard
              otherDetailTitle="Item Details"
              otherDetail={itemDetail}
              detail={stocktakingDetail}
              image={itemImage}
            />
          ))}
        {/* delete modal */}
        {modalState?.type === "delete" && (
          <p className="text-text-primary text-base font-normal">
            Are you sure you want to delete this stocktaking?{" "}
          </p>
        )}
      </CustomModal>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.inventory.name", href: "#", current: false },
  {
    name: "client_dashboard.inventory.stocktaking.name",
    href: "#",
    current: true,
  },
];
// table headings for stocktaking table

const tableHeader = [
  { label: "Date/Time", key: "date" },
  { label: "Created By", key: "createdBy" },
  { label: "Count Name", key: "countName" },
  { label: "Store", key: "store.branchName" },
  { label: "Total Cost", key: "totalCost", type: "cost" },
  { label: "Quantity", key: "quantity" },
];
