import React, { Fragment, useRef, useState } from "react";
import CustomButton from "../Button/Button";
import { ThreeDotsIcon } from "../../Icons";
import { Menu, MenuButton, MenuItems, Transition } from "@headlessui/react";
import Loader from "../Loader/Loader";
import { formatDateTime } from "../../../../helpers/dateFormatter";
import _ from "lodash";
import { useDropdownPosition } from "../../../../hooks/useDropdownPosition";

/**
 * CustomTable component.
 * Renders a table UI with customizable header, body, and action buttons in a dropdown menu.
 *
 * @param {Object} props - Props for the CustomTable component.
 * @param {string[]} props.tableHeader - Array of header labels for the table columns.
 * @param {Object[]} props.tableBody - Array of objects representing the table rows.
 * @param {Function} props.onEdit - Function to handle the edit action.
 * @param {Function} props.onDelete - Function to handle the delete action.
 * @param {Function} props.onView - Function to handle the view action.
 * @param {Function} props.setSelectedItem - Function to set the selected item when an action is triggered.
 * @returns {JSX.Element} JSX code for rendering the CustomTable component.
 */

const CustomTable = ({
  tableHeader,
  tableBody = [],
  setSelectedItem,
  loading,
  callback = (item) => item,
  dropdownOptions = [],
}) => {
  if (loading) return <Loader center className="mt-20" />;

  return (
    <div className="mt-6 flow-root">
      <div className="inline-block max-w-full min-w-full py-2 align-middle">
        <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300 border ">
            <thead className="bg-gray-50 ">
              <tr>
                {tableHeader?.map((item, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`py-2 text-start whitespace-nowrap text-sm font-semibold text-text-secondary ${
                      index === 0 ? "pr-3 pl-4 sm:pl-6" : "px-3"
                    }`}
                  >
                    {item?.label}
                  </th>
                ))}

                <th scope="col" className="relative py-2 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {tableBody?.length > 0 &&
                tableBody?.map(callback)?.map((obj, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        tableBody?.length > 4 &&
                        "[&:last-child_.dropDownMenu]:bottom-10 [&:last-child_.dropDownMenu]:top-auto"
                      }`}
                    >
                      {tableHeader.map((heading, subIndex) => (
                        <td
                          key={subIndex}
                          className={`text-sm max-w-72 font-medium text-text-primary ${
                            subIndex === 0
                              ? "py-4 pl-4 pr-3 sm:pl-6"
                              : "px-3 py-4"
                          } ${
                            tableHeader?.length === 1 ? "max-w-1" : "max-w-72"
                          }`}
                        >
                          {heading.key === "itemDetails" ? (
                            <div className="flex items-center gap-2">
                              <img
                                src={obj?.itemDetails?.image}
                                alt={obj?.itemDetails?.name}
                                className="w-14 h-8 object-cover rounded-md"
                              />
                              <span>{obj?.itemDetails?.name}</span>
                            </div>
                          ) : (
                            // Render other fields as usual
                            <span
                              className={
                                heading?.bgColorKey &&
                                `py-1 px-2 rounded-lg ${
                                  heading?.bgColorKey[obj[heading.key]]
                                }`
                              }
                            >
                              {heading?.type === "default"
                                ? heading?.key
                                : heading?.key.includes(",")
                                ? heading?.key
                                    .split(",")
                                    .map((property) => _.get(obj, property))
                                    .filter(Boolean)
                                    .join(", ")
                                : typeof _.get(obj, heading?.key) !==
                                    "undefined" &&
                                  _.get(obj, heading?.key) !== null
                                ? typeof _.get(obj, heading?.key) === "boolean"
                                  ? _.get(obj, heading?.key)
                                    ? "Yes"
                                    : "No"
                                  : heading?.type === "date"
                                  ? formatDateTime(_.get(obj, heading?.key))
                                  : heading?.type === "cost"
                                  ? _.get(obj, heading?.key) + " SAR"
                                  : heading?.bgColorKey
                                  ? _.capitalize(_.get(obj, heading?.key))
                                  : _.get(obj, heading?.key)
                                : "-"}
                            </span>
                          )}
                        </td>
                      ))}
                      {dropdownOptions.length > 0 && (
                        <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex justify-end">
                          <Menu>
                            {({ open }) => {
                              return (
                                <>
                                  <MenuButton
                                    className="flex items-center"
                                    onClick={() =>
                                      setSelectedItem && setSelectedItem(obj)
                                    }
                                  >
                                    <span className="sr-only">
                                      Open action menu
                                    </span>
                                    <ThreeDotsIcon />
                                  </MenuButton>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <MenuItems className="absolute dropDownMenu flex flex-col end-4 top-11 z-10 w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                      {dropdownOptions?.map((option, i) => {
                                        return (
                                          <Menu.Item as="span" key={i}>
                                            <CustomButton
                                              bgColor="bg-trasnparent"
                                              textColor="text-text-primary"
                                              hoverTextColor="text-text-primary"
                                              padding="py-3 px-3"
                                              className="leading-none !shadow-none !justify-start"
                                              hoverBgColor="bg-gray-100"
                                              borderRadius="rounded-none"
                                              {...option}
                                            />
                                          </Menu.Item>
                                        );
                                      })}
                                    </MenuItems>
                                  </Transition>
                                </>
                              );
                            }}
                          </Menu>
                        </td>
                      )}
                    </tr>
                  );
                })}

              {tableBody?.length < 1 && (
                <tr>
                  <td colSpan={2} className="pl-4">
                    <p className="p-2 text-red-500">No record found!</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;

// sample usage
// import Customtable from '.path/to/CustomTable.js"

/* <CustomTable
  tableHeader={tableHeader}
  tableBody={simplifiedUserList}
  delete
  onEdit={() => handleUserActions("edit")}
  onView={() => handleViewUser("detail")}
  onDelete={() => handleUserActions("delete")}
  setSelectedItem={setSelectedItem}
/>; */
