import React, { useState } from "react";
import CustomButton from "../../../common/components/Button/Button";
import { Plus } from "../../../common/Icons/fontawesome";
import CustomPosTable from "../../../common/components/PosTable/PosTable";
import { useSubscription } from "./useSubscription";
import CustomAdminModel from "../../../common/components/AdminModal/CustomAdminModal";
import AddBranch from "./AddBranch/AddBranch";
import ChangeSubscription from "./ChangeSubscription/ChangeSubscription";
import ExtendSubscription from "./ExtendSubscription/ExtendSubscription";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import AddNewSubscription from "./AddNewSubscription/AddNewSubscription";
import { SelectGroup } from "../../../common/components";
import CustomDatePicker from "../../../common/components/CustomDatePicker/CustomDatePicker";
import PaginationBar from "../../../common/components/PaginationBar/PaginationBar";
import { ROLE } from "../../../../enums/role";

function Subscription() {
  const [startDate, setStartDate] = useState(new Date(2000, 0, 1));
  const [endDate, setEndDate] = useState(new Date(2024, 5, 24));
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [selectedStaffUser, setSelectedStaffUser] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTab, setSelectedTab] = useState("PaidSubscriptions");

  const {
    activeModal,
    dropdownOptions,
    headers,
    tableBody,
    closeModal,
    openModal,
    openAddNewSubscriptionModal,
    subscriptionLoading,
    selectedItem,
    setSelectedItem,
    SortfromLatest,
    AllStatus,
    staffUserOptions,
    PlanTypes,
    currentPage,
    setCurrentPage,
    fetchAllSubscriptions,
    storedUserData,
    setSelectedSort,
  } = useSubscription(
    startDate,
    endDate,
    selectedPlanType,
    selectedStaffUser,
    selectedStatus,
    selectedTab
  );

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    // fetchAllSubscriptions();
  };

  return (
    <div>
      <p className="text-sm font-medium mb-1">Toggle Plan</p>

      {storedUserData?.user?.role !== ROLE.ACCOUNTING && (
        <div className="flex items-center space-x-4 mb-6">
          <div className="flex space-x-4 border px-2 py-1 rounded-lg bg-gray-200">
            <button
              className={`px-4 py-1 ${
                selectedTab === "PaidSubscriptions"
                  ? "bg-blue-500 text-white font-medium rounded-lg"
                  : "bg-white border rounded-lg"
              }`}
              onClick={() => handleTabChange("PaidSubscriptions")}
            >
              Paid Subscriptions
            </button>
            <button
              className={`px-4 py-1 ${
                selectedTab === "FreeTrial"
                  ? "bg-blue-500 font-medium text-white rounded-lg"
                  : "bg-white border rounded-lg"
              }`}
              onClick={() => handleTabChange("FreeTrial")}
            >
              Free Trial
            </button>
          </div>
          <div className="flex items-center flex-grow text-text-primary font-semibold text-base"></div>

          <CustomButton
            width="w-fit"
            border="border"
            text="Add New Subscription"
            padding="px-2 py-3"
            bgColor="!bg-surface-primary"
            hoverTextColor="text-white"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={openAddNewSubscriptionModal}
          />
        </div>
      )}
      {selectedTab === "PaidSubscriptions" && (
        <div className="flex items-center space-x-2 mt-4">
          <div className="flex items-center flex-grow gap-2">
            <SelectGroup
              htmlFor="planType"
              inputName="planType"
              width="w-fit"
              options={PlanTypes}
              textColor="text-text-primary"
              className="!py-2 min-w-32"
              onChange={(e) => {
                setSelectedPlanType(e.target.value);
                // fetchAllSubscriptions();
              }}
            />
            {storedUserData?.user?.role !== ROLE.ACCOUNT_MANAGER && (
              <SelectGroup
                htmlFor="subscriptionProvider"
                inputName="subscriptionProvider"
                width="w-fit"
                options={staffUserOptions}
                textColor="text-text-primary"
                className="!py-2 min-w-60"
                onChange={(e) => {
                  setSelectedStaffUser(e.target.value);
                  // fetchAllSubscriptions();
                }}
              />
            )}
            <SelectGroup
              htmlFor="status"
              inputName="status"
              width="w-fit"
              options={AllStatus}
              textColor="text-text-primary"
              className="!py-2 min-w-32"
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                // fetchAllSubscriptions();
              }}
            />
          </div>
          <CustomDatePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            onChange={(selection) => {
              if (selection) {
                setStartDate(selection.startDate);
                setEndDate(selection.endDate);
                // fetchAllSubscriptions();
              }
            }}
          />
          <SelectGroup
            htmlFor="sort"
            inputName="sort"
            width="w-fit"
            options={SortfromLatest}
            textColor="text-text-primary"
            className="!py-2 min-w-44"
            onChange={(e) => {
              setSelectedSort(e.target.value);
            }}
          />
        </div>
      )}
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between mt-2">
        <CustomPosTable
          tableHeader={headers}
          tableBody={tableBody}
          dropdownOptions={dropdownOptions}
          loading={subscriptionLoading}
          showActionsColumn={true}
          openModal={openModal}
          setSelectedItem={(sub) => setSelectedItem(sub)}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={tableBody ? tableBody?.length : 0}
        />
      </div>

      {activeModal === "AddBranches" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[650px]"
          title="Add Branch"
        >
          <AddBranch
            selectedItem={selectedItem}
            closeModal={closeModal}
            selectedTab={selectedTab}
          />
        </CustomAdminModel>
      )}

      {activeModal === "ChangeSubscription" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          onClose={closeModal}
          title="Change Subscription"
        >
          <ChangeSubscription
            selectedItem={selectedItem}
            closeModal={closeModal}
            selectedTab={selectedTab}
          />
        </CustomAdminModel>
      )}
      {activeModal === "ExtendSubscription" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Extend Subscription"
        >
          <ExtendSubscription
            selectedItem={selectedItem}
            closeModal={closeModal}
            selectedTab={selectedTab}
          />
        </CustomAdminModel>
      )}
      {activeModal === "AddNewSubscription" && (
        <CustomAdminModel
          isOpen={true}
          onClose={closeModal}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
          title="Add New Subscription"
        >
          <AddNewSubscription closeModal={closeModal} />
        </CustomAdminModel>
      )}

      {activeModal === "CancelSubscription" && (
        <CustomAdminModel
          isOpen={true}
          border="border-b"
          width="min-[280px]:w-[80%] min-[1080px]:w-[500px]"
          onClose={closeModal}
          title="Cancel Subscription"
        >
          <CancelSubscription
            selectedItem={selectedItem}
            closeModal={closeModal}
            selectedTab={selectedTab}
          />
        </CustomAdminModel>
      )}
    </div>
  );
}

export default Subscription;
