import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import SelectGroup from "../../../../../common/components/SelectDropdown/SelectDropdown";
import { checkInputChanges } from "../../../../../../helpers/checkInputChanges";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addStocktaking,
  getAllStocktakings,
  updateStocktaking,
} from "../../../../../../features/Stocktaking/StocktakingSlice";
import { getAllItems } from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import { showToast } from "../../../../../common/utils/showToast.util";

/**
 * AddEditStocking component.
 * Renders a form to add or edit stocktaking information.
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditStocking component
 */
const AddEditStocking = forwardRef(
  (
    {
      stocktaking,
      storesList,
      itemList,
      setIsInputChanged,
      setIsSubmitting,
      itemListLoading,
    },
    ref
  ) => {
    const selectedItemRef = useRef(null);

    const [isFirstTime, setIsFirstTime] = useState(true);
    const modalState = useSelector((state) => state.modal);
    const stocktakingId = stocktaking?.id;
    const dispatch = useDispatch();
    // const vItemId = stocktaking?.itemDetails?.variableItem[0]?.

    const [detailCount] = useState(1);
    // State variable to track the selected value

    const defaultValues = useMemo(
      () => ({
        countName: stocktaking?.countName ?? "",
        description: stocktaking?.description ?? "",
        date: stocktaking?.date ?? "",
        store: stocktaking?.store?.id ?? "",
        item: stocktaking?.variableItem?.id || stocktaking?.itemDetails?.id,
        stocktaking: stocktaking?.stocktaking ?? "",
        unitmeasure: stocktaking?.itemDetails?.measureOfUnit ?? "",
        expected: stocktaking?.expected ?? "",
        quantity: stocktaking?.quantity ?? "",
        costPrice: stocktaking?.costPrice ?? "",
        note: stocktaking?.note ?? "",
      }),
      [stocktaking]
    );

    // validation schema for add/edit stocktaking form
    const addEditStocktakingSchema = z.object({
      countName: z
        .string()
        .min(
          1,
          "client_dashboard.inventory.stocktaking.validations.countName_error"
        )
        .max(
          100,
          "client_dashboard.inventory.stocktaking.validations.countName_error_max"
        ),
      description: z
        .string()
        .min(
          1,
          "client_dashboard.inventory.stocktaking.validations.description_error"
        )
        .max(
          200,
          "client_dashboard.inventory.stocktaking.validations.description_error_max"
        ),
      date: z
        .string()
        .min(
          1,
          "client_dashboard.inventory.stocktaking.validations.date_error"
        ),
      store: z.coerce.number().int().min(1, {
        message:
          "client_dashboard.inventory.stocktaking.validations.store_error",
      }),
      item: z.coerce.number().int().min(1, {
        message:
          "client_dashboard.inventory.stocktaking.validations.item_error",
      }),
      expected: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.inventory.stocktaking.validations.expected_error",
        })
        .int()
        .optional(),
      quantity: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.inventory.stocktaking.validations.quantity_error",
        })
        .int()
        .min(1, {
          message:
            "client_dashboard.inventory.stocktaking.validations.quantity_error",
        })
        .max(1000000, {
          message:
            "client_dashboard.inventory.stocktaking.validations.quantity_error_max",
        }),
      costPrice: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.inventory.stocktaking.validations.costPrice_error",
        })
        .int()
        .optional(),
      note: z.string().optional(),
    });

    // Form handling using react-hook-form
    const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange",
      resolver: zodResolver(addEditStocktakingSchema),
    });

    useEffect(() => {
      // set the default values for each field to edit stocktaking using 'setValue' method of react-hook-form
      if (stocktaking) {
        defaultValues &&
          Object.keys(defaultValues).forEach((key) =>
            setValue(key, defaultValues[key])
          );
      }
    }, [stocktaking, setValue, defaultValues, itemList]);

    useEffect(() => {
      setIsSubmitting(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const selectedItem = watch("item");

    // set currentValues to track changes in the input fields using watch method
    const currentValues = watch();

    useEffect(() => {
      // Check if the current state is different from the initial state
      setIsInputChanged(checkInputChanges(currentValues, defaultValues));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValues, defaultValues]);

    const onStocktakingUpdate = (data) => {
      if (modalState?.type === "duplicate") {
        showToast("Stocktaking duplicated successfully!");
      } else if (modalState?.type === "add") {
        showToast("Stocktaking created successfully!");
      }
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      const payloadItems = {
        page: 1,
        pageSize: 1000,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };

      dispatch(getAllItems({ payload: payloadItems }));
      dispatch(getAllStocktakings({ payload }));
      dispatch(closeModal());
    };

    const selectedStore = watch("store");

    const storeId = selectedStore;

    useEffect(() => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {
          stores: { id: storeId },
        },
        attributes: {},
      };
      if (storeId) dispatch(getAllItems({ payload }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeId]);

    const filteredItem = useMemo(
      () =>
        itemList?.filter(
          (item) => (item?.vItemId || item.id) === +selectedItem
        ),
      [itemList, selectedItem]
    );

    useEffect(() => {
      if (isFirstTime) {
        setTimeout(() => {
          setIsFirstTime(false);
        }, 100);
        return;
      }

      if (filteredItem && filteredItem?.length > 0) {
        selectedItemRef.current = filteredItem;

        setValue("expected", filteredItem[0]?.quantity);
        setValue("costPrice", filteredItem[0]?.itemCost);
        setValue("unitmeasure", filteredItem[0]?.measureOfUnit);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredItem, selectedItem, setValue]);

    // Expose handleSubmit function to parent component
    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: handleSubmit((data) => {
          const payload = {
            countName: data?.countName,
            description: data?.description,
            date: data?.date,
            expected: +data?.expected,
            quantity: +data?.quantity,
            costPrice: +data?.costPrice,
            note: data?.note,
            storeId: +data?.store,
            itemId:
              selectedItemRef &&
              selectedItemRef.current &&
              selectedItemRef?.current[0]?.type === "VARIABLE"
                ? +selectedItemRef.current[0]?.id
                : +data?.item,
            vItemId: +data?.item,
          };

          if (stocktakingId && modalState?.type === "edit")
            return dispatch(
              updateStocktaking({
                stocktakingId,
                // vItemId,
                payload,
                successCallBack: onStocktakingUpdate,
              })
            );
          else {
            return dispatch(
              addStocktaking({
                payload,
                successCallBack: onStocktakingUpdate,
              })
            );
          }
        }),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [handleSubmit]
    );

    return (
      <React.Fragment>
        {/* count name */}
        <InputGroup
          labelText={t("client_dashboard.inventory.stocktaking.count_name")}
          htmlFor="countName"
          inputName="countName"
          placeholder="Enter Count Name"
          register={register}
          errors={errors}
        />
        {/* description */}
        <InputGroup
          labelText={t("client_dashboard.inventory.stocktaking.description")}
          htmlFor="description"
          inputName="description"
          placeholder="Enter Description"
          register={register}
          errors={errors}
        />
        {/* date */}
        <InputGroup
          labelText={t("client_dashboard.inventory.stocktaking.date")}
          inputType="date"
          htmlFor="date"
          inputName="date"
          placeholder="Enter Date"
          register={register}
          errors={errors}
        />
        {/* store */}
        <SelectGroup
          labelText={t("client_dashboard.inventory.stocktaking.store")}
          htmlFor="store"
          inputName="store"
          options={[
            { value: "", label: "Select Store" },
            ...storesList?.map((option) => ({
              value: option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        <h6 className="text-text-primary font-semibold text-base">
          {t("client_dashboard.inventory.stocktaking.item_details")}
        </h6>

        {[...Array(detailCount)].map((_, index) => (
          <div className="space-y-3" key={index}>
            <span className="font-semibold to-text-primary text-sm">
              {t("client_dashboard.inventory.stocktaking.detail")} {index + 1}
            </span>
            {/* item */}
            <SelectGroup
              labelText={t("client_dashboard.inventory.stocktaking.item")}
              htmlFor="item"
              inputName="item"
              isLoading={itemListLoading}
              options={[
                {
                  value: "",
                  label: t("client_dashboard.invoices.sales.select_item"),
                },
                ...itemList?.map((option) => ({
                  value: option?.vItemId ?? option?.id,
                  label: option?.slug ?? option?.englishName,
                })),
              ]}
              register={register}
              errors={errors}
              className="rtl:bg-left"
            />
            {/* Unit of Measure */}
            {filteredItem[0]?.type === "RAW" && (
              <SelectGroup
                labelText={t(
                  "client_dashboard.inventory.stocktaking.unitmeasure"
                )}
                htmlFor="unitmeasure"
                inputName="unitmeasure"
                options={unitMeasures}
                register={register}
                errors={errors}
                className="pointer-events-none rtl:bg-left"
                bgColor="!bg-surface-gray"
                disabled={true}
              />
            )}

            {/* expected */}
            <InputGroup
              labelText={t("client_dashboard.inventory.stocktaking.expected")}
              htmlFor="expected"
              inputName="expected"
              register={register}
              errors={errors}
              className="pointer-events-none"
              bgColor="!bg-surface-gray"
              disabled={true}
            />
            {/* quantity */}
            <InputGroup
              labelText={t("client_dashboard.inventory.stocktaking.quantity")}
              htmlFor="quantity"
              inputName="quantity"
              placeholder="Enter Quantity"
              register={register}
              errors={errors}
            />
            {/* cost price */}
            <InputGroup
              labelText={t("client_dashboard.inventory.stocktaking.cost_price")}
              htmlFor="costPrice"
              inputName="costPrice"
              register={register}
              errors={errors}
              className="pointer-events-none"
              bgColor="!bg-surface-gray"
              disabled={true}
            />
            {/* note */}
            <InputGroup
              labelText={t("client_dashboard.inventory.stocktaking.note")}
              htmlFor="note"
              inputName="note"
              placeholder="Enter Note"
              register={register}
              errors={errors}
            />
          </div>
        ))}

        {/* <CustomButton
        text="Add Other Detail"
        icon={<Plus color="text-blue-sky" />}
        width="w-fit"
        bgColor="bg-transparent"
        className="shadow-none"
        textColor="text-text-link"
        onClick={addDetailCount}
      /> */}
      </React.Fragment>
    );
  }
);

export default AddEditStocking;

const unitMeasures = [
  { value: "", label: "Select Unit of Measure" },
  { value: "kg", label: "Kg" },
  { value: "m", label: "Meter" },
  { value: "cm", label: "Cm" },
  { value: "mm", label: "Millimeters" },
  { value: "in", label: "Inch" },
  { value: "m2", label: "Square meters" },
  { value: "m3", label: "Cubic meters" },
  { value: "t", label: "Ton" },
  { value: "g", label: "Gram" },
  { value: "oz", label: "Ounce" },
  { value: "lb", label: "Pound" },
  { value: "unit", label: "Unit" },
  { value: "pc", label: "Piece" },
  { value: "box8", label: "Box of 8" },
  { value: "box12", label: "Box of 12" },
  { value: "box15", label: "Box of 15" },
  { value: "box24", label: "Box of 24" },
  { value: "box40", label: "Box of 40" },
  { value: "box48", label: "Box of 48" },
  { value: "hr", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "month", label: "Month" },
  { value: "report", label: "Report" },
];
