import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  addCategory,
  deleteCategory,
  getAllCategories,
  getSingleCategory,
  updateCategory,
} from "../../../../../features/Categories/CategoriesSlice";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling categories related functionalities.
 *
 * @returns {Object}
 *   categoryList: Array containing category information for all categories
 *   isLoading: Data loading state for all categories
 *   singleCategory: Object containing category information
 *   singleCategoryLoading: Data loading state for single category information
 */
const useCategoriesList = (categoryId) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { query } = useSelector((state) => state.search);

  // get single category information from the store
  const { data: singleCategory, isLoading: singleCategoryLoading } =
    useSelector((state) => state.categories.getSingleCategory);
  // get category list for all categories from the store
  const { data: categoryList, isLoading: categoryListLoading } = useSelector(
    (state) => state.categories.getAllCategories
  );
  // get uploaded image url
  const uploadFileData = useSelector((state) => state.uploadFile);

  const categoryDetail = singleCategory
    ? {
        "English Name": singleCategory?.englishName,
        "Arabic Name": singleCategory?.arabicName,
        "Visible to pos": singleCategory?.isVisibleToPos ? "Yes" : "No",
      }
    : null;

  // validation schema for add/edit category form
  const addEditCategorySchema = z.object({
    englishname: z
      .string()
      .min(
        3,
        "client_dashboard.directory.category.validations.englishname_error"
      )
      .max(
        100,
        "client_dashboard.directory.category.validations.englishname_error_max"
      ),
    arabicname: z.string().optional(),
    accesstopos: z.boolean(),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(addEditCategorySchema),
    defaultValues: { accesstopos: false },
  });

  /**
   * Handler function for form submission to update category information.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   *                        Example: Name, description, etc.
   */
  const onSubmit = async (data) => {
    const payload = {
      englishName: data?.englishname,
      arabicName: data?.arabicname,
      image: uploadFileData?.uploadSingleFile?.data?.url,
      isVisibleToPos: data?.accesstopos,
    };
    if (categoryId && modalState?.type === "edit")
      return dispatch(
        updateCategory({
          categoryId,
          payload,
          successCallBack: onCategoryUpdate,
        })
      );
    else {
      return dispatch(
        addCategory({
          payload,
          successCallBack: onCategoryUpdate,
        })
      );
    }
  };

  /**
   * This function is called when the category is updated successfully!
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting categories
   *    order: order for sorting categories by asc or desc
   *    condition: {}
   *    attributes:{}
   */
  const onCategoryUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCategories({ payload }));
    dispatch(closeModal());
  };

  /**
   * This function is used to delete a category
   *
   *  @param {object} categoryId The id of the category to delete
   *  @param {function} successCallBack This function will be called on the success of delete category function
   */
  const deleteCategoryItem = () => {
    if (categoryId)
      dispatch(
        deleteCategory({ categoryId, successCallBack: onCategoryUpdate })
      );
  };

  useEffect(() => {
    if (categoryId) {
      dispatch(getSingleCategory({ categoryId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    let searchParams = ["englishName"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllCategories({ payload }));
  }, [currentPage, query, dispatch]);

  return {
    categoryList,
    categoryListLoading,
    singleCategoryLoading,
    singleCategory,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    deleteCategoryItem,
    categoryDetail,
    isSubmitting,
    watch,
    currentPage,
    setCurrentPage,
    clearErrors,
  };
};

export default useCategoriesList;
