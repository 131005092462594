import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
} from "react";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CustomButton,
  InputGroup,
  SelectGroup,
} from "../../../../../common/components";
import { checkInputChanges } from "../../../../../../helpers/checkInputChanges";
import { useFieldArray, useForm } from "react-hook-form";
import { Plus } from "../../../../../common/Icons/fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  addInventoryTransfer,
  getAllInventoryTransfers,
  updateInventoryTransfer,
} from "../../../../../../features/InventoryTransfer/InventoryTransferSlice";
import { formatDateTime } from "../../../../../../helpers/dateFormatter";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import { CrossIcon } from "../../../../../common/Icons";
import { useTranslation } from "react-i18next";
import { getAllItems } from "../../../../../../features/Items/ItemsSlice";

/**
 * AddEditInventoryTransfer component.
 * Renders a form to add or edit an inventory transfer
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditInventoryTransfer component
 */

// validation schema for add/edit inventory transfer form
const addEditInventoryTransferSchema = z.object({
  storefrom: z.coerce.number().int().min(1, {
    message:
      "client_dashboard.inventory.inventory_transfer.validations.storefrom_error",
  }),
  storeto: z.coerce.number().int().min(1, {
    message:
      "client_dashboard.inventory.inventory_transfer.validations.storeto_error",
  }),
  date: z
    .string()
    .min(
      1,
      "client_dashboard.inventory.inventory_transfer.validations.date_error"
    ),
  items: z.array(
    z.object({
      itemid: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.inventory.inventory_transfer.validations.itemid_error"
        ),

      quantity: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.inventory.inventory_transfer.validations.quantity_error"
        ),
      description: z
        .string()
        .min(
          1,
          "client_dashboard.inventory.inventory_transfer.validations.description_error"
        ),
    })
  ),
});

const AddEditInventoryTransfer = forwardRef(
  (
    {
      inventoryTransfer,
      storesList,
      storeListLoading,
      setIsInputChanged,
      itemList,
      id,
      itemListLoading,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const selectedItemRef = useRef(null);

    const modalState = useSelector((state) => state.modal);
    const [isDuplicating, setIsDuplicating] = useState(false);

    const dispatch = useDispatch();
    // Refs to access handleSubmit functions of child components

    // Form handling using react-hook-form
    const {
      register,
      handleSubmit,
      setValue,
      watch,
      control,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange",
      resolver: zodResolver(addEditInventoryTransferSchema),
      defaultValues: {
        items: [
          {
            itemid: "",
            unitmeasure: "",
            quantity: "",
            description: "",
          },
        ],
      },
    });

    const addComposit = () => {
      append({});
    };

    const selectedItem = watch("items");

    useEffect(() => {
      if (modalState?.type === "duplicate") {
        setIsDuplicating(true);
      }
    }, [modalState?.type]);

    const { fields, append, remove, update } = useFieldArray({
      control: control,
      name: "items",
    });

    const defaultValues = useMemo(
      () => ({
        storefrom: inventoryTransfer?.storeFrom?.id ?? "",
        storeto: inventoryTransfer?.storeTo?.id ?? "",
        date: inventoryTransfer?.date ?? "",
        "items.0.itemid":
          inventoryTransfer?.transferredItems[0]?.itemDetail?.id ?? "",
        "items.0.unitmeasure":
          inventoryTransfer?.transferredItems[0]?.itemDetail?.measureOfUnit ??
          "",
        "items.0.numberunit":
          inventoryTransfer?.transferredItems[0]?.itemDetail?.noMeasureOfUnit ??
          "",
        "items.0.quantity":
          inventoryTransfer?.transferredItems[0]?.quantity ?? "",
        "items.0.description":
          inventoryTransfer?.transferredItems[0]?.itemDetail?.description ?? "",
      }),
      [inventoryTransfer]
    );

    useEffect(() => {
      // Set the default values for each field to edit inventory transfer using 'setValue' method of react-hook-form
      if (inventoryTransfer) {
        defaultValues &&
          Object.keys(defaultValues).forEach((key) =>
            setValue(key, defaultValues[key])
          );
      }
    }, [inventoryTransfer, setValue, defaultValues, itemList]);

    // Set currentValues to track changes in the input fields using watch method
    const currentValues = watch();

    useEffect(() => {
      // Check if the current state is different from the initial state
      setIsInputChanged(checkInputChanges(currentValues, defaultValues));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValues, defaultValues]);

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          storeFromId: data?.storefrom,
          storeToId: data?.storeto,
          date: formatDateTime(data?.date, "mm-dd-yyyy"),
          transferredItems: data?.items?.map((item) => ({
            quantity:
              selectedItemRef?.current?.type === "RAW"
                ? +selectedItem[0]?.numberunit
                : +item?.quantity,
            description: item?.description,
            itemId:
              selectedItemRef?.current?.type === "VARIABLE"
                ? selectedItemRef?.current?.id
                : +item?.itemid,
            vItemId: +item?.itemid,
          })),
        };

        if (id && modalState?.type === "edit")
          return dispatch(
            updateInventoryTransfer({
              id: inventoryTransfer?.id,
              payload,
              successCallBack: onInventoryTransferUpdate,
            })
          );
        else {
          dispatch(
            addInventoryTransfer({
              payload,
              successCallBack: onInventoryTransferUpdate,
            })
          );
        }
      }),
    }));

    const onInventoryTransferUpdate = () => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      dispatch(getAllInventoryTransfers({ payload }));
      dispatch(closeModal());
    };

    const selectedStore = watch("storefrom");

    const storeId = selectedStore;

    useEffect(() => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {
          stores: { id: storeId },
        },
        attributes: {},
      };
      if (storeId) dispatch(getAllItems({ payload }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeId]);

    useEffect(() => {
      selectedItem.map((s, i) => {
        const curItem = itemList.find(
          (r) => (r?.vItemId || r.id) === +s.itemid
        );
        selectedItemRef.current = curItem;
        if (!curItem) return null;
        if (+s.itemid === +(curItem?.vItemId || curItem.id)) {
          setValue(`items.${i}.itemid`, curItem?.vItemId || curItem.id);
          setValue(`items.${i}.unitmeasure`, curItem.measureOfUnit);
          setValue(`totalquantity`, curItem.quantity);
          setValue(`items.${i}.unitmeasure`, curItem.measureOfUnit);
        }
        return null;
      });

      const totalItemCost = selectedItem.reduce(
        (acc, s) => acc + s.itemcost,
        0
      );
      setValue("totalcost", totalItemCost);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedItem)]);

    return (
      <React.Fragment>
        <div className="flex gap-3">
          {!storeListLoading && (
            <SelectGroup
              labelText={t(
                "client_dashboard.inventory.inventory_transfer.storefrom"
              )}
              htmlFor="storefrom"
              inputName="storefrom"
              options={[
                {
                  value: "",
                  label: t(
                    "client_dashboard.inventory.inventory_transfer.select_storefrom"
                  ),
                },
                ...storesList?.map((option) => ({
                  value: option?.id,
                  label: option?.branchName,
                })),
              ]}
              register={register}
              errors={errors}
              className="w-full rtl:bg-left"
            />
          )}
          {!storeListLoading && (
            <SelectGroup
              labelText={t(
                "client_dashboard.inventory.inventory_transfer.storeto"
              )}
              htmlFor="storeto"
              inputName="storeto"
              options={[
                {
                  value: "",
                  label: t(
                    "client_dashboard.inventory.inventory_transfer.select_storeto"
                  ),
                },
                ...storesList?.map((option) => ({
                  value: option?.id,
                  label: option?.branchName,
                })),
              ]}
              register={register}
              errors={errors}
              className="w-full rtl:bg-left"
            />
          )}
        </div>
        {/* date */}
        <InputGroup
          labelText={t("client_dashboard.inventory.inventory_transfer.date")}
          inputType="date"
          htmlFor="date"
          inputName="date"
          register={register}
          errors={errors}
        />
        {fields.map((item, index) => (
          <div key={index + 1} className="space-y-3">
            <div className="flex items-center justify-between">
              <span className="font-semibold to-text-primary text-sm">
                Item {index + 1}
              </span>
              {fields?.length > 1 && (
                <span
                  className="block w-fit cursor-pointer ml-auto"
                  onClick={() => remove(index)}
                >
                  <CrossIcon />
                </span>
              )}
            </div>

            <SelectGroup
              labelText={t(
                "client_dashboard.inventory.inventory_transfer.item"
              )}
              htmlFor={`item-${index}`}
              inputName={`items.${index}.itemid`}
              isLoading={itemListLoading}
              options={[
                {
                  value: "",
                  label: t(
                    "client_dashboard.inventory.inventory_transfer.select_item"
                  ),
                },
                ...itemList?.map((option) => ({
                  value: option?.vItemId || option?.id,
                  label: option?.slug || option?.englishName,
                })),
              ]}
              register={register}
              errors={errors}
              className="rtl:bg-left"
            />
            {/* Unit of Measure */}
            {selectedItemRef?.current?.type === "RAW" && (
              <>
                <InputGroup
                  labelText={t(
                    "client_dashboard.inventory.stocktaking.unitmeasure"
                  )}
                  htmlFor={`unitmeasure-${index}`}
                  inputName={`items.${index}.unitmeasure`}
                  register={register}
                  errors={errors}
                  className="pointer-events-none rtl:bg-left"
                  bgColor="!bg-surface-gray"
                  disabled={true}
                />
                <InputGroup
                  labelText={t(
                    "client_dashboard.inventory.inventory_transfer.number_unit"
                  )}
                  htmlFor={`numberunit-${index}`}
                  inputName={`items.${index}.numberunit`}
                  placeholder={t(
                    "client_dashboard.inventory.inventory_transfer.enter_number_unit"
                  )}
                  register={register}
                  errors={errors}
                />
              </>
            )}

            {/* total quantity */}
            <InputGroup
              labelText={t(
                "client_dashboard.inventory.inventory_transfer.total_quantity"
              )}
              htmlFor={`totalquantity`}
              inputName={`totalquantity`}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
              disabled={true}
              register={register}
              errors={errors}
            />
            {/* quantity */}
            {selectedItemRef &&
              selectedItemRef?.current &&
              selectedItemRef?.current?.type !== "RAW" && (
                <InputGroup
                  labelText={t(
                    "client_dashboard.inventory.inventory_transfer.quantity"
                  )}
                  htmlFor={`quantity-${index}`}
                  inputName={`items.${index}.quantity`}
                  placeholder={t(
                    "client_dashboard.inventory.inventory_transfer.enter_quantity"
                  )}
                  register={register}
                  errors={errors}
                />
              )}

            {/* description */}
            <InputGroup
              htmlFor={`description-${index}`}
              inputName={`items.${index}.description`}
              labelText={t(
                "client_dashboard.inventory.inventory_transfer.description"
              )}
              placeholder={t(
                "client_dashboard.inventory.inventory_transfer.enter_description"
              )}
              register={register}
              errors={errors}
            />
            {!inventoryTransfer && (
              <CustomButton
                text={t(
                  "client_dashboard.inventory.inventory_transfer.add_more"
                )}
                icon={<Plus color="text-blue-sky" />}
                width="w-fit"
                bgColor="bg-transparent"
                className="shadow-none"
                textColor="text-text-link"
                onClick={addComposit}
              />
            )}
          </div>
        ))}
      </React.Fragment>
    );
  }
);

export default AddEditInventoryTransfer;
