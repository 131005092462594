import { Fragment, useState } from "react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { CaretUpDown, CrossIcon, GreenCheckCircle } from "../../Icons";
import { Badge } from "../Badge/Badge";

export default function MultiSelect({
  options = [],
  placeholder = "",
  onChange,
  value,
  error,
}) {
  const [query, setQuery] = useState("");

  const filteredItems =
    query === ""
      ? options
      : options?.filter((option) =>
          option?.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSelect = (option) => {
    const newValue = value.some((sel) => sel.value === option.value)
      ? value.filter((sel) => sel.value !== option.value) // Remove the item if it's already selected
      : [...value, option]; // Add the item if it's not selected

    onChange(newValue); // Notify the parent component about the change
  };

  const removeItem = (optionValue) => {
    const newValue = value.filter((sel) => sel.value !== optionValue);
    onChange(newValue); // Notify the parent component about the change
  };

  return (
    <div className="relative mt-1 z-10 w-full">
      <div className="flex flex-wrap gap-2 mb-2">
        {value?.map((option) => (
          <Badge key={option.value} className="shadow">
            {option.label}
            <button onClick={() => removeItem(option?.value)} className="ml-2">
              <CrossIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </Badge>
        ))}
      </div>
      <Combobox onChange={handleSelect}>
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300">
          <ComboboxInput
            className="w-full border text-text-primary border-border-primary rounded-lg focus:border-border-primary focus:ring-0 focus:outline-none py-2 pl-3 pr-10 placeholder:text-text-primary"
            displayValue={() => ""}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            autoComplete="off"
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <CaretUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <ComboboxOptions className="absolute mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredItems?.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredItems.map((option) => (
                <ComboboxOption
                  key={option.value}
                  value={option}
                  className={({ focus }) =>
                    `relative select-none py-2 pl-10 pr-4 rounded cursor-pointer ${
                      focus
                        ? "bg-surface-primary text-white"
                        : "text-text-primary"
                    }`
                  }
                >
                  {({ focus, selected }) => {
                    const isSelected = value.some(
                      (sel) => sel?.value === option?.value
                    );
                    return (
                      <>
                        <span
                          className={`block truncate ${
                            isSelected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option.label}
                        </span>
                        {isSelected && (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              focus ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <GreenCheckCircle />
                          </span>
                        )}
                      </>
                    );
                  }}
                </ComboboxOption>
              ))
            )}
          </ComboboxOptions>
        </Transition>
      </Combobox>
      {error && (
        <div className="text-red-500 text-sm mt-2">{error.message}</div>
      )}
    </div>
  );
}
