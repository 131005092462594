import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../../common/Icons/fontawesome";
import Loader from "../../../../../common/components/Loader/Loader";
import Breadcrumb from "../../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../../common/components/Button/Button";
import CustomTable from "../../../../../common/components/CustomTable/CustomTable";
import Layout from "../../../Layout/Layout";
import AddEditStore from "../AddEditStore/AddEditStore";
import CustomModal from "../../../../../common/components/CustomModel/CustomModel";
import DetailCard from "../../../../../common/components/DetailCard/DetailCard";
import useStoresList from "./useStoresList";
import { EditIcon, EyeIcon, TrashIcon } from "../../../../../common/Icons";
import PaginationBar from "../../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";

/**
 * Stores List component
 * Renders table of Stores list
 *
 * @returns {JSX.Element} JSX code for rendering the store list table
 */

export default function StoresList() {
  const { t } = useTranslation();
  const [isInputChanged, setIsInputChanged] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    storesList,
    storeDetail,
    singleStore,
    register,
    errors,
    handleSubmit,
    singleStoreLoading,
    setValue,
    deleteStoreItem,
    storeListLoading,
    isSubmitting,
    watch,
    currentPage,
    clearErrors,
    setCurrentPage,
  } = useStoresList(selectedItem?.id);

  const handleViewStore = (type) => {
    dispatch(
      openModal({
        title: `Store ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleStoresActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type} Store`,
        type: type,
      })
    );
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text={"client_dashboard.management.stores.add_store"}
          width="w-fit"
          bgColor="!bg-surface-primary"
          hoverTextColor="text-white"
          className="hover:!text-white"
          icon={<Plus color="white" />}
          onClick={() => handleStoresActions("add")}
        />
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* stores list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={storesList ?? []}
          delete
          setSelectedItem={setSelectedItem}
          loading={storeListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewStore("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleStoresActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleStoresActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={storesList ? storesList.length : 0}
        />
      </div>
      {/* modal */}
      <CustomModal
        onAdd={handleSubmit}
        onEdit={() => handleStoresActions("edit")}
        onDelete={deleteStoreItem}
        loading={isSubmitting}
        isInputChanged={isInputChanged}
      >
        {/* conditionally render modal for different store actions */}
        {(modalState?.type === "edit" || modalState?.type === "add") && (
          <AddEditStore
            store={modalState?.type === "edit" ? singleStore : null}
            register={register}
            errors={errors}
            setValue={setValue}
            setIsInputChanged={setIsInputChanged}
            watch={watch}
          />
        )}
        {/* detail modal */}
        {modalState?.type === "detail" &&
          (singleStoreLoading ? (
            <Loader />
          ) : (
            <DetailCard detail={storeDetail} />
          ))}
        {/* delete modal */}
        {modalState?.type === "delete" && (
          <p className="text-text-primary text-base font-normal">
            Are you sure you want to delete this store?{" "}
          </p>
        )}
      </CustomModal>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.management.name", href: "#", current: false },
  { name: "client_dashboard.management.stores.name", href: "#", current: true },
];
// table headings for Stores table

const tableHeader = [
  { label: "Store Code", key: "storeCode" },
  { label: "Name of Branch", key: "branchName" },
  {
    label: "Store Address",
    key: "buildingNumber,streetName,districtName,city,zipCode",
  },
];
