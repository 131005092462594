import React, { useEffect, useMemo } from "react";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import SelectGroup from "../../../../../common/components/SelectDropdown/SelectDropdown";
import { checkInputChanges } from "../../../../../../helpers/checkInputChanges";

/**
 * AddEditCustomer component.
 * Renders a form to add or edit a customer
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditCustomer component
 */

const AddEditCustomer = ({
  customer,
  register,
  errors,
  setValue,
  watch,
  setIsInputChanged,
}) => {
  const defaultValues = useMemo(
    () => ({
      name: customer?.name ?? "",
      email: customer?.email ?? "",
      phonenumber: customer?.phoneNumber ?? "",
      gender: customer?.gender ?? "",
    }),
    [customer]
  );

  useEffect(() => {
    // set the default values for each field to edit customer using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [customer, setValue, defaultValues]);

  // set currentValues to track changes in the input fields using watch method
  const currentValues = watch();

  /* this useEffect will run if the current 
  values are being changed meaning if 
  any change happen in input fields to disable or enable form buttons */
  useEffect(() => {
    // Check if the current state is different from the initial state
    setIsInputChanged(checkInputChanges(currentValues, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  return (
    <React.Fragment>
      {/* name */}
      <InputGroup
        labelText={"client_dashboard.directory.customer.customer_name"}
        htmlFor="name"
        inputName="name"
        placeholder={"client_dashboard.directory.customer.enter_name"}
        register={register}
        errors={errors}
      />
      {/* email */}
      <InputGroup
        labelText={"client_dashboard.directory.customer.email"}
        htmlFor="email"
        inputName="email"
        placeholder={"client_dashboard.directory.customer.enter_email"}
        register={register}
        errors={errors}
      />
      {/* phone */}
      <InputGroup
        labelText={"client_dashboard.directory.customer.phone_number"}
        htmlFor="phonenumber"
        inputName="phonenumber"
        placeholder={"client_dashboard.directory.customer.enter_phone"}
        register={register}
        errors={errors}
      />
      {/* gender */}
      <SelectGroup
        labelText={"client_dashboard.directory.customer.select_gender"}
        htmlFor="gender"
        inputName="gender"
        options={[
          {
            value: "",
            label: "client_dashboard.directory.customer.select_gender",
          },
          {
            value: "MALE",
            label: "client_dashboard.directory.customer.male",
          },
          {
            value: "FEMALE",
            label: "client_dashboard.directory.customer.female",
          },
        ]}
        register={register}
        errors={errors}
        className="rtl:bg-left"
      />
    </React.Fragment>
  );
};

export default AddEditCustomer;

// array of gender
