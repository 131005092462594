import React, { useEffect, useMemo, useState } from "react";

import { InputGroup, SelectGroup } from "../../../../../common/components";
import { Eye, EyeSlash } from "../../../../../common/Icons/fontawesome";
import { checkInputChanges } from "../../../../../../helpers/checkInputChanges";
import { useTranslation } from "react-i18next";
import MultiSelect from "../../../../../common/components/MultiSelect/MultiSelect";
import { Controller } from "react-hook-form";
import InputLabel from "../../../../../common/components/InputLabel/InputLabel";

/**
 * AddEditUser component.
 * Renders a form to add or edit a user
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditUser component
 */

const AddEditUser = ({
  user,
  register,
  errors,
  setValue,
  storesList,
  watch,
  setIsInputChanged,
  control,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = useMemo(
    () => ({
      name: user?.fullName ?? "",
      email: user?.email ?? "",
      createpassword: user?.password ?? "",
      code: user?.fastAccessCode ?? "",
      selectedStores:
        user?.stores?.map((store) => ({
          value: store.id,
          label: store.branchName,
        })) ?? [],
      role: user?.role ?? "",
      accesstodashboard: user?.accessToDashboard ?? false,
      accesstopos: user?.accessToPos ?? false,
    }),
    [user]
  );

  useEffect(() => {
    // set the default values for each field to edit user using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [user, setValue, defaultValues]);

  // set currentValues to track changes in the input fields using watch method
  const currentValues = watch();

  useEffect(() => {
    // Check if the current state is different from the initial state
    setIsInputChanged(checkInputChanges(currentValues, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues, defaultValues]);

  console.log(watch("selectedStores"));

  return (
    <React.Fragment>
      {/* name */}
      <InputGroup
        labelText={"client_dashboard.management.users.user_name"}
        htmlFor="name"
        inputName="name"
        placeholder={"client_dashboard.management.users.enter_name"}
        register={register}
        errors={errors}
      />
      {/* email */}
      <InputGroup
        labelText={"client_dashboard.management.users.email"}
        htmlFor="email"
        inputName="email"
        placeholder={"client_dashboard.management.users.enter_email"}
        register={register}
        errors={errors}
      />
      {/* password */}
      <InputGroup
        labelText={"client_dashboard.management.users.create_password"}
        htmlFor="createpassword"
        inputName="createpassword"
        placeholder={"client_dashboard.management.users.enter_password"}
        register={register}
        errors={errors}
        inputType={showPassword ? "text" : "password"}
        className={`w-full `}
        bgColor={`${user && "!bg-surface-gray"}`}
        disabled={user && true}
      >
        <span
          className="absolute end-3 top-7.5 pr-3 items-center cursor-pointer"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? (
            <Eye color="gray-700" />
          ) : (
            <EyeSlash color="gray-700" />
          )}
        </span>
      </InputGroup>
      {/* access code */}
      <InputGroup
        labelText={"client_dashboard.management.users.code"}
        htmlFor="code"
        inputName="code"
        placeholder={"client_dashboard.management.users.enter_code"}
        register={register}
        errors={errors}
      />
      {/* stores */}

      <Controller
        control={control}
        name="selectedStores"
        render={({ field: { value }, fieldState: { error } }) => {
          return (
            <MultiSelect
              options={
                [
                  ...storesList?.map((option) => ({
                    value: option?.id,
                    label: option?.branchName,
                  })),
                ] || []
              }
              placeholder="Select Stores"
              error={error}
              value={value}
              onChange={(val) => {
                setValue("selectedStores", val, { shouldValidate: true });
              }}
            />
          );
        }}
      />

      {/* role */}
      <SelectGroup
        labelText={"client_dashboard.management.users.select_role"}
        htmlFor="role"
        inputName="role"
        options={[
          {
            value: "",
            label: "client_dashboard.management.users.select_role",
          },
          {
            value: "CASHIER",
            label: "client_dashboard.management.users.roles.cashier",
          },
          {
            value: "SALES MANAGER",
            label: "client_dashboard.management.users.roles.sales_manager",
          },
          {
            value: "SYSTEM MANAGER",
            label: "client_dashboard.management.users.roles.system_manager",
          },
        ]}
        register={register}
        errors={errors}
        className="rtl:bg-left"
      />
      {/* permissions */}
      {/* access to dashbaord */}
      <div className="flex items-center gap-2">
        <InputGroup
          inputName="accesstodashboard"
          inputType="checkbox"
          register={register}
          errors={errors}
          className="!leading-none"
        />
        <InputLabel
          htmlFor="accesstodashboard"
          className="block text-sm font-normal text-text-primary leading-none"
          text={"client_dashboard.management.users.access_to_dashboard"}
        />
      </div>
      {/* access to pos */}
      <div className="flex items-center gap-2">
        <InputGroup
          inputName="accesstopos"
          inputType="checkbox"
          register={register}
          errors={errors}
          className="!leading-none"
        />
        <InputLabel
          htmlFor="accesstopos"
          className="block text-sm font-normal text-text-primary leading-none"
          text={"client_dashboard.management.users.access_to_pos"}
        />
      </div>
    </React.Fragment>
  );
};

export default AddEditUser;
