import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
} from "../../../../../common/components";
import { CrossIcon, QrCodeIcon } from "../../../../../common/Icons";
import { Plus } from "../../../../../common/Icons/fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  getAllItems,
} from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/ItemsModal/ItemsModalSlice";
/**
 * CompositingItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the CompositingItems component.
 */

const CompositingItems = forwardRef(
  ({ storesList, categoryList, rawItems, setIsSubmitting }, ref) => {
    const dispatch = useDispatch();
    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);

    // Schema for the main form data
    const compositingItemSchema = z.object({
      englishname: z
        .string({
          required_error:
            "client_dashboard.directory.items.compositing_item.validations.englishname_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.compositing_item.validations.englishname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.compositing_item.validations.englishname_error_max"
        ),
      arabicname: z
        .string({
          required_error:
            "client_dashboard.directory.items.compositing_item.validations.arabicname_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.compositing_item.validations.arabicname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.compositing_item.validations.arabicname_error_max"
        ),
      description: z
        .string({
          required_error:
            "client_dashboard.directory.items.compositing_item.validations.description_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.compositing_item.validations.description_error_min"
        )
        .max(
          500,
          "client_dashboard.directory.items.compositing_item.validations.description_error_max"
        ),
      store: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.compositing_item.validations.store_error"
        ),
      category: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.compositing_item.validations.category_error"
        ),
      saleprice: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.directory.items.compositing_item.validations.number_only_error",
        })
        .min(
          1,
          "client_dashboard.directory.items.compositing_item.validations.saleprice_error_min"
        )
        .max(
          1000000,
          "client_dashboard.directory.items.compositing_item.validations.saleprice_error_max"
        ),
      totalcost: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.directory.items.compositing_item.validations.number_only_error",
        })
        .min(
          1,
          "client_dashboard.directory.items.compositing_item.validations.totalcost_error_min"
        )
        .max(
          1000000,
          "client_dashboard.directory.items.compositing_item.validations.totalcost_error_max"
        ),
      compositingItem: z.array(
        z.object({
          itemcost: z.coerce
            .number({
              invalid_type_error:
                "client_dashboard.directory.items.compositing_item.validations.number_only_error",
            })
            .min(
              1,
              "client_dashboard.directory.items.compositing_item.validations.itemcost_error_min"
            )
            .max(
              1000000,
              "client_dashboard.directory.items.compositing_item.validations.itemcost_error_max"
            ),
          rawitem: z.coerce
            .number()
            .min(
              1,
              "client_dashboard.directory.items.compositing_item.validations.rawitem_error"
            ),
          unitmeasure: z.string().optional({
            required_error:
              "client_dashboard.directory.items.compositing_item.validations.unitmeasure_error",
          }),
          perunitprice: z.coerce
            .number({
              invalid_type_error:
                "client_dashboard.directory.items.compositing_item.validations.number_only_error",
            })
            .min(
              1,
              "client_dashboard.directory.items.compositing_item.validations.perunitprice_error_min"
            )
            .max(
              1000000,
              "client_dashboard.directory.items.compositing_item.validations.perunitprice_error_max"
            ),
          numberunit: z.coerce
            .number({
              invalid_type_error:
                "client_dashboard.directory.items.compositing_item.validations.number_only_error",
            })
            .min(
              1,
              "client_dashboard.directory.items.compositing_item.validations.numberunit_error_min"
            )
            .max(
              1000000,
              "client_dashboard.directory.items.compositing_item.validations.numberunit_error_max"
            ),
        })
      ),
    });

    // Form handling using react-hook-form
    /**
     * Object destructuring to manage form functionality with react-hook-form.
     * Provides functions and objects for form input validation and usage.
     *
     * @property {Function} register - Function to register form inputs for validation and usage.
     * @property {Function} handleSubmit - Function to handle form submission.
     * @property {Object} formState - Object holding form validation errors.
     * @property {Function} setValues - Function to set form input values.
     */
    const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors, isSubmitting },
      control,
    } = useForm({
      mode: "onChange", // Setting the form mode to trigger validation on input change
      resolver: zodResolver(compositingItemSchema),
      defaultValues: {
        compositingItem: [
          {
            rawitem: "",
            unitmeasure: "",
            perunitprice: "",
            numberunit: "",
            itemcost: "",
          },
        ],
      },
    });

    const { fields, append, remove, update } = useFieldArray({
      control: control,
      name: "compositingItem",
    });

    const selectedItem = watch("compositingItem");

    useEffect(() => {
      setIsSubmitting(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    useEffect(() => {
      selectedItem?.map((s, i) => {
        const rawItem = rawItems.find((r) => r.id === +s.rawitem);
        if (!rawItem) return null;
        if (s.perunitprice !== rawItem.perUnitPrice)
          update(i, {
            rawitem: rawItem.id,
            unitmeasure: rawItem.measureOfUnit,
            perunitprice: rawItem.perUnitPrice,
            // itemcost: rawItem.perUnitPrice * +s?.numberunit,
          });
        setValue(
          `compositingItem.${i}.itemcost`,
          rawItem.perUnitPrice * +s?.numberunit
        );
        return null;
      });

      const totalItemCost = selectedItem.reduce(
        (acc, s) => acc + s.itemcost,
        0
      );
      setValue("totalcost", totalItemCost);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedItem)]);

    const addComposit = () => {
      append({
        rawitem: "",
        unitmeasure: "",
        perunitprice: "",
        numberunit: "",
        itemcost: "",
      });
    };

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          type: "COMPOSITING",
          image: uploadFileData?.uploadSingleFile?.data?.url,
          englishName: data?.englishname,
          arabicName: data?.arabicname,
          description: data?.description,
          storeId: +data?.store,
          categoryId: +data?.category,
          itemCost: +data?.totalcost,
          salePrice: +data?.saleprice,
          compositingItem: data?.compositingItem?.map((item) => ({
            noMeasureOfUnit: item?.numberunit,
            rawItemId: item?.rawitem,
          })),
        };

        return dispatch(
          addItem({
            payload: payload,
            successCallBack: onItemUpdate,
          })
        );
      }),
    }));

    const onItemUpdate = (data) => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      dispatch(getAllItems({ payload }));
      dispatch(closeModal());
    };

    return (
      <div className="space-y-3">
        <CustomFileUploader
          isLoading={uploadFileData?.uploadSingleFile?.isLoading}
        />
        {/* name */}
        <InputGroup
          labelText="Item Name"
          htmlFor="englishname"
          inputName="englishname"
          placeholder="Enter Item Name"
          register={register}
          errors={errors}
        />
        {/* arabic name */}
        <InputGroup
          labelText="Arabic Item Name"
          htmlFor="arabicname"
          inputName="arabicname"
          placeholder="Enter Arabic Item Name"
          register={register}
          errors={errors}
        />
        {/* description */}
        <InputGroup
          labelText="Description"
          htmlFor="description"
          inputName="description"
          placeholder="Enter Description"
          register={register}
          errors={errors}
        />
        {/* store */}
        <SelectGroup
          labelText="Select Store"
          htmlFor="store"
          inputName="store"
          options={[
            { value: "", label: "All Store" },
            ...storesList?.map((option) => ({
              value: option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
        />
        {/* category */}
        <SelectGroup
          labelText="Select Category"
          htmlFor="category"
          inputName="category"
          options={[
            { value: "", label: "Select Category" },
            ...categoryList?.map((option) => ({
              value: option?.id,
              label: option?.englishName,
            })),
          ]}
          register={register}
          errors={errors}
        />

        {/* sale price */}
        <InputGroup
          labelText="Sale Price (without tax)"
          htmlFor="saleprice"
          inputName="saleprice"
          placeholder="Enter Sale Price"
          register={register}
          errors={errors}
        />
        <h6 className="text-text-primary font-semibold text-base">
          Compositing Product
        </h6>

        {fields.map((item, index) => (
          <div className="space-y-3" key={item.id}>
            <div className="flex items-center justify-between">
              <span className="font-semibold to-text-primary text-sm">
                Composition {index + 1}
              </span>
              {fields?.length > 1 && (
                <span className="cursor-pointer" onClick={() => remove(index)}>
                  <CrossIcon />
                </span>
              )}
            </div>

            <SelectGroup
              labelText="Raw Items"
              htmlFor={`rawitem-${index}`}
              inputName={`compositingItem.${index}.rawitem`}
              options={[
                { value: "", label: "Select Raw Items" },
                ...rawItems
                  ?.filter(
                    (item) =>
                      !fields.some(
                        (field, fieldIndex) =>
                          fieldIndex !== index && field.rawitem === item.id
                      )
                  )
                  .map((option) => ({
                    value: option?.id,
                    label: option?.englishName,
                  })),
              ]}
              register={register}
              errors={errors}
            />

            <InputGroup
              labelText="Unit of Measure"
              htmlFor={`unitmeasure-${index}`}
              inputName={`compositingItem.${index}.unitmeasure`}
              register={register}
              errors={errors}
              className="pointer-events-none"
              bgColor="!bg-surface-gray"
            />
            <InputGroup
              labelText="Per Unit Price"
              htmlFor={`perunitprice-${index}`}
              inputName={`compositingItem.${index}.perunitprice`}
              register={register}
              errors={errors}
              className="pointer-events-none"
              bgColor="!bg-surface-gray"
            />

            <InputGroup
              labelText="Number Unit of Measure"
              htmlFor={`numberunit-${index}`}
              inputName={`compositingItem.${index}.numberunit`}
              placeholder="Enter Number Unit of Measure"
              register={register}
              errors={errors}
            />
            <InputGroup
              labelText="Item Cost"
              htmlFor={`itemcost-${index}`}
              inputName={`compositingItem.${index}.itemcost`}
              register={register}
              errors={errors}
              className="pointer-events-none"
              bgColor="!bg-surface-gray"
              disabled={true}
            />
            <div className="flex items-center gap-10 !mt-4">
              <CustomButton
                text={
                  "client_dashboard.directory.items.simple_item.create_barcode_num"
                }
                icon={<QrCodeIcon />}
                textColor="text-text-primary"
                width="w-fit"
                border="border"
                padding="p-2"
              />
              <InputGroup
                htmlFor="barcode"
                inputName="barcode"
                placeholder={
                  "client_dashboard.directory.items.simple_item.enter_barcode"
                }
                register={register}
                errors={errors}
                className="flex-1"
              />
            </div>
          </div>
        ))}

        <CustomButton
          text="Add Another Composition"
          icon={<Plus color="text-blue-sky" />}
          width="w-fit"
          bgColor="bg-transparent"
          className="shadow-none"
          textColor="text-text-link"
          onClick={addComposit}
        />
        {/* total cost */}
        <InputGroup
          labelText="Total Cost"
          htmlFor="totalcost"
          inputName="totalcost"
          register={register}
          errors={errors}
          className="pointer-events-none"
          bgColor="!bg-surface-gray"
          disabled={true}
        />
      </div>
    );
  }
);

export default CompositingItems;

// sample usage
// import CompositingItems from "./path/to/CompositingItems.jsx";

// <CompositingItems />
