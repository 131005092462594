import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import {
  addPaymentMethods,
  getPaymentMethods,
} from "../../../../../../features/Payments/PaymentsSlice";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import { CrossIcon, TrashIcon } from "../../../../../common/Icons";
import { Plus } from "../../../../../common/Icons/fontawesome";
import {
  Card,
  CustomButton,
  InputGroup,
} from "../../../../../common/components";

const DeliveryCompany = forwardRef(({ setIsSubmitting }, ref) => {
  const dispatch = useDispatch();

  // validation schema for card payment
  const deliveryNotesSchema = z.object({
    companies: z.array(
      z.object({
        companyName: z
          .string()
          .min(3, "Company name must be at least 3 characters long!")
          .max(30, "Maximum 30 characters are allowed!"),
        feePercentage: z.coerce
          .number({ invalid_type_error: "Only numbers are allowed!" })
          .int()
          .min(1, { message: "Fee percentage is required!" }),
        amountAdded: z.coerce
          .number({ invalid_type_error: "Only numbers are allowed!" })
          .int()
          .min(1, { message: "Amount added is required!" }),
      })
    ),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(deliveryNotesSchema),
    defaultValues: {
      companies: [
        {
          companyName: "",
          feePercentage: "",
          amountAdded: "",
          edit: true,
        },
      ],
    },
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  // Expose handleSubmit function to parent component
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit((data) => {
      const payload = {
        paymentType: "DELIVERY_COMPANIES",

        paymentDeliveryNotes: getValues().companies?.map((company) => ({
          companyName: company?.companyName,
          feePercentage: +company?.feePercentage,
          amountAdded: +company?.amountAdded,
        })),
      };

      return dispatch(
        addPaymentMethods({
          payload: payload,
          successCallBack: onAddPaymentMethod,
        })
      );
    }),
  }));

  const onAddPaymentMethod = (data) => {
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  const {
    fields,
    append,
    update,
    remove: removeCompany,
  } = useFieldArray({
    control: control,
    name: "companies",
  });

  const handleAppend = async () => {
    // Trigger validation for all fields in the form
    const isValid = await trigger();

    // Check if the form is valid
    if (isValid) {
      // If the form is valid, append a new item to the form array
      append({
        companyName: "",
        feePercentage: "",
        amountAdded: "",
        edit: true,
      });
    }
  };

  const handleEdit = (i) => {
    update(i, {
      companyName: fields[i].companyName,
      feePercentage: fields[i].feePercentage,
      amountAdded: fields[i].amountAdded,
      edit: true,
    });
  };

  const { companies } = getValues();

  const handleSave = (i) => {
    update(i, {
      companyName: getValues().companies[i].companyName,
      feePercentage: getValues().companies[i].feePercentage,
      amountAdded: getValues().companies[i].amountAdded,
      edit: false,
    });
  };

  return (
    <React.Fragment>
      {fields?.map((val, i) => (
        <React.Fragment key={val.id}>
          {!val.edit ? (
            <Card padding="px-4 py-3 !mb-5">
              <div className="grid grid-cols-12">
                <div className="col-span-8">
                  <div className="grid grid-cols-12 space-y-5">
                    <div className="col-span-full flex flex-col space-y-1">
                      <h6 className="text-text-secondary text-xs">
                        Delivery Company Name
                      </h6>
                      <span className="text-text-primary text-sm font-medium">
                        {companies[i].companyName}
                      </span>
                    </div>
                    <div className="col-span-6 flex flex-col space-y-1">
                      <h6 className="text-text-secondary text-xs">
                        Fee Percentage
                      </h6>
                      <span className="text-text-primary text-sm font-medium">
                        {companies[i].feePercentage} %
                      </span>
                    </div>
                    <div className="col-span-6 flex flex-col space-y-1">
                      <h6 className="text-text-secondary text-xs">
                        Amount Deducted
                      </h6>
                      <span className="text-text-primary text-sm font-medium">
                        {companies[i].amountAdded} SAR
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 flex flex-col justify-between items-end">
                  <CustomButton
                    width="w-fit"
                    className="leading-none !shadow-none !text-xs"
                    padding="p-1"
                    textColor="text-danger"
                    text="Delete"
                    onClick={() => removeCompany(i)}
                    icon={<TrashIcon />}
                  />
                  <CustomButton
                    onClick={() => handleEdit(i)}
                    className="leading-none !shadow-none !text-sm"
                    text="Edit"
                    width="w-fit"
                    bgColor="bg-trasnparent"
                    border="border"
                    textColor="text-text-primary"
                    icon
                    padding="py-1 px-4"
                  />
                </div>
              </div>
            </Card>
          ) : (
            <div className="space-y-4" key={val.id}>
              <div className="flex items-center justify-between">
                <span className="to-text-primary font-semibold">
                  Company #{i + 1}
                </span>
                {fields?.length > 1 && (
                  <span
                    className="cursor-pointer"
                    onClick={() => removeCompany(i)}
                  >
                    <CrossIcon />
                  </span>
                )}
              </div>
              <InputGroup
                inputName={`companies.${i}.companyName`}
                htmlfor="companyName"
                labelText="Delivery Company Name"
                placeholder="Enter Delivery Company Name"
                register={register}
                errors={errors}
              />
              <InputGroup
                inputName={`companies.${i}.feePercentage`}
                htmlfor="feePercentage"
                labelText="Fee Percentage"
                placeholder="Enter Fee Percentage"
                register={register}
                errors={errors}
              />
              <InputGroup
                inputName={`companies.${i}.amountAdded`}
                htmlfor="amountAdded"
                labelText="Amount Added"
                placeholder="Enter Amount Added"
                register={register}
                errors={errors}
              />

              <CustomButton
                text={"Done"}
                width="w-fit"
                onClick={() => handleSave(i)}
              />
            </div>
          )}
        </React.Fragment>
      ))}
      <CustomButton
        text={"Add Another Company"}
        icon={<Plus color="text-blue-sky" />}
        width="w-fit"
        bgColor="bg-transparent"
        className="shadow-none"
        textColor="text-text-link"
        onClick={handleAppend}
      />
    </React.Fragment>
  );
});

export default DeliveryCompany;
