import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { QrCodeIcon } from "../../../../../common/Icons";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
} from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  getAllItems,
} from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/ItemsModal/ItemsModalSlice";
import { useTranslation } from "react-i18next";

/**
 * RawItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the RawItems component.
 */

const RawItems = forwardRef(
  ({ storesList, categoryList, setIsSubmitting }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);

    // validation schema for add/edit raw item form
    const rawItemSchema = z.object({
      englishname: z
        .string({
          required_error:
            "client_dashboard.directory.items.raw_item.validations.englishname_required_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.raw_item.validations.englishname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.raw_item.validations.englishname_error_max"
        ),
      arabicname: z
        .string({
          required_error:
            "client_dashboard.directory.items.raw_item.validations.arabicname_required_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.raw_item.validations.arabicname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.raw_item.validations.arabicname_error_max"
        ),
      description: z
        .string({
          required_error:
            "client_dashboard.directory.items.raw_item.validations.description_required_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.raw_item.validations.description_error_min"
        )
        .max(
          500,
          "client_dashboard.directory.items.raw_item.validations.description_error_max"
        ),
      store: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.raw_item.validations.store_error_min"
        ),
      itemcost: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.directory.items.raw_item.validations.itemcost_number_only_error",
        })
        .min(
          1,
          "client_dashboard.directory.items.raw_item.validations.itemcost_error_min"
        )
        .max(
          1000000,
          "client_dashboard.directory.items.raw_item.validations.itemcost_error_max"
        ),
      saleprice: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.directory.items.raw_item.validations.saleprice_number_only_error",
        })
        .min(
          1,
          "client_dashboard.directory.items.raw_item.validations.saleprice_error_min"
        )
        .max(
          1000000,
          "client_dashboard.directory.items.raw_item.validations.saleprice_error_max"
        ),
      category: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.raw_item.validations.category_error_min"
        ),
      unitmeasure: z
        .string()
        .min(
          1,
          "client_dashboard.directory.items.raw_item.validations.unitmeasure_error_min"
        ),
      numberunit: z.coerce
        .number({
          invalid_type_error:
            "client_dashboard.directory.items.raw_item.validations.numberunit_number_only_error",
        })
        .min(
          1,
          "client_dashboard.directory.items.raw_item.validations.numberunit_error_min"
        )
        .max(
          1000000,
          "client_dashboard.directory.items.raw_item.validations.numberunit_error_max"
        ),
    });

    // Form handling using react-hook-form
    /**
     * Object destructuring to manage form functionality with react-hook-form.
     * Provides functions and objects for form input validation and usage.
     *
     * @property {Function} register - Function to register form inputs for validation and usage.
     * @property {Function} handleSubmit - Function to handle form submission.
     * @property {Object} formState - Object holding form validation errors.
     * @property {Function} setValues - Function to set form input values.
     */
    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange", // Setting the form mode to trigger validation on input change
      resolver: zodResolver(rawItemSchema),
    });

    useEffect(() => {
      setIsSubmitting(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          type: "RAW",
          image: uploadFileData?.uploadSingleFile?.data?.url,
          englishName: data?.englishname,
          arabicName: data?.arabicname,
          description: data?.description,
          quantity: 300,
          storeId: +data?.store,
          categoryId: +data?.category,
          itemCost: +data?.itemcost,
          salePrice: +data?.saleprice,
          rawItem: {
            measureOfUnit: data?.unitmeasure,
            noMeasureOfUnit: data?.numberunit,
          },
        };

        return dispatch(
          addItem({
            payload: payload,
            successCallBack: onItemUpdate,
          })
        );
      }),
    }));

    const onItemUpdate = (data) => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      dispatch(getAllItems({ payload }));
      dispatch(closeModal());
    };

    return (
      <React.Fragment>
        <CustomFileUploader
          isLoading={uploadFileData?.uploadSingleFile?.isLoading}
        />
        <InputGroup
          labelText={t("client_dashboard.directory.items.raw_item.item_name")}
          htmlFor="englishname"
          inputName="englishname"
          placeholder={t(
            "client_dashboard.directory.items.raw_item.enter_item_name"
          )}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={t(
            "client_dashboard.directory.items.raw_item.arabic_item_name"
          )}
          htmlFor="arabicname"
          inputName="arabicname"
          placeholder={t(
            "client_dashboard.directory.items.raw_item.enter_arabic_item_name"
          )}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={t("client_dashboard.directory.items.raw_item.description")}
          htmlFor="description"
          inputName="description"
          placeholder={t(
            "client_dashboard.directory.items.raw_item.enter_description"
          )}
          register={register}
          errors={errors}
        />
        <SelectGroup
          labelText={t(
            "client_dashboard.directory.items.raw_item.select_store"
          )}
          htmlFor="store"
          inputName="store"
          className="rtl:bg-left"
          options={[
            {
              value: "",
              label: t(
                "client_dashboard.directory.items.raw_item.select_store"
              ),
            },
            ...storesList?.map((option) => ({
              value: option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={t("client_dashboard.directory.items.raw_item.item_cost")}
          htmlFor="itemcost"
          inputName="itemcost"
          placeholder={t(
            "client_dashboard.directory.items.raw_item.enter_item_cost"
          )}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={t("client_dashboard.directory.items.raw_item.sale_price")}
          htmlFor="saleprice"
          inputName="saleprice"
          placeholder={t(
            "client_dashboard.directory.items.raw_item.enter_sale_price"
          )}
          register={register}
          errors={errors}
        />
        <SelectGroup
          labelText={t(
            "client_dashboard.directory.items.raw_item.select_category"
          )}
          htmlFor="category"
          inputName="category"
          className="rtl:bg-left"
          options={[
            {
              value: "",
              label: t(
                "client_dashboard.directory.items.raw_item.select_category"
              ),
            },
            ...categoryList?.map((option) => ({
              value: option?.id,
              label: option?.englishName,
            })),
          ]}
          register={register}
          errors={errors}
        />
        <SelectGroup
          labelText={t(
            "client_dashboard.directory.items.raw_item.unit_of_measure"
          )}
          className="rtl:bg-left"
          htmlFor="unitmeasure"
          inputName="unitmeasure"
          options={unitMeasures}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={t(
            "client_dashboard.directory.items.raw_item.number_unit_of_measure"
          )}
          htmlFor="numberunit"
          inputName="numberunit"
          placeholder={t(
            "client_dashboard.directory.items.raw_item.enter_number_unit_of_measure"
          )}
          register={register}
          errors={errors}
        />

        <CustomButton
          text={t(
            "client_dashboard.directory.items.raw_item.create_sku_number"
          )}
          icon={<QrCodeIcon />}
          textColor="text-text-primary"
          border="border"
        />
      </React.Fragment>
    );
  }
);

export default RawItems;

const unitMeasures = [
  { value: "", label: "Select Unit of Measure" },
  { value: "kg", label: "Kg" },
  { value: "m", label: "Meter" },
  { value: "cm", label: "Cm" },
  { value: "mm", label: "Millimeters" },
  { value: "in", label: "Inch" },
  { value: "m2", label: "Square meters" },
  { value: "m3", label: "Cubic meters" },
  { value: "t", label: "Ton" },
  { value: "g", label: "Gram" },
  { value: "oz", label: "Ounce" },
  { value: "lb", label: "Pound" },
  { value: "unit", label: "Unit" },
  { value: "pc", label: "Piece" },
  { value: "box8", label: "Box of 8" },
  { value: "box12", label: "Box of 12" },
  { value: "box15", label: "Box of 15" },
  { value: "box24", label: "Box of 24" },
  { value: "box40", label: "Box of 40" },
  { value: "box48", label: "Box of 48" },
  { value: "hr", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "month", label: "Month" },
  { value: "report", label: "Report" },
];

// sample usage
// import RawItems from "./path/to/RawItems.jsx";

// <RawItems />
