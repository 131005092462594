import { useState } from "react";
import { ArrowUp } from "../../../../common/Icons";
import { CustomTable, SelectGroup } from "../../../../common/components";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import Layout from "../../Layout/Layout";
import useInventory from "./useInventory";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import { handleExport } from "../../../../../helpers/handleExport";

/**
 * Inventory reports List component
 * Renders table of Stores list
 *
 * @returns {JSX.Element} JSX code for rendering the Inventory reports list table
 */

export default function Inventory() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { storesList, handleSubmit, setValue, inventoryReports } = useInventory(
    startDate,
    endDate
  );

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text="Export"
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          padding="py-2 px-3"
          icon={<ArrowUp className="min-w-6 min-h-6 pb-3" />}
          onClick={() =>
            handleExport("reports/inventory-export-report", "Inventory Report")
          }
        />
      </div>
      {/* filters */}

      <div className="flex items-center justify-between mt-3">
        <div className="flex items-center gap-3">
          {storesList?.length > 0 && (
            <SelectGroup
              className="min-w-44"
              htmlFor="store"
              inputName="store"
              options={[
                { value: "", label: "All Store" },
                ...storesList?.map((option) => ({
                  value: option?.id,
                  label: option?.branchName,
                })),
              ]}
              onChange={(e) => {
                setValue("store", e.target.value);
                handleSubmit();
              }}
            />
          )}
        </div>
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
            handleSubmit();
          }}
        />
      </div>
      {/* data */}
      <CustomTable
        tableHeader={tableHeader}
        tableBody={inventoryReports?.items ?? []}
      />
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.report.name", href: "#", current: false },
  { name: "client_dashboard.report.inventory", href: "#", current: true },
];
const tableHeader = [
  { label: "Item", key: "item_english_name" },
  { label: "Unit of Measure", key: "item_measure_of_unit" },
  { label: "Quantity", key: "totalquantity" },
  {
    label: "Cost",
    key: "item_item_cost",
  },
];
