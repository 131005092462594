import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BoxesIcon,
  CurrencyDollar,
  UserIcon,
  ChartLine,
  SignoutIcon,
  CrossIcon,
  LogoLg,
  CaretUpDown,
  LogoGram,
} from "../../../../common/Icons";
import useSideBar from "./useSideBar";
import DefaultImg from "../../../../../assets/images/DefaultImg.png";
import CustomButton from "../../../../common/components/Button/Button";
import { ROLE } from "../../../../../enums/role";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setActiveMenuItem,
  activeMenuItem,
}) => {
  const { userName, profilePic, storedUserData } = useSideBar();
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad) {
      const savedActiveMenuItem = localStorage.getItem("activeMenuItem");
      if (savedActiveMenuItem) {
        setActiveMenuItem(savedActiveMenuItem);
      }
      setInitialLoad(false);
    }
  }, [initialLoad, setActiveMenuItem]);

  const handleMenuItemClick = (name) => {
    setActiveMenuItem(name);
    localStorage.setItem("activeMenuItem", name); // Save active menu item to local storage
  };

  const handleLogout = () => {
    // Clear the active menu item from local storage
    localStorage.removeItem("activeMenuItem");
    // Additional logout logic here, e.g., redirect to login page
  };

  const getNavigation = () => {
    const baseNavigation = [
      { name: "Overview", href: "/admin/dashboard", icon: BoxesIcon },
      { name: "Clients", href: "/admin/clients", icon: UserIcon },
      { name: "Subscriptions", href: "/admin/subscriptions", icon: CurrencyDollar },
    ];

    if (
      storedUserData?.user?.role === ROLE.SUPER_ADMIN ||
      storedUserData?.user?.role === ROLE.ACCOUNTING
    ) {
      baseNavigation.splice(2, 0, { // Insert "Sales" before "Subscriptions"
        name: "Sales",
        href: "/admin/sales",
        icon: ChartLine,
      });
    }

    return baseNavigation;
  };

  const navigation = getNavigation();

  return (
    <>
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col  ">
        <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-linear-soft backdrop-blur-1xl px-4 pb-4 pt-2">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>
              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <CustomButton
                          type="button"
                          onClick={() => setSidebarOpen(false)}
                          icon={<CrossIcon />}
                          text={<span className="sr-only">Close sidebar</span>}
                          className="-m-2.5 p-2.5"
                          padding="p-0"
                        />
                      </div>
                    </Transition.Child>
                    <div className="flex grow flex-col gap-y-2 overflow-y-auto bg-linear-soft backdrop-blur-1xl px-4 pb-4">
                      <div className="flex py-3 shrink-0 justify-center items-center border-b border-neutral-200 -mx-4">
                        <LogoLg />
                      </div>
                      <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                          <li>
                            <ul className="-mx-2 gap-3">
                              {navigation?.map((item) => (
                                <li className="py-2 my-2" key={item.name}>
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      activeMenuItem === item.name
                                        ? "bg-white text-text-link shadow-sm"
                                        : "text-text-secondary hover:text-text-link hover:bg-white",
                                      "group flex items-center gap-x-2 rounded-md py-2 px-3 text-sm leading-6 font-semibold"
                                    )}
                                    onClick={() =>
                                      handleMenuItemClick(item.name)
                                    }
                                  >
                                    <item.icon
                                      strokeColor={
                                        activeMenuItem === item.name &&
                                        "#0086FF"
                                      }
                                    />
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center">
              <span className="sr-only">Open user menu</span>
              <LogoGram className="mr-2" />
              <div className="flex flex-col">
                <span className="text-sm text-text-primary font-medium pr-6">
                  {userName}
                </span>
                <span className="text-xs font-normal text-gray-500 ">
                  admin/tagmanagement
                </span>
              </div>
              <CaretUpDown />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute px-4 end-0 z-10 mt-2.5 min-w-[220px] origin-top-right rounded-md bg-white py-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item className="text-center">
                  <div className="flex items-center  py-3 border-b border-gray-200">
                    <img
                      src={profilePic || DefaultImg}
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />{" "}
                    <div>
                      <div className="text-sm font-medium text-gray-800 pl-1">
                        {userName}{" "}
                      </div>
                      <div className="text-xs font-normal text-gray-500 pl-1">
                        (Tag Management)
                      </div>
                    </div>
                    <span className="flex-shrink-0 ml-auto mr-2 -mt-4 bg-green-600 w-2.5 h-2.5 rounded-full"></span>
                  </div>
                </Menu.Item>
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    <Link to={item.href} onClick={handleLogout}>
                      <span className="text-red-700 flex items-center gap-2 mt-2">
                        <SignoutIcon />
                        {item.name}
                      </span>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-8">
              <li>
                <ul className="-mx-2 my-1 mt-8">
                  {navigation?.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        onClick={() => handleMenuItemClick(item.name)}
                        className={classNames(
                          activeMenuItem === item.name
                            ? "bg-white text-text-link shadow-sm"
                            : "text-text-secondary hover:bg-white",
                          "group flex items-center gap-x-3 rounded-md py-2 px-4 mb-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          strokeColor={
                            activeMenuItem === item.name && "#0086FF"
                          }
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="mt-auto p-4 ">
                <p className="to-text-secondary  text-center font-normal text-xs mt-4">
                  Copyright © 2023 TagDev{" "}
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

const userNavigation = [{ name: "Sign out", href: "/admin/login" }];
