import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import { Loader, SelectGroup } from "../../../../common/components";
import { useTopClientSalesGraph } from "./useTopClientSales";

const TopClientSales = () => {
  const [startDate, setStartDate] = useState(new Date(2000, 4, 1));
  const [endDate, setEndDate] = useState(new Date(2025, 4, 1));
  const [mappedData, setMappedData] = useState([]);
  const [labels] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  const {
    getclientsSale,
    dropdownOptions,
    saleInvoicesListLoading,
    selectedClientId,
    setSelectedClientId,
  } = useTopClientSalesGraph(startDate, endDate);
  
  useEffect(() => {
    if (getclientsSale) {
      const monthIndex = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11,
      };

      const clientSalesMap = {};

      // Flatten the data and populate client sales
      getclientsSale.forEach(({ month, data }) => {
        data.forEach(({ clientName, monthlySale }) => {
          if (!clientSalesMap[clientName]) {
            clientSalesMap[clientName] = new Array(12).fill(0); // initialize array for 12 months
          }
          clientSalesMap[clientName][monthIndex[month]] = monthlySale;
        });
      });

      // Convert the client sales map to the format required by the chart
      const mapped = Object.keys(clientSalesMap).map((clientName) => ({
        label: clientName,
        data: clientSalesMap[clientName],
        backgroundColor: getRandomColor(),
        borderColor: getRandomColor(),
        borderWidth: 1,
        borderRadius: 10,
        barThickness: "flex",
        categoryPercentage: 0.8,
        barPercentage: 0.6,
      }));

      setMappedData(mapped);
    }
  }, [getclientsSale]);

  function getRandomColor() {
    const colors = ["#53B1FD", "#2BE3B7", "#92E4FE", "#2E90FA"]; // Define more colors as needed
    return colors[Math.floor(Math.random() * colors.length)];
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 10, // set checkbox width
          boxHeight: 10, // set checkbox height
          usePointStyle: true, // use circle instead of square
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawBorder: false, // This will hide the border
        },
      },
    },
  };

  const data = {
    labels,
    datasets: mappedData,
  };

  if (saleInvoicesListLoading) {
    return (
      <div className="flex justify-center py-12">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center space-x-4">
        <span className="flex-grow text-text-primary font-medium text-base">
          Client Sales
        </span>
        <SelectGroup
          htmlFor="role"
          inputName="role"
          options={dropdownOptions}
          className="pr-7 py-2 font-medium w-44"
          textColor="text-text-primary"
          value={selectedClientId} // Set the value prop
          onChange={(e) => {
            const value = e.target.value === "Select Client" ? null : e.target.value;
            setSelectedClientId(value);
          }}
        />
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={handleDateChange}
        />
      </div>
      <div >
        <Bar data={data} options={options} style={{ width: 'full', height: '40%' }} />
      </div>
    </div>
  );
};

export default TopClientSales;
