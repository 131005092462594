/**
 * Manages state for categories.
 * Defines initial state for each category action.
 */

// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createCategoriesApi from "./CategoriesService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to category processes.
 * Each property represents a specific category action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllCategories, getSingleCategory, updateCategory, addCategory, and deleteCategory
 * store data, error, success, loading, and message status for corresponding category actions.
 */
const initialState = {
  getAllCategories: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleCategory: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateCategory: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addCategory: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteCategory: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the category process.
 * These action types are prefixed with the 'categories' base path for better organization.
 */
const BASE = "categories";

export const ActionTypes = {
  GET_ALL_CATEGORIES: `${BASE}/get-all`, // Action type for get all categories
  ADD_CATEGORY: `${BASE}`, // Action type for add category
};

// Creating an instance of the categories service with a base URL 'categories'
const categoriesService = createCategoriesApi("categories");

/**
 * Initiates the getAllCategories process for a category.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting categories
 *    order: order for sorting categories by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllCategories.
 */
export const getAllCategories = createAsyncThunk(
  ActionTypes.GET_ALL_CATEGORIES,

  async ({ payload }, thunkAPI) => {
    const response = await categoriesService.getAllCategories(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getSingleCategory process for a category.
 * @param {object} categoryId - id of the category
 * @param {function} successCallBack - Callback function upon successful getSingleCategory.
 */
export const getSingleCategory = createAsyncThunk(
  `${BASE}/categoryId`,

  async ({ categoryId }, thunkAPI) => {
    const response = await categoriesService.getSingleCategory(categoryId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updateCategory process for a category.
 * @param {object} categoryId - id of the category
 * @param {object} payload - category data to update
 * @param {function} successCallBack - Callback function upon successful updateCategory.
 */
export const updateCategory = createAsyncThunk(
  `${BASE}/categoryId`,

  async ({ categoryId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await categoriesService.updateCategory(
        categoryId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Category updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addCategory process for a category.
 * @param {object} payload - category data to add
 * {
  "name": "Category Name",
  "description": "Category Description"
}
 * @param {function} successCallBack - Callback function upon successful addCategory.
 */
export const addCategory = createAsyncThunk(
  ActionTypes.ADD_CATEGORY,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await categoriesService.addCategory(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Category added successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteCategory process for a category.
 * @param {object} categoryId - id of the category
 * @param {function} successCallBack - Callback function upon successful deleteCategory.
 */
export const deleteCategory = createAsyncThunk(
  `${BASE}/categoryId`,

  async ({ categoryId, successCallBack }, thunkAPI) => {
    try {
      const response = await categoriesService.deleteCategory(categoryId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Category deleted successfully!", "success", async () => {
          const undoResponse = await categoriesService.undoCategory(categoryId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllCategories({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for categories related state management.
 * - `name`: Name of the slice (categories)
 * - `initialState`: Initial state defining properties for various category actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllCategories, updateCategory etc).
 */
export const categoriesSlice = createSlice({
  name: "categories",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllCategories action.
     */
    reset: (state) => {
      state.getAllCategories = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleCategory = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllCategories action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllCategories.pending, (state) => {
        state.getAllCategories.isLoading = true;
        state.getAllCategories.message = "";
        state.getAllCategories.isError = false;
        state.getAllCategories.isSuccess = false;
        state.getAllCategories.data = null;
      })
      /**
       * Updates the state when getAllCategories action is fulfilled/successful.
       * Updates loading and success flags and sets getAllCategories data with the payload.
       */
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.getAllCategories.isLoading = false;
        state.getAllCategories.isSuccess = true;
        state.getAllCategories.data = action.payload;
      })
      /**
       * Updates the state when getAllCategories action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllCategories.rejected, (state, action) => {
        state.getAllCategories.message = action.payload?.message;
        state.getAllCategories.isLoading = false;
        state.getAllCategories.isError = true;
        state.getAllCategories.data = null;
      })
      /**
       * Updates the state while the getSingleCategory action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleCategory.pending, (state) => {
        state.getSingleCategory.isLoading = true;
        state.getSingleCategory.message = "";
        state.getSingleCategory.isError = false;
        state.getSingleCategory.isSuccess = false;
        state.getSingleCategory.data = null;
      })
      /**
       * Updates the state when getSingleCategory action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleCategory data with the payload.
       */
      .addCase(getSingleCategory.fulfilled, (state, action) => {
        state.getSingleCategory.isLoading = false;
        state.getSingleCategory.isSuccess = true;
        state.getSingleCategory.data = action.payload;
      })
      /**
       * Updates the state when getSingleCategory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleCategory.rejected, (state, action) => {
        state.getSingleCategory.message = action.payload?.message;
        state.getSingleCategory.isLoading = false;
        state.getSingleCategory.isError = true;
        state.getSingleCategory.data = null;
      })
      /**
       * Updates the state while the addCategory action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addCategory.pending, (state) => {
        state.addCategory.isLoading = true;
        state.addCategory.message = "";
        state.addCategory.isError = false;
        state.addCategory.isSuccess = false;
        state.addCategory.data = null;
      })
      /**
       * Updates the state when addCategory action is fulfilled/successful.
       * Updates loading and success flags and sets addCategory data with the payload.
       */
      .addCase(addCategory.fulfilled, (state, action) => {
        state.addCategory.isLoading = false;
        state.addCategory.isSuccess = true;
        state.addCategory.data = action.payload;
      })
      /**
       * Updates the state when addCategory action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addCategory.rejected, (state, action) => {
        state.addCategory.message = action.payload?.message;
        state.addCategory.isLoading = false;
        state.addCategory.isError = true;
        state.addCategory.data = null;
      });
  },
});

/**
 * Destructures the reset action from the categoriesSlice actions.
 * - `reset`: Action function to reset the categories related state.
 */
export const { reset } = categoriesSlice.actions;

/**
 * Exports the default reducer generated by categoriesSlice.
 * This reducer handles state updates for categories related actions.
 */
export default categoriesSlice.reducer;
