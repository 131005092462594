import React from "react";
import { useAddNewSubscription } from "./useAddNewSubscription";
import {
  CustomButton,
  InputGroup,
  SelectGroup,
} from "../../../../common/components";
import SelectPlanCards from "../../../../Signup/ClientProfile/SetPlan/SelectPlanCards";

function AddNewSubscription({ closeModal }) {
  const {
    onSubmit,
    handleSubmit,
    register,
    errors,
    staffUserOptions,
    selectedPlan,
    setSelectedPlan,
    activeTab,
    setActiveTab,
    branchCount,
    setBranchCount,
    totalPrice,
    tax,
    discountPrice,
    totalPriceBeforeTax,
    additionalBranchPrice,
  } = useAddNewSubscription({ closeModal });

  return (
    <div className="p-4">
      <p className="text-base font-semibold">Client Detail</p>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 !mt-3">
        {/* company name */}
        <InputGroup
          labelText="Full Name"
          htmlfor="fullname"
          inputName="fullname"
          placeholder="Enter Full Name"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="Arabic Name"
          htmlfor="arabicname"
          inputName="arabicname"
          placeholder="Enter Arabic Name"
          register={register}
          errors={errors}
        />
        {/* company address */}
        <InputGroup
          labelText="Phone Number"
          htmlfor="phonenumber"
          inputName="phonenumber"
          placeholder="Enter Phone Number"
          register={register}
          errors={errors}
        />
        {/* website */}
        <InputGroup
          labelText="Email"
          htmlfor="email"
          inputName="email"
          placeholder="Enter Email"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="Password "
          htmlfor="password"
          inputName="password"
          placeholder="Enter Password "
          register={register}
          errors={errors}
        />
        <p className="text-base font-semibold">Company Detail</p>
        <InputGroup
          labelText="Company Name"
          htmlfor="companyname"
          inputName="companyname"
          placeholder="Enter Company Name"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="Company Address"
          htmlfor="companyaddress"
          inputName="companyaddress"
          placeholder="Company address"
          inputType="textarea"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="Website"
          htmlfor="website"
          inputName="website"
          placeholder="https://lorempipsum.com"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="Commercial Registration"
          htmlfor="commercialregistration"
          inputName="commercialregistration"
          placeholder="Enter Commercial Registration"
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText="VAT "
          htmlfor="vat"
          inputName="vat"
          placeholder="Enter VAT Number"
          register={register}
          errors={errors}
        />
        <h4 className="text-base font-semibold ml-2">Choose Plan</h4>
        <div className="bg-white border rounded-lg p-4 mx-auto">
          <div className="bg-white p-1  justify-between items-start ">
            <SelectPlanCards
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              branchCount={branchCount}
              setBranchCount={setBranchCount}
              additionalBranchPrice={additionalBranchPrice}
            />
          </div>
          <hr className="border-t-2 border-gray-100 px-2 my-4" />

          <InputGroup
            labelText="Start Date"
            htmlfor="startDate"
            inputName="startDate"
            placeholder="DD/MM/YY"
            inputType="date"
            register={register}
            errors={errors}
          />
          <SelectGroup
            labelText="Subscriber Account Manager is"
            htmlFor="subscriptionprovider"
            inputName="subscriptionprovider"
            width="w-fit"
            options={staffUserOptions}
            textColor="text-text-primary"
            className="!py-1 mt-2"
            register={register}
            errors={errors}
          />
        </div>
        <div className="bg-white border rounded-lg p-6 mx-auto mt-8">
          <h4 className="text-text-primary font-semibold mb-5">Summary</h4>
          <div className="flex flex-col w-full mt-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-text-secondary font-normal text-base">
                Total Before Tax
              </span>
              <span className="text-text-primary text-2xs font-medium">
                {totalPriceBeforeTax ?? "0"} SAR
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-text-secondary font-normal text-base">
                Total Discount
              </span>
              <span className="text-text-primary text-2xs font-medium">
                {discountPrice ?? "0"} SAR
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-text-secondary font-normal text-base">
                VAT Amount
              </span>
              <span className="text-text-primary text-2xs font-medium">
                {tax ?? "-"} SAR
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-text-primary font-semibold">Subtotal</span>
              <span className="text-text-link text-lg font-medium">
                {totalPrice ?? "-"} SAR
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-2 pt-4 ">
          <CustomButton
            width="20px"
            text="Cancel"
            bgColor="bg-white"
            textColor="text-text-primary"
            border="border"
            onClick={closeModal}
            className="hover:!bg-gray-200"

          />
          <CustomButton
            width="20px"
            text="Add"
            type="submit"
            textColor="text-white"
          />
        </div>{" "}
      </form>
    </div>
  );
}

export default AddNewSubscription;
