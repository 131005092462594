import React, { useState } from "react";
import { BoxesIcon, DrawerIcon } from "../../../common/Icons";
import { SearchBox } from "../../../../assets/images/svgs";
import { Card, Loader } from "../../../common/components";
import { useTranslation } from "react-i18next";
import useItems from "./useItems";
import { ItemCard } from "./ItemCard";

const lineColors = ["bg-red-500", "bg-gray-500", "bg-green-500"];

/**
 * Items component to display a list/grid of items with switching layout.
 * @returns {JSX.Element} JSX element representing the Items component.
 */
const Items = ({ selectedCategoryCardId, selectedItems, setSelectedItems, }) => {
  const {
    categoryList,
    itemListLoading,
    itemsCount,
    layout,
    itemList,
    setLayout,
    handleCategoryClick,
    selectedCategoryId,
  } = useItems(selectedCategoryCardId);
  const { t } = useTranslation();

  return (
    <div className="mt-5 relative">
      {/* Category List and other UI elements remain unchanged... */}
      <div className="flex justify-between items-center mb-4  top-0">
        <div className="text-lg font-semibold text-text-primary">
          {t("pos_dashboard.items")} ({itemsCount})
        </div>
        {/* switch grid/list */}
        <div className="flex items-center bg-surface-gray  gap-1 p-1">
          <span
            onClick={() => setLayout("grid")}
            className={`cursor-pointer ${layout === "grid" && "bg-white shadow-2xl rounded-sm p-1"
              }`}
          >
            <BoxesIcon className="min-w-8 min-h-2" />
          </span>
          <span
            onClick={() => setLayout("list")}
            className={`cursor-pointer ${layout === "list" && "bg-white shadow-2xl rounded-sm p-1"
              }`}
          >
            <DrawerIcon className="min-w-8 min-h-2" />
          </span>
        </div>
      </div>
      <div className="py-2 mb-4 overflow-auto w-[720px] grayScrollNoBg overflow-x-auto">
        <ul className="flex space-x-4 justify-start gap-3">
          {/* 'All' category button with a click handler */}
          <li
            className={`px-1 py-1 rounded-lg cursor-pointer text-sm font-medium ${selectedCategoryId === null
                ? "bg-gray-200 text-text-primary font-medium"
                : "text-text-secondary"
              } `}
            onClick={() => handleCategoryClick(null)}
          >
            All
          </li>

          {/* Mapping over categoryList to create buttons */}
          {categoryList &&
            categoryList.map((category) => (
              <li
                key={category.id}
                className={`px-1 py-1 cursor-pointer text-sm rounded-lg font-medium ${selectedCategoryId === category.id
                    ? "bg-gray-200 text-text-primary font-medium"
                    : "text-text-secondary"
                  } `}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.englishName}
              </li>
            ))}
        </ul>
      </div>
      <div
        className={`w-full max-h-[500px] min-h-[450px] overflow-auto pr-3 ${layout === "grid"
            ? "grid xl:grid-cols-4 grid-cols-2 gap-3"
            : "flex flex-col gap-4"
          }`}
      >
        {/* Check if itemList is loading */}
        {itemListLoading ? (
          <div>
            {" "}
            <Loader />
          </div>
        ) : (
          <>
            {/* Check if itemList has items */}
            {itemList && itemList?.length > 0 ? (
              itemList &&
              itemList?.map((item, index) => {
                return layout === "grid" ? (
                  <div
                    key={item.id}
                    className={`${layout === "grid" && "rounded-lg "}`}
                  >
                    <ItemCard
                      layout={layout}
                      englishName={item.englishName}
                      salePrice={item.salePrice}
                      image={item.image}
                      index={index}
                      setSelectedItems={setSelectedItems}
                      selectedItems={selectedItems}
                      color={lineColors[index % lineColors.length]}
                      type={item.type}
                      id={item.id}
                      variablePairs={item.variablePairs}
                      variableItem={item.variableItem}
                      quantity={item.quantity}
                    />
                  </div>
                ) : (
                  <Card
                    key={item.id}
                    padding="p-1"
                    className="border-none !shadow-none rounded-none"
                  >
                    <ItemCard
                      layout={layout}
                      englishName={item.englishName}
                      salePrice={item.salePrice}
                      image={item.image}
                      index={index}
                      setSelectedItems={setSelectedItems}
                      selectedItems={selectedItems}
                      color={lineColors[index % lineColors.length]}
                      type={item.type}
                      id={item.id}
                      variablePairs={item.variablePairs}
                      variableItem={item.variableItem}
                      quantity={item.quantity}
                    />
                  </Card>
                );
              })
            ) : (
              <div className="flex flex-col items-center py-10 my-4 min-w-[600px]  max-w-[800px] rounded-lg">
                <img
                  src={SearchBox}
                  alt="Search Box"
                  className="ml-52 mt-24 rounded-lg"
                />
                <span className="text-text-primary text-xl ml-40 font-bold">
                  No Item to display
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Items;
