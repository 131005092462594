import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { generateExpenseReport } from "../../../../../features/Reports/ReportsSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";

/**
 * Custom hook for handling purchase reports related functionalities

 /**
   *
   * @returns {Object}
   *   storesList: Array containing store information for all stores
   *   supplierList: Array containing supplier information for all supplier
 */
const useExpenses = (startDate, endDate) => {
  const dispatch = useDispatch();

  // get purchase reports list from the redux store
  const { data: expenseReports } = useSelector(
    (state) => state.reports.generateExpenseReport
  );

  // get store list for all stores from the redux store
  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.stores.getAllStores
  );

  // validation schema for add/edit store form
  const expenseReportsSchema = z.object({
    store: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .int()
      .optional(),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(expenseReportsSchema),
  });

  /**
   * Handler function for form submission to update store information.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   *                        Example: storeId, supplierId
   */

  const onSubmit = async (data) => {
    const payload = {
      storeId: data?.store,
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
    };
    dispatch(generateExpenseReport({ payload: payload }));
  };

  useEffect(() => {
    handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!storesList) dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @returns {Object}
   *
   * Usage Example:
   * const { storesList, supplierList } = useExpenses();
   *
   * storesList:
   *  Example usage: {storesList && storesList.map(store => store.name)}
   * regiser:
   *  Example usage: <input {...register("email", { required: true })}
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.email && <span>Email is required</span>}
   */
  return {
    storesList,
    storeListLoading,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    isSubmitting,
    watch,
    expenseReports,
  };
};

export default useExpenses;
