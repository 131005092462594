/**
 * Manages state for pos user.
 * Defines initial state for each user action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createPosUserApi from "./PosUsers";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to pos user processes.
 * Each property represents a specific pos user action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllPosUsers, signUp, verifyOtp, sendOtp, forgetPassword, createPassword, resetPassword, createAccount, and getAllPosUsersAndSignUpWithOAuth
 * store data, error, success, loading, and message status for corresponding pos user actions.
 */
const initialState = {
  getAllPosUsers: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSinglePosUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addPosUser: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the pos user process.
 * These action types are prefixed with the 'pos-user' base path for better organization.
 */
const BASE = "pos-user";

export const ActionTypes = {
  GET_ALL_POS_USERS: `${BASE}/get-all`, // Action type for get all users
  ADD_POS_USER: `${BASE}`, // Action type for get all users
};

// Creating an instance of the pos-user service with a base URL 'auth'
const posUserService = createPosUserApi("pos-user");

/**
 * Initiates the getAllPosUsers process for a user.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting users
 *    order: order for sorting users by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllPosUsers.
 */
export const getAllPosUsers = createAsyncThunk(
  ActionTypes.GET_ALL_POS_USERS,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload }, thunkAPI) => {
    const response = await posUserService.getAllPosUsers(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getAllPosUsers process for a user.
 * @param {object} userId id of the user
 * @param {function} successCallBack - Callback function upon successful getSinglePosUser.
 */
export const getSinglePosUser = createAsyncThunk(
  `${BASE}/userId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId }, thunkAPI) => {
    const response = await posUserService.getSinglePosUser(userId);
    return response?.data?.data;
  }
);

/**
 * Initiates the updatePostUser process for a user.
 * @param {object} userId id of the user
 * @param {object} payload
 *    {
      fullName: name of the user
      email: email of the user
      fastAccessCode: fast access code of the user
      password: password of the user
      role: role of the user
      accessToDashboard: access to dashboard true or false
      accessToPos: access to dashboard true or false
}
 * @param {function} successCallBack - Callback function upon successful updatePosUser.
 */
export const updatePosUser = createAsyncThunk(
  `${BASE}/userId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posUserService.updatePosUser(userId, payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("User updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPosUser process for a user.
 * @param {object} userId id of the user
 * @param {object} payload
 *    {
      fullName: name of the user
      email: email of the user
      fastAccessCode: fast access code of the user
      password: password of the user
      role: role of the user
      accessToDashboard: access to dashboard true or false
      accessToPos: access to dashboard true or false
}
 * @param {function} successCallBack - Callback function upon successful addPosUser.
 */
export const addPosUser = createAsyncThunk(
  ActionTypes.ADD_POS_USER,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await posUserService.addPosUser(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("User added successfully!");

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addPosUser process for a user.
 * @param {object} userId id of the user
 * @param {object} payload
 *    {
      fullName: name of the user
      email: email of the user
      fastAccessCode: fast access code of the user
      password: password of the user
      role: role of the user
      accessToDashboard: access to dashboard true or false
      accessToPos: access to dashboard true or false
}
 * @param {function} successCallBack - Callback function upon successful addPosUser.
 */
export const deletePosUser = createAsyncThunk(
  `${BASE}/userId`,

  /**
   * Explanation of createAsyncThunk method of Redux-toolkit used for every function here with example
   * Creates an asynchronous Redux Thunk action creator using createAsyncThunk.
   * This function encapsulates an asynchronous operation and dispatches actions based on the operation's lifecycle.
   * @param {string} typePrefix - A string prefix for action types related to this thunk.
   * @param {function} payloadCreator - A function that initiates the asynchronous logic.
   * @param {object} options - Additional options for customizing the behavior of the created thunk action.
   * @returns {function} An asynchronous action creator (thunk) to dispatch actions based on the asynchronous operation's lifecycle.
   }
   */

  // createAsyncThunk(
  //   typePrefix,
  //   payloadCreator,
  //   options
  // );

  async ({ userId, successCallBack }, thunkAPI) => {
    try {
      const response = await posUserService.deletePosUser(userId);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("User deleted successfully!", "success", async () => {
          const undoResponse = await posUserService.undoPosUser(userId);
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllPosUsers({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for pos-user related state management.
 * - `name`: Name of the slice (posUser)
 * - `initialState`: Initial state defining properties for various pos user actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllPosUsers, updatePosUser etc).
 */
export const posUserSlice = createSlice({
  name: "posUser",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllPosUser action.
     */
    reset: (state) => {
      state.getAllPosUsers = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSinglePosUser = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllPosUsers action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllPosUsers.pending, (state) => {
        state.getAllPosUsers.isLoading = true;
        state.getAllPosUsers.message = "";
        state.getAllPosUsers.isError = false;
        state.getAllPosUsers.isSuccess = false;
        state.getAllPosUsers.data = null;
      })
      /**
       * Updates the state when getAllPosUsers action is fulfilled/successful.
       * Updates loading and success flags and sets getAllPosUsers data with the payload.
       */
      .addCase(getAllPosUsers.fulfilled, (state, action) => {
        state.getAllPosUsers.isLoading = false;
        state.getAllPosUsers.isSuccess = true;
        state.getAllPosUsers.data = action.payload;
      })
      /**
       * Updates the state when getAllPosUsers action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllPosUsers.rejected, (state, action) => {
        state.getAllPosUsers.message = action.payload?.message;
        state.getAllPosUsers.isLoading = false;
        state.getAllPosUsers.isError = true;
        state.getAllPosUsers.data = null;
      })
      /**
       * Updates the state while the getSinglePosUser action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSinglePosUser.pending, (state) => {
        state.getSinglePosUser.isLoading = true;
        state.getSinglePosUser.message = "";
        state.getSinglePosUser.isError = false;
        state.getSinglePosUser.isSuccess = false;
        state.getSinglePosUser.data = null;
      })
      /**
       * Updates the state when getSinglePosUser action is fulfilled/successful.
       * Updates loading and success flags and sets getSinglePosUser data with the payload.
       */
      .addCase(getSinglePosUser.fulfilled, (state, action) => {
        state.getSinglePosUser.isLoading = false;
        state.getSinglePosUser.isSuccess = true;
        state.getSinglePosUser.data = action.payload;
      })
      /**
       * Updates the state when getSinglePosUser action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSinglePosUser.rejected, (state, action) => {
        state.getSinglePosUser.message = action.payload?.message;
        state.getSinglePosUser.isLoading = false;
        state.getSinglePosUser.isError = true;
        state.getSinglePosUser.data = null;
      })
      /**
       * Updates the state while the addPosUser action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addPosUser.pending, (state) => {
        state.addPosUser.isLoading = true;
        state.addPosUser.message = "";
        state.addPosUser.isError = false;
        state.addPosUser.isSuccess = false;
        state.addPosUser.data = null;
      })
      /**
       * Updates the state when addPosUser action is fulfilled/successful.
       * Updates loading and success flags and sets addPosUser data with the payload.
       */
      .addCase(addPosUser.fulfilled, (state, action) => {
        state.addPosUser.isLoading = false;
        state.addPosUser.isSuccess = true;
        state.addPosUser.data = action.payload;
      })
      /**
       * Updates the state when addPosUser action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addPosUser.rejected, (state, action) => {
        state.addPosUser.message = action.payload?.message;
        state.addPosUser.isLoading = false;
        state.addPosUser.isError = true;
        state.addPosUser.data = null;
      });
  },
});

/**
 * Destructures the reset action from the posUserSlice actions.
 * - `reset`: Action function to reset the pos-user related state.
 */

// sample usage

// import { reset } from './posUserSlice';

// Using the reset action
// const resetAuth = () => {
//   // Dispatching the reset action to reset pos-user state
//   store.dispatch(reset());
// };

export const { reset } = posUserSlice.actions;

/**
 * Exports the default reducer generated by posUserSlice.
 * This reducer handles state updates for pos-user related actions.
 */

// sample usage

// import posUserReducer from "./posUserSlice";

// Using the default reducer
// const initialState = {
// Your initial state for pos-user actions
// };

// Creating a store with the posUserReducer handling pos-user related actions
// const store = configureStore({
// reducer: {
// auth: posUserReducer,
// other reducers...
// },
// preloadedState: {
// auth: initialState,
// other preloaded states...
// },
// });
export default posUserSlice.reducer;
