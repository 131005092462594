import React from "react";
import CustomAdminModel from "../../../../../common/components/AdminModal/CustomAdminModal";
import CustomButton from "../../../../../common/components/Button/Button";
import { useDispatch } from "react-redux";
import {
  deleteClientProfile,
  getClientProfile,
} from "../../../../../../features/Clients/clientSlice";
import { useNavigate } from "react-router-dom";

function DeleteClient({
  data,
  isDeleteModalOpen,
  setDeleteModalOpen,
  setAllData,
}) {
  const navigate = useNavigate();
  const handleDismiss = () => {
    setDeleteModalOpen(false);
  };
  const dispatch = useDispatch();
  // Function to handle the delete action
  const handleDelete = () => {
    // Add your delete logic here
    dispatch(
      deleteClientProfile({
        profileId: data?.id,
        successCallBack: onSuccessDeleteClient,
      })
    );
    // After deletion, you might want to close the modal as well
    setDeleteModalOpen(false);
  };
  const onSuccessDeleteClient = (data) => {
    navigate("/admin/clients");
    dispatch(
      getClientProfile({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: {
            id: "DESC",
          },
          condition: {},
          attributes: {},
        },
        successCallBack: onGetClientProfileSuccess,
      })
    );
  };
  const onGetClientProfileSuccess = (data) => {
    setAllData(data?.data?.data);
  };
  return (
    <CustomAdminModel
      isOpen={isDeleteModalOpen}
      onClose={() => setDeleteModalOpen(false)}
      title="Delete Client"
      border="border-b"
      width="min-[280px]:w-[80%] min-[1080px]:w-[420px]"
      cancelButtonText="Dismiss"
      saveButtonText="Delete"
      cancelButtonBgColor="bg-gray-100"
      saveButtonBgColor="bg-danger"
      cancelButtonTextColor="text-text-primary"
      saveButtonTextColor="text-white"
    >
      <div className="p-5 ">
        <div className="pb-6">Are you sure you want to delete this client?</div>
        <div className="pb-6">
          This action cannot be undone. If you proceed, all your data and
          settings will be lost.
        </div>
        <div className="bg-surface-gray p-5">
          <h2 className="text-base font-semibold pb-3">Client Details</h2>
          <div className="w-[340px] h-5 justify-between items-center inline-flex">
            <span className="text-text-secondary font-normal text-base">
              Name
            </span>
            <span className="text-text-primary font-medium text-sm">
              {data?.clientname ?? data?.profile?.fullName}
            </span>
          </div>
          <div className="w-[340px] h-5 justify-between items-center inline-flex">
            <span className="text-text-secondary font-normal text-base">
              Phone Number
            </span>
            <span className="text-text-primary font-medium text-sm">
              {data?.phonenumber ?? data?.profile?.phoneNumber}
            </span>
          </div>
          <div className="w-[340px] h-5 justify-between items-center inline-flex">
            <span className="text-text-secondary font-normal text-base">
              Email
            </span>
            <span className="text-text-primary font-medium text-sm">
              {data?.email}
            </span>
          </div>
        </div>
        <div className="pt-4 border-t border-gray-300">
          <div className="flex justify-end space-x-2">
            <CustomButton
              onClick={handleDismiss}
              width="20px"
              text="Dismiss"
              bgColor="bg-white"
              textColor="text-text-primary"
              border="border"
              hoverTextColor="text-text-primary"
              className="hover:!bg-gray-200"
            />
            <CustomButton
              onClick={handleDelete}
              type="button"
              width="20px"
              text="Delete"
              bgColor="bg-danger"
              textColor="text-white"
              className="hover:!text-black"
            />
          </div>
        </div>
      </div>
    </CustomAdminModel>
  );
}

export default DeleteClient;
