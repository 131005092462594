import React, { useState } from "react";
import InputGroup from "../common/components/InputGroup/InputGroup";
import { Eye, EyeSlash } from "../common/Icons/fontawesome";
import { CheckCircle, GreenCheckCircle } from "../common/Icons";
import CustomButton from "../common/components/Button/Button";

/**
 * CreatePasswordForm component.
 * Renders a form for creating a new password.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.register - Function for registering input fields.
 * @param {Function} props.onSubmit - Function to handle form submission.
 * @param {Object} props.errors - Object containing form validation errors.
 * @param {Object} props.isPasswordValid - Object indicating password validity.
 * @param {Function} props.validatePassword - Function to validate the password.
 * @returns {JSX.Element} JSX code for rendering the CreatePasswordForm component.
 */

const CreatePasswordForm = ({
  register,
  onSubmit,
  errors,
  isPasswordValid,
  validatePassword,
  isSubmitting,
  showEmailField = false,
  t,
  email,
}) => {
  const [passwordFieldsVisibility, setPasswordFieldsVisibility] = useState({
    password: false,
    confirmPassword: false,
    email: false,
  });
  const allValid = showEmailField
    ? Object.values(isPasswordValid).every(Boolean) && isPasswordValid.email
    : Object.values(isPasswordValid).every(Boolean);

  /**
   * Toggles the visibility of the password field.
   * @param {string} fieldName - The name of the password field.
   */
  const togglePasswordVisibility = (fieldName) => {
    setPasswordFieldsVisibility((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  return (
    <form onSubmit={onSubmit} className="space-y-4 mt-10">
      {/* new password */}
      {showEmailField && (
        <InputGroup
          htmlfor="email"
          labelText={t(`create_password_form.email`)}
          inputName="email"
          placeholder={t(`create_password_form.enter_email`)}
          inputType="email"
          register={register}
          errors={errors}
          value={email} // Set the value of the input to the email prop
          readOnly={true} // Make the input field read-only
        />
      )}
      <InputGroup
        htmlfor="newPassword"
        labelText={t(`create_password_form.new_password`)}
        inputName="newPassword"
        placeholder={t(`create_password_form.enter_new_password`)}
        inputType={passwordFieldsVisibility.password ? "text" : "password"}
        register={register}
        errors={errors}
      >
        <span
          className="absolute end-3 top-6 items-center cursor-pointer"
          onClick={() => togglePasswordVisibility("password")}
        >
          {passwordFieldsVisibility.password ? (
            <Eye color="gray-700" />
          ) : (
            <EyeSlash color="gray-700" />
          )}
        </span>
      </InputGroup>

      {/* confirm password */}
      <InputGroup
        htmlfor="confirmPassword"
        labelText={t(`create_password_form.confirm_password`)}
        inputName="confirmPassword"
        placeholder={t(`create_password_form.enter_password_again`)}
        inputType={
          passwordFieldsVisibility.confirmPassword ? "text" : "password"
        }
        register={register}
        errors={errors}
      >
        <span
          className="absolute end-3 top-6 items-center cursor-pointer"
          onClick={() => togglePasswordVisibility("confirmPassword")}
        >
          {passwordFieldsVisibility?.confirmPassword ? (
            <Eye color="gray-700" />
          ) : (
            <EyeSlash color="gray-700" />
          )}
        </span>
      </InputGroup>
      {/* validations */}
      <ul className="list-none space-y-3 !mt-4">
        <li className="flex items-center gap-2">
          {isPasswordValid?.minLength ? (
            <GreenCheckCircle />
          ) : (
            <CheckCircle className="fill-custom-gray" />
          )}{" "}
          <span className="text-sm text-text-secondary font-normal">
            {t("create_password_form.use_at_least_8_characters")}
          </span>
        </li>
        <li className="flex items-center gap-2">
          {isPasswordValid?.specialChar ? (
            <GreenCheckCircle />
          ) : (
            <CheckCircle className="fill-custom-gray" />
          )}{" "}
          <span className="text-sm text-text-secondary font-normal">
            {t("create_password_form.use_mix_of_letters_numbers_special_chars")}
          </span>
        </li>
      </ul>
      <CustomButton
        text={t(`create_password_form.create_password`)}
        type="submit"
        className="!mt-8"
        isLoading={isSubmitting}
      />
    </form>
  );
};

export default CreatePasswordForm;

// Example usage of CreatePasswordForm component:
// import CreatePasswordForm from '.path/CreatePasswordForm'
// <CreatePasswordForm
//   onSubmit={handleSubmit} // Function to handle form submission
//   register={register} // Function for registering input fields
//   errors={formErrors} // Object containing form validation errors
//   isPasswordValid={passwordValidity} // Object indicating password validity
//   validatePassword={validatePwd} // Function to validate the password
// />
