import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardsList,
  getPaymentMethods,
  getSinglePaymentMethod,
} from "../../../../../../features/Payments/PaymentsSlice";

/**
 * Custom hook for handling payment methods related functionalities

 /**
   *
   * @returns {Object}
   *   cardsList: Array containing card information for all cards
   *   paymentMethodList: Array containing payment  methods information for all payment methods
   *   singleDetailLoading: Loading state for single payment method
   *   isLoading: Loading state for the list of all payment methods
   *   handleDelete: A funcion for handling delete functionality for a single payment method
   *   deliveryDetail: Array of objects containing details for delivery companies payment method
   *   cardDetail: Object containing information for all the added cards
   *   pointDetail: Object containing information for points payment method
   *   
 */
const usePaymentsList = (paymentId) => {
  const dispatch = useDispatch();

  // get payment methods list from the redux store
  const { data: paymentMethodsList, isLoading } = useSelector(
    (state) => state.payments.getPaymentMethods
  );
  // get single payment methods from the redux store
  const { data: singlePaymentMethod, isLoading: singleDetailLoading } =
    useSelector((state) => state.payments.getSinglePaymentMethod);
  // get payment cards list from the redux store
  const { data: cardsList } = useSelector(
    (state) => state.payments.getCardsList
  );

  const pointDetail = singlePaymentMethod
    ? {
        "Value Per Point": `${singlePaymentMethod?.paymentPoints?.valuePerPoint} SAR`,
        "Point Per Value": `${singlePaymentMethod?.paymentPoints?.pointPerValue} Points`,
        "Minimum Replacement Application":
          singlePaymentMethod?.paymentPoints?.minReplacementApplication,
        "Minimum Replacement Points":
          singlePaymentMethod?.paymentPoints?.minReplacementPoints,
      }
    : null;

  const deliveryDetail = singlePaymentMethod
    ? singlePaymentMethod?.paymentDeliveryNotes?.map((detail) => ({
        companyName: detail?.delivery?.companyName,
        feePercentage: detail?.delivery?.feePercentage,
        amountAdded: detail?.delivery?.amountAdded,
      }))
    : null;

  const cardDetail = singlePaymentMethod?.paymentCard?.cards?.reduce(
    (acc, card, index) => {
      acc["[image]" + card.image] = card.name;
      return acc;
    },
    {}
  );

  useEffect(() => {
    dispatch(getPaymentMethods());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getCardsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentId) dispatch(getSinglePaymentMethod({ id: paymentId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  /**
   *
   * @returns {Object}
   *
   * Usage Example:
   * const { storesList, supplierList } = usePaymentsList();
   *
   * storesList:
   *  Example usage: {storesList && storesList.map(store => store.name)}
   * regiser:
   *  Example usage: <input {...register("email", { required: true })}
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.email && <span>Email is required</span>}
   */
  return {
    paymentMethodsList,
    singlePaymentMethod,
    pointDetail,
    deliveryDetail,
    isLoading,
    cardsList,
    cardDetail,
    singleDetailLoading,
  };
};

export default usePaymentsList;
