import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import Loader from "../../../../common/components/Loader/Loader";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import CustomTable from "../../../../common/components/CustomTable/CustomTable";
import Layout from "../../Layout/Layout";
import CustomModal from "../../../../common/components/CustomModel/CustomModel";
import DetailCard from "../../../../common/components/DetailCard/DetailCard";
import useCategoriesList from "./useCategoriesList";
import AddEditCategory from "./AddEditCategories/AddEditCategories";
import { EditIcon, EyeIcon, TrashIcon } from "../../../../common/Icons";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";

/**
 * Categories List component
 * Renders table of Categories list
 *
 * @returns {JSX.Element} JSX code for rendering the categories list table
 */

export default function CategoriesList() {
  const { t } = useTranslation();
  const [isInputChanged, setIsInputChanged] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    categoryList,
    categoryDetail,
    register,
    categoryListLoading,
    errors,
    handleSubmit,
    singleCategoryLoading,
    setValue,
    isSubmitting,
    watch,
    deleteCategoryItem,
    currentPage,
    setCurrentPage,
    clearErrors,
  } = useCategoriesList(selectedItem?.id);

  const handleViewCategory = (type) => {
    dispatch(
      openModal({
        title: `Category ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleCategoriesActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type} Category`,
        type: type,
      })
    );
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text={t("client_dashboard.directory.category.add_category")}
          width="w-fit"
          bgColor="!bg-surface-primary"
          hoverTextColor="text-white"
          className="hover:!text-white"
          icon={<Plus color="white" />}
          onClick={() => handleCategoriesActions("add")}
        />
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* categories list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={categoryList}
          setSelectedItem={setSelectedItem}
          loading={categoryListLoading}
          callback={(item) => ({
            ...item,
            itemDetails: {
              name: item?.englishName,
              image: item?.image,
            },
          })}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewCategory("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleCategoriesActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleCategoriesActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={categoryList ? categoryList?.length : 0}
        />
      </div>

      {/* modal */}
      <CustomModal
        onAdd={handleSubmit}
        onEdit={() => handleCategoriesActions("edit")}
        onDelete={deleteCategoryItem}
        loading={isSubmitting}
        isInputChanged={isInputChanged}
      >
        {/* conditionally render modal for different category actions */}
        {(modalState?.type === "edit" || modalState?.type === "add") && (
          <AddEditCategory
            category={selectedItem ? selectedItem : null}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            setIsInputChanged={setIsInputChanged}
          />
        )}
        {/* detail modal */}
        {modalState?.type === "detail" &&
          (singleCategoryLoading ? (
            <Loader />
          ) : (
            <DetailCard detail={categoryDetail} />
          ))}
        {/* delete modal */}
        {modalState?.type === "delete" && (
          <p className="text-text-primary text-base font-normal">
            Are you sure you want to delete this category?{" "}
          </p>
        )}
      </CustomModal>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.directory.name", href: "#", current: false },
  {
    name: "client_dashboard.directory.category.name",
    href: "#",
    current: true,
  },
];
// table headings for Categories table
const tableHeader = [{ label: "Category", key: "itemDetails" }];
