import React, { Suspense, lazy, useState } from "react";
import Layout from "../../Layout/Layout";
import { Card, Breadcrumb, Tabs, Loader } from "../../../../common/components";
// import dynamic components with react lazy components
const UpdateProfile = lazy(() => import("./UpdateProfile/UpdateProfile.jsx"));
const BusinesssInfo = lazy(() => import("./BusinessInfo/BusinessInfo.jsx"));
const Subscription = lazy(() => import("./Subscription/Subscription.jsx"));
const ChangePassword = lazy(() =>
  import("./ChangePassword/ChangePassword.jsx")
);

/**
 * Management component.
 * Renders a management interface with tabs for different settings sections.
 *
 * @returns {JSX.Element} JSX code for rendering the Management component.
 */
const Settings = () => {
  const [activeTab, setActiveTab] = useState("UpdateProfile");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const tabComponents = {
    UpdateProfile: UpdateProfile,
    BusinessInfo: BusinesssInfo,
    Subscription: Subscription,
    ChangePassword: ChangePassword,
  };

  const renderTabContent = () => {
    const Component = tabComponents[activeTab] || null;
    return Component ? <Component /> : null;
  };

  return (
    <Layout>
      <Breadcrumb pages={pages} />
      <Card className="mt-6">
        <Tabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
        <Suspense fallback={<Loader />}>{renderTabContent()}</Suspense>
      </Card>
    </Layout>
  );
};

export default Settings;

const pages = [
  { name: "client_dashboard.management.name", href: "#", current: false },
  {
    name: "client_dashboard.management.setting",
    href: "#",
    current: true,
  },
];

const tabs = [
  {
    name: "client_dashboard.management.settings.profile.name",
    value: "UpdateProfile",
    href: "#",
    current: true,
  },
  {
    name: "client_dashboard.management.settings.business_info.name",
    value: "BusinessInfo",
    href: "#",
    current: false,
  },
  {
    name: "client_dashboard.management.settings.subscription",
    value: "Subscription",
    href: "#",
    current: false,
  },
  {
    name: "client_dashboard.management.settings.password.name",
    value: "ChangePassword",
    href: "#",
    current: false,
  },
];

// Sample Usage:
// import Management from "path/to/Management";
// <Management />
