import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  addPromotions,
  deletePromotions,
  updatePromotion,
  getSinglePromotion,
  getAllPromotions,
} from "../../../../../../features/Promotions/PromotionsSlice";
import { getAllStores } from "../../../../../../features/Stores/StoresSlice";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import { getAllItems } from "../../../../../../features/Items/ItemsSlice";
import { formatDateTime } from "../../../../../../helpers/dateFormatter";
import { generatePayloadCondition } from "../../../../../../helpers/generatePayloadCondition";

const usePromotionsList = (userId) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  // get single promtotion information from the store
  const { data: singlePromotion, isLoading: singlePromotionLoading } =
  useSelector((state) => state.promotion.getSinglePromotion);
  // get user list for all promotion from the store
  const { data: promotionList, isLoading: promotionListLoading } = useSelector(
    (state) => state.promotion.getAllPromotions
  );
  // get item list for all promotion from the store
  const { data: itemList } = useSelector((state) => state.items.getAllItems);
  const { data: storesList } = useSelector(
    (state) => state.stores.getAllStores
  );
  const { query } = useSelector((state) => state.search);

  // get only required user data from userList
  const customerDetail = singlePromotion
  ? {
      "Promotion Name": singlePromotion.name ?? "_",
      "Start Date": singlePromotion.startDate ?? "_",
      "End Date": singlePromotion.endDate ?? "_",
      "Promotion Type": singlePromotion.type ?? "_",
      "Promotion Value":
        singlePromotion.type === "PERCENTAGE"
          ? `${singlePromotion.value}%`
          : singlePromotion.value ?? "_",
      store: singlePromotion.store?.branchName ?? "_",
      product: singlePromotion.isAllItem
        ? "All Item"
        : singlePromotion.items
            ?.map(item => item.englishName)
            .join(", ") ?? "_",
    }
  : null;

  const items = z.object({
    label: z.string().optional(),
    value: z.coerce
      .number({
        invalid_type_error:
          "client_dashboard.management.users.validations.only_numbers_error",
      })
      .int()
      .min(1, {
        message: "client_dashboard.management.users.validations.store_error",
      }),
  });
  
  const addEditUserSchema = z.object({
    name: z
      .string()
      .min(
        3,
        "client_dashboard.management.promotions.validations.name_error_min"
      )
      .max(
        100,
        "client_dashboard.management.promotions.validations.name_error_max"
      ),
    startDate: z
      .string()
      .min(
        1,
        "client_dashboard.management.promotions.validations.start_date_error"
      ),
    endDate: z
      .string()
      .min(
        1,
        "client_dashboard.management.promotions.validations.end_date_error"
      ),
    type: z
      .string()
      .min(1, "client_dashboard.management.promotions.validations.type_error"),
    value: z.coerce
      .number()
      .min(1, "client_dashboard.management.promotions.validations.value_error"),
    store: z.coerce.number().int().min(1, {
      message: "client_dashboard.invoices.purchase.validations.store_error",
    }),
    selectedItems: z.array(items),
    allproduct: z.boolean().optional(),
  });
  
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(addEditUserSchema),
  });

  const allProductChecked = watch("allproduct");

  useEffect(() => {
    if (allProductChecked) {
      trigger("product");
    }
  }, [allProductChecked, trigger]);

  const onSubmit = async (data) => {
    const payload = {
      name: data.name,
      startDate: formatDateTime(data.startDate),
      endDate: formatDateTime(data.endDate),
      type: data.type,
      value: parseInt(data.value, 10),
      storeId: parseInt(data.store, 10),
      isAllItem: allProductChecked,
      itemIds: data?.selectedItems?.map((id) => id?.value) || [],
    };
    if (userId && modalState?.type === "edit")
      dispatch(
        updatePromotion({
          userId,
          payload,
          successCallBack: onUserUpdate,
        })
      );
    else {
      dispatch(
        addPromotions({
          payload,
          successCallBack: onUserUpdate,
        })
      );
    }
  };

  const onUserUpdate = (data) => {
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPromotions({ payload }));
    dispatch(closeModal());
  };


  const deleteUser = () => {
    if (userId)
      dispatch(deletePromotions({ userId, successCallBack: onUserUpdate }));
    dispatch(closeModal());
  };

  /**
   * Handles the api call for getting a specific promotion
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} userId id of the promotion
   */

  useEffect(() => {
    if (userId) dispatch(getSinglePromotion({ userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };

    if (!storesList) {
      dispatch(getAllStores({ payload }));
    }
    if (!itemList) {
      dispatch(getAllItems({ payload }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
   let searchParams = ["name","type","store.branchName","status"];

    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
  attributes: {},
    };

    dispatch(getAllPromotions({ payload }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage,query]);

  return {
    promotionList,
    customerDetail,
    promotionListLoading,
    singlePromotionLoading,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    deleteUser,
    storesList,
    itemList,
    watch,
    currentPage,
    clearErrors,
    setCurrentPage,
    control,
    singlePromotion,
  };
};

export default usePromotionsList;
