import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { InputGroup } from "../../../../../common/components";
import { useDispatch } from "react-redux";
import {
  addPaymentMethods,
  getPaymentMethods,
} from "../../../../../../features/Payments/PaymentsSlice";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";

// eslint-disable-next-line no-empty-pattern
const CardPayment = forwardRef(({ cardsList, setIsSubmitting }, ref) => {
  const dispatch = useDispatch();
  // validation schema for card payment
  const cardPaymentSchema = z
    .object({
      // Dynamically create the schema for each card
      ...cardsList?.reduce((acc, card) => {
        acc[card.cardName] = z.boolean();
        return acc;
      }, {}),
    })
    .refine(
      (data) => {
        // Check if at least one card is selected
        return Object.values(data)?.some((value) => value);
      },
      {
        message: "Please select at least one card to continue!",
        path: ["cardSelection"], // Specify a path for the error
      }
    );

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} setValues - Function to set form input values.
   */
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    resolver: zodResolver(cardPaymentSchema),
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  // Expose handleSubmit function to parent component
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit((data) => {
      debugger; // Add debugger statement
      console.log(data); // Log the form data

      const selectedCards = Object.keys(data)
        .filter((key) => data[key])
        .map((key) => {
          const card = cardsList.find((card) => card.cardName === key);
          return {
            cardName: key,
            cardImage: card ? card.cardImage : null,
          };
        });

      const payload = {
        paymentType: "CARD",
        paymentCard: selectedCards,
      };

      console.log(payload); // Log the payload

      return dispatch(
        addPaymentMethods({
          payload: payload,
          type: "add",
          successCallBack: onAddPaymentMethod,
        })
      );
    }),
  }));

  const onAddPaymentMethod = (data) => {
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  return (
    <div className="space-y-6">
      <p className="font-medium text-sm to-text-primary !mb-8">
        Choose method allowed in you system
      </p>
      {cardsList?.map((card) => (
        <div className="flex items-center gap-2" key={card.cardName}>
          <div className="border-2 border-border-primary rounded-md w-9 h-6 flex items-center justify-center">
            <img
              src={card.cardImage}
              alt={card.cardName}
              width={25}
              loading="lazy"
            />
          </div>
          <InputGroup
            inputType="checkbox"
            inputName={card.cardName}
            labelText={card.cardName}
            htmlFor={card.cardName}
            register={register}
            errors={errors}
            className="flex justify-between items-center !mt-0 w-full"
            bgColor="focus:ring-opacity-0"
          />
        </div>
      ))}
      {errors.cardSelection && (
        <p className="text-red-500 text-sm">{errors.cardSelection.message}</p>
      )}
    </div>
  );
});

export default CardPayment;
