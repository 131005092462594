/**
 * Manages state for stocktaking.
 * Defines initial state for each stocktaking action.
 */

// Import necessary functions and modules from Redux Toolkit

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createStocktakingApi from "./StocktakingService";
import { showToast } from "../../components/common/utils/showToast.util";
import store from "../../store/store";

/**
 * Initial state structure defining various properties related to stocktaking processes.
 * Each property represents a specific stocktaking action/status with its associated data, error, success, loading, and message.
 * Properties such as getAllStocktakings, addStocktaking, updateStocktaking, deleteStocktaking, etc.
 * store data, error, success, loading, and message status for corresponding stocktaking actions.
 */
const initialState = {
  getAllStocktakings: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleStocktaking: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addStocktaking: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateStocktaking: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteStocktaking: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the stocktaking process.
 * These action types are prefixed with the 'stocktaking' base path for better organization.
 */
const BASE = "stocktaking";

export const ActionTypes = {
  GET_ALL_STOCKTAKINGS: `${BASE}/get-all`, // Action type for get all stocktakings
  ADD_STOCKTAKING: `${BASE}`, // Action type for add stocktaking
  UPDATE_STOCKTAKING: `${BASE}/update`, // Action type for update stocktaking
  DELETE_STOCKTAKING: `${BASE}/delete`, // Action type for delete stocktaking
};

// Creating an instance of the stocktaking service with a base URL 'stock-taking'
const stocktakingService = createStocktakingApi("stock-taking");

/**
 * Initiates the getAllStocktakings process for stocktaking.
 * @param {object} payload
 *    page: current page
 *    pageSize: number of pages
 *    sortColumn: column id for sorting stocktakings
 *    order: order for sorting stocktakings by asc or desc
 *    condition: {}
 *    attributes:{}
 * @param {function} successCallBack - Callback function upon successful getAllStocktakings.
 */
export const getAllStocktakings = createAsyncThunk(
  ActionTypes.GET_ALL_STOCKTAKINGS,

  async ({ payload }, thunkAPI) => {
    const response = await stocktakingService.getAllStocktakings(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getSingleStocktaking process for stocktaking.
 * @param {object} stocktakingId id of the stocktaking
 * @param {function} successCallBack - Callback function upon successful getSingleStocktaking.
 */
export const getSingleStocktaking = createAsyncThunk(
  `${BASE}/stocktakingId`,

  async ({ stocktakingId }, thunkAPI) => {
    const response = await stocktakingService.getSingleStocktaking(
      stocktakingId
    );
    return response?.data?.data;
  }
);

/**
 * Initiates the updateStocktaking process for stocktaking.
 * @param {object} stocktakingId id of the stocktaking
 * @param {object} payload
 *    {
      // properties to update
}
 * @param {function} successCallBack - Callback function upon successful updateStocktaking.
 */
export const updateStocktaking = createAsyncThunk(
  ActionTypes.UPDATE_STOCKTAKING,

  async ({ stocktakingId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await stocktakingService.updateStocktaking(
        stocktakingId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Stocktaking updated successfully!");

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addStocktaking process for stocktaking.
 * @param {object} payload
 *    {
      // properties to add
}
 * @param {function} successCallBack - Callback function upon successful addStocktaking.
 */
export const addStocktaking = createAsyncThunk(
  ActionTypes.ADD_STOCKTAKING,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await stocktakingService.addStocktaking(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);

        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteStocktaking process for stocktaking.
 * @param {object} stocktakingId id of the stocktaking
 * @param {function} successCallBack - Callback function upon successful deleteStocktaking.
 */
export const deleteStocktaking = createAsyncThunk(
  ActionTypes.DELETE_STOCKTAKING,

  async ({ stocktakingId, successCallBack }, thunkAPI) => {
    try {
      const response = await stocktakingService.deleteStocktaking(
        stocktakingId
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Stocktaking deleted successfully!", "success", async () => {
          const undoResponse = await stocktakingService.undoStocktaking(
            stocktakingId
          );
          if (undoResponse?.data?.Succeeded) {
            const payload = {
              page: 1,
              pageSize: 10,
              sortColumn: "id",
              order: {
                id: "DESC",
              },
              condition: {},
              attributes: {},
            };
            store.dispatch(getAllStocktakings({ payload }));
          }
        });

        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for stocktaking related state management.
 * - `name`: Name of the slice (stocktaking)
 * - `initialState`: Initial state defining properties for various stocktaking actions.
 * - `reducers`: Defines actions to modify the state (e.g., reset)
 * - `extraReducers`: Defines how the state should be updated based on asynchronous actions (getAllStocktakings, updateStocktaking etc).
 */
export const stocktakingSlice = createSlice({
  name: "stocktaking",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllStocktaking action.
     */
    reset: (state) => {
      state.getAllStocktakings = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleStocktaking = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllStocktakings action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllStocktakings.pending, (state) => {
        state.getAllStocktakings.isLoading = true;
        state.getAllStocktakings.message = "";
        state.getAllStocktakings.isError = false;
        state.getAllStocktakings.isSuccess = false;
        state.getAllStocktakings.data = null;
      })
      /**
       * Updates the state when getAllStocktakings action is fulfilled/successful.
       * Updates loading and success flags and sets getAllStocktakings data with the payload.
       */
      .addCase(getAllStocktakings.fulfilled, (state, action) => {
        state.getAllStocktakings.isLoading = false;
        state.getAllStocktakings.isSuccess = true;
        state.getAllStocktakings.data = action.payload;
      })
      /**
       * Updates the state when getAllStocktakings action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllStocktakings.rejected, (state, action) => {
        state.getAllStocktakings.message = action.payload?.message;
        state.getAllStocktakings.isLoading = false;
        state.getAllStocktakings.isError = true;
        state.getAllStocktakings.data = null;
      })
      /**
       * Updates the state while the getSingleStocktaking action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleStocktaking.pending, (state) => {
        state.getSingleStocktaking.isLoading = true;
        state.getSingleStocktaking.message = "";
        state.getSingleStocktaking.isError = false;
        state.getSingleStocktaking.isSuccess = false;
        state.getSingleStocktaking.data = null;
      })
      /**
       * Updates the state when getSingleStocktaking action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleStocktaking data with the payload.
       */
      .addCase(getSingleStocktaking.fulfilled, (state, action) => {
        state.getSingleStocktaking.isLoading = false;
        state.getSingleStocktaking.isSuccess = true;
        state.getSingleStocktaking.data = action.payload;
      })
      /**
       * Updates the state when getSingleStocktaking action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleStocktaking.rejected, (state, action) => {
        state.getSingleStocktaking.message = action.payload?.message;
        state.getSingleStocktaking.isLoading = false;
        state.getSingleStocktaking.isError = true;
        state.getSingleStocktaking.data = null;
      })
      /**
       * Updates the state while the addStocktaking action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addStocktaking.pending, (state) => {
        state.addStocktaking.isLoading = true;
        state.addStocktaking.message = "";
        state.addStocktaking.isError = false;
        state.addStocktaking.isSuccess = false;
        state.addStocktaking.data = null;
      })
      /**
       * Updates the state when addStocktaking action is fulfilled/successful.
       * Updates loading and success flags and sets addStocktaking data with the payload.
       */
      .addCase(addStocktaking.fulfilled, (state, action) => {
        state.addStocktaking.isLoading = false;
        state.addStocktaking.isSuccess = true;
        state.addStocktaking.data = action.payload;
      })
      /**
       * Updates the state when addStocktaking action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addStocktaking.rejected, (state, action) => {
        state.addStocktaking.message = action.payload?.message;
        state.addStocktaking.isLoading = false;
        state.addStocktaking.isError = true;
        state.addStocktaking.data = null;
      })
      /**
       * Updates the state while the updateStocktaking action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateStocktaking.pending, (state) => {
        state.updateStocktaking.isLoading = true;
        state.updateStocktaking.message = "";
        state.updateStocktaking.isError = false;
        state.updateStocktaking.isSuccess = false;
        state.updateStocktaking.data = null;
      })
      /**
       * Updates the state when updateStocktaking action is fulfilled/successful.
       * Updates loading and success flags and sets updateStocktaking data with the payload.
       */
      .addCase(updateStocktaking.fulfilled, (state, action) => {
        state.updateStocktaking.isLoading = false;
        state.updateStocktaking.isSuccess = true;
        state.updateStocktaking.data = action.payload;
      })
      /**
       * Updates the state when updateStocktaking action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateStocktaking.rejected, (state, action) => {
        state.updateStocktaking.message = action.payload?.message;
        state.updateStocktaking.isLoading = false;
        state.updateStocktaking.isError = true;
        state.updateStocktaking.data = null;
      })
      /**
       * Updates the state while the deleteStocktaking action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deleteStocktaking.pending, (state) => {
        state.deleteStocktaking.isLoading = true;
        state.deleteStocktaking.message = "";
        state.deleteStocktaking.isError = false;
        state.deleteStocktaking.isSuccess = false;
        state.deleteStocktaking.data = null;
      })
      /**
       * Updates the state when deleteStocktaking action is fulfilled/successful.
       * Updates loading and success flags and sets deleteStocktaking data with the payload.
       */
      .addCase(deleteStocktaking.fulfilled, (state, action) => {
        state.deleteStocktaking.isLoading = false;
        state.deleteStocktaking.isSuccess = true;
        state.deleteStocktaking.data = action.payload;
      })
      /**
       * Updates the state when deleteStocktaking action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteStocktaking.rejected, (state, action) => {
        state.deleteStocktaking.message = action.payload?.message;
        state.deleteStocktaking.isLoading = false;
        state.deleteStocktaking.isError = true;
        state.deleteStocktaking.data = null;
      });
  },
});

/**
 * Destructures the reset action from the stocktakingSlice actions.
 * - `reset`: Action function to reset the stocktaking related state.
 */

// sample usage

// import { reset } from './stocktakingSlice';

// Using the reset action
// const resetStocktaking = () => {
//   // Dispatching the reset action to reset stocktaking state
//   store.dispatch(reset());
// };

export const { reset } = stocktakingSlice.actions;

/**
 * Exports the default reducer generated by stocktakingSlice.
 * This reducer handles state updates for stocktaking related actions.
 */

// sample usage

// import stocktakingReducer from "./stocktakingSlice";

// Using the default reducer
// const initialState = {
// Your initial state for stocktaking actions
// };

// Creating a store with the stocktakingReducer handling stocktaking related actions
// const store = configureStore({
// reducer: {
// stocktaking: stocktakingReducer,
// other reducers...
// },
// preloadedState: {
// stocktaking: initialState,
// other preloaded states...
// },
// });
export default stocktakingSlice.reducer;
