import React from "react";
import { Card } from "../common/components";
import { Dollars, Headphone, Tag } from "../../assets/images";

const WhyTagDev = () => {
  return (
    <div className="md:py-16 py-10 1xl:px-29 px-4">
      <div className="space-y-3 text-center">
        <h2 className="text-text-primary font-semibold xs:text-5xl text-3xl xs:leading-wider">
          Why TagDev?
        </h2>
        <p className="text-text-secondary font-medium text-base">
          Your All-in-One POS Solution – Elevate your retail efficiency and
          growth
        </p>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mx-auto w-fit md:mt-14 mt-10">
        {cards.map((card) => (
          <Card key={card.heading}>
            <div className="flex gap-3">
              <img
                src={card.icon}
                alt="Headphone"
                className="flex-shrink-0 w-12 h-11"
              />
              <div className="">
                <h5 className="text-lg font-semibold text-text-primary">
                  {card.heading}
                </h5>
                <p className="text-text-secondary font-normal text-base">
                  {card.text}
                </p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default WhyTagDev;

const cards = [
  {
    heading: "24/7 Support",
    text: "Phone, live Chat, and email support at no cost.",
    icon: Headphone,
  },
  {
    heading: "No Hidden Cost",
    text: "Don’t worry about hidden cost because no price hikes either.",
    icon: Dollars,
  },
  {
    heading: "Free Data Migration",
    text: "Switching platforms? Our team will get you set up.",
    icon: Tag,
  },
];
