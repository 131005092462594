import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "../../../../../common/Icons/fontawesome";
import {
  CustomButton,
  InputGroup,
  SelectGroup,
} from "../../../../../common/components";
import {
  addSaleInvoice,
  getAllSaleInvoices,
} from "../../../../../../features/Invoices/SaleInvoices/SaleInvoicesSlice";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import AddNewCustomer from "./AddNewCustomer";
import { getAllItems } from "../../../../../../features/Items/ItemsSlice";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../../../common/utils/showToast.util";

/**
 * AddInvoice component.
 * Renders a form to add or edit a sale invoice
 *
 * @returns {JSX.Element} JSX code for rendering the AddInvoice component
 */

// validation schema for add/edit sale invoice form
const addInvoiceSchema = z.object({
  customer: z.coerce.number().int().min(1, {
    message: "client_dashboard.invoices.sales.validations.customer_error",
  }),
  store: z.coerce.number().int().min(1, {
    message: "client_dashboard.invoices.sales.validations.store_error",
  }),
  date: z.string().min(1, {
    message: "client_dashboard.invoices.sales.validations.date_error",
  }),
  paymentmethod: z.string().min(1, {
    message: "client_dashboard.invoices.sales.validations.paymentmethod_error",
  }),
  items: z.array(
    z.object({
      itemid: z.coerce.number().min(1, {
        message: "client_dashboard.invoices.sales.validations.item_error",
      }),
      quantity: z.coerce
        .number()
        .min(1, {
          message: "client_dashboard.invoices.sales.validations.quantity_error",
        })
        .max(1000000, {
          message:
            "client_dashboard.invoices.sales.validations.quantity_error_max",
        }),
      unitprice: z.coerce.number().min(1, {
        message: "client_dashboard.invoices.sales.validations.unitprice_error",
      }),
      beforetax: z.coerce.number().min(1, {
        message: "client_dashboard.invoices.sales.validations.beforetax_error",
      }),
      taxamount: z.coerce.number().min(1, {
        message: "client_dashboard.invoices.sales.validations.taxamount_error",
      }),
      subtotal: z.coerce.number().min(1, {
        message: "client_dashboard.invoices.sales.validations.subtotal_error",
      }),
      note: z.string().min(1, {
        message: "client_dashboard.invoices.sales.validations.note_error",
      }),
    })
  ),
});

const AddInvoice = forwardRef(
  (
    {
      saleInvoice,
      customerList,
      itemList,
      storesList,
      setIsFormSubmitting,
      itemListLoading,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const modalState = useSelector((state) => state.modal);
    const selectedItemRef = useRef(null);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const dispatch = useDispatch();

    console.log({ saleInvoice });

    const defaultValues = useMemo(
      () => ({
        date: saleInvoice?.saleDate ?? "",
        paymentmethod: saleInvoice?.paymentMethod ?? "",
        customer: saleInvoice?.customer?.id ?? "",
        store: saleInvoice?.store?.id ?? "",
        "items.0.itemid":
          saleInvoice?.saleInvoiceItem?.variableItem?.id ||
          saleInvoice?.saleInvoiceItem?.item?.id,
        "items.0.quantity": saleInvoice?.saleInvoiceItem?.quantity ?? "",
        "items.0.beforetax": saleInvoice?.saleInvoiceItem?.beforeTax ?? "",
        "items.0.unitprice": saleInvoice?.saleInvoiceItem?.unitPrice ?? "",
        "items.0.taxamount": saleInvoice?.saleInvoiceItem?.taxAmount ?? "",
        "items.0.subtotal": saleInvoice?.saleInvoiceItem?.subTotal ?? "",
        "items.0.note": saleInvoice?.saleInvoiceItem?.note ?? "",
      }),
      [saleInvoice]
    );

    useEffect(() => {
      if (saleInvoice) {
        setIsDuplicating(true);
      }
    }, [saleInvoice]);

    // Form handling using react-hook-form
    const {
      register,
      handleSubmit,
      control,
      setValue,
      watch,
      formState: { errors, isSubmitting },
    } = useForm({
      resolver: zodResolver(addInvoiceSchema),
      defaultValues: {
        items: [{}],
      },
    });

    useEffect(() => {
      // set the default values for each field to edit customer using 'setValue' method of react-hook-form
      if (saleInvoice) {
        defaultValues &&
          Object.keys(defaultValues).forEach((key) =>
            setValue(key, defaultValues[key])
          );
      }
    }, [saleInvoice, setValue, defaultValues, itemList]);

    const { fields } = useFieldArray({
      control: control,
      name: "items",
    });

    // Expose handleSubmit function to parent component
    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: handleSubmit((data) => {
          const payload = {
            saleDate: data?.date,
            paymentMethod: data?.paymentmethod,
            storeId: data?.store,
            customerId: data?.customer,
            invoiceItem: {
              quantity: +data?.items[0]?.quantity,
              unitPrice: +data?.items[0]?.unitprice,
              beforeTax: +data?.items[0]?.beforetax,
              taxAmount: +data?.items[0]?.taxamount,
              subTotal: +data?.items[0]?.subtotal,
              note: data?.items[0]?.note,
              itemId:
                selectedItemRef?.current?.type === "VARIABLE"
                  ? selectedItemRef?.current?.id
                  : data?.items[0]?.itemid,
              vItemId: data?.items[0]?.itemid,
            },
          };

          return dispatch(
            addSaleInvoice({
              payload,
              successCallBack: onSaleInvoiceUpdate,
            })
          );
        }),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    );
    useEffect(() => {
      setIsFormSubmitting(!isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const onSaleInvoiceUpdate = () => {
      if (modalState?.type === "duplicate") {
        showToast("Invoice duplicated successfully!");
      }
      if (modalState?.type === "add") {
        showToast("Invoice created successfully!");
      }
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      const payloadItems = {
        page: 1,
        pageSize: 1000,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };

      dispatch(getAllItems({ payload: payloadItems }));
      dispatch(getAllSaleInvoices({ payload }));
      dispatch(closeModal());
    };

    const selectedItem = watch("items");

    const selectedStore = watch("store");

    const storeId = selectedStore;

    useEffect(() => {
      const payload = {
        page: 1,
        pageSize: 1000,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {
          stores: { id: storeId },
        },
        attributes: {},
      };
      if (storeId) dispatch(getAllItems({ payload }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeId]);

    useEffect(() => {
      selectedItem.map((s, i) => {
        const curItem = itemList.find(
          (r) => (r?.vItemId || r.id) === +s.itemid
        );
        if (!curItem) return null;
        selectedItemRef.current = curItem;
        if (+s.itemid === +(curItem?.vItemId || curItem.id)) {
          setValue(`items.${[i]}.itemid`, curItem?.vItemId || curItem.id);
          setValue(`items.${[i]}.unitprice`, curItem.perUnitPrice);
          setValue("availableQuantity", curItem.quantity);
        }
        return null;
      });

      setValue(
        "items.[0].beforetax",
        +selectedItem[0].quantity * +selectedItem[0].unitprice
      );
      setValue("items.[0].taxamount", selectedItem[0]?.beforetax * 0.15);
      setValue(
        "items.[0].subtotal",
        selectedItem[0]?.beforetax + selectedItem[0]?.taxamount
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedItem)]);

    return (
      <React.Fragment>
        <SelectGroup
          labelText={"client_dashboard.invoices.sales.customer"}
          htmlFor="customer"
          inputName="customer"
          options={[
            {
              value: "",
              label: "client_dashboard.invoices.sales.select_customer",
            },
            ...customerList?.map((option) => ({
              value: option?.id,
              label: option?.name,
            })),
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        {!isDuplicating && isNewCustomer && (
          <AddNewCustomer setIsNewCustomer={setIsNewCustomer} />
        )}
        {!isNewCustomer && (
          <CustomButton
            text={"client_dashboard.invoices.sales.add_new_customer"}
            icon={<Plus color="text-blue-sky" />}
            width="w-fit"
            bgColor="bg-transparent"
            className="shadow-none"
            textColor="text-text-link"
            onClick={() => setIsNewCustomer(true)}
          />
        )}

        <SelectGroup
          labelText={"client_dashboard.invoices.sales.store"}
          htmlFor="store"
          inputName="store"
          options={[
            {
              value: "",
              label: "client_dashboard.invoices.sales.select_store",
            },
            ...storesList?.map((option) => ({
              value: +option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        {/* date */}
        <InputGroup
          labelText={"client_dashboard.invoices.sales.sale_date"}
          inputType="date"
          htmlFor="date"
          inputName="date"
          register={register}
          errors={errors}
        />
        <SelectGroup
          labelText={"client_dashboard.invoices.sales.payment_method"}
          htmlFor="paymentmethod"
          inputName="paymentmethod"
          options={paymentMethods}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        <hr className="bg-border-primary" />

        <h6 className="text-text-primary font-semibold text-base">
          {t("client_dashboard.invoices.sales.item_details")}
        </h6>
        {fields?.map((item, index) => (
          <div className="space-y-3" key={item.id}>
            <SelectGroup
              labelText={"client_dashboard.invoices.sales.item"}
              htmlFor={`itemid-${index}`}
              inputName={`items.${index}.itemid`}
              isLoading={itemListLoading}
              options={[
                {
                  value: "",
                  label: "client_dashboard.invoices.sales.select_item",
                },
                ...itemList?.map((option) => ({
                  value: option?.vItemId ?? option?.id,
                  label: option?.slug ?? option?.englishName,
                })),
              ]}
              register={register}
              errors={errors}
              className="rtl:bg-left"
            />
            {/* available quantity */}
            <InputGroup
              labelText={"client_dashboard.invoices.sales.available_quantity"}
              htmlFor={`available quantity`}
              inputName={`availableQuantity`}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            {/* quantity */}
            <InputGroup
              labelText={"client_dashboard.invoices.sales.quantity"}
              htmlFor={`quantity-${index}`}
              inputName={`items.${index}.quantity`}
              placeholder={"client_dashboard.invoices.sales.enter_quantity"}
              register={register}
              errors={errors}
            />
            {/* Unit price */}
            <InputGroup
              labelText={"client_dashboard.invoices.sales.unit_price"}
              htmlFor={`unitprice-${index}`}
              inputName={`items.${index}.unitprice`}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            <InputGroup
              labelText={"client_dashboard.invoices.sales.before_vat"}
              htmlFor={`beforetax-${index}`}
              inputName={`items.${index}.beforetax`}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            <InputGroup
              labelText={"client_dashboard.invoices.sales.vat_amount"}
              htmlFor={`taxamount-${index}`}
              inputName={`items.${index}.taxamount`}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />

            {/* sub total */}
            <InputGroup
              htmlFor={`subtotal-${index}`}
              inputName={`items.${index}.subtotal`}
              labelText={"client_dashboard.invoices.sales.total"}
              register={register}
              errors={errors}
              className={`w-full pointer-events-none`}
              bgColor={`!bg-surface-gray`}
            />
            {/* note */}
            <InputGroup
              htmlFor={`note-${index}`}
              inputName={`items.${index}.note`}
              labelText={"client_dashboard.invoices.sales.note"}
              placeholder={"client_dashboard.invoices.sales.enter_note"}
              register={register}
              errors={errors}
            />
          </div>
        ))}
      </React.Fragment>
    );
  }
);

export default AddInvoice;

const paymentMethods = [
  {
    value: "",
    label: "client_dashboard.invoices.expense.select_payment_method",
  },
  { label: "Cash", value: "Cash" },
  { label: "Mada", value: "Mada" },
  { label: "Visa", value: "Visa" },
  { label: "Bank transfer", value: "Bank transfer" },
];
