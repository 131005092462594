import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { GoogleIcon } from "../common/Icons";
import { CustomButton } from "../common/components";
import { useGoogleLogin } from "@react-oauth/google";
import { loginAndSignUpWithOAuth } from "../../features/Auth/AuthSlice";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../context/UserContext";

const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUser, setUserProfile, setToken } = useUserContext();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      dispatch(
        loginAndSignUpWithOAuth({
          loginType: "google",
          accessToken: tokenResponse?.access_token,
          successCallBack: (data) => {
            if (data?.user) {
              if (!data.user.isProfileCompleted) {
                navigate("/create-account");
                return;
              }
              if (!data.user.isBusinessDetailCompleted) {
                navigate("/business-profile");
                return;
              }

              // Save user data, token, profile, etc.
              updateUser(data.user);
              setUserProfile(data.user.profile);
              setToken(data.token);

              // Check user role and redirect accordingly
              if (data.user.role === "SUPER_ADMIN") {
                navigate("/admin/dashboard");
              } else if (data.user.role === "CLIENT") {
                navigate("/dashboard");
              }

              console.log("Login/Signup with Google succeeded:", data);
            } else {
              // Handle case where user data is not available
              console.error("User data not found:", data);
            }
          },
        })
      );
      // fetching userinfo can be done on the client or the server
    },
    clientId: "your_client_id",
    onFailure: (error) => {
      console.log("Error:", error);
    },
    isSignedIn: true, // Specify if you need to automatically check if the user is signed in
  });

  return (
    <CustomButton
      onClick={handleGoogleLogin}
      border="border"
      textColor="text-text-primary"
      text={t(`login.login_with_google`)}
      icon={<GoogleIcon />}
    />
  );
};

export default GoogleLoginButton;
