import React from "react";
import {
  Card,
  CurrencySign,
  TrashIcon,
  VerticalBlackCoin,
} from "../../../../common/Icons";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../../../common/components/ProgressBar/ProgressBar";
import { useSplitEqually } from "./useSplitEqually";
import { CustomButton } from "../../../../common/components";
import { Plus } from "../../../../common/Icons/fontawesome";
import EquallyByCard from "./EquallyByCard";
import EquallyByPoints from "./EquallyByPoints";

function SplitEqually({
  setCheckOutVisible,
  setIsModalOpen,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen,
}) {
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    parts,
    togglePartSelection,
    addPart,
    deletePart,
    selectedTotal,
    paymentMethodsList,
    discountAmount,
    taxAmount,
    totalAmount,
    total,
    handleCheckout,
    remainingParts,
    paidParts,
    totalParts,
    pricePerParts,
    checkoutType,
  } = useSplitEqually(
    setCheckOutVisible,
    setOrderCheckoutPaid,
    setIsCheckoutModalOpen
  );

  const { t } = useTranslation();

  const getIconComponent = (type) => {
    switch (type) {
      case "CASH":
        return CurrencySign;
      case "CARD":
        return Card;
      case "POINTS":
        return VerticalBlackCoin;
      default:
        return null;
    }
  };

  const paymentMethodOptions = paymentMethodsList
    ?.filter((method) => method.type !== "DELIVERY_COMPANIES")
    ?.map((method) => ({
      method: method.type.toLowerCase(),
      label: t(`pos_dashboard.${method.type.toLowerCase()}`),
      IconComponent: getIconComponent(method.type),
    }));

  const renderSelectedPaymentMethod = () => {
    switch (selectedPaymentMethod) {
      case "cash":
        return null;
      case "card":
        return <EquallyByCard />;
      case "points":
        return <EquallyByPoints />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      <div className="font-medium text-sm mb-4">
        {" "}
        {t("pos_dashboard.items")}
      </div>
      <div className="max-h-[160px] h-auto overflow-auto">
        {checkoutType === "SPLIT_EQUALLY"
          ? Array.from({ length: totalParts }).map((_, index) => (
              <div
                key={index}
                className={`flex items-center justify-between mb-2 border p-2 rounded-lg ${
                  paidParts > index ? "bg-yellow-200" : ""
                }`}
              >
                <div className="flex items-center rtl:gap-2">
                  <input
                    type="checkbox"
                    checked={remainingParts > index}
                    disabled={paidParts > index}
                    onChange={() => togglePartSelection(index)}
                    className="form-checkbox h-3 w-3 rtl:bg-left"
                  />
                  <span className="ml-2 font-medium text-sm">
                    Part {index + 1}{" "}
                    {paidParts > index ? "(Paid)" : ""}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="font-medium text-sm">{pricePerParts} SAR</span>
                  {/* Hide TrashIcon in SPLIT_EQUALLY mode */}
                </div>
              </div>
            ))
          : parts?.map((part, index) => (
              <div
                key={index}
                className={`flex items-center justify-between mb-2 border p-2 rounded-lg ${
                  paidParts ? "bg-yellow-200" : ""
                }`}
              >
                <div className="flex items-center rtl:gap-2">
                  <input
                    type="checkbox"
                    checked={part.selected}
                    onChange={() => togglePartSelection(index)}
                    className="form-checkbox h-3 w-3 rtl:bg-left"
                  />
                  <span className="ml-2 font-medium text-sm">
                    Part {index + 1} {paidParts ? "(Paid)" : ""}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="font-medium text-sm">
                    {part?.price ?? pricePerParts} SAR
                  </span>
                  {index !== 0 && (
                    <CustomButton
                      onClick={() => deletePart(index)}
                      bgColor="bg-transparent"
                      icon={<TrashIcon color="red" />}
                      width="w-fit"
                      padding="px-2 py-1"
                    />
                  )}
                </div>
              </div>
            ))}

        {checkoutType !== "SPLIT_EQUALLY" && remainingParts > 0 && (
          <div className="flex items-center justify-between mb-2 border p-2 rounded-lg">
            <div className="flex items-center rtl:gap-2">
              <input
                type="checkbox"
                checked={false}
                disabled
                className="form-checkbox h-3 w-3 rtl:bg-left"
              />
              <span className="ml-2 font-medium text-sm">
                Additional Part {parts.length + 1} (Remaining)
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-medium text-sm">{pricePerParts} SAR</span>
            </div>
          </div>
        )}
      </div>
      {checkoutType !== "SPLIT_EQUALLY" && (
        <div className="flex justify-center">
          <CustomButton
            icon={<Plus color="black" />}
            width="w-fit"
            border="border"
            bgColor="!bg-white"
            text={t("pos_dashboard.add_part")}
            padding="px-2 py-2"
            textColor="text-text-primary"
            onClick={addPart}
          />
        </div>
      )}
      <p className=" my-2 text-sm font-medium">Choose Payment Method</p>
      {/* Payment Method Options */}
      <div className="grid grid-cols-2 gap-4 mb-2">
        {paymentMethodOptions?.map((option) => (
          <div
            key={option.method}
            className={`flex items-center justify-between border p-4 rounded-lg cursor-pointer ${
              selectedPaymentMethod === option.method
                ? "border-blue-500 bg-blue-100"
                : "border-gray-300 bg-white"
            }`}
            onClick={() => setSelectedPaymentMethod(option.method)}
          >
            <div className="flex items-center gap-2">
              {option.IconComponent && (
                <option.IconComponent
                  className={`text-lg ${
                    selectedPaymentMethod === option.method
                      ? "text-blue-600"
                      : "text-gray-500"
                  }`}
                />
              )}
              <span
                className={`ml-2 ${
                  selectedPaymentMethod === option.method
                    ? "text-blue-700 font-medium"
                    : "text-gray-700 font-medium"
                }`}
              >
                {option.label}
              </span>
            </div>
            <div
              className={`flex items-center justify-center h-5 w-5 rounded-full border-2 ${
                selectedPaymentMethod === option.method
                  ? "border-blue-500 bg-blue-500"
                  : "border-gray-300"
              }`}
            >
              {selectedPaymentMethod === option.method && (
                <div className="h-3 w-3 bg-white rounded-full"></div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Render the selected payment method's component */}
      {renderSelectedPaymentMethod()}
      <div className="bg-white  rounded-lg   p-2 w-full ">
        <hr className="border mb-2" />
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {t("pos_dashboard.sub_total")}
          </span>
          <span className="text-sm font-medium">{totalAmount || 0} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {t("pos_dashboard.discount")}
          </span>
          <span className="text-sm font-medium">{discountAmount || 0} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {t("pos_dashboard.vat")}
          </span>
          <span className="text-sm font-medium">{taxAmount || 0} SAR</span>
        </div>
        <div className="flex justify-between pb-2">
          <span className="text-sm text-gray-500 ">
            {t("pos_dashboard.total")}
          </span>
          <span className="text-sm font-medium">{total || 0} SAR</span>
        </div>
        <hr className="border mb-2" />
      </div>
      <div className="border p-2 mt-2 rounded-lg">
        <ProgressBar
          current={selectedTotal || 0}
          total={total || 0}
          trackColor="bg-gray-300"
          textColor="text-text-primary"
          height="h-1.5"
          rounded="rounded"
          label={t("pos_dashboard.paying")}
          showLabel={true}
          showProgressText={true}
        />
      </div>
      <div className="w-full pt-2">
        <CustomButton
          text={t("pos_dashboard.checkout")}
          type="button"
          onClick={handleCheckout}
        />
      </div>
    </div>
  );
}

export default SplitEqually;
