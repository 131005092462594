import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../../../../common/components";
import { CrossIcon } from "../../../../../common/Icons";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import {
  deletePaymentMethod,
  getPaymentMethods,
} from "../../../../../../features/Payments/PaymentsSlice";

const DeletePaymentMethod = ({ paymentId }) => {
  const [loading, setLoading] = useState(false);
  const { title, isModalOpen, type } = useSelector(
    (state) => state.paymentsModal
  );
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      setLoading(true);
      if (paymentId) {
        await dispatch(
          deletePaymentMethod({
            id: paymentId,
            successCallBack: onAddPaymentMethod,
          })
        );
      }
    } catch (error) {
      console.error("Error deleting payment method:", error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const onAddPaymentMethod = (data) => {
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };

  return (
    <React.Fragment>
      {isModalOpen && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5 space-y-3">
              <p className="text-text-primary text-base font-normal">
                Are you sure you want to delete this Payment Method?{" "}
              </p>
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
                bgColor={
                  type === "delete" ? "bg-red-500" : "bg-surface-primary"
                }
                type="submit"
                text="Delete"
                onClick={handleDelete}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DeletePaymentMethod;
