import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  addPaymentMethods,
  getPaymentMethods,
} from "../../../../../../features/Payments/PaymentsSlice";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import { useForm } from "react-hook-form";

const CashPayment = forwardRef(({ setIsSubmitting }, ref) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    setIsSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);
  // Expose handleSubmit function to parent component
  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit((data) => {
      const payload = {
        paymentType: "CASH",
      };

      return dispatch(
        addPaymentMethods({
          payload: payload,
          successCallBack: onAddPaymentMethod,
        })
      );
    }),
  }));

  const onAddPaymentMethod = (data) => {
    dispatch(getPaymentMethods());
    dispatch(closeModal());
  };
  return (
    <div className="bg-surface-gray p-3 to-text-primary text-sm font-medium rounded-lg mb-28">
      You are about to add Cash as payment method to your system. Every
      transaction with this method will noted/mentioned/marked as cash. You can
      remove or modify it anytime.
    </div>
  );
});

export default CashPayment;
