import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
} from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import { CrossIcon, TrashIcon } from "../../../../../common/Icons";
import {
  DetailCard,
  CustomButton,
  Loader,
} from "../../../../../common/components";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const ShowPaymentDetail = ({ paymentDetail, loading }) => {
  const dispatch = useDispatch();
  const { title, selectedOption } = useSelector((state) => state.paymentsModal);

  const handleEdit = (type) => {
    dispatch(
      openModal({
        title: `${type} Item`,
        type: type,
        editable: true,
      })
    );
  };
  const handleDelete = (type) => {
    dispatch(
      openModal({
        title: `${type} Item`,
        type: type,
        editable: true,
      })
    );
  };
  console.log({ paymentDetail, selectedOption });

  return (
    <React.Fragment>
      <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
        <div className="bg-white rounded-lg max-w-[700px] w-full">
          {/* header */}
          <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
            <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
              {title}
            </h6>
            <CustomButton
              bgColor="bg-transparent"
              icon={<CrossIcon />}
              width="w-fit"
              border="border"
              onClick={() => dispatch(closeModal())}
            />
          </div>
          {/* body */}
          <div className="p-5 space-y-3">
            {loading ? (
              <Loader />
            ) : (
              <DetailCard
                title={selectedOption === "CARD" && "Allowed Methods"}
                detail={paymentDetail}
              />
            )}
          </div>

          {/* footer */}
          <div
            className={`px-6 !mt-2 flex items-center py-3 gap-2 border-border-primary border-t ${
              selectedOption !== "DELIVERY_COMPANIES"
                ? "justify-between"
                : "justify-end"
            }`}
          >
            {selectedOption !== "DELIVERY_COMPANIES" && (
              <CustomButton
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
                type="submit"
                textColor="text-text-primary"
                border="border"
                text="Delete"
                onClick={() => handleDelete("delete")}
                icon={<TrashIcon />}
              />
            )}
            <CustomButton
              onClick={() => handleEdit("edit")}
              text={"Edit"}
              width="w-fit"
              bgColor="bg-transparent"
              hoverTextColor="text-text-primary"
              border="border"
              textColor="text-text-primary"
              padding="py-2 px-3"
              className="leading-none"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShowPaymentDetail;

// sample usage
// import ShowPaymentDetail from "./path/to/ShowPaymentDetail.jsx";

// <ShowPaymentDetail />
