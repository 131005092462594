// Import necessary functions and modules from Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createExpenseInvoiceApi from "./ExpenseInvoice";
import { showToast } from "../../../components/common/utils/showToast.util";
import store from "../../../store/store";

/**
 * Manages state for expense invoices.
 * Defines initial state for each expense invoice action.
 */
const initialState = {
  getAllExpenseInvoices: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  getSingleExpenseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  addExpenseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  updateExpenseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  deleteExpenseInvoice: {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

/**
 * Constants defining action types related to the expense invoice process.
 * These action types are prefixed with the 'expense-invoices' base path for better organization.
 */
const BASE = "expense-invoices";

export const ActionTypes = {
  GET_ALL_EXPENSE_INVOICES: `${BASE}/get-all`,
  ADD_EXPENSE_INVOICE: `${BASE}`,
  UPDATE_EXPENSE_INVOICE: `${BASE}/update`,
  DELETE_EXPENSE_INVOICE: `${BASE}/delete`,
};

// Creating an instance of the expense invoices service with a base URL 'expense-invoices'
const expenseInvoiceService = createExpenseInvoiceApi("expense-invoices");

/**
 * Initiates the getAllExpenseInvoices process for expense invoices.
 * @param {object} payload - Payload containing query parameters.
 */
export const getAllExpenseInvoices = createAsyncThunk(
  ActionTypes.GET_ALL_EXPENSE_INVOICES,

  async ({ payload }, thunkAPI) => {
    const response = await expenseInvoiceService.getAllExpenseInvoices(payload);
    return response?.data?.data;
  }
);

/**
 * Initiates the getSingleExpenseInvoice process for expense invoices.
 * @param {string} invoiceId - ID of the expense invoice.
 */
export const getSingleExpenseInvoice = createAsyncThunk(
  `${BASE}/getSingleExpenseInvoice`,

  async ({ invoiceId }, thunkAPI) => {
    const response = await expenseInvoiceService.getSingleExpenseInvoice(
      invoiceId
    );
    return response?.data?.data;
  }
);

/**
 * Initiates the updateExpenseInvoice process for expense invoices.
 * @param {string} invoiceId - ID of the expense invoice.
 * @param {object} payload - Payload containing updated data.
 */
export const updateExpenseInvoice = createAsyncThunk(
  ActionTypes.UPDATE_EXPENSE_INVOICE,

  async ({ invoiceId, payload, successCallBack }, thunkAPI) => {
    try {
      const response = await expenseInvoiceService.updateExpenseInvoice(
        invoiceId,
        payload
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast("Expense invoice updated successfully!");
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the addExpenseInvoice process for expense invoices.
 * @param {object} payload - Payload containing data for the new expense invoice.
 */
export const addExpenseInvoice = createAsyncThunk(
  ActionTypes.ADD_EXPENSE_INVOICE,

  async ({ payload, successCallBack }, thunkAPI) => {
    try {
      const response = await expenseInvoiceService.addExpenseInvoice(payload);
      if (response?.data?.Succeeded) {
        successCallBack(response);
        return response?.data?.data;
      } else {
        if (
          Array.isArray(response?.data?.message) &&
          response?.data?.message.length > 0
        ) {
          showToast(response?.data?.message[0], "error");
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Initiates the deleteExpenseInvoice process for expense invoices.
 * @param {string} invoiceId - ID of the expense invoice to be deleted.
 */
export const deleteExpenseInvoice = createAsyncThunk(
  ActionTypes.DELETE_EXPENSE_INVOICE,

  async ({ invoiceId, successCallBack }, thunkAPI) => {
    try {
      const response = await expenseInvoiceService.deleteExpenseInvoice(
        invoiceId
      );
      if (response?.data?.Succeeded) {
        successCallBack(response);
        showToast(
          "Expense invoice deleted successfully!",
          "success",
          async () => {
            const undoResponse = await expenseInvoiceService.undoExpenseInvoice(
              invoiceId
            );
            if (undoResponse?.data?.Succeeded) {
              const payload = {
                page: 1,
                pageSize: 10,
                sortColumn: "id",
                order: {
                  id: "DESC",
                },
                condition: {},
                attributes: {},
              };
              store.dispatch(getAllExpenseInvoices({ payload }));
            }
          }
        );
        return response?.data?.data;
      } else {
        showToast(response?.data?.message, "error");
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue({ payload: error });
    }
  }
);

/**
 * Creates a slice for expense invoice related state management.
 */
export const expenseInvoiceSlice = createSlice({
  name: "expenseInvoice",
  initialState,

  reducers: {
    /**
     * Resets the state for the getAllExpenseInvoices action.
     */
    reset: (state) => {
      state.getAllExpenseInvoices = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
      state.getSingleExpenseInvoice = {
        data: null,
        isError: false,
        isSuccess: false,
        isLoading: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /**
       * Updates the state while the getAllExpenseInvoices action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getAllExpenseInvoices.pending, (state) => {
        state.getAllExpenseInvoices.isLoading = true;
        state.getAllExpenseInvoices.message = "";
        state.getAllExpenseInvoices.isError = false;
        state.getAllExpenseInvoices.isSuccess = false;
        state.getAllExpenseInvoices.data = null;
      })
      /**
       * Updates the state when getAllExpenseInvoices action is fulfilled/successful.
       * Updates loading and success flags and sets getAllExpenseInvoices data with the payload.
       */
      .addCase(getAllExpenseInvoices.fulfilled, (state, action) => {
        state.getAllExpenseInvoices.isLoading = false;
        state.getAllExpenseInvoices.isSuccess = true;
        state.getAllExpenseInvoices.data = action.payload;
      })
      /**
       * Updates the state when getAllExpenseInvoices action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getAllExpenseInvoices.rejected, (state, action) => {
        state.getAllExpenseInvoices.message = action.payload?.message;
        state.getAllExpenseInvoices.isLoading = false;
        state.getAllExpenseInvoices.isError = true;
        state.getAllExpenseInvoices.data = null;
      })
      /**
       * Updates the state while the getSingleExpenseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(getSingleExpenseInvoice.pending, (state) => {
        state.getSingleExpenseInvoice.isLoading = true;
        state.getSingleExpenseInvoice.message = "";
        state.getSingleExpenseInvoice.isError = false;
        state.getSingleExpenseInvoice.isSuccess = false;
        state.getSingleExpenseInvoice.data = null;
      })
      /**
       * Updates the state when getSingleExpenseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets getSingleExpenseInvoice data with the payload.
       */
      .addCase(getSingleExpenseInvoice.fulfilled, (state, action) => {
        state.getSingleExpenseInvoice.isLoading = false;
        state.getSingleExpenseInvoice.isSuccess = true;
        state.getSingleExpenseInvoice.data = action.payload;
      })
      /**
       * Updates the state when getSingleExpenseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(getSingleExpenseInvoice.rejected, (state, action) => {
        state.getSingleExpenseInvoice.message = action.payload?.message;
        state.getSingleExpenseInvoice.isLoading = false;
        state.getSingleExpenseInvoice.isError = true;
        state.getSingleExpenseInvoice.data = null;
      })
      /**
       * Updates the state while the addExpenseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(addExpenseInvoice.pending, (state) => {
        state.addExpenseInvoice.isLoading = true;
        state.addExpenseInvoice.message = "";
        state.addExpenseInvoice.isError = false;
        state.addExpenseInvoice.isSuccess = false;
        state.addExpenseInvoice.data = null;
      })
      /**
       * Updates the state when addExpenseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets addExpenseInvoice data with the payload.
       */
      .addCase(addExpenseInvoice.fulfilled, (state, action) => {
        state.addExpenseInvoice.isLoading = false;
        state.addExpenseInvoice.isSuccess = true;
        state.addExpenseInvoice.data = action.payload;
      })
      /**
       * Updates the state when addExpenseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(addExpenseInvoice.rejected, (state, action) => {
        state.addExpenseInvoice.message = action.payload?.message;
        state.addExpenseInvoice.isLoading = false;
        state.addExpenseInvoice.isError = true;
        state.addExpenseInvoice.data = null;
      })
      /**
       * Updates the state while the updateExpenseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(updateExpenseInvoice.pending, (state) => {
        state.updateExpenseInvoice.isLoading = true;
        state.updateExpenseInvoice.message = "";
        state.updateExpenseInvoice.isError = false;
        state.updateExpenseInvoice.isSuccess = false;
        state.updateExpenseInvoice.data = null;
      })
      /**
       * Updates the state when updateExpenseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets updateExpenseInvoice data with the payload.
       */
      .addCase(updateExpenseInvoice.fulfilled, (state, action) => {
        state.updateExpenseInvoice.isLoading = false;
        state.updateExpenseInvoice.isSuccess = true;
        state.updateExpenseInvoice.data = action.payload;
      })
      /**
       * Updates the state when updateExpenseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(updateExpenseInvoice.rejected, (state, action) => {
        state.updateExpenseInvoice.message = action.payload?.message;
        state.updateExpenseInvoice.isLoading = false;
        state.updateExpenseInvoice.isError = true;
        state.updateExpenseInvoice.data = null;
      })
      /**
       * Updates the state while the deleteExpenseInvoice action is pending.
       * Sets loading to true and clears previous messages.
       */
      .addCase(deleteExpenseInvoice.pending, (state) => {
        state.deleteExpenseInvoice.isLoading = true;
        state.deleteExpenseInvoice.message = "";
        state.deleteExpenseInvoice.isError = false;
        state.deleteExpenseInvoice.isSuccess = false;
        state.deleteExpenseInvoice.data = null;
      })
      /**
       * Updates the state when deleteExpenseInvoice action is fulfilled/successful.
       * Updates loading and success flags and sets deleteExpenseInvoice data with the payload.
       */
      .addCase(deleteExpenseInvoice.fulfilled, (state, action) => {
        state.deleteExpenseInvoice.isLoading = false;
        state.deleteExpenseInvoice.isSuccess = true;
        state.deleteExpenseInvoice.data = action.payload;
      })
      /**
       * Updates the state when deleteExpenseInvoice action is rejected/failed.
       * Updates error message and flags accordingly.
       */
      .addCase(deleteExpenseInvoice.rejected, (state, action) => {
        state.deleteExpenseInvoice.message = action.payload?.message;
        state.deleteExpenseInvoice.isLoading = false;
        state.deleteExpenseInvoice.isError = true;
        state.deleteExpenseInvoice.data = null;
      });
  },
});

/**
 * Destructures the reset action from the expenseInvoiceSlice actions.
 */
export const { reset } = expenseInvoiceSlice.actions;

/**
 * Exports the default reducer generated by expenseInvoiceSlice.
 */
export default expenseInvoiceSlice.reducer;
