import React, { useState } from "react";
import Navbar from "../components/LandingPage/Navbar";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ArrowDown, MinusIcon, PlusIcon } from "../components/common/Icons";
import { CustomButton } from "../components/common/components";
import Footer from "../components/LandingPage/Footer";

const Faqs = () => {
  const [activeTab, setActiveTab] = useState(Object.keys(faqs)[0]);

  return (
    <React.Fragment>
      <div className="w-full faqpagehero 1xl:px-29 px-4 relative">
        <Navbar />
        <div className="text-center xs:max-w-2xl mx-auto space-y-5">
          <h1 className="font-bold text-5xl md:leading-wider leading-tight lg:pt-44 pt-36">
            Frequently Asked Questions
          </h1>
          <p className="mt-2 md:text-base text-sm text-gray-600 font-medium">
            Have questions? We've got answers. Check out our list of frequently
            asked questions to find the information you need.
          </p>
        </div>
        <div className="grid grid-cols-12 lg:mt-20 mt-16">
          {/* desktop */}
          <div className="p-3 rounded-xl bg-neutral-50 space-y-1 xl:col-span-3 lg:col-span-4 lg:block hidden self-start">
            {Object.keys(faqs).map((tab) => (
              <button
                className={`p-2 block rounded-xl bg-transparent text-sm font-medium ${
                  tab === activeTab ? "text-text-link" : "text-text-primary"
                }`}
                key={tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          {/* mobile */}
          <div className="lg:hidden col-span-12 relative">
            <Listbox value={activeTab} onChange={setActiveTab}>
              <ListboxButton className="p-3 flex justify-between items-center w-full text-left rounded-lg border border-border-primary bg-white text-base text-text-primary">
                <span>{activeTab}</span>
                <ArrowDown />
              </ListboxButton>
              <ListboxOptions className="absolute z-10 mt-2 w-full bg-white rounded-md shadow-lg">
                {Object.keys(faqs).map((tab) => (
                  <ListboxOption key={tab} value={tab}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-neutral-100" : ""
                        } block px-4 py-2 text-sm text-text-primary w-full text-left`}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </button>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </div>
          {/* faq content */}
          <div className="xl:col-span-9 lg:col-span-8 col-span-12">
            <dl>
              {faqs[activeTab]?.map((faq) => (
                <Disclosure
                  as="div"
                  key={faq.question}
                  className="lg:p-5 p-0 lg:mt-0 mt-3"
                >
                  {({ open }) => (
                    <>
                      <dt
                        className={
                          open
                            ? "border border-border-primary bg-white lg:p-6 p-4 rounded-xl"
                            : ""
                        }
                      >
                        <DisclosureButton className="flex w-full items-start gap-4">
                          <span className="flex h-7 items-center lg:mt-0 mt-2">
                            {open ? (
                              <CustomButton
                                border="border"
                                padding="p-2"
                                icon={
                                  <MinusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                }
                              />
                            ) : (
                              <CustomButton
                                border="border"
                                padding="p-2"
                                icon={
                                  <PlusIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                }
                              />
                            )}
                          </span>
                          <div className="flex flex-col text-left">
                            <span
                              className={`lg:text-xl text-lg font-semibold leading-snug ${
                                open ? "text-text-link" : "text-text-primary"
                              }`}
                            >
                              {faq.question}
                            </span>
                            <DisclosurePanel as="dd" className="mt-2">
                              <p className="text-base text-text-secondary">
                                {faq.answer}
                              </p>
                            </DisclosurePanel>
                          </div>
                        </DisclosureButton>
                      </dt>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer className="mt-20" />
    </React.Fragment>
  );
};

export default Faqs;

const faqs = {
  "General Account Management": [
    {
      question: "How do I sign up for the POS system?",
      answer:
        "You can sign up using Google, Facebook, or email. Just follow the instructions to agree to the Terms of Use and Privacy Policy and confirm your email.",
    },
    {
      question: "What should I do if I haven't received my confirmation code?",
      answer:
        "If you don't receive a confirmation code in your email, you can request to resend the code through the sign-up interface.",
    },
    {
      question: "How do I complete my business profile?",
      answer:
        "After creating your account and password, you can complete your business profile by uploading a picture and providing the necessary business details.",
    },
    {
      question: "How do I manage messages within the Dashboard system?",
      answer:
        "You can manage your messages by navigating to the Messages section, where you can create new messages, view, send, search, and delete messages. You can also add attachments to your messages.",
    },
    {
      question:
        "Can I transfer my business data (Item) from my current platform into TagDev platform?",
      answer:
        "Yes, Account managers will take care of transferring all items from your old system to your new system.",
    },
    {
      question: "Can I check the platform from my phone?",
      answer:
        "Yes, you can download the TagDev Application from the Appstore or Google Play Store.",
    },
    {
      question: "Does TagDev provide cashier devices and barcode readers?",
      answer: "Yes, we provide devices at a lower price than the market price.",
    },
    {
      question: "Does TagDev comply with Zakat, Tax, and Customs Authority?",
      answer: "Yes.",
    },
    {
      question: "Are TagDev platform updates paid?",
      answer:
        "All updates are free and automatically set up on TagDev subscribers’ systems.",
    },
    {
      question: "Can I print if the system is not connected to the Internet?",
      answer:
        "Printing is possible if the POS and printer are connected to the same local network (Wi-Fi/Ethernet).",
    },
  ],
  "Subscription and Billing": [
    {
      question: "How do I set or change my subscription plan?",
      answer:
        "To set or change your subscription plan, go to Subscription Settings, choose your plan, add any additional branches if needed, select your payment method, and confirm your subscription.",
    },
    {
      question: "Can I add multiple branches to my subscription?",
      answer:
        "Yes, you can add additional branches to your subscription plan during the setup or modification of your subscription.",
    },
  ],
  "Dashboard and Analytics": [
    {
      question: "How can I view analytics for specific dates?",
      answer:
        "On the dashboard, use the calendar feature to select and view analytics for your desired dates or store or Casher or more",
    },
    {
      question: "What kind of sales analytics can I view on the dashboard?",
      answer:
        "The dashboard provides an overview of sales, costs, net income, expenses, and sales categorized by items, categories, stores, cashiers, and sales forecasts.",
    },
  ],
  "Invoice and Inventory Management": [
    {
      question: "How do I manage sales expenses and purchase invoices?",
      answer:
        "as TagDev offers a Simple Accounting system to help you calculate your costs and expenses quickly and accurately. Invoices can be managed through the 'Sales Invoices' and 'Purchase Invoices' and 'Expenses Invoices' sections, where you can view, create, edit, delete, and search for invoices. You also have options like showing details, printing, duplicating, and processing returns",
    },
    {
      question: "How can I handle inventory transfers and stocktaking?",
      answer:
        "Go to the 'Inventory Transfer' and 'Stocktaking' sections to manage these aspects. You can add, edit, delete, and search for transfers or stocktaking records, and access detailed options.",
    },
  ],
  "Reports and Analytics": [
    {
      question: "How do I access different types of business reports?",
      answer:
        "Navigate to the Reports section to access and export various reports such as sales, purchases, taxes, profit & loss, expenses, and inventory. You can apply filters and select dates for customized reporting",
    },
  ],
  "User and Store Management": [
    {
      question: "How do I manage user roles and store details?",
      answer:
        "In the 'Manage Users' section, you can add, edit, delete, and search for users, as well as manage their roles and permissions. For store management, use the 'Manage Stores' section to add, edit, and manage store details.",
    },
  ],
  "Promotions and Settings": [
    {
      question: "How can I set up promotions for my products?",
      answer:
        "Use the 'Manage Promotions' section to create and edit promotions. You can set up promotions for selected products, all products, and define the discount as percentages or fixed amounts.",
    },
    {
      question: "How do I change general settings and manage devices?",
      answer:
        "In the 'General Settings' section, you can view and edit various settings, including managing connected devices and configuring new ones",
    },
  ],
};
