import axios from "axios";
import { getAccessToken } from "../components/common/utils/access-token.util";

/**
 * Handles the export of customer data by sending a request to the server and triggering a file download.
 * @param {string} url - The base URL for the request (not used in current implementation).
 * @param {string} fileName - The default filename for the downloaded file.
 */
export const handlePrint = async (url, fileName) => {
  const accessToken = getAccessToken();
  // Define the payload for the POST request
  const payload = {
    page: 1,
    pageSize: 10,
    sortColumn: "id",
    order: { id: "DESC" },
    condition: {},
    attributes: {},
    periodId: 0,
  };

  try {
    // Perform the POST request to export customer data
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_MAIN_URL}${url}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Attach the access token to the request headers
        },
        responseType: "blob", // Ensures the response is treated as a Blob
      }
    );

    // Create a URL for the blob object received in the response
    const blob = new Blob([response.data], { type: "application/pdf" });
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName; // Set the filename for the download
    document.body.appendChild(a); // Append the anchor tag to the body
    a.click(); // Trigger the download
    a.remove(); // Remove the anchor tag from the DOM
    window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
  } catch (error) {
    console.error("Error during export:", error);
  }
};

/**
 * Sample usage:
 * import  {handlePrint} from "./handlePrint";
 * handlePrint("customers/printCustomers", "Customers.pdf")
 */
