import ProfileLayout from "../Layout.jsx";
import CreatePasswordForm from "../../../CreatePasswordForm/CreatePasswordForm";

import { useResetPassword } from "./useCreatePassword";
import { useTranslation } from "react-i18next";

/**
 * CreatePassword component.
 * Renders a form to create a new password within a profile layout.
 *
 * @returns {JSX.Element} JSX code for rendering the CreatePassword component.
 */

export const CreatePassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,
    validatePassword,
    isPasswordValid,
    trigger,
    isSubmitting,
  } = useResetPassword();
  return (
    <ProfileLayout activePage={t("stepper.create_password")}>
      <div>
        <div className="max-w-[480px] mx-auto py-12">
          {/* heading */}
          <h2 className="text-3xl  text-center font-semibold mb-3 text-text-primary mt-3">
            {t(`create_password.title`)}
          </h2>
          {/* form */}
          <CreatePasswordForm
            t={t}
            register={register}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            isPasswordValid={isPasswordValid}
            validatePassword={validatePassword}
            trigger={trigger}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </ProfileLayout>
  );
};

// Example usage of CreatePassword component:
// import CreatePassword from '.path/CreatePasswordForm';
// <CreatePassword />
