import React from "react";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import CustomButton from "../../../../../common/components/Button/Button";
import useUpdateProfile from "./useUpdateProfile";
import { Loader } from "../../../../../common/components";
import { ROLE } from "../../../../../../enums/role";

function UpdateProfile({ onClose }) {
  const {
    register,
    errors,
    handleSubmit,
    email,
    isFormChanged,
    isLoading,
    isFetching,
    storedUserData,
  } = useUpdateProfile(onClose);

  if (isFetching) {
    return (
      <div className="flex justify-center items-center mt-24">
        <Loader />
      </div>
    );
  }

  return (
    <form className="space-y-4 mt-4 px-4" onSubmit={handleSubmit}>
      <div className="space-y-4 mt-10 mb-20">
        <div className="flex items-center">
          <p className="block text-sm font-normal text-text-primary w-1/4">
            English Name
          </p>
          <InputGroup
            className="w-3/4"
            inputName="fullName"
            register={register}
            errors={errors}
            focusOnType={true}
          />
        </div>
        {/* Arabic Name */}
        <div className="flex items-center">
          <p className="block text-sm font-normal text-text-primary w-1/4">
            Arabic Name
          </p>
          <InputGroup
            className="w-3/4"
            inputName="arabicName"
            register={register}
            errors={errors}
            focusOnType={true}
          />
        </div>

        {/* Email */}
        <div className="flex items-center">
          <p className="block text-sm font-normal text-text-primary w-1/4">
            Email
          </p>
          <InputGroup
            className="w-3/4"
            inputName="email"
            register={register}
            errors={errors}
            value={email}
            focusOnType={true}
          />
        </div>
        {/* Phone */}
        <div className="flex items-center">
          <p className="block text-sm font-normal text-text-primary w-1/4">
            Phone
          </p>
          <InputGroup
            className="w-3/4"
            // type="text"
            inputName="phone"
            register={register}
            errors={errors}
            focusOnType={true}
          />
        </div>
        {/* Role */}
        <div className="flex items-center">
          <p className="block text-sm font-normal text-text-primary w-1/4">
            Role
          </p>
          <InputGroup
            className="w-3/4 font-semibold"
            inputName="admin"
            register={register}
            errors={errors}
            readOnly={true}
          />
        </div>
        <div className="pl-52">
          <CustomButton
            text="Save Changes"
            width="w-fit"
            type="submit"
            disabled={!isFormChanged}
          />
        </div>
      </div>
    </form>
  );
}

export default UpdateProfile;
