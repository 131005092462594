import React, { Suspense, lazy, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import {
  EditIcon,
  EyeIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import {
  Loader,
  Breadcrumb,
  CustomButton,
  CustomTable,
  DetailCard,
} from "../../../../common/components";
import Layout from "../../Layout/Layout";
import useInventoryTransferList from "./useInventoryTransferList";
import AddEditInventoryTransfer from "./AddEditInventoryTransfer/AddEditInventoryTransfer";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

// import dynamic components with react lazy components
const CustomModal = lazy(() =>
  import("../../../../common/components/CustomModel/CustomModel")
);

/**
 * Inventory Transfer List component
 * Renders table of inventory transfers list
 *
 * @returns {JSX.Element} JSX code for rendering the inventory transfer list table
 */
export default function InventoryTransferList() {
  const { t } = useTranslation();
  const [isInputChanged, setIsInputChanged] = useState();
  const [selectedItem, setSelectedItem] = useState(null);

  const addEditInventoryRef = useRef();

  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    inventoryTransferList,
    inventoryTransferListLoading,
    isSubmitting,
    onDeleteInventoryTransfer,
    storesList,
    itemList,
    inventoryDetail,
    currentPage,
    setCurrentPage,
    clearErrors,
    itemListLoading,
  } = useInventoryTransferList(selectedItem?.id);

  const handleInventoryTransferActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type === "add" ? "Add" : "Edit"} Inventory Transfer`,
        type: type,
      })
    );
  };

  const handleViewInventoryTransfer = (type) => {
    dispatch(
      openModal({
        title: `Inventory ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleFormSubmission = () => {
    if (
      addEditInventoryRef?.current &&
      addEditInventoryRef?.current?.handleSubmit
    ) {
      addEditInventoryRef?.current?.handleSubmit();
    }
  };

  const filteredItems = useMemo(
    () => itemList?.filter((item) => item?.type !== "COMPOSITING"),
    [itemList]
  );

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={t("buttons.export")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() =>
              handleExport(
                "inventory-transfer/export-inventory-transfer",
                "Purchase_Invoices"
              )
            }
          />
          <CustomButton
            text={t("buttons.print")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint(
                "inventory-transfer/print-inventory-transfer-invoice",
                "Purchase_Invoices"
              )
            }
          />
          <CustomButton
            text={t(
              "client_dashboard.inventory.inventory_transfer.add_inventory"
            )}
            width="w-fit"
            bgColor="!bg-surface-primary"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handleInventoryTransferActions("add");
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* inventory transfer list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={inventoryTransferList ?? []}
          setSelectedItem={setSelectedItem}
          loading={inventoryTransferListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewInventoryTransfer("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleInventoryTransferActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleInventoryTransferActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={
            inventoryTransferList ? inventoryTransferList?.length : 0
          }
        />
      </div>
      {/* modal */}
      <Suspense fallback={<Loader />}>
        <CustomModal
          onAdd={handleFormSubmission}
          onEdit={() => handleInventoryTransferActions("edit")}
          onDelete={onDeleteInventoryTransfer}
          loading={isSubmitting}
          isInputChanged={isInputChanged}
        >
          {/* conditionally render modal for different inventory actions */}
          {(modalState?.type === "edit" || modalState?.type === "add") && (
            <AddEditInventoryTransfer
              ref={addEditInventoryRef}
              inventoryTransfer={selectedItem ? selectedItem : null}
              storesList={storesList ?? []}
              setIsInputChanged={setIsInputChanged}
              itemList={filteredItems ?? []}
              itemListLoading={itemListLoading}
            />
          )}
          {/* detail modal */}
          {modalState?.type === "detail" &&
            (inventoryTransferListLoading ? (
              <Loader />
            ) : (
              <DetailCard detail={inventoryDetail} />
            ))}
          {/* delete modal */}
          {modalState?.type === "delete" && (
            <p className="text-text-primary text-base font-normal">
              Are you sure you want to delete this inventory transfer?{" "}
            </p>
          )}
        </CustomModal>
      </Suspense>
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.inventory.name", href: "#", current: false },
  {
    name: "client_dashboard.inventory.inventory_transfer.name",
    href: "#",
    current: true,
  },
];

// table headings for inventory transfers table
const tableHeader = [
  { label: "Date", key: "date", type: "date" },
  { label: "Created by", key: "createdBy" },
  { label: "Store(From)", key: "storeFrom.branchName" },
  { label: "Store(To)", key: "storeTo.branchName" },
  { label: "Item", key: "transferredItems[0].itemDetail.englishName" },
  { label: "Quantity", key: "transferredItems[0].quantity" },
];
