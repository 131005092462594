import React from "react";
import CustomPosTable from "../../../../../../common/components/PosTable/PosTable";

function ClientDetailBillingHistory({ clientDetails }) {
  const { billingHistory } = clientDetails.subscription || {};
  const capitalizePlanType = (planType) => {
    if (!planType) return "";
    return planType.replace(/\(([^)]+)\)/, (match, p1) => {
      return `(${p1.charAt(0).toUpperCase() + p1.slice(1).toLowerCase()})`;
    }).replace(/^\w/, c => c.toUpperCase()).replace(/\s+(\w)/g, c => c.toLowerCase());
  };
  
  const tableBody = billingHistory?.map((subscription) => ({
    invoiceno: subscription.invoiceNumber??"-",
    startdate: subscription.startDate??"-",
    enddate: subscription.endDate??"-",
    plan: capitalizePlanType(subscription.planType)??"-",
    branches: subscription.branches === null ? "None" : subscription.branches.toString(),
    totalamount: `${subscription.subTotal} SAR`??"-",
    status: subscription.status ? "Active" : "Inactive",
    paymentmethod: (
      <img className="h-6 w-6" src={subscription.paymentMethod} alt="Bank" />
    ),
  }));

  const headers = [
    { label: "Invoice No", key: "invoiceno" },
    { label: "Start Date", key: "startdate" },
    { label: "End Date", key: "enddate" },
    { label: "Plan", key: "plan" },
    { label: "Branches", key: "branches" },
    { label: "Total Amount", key: "totalamount" },
    { label: "Status", key: "status" },
    { label: "Payment Method", key: "paymentmethod" },
  ];

  return (
      <div>
        {billingHistory && billingHistory.length > 0 ? (
          <CustomPosTable
            tableHeader={headers}
            tableBody={tableBody}
            loading={false}
            showActionsColumn={false}
          />
        ) : (
          <p className="border  py-2 text-red-600 rounded-lg pl-3">No record found!</p>
        )}
      </div>
    );
}

export default ClientDetailBillingHistory;
