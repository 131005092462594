import { useState } from "react";
import { ArrowUp } from "../../../../common/Icons";
import { Card, SelectGroup } from "../../../../common/components";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import Layout from "../../Layout/Layout";
import useProfitLoss from "./useProfitLoss";

/**
 * Profit & Loss Reports List component
 * Renders table of Stores list
 *
 * @returns {JSX.Element} JSX code for rendering the Profit & Loss Reports list table
 */

export default function ProfitLoss() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };
  const { handleSubmit, storesList, setValue, profitLossReports } =
    useProfitLoss(startDate, endDate);

  const netSales = profitLossReports
    ? profitLossReports?.grossSales -
      profitLossReports?.returns -
      profitLossReports?.discounts
    : 0;

  const grossProfit = netSales
    ? netSales - profitLossReports?.costOfGoodsSold
    : 0;

  const netProfit = netSales
    ? netSales -
      profitLossReports?.costOfGoodsSold -
      profitLossReports?.generalAdministrativeExpenes
    : 0;

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text="Export"
          width="w-fit"
          textColor="text-text-primary"
          border="border"
          padding="py-2 px-3"
          icon={<ArrowUp className="min-w-6 min-h-6 pb-3" />}
        />
      </div>
      {/* filters */}

      <div className="flex items-center justify-between mt-3">
        {storesList?.length > 0 && (
          <SelectGroup
            className="min-w-44"
            htmlFor="store"
            inputName="store"
            options={[
              { value: "", label: "All Store" },
              ...storesList?.map((option) => ({
                value: option?.id,
                label: option?.branchName,
              })),
            ]}
            onChange={(e) => {
              setValue("store", e.target.value);
              handleSubmit();
            }}
          />
        )}
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
            handleSubmit();
          }}
        />
      </div>
      {/* data */}
      <Card className="mt-3 space-y-6">
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm">Gross Sales</h6>
          <span className="to-text-primary text-sm font-semibold">
            {profitLossReports?.grossSales ?? 0} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm">Return</h6>
          <span className="to-text-primary text-sm font-semibold">
            {profitLossReports?.returns ?? 0} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm">Discounts</h6>
          <span className="to-text-primary text-sm font-semibold">
            {profitLossReports?.discounts ?? 0} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm font-semibold">Net Sales</h6>
          <span className="to-text-primary text-sm font-semibold text-text-link">
            {netSales} SAR
          </span>
        </div>
      </Card>
      <Card className="mt-3 space-y-6">
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm">Net Sales</h6>
          <span className="to-text-primary text-sm font-semibold">
            {netSales} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm">Cost Of Goods Sold (COGS)</h6>
          <span className="to-text-primary text-sm font-semibold">
            {profitLossReports?.costOfGoodsSold ?? 0} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm font-semibold">
            Gross Profit
          </h6>
          <span className="to-text-primary text-sm font-semibold text-text-link">
            {grossProfit} SAR
          </span>
        </div>
      </Card>
      <Card className="mt-3 space-y-6">
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm"> Groos Profit</h6>
          <span className="to-text-primary text-sm font-semibold">
            {grossProfit} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm">
            Expense General & Administrative
          </h6>
          <span className="to-text-primary text-sm font-semibold">
            {profitLossReports?.generalAdministrativeExpenes ?? 0} SAR
          </span>
        </div>
        <div className="flex items-center justify-between">
          <h6 className="to-text-primary text-sm font-semibold">Net Proft</h6>
          <span className="to-text-primary text-sm font-semibold text-text-link">
            {netProfit} SAR
          </span>
        </div>
      </Card>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.report.name", href: "#", current: false },
  { name: "client_dashboard.report.profit_loss", href: "#", current: true },
];
