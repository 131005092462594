import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../common/Icons/fontawesome";
import Loader from "../../../../common/components/Loader/Loader";
import Breadcrumb from "../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../common/components/Button/Button";
import CustomTable from "../../../../common/components/CustomTable/CustomTable";
import Layout from "../../Layout/Layout";
import CustomModal from "../../../../common/components/CustomModel/CustomModel";
import DetailCard from "../../../../common/components/DetailCard/DetailCard";
import useSuppliersList from "./useSuppliersList";
import AddEditSupplier from "./AddEditSupplier/AddEditSupplier";
import {
  EditIcon,
  EyeIcon,
  PrinterIcon,
  TrashIcon,
  UploadIcon,
} from "../../../../common/Icons";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";
import { handleExport } from "../../../../../helpers/handleExport";
import { handlePrint } from "../../../../../helpers/handlePrint";

/**
 * customer List component
 * Renders table of customers list
 *
 * @returns {JSX.Element} JSX code for rendering the customer list table
 */

export default function SuppliersList() {
  const { t } = useTranslation();
  const [isInputChanged, setIsInputChanged] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    suppliersList,
    singleCustomerLoading,
    supplierDetail,
    register,
    errors,
    handleSubmit,
    setValue,
    deleteCustomer,
    supplierListLoading,
    watch,
    currentPage,
    clearErrors,
    setCurrentPage,
    isSubmitting,
  } = useSuppliersList(selectedItem?.id);

  const handleViewSupplier = (type) => {
    dispatch(
      openModal({
        title: `Supplier ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handleSupplierActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type} Supplier`,
        type: type,
      })
    );
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <div className="flex items-center gap-3">
          <CustomButton
            text={t("buttons.export")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<UploadIcon />}
            onClick={() => handleExport("suppliers/export-suppliers")}
          />
          <CustomButton
            text={t("buttons.print")}
            width="w-fit"
            textColor="text-text-primary"
            border="border"
            icon={<PrinterIcon />}
            onClick={() =>
              handlePrint(
                "suppliers/print-supplier-invoice",
                "Suppliers"
              )
            }
          />
          <CustomButton
            text={t("client_dashboard.directory.supplier.add_supplier")}
            width="w-fit"
            bgColor="!bg-surface-primary"
            hoverTextColor="text-white"
            className="hover:!text-white"
            icon={<Plus color="white" />}
            onClick={() => {
              handleSupplierActions("add");
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* customer list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={suppliersList}
          setSelectedItem={setSelectedItem}
          loading={supplierListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,
              onClick: () => handleViewSupplier("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,
              onClick: () => handleSupplierActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handleSupplierActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={suppliersList ? suppliersList?.length : 0}
        />
      </div>

      {/* modal */}
      <CustomModal
        onAdd={() => handleSubmit()}
        onEdit={() => handleSupplierActions("edit")}
        onDelete={() => deleteCustomer()}
        isInputChanged={isInputChanged}
        loading={isSubmitting}
      >
        {/* conditionally render modal for different customer actions */}
        {(modalState?.type === "edit" || modalState?.type === "add") && (
          <AddEditSupplier
            customer={selectedItem ? selectedItem : null}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            setIsInputChanged={setIsInputChanged}
          />
        )}
        {/* detail modal */}
        {modalState?.type === "detail" &&
          (singleCustomerLoading ? (
            <Loader />
          ) : (
            <DetailCard detail={supplierDetail} />
          ))}
        {/* delete modal */}
        {modalState?.type === "delete" && (
          <p className="text-text-primary text-base font-normal">
            Are you sure you want to delete this Supplier?{" "}
          </p>
        )}
      </CustomModal>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.directory.name", href: "#", current: false },
  {
    name: "client_dashboard.directory.supplier.name",
    href: "#",
    current: true,
  },
];
// table headings for customers table

const tableHeader = [
  { label: "Name", key: "name" },
  { label: "Company", key: "companyName" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Email", key: "email" },
  { label: "Total Purchase", key: "totalPurchases" },
];
