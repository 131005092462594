import React, { useState } from "react";
import {
  Breadcrumb,
  CustomModal,
  CustomTable,
  DetailCard,
} from "../../../../common/components";
import Layout from "../../Layout/Layout";
import useTabs from "../../../../../hooks/useTabs";
import useInventoryTransactions from "./useInventoryTransactions";
import { EyeIcon } from "../../../../common/Icons";
import { openModal } from "../../../../../features/Modal/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PaginationBar from "../../../../common/components/PaginationBar/PaginationBar";
// const AllInventoryTransfers = lazy(() =>
//   import("./UpdateProfile/UpdateProfile.jsx")
// );

/**
 * Inventory transaction List component
 * Renders table of inventory transactions list
 *
 * @returns {JSX.Element} JSX code for rendering the inventory transaction list table
 */

export default function InventoryTransactions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const modalState = useSelector((state) => state.modal);
  const {
    inventoryTransferList,
    purchaseInvoicesList,
    stocktakingList,
    saleInvoicesList,
    inventory_transferTitle,
    stocktakingTitle,
    sale_invoicesTitle,
    purchase_invoicesTitle,
    dataDetails,
    tableHeader,
    itemDetails,
    itemImage,
    currentPage,
    setCurrentPage,
  } = useInventoryTransactions();

  const handleView = (type) => {
    dispatch(
      openModal({
        title: `Details`,
        type: "detail",
        editable: true,
      })
    );
  };

  const headerReference = {
    [inventory_transferTitle]: inventoryTransferList,
    [stocktakingTitle]: stocktakingList,
    [sale_invoicesTitle]: saleInvoicesList,
    [purchase_invoicesTitle]: purchaseInvoicesList,
  };
  const tabs = Object.entries(tableHeader).map(([title, tableHeader]) => ({
    title,
    content: () => (
      <CustomTable
        tableHeader={tableHeader}
        tableBody={headerReference[title] || []}
        setSelectedItem={setSelectedItem}
        dropdownOptions={[
          {
            text: t("buttons.show_details"),
            icon: <EyeIcon />,
            onClick: () => handleView("detail"),
          },
        ]}
      />
    ),
  }));

  const { TabContent, TabHeader, activeTab } = useTabs(0, tabs);

  return (
    <Layout>
      {/* header */}
      <Breadcrumb pages={pages} />
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        <div className="space-y-3 mt-4">
          <TabHeader position="start" />
          <TabContent />
        </div>
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={{
            [inventory_transferTitle]: inventoryTransferList?.length,
            [stocktakingTitle]: stocktakingList?.length,
            [sale_invoicesTitle]: saleInvoicesList?.length,
            [purchase_invoicesTitle]: purchaseInvoicesList?.length,
          }}
        />
      </div>

      {/* modal */}
      <CustomModal
        buttons={{
          detail: [],
        }}
      >
        {/* detail modal */}
        {modalState?.type === "detail" && (
          <DetailCard
            detail={dataDetails[activeTab.title](selectedItem)}
            otherDetail={itemDetails[activeTab.title](selectedItem)}
            image={itemImage[activeTab.title](selectedItem)}
          />
        )}
      </CustomModal>
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.inventory.name", href: "#", current: false },
  {
    name: "client_dashboard.inventory.inventory_transaction.name",
    href: "#",
    current: true,
  },
];
