import React from "react";
import {
  AppleStore,
  GooglePay,
  StcPay,
  Tabby,
  VisaPay,
  ZakatTax,
} from "../assets/images";
import Footer from "../components/LandingPage/Footer";
import HeroSection from "../components/LandingPage/HeroSection";
import OnePlace from "../components/LandingPage/OnePlace";
import TabsSection from "../components/LandingPage/TabsSection";
import WhyTagDev from "../components/LandingPage/WhyTagDev";

const HomePage = () => {
  return (
    // <SubscriptionAlertEmail />
    <div className="">
      {/* hero */}
      <HeroSection />
      {/* payment methods */}
      <div className="flex gap-x-12 gap-y-9 items-center justify-center py-10 flex-wrap 1xl:px-29 px-4">
        <img src={VisaPay} alt="Google Pay" className="h-9 md:w-auto w-10" />
        <img src={Tabby} alt="Google Pay" className="h-9 md:w-auto w-16" />
        <img src={GooglePay} alt="Google Pay" className="h-9 md:w-auto w-36" />
        <img src={ZakatTax} alt="Google Pay" className="h-9 md:w-auto w-36" />
        <img src={StcPay} alt="Google Pay" className="h-9 md:w-auto w-20" />
        <img src={AppleStore} alt="Google Pay" className="h-9 md:w-auto w-24" />
      </div>
      {/* all in once place */}
      <OnePlace />
      <div className="headline text-center flex flex-col items-center justify-center lg:h-[688px] h-[400px] 1xl:px-29 px-4">
        <h6 className="text-text-primary font-semibold lg:text-5xl text-3xl lg:leading-wider">
          “More than a POS system – it's the foundation of your{" "}
          <span className="text-text-link">business's success</span> in the
          competitive retail world”
        </h6>
      </div>
      {/* tabs section */}
      <TabsSection />
      {/* why tagdev */}
      <WhyTagDev />
      {/* footer */}
      <Footer />
      {/*       
      <BluetoothDeviceSelector /> */}
    </div>
  );
};

export default HomePage;
