import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Eye, EyeSlash } from "../common/Icons/fontawesome";
import useLogin from "./useLogin";
import { CustomButton, InputGroup } from "../common/components";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";

/**
 * LoginForm component.
 * Renders a form for user login including email, password fields, and social login options.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.userType - The type of user (e.g., "client").
 * @returns {JSX.Element} JSX code for rendering the LoginForm component.
 */

const LoginForm = ({ userType }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, errors, isValid, isSubmitting } =
    useLogin(userType);

  return (
    <div className="max-w-[480px] min-w-[480px] mx-auto">
      {/* heading */}
      <h2 className="text-3xl font-semibold mb-3 text-center text-text-primary">
        {t(`login.title`)}
      </h2>
      <p className="text-text-secondary text-base font-normal text-center">
        {t(`login.welcome`)}
      </p>
      {/* social logins and form fields */}
      <div className="space-y-4 mt-3">
        {userType === "client" && (
          <div className="space-y-4">
            {/* google login */}
            <GoogleLoginButton />

            {/* facebook login */}
            <FacebookLoginButton />

            {/* or */}
            <div className="flex items-center justify-center">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="mx-4 text-text-primary font-normal">
                {t(`login.or`)}
              </span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
          </div>
        )}
        {/* login form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* email */}
          <InputGroup
            htmlfor="email"
            labelText={t(`login.email`)}
            inputName="email"
            placeholder={t(`login.email_placeholder`)}
            inputType="email"
            register={register}
            errors={errors}
          />

          {/* password */}
          <InputGroup
            htmlfor="password"
            labelText={t(`login.password`)}
            inputName="password"
            placeholder={t(`login.password_placeholder`)}
            inputType={showPassword ? "text" : "password"}
            register={register}
            errors={errors}
          >
            <span
              className="cursor-pointer absolute end-3 top-6 pt-1"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <Eye color="gray-600" />
              ) : (
                <EyeSlash color="gray-700" />
              )}
            </span>
          </InputGroup>

          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center gap-2">
              <InputGroup
                htmlfor="remember"
                inputName="remember"
                inputType="checkbox"
                register={register}
                errors={errors}
              />
              <label
                htmlFor="remember"
                className="mt-1 block text-xs font-normal text-text-primary"
              >
                {t(`login.remember_me`)}
              </label>
            </div>
            <Link
              to="/forget-password"
              className="text-sm underline hover:text-text-link"
            >
              {t(`login.forgot_password`)}
            </Link>
          </div>
          <CustomButton
            text={t(`login.title`)}
            type="submit"
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
          />
        </form>
        {/* signup button */}
        {userType === "client" && (
          <div className="text-text-secondary text-base text-center !mt-6">
            {t(`login.account_confirmation`)}
            <Link to="/signup" className="text-text-link hover:underline">
              {" "}
              {t(`signup.title`)}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;

// Example usage of LoginForm component:
// import  LoginForm  from 'path/to/login'
// <LoginForm userType="client" />
