import React, { useEffect, useState } from "react";
import { Card, CustomButton, Loader } from "../../../../common/components";
import {
  LightBulb,
  PhoneIcon,
  Rocket,
  RocketLaunch,
  ToggleOff,
  ToggleOn,
} from "../../../../common/Icons";
import { useForm } from "react-hook-form";
import {
  getSingleSubscriptions,
  changeSubscription,
  getAllSubscriptions,
} from "../../../../../features/AdminSubscribtions/AdminSubscribtionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Check } from "../../../../common/Icons/fontawesome";
import { DefaultImg } from "../../../../../assets/images";

function ChangeSubscription({
  selectedItem,
  closeModal,
  subscriptionDetailId,
  selectedTab
}) {
  const dispatch = useDispatch();
  const subscriptionId = selectedItem?.id ?? subscriptionDetailId;

  useEffect(() => {
    if (subscriptionId) dispatch(getSingleSubscriptions({ subscriptionId, isFreeTrial: selectedTab === 'FreeTrial' ? true : false }));
  }, [dispatch, subscriptionId]);

  const { data: singleSubscriptiondata, isLoading: subscriptionLoading } =
    useSelector((state) => state.subscriptions.getSingleSubscriptions);

  const profile = singleSubscriptiondata?.clientUser?.profile ?? "-";
  const PrivideBy = singleSubscriptiondata?.managedBy?.fullName ?? "-";
  const subTotal = singleSubscriptiondata?.subTotal ?? "-";
  const plan = singleSubscriptiondata?.plan_tenure ?? "-";
  const planId = singleSubscriptiondata?.planId ?? "-";
  const startDate = singleSubscriptiondata?.startDate ?? "-";
  const endDate = singleSubscriptiondata?.endDate ?? "-";

  const {
    formState: { errors },
  } = useForm();

  const [trialPeriodEnding, setTrialPeriodEnding] = useState(plan === "ANNUAL");
  const [selectedPlan, setSelectedPlan] = useState(planId);

  useEffect(() => {
    if (planId) {
      setSelectedPlan(planId);
    }
    if (plan === "MONTHLY") {
      setTrialPeriodEnding(false);
    }
  }, [planId, plan]);

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleConfirmClick = () => {
    const planTenure = trialPeriodEnding ? "ANNUAL" : "MONTHLY";
    const planAmount = trialPeriodEnding
      ? selectedPlan === "1"
        ? 850
        : selectedPlan === "2"
        ? 1100
        : 1600
      : selectedPlan === "1"
      ? 84
      : selectedPlan === "2"
      ? 110
      : 180;

    const payload = {
      planId: selectedPlan,
      planTenure,
      planAmount,
    };

    dispatch(
      changeSubscription({
        subscriptionId,
        payload,
        successCallBack: handleSuccess,
      })
    );
  };

  const handleSuccess = () => {
    closeModal();
    fetchAllSubscriptions();
  };

  const fetchAllSubscriptions = () => {
    dispatch(
      getAllSubscriptions({
        payload: {
          page: 1,
          pageSize: 10,
          sortColumn: "id",
          order: { id: "DESC" },
          condition: {},
          attributes: {},
          periodId: 0,
        },
      })
    );
  };

  const PlanCard = ({ title, price, IconComponent, isSelected, onClick }) => {
    return (
      <div
        className={`relative flex flex-col w-full p-4 rounded-lg ${
          isSelected
            ? "bg-blue-200 text-text-primary font-bold border-blue-400"
            : "bg-gray-100 text-gray-700"
        }`}
        onClick={onClick}
      >
        <div className="flex justify-between items-start">
          <IconComponent
            className={`h-5 w-5 ${isSelected ? "text-white" : "text-gray-200"}`}
          />
          {isSelected && (
            <div className="text-gray-200">
              <Check />{" "}
            </div>
          )}
        </div>
        <div>
          <h4 className="text-base font-semibold">{title}</h4>
          <div className="text-xs">{price} SAR</div>
        </div>
      </div>
    );
  };
  if (subscriptionLoading) {
    return (
      <div className="mx-60 mt-12">
        <Loader />
      </div>
    );
  }
  return (
    <div className="p-4">
      <div className="flex items-center mt-4 ">
        <img
          src={profile?.profilePic || DefaultImg}
          alt="Pic"
          className="inline-block h-12 w-12 rounded-full border mr-3"
        />

        <div className="flex-grow ">
          <p className="text-text-primary text-lg font-semibold">
            {profile?.fullName ?? "-"}
          </p>
          <div className="flex items-center text-text-gray font-normal mt-2">
            <PhoneIcon className="mr-2" />{" "}
            <span className="pl-2 text-text-primary  text-sm font-normal">
              {profile?.phoneNumber ?? "-"}
            </span>
          </div>
        </div>
      </div>
      <p className="mt-4 text-base font-normal text-text-primary">
        Plan Detail
      </p>
      <div className="bg-white  mt-4 mb-4 border rounded-lg p-4">
        <div className="flex gap-36 mb-4">
          <div>
            <p className="text-text-primary font-semibold">Advance Plan</p>
            <p className="text-text-secondary">
              {subTotal} ({plan})
            </p>
          </div>
          <div>
            <p className="text-text-primary font-semibold">Provider</p>
            <p className="text-text-secondary">{PrivideBy ?? "-"}</p>
          </div>
        </div>
        <div className="flex gap-40">
          <div>
            <p className="text-text-primary font-semibold">Start Date</p>
            <p className="text-text-secondary">{startDate ?? "-"}</p>
          </div>
          <div>
            <p className="text-text-primary font-semibold ml-4">End Date</p>
            <p className="text-text-secondary ml-4">{endDate ?? "-"}</p>
          </div>
        </div>
      </div>

      <Card>
        <p className=" text-text-primary font-semibold text-base">
          Update to New Plan
        </p>
        <div className="flex items-center justify-between mt-2">
          <label className="text-sm font-normal">Annual Plan</label>
          <div onClick={() => setTrialPeriodEnding(!trialPeriodEnding)}>
            {trialPeriodEnding ? <ToggleOn /> : <ToggleOff />}
          </div>
        </div>

        <div className="bg-white p-1 gap-4 flex justify-between items-start mt-2 ">
          <PlanCard
            title="Basic Plan"
            price={trialPeriodEnding ? "850" : "84"}
            IconComponent={LightBulb}
            isSelected={selectedPlan === "1"}
            onClick={() => selectPlan("1")}
          />
          <PlanCard
            title="Professional Plan"
            price={trialPeriodEnding ? "1100" : "110"}
            IconComponent={Rocket}
            isSelected={selectedPlan === "2"}
            onClick={() => selectPlan("2")}
          />
          <PlanCard
            title="Enterprise Plan"
            price={trialPeriodEnding ? "1600" : "180"}
            IconComponent={RocketLaunch}
            isSelected={selectedPlan === "3"}
            onClick={() => selectPlan("3")}
          />
        </div>
      </Card>
      <div className="flex justify-end space-x-2 mt-4">
        <CustomButton
          width="20px"
          text="Cancel"
          bgColor="bg-white"
          textColor="text-text-primary"
          border="border"
          onClick={closeModal}
          className="hover:!bg-gray-200"
        />
        <CustomButton
          type="button"
          width="20px"
          text="Confirm"
          textColor="text-white"
          onClick={handleConfirmClick}
        />
      </div>
    </div>
  );
}

export default ChangeSubscription;
