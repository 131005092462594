import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, PencilIcon, Printer } from "../../../../common/Icons";
import { getClientProfile } from "../../../../../features/Clients/clientSlice";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";
import { getAllStaffUsers } from "../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";
import { ROLE } from "../../../../../enums/role";

const PlanTypes = [
  { value: "", label: "Plan Type" },
  { value: "Basic (ANNUAL)", label: "Basic (Annual)" },
  { value: "Professional (ANNUAL)", label: "Professional (Annual)" },
  { value: "Enterprise (ANNUAL)", label: "Enterprise (Annual)" },
  { value: "Basic (Monthly)", label: "Basic (Monthly)" },
  { value: "Professional (Monthly)", label: "Professional (Monthly)" },
  { value: "Enterprise (Monthly)", label: "Enterprise (Monthly)" },
];

const SortfromLatest = [
  { value: "", label: "Sort From Latest" },
  { value: "ASC", label: "Ascending" },
  { value: "DESC", label: "Descending" },
];

// Table headers
const tableHeaders = ["Client Name", "Email", "Phone Number", "Active Plan"];

function useClients() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [clientData, setClientData] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateClient, setUpdateClient] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { query } = useSelector((state) => state.search);
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [selectedStaffUser, setSelectedStaffUser] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const userId = storedUserData?.user?.id;
  const role = storedUserData?.user?.role;
  
  useEffect(() => {
    setClientsLoading(true);
    let searchParams = ["email", "profile.fullName", "profile.phoneNumber"];
    const condition = {
      subscription: {
        managedBy: { id: selectedStaffUser || null },
      },
    };
    if (selectedPlanType) {
      condition.subscription.planType = selectedPlanType || null;
    }

    if (role === ROLE.ACCOUNT_MANAGER) {
      setSelectedStaffUser(userId);
      condition.subscription.managedBy.id = userId;
    }

    dispatch(
      getClientProfile({
        payload: {
          page: currentPage,
          pageSize: 10,
          sortColumn: "id",
          order: {
            createdAt: selectedSort || "DESC",
          },
          condition: generatePayloadCondition(searchParams, query, condition),
          attributes: {},
        },
        successCallBack: onGetClientProfileSuccess,
      })
    );
  }, [
    currentPage,
    dispatch,
    updateClient,
    query,
    selectedPlanType,
    selectedStaffUser,
    selectedSort,
  ]);

  const onGetClientProfileSuccess = (response) => {
    setClientsList(response?.data?.data);
    setClientsLoading(false);
  };

  const capitalizePlanType = (planType) => {
    if (!planType) return "";
    return planType.replace(/\(([^)]+)\)/, (match, p1) => {
      return `(${p1.charAt(0).toUpperCase() + p1.slice(1).toLowerCase()})`;
    }).replace(/^\w/, c => c.toUpperCase()).replace(/\s+(\w)/g, c => c.toLowerCase());
  };
  
  const transformData = (clientsDetails) => {
    const initialUsers = clientsDetails?.map((item) => {
      return {
        ...item,
        clientname: item?.profile?.fullName ?? "__",
        email: item?.email ?? "__",
        phonenumber: item?.profile?.phoneNumber ?? "__",
        activeplan: capitalizePlanType(item?.subscription?.planType) ?? "__",
        imageSrc: item?.profile?.profilePic,
      };
    });
    return initialUsers;
  };

  // Function to handle editing a client
  const handleEdit = (user) => {
    setModalOpen(true);
    setClientData(user);
  };

  // Function to handle deleting a client
  const handleDelete = (user) => {
    setDeleteModalOpen(true);
    setClientData(user);
  };

  // Function to handle printing a client
  const handlePrint = (userId) => {
    // Logic for Print action
  };

  // Function to handle clicking on a row (navigating to details)
  const handleRowClick = (id) => {
    navigate(`/admin/clients/details/${id}`);
  };

  const dropdownOptions = [
    {
      label: "Edit",
      action: handleEdit,
      icon: <PencilIcon />,
      toShow: [ROLE.SUPER_ADMIN],
    },
    {
      label: "Print",
      action: handlePrint,
      icon: <Printer />,
      toShow: [ROLE.SUPER_ADMIN, ROLE.ACCOUNT_MANAGER],
    },
    {
      label: "Delete",
      action: handleDelete,
      icon: <DeleteIcon />,
      toShow: [ROLE.SUPER_ADMIN],
    },
  ];
  const clientsDropdownOptions = dropdownOptions.filter((item) =>
    item?.toShow?.includes(storedUserData?.user?.role)
  );

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: { id: "DESC" },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, [dispatch]);

  const { data: staffUser } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );

  const staffUserOptions = [
    { value: "", label: "Select Account Manager" },
    ...(staffUser?.filter((user) => user.role === "ACCOUNT_MANAGER").map((user) => ({
      value: user.id,
      label: user.fullName,
    })) || []),
  ];

  return {
    currentPage,
    setCurrentPage,
    handleEdit,
    handleDelete,
    handlePrint,
    setUpdateClient,
    handleRowClick,
    tableHeaders,
    isModalOpen,
    setModalOpen,
    isDeleteModalOpen,
    setDeleteModalOpen,
    clientData,
    setClientsList,
    transformData,
    clientsList,
    clientsDropdownOptions,
    clientsLoading,
    staffUserOptions,
    PlanTypes,
    SortfromLatest,
    setSelectedStaffUser,
    setSelectedPlanType,
    setSelectedSort,
    storedUserData,
  };
}

export default useClients;
