import React, { useEffect, useState } from "react";
import OrderDetail from "../PosHome/OrderDetail/OrderDetail";
import Items from "../PosHome/Items/Items";
import Categories from "../PosHome/Categories/Categories";
import { Card } from "../../common/components";
import { useSelector } from "react-redux";

/**
 * PosHome component rendering the Point of Sale (POS) interface.
 * @returns {JSX.Element} JSX element representing the PosHome component.
 */
const PosHome = () => {
  const orderDuplicateData = useSelector(
    (state) => state.posOrder.orderDuplicateData
  );
  const [selectedCategoryCardId, setSelectedCategoryCardId] = useState(null);
  const [selectedItems, setSelectedItems] = useState(orderDuplicateData?.orderedItems ?? []);
  const handleItemSelected = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
  };

  const handleItemDelete = (index) => {
    setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleCategorySelected = (categoryId) => {
    setSelectedCategoryCardId(categoryId);
  };

  return (
    <div className="flex lg:flex-nowrap flex-wrap gap-3 posbackground-image">
      <Card className="w-full">
        <Categories onCategorySelected={handleCategorySelected} />
        <Items selectedItems={selectedItems} setSelectedItems={setSelectedItems} onItemSelected={handleItemSelected} selectedCategoryCardId={selectedCategoryCardId} />
      </Card>
      <Card className="lg:w-auto w-full">
        <OrderDetail
          selectedItems={selectedItems}
          orderDuplicateData={orderDuplicateData}
          setSelectedItems={setSelectedItems}
          onItemDelete={handleItemDelete}
        />
      </Card>
    </div>
  );
};

export default PosHome;


// Sample Usage:
// import React from 'react';
// import { PosHome } from './PosHome';
//
//  Render the PosHome component
// <PosHome />
