import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  openModal,
  setActiveStep,
  setSelectedOption,
  updateTitle,
} from "../../../../../../features/PaymentsModal/PaymentsModalSlice";
import { Plus } from "../../../../../common/Icons/fontawesome";
import {
  Breadcrumb,
  CustomButton,
  InputGroup,
  Loader,
} from "../../../../../common/components";
import Layout from "../../../Layout/Layout";
import usePaymentsList from "./usePaymentsList";
import {
  ArrowLeft,
  CardIcon,
  CashIcon,
  CoinIcon,
  CrossIcon,
  TruckIcon,
} from "../../../../../common/Icons";
import {
  Points,
  CardPayment,
  CashPayment,
  DeliveryCompany,
} from "../PaymentMethods";
import ShowPaymentDetail from "../PaymentMethods/ShowPaymentDetail";
import PaymentsTable from "../PaymentsTable/PaymentsTable";
import { CircleCards } from "../../../../../../assets/images";
import EditCardMethod from "../PaymentMethods/EditCardMethod";
import EditPointsMethod from "../PaymentMethods/EditPointsMethod";
import EditDeliveryCompanies from "../PaymentMethods/EditDeliveryCompanies";
import DeletePaymentMethod from "../PaymentMethods/DeletePaymentMethod";
import _ from "lodash";

/**
 * Payment Methods List component
 * Renders table of payment methods list
 *
 * @returns {JSX.Element} JSX code for rendering the payment methods list
 */

export default function PaymentsList() {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Handles the action when an icon is clicked.
   *
   * @param {Object} item - Selected item.
   * @param {string} id - Item ID.
   * @param {string} type - Type of action.
   * @param {string} action - Action to perform.
   * @param {number} idx - Index.
   */
  const handleIconAction = (item, id, type, action, idx) => {
    setSelectedItem(item);
    dispatch(setSelectedOption(type));

    switch (action) {
      case "edit":
        handleEdit(type);
        break;
      case "view":
        handleView(type);
        break;
      case "delete":
        handlePaymentMethodActions("delete");
        break;
      default:
        break;
    }
  };

  // Destructuring values from paymentsModal state
  const { activeStep, selectedOption, type, title, isModalOpen } = useSelector(
    (state) => state.paymentsModal
  );

  const {
    paymentMethodsList,
    pointDetail,
    deliveryDetail,
    isLoading,
    cardsList,
    cardDetail,
    singleDetailLoading,
  } = usePaymentsList(selectedItem?.id);

  // Refs to access handleSubmit functions of child components
  const pointsRef = useRef();
  const cardPaymentRef = useRef();
  const cashPaymentRef = useRef();
  const deliveryPaymentRef = useRef();

  const handleView = (type) => {
    dispatch(
      openModal({
        title: `Details (${type})`,
        type: "detail",
        editable: true,
      })
    );
  };

  const handlePaymentMethodActions = (type) => {
    dispatch(
      openModal({
        title: `${type} Payment Method`,
        type: type,
        editable: true,
        activeStep: 1,
      })
    );
  };

  /**
   * Handle option change for item type.
   *
   * @param {string} item - Selected item type.
   */
  const handleOptionChange = (item) => {
    dispatch(setSelectedOption(item));
  };

  /**
   * Handle moving to the next step.
   */
  const handleNext = () => {
    dispatch(setActiveStep(2));
    dispatch(updateTitle(`Add Method (${selectedOption})`));
  };

  /**
   * Handle moving back to the previous step.
   */
  const handleBack = () => {
    dispatch(setActiveStep(1));
    dispatch(updateTitle("Add Payment Method"));
  };

  // Function to handle form submission for different payment methods
  const handleSubmitForms = (type) => {
    const refs = {
      POINTS: pointsRef,
      CARD: cardPaymentRef,
      CASH: cashPaymentRef,
      DELIVERY_COMPANIES: deliveryPaymentRef,
    }[type];

    // Check if the ref exists and has a current property
    if (refs && refs.current && refs.current.handleSubmit) {
      // Call the handleSubmit method if available
      refs.current.handleSubmit();
    }
  };

  const handleEdit = (type) => {
    dispatch(
      openModal({
        title: `Edit`,
        type: "edit",
        editable: true,
      })
    );
  };

  // show detail for specific payment method
  const paymentDetails = {
    POINTS: pointDetail,
    CARD: cardDetail,
    CASH: "variableItemDetail",
    DELIVERY_COMPANIES: deliveryDetail,
  }[selectedOption];

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between mb-4">
        <Breadcrumb pages={pages} />
        <CustomButton
          text="Add Payment Method"
          width="w-fit"
          bgColor="!bg-surface-primary"
          hoverTextColor="text-white"
          className="hover:!text-white disabled:!bg-gray-300"
          icon={<Plus color="white" />}
          disabled={
            paymentMethods?.filter(
              (item) =>
                !paymentMethodsList?.some(
                  (method) => method.type === item.value
                )
            ).length === 0
          }
          onClick={() => handlePaymentMethodActions("add")}
        />
      </div>
      {/* payment methods list */}
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {paymentMethodsList?.length > 0 ? (
              <PaymentsTable
                paymentMethodsList={paymentMethodsList}
                handleIconAction={handleIconAction}
              />
            ) : (
              <div className="bg-neutral-50 rounded flex items-center justify-center h-[60vh]">
                <div className="flex flex-col space-y-2 justify-center items-center text-center w-fit">
                  <img src={CircleCards} alt="Cards" width={192} height={160} />
                  <span className="text-lg font-semibold to-text-primary">
                    No Data Available
                  </span>
                  <p className="text-sm font-normal text-text-secondary w-80">
                    No payment method to display in this page. You can add a new
                    method to populate this list.
                  </p>
                  <CustomButton
                    text="Add Payment Method"
                    width="w-fit"
                    textColor="text-text-primary"
                    icon={<Plus color="black" />}
                    border="border"
                    onClick={() => handlePaymentMethodActions("add")}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* conditionally render modal for different actions */}

      {isModalOpen && type === "add" && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full self-center">
            {/* header */}
            <div className="flex items-center justify-between py-2 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {activeStep === 2 && (
                  <CustomButton
                    icon={<ArrowLeft />}
                    text=""
                    padding="p-2"
                    border="border"
                    width="w-fit"
                    onClick={handleBack}
                  />
                )}
                {title
                  .split("_")
                  .map((word) => _.capitalize(word))
                  .join(" ")}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            {type === "add" && (
              <div className="p-5 space-y-3">
                {activeStep === 1 && (
                  <div className="flex flex-col items-center justify-center space-y-3">
                    <h4 className="text-lg font-semibold to-text-primary text-center !my-3">
                      What kind of payment?
                    </h4>
                    {paymentMethods?.filter(
                      (item) =>
                        !paymentMethodsList?.some(
                          (method) => method.type === item.value
                        )
                    ).length === 0 ? (
                      <div className="text-center">
                        All payment methods are already added.
                      </div>
                    ) : (
                      paymentMethods
                        ?.filter(
                          (item) =>
                            !paymentMethodsList?.some(
                              (method) => method.type === item.value
                            )
                        )
                        .map((item, index) => (
                          <div
                            key={item?.value}
                            className={`p-4 flex items-center border gap-4 rounded-lg relative max-w-[400px] w-full ${
                              selectedOption === item.value &&
                              "border-sky-blue bg-info-50"
                            } `}
                          >
                            <div
                              onClick={() => handleOptionChange(item?.value)}
                              className="absolute inset-0 bg-transparent z-20 cursor-pointer"
                            />

                            <item.icon stroke={selectedOption === item.value} />

                            <InputGroup
                              inputName="simple"
                              labelText={item?.name}
                              inputType="radio"
                              className="flex items-center justify-between w-full !space-y-0"
                              padding="p-0"
                              checked={selectedOption === item?.value}
                              onChange={handleOptionChange}
                            />
                          </div>
                        ))
                    )}
                  </div>
                )}
                {activeStep === 2 && (
                  <form className="space-y-3">
                    {selectedOption === "POINTS" && (
                      <Points
                        setIsSubmitting={setIsSubmitting}
                        ref={pointsRef}
                      />
                    )}
                    {selectedOption === "CASH" && (
                      <CashPayment
                        ref={cashPaymentRef}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                    {selectedOption === "CARD" && (
                      <CardPayment
                        ref={cardPaymentRef}
                        cardsList={cardsList}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                    {selectedOption === "DELIVERY_COMPANIES" && (
                      <DeliveryCompany
                        ref={deliveryPaymentRef}
                        setIsSubmitting={setIsSubmitting}
                      />
                    )}
                  </form>
                )}
              </div>
            )}

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              {type !== "delete" ? (
                <CustomButton
                  text={activeStep === 1 ? "Next" : "Add Method"}
                  width="w-fit"
                  padding="py-2 px-3"
                  disabled={activeStep === 2 && isSubmitting}
                  onClick={
                    activeStep === 1
                      ? handleNext
                      : () => handleSubmitForms(selectedOption)
                  }
                />
              ) : (
                <CustomButton
                  width="w-fit"
                  padding="py-2 px-3"
                  className="leading-none"
                  bgColor={
                    type === "delete" ? "bg-red-500" : "bg-surface-primary"
                  }
                  type="submit"
                  text="Delete"
                />
              )}
            </div>
          </div>
        </div>
      )}
      {type === "edit" && selectedOption === "CARD" && (
        <EditCardMethod
          cardsList={cardsList}
          selected={selectedItem}
          selectedCards={cardDetail}
        />
      )}
      {type === "edit" && selectedOption === "POINTS" && (
        <EditPointsMethod selected={selectedItem} />
      )}
      {type === "edit" && selectedOption === "DELIVERY_COMPANIES" && (
        <EditDeliveryCompanies selected={selectedItem} />
      )}

      {type === "detail" && (
        <ShowPaymentDetail
          paymentDetail={paymentDetails}
          loading={singleDetailLoading}
        />
      )}
      {type === "delete" && (
        <DeletePaymentMethod
          paymentId={selectedItem ? selectedItem?.id : null}
        />
      )}
    </Layout>
  );
}

// breadcrumbs pages
const pages = [
  { name: "client_dashboard.management.name", href: "#", current: false },
  { name: "client_dashboard.management.payments", href: "#", current: true },
];

const paymentMethods = [
  { name: "Points", value: "POINTS", icon: CoinIcon },
  { name: "Card", value: "CARD", icon: CardIcon },
  { name: "Cash", value: "CASH", icon: CashIcon },
  { name: "Delivery Companies", value: "DELIVERY_COMPANIES", icon: TruckIcon },
];

// sample usage
// import PaymentsList from "./path/to/PaymentsList.jsx";

// <PaymentsList />
