import React, { useEffect, useMemo } from "react";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import { useTranslation } from "react-i18next";

/**
 * AddEditCustomer component.
 * Renders a form to add or edit a customer
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditCustomer component
 */

const AddEditSupplier = ({
  customer,
  register,
  errors,
  setValue,
  watch,
  setIsInputChanged,
}) => {
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => ({
      englishname: customer?.name ?? "",
      email: customer?.email ?? "",
      company: customer?.companyName ?? "",
      phonenumber: customer?.phoneNumber ?? "",
      address: customer?.address ?? "",
      vatnumber: +customer?.vatNumber ?? "",
    }),
    [customer]
  );

  useEffect(() => {
    // set the default values for each field to edit customer using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [customer, setValue, defaultValues]);

  // set currentValues to track changes in the input fields using watch method
  const currentValues = watch();

  useEffect(() => {
    // Check if the current state is different from the initial state
    setIsInputChanged(
      Object.keys(defaultValues).some(
        (keys) =>
          JSON.stringify(defaultValues[keys]) !==
          JSON.stringify(currentValues[keys])
      )
    );
  }, [currentValues, defaultValues]);

  return (
    <React.Fragment>
      {/* name */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.english_name")}
        htmlFor="englishname"
        inputName="englishname"
        placeholder={t(
          "client_dashboard.directory.supplier.enter_english_name"
        )}
        register={register}
        errors={errors}
      />
      {/*company Name */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.company_name")}
        htmlFor="company"
        inputName="company"
        placeholder={t(
          "client_dashboard.directory.supplier.enter_company_name"
        )}
        register={register}
        errors={errors}
      />
      {/* email */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.email")}
        htmlFor="email"
        inputName="email"
        placeholder={t("client_dashboard.directory.supplier.enter_email")}
        register={register}
        errors={errors}
      />
      {/* phone */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.phone_number")}
        htmlFor="phonenumber"
        inputName="phonenumber"
        placeholder={t("client_dashboard.directory.supplier.enter_phone")}
        register={register}
        errors={errors}
      />
      {/* Address */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.enter_address")}
        htmlFor="address"
        inputType="textarea"
        placeholder={t("client_dashboard.directory.supplier.enter_addreess")}
        inputName="address"
        register={register}
        errors={errors}
        className="my-4"
      />

      {/* VAT */}
      <InputGroup
        labelText={t("client_dashboard.directory.supplier.vat_number")}
        htmlFor="vatnumber"
        inputName="vatnumber"
        placeholder={t("client_dashboard.directory.supplier.enter_vatnumber")}
        register={register}
        errors={errors}
      />
    </React.Fragment>
  );
};

export default AddEditSupplier;
