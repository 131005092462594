import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { getAllItems } from "../../../../../features/Items/ItemsSlice";
import {
  deleteInventoryTransfer,
  getAllInventoryTransfers,
  getSingleInventoryTransfer,
} from "../../../../../features/InventoryTransfer/InventoryTransferSlice";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling inventory transfer related functionalities
 *
 * @returns {Object}
 *   inventoryTransferList: Array containing inventory transfer information for all transfers
 *   isLoading: Data loading state for all inventory transfers
 *   singleInventoryTransfer: Object containing inventory transfer information
 *   singleInventoryTransferLoading: Data loading state for single inventory transfer information
 */
const useInventoryTransferList = (inventoryTransferId) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.search);

  // get single inventory transfer information from the store
  const {
    data: singleInventoryTransfer,
    isLoading: singleInventoryTransferLoading,
  } = useSelector(
    (state) => state.inventoryTransfer.getSingleInventoryTransfer
  );
  // get inventory transfer list for all transfers from the store
  const {
    data: inventoryTransferList,
    isLoading: inventoryTransferListLoading,
  } = useSelector((state) => state.inventoryTransfer.getAllInventoryTransfers);
  // get store list for all stores from the redux store
  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.stores.getAllStores
  );
  // get item list for all items from the store
  const { data: itemList, isLoading: itemListLoading } = useSelector(
    (state) => state.items.getAllItems
  );

  // validation schema for add/edit inventory transfer form
  const addEditInventoryTransferSchema = z.object({
    storeto: z.coerce
      .number()
      .int()
      .min(1, { message: "Store to is required!" }),
    storefrom: z.coerce
      .number()
      .int()
      .min(1, { message: "Store from is required!" }),

    date: z.string().min(1, "Date is required!"),
    store: z.coerce.number().int().min(1, { message: "Store is required!" }),
    item: z.coerce.number().int().min(1, { message: "Item is required!" }),
    description: z
      .string()
      .min(1, "Description is required!")
      .max(200, "Maximum 200 characters are allowed"),
    expected: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .int()
      .min(1, { message: "Expected is required!" }),
    quantity: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .int()
      .min(1, { message: "Quantity is required!" }),
    costPrice: z.coerce
      .number({ invalid_type_error: "Only numbers are allowed!" })
      .int()
      .min(1, { message: "Cost Price is required!" }),
    note: z.string().min(1, "Note is required!"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(addEditInventoryTransferSchema),
    defaultValues: {
      items: [{}],
    },
  });

  const inventoryDetail = singleInventoryTransfer
    ? {
        "Store From": singleInventoryTransfer?.storeFrom?.branchName,
        "Store To": singleInventoryTransfer?.storeTo?.branchName,
        date: singleInventoryTransfer?.date,
        Item: singleInventoryTransfer?.transferredItems[0]?.itemDetail
          ?.englishName,
        "Unit Of Measure":
          singleInventoryTransfer?.transferredItems[0]?.itemDetail
            ?.measureOfUnit,
        "Number Of Measure":
          singleInventoryTransfer?.transferredItems[0]?.itemDetail
            ?.noMeasureOfUnit,
        "Total Quantity":
          singleInventoryTransfer?.transferredItems[0]?.itemDetail?.quantity,
        Quantity: singleInventoryTransfer?.transferredItems[0]?.quantity,
        Description:
          singleInventoryTransfer?.transferredItems[0]?.itemDetail?.description,
      }
    : null;

  const onSubmit = async (data) => {
    // Handle form submission here
  };

  const onDeleteInventoryTransfer = () => {
    if (inventoryTransferId) {
      dispatch(
        deleteInventoryTransfer({
          inventoryTransferId: inventoryTransferId,
          successCallBack: onInventoryTransferUpdate,
        })
      );
    }
  };

  /**
   * Handles the api call for getting all items
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!itemList) dispatch(getAllItems({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInventoryTransferUpdate = () => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllInventoryTransfers({ payload }));
    dispatch(closeModal());
  };

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!storesList) dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = [
      "createdBy",
      "storeTo.branchName",
      "storeFrom.branchName",
    ];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),

      attributes: {},
    };
    dispatch(getAllInventoryTransfers({ payload }));
  }, [currentPage, query, dispatch]);

  useEffect(() => {
    // Fetch single inventory transfer information when inventoryTransferId changes
    // Dispatch action to get single inventory transfer
  }, [inventoryTransferId]);

  useEffect(() => {
    if (inventoryTransferId)
      dispatch(getSingleInventoryTransfer({ inventoryTransferId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryTransferId]);

  useEffect(() => {
    // Fetch stores list when component mounts if not already fetched
    // Dispatch action to get all stores
  }, []);

  useEffect(() => {
    // Fetch item list when component mounts if not already fetched
    // Dispatch action to get all items
  }, []);

  return {
    inventoryTransferList,
    inventoryTransferListLoading,
    singleInventoryTransferLoading,
    singleInventoryTransfer,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    onDeleteInventoryTransfer,
    isSubmitting,
    storesList,
    storeListLoading,
    itemList,
    watch,
    inventoryDetail,
    currentPage,
    setCurrentPage,
    clearErrors,
    itemListLoading,
  };
};

export default useInventoryTransferList;
