import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  addPosUser,
  deletePosUser,
  getAllPosUsers,
  getSinglePosUser,
  updatePosUser,
} from "../../../../../../features/PosUsers/PosUsersSlice";
import { closeModal } from "../../../../../../features/Modal/ModalSlice";
import { getAllStores } from "../../../../../../features/Stores/StoresSlice";
import { generatePayloadCondition } from "../../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling users related functionalities

 /**
   *
   * @returns {Object}
   *   userList: Array containing user information for all users
   *   isLoading: Data loading state for all users
   *   singleUser: Object contiaining user information
   *   singleUserLoading: Data loading state for singel user information
 */
const useUsersList = (userId) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { query } = useSelector((state) => state.search);

  // state to track input fields

  // get single user information from the store
  const { data: singleUser, isLoading: singleUserLoading } = useSelector(
    (state) => state.posUser.getSinglePosUser
  );
  // get user list for all users from the store
  const { data: userList, isLoading: userListLoading } = useSelector(
    (state) => state.posUser.getAllPosUsers
  );
  // get store list for all stores from the redux store
  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.stores.getAllStores
  );
  const stores = z.object({
    label: z.string().optional(),
    value: z.coerce
      .number({
        invalid_type_error:
          "client_dashboard.management.users.validations.only_numbers_error",
      })
      .int()
      .min(1, {
        message: "client_dashboard.management.users.validations.store_error",
      }),
  });

  // Now add this object into an array
  // validation schema for add/edit user form
  const addEditUserSchema = z.object({
    name: z
      .string()
      .min(3, "client_dashboard.management.users.validations.name_error")
      .max(100, "client_dashboard.management.users.validations.name_error_max"),
    email: z
      .string()
      .min(1, "client_dashboard.management.users.validations.email_error")
      .email("client_dashboard.management.users.validations.email_error_valid"),
    createpassword: z
      .string()
      .min(8, "client_dashboard.management.users.validations.password_error")

      .regex(
        /^(?=.*[A-Z])/,
        "client_dashboard.management.users.validations.password_error_uppercase"
      )
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        "client_dashboard.management.users.validations.password_error_special"
      )
      .regex(
        /\d/,
        "client_dashboard.management.users.validations.password_error_numeric"
      ),
    role: z
      .string()
      .min(1, "client_dashboard.management.users.validations.role_error"),
    selectedStores: z.array(stores),

    code: z.coerce
      .number({
        invalid_type_error:
          "client_dashboard.management.users.validations.only_numbers_error",
      })
      .int()
      .min(1000, {
        message: "client_dashboard.management.users.validations.code_error",
      })
      .max(9999, {
        message: "client_dashboard.management.users.validations.code_error",
      }),
    accesstodashboard: z.boolean(),
    accesstopos: z.boolean(),
  });

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   * @property {Function} getValues - Function to retrieve form input values.
   */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange", // Setting the form mode to trigger validation on input change
    defaultValues: { selectedStores: [] },
    resolver: zodResolver(addEditUserSchema),
  });

  /**
   * Handler function for form submission to update user information.
   * This function can be async if needed for API calls or other asynchronous operations.
   * @param {Object} data - Form data provided by react-hook-form upon successful validation.
   *                        Example: Name, email, role etc
   */
  const onSubmit = async (data) => {
    const payload = {
      fullName: data?.name,
      email: data?.email,
      fastAccessCode: +data?.code,
      password: data?.createpassword,
      role: data?.role,
      accessToDashboard: data?.accesstodashboard ?? false,
      accessToPos: data?.accesstopos ?? false,
      storeIds: data?.selectedStores?.map((id) => id?.value) || [],
    };
    if (userId && modalState?.type === "edit")
      return dispatch(
        updatePosUser({
          userId,
          payload,
          successCallBack: onUserUpdate,
        })
      );
    else {
      return dispatch(
        addPosUser({
          payload,
          successCallBack: onUserUpdate,
        })
      );
    }
  };

  /**
   * This function is called when the user is updated successfully!
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting users
   *    order: order for sorting users by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  const onUserUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosUsers({ payload }));
    dispatch(closeModal());
  };

  /**
   * This function is used to delete a user
   *
   *  @param {object} userId The is of the user to delete
   *  @param {function} successCallBack This function will be called on the success of delete user function
   */

  const deleteUser = () => {
    if (userId) {
      dispatch(deletePosUser({ userId, successCallBack: onUserUpdate }));
    }
  };

  /**
   * Handles the api call for getting a specific user
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} userId id of the user
   */

  useEffect(() => {
    if (userId) dispatch(getSinglePosUser({ userId }));
  }, [dispatch, userId]);

  /**
   *  Extracting userDetail object from singleUser object for user detail modal
   * @returns {Object}
   * 
   *   name: Name of the user
   *   email: Email of the user
   *   POS Fast Access Code: POS code of the user
   *   role: Role of the user
   *  Access to Dashboard: User access to Dashboard
      Access to POS: User access to POS
 */

  const userDetail = singleUser
    ? {
        name: singleUser?.fullName,
        email: singleUser?.email,
        "POS Fast Access Code": singleUser?.fastAccessCode,
        Store: singleUser?.stores?.map((store) => store.branchName).join(", "),
        role: singleUser?.role,
        "Access to Dashboard": singleUser?.accessToDashboard ? "Yes" : "No",
        "Access to POS": singleUser?.accessToPos ? "Yes" : "No",
      }
    : null;

  /**
   * Handles the api call for getting all users
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting users
   *    order: order for sorting users by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["fullName", "email"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };
    dispatch(getAllPosUsers({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, query]);

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!storesList) dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @returns {Object}
   * {
   *   simplifiedUserList: Object containing only required user information for all users
   *   isLoading: Data loading state for all users
   *   singleUser: Object contiaining user information
   *   singleUserLoading: Data loading state for singel user information
   *   userDetail: Objecct cotnaining only required user information
   *   register: Function provided by react-hook-form to register input fields,
   *   handleSubmit: Function provided by react-hook-form to handle form submission,
   *   errors: Object Function provided by react-hook-form to handle validation errors,
   *   setValue: Object Function provided by react-hook-form to handle value changes,
   * }
   * Usage Example:
   * Accesses the form handling functions, error.
   * const { userList, isLoading } = useUsersList();
   *
   * userList:
   *  Example usage: {simplifiedUserList && simplifiedUserList.map(user => user.name)}
   * isLoading::
   *  Example usage: {isLoading  && <Loader />}
   * singleUserLoading::
   *  Example usage: {singleUserLoading  && <Loader />}
   * userDetail:
   *  Example usage: {userDetail && userDetail.map(user => user.name)}
   * regiser:
   *  Example usage: <input {...register("email", { required: true })}
   * handleSubmit:
   *  Example usage: <form onSubmit={handleSubmit(onSubmit)}>
   * errors:
   *  Example usage: {errors.email && <span>Email is required</span>}
   */
  return {
    userList,
    userListLoading,
    singleUserLoading,
    userDetail,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    deleteUser,
    isSubmitting,
    storesList,
    storeListLoading,
    watch,
    currentPage,
    setCurrentPage,
    clearErrors,
    control,
  };
};

export default useUsersList;
