import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CustomButton,
  InputGroup,
  Loader,
  SelectGroup,
} from "../../../../../common/components";

import { closeModal } from "../../../../../../features/Modal/ModalSlice";

import {
  addExpenseInvoice,
  getAllExpenseInvoices,
  updateExpenseInvoice,
} from "../../../../../../features/Invoices/ExpenseInvoices/ExpenseInvoiceSlice";
import { useTranslation } from "react-i18next";
import { CrossIcon, FileIcon } from "../../../../../common/Icons";
import { uploadSingleFile } from "../../../../../../features/UploadFile/UploadFileSlice";
import { PdfIcon } from "../../../../../common/Icons/fontawesome";
import { getAllItems } from "../../../../../../features/Items/ItemsSlice";
import { showToast } from "../../../../../common/utils/showToast.util";

// Validation schema for add/edit sale invoice form
const addInvoiceSchema = z.object({
  expensename: z
    .string({
      required_error:
        "client_dashboard.invoices.expense.validations.expensename_error",
    })
    .min(
      3,
      "client_dashboard.invoices.expense.validations.expensename_error_min"
    )
    .max(
      100,
      "client_dashboard.invoices.expense.validations.expensename_error_max"
    ),
  invoicenumber: z.coerce
    .number({
      invalid_type_error:
        "client_dashboard.invoices.expense.validations.invoicenumber_error_invalid",
      required_error:
        "client_dashboard.invoices.expense.validations.invoicenumber_error",
    })
    .min(
      99,
      "client_dashboard.invoices.expense.validations.invoicenumber_error_min"
    )
    .max(1000000, {
      message: "client_dashboard.invoices.expense.validations.max_number_error",
    }),
  store: z.coerce.number().int().min(1, {
    message: "client_dashboard.invoices.expense.validations.store_error",
  }),
  date: z
    .string()
    .min(1, "client_dashboard.invoices.expense.validations.date_error"),
  paymentmethod: z
    .string()
    .min(
      1,
      "client_dashboard.invoices.expense.validations.paymentmethod_error"
    ),
  paymentStatus: z
    .string()
    .min(
      1,
      "client_dashboard.invoices.purchase.validations.paymentStatus_error"
    ),
  expensecategory: z
    .string({
      required_error:
        "client_dashboard.invoices.expense.validations.expensecategory_error",
    })
    .min(
      1,
      "client_dashboard.invoices.expense.validations.expensecategory_error"
    ),
  amount: z.coerce
    .number()
    .min(1, "client_dashboard.invoices.expense.validations.amount_error")
    .max(1000000, {
      message: "client_dashboard.invoices.expense.validations.max_number_error",
    }),
  beforetax: z.coerce
    .number()
    .min(1, "client_dashboard.invoices.expense.validations.beforetax_error")
    .max(1000000, {
      message: "client_dashboard.invoices.expense.validations.max_number_error",
    }),
  taxamount: z.coerce
    .number()
    .min(1, "client_dashboard.invoices.expense.validations.taxamount_error")
    .max(1000000, {
      message: "client_dashboard.invoices.expense.validations.max_number_error",
    }),
  total: z.coerce
    .number()
    .min(1, "client_dashboard.invoices.expense.validations.total_error")
    .max(1000000, {
      message: "client_dashboard.invoices.expense.validations.max_number_error",
    }),
  note: z.string().optional(),
});

const AddInvoice = forwardRef(
  ({ expenseInvoice, storesList, setIsFormSubmitting }, ref) => {
    const modalState = useSelector((state) => state.modal);
    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);
    const [filePreview, setFilePreview] = useState(
      expenseInvoice && {
        type: expenseInvoice?.attachment?.url?.split(".")?.pop(),
        image: expenseInvoice?.attachment?.url,
        name: expenseInvoice?.attachment?.name,
        size: expenseInvoice?.attachment?.size,
      }
    );
    const [imageUploadError, setImageUploadError] = useState("");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const defaultValues = useMemo(
      () => ({
        expensename: expenseInvoice?.name,
        invoicenumber: expenseInvoice?.invoiceNumber,
        expensecategory: expenseInvoice?.expenseInvoiceItem?.expenseCategory,
        date: expenseInvoice?.supplyDate ?? "",
        paymentmethod: expenseInvoice?.paymentMethod ?? "",
        paymentStatus: expenseInvoice?.paymentStatus ?? "",
        supplierid: expenseInvoice?.supplier?.id ?? "",
        store: expenseInvoice?.store?.id ?? "",
        amount: expenseInvoice?.expenseInvoiceItem?.amount ?? "",
        beforetax: expenseInvoice?.expenseInvoiceItem?.beforeTax ?? "",
        taxamount: expenseInvoice?.expenseInvoiceItem?.taxAmount ?? "",
        total: expenseInvoice?.expenseInvoiceItem?.total ?? "",
        note: expenseInvoice?.expenseInvoiceItem?.note ?? "",
      }),
      [expenseInvoice]
    );

    // Form handling using react-hook-form
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors, isSubmitting },
    } = useForm({
      resolver: zodResolver(addInvoiceSchema),
    });

    useEffect(() => {
      // Set the default values for each field to edit supplier using 'setValue' method of react-hook-form
      defaultValues &&
        Object.keys(defaultValues).forEach((key) =>
          setValue(key, defaultValues[key])
        );
    }, [expenseInvoice, setValue, defaultValues]);

    useEffect(() => {
      setIsFormSubmitting(!isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          name: data?.expensename,
          invoiceNumber: +data?.invoicenumber,
          supplyDate: data?.date,
          paymentMethod: data?.paymentmethod,
          paymentStatus: data?.paymentStatus,
          attachment: {
            name: uploadFileData?.uploadSingleFile?.data?.name,
            url: uploadFileData?.uploadSingleFile?.data?.url,
            size: uploadFileData?.uploadSingleFile?.data?.size,
          },
          storeId: +data?.store,
          invoiceItem: {
            expenseCategory: data?.expensecategory,
            amount: +data?.amount,
            beforeTax: +data?.beforetax,
            taxAmount: +data?.taxamount,
            total: +data?.total,
            note: data?.note,
          },
        };

        if (modalState?.type === "edit") {
          return dispatch(
            updateExpenseInvoice({
              payload: payload,
              invoiceId: expenseInvoice?.id,
              successCallBack: onExpenseInvoiceUpdate,
            })
          );
        } else {
          return dispatch(
            addExpenseInvoice({
              payload,
              successCallBack: onExpenseInvoiceUpdate,
            })
          );
        }
      }),
    }));

    const onExpenseInvoiceUpdate = () => {
      if (modalState?.type === "duplicate") {
        showToast("Invoice duplicated successfully!");
      }
      if (modalState?.type === "add") {
        showToast("Invoice created successfully!");
      }
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      const payloadItems = {
        page: 1,
        pageSize: 1000,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };

      dispatch(getAllItems({ payload: payloadItems }));
      dispatch(getAllExpenseInvoices({ payload }));
      dispatch(closeModal());
    };

    const handleImageChange = (event) => {
      const file = event.target.files[0];

      if (file) {
        // Check file type
        const validTypes = ["image/jpeg", "image/png", "application/pdf"]; // Include PDF type
        if (!validTypes.includes(file.type)) {
          setImageUploadError(
            "client_dashboard.management.settings.profile.validations.img_err_type"
          );
          return;
        }

        // Update state and dispatch if all validations pass
        setImageUploadError(""); // Clear any existing error
        const formImgData = new FormData();
        formImgData.append("file", file);
        if (file.type.startsWith("image")) {
          // If it's an image file, set file preview
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            setFilePreview(img);
          };
        }
        setFilePreview(file);
        dispatch(uploadSingleFile({ payload: formImgData }));
      } else {
        setImageUploadError("Uploading an image is mandatory.");
      }
    };

    return (
      <React.Fragment>
        {/*Expense Name */}
        <InputGroup
          labelText={"client_dashboard.invoices.expense.expense_name"}
          htmlFor="expensename"
          inputName="expensename"
          placeholder={"client_dashboard.invoices.expense.enter_expense_name"}
          register={register}
          errors={errors}
        />
        {/* invoice number */}
        <InputGroup
          labelText={t("client_dashboard.invoices.expense.invoice_no")}
          htmlFor="invoicenumber"
          inputName="invoicenumber"
          placeholder={"client_dashboard.invoices.expense.enter_invoice_no"}
          register={register}
          errors={errors}
        />
        {/* Store Selection */}
        <SelectGroup
          labelText={"client_dashboard.invoices.expense.store"}
          htmlFor="store"
          inputName="store"
          options={[
            {
              value: "",
              label: "client_dashboard.invoices.expense.select_store",
            },
            ...storesList?.map((option) => ({
              value: +option?.id,
              label: option?.branchName,
            })),
          ]}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        {/* Date */}
        <InputGroup
          labelText={"client_dashboard.invoices.expense.supply_date"}
          inputType="date"
          htmlFor="date"
          inputName="date"
          register={register}
          errors={errors}
        />
        {/* Payment Method */}
        <SelectGroup
          labelText={"client_dashboard.invoices.expense.payment_method"}
          htmlFor="paymentmethod"
          inputName="paymentmethod"
          options={paymentMethods}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />

        {/* Payment Status */}
        <SelectGroup
          labelText={"client_dashboard.invoices.purchase.payment_status"}
          htmlFor="paymentStatus"
          inputName="paymentStatus"
          options={paymentStatus}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />

        {uploadFileData?.uploadSingleFile?.isLoading ? (
          <Loader />
        ) : filePreview ? (
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              {filePreview?.type === "application/pdf" ||
              filePreview?.type === "pdf" ? (
                <PdfIcon />
              ) : (
                <img
                  src={
                    filePreview?.image ||
                    uploadFileData?.uploadSingleFile?.data?.url
                  }
                  alt=""
                  className="w-24 h-16"
                />
              )}
              <div className="flex flex-col justify-between">
                <span>
                  {filePreview?.name ||
                    uploadFileData?.uploadSingleFile?.data?.name}
                </span>
                <span>
                  {filePreview?.size ||
                    uploadFileData?.uploadSingleFile?.data?.size}{" "}
                  KB
                </span>
              </div>
            </div>
            <span
              className="cursor-pointer"
              onClick={() => setFilePreview(null)}
            >
              <CrossIcon />
            </span>
          </div>
        ) : (
          <CustomButton
            text="Attach File"
            bgColor="bg-transparent"
            textColor="tex-text-primary"
            border="border"
            icon={<FileIcon />}
            className="relative"
          >
            <input
              type="file"
              className="absolute opacity-0 inset-0 cursor-pointer"
              onChange={handleImageChange}
            />
          </CustomButton>
        )}
        {imageUploadError && (
          <p className="text-red-500 text-sm">{t(imageUploadError)}</p>
        )}

        {/* Expense Details */}
        <hr className="bg-border-primary" />
        <h6 className="text-text-primary font-semibold text-base">
          {t("client_dashboard.invoices.expense.expense_details")}
        </h6>
        {/* Display expense fields */}
        {/* category */}
        <SelectGroup
          labelText={"client_dashboard.invoices.expense.expense_category"}
          htmlFor="expensecategory"
          inputName="expensecategory"
          options={expenseCategories}
          register={register}
          errors={errors}
          className="rtl:bg-left"
        />
        <InputGroup
          labelText={"client_dashboard.invoices.expense.amount"}
          htmlFor="amount"
          inputName="amount"
          placeholder={"client_dashboard.invoices.expense.enter_amount"}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={"client_dashboard.invoices.expense.before_tax"}
          htmlFor="beforetax"
          inputName="beforetax"
          placeholder={
            "client_dashboard.invoices.expense.enter_before_tax_price"
          }
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={"client_dashboard.invoices.expense.tax_amount"}
          htmlFor="taxamount"
          inputName="taxamount"
          placeholder={"client_dashboard.invoices.expense.enter_tax_amount"}
          register={register}
          errors={errors}
        />
        <InputGroup
          labelText={t("client_dashboard.invoices.expense.total")}
          htmlFor="total"
          inputName="total"
          placeholder={"client_dashboard.invoices.expense.enter_total_amount"}
          register={register}
          errors={errors}
        />
        {/* note */}
        <InputGroup
          htmlFor="note"
          inputName="note"
          labelText={"client_dashboard.invoices.expense.note"}
          placeholder={"client_dashboard.invoices.expense.enter_note"}
          register={register}
          errors={errors}
        />
      </React.Fragment>
    );
  }
);

export default AddInvoice;

const paymentStatus = [
  {
    value: "",
    label: "client_dashboard.invoices.purchase.select_payment_status",
  },
  { label: "Fully Paid", value: "Fully Paid" },
  { label: "Partially Paid", value: "Partially Paid" },
];

const paymentMethods = [
  {
    value: "",
    label: "client_dashboard.invoices.expense.select_payment_method",
  },
  { label: "Cash", value: "Cash" },
  { label: "Mada", value: "Mada" },
  { label: "Visa", value: "Visa" },
  { label: "Bank transfer", value: "Bank transfer" },
];

const expenseCategories = [
  {
    value: "",
    label: "client_dashboard.invoices.expense.select_expense_category",
  },
  { label: "Governmental fees", value: "Governmental fees" },
  { label: "Devices and machines", value: "Devices and machines" },
  { label: "Utility bills", value: "Utility bills" },
  { label: "Electronic systems", value: "Electronic systems" },
  { label: "Payroll", value: "Payroll" },
  { label: "Decorations", value: "Decorations" },
  { label: "Monthly expenses", value: "Monthly expenses" },
  { label: "Annual expenses", value: "Annual expenses" },
];
