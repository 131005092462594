import React from "react";
import { useTranslation } from "react-i18next";
import { useSplitByItem } from "./useSplitByItem";

function SplitByCash() {
  const { t } = useTranslation();
  const { total, taxAmount, totalAmount, discountAmount } = useSplitByItem();
  return (
    <div className="bg-white  rounded-lg   p-2  my-4 w-full">
      <hr className="border mb-2" />
      <div className="flex justify-between pb-2">
        <span className="text-sm text-gray-500 ">
          {" "}
          {t("pos_dashboard.sub_total")}
        </span>
        <span className="text-sm font-medium">{totalAmount??"0"} SAR</span>
      </div>
      <div className="flex justify-between pb-2">
        <span className="text-sm text-gray-500 ">
          {" "}
          {t("pos_dashboard.discount")}
        </span>
        <span className="text-sm font-medium">{discountAmount??"0"} SAR</span>
      </div>
      <div className="flex justify-between pb-2">
        <span className="text-sm text-gray-500 ">
          {" "}
          {t("pos_dashboard.vat")}
        </span>
        <span className="text-sm font-medium">{taxAmount??"0"} SAR</span>
      </div>
      <div className="flex justify-between pb-2">
        <span className="text-sm text-gray-500 ">
          {" "}
          {t("pos_dashboard.total")}
        </span>
        <span className="text-sm font-medium">{total??"0"} SAR</span>
      </div>
      <hr className="border " />
    </div>
  );
}

export default SplitByCash;
