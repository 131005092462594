import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import { getAllItems } from "../../../../../features/Items/ItemsSlice";
import {
  getAllPurchaseInvoices,
  getSinglePurchaseInvoice,
  deletePurchaseInvoice,
} from "../../../../../features/Invoices/PurchaseInvoices/PurchaseInvoiceSlice";
import { getAllSuppliers } from "../../../../../features/Suppliers/SuppliersSlice";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { ImageIcon, PdfIcon } from "../../../../common/Icons/fontawesome";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling purchase invoices related functionalities
 *
 * @returns {Object}
 *   purchaseInvoicesList: Array containing purchase invoices information for all invoices
 *   purchaseInvoicesListLoading: Data loading state for all purchase invoices
 *   singlePurchaseInvoice: Object containing purchase invoice information
 *   singlePurchaseInvoiceLoading: Data loading state for single purchase invoice information
 */
const usePurchaseInvoicesList = (invoiceId, activeTab) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { query } = useSelector((state) => state.search);

  // get item list for all items from the store
  const { data: itemList, isLoading: itemListLoading } = useSelector(
    (state) => state.items.getAllItems
  );
  // get supplier list for all suplliers from the store
  const { data: suppliersList } = useSelector(
    (state) => state.suppliers.getAllSuppliers
  );
  // get store list for all stores from the redux store
  const { data: storesList } = useSelector(
    (state) => state.stores.getAllStores
  );
  // get single purchase invoice information from the store
  const { data: singleInvoice, isLoading: singlePurchaseInvoiceLoading } =
    useSelector((state) => state.purchaseInvoices.getSinglePurchaseInvoice);
  // get purchase invoices list for all invoices from the store
  const { data: purchaseInvoicesList, isLoading: purchaseInvoicesListLoading } =
    useSelector((state) => state.purchaseInvoices.getAllPurchaseInvoices);

  /**
   *  Extracting singlePurchaseInvoice object from singleInvoice object for invoice detail modal
   * @returns {Object}
   *
   */
  let fileType = singleInvoice?.attachment?.name?.split(".").pop();

  const FileIcon = {
    pdf: PdfIcon,
    jpeg: ImageIcon,
    jpg: ImageIcon,
  }[fileType];

  const AttachmentComponent = () => (
    <div className="flex items-center gap-3">
      <div className="flex items-center justify-center w-10 h-10 bg-slate-200 rounded-lg">
        {FileIcon && <FileIcon />}
      </div>
      <div className="flex flex-col">
        <span>{singleInvoice && singleInvoice?.attachment?.name}</span>
        <span>{singleInvoice && singleInvoice?.attachment?.size} KB</span>
      </div>
    </div>
  );
  const singleInvoiceDetail = singleInvoice
    ? {
        Supplier: singleInvoice?.suppliers?.name ?? "-",
        "Invoice Date": singleInvoice?.supplyDate ?? "-",
        Store: singleInvoice?.store?.branchName ?? "-",
        "Payment Method": singleInvoice?.paymentMethod ?? "-",
        "Payment Status": singleInvoice?.paymentStatus ?? "-",
        Attachment: singleInvoice?.attachment?.name
          ? () => <AttachmentComponent />
          : "-",
      }
    : null;

  /**
   *  Extracting itemDetail object from singleInvoice object for invoice detail modal
   * @returns {Object}
   *
   */
  const itemDetail = singleInvoice
    ? {
        Item:
          singleInvoice?.purchaseInvoiceItem?.slug ||
          singleInvoice?.purchaseInvoiceItem?.item?.englishName,
        "Unit Price": `${singleInvoice?.purchaseInvoiceItem?.unitPrice} SAR`,
        Quantity: singleInvoice?.purchaseInvoiceItem?.quantity,
        "Before Tax": `${singleInvoice?.purchaseInvoiceItem?.beforeTax} SAR`,
        "Tax Amount": `${singleInvoice?.purchaseInvoiceItem?.taxAmount} SAR`,
        Subtotal: `${singleInvoice?.purchaseInvoiceItem?.subTotal} SAR`,
      }
    : null;

  const itemImage = singleInvoice?.purchaseInvoiceItem?.item?.image;

  useEffect(() => {
    let searchParams = ["invoiceNumber", "createdBy"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: generatePayloadCondition(
        searchParams,
        query,
        activeTab?.title === "All" ? {} : { paymentStatus: activeTab.title }
      ),

      attributes: {},
    };
    dispatch(getAllPurchaseInvoices({ payload }));
  }, [activeTab.title, currentPage, query, dispatch]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(getSinglePurchaseInvoice({ invoiceId: invoiceId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  /**
   * Handles the api call for getting all vendors
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting vendors
   *    order: order for sorting vendors by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!suppliersList) dispatch(getAllSuppliers({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all stores
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting stores
   *    order: order for sorting stores by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the api call for getting all items
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting items
   *    order: order for sorting items by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 100,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteInvoice = () => {
    if (invoiceId) {
      dispatch(
        deletePurchaseInvoice({
          invoiceId: invoiceId,
          successCallBack: onInvoiceSuccess,
        })
      );
    }
  };

  const onInvoiceSuccess = () => {
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition:
        activeTab.title === "All" ? {} : { paymentStatus: activeTab.title },
      attributes: {},
    };
    dispatch(getAllPurchaseInvoices({ payload }));
    dispatch(closeModal());
  };

  return {
    purchaseInvoicesList,
    purchaseInvoicesListLoading,
    singlePurchaseInvoiceLoading,
    singleInvoiceDetail,
    itemList,
    itemDetail,
    itemImage,
    storesList,
    suppliersList,
    singleInvoice,
    currentPage,
    setCurrentPage,
    deleteInvoice,
    itemListLoading,
  };
};

export default usePurchaseInvoicesList;
