import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../../../features/Customers/CustomersSlice";
import { checkOutOrder } from "../../../../../features/PosOrder/PosOrderSlice";

export const useSplitByItem = (setCheckOutVisible, setOrderCheckoutPaid, setIsCheckoutModalOpen) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const selectedPaymentMethodUpperCase = selectedPaymentMethod.toUpperCase();
  // const [orderCheckoutPaid, setOrderCheckoutPaid] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef();

  const { data: customerList, isLoading: customerListLoading } = useSelector(
    (state) => state.customers.getAllCustomers
  );

  const { data: paymentMethodsList, isLoading: paymentMethodsListLoading } =
    useSelector((state) => state.payments.getPaymentMethods);

  const orderCheckoutData = useSelector(
    (state) => state.posOrder.orderCheckoutData
  );
  const discountAmount = Number(orderCheckoutData?.discountAmount) || 0;
  const totalAmount = Number(orderCheckoutData?.totalAmount)?.toFixed() || 0;
  let taxAmount = 0.15 * Number(totalAmount);
  const amount = Number(totalAmount) + Number(taxAmount);
  const total = Number(amount) - Number(discountAmount);
  const orderId = orderCheckoutData?.id;
  const selectedCustomerId = selectedCustomer?.id;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllCustomers({ payload }));
  }, [dispatch]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  const items =
    orderCheckoutData?.orderedItems?.map((orderedItem) => ({
      orderItemId: orderedItem.id,
      id: orderedItem.item.id,
      name: orderedItem.item.englishName,
      price: orderedItem.price,
      paymentStatus: orderedItem.paymentStatus,
    })) || [];

  const handleSelectItem = (id) => {
    const selectedItem = items.find((item) => item.id === id);
    if (selectedItem) {
    }
    const isItemSelected = selectedItems.some((item) => item.id === id);
    setSelectedItems((prevSelectedItems) =>
      isItemSelected
        ? prevSelectedItems.filter((item) => item.id !== id)
        : [...prevSelectedItems, selectedItem]
    );
  };

  const handleSelectAll = () => {
    if (selectedItems.length === items.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(items);
    }
  };

  const handleSelectAllClick = () => {
    handleSelectAll();
  };

  const handleSelectionChange = (value) => {
    setSelectedCard(value);
  };
  const totalItems = selectedItems.reduce((acc, itemId) => {
    const item = items.find((item) => item.id === itemId);
    return acc + (item ? item.price : 0);
  }, 0);
  const orderItemIds = selectedItems?.map((item) => item.orderItemId);
  const selectedItemsPrice = selectedItems?.reduce(
    (acc, item) => acc + (item ? item.price : 0),
    0
  );

  const handleCheckout = () => {
    const payload = {
      paymentMethod: selectedPaymentMethodUpperCase ?? "",
      orderId: orderId,
      checkoutType: "SPLIT_BY_ITEM",
      cardType: selectedCard,
      customerPointsid: selectedCustomerId,
      orderItemIds: orderItemIds,
    };
    // Dispatch the checkout action
    dispatch(
      checkOutOrder({ payload, successCallBack: successCheckoutCallback })
    );
  };

  const successCheckoutCallback = (response) => {
    setOrderCheckoutPaid(response?.data?.data);
    setIsCheckoutModalOpen(true);
    setCheckOutVisible(false)

  };

  return {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    customerList,
    customerListLoading,
    setIsOpen,
    isOpen,
    selectedCustomer,
    setSelectedCustomer,
    handleSelectCustomer,
    dropdownRef,
    totalItems,
    handleSelectAllClick,
    handleSelectItem,
    selectedItems,
    items,
    paymentMethodsList,
    paymentMethodsListLoading,
    // isCheckoutModalOpen,
    handleCheckout,
    // setIsCheckoutModalOpen,
    total,
    taxAmount,
    totalAmount,
    discountAmount,
    selectedItemsPrice,
    handleSelectionChange,
    selectedCard,
    // orderCheckoutPaid,
  };
};
