import React, { useState } from "react";
import { CustomButton } from "../../../../common/components";
import { CrossIcon } from "../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "../../../../../features/ItemsModal/ItemsModalSlice";
import {
  deleteItem,
  getAllItems,
} from "../../../../../features/Items/ItemsSlice";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const DeleteItem = ({ selected }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { type, title, isModalOpen } = useSelector((state) => state.itemsModal);

  const handleDelete = async () => {
    setIsSubmitting(true);
    dispatch(
      deleteItem({
        itemId: selected?.id,
        vItemId: selected?.vItemId,
        successCallBack: onItemUpdate,
      })
    );
  };
  const onItemUpdate = async (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    setIsSubmitting(false);
    dispatch(getAllItems({ payload }));
    dispatch(closeModal());
  };
  return (
    <React.Fragment>
      {isModalOpen && type === "delete" && (
        <div className="fixed overflow-y-auto h-[100vh] inset-0 bg-black/80 flex justify-center items-start z-[9999] md:px-0 px-4 py-10">
          <div className="bg-white rounded-lg max-w-[700px] w-full">
            {/* header */}
            <div className="flex items-center justify-between py-4 px-5 border-b border-neutral-200">
              <h6 className="flex items-center gap-2 font-semibold text-text-primary text-xl capitalize">
                {title}
              </h6>
              <CustomButton
                bgColor="bg-transparent"
                icon={<CrossIcon />}
                width="w-fit"
                border="border"
                onClick={() => dispatch(closeModal())}
              />
            </div>
            {/* body */}
            <div className="p-5 space-y-3">
              {/* delete modal */}
              <p className="text-text-primary text-base font-normal">
                Are you sure you want to delete this item?{" "}
              </p>
            </div>

            {/* footer */}
            <div className="px-6 !mt-2 flex items-center py-3 gap-2 justify-end border-border-primary border-t">
              <CustomButton
                onClick={() => dispatch(closeModal())}
                text={"Cancel"}
                width="w-fit"
                bgColor="bg-transparent"
                hoverTextColor="text-text-primary"
                border="border"
                textColor="text-text-primary"
                padding="py-2 px-3"
                className="leading-none"
              />
              <CustomButton
                width="w-fit"
                padding="py-2 px-3"
                className="leading-none"
                bgColor={
                  type === "delete" ? "bg-red-500" : "bg-surface-primary"
                }
                type="submit"
                text="Delete"
                onClick={handleDelete}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DeleteItem;

// sample usage
// import DeleteItem from "./path/to/DeleteItem.jsx";

// <DeleteItem />
