import React from "react";
import { DeleteIcon, DownloadIcon, PlusCircle } from "../../../common/Icons";
import CustomButton from "../../../common/components/Button/Button";
import CustomAdminModel from "../../../common/components/AdminModal/CustomAdminModal";
import ChooseCustomer from "../ChooseCustomer/ChooseCustomer";
import ChooseDiscount from "../ChooseDiscount/ChooseDiscount";
import SaveOrder from "./SaveOrder/SaveOrder";
import Checkout from "../Checkout/Checkout";
import useOrderDetail from "./useOrderDetail";
import OrderNote from "./OrderNote/OrderNote";
import SelectedItemCard from "./OrderNote/SelectedItemCard/SelectedItemCard";
import ChooseTable from "../ChooseTable/ChooseTable";
import { useTranslation } from "react-i18next";
import PaymentSuccess from "../Checkout/PaymentSuccess/PaymentSuccess";
const OrderDetail = ({
  selectedItems,
  orderDuplicateData,
  setSelectedItems,
  onItemDelete,
}) => {
  const {
    OrderTabs,
    closeChooseDiscountModal,
    openChooseDiscountModal,
    handleChangeDiscount,
    handleDeletePromotion,
    handleSavePromotion,
    toggleCheckOutVisible,
    toggleSaveOrderModal,
    setIsCheckoutModelOpen,
    closeCheckoutModal,
    selectedPromotions,
    isChooseDiscountModalOpen,
    setActiveTab,
    saveOrderModalVisible,
    activeTab,
    checkOutVisible,
    setIsChooseDiscountModalOpen,
    isModalOpen,
    TableModal,
    isTableOpen,
    setIsModalOpen,
    setIsTableOpen,
    handleRemoveTables,
    handleRemoveCustomer,
    handleSelectCustomer,
    selectedTables,
    selectedCustomer,
    setSelectedTables,
    setSelectedCustomer,
    vat,
    grandTotal,
    storeId,
    subtotal,
    updateItemTotal,
    branchName,
    fullName,
    setCheckOutVisible,
    currentDate,
    isCheckoutModalOpen,
    setIsCheckoutModalOpen,
    orderCheckoutPaid,
    setOrderCheckoutPaid,
  } = useOrderDetail(selectedItems, orderDuplicateData);
  const { t } = useTranslation();

  return (
    <>
      <div className="xl:min-w-[420px] h-[100%]">
        <div className="flex flex-col justify-between h-[100%]">
          <div>
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg text-text-primary font-semibold">
                {t("pos_dashboard.order_detail")}
              </h2>
              <CustomButton
                text={t("pos_dashboard.save_order")}
                bgColor="bg-transparent"
                textColor="text-text-primary"
                hoverTextColor="text-text-primary"
                border="border"
                icon={<DownloadIcon />}
                onClick={toggleSaveOrderModal}
                width="w-fit"
                padding="py-1 px-2"
              />
              <CustomAdminModel
                isOpen={saveOrderModalVisible}
                onClose={toggleSaveOrderModal}
                title={t("pos_dashboard.save_order")}
                width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
                subtitleText={{
                  value: (
                    <span>
                      <span className="pl-2">{currentDate}</span>
                      <span className="pl-2">· {branchName}</span>
                      <span className="pl-2">· {fullName}</span>
                    </span>
                  ),
                  show: true,
                }}
              >
                <SaveOrder
                  activeTab={activeTab}
                  selectedItems={selectedItems}
                  selectedPromotions={selectedPromotions}
                  selectedCustomer={selectedCustomer}
                  toggleSaveOrderModal={toggleSaveOrderModal}
                  selectedTables={selectedTables}
                  vat={vat}
                  storeId={storeId}
                  grandTotal={grandTotal}
                  subtotal={subtotal}
                  updateItemTotal={updateItemTotal}
                />
              </CustomAdminModel>
            </div>
            <div className="bg-surface-gray rounded-lg flex items-center justify-between p-1 ">
              {OrderTabs?.map((tab) => (
                <button
                  key={tab.value}
                  onClick={() => setActiveTab(tab)}
                  className={`font-medium rounded-lg  py-1  px-16 transition-colors duration-150 ${
                    activeTab?.value === tab?.value
                      ? "bg-white  shadow-lg text-[#0086FF]"
                      : "text-gray-400 bg-transparent"
                  }`}
                >
                  {tab?.label}
                </button>
              ))}
            </div>
            <div className="mt-3 mb-2 max-h-[310px] overflow-auto">
              {selectedItems?.length > 0 ? (
                selectedItems?.map((item, index) => (
                  <SelectedItemCard
                    key={index}
                    id={item.id}
                    item={item}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    onDelete={() => onItemDelete(index)}
                    onQuantityChange={updateItemTotal}
                  />
                ))
              ) : (
                <OrderNote />
              )}
            </div>

            <div className="flex flex-col items-start">
              {activeTab?.value !== "TAKEAWAY" &&
                (selectedTables ? (
                  <div className="flex items-center justify-between p-4 bg-white shadow border rounded-lg my-2 w-full">
                    <div>
                      <span className="text-sm text-text-secondary font-normal">
                        {t("pos_dashboard.table_button")}
                      </span>
                      <div className="text-sm font-medium">
                        {t("pos_dashboard.table_number")}:{" "}
                        {selectedTables?.position}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <CustomButton
                        text={t("pos_dashboard.change")}
                        onClick={() => setIsTableOpen(true)}
                        border="border-none"
                        bgColor=""
                        textColor="text-text-primary"
                      />

                      <CustomButton
                        icon={<DeleteIcon />}
                        onClick={handleRemoveTables}
                      />
                    </div>
                  </div>
                ) : (
                  activeTab.label !== "TAKEAWAY" && (
                    <CustomButton
                      icon={<PlusCircle />}
                      onClick={TableModal}
                      text={t("pos_dashboard.add_table")}
                      textColor="text-text-link"
                      width="w-fit"
                    />
                  )
                ))}

              <CustomAdminModel
                isOpen={isTableOpen}
                onClose={TableModal}
                title={t("pos_dashboard.choose_table")}
                width="min-[280px]:w-[80%] min-[1080px]:w-[900px]"
              >
                <ChooseTable
                  selectedTables={selectedTables}
                  setSelectedTables={setSelectedTables}
                  closeModal={TableModal}
                />
              </CustomAdminModel>
              {selectedCustomer ? (
                <div className="flex items-center justify-between p-4 border bg-white shadow rounded-lg my-2 w-full">
                  <div>
                    <span className="text-sm text-text-secondary font-normal">
                      Customer{" "}
                    </span>
                    <div className="text-sm font-medium">
                      {selectedCustomer.name}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <CustomButton
                      text={t("pos_dashboard.change")}
                      onClick={() => setIsModalOpen(true)}
                      border="border-none"
                      bgColor=""
                      textColor="text-text-primary"
                    />
                    <CustomButton
                      icon={<DeleteIcon />}
                      onClick={handleRemoveCustomer}
                    />
                  </div>
                </div>
              ) : (
                <CustomButton
                  icon={<PlusCircle />}
                  onClick={() => setIsModalOpen(true)}
                  text={t("pos_dashboard.add_customer")}
                  textColor="text-text-link"
                  width="w-fit"
                />
              )}

              <CustomAdminModel
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={t("pos_dashboard.choose_customer")}
                width="min-[280px]:w-[80%] min-[1080px]:w-[600px]"
                subtitleText={{
                  value: `${currentDate} · ${branchName} · ${fullName}`,
                  show: true,
                }}
              >
                <ChooseCustomer
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  closeModal={() => setIsModalOpen(false)}
                  onSelectCustomer={handleSelectCustomer}
                />
              </CustomAdminModel>

              {selectedPromotions?.length === 0 ? (
                <CustomButton
                  icon={<PlusCircle />}
                  onClick={openChooseDiscountModal}
                  text={t("pos_dashboard.add_discount")}
                  textColor="text-text-link"
                  width="w-fit"
                />
              ) : (
                selectedPromotions?.map((promotion) => (
                  <div className=" mt-2  border  px-3 pt-2 w-full shadow rounded-lg ">
                    <p className="text-sm text-text-gray font-normal ">
                      {" "}
                      {t("pos_dashboard.discount")}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex gap-1">
                        <span className="text-sm font-medium pr-1 justify-start items-start">
                          {promotion.name ?? "-"}
                        </span>
                        <span className="text-sm font-medium  pl-1 justify-left text-text-link">
                          {promotion.value ?? "0"}SAR
                        </span>
                      </div>
                      <div className="flex items-center ">
                        <CustomButton
                          text={t("pos_dashboard.change")}
                          onClick={() => handleChangeDiscount(promotion.id)}
                          border="border-none"
                          bgColor=""
                          textColor="text-text-primary"
                        />

                        <CustomButton
                          icon={<DeleteIcon />}
                          onClick={() => handleDeletePromotion(promotion.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}
              <CustomAdminModel
                isOpen={isChooseDiscountModalOpen}
                onClose={() => setIsChooseDiscountModalOpen(false)}
                title={t("pos_dashboard.choose_promotion")}
                width="min-[280px]:w-[80%] min-[1080px]:w-[700px]"
                subtitleText={{
                  value: `${currentDate} · ${branchName} · ${fullName}`,
                  show: true,
                }}
              >
                <ChooseDiscount
                  onSave={handleSavePromotion}
                  onCancel={closeChooseDiscountModal}
                  selectedItems={selectedItems}

                />
              </CustomAdminModel>
            </div>
          </div>
          <div>
            <div className="flex  flex-col text-sm mb-4  border p-1 px-2 rounded-lg shadow-sm  ">
              <div className="flex justify-between mt-4 text-[14px]">
                <span> {t("pos_dashboard.sub_total")}</span>
                <span className="font-semibold">{subtotal ?? "0"} SAR</span>
              </div>
              <div className="flex justify-between mt-2 text-[14px]">
                <span> {t("pos_dashboard.discount")}</span>
                <span className="font-semibold">20 SAR</span>
              </div>
              <div className="flex justify-between mt-2 text-[14px]">
                <span> {t("pos_dashboard.vat")}</span>
                <span className="font-semibold">{vat.toFixed(2)} SAR</span>
              </div>
              <div className="flex justify-between mt-2 font-semibold text-[14px]">
                <span> {t("pos_dashboard.total")}</span>
                <span>{grandTotal.toFixed(2)} SAR</span>
              </div>
            </div>
            <CustomButton
              width="w-full"
              border="border"
              text={t("pos_dashboard.paynow")}
              textColor="text-white"
              onClick={toggleCheckOutVisible}
            />
          </div>
        </div>
      </div>
      {/* FullPayment Modal */}
      {checkOutVisible && (
        <CustomAdminModel
          isOpen={checkOutVisible}
          onClose={() => setCheckOutVisible(false)}
          title={t("pos_dashboard.checkout")}
          width="min-[280px]:w-[80%] min-[1080px]:w-[580px]"
        >
          <Checkout
            setIsModalOpen={setIsCheckoutModelOpen}
            setCheckOutVisible={setCheckOutVisible}
            closeCheckoutModal={closeCheckoutModal}
            setOrderCheckoutPaid={setOrderCheckoutPaid}
            setIsCheckoutModalOpen={setIsCheckoutModalOpen}
          />
        </CustomAdminModel>
      )}
      {isCheckoutModalOpen && (
        <CustomAdminModel
          isOpen={isCheckoutModalOpen}
          onClose={() => setIsCheckoutModalOpen(false)}
          showHeader={false}
          width="min-[280px]:w-[50%] min-[1080px]:w-[400px]"
        >
          <PaymentSuccess
            handleClose={() => setIsCheckoutModalOpen(false)}
            orderCheckoutPaid={orderCheckoutPaid}
          />
        </CustomAdminModel>
      )}
    </>
  );
};

export default OrderDetail;
