import React, { useState } from "react";
import CustomButton from "../Button/Button";
import { ArrowDown, ChervonLeft, ChervonRight } from "../../Icons";
import Card from "../Card/Card";
import { formatDateTime } from "../../../../helpers/dateFormatter";

/**
 * DateRangePicker component.
 * Renders a date range picker with customizable options.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} [props.skipYear=false] - Whether to skip displaying the year in the date range.
 * @param {boolean} [props.dropdownIcon=true] - Whether to display the dropdown icon.
 * @returns {JSX.Element} JSX code for rendering the DateRangePicker component.
 */
const DateRangePicker = ({
  dropdownIcon = true,
  onStartDateChange,
  onEndDateChange,
  initialSelectedDates,
  dateFormat = "mmm dd, yyyy",
  position = "start-0",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [startMonth, setStartMonth] = useState(new Date());
  const [endMonth, setEndMonth] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const [selectedDates, setSelectedDates] = useState(
    initialSelectedDates ? initialSelectedDates : []
  );

  console.log({ selectedDates });

  /**
   * Get the days in a given month.
   * @param {Date} date - The date object representing the month.
   * @returns {Object} - An object containing the days and the day of the week the month starts on.
   */
  const getDaysInMonth = (date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dayOfWeek = new Date(year, month, 1).getDay();

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }
    return { days, dayOfWeek };
  };

  /**
   * Navigate to the previous or next month.
   * @param {Event} event - The event object.
   * @param {number} direction - The direction to navigate (-1 for previous, 1 for next).
   * @param {boolean} isStart - Whether to navigate the start month or end month.
   */
  const navigateMonth = (event, direction, isStart) => {
    event.stopPropagation();
    if (isStart) {
      setStartMonth(
        new Date(startMonth.getFullYear(), startMonth.getMonth() + direction, 1)
      );
    } else {
      setEndMonth(
        new Date(endMonth.getFullYear(), endMonth.getMonth() + direction, 1)
      );
    }
  };

  /**
   * Handle date click event.
   * @param {Event} event - The event object.
   * @param {Date} date - The date that was clicked.
   */
  const handleDateClick = (event, date) => {
    event.stopPropagation();
    if (selectedDates.length === 0 || selectedDates.length === 2) {
      setSelectedDates([date]); // Start new range with the first date
    } else if (selectedDates.length === 1) {
      const [startDate] = selectedDates;
      if (date >= startDate) {
        setSelectedDates([startDate, date]); // End date is after the start date
      } else {
        setSelectedDates([date, startDate]); // End date is before the start date
      }
    }
  };

  /**
   * Calendar grid component.
   * @param {Object} props - The component props.
   * @param {Date} props.currentMonth - The current month to display.
   * @param {Function} props.handleDateClick - The function to handle date clicks.
   * @param {Array} props.selectedDates - The selected dates.
   * @returns {JSX.Element} - The calendar grid component.
   */
  const CalendarGrid = ({ currentMonth, handleDateClick, selectedDates }) => {
    const { days, dayOfWeek } = getDaysInMonth(currentMonth);
    const startDate = selectedDates.length > 0 ? selectedDates[0] : null;
    const endDate = selectedDates.length > 1 ? selectedDates[1] : null;

    const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

    return (
      <div className="">
        <div className="grid grid-cols-7 gap-1 mt-4 mb-2">
          {dayNames.map((dayName) => (
            <div key={dayName} className="text-center text-sm">
              {dayName}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-7 gap-x-0 gap-y-1">
          {Array.from({ length: dayOfWeek }).map((_, i) => (
            <div key={`empty-${i}`} className="h-6 w-6"></div>
          ))}
          {days.map((day, index) => {
            const isStart =
              startDate && day.toDateString() === startDate.toDateString();
            const isEnd =
              endDate && day.toDateString() === endDate.toDateString();
            const isInRange =
              startDate && endDate && day > startDate && day < endDate;
            return (
              <button
                key={index}
                onClick={(event) => handleDateClick(event, day)}
                className={`h-6 w-6 rounded-full text-xs flex items-center justify-center 
                            ${
                              isInRange
                                ? "bg-info-50"
                                : isStart || isEnd
                                ? "bg-aqua-marine text-black-pearl font-medium"
                                : "text-black-pearl"
                            }
                            hover:bg-blue-100`}
              >
                {day.getDate()}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  /**
   * Check if the end month is the next month after the start month.
   * @param {Date} start - The start month.
   * @param {Date} end - The end month.
   * @returns {boolean} - Whether the end month is the next month.
   */
  const isNextMonth = (start, end) => {
    const startMonth = start.getMonth();
    const startYear = start.getFullYear();
    const endMonth = end.getMonth();
    const endYear = end.getFullYear();

    return (
      (endMonth === startMonth + 1 && endYear === startYear) ||
      (endMonth === 0 && startMonth === 11 && endYear === startYear + 1)
    );
  };

  const disableEndMonthPrev = isNextMonth(startMonth, endMonth);
  const disableStartMonthNext = isNextMonth(startMonth, endMonth);

  /**
   * Toggle the date picker open or closed.
   */
  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Handle cancel button click.
   */
  const handleCancel = () => {
    setSelectedDates([]);
    setIsOpen(false);
  };

  const handleDone = () => {
    setIsOpen(false);
    // Call the callback functions with the respective dates when done
    const [startDate, endDate] = selectedDates;
    onStartDateChange(startDate);
    onEndDateChange(endDate);
  };

  return (
    <div
      className="relative border rounded-lg py-2 px-2 to-text-primary font-semibold cursor-pointer"
      onClick={toggleDatePicker}
    >
      <div className="flex items-center justify-between gap-3">
        {!selectedDates?.length > 0 && (
          <span className=" text-text-secondary font-normal">DD/MM/YY</span>
        )}
        {selectedDates.length > 0 && (
          <span className="text-sm">
            {formatDateTime(selectedDates[0], dateFormat)} -{" "}
            {formatDateTime(selectedDates[1], dateFormat)}
          </span>
        )}
        {dropdownIcon && <ArrowDown />}
      </div>
      {isOpen && (
        <Card
          className={`min-w-96 absolute z-10 top-10 ${position}`}
          padding="pt-4 px-2 pb-2"
          shadow="lg"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="bg-white flex flex-col items-center">
            <div className="flex justify-between mb-4">
              <div className="flex flex-col items-center">
                <div className="flex w-full justify-between items-center mb-2">
                  <CustomButton
                    onClick={(event) => navigateMonth(event, -1, true)}
                    icon={<ChervonLeft className="w-5 h-5" />}
                    width="w-fit"
                    border="border"
                    padding="p-1"
                  />
                  <span className="mx-4">
                    {startMonth.toLocaleDateString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <CustomButton
                    onClick={(event) => navigateMonth(event, 1, true)}
                    icon={<ChervonRight className="w-5 h-5" />}
                    width="w-fit"
                    border="border"
                    padding="p-1"
                    disabled={disableStartMonthNext}
                  />
                </div>
                <CalendarGrid
                  currentMonth={startMonth}
                  handleDateClick={handleDateClick}
                  selectedDates={selectedDates}
                />
              </div>
              <div className="flex flex-col items-center ml-3">
                <div className="flex w-full justify-between items-center mb-2">
                  <CustomButton
                    onClick={(event) => navigateMonth(event, -1, false)}
                    icon={<ChervonLeft className="w-5 h-5" />}
                    width="w-fit"
                    border="border"
                    padding="p-1"
                    disabled={disableEndMonthPrev}
                  />
                  <span className="mx-4">
                    {endMonth.toLocaleDateString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <CustomButton
                    onClick={(event) => navigateMonth(event, 1, false)}
                    icon={<ChervonRight className="w-5 h-5" />}
                    width="w-fit"
                    border="border"
                    padding="p-1"
                  />
                </div>
                <CalendarGrid
                  currentMonth={endMonth}
                  handleDateClick={handleDateClick}
                  selectedDates={selectedDates}
                />
              </div>
            </div>
            <div className="flex justify-end w-full gap-3">
              <CustomButton
                text="buttons.cancel"
                icon
                border="border"
                width="w-fit"
                textColor="text-text-primary"
                padding="px-3 py-2"
                onClick={handleCancel}
              />
              <CustomButton
                text="buttons.done"
                border="border"
                width="w-fit"
                padding="px-3 py-2"
                onClick={handleDone}
              />
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default DateRangePicker;

/**
 * Sample usage:
 * import DateRangePicker from "path/to/DateRangePicker";
 * <DateRangePicker skipYear />
 */
