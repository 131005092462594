import React, { useEffect, useMemo } from "react";

import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import { checkInputChanges } from "../../../../../../helpers/checkInputChanges";
import { useTranslation } from "react-i18next";

/**
 * AddEditStore component.
 * Renders a form to add or edit a store
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditStore component
 */

const AddEditStore = ({
  store,
  register,
  errors,
  setValue,
  setIsInputChanged,
  watch,
}) => {
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => ({
      branchname: store?.branchName ?? "",
      streetname: store?.streetName ?? "",
      buildingno: store?.buildingNumber ?? "",
      districtname: store?.districtName ?? "",
      city: store?.city ?? "",
      zipcode: store?.zipCode ?? "",
    }),
    [store]
  );

  useEffect(() => {
    // set the default values for each field to edit store using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [store, setValue, defaultValues]);

  // set currentValues to track changes in the input fields using watch method
  const currentValues = watch();

  useEffect(() => {
    setIsInputChanged(checkInputChanges(currentValues, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues, defaultValues]);

  return (
    <React.Fragment>
      {/* branch name */}
      <InputGroup
        labelText={"client_dashboard.management.stores.branch_name"}
        htmlFor="branchname"
        inputName="branchname"
        placeholder={"client_dashboard.management.stores.enter_name"}
        register={register}
        errors={errors}
      />
      <h6 className="to-text-primary text-base font-semibold">
        {t("client_dashboard.management.stores.store_address")}
      </h6>
      {/* street name */}
      <InputGroup
        labelText={"client_dashboard.management.stores.street_name"}
        htmlFor="streetname"
        inputName="streetname"
        placeholder={"client_dashboard.management.stores.enter_street_name"}
        register={register}
        errors={errors}
      />

      {/* building no */}
      <InputGroup
        labelText={"client_dashboard.management.stores.building_no"}
        htmlFor="buildingno"
        inputName="buildingno"
        placeholder={"client_dashboard.management.stores.enter_building_no"}
        register={register}
        errors={errors}
      />

      {/* district name */}
      <InputGroup
        labelText={"client_dashboard.management.stores.district_name"}
        htmlFor="districtname"
        inputName="districtname"
        placeholder={"client_dashboard.management.stores.enter_district_name"}
        register={register}
        errors={errors}
      />
      {/* city  */}
      <InputGroup
        labelText={"client_dashboard.management.stores.city"}
        htmlFor="city"
        inputName="city"
        placeholder={"client_dashboard.management.stores.enter_city"}
        register={register}
        errors={errors}
      />
      {/* zip code  */}
      <InputGroup
        labelText={"client_dashboard.management.stores.zip_code"}
        htmlFor="zipcode"
        inputName="zipcode"
        placeholder={"client_dashboard.management.stores.enter_zip_code"}
        register={register}
        errors={errors}
      />
    </React.Fragment>
  );
};

export default AddEditStore;
