import React from "react";
import {
  LightBulb,
  MinusIcon,
  PlusIcon,
  Rocket,
  RocketLaunch,
} from "../../../common/Icons";
import { Check } from "../../../common/Icons/fontawesome";
import { CustomButton } from "../../../common/components";

function SelectPlanCards({
  setBranchCount,
  branchCount,
  setActiveTab,
  activeTab,
  setSelectedPlan,
  selectedPlan,
  additionalBranchPrice,
}) {
  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const toggleTab = (tab) => {
    setActiveTab(tab === "Pay Monthly" ? "MONTHLY" : "ANNUAL");
  };

  const increment = () => {
    setBranchCount((count) => count + 1);
  };

  const decrement = () => {
    setBranchCount((count) => (count > 0 ? count - 1 : 0));
  };

  const PlanCard = ({
    title,
    monthlyPrice,
    annualPrice,
    IconComponent,
    isSelected,
    onClick,
  }) => {
    const displayedPrice = activeTab === "MONTHLY" ? monthlyPrice : annualPrice;
    return (
      <div
        className={`relative flex flex-col border space-y-1 w-full justify-between p-3 rounded-lg cursor-pointer ${
          isSelected
            ? "border-surface-primary bg-info-50"
            : "border-border-primary"
        }`}
        onClick={onClick}
      >
        <div className="flex justify-between items-start">
          <IconComponent
            className={`h-5 w-5 ${isSelected ? "text-white" : "text-blue-500"}`}
          />
          {isSelected && <Check color="text-link" />}
        </div>
        <div className="space-y-1">
          <h4 className="text-text-primary font-semibold text-base">{title}</h4>
          <div className="text-xs">{displayedPrice} SAR</div>
        </div>
      </div>
    );
  };

  const additionalBranchPriceToDisplay =
    activeTab === "MONTHLY"
      ? 71 * branchCount
      : 850 * branchCount;

  return (
    <div>
      <div className="flex mb-2 gap-3">
        <CustomButton
          text="Pay Monthly"
          bgColor={activeTab === "MONTHLY" ? "bg-info-50" : "bg-neutral-50"}
          textColor={activeTab === "MONTHLY" ? "text-text-link" : "black-pearl"}
          className="font-normal"
          width="w-fit"
          onClick={() => toggleTab("Pay Monthly")}
        />
        <CustomButton
          text="Pay Annually"
          bgColor={activeTab === "ANNUAL" ? "bg-info-50" : "bg-neutral-50"}
          textColor={activeTab === "ANNUAL" ? "text-text-link" : "black-pearl"}
          width="w-fit"
          className="font-normal"
          onClick={() => toggleTab("Pay Annually")}
        />
      </div>
      <div className="bg-white gap-4 w-full flex justify-between items-start mt-4">
        <PlanCard
          title="Basic Plan"
          monthlyPrice={84}
          annualPrice={1008}
          IconComponent={LightBulb}
          isSelected={selectedPlan === "Basic"}
          onClick={() => selectPlan("Basic")}
        />
        <PlanCard
          title="Professional Plan"
          monthlyPrice={110}
          annualPrice={1416}
          IconComponent={Rocket}
          isSelected={selectedPlan === "Professional"}
          onClick={() => selectPlan("Professional")}
        />
        <PlanCard
          title="Enterprise Plan"
          monthlyPrice={180}
          annualPrice={2160}
          IconComponent={RocketLaunch}
          isSelected={selectedPlan === "Enterprise"}
          onClick={() => selectPlan("Enterprise")}
        />
      </div>
      {/* divider */}
      <hr className="border-t-2 border-gray-100 px-2 my-4" />
      {/* additional branch */}
      <div className="border border-border-primary p-3 rounded-lg flex items-center justify-between">
        <div>
          <h6 className="text-text-primary font-semibold text-sm">
            Additional New Branch
          </h6>
          <span className="text-text-secondary text-base">
            {branchCount === 0 ? (activeTab === "MONTHLY" ? 71 : 850) : additionalBranchPriceToDisplay} SAR
          </span>
        </div>
        <div className="flex items-center gap-3">
          <CustomButton
            icon={<MinusIcon />}
            border="border"
            width="w-fit"
            padding="p-1"
            onClick={decrement}
          />
          <p className="font-medium">{branchCount}</p>

          <CustomButton
            icon={<PlusIcon />}
            border="border"
            width="w-fit"
            padding="p-1"
            onClick={increment}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectPlanCards;
