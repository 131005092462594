import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../common/components";
import { useFullPay } from "./useFullPay";

function FullPayByCard() {
  const { t } = useTranslation();
  const {
    paymentMethodsList,
    paymentMethodsListLoading,
    selectedCard,
    handleSelectionCard,
  } = useFullPay();

  if (paymentMethodsListLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // Filter to only include methods with type "CARD" and ensure paymentCard data is not null
  const cardMethods = paymentMethodsList?.filter(
    (method) => method.type === "CARD" && method.paymentCard
  );

  return (
    <div className="bg-white rounded-lg ">
      <span className="mb-4 text-sm font-medium">
        {t("pos_dashboard.choose_card")}
      </span>

      <fieldset className="h-[170px] my-2 overflow-auto">
        {cardMethods?.map((method) =>
          method?.paymentCard.cards.map((card) => (
            <div
              key={card.name}
              className="flex items-center justify-between mb-2  border p-3 rounded-lg "
            >
              <label
                htmlFor={card.name}
                className="flex items-center  text-sm  font-medium cursor-pointer"
              >
                <img src={card.image} alt={card.name} className="h-6 w-8 mr-3" />
                {card.name}
              </label>
              <input
                id={card.name}
                name="paymentMethod"
                type="radio"
                value={card.name}
                checked={selectedCard === card.name}
                onChange={() => handleSelectionCard(card.name)}
                className="form-radio h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                style={{ accentColor: "blue" }}
              />
            </div>
          ))
        )}
      </fieldset>
    </div>
  );
}

export default FullPayByCard;
