import React, { forwardRef, useImperativeHandle } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
  Card,
} from "../../../../../common/components";
import { CrossIcon } from "../../../../../common/Icons";
import { Plus } from "../../../../../common/Icons/fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  getAllItems,
} from "../../../../../../features/Items/ItemsSlice";
import {
  closeModal,
  setVariableOptions,
} from "../../../../../../features/ItemsModal/ItemsModalSlice";
/**
 * VariableItems2 component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the VariableItems2 component.
 */

const VariableItems2 = forwardRef(
  ({ storesList, categoryList, selected, singleItem }, ref) => {
    const dispatch = useDispatch();
    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);

    // validation schema for add/edit variable item form
    const variableItemS2chema = z.object({
      englishname: z.string().min(3, "English Name is required!"),
      arabicname: z.string().min(3, "Arabic Name is required!"),
      description: z.string().min(1, "Description is required!"),
      store: z.coerce.number().min(1, "Store is required!"),
      category: z.coerce.number().min(1, "Category is required!"),
      variables: z.array(
        z.object({
          variablename: z.string().min(1, "Variable Name is required!"),
          values: z.array(
            z.object({
              value: z.string().min(1, "Value is required!"),
            })
          ),
        })
      ),
    });

    // Form handling using react-hook-form
    /**
     * Object destructuring to manage form functionality with react-hook-form.
     * Provides functions and objects for form input validation and usage.
     *
     * @property {Function} register - Function to register form inputs for validation and usage.
     * @property {Function} handleSubmit - Function to handle form submission.
     * @property {Object} formState - Object holding form validation errors.
     * @property {Function} setValues - Function to set form input values.
     */
    const {
      register,
      handleSubmit,
      control,
      trigger,
      getValues,
      formState: { errors },
    } = useForm({
      mode: "onChange", // Setting the form mode to trigger validation on input change
      resolver: zodResolver(variableItemS2chema),
      defaultValues: {
        variables: [
          {
            variablename: "",
            edit: true,
            values: [
              {
                value: "",
              },
            ],
          },
        ],
      },
    });

    const {
      fields,
      append: appendVariable,
      remove: removeVariable,
      update,
    } = useFieldArray({
      control: control,
      name: "variables",
    });

    const onItemUpdate = (data) => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      dispatch(getAllItems({ payload }));
      dispatch(closeModal());
    };

    // function to add another variable field
    const addVariable = async () => {
      // Trigger validation for all fields in the form
      const isValid = await trigger("variables");

      // Check if the form is valid
      if (isValid) {
        // If the form is valid, append a new item to the form array
        appendVariable({
          variablename: "",
          edit: true,
          values: [
            {
              value: "",
            },
          ],
        });
      }
    };

    const handleDone = async (i) => {
      // Trigger validation for all fields in the form
      const isValid = await trigger("variables");

      // Check if the form is valid
      if (isValid) {
        // If the form is valid, append a new item to the form array
        update(i, {
          variablename: getValues().variables[i].variablename,
          edit: false,
          values: getValues().variables[i].values,
        });
      }
    };

    const handleEdit = (i) => {
      update(i, {
        edit: true,
        variablename: getValues().variables[i]?.variablename,
        values: getValues().variables[i].values,
      });
    };

    const { variables } = getValues();

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          type: "VARIABLE",
          image: uploadFileData?.uploadSingleFile?.data?.url,
          englishName: data?.englishname,
          arabicName: data?.arabicname,
          description: data?.description,
          storeId: +data?.store,
          categoryId: +data?.category,
          variableItem: variables,
        };
        dispatch(setVariableOptions(payload));
      }),
    }));

    return (
      <React.Fragment>
        <CustomFileUploader />
        {/* name */}
        <InputGroup
          labelText="Item Name"
          htmlFor="englishname"
          inputName="englishname"
          placeholder="Enter Item Name"
          register={register}
          errors={errors}
        />
        {/* arabic name */}
        <InputGroup
          labelText="Arabic Item Name"
          htmlFor="arabicname"
          inputName="arabicname"
          placeholder="Enter Arabic Item Name"
          register={register}
          errors={errors}
        />
        {/* description */}
        <InputGroup
          labelText="Description"
          htmlFor="description"
          inputName="description"
          placeholder="Enter Description"
          register={register}
          errors={errors}
        />
        {/* store */}
        <SelectGroup
          labelText="Select Store"
          htmlFor="store"
          inputName="store"
          options={
            [
              { value: "", label: "All Store" },
              ...storesList?.map((option) => ({
                value: option?.id,
                label: option?.branchName,
              })),
            ] ?? []
          }
          register={register}
          errors={errors}
        />
        {/* category */}
        <SelectGroup
          labelText="Select Category"
          htmlFor="category"
          inputName="category"
          options={
            [
              { value: "", label: "Select Category" },
              ...categoryList?.map((option) => ({
                value: option?.id,
                label: option?.englishName,
              })),
            ] ?? []
          }
          register={register}
          errors={errors}
        />
        <h6 className="text-text-primary font-semibold text-base">Variables</h6>
        {fields.map((field, i) => (
          <div key={field.variablename + i} className="space-y-3">
            {!field.edit ? (
              <div className="flex flex-col space-y-3 px-4 py-3" key={i}>
                <div className="flex justify-between items-center">
                  <span>{variables[i].variablename}</span>
                  <CustomButton
                    text="Edit"
                    width="w-fit"
                    bgColor="bg-trasnparent"
                    border="border"
                    textColor="text-text-primary"
                    icon
                    padding="py-2 px-3"
                    onClick={() => handleEdit(i)}
                  />
                </div>
                <div className="flex items-center gap-3">
                  {variables[i].values.map((value, valIndex) => (
                    <span
                      className="bg-surface-gray py-1 px-2 rounded-3xl text-xs font-medium to-text-secondary"
                      key={value + valIndex}
                    >
                      {value.value}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              <div className="space-y-3">
                {" "}
                <div className="flex items-center justify-between">
                  <span className="space-y-3 font-semibold to-text-primary text-sm">
                    Variable {i + 1}{" "}
                  </span>
                  <span
                    className="cursor-pointer"
                    onClick={() => removeVariable(i)}
                  >
                    <CrossIcon />
                  </span>
                </div>
                {/* variable name */}
                <SelectGroup
                  htmlFor={`variablename-${i}`}
                  inputName={`variables.${i}.variablename`}
                  labelText="Variable Name"
                  options={[
                    { value: "", label: "Select Variable Name" },
                    { value: "Size", label: "Size" },
                    { value: "Color", label: "Color" },
                    { value: "Type", label: "Type" },
                    { value: "Material", label: "Material" },
                  ]}
                  register={register}
                  errors={errors}
                />
                <Card className="space-y-3">
                  {field.values?.map((val, valueIndex) => (
                    <React.Fragment
                      key={`variables.${i}.values.${valueIndex}.value`}
                    >
                      <div className="flex gap-3 items-center">
                        <InputGroup
                          labelText={`Value #${valueIndex + 1}`}
                          htmlFor={`value-${valueIndex}`}
                          inputName={`variables.${i}.values.${valueIndex}.value`}
                          placeholder="Enter Value"
                          register={register}
                          errors={errors}
                          className="w-full"
                        />
                        {field?.values?.length > 1 && (
                          <span
                            className="block w-fit cursor-pointer ml-auto"
                            onClick={() => {
                              update(i, {
                                values: field.values.filter(
                                  (_, id) => valueIndex !== id
                                ),
                              });
                            }}
                          >
                            <CrossIcon />
                          </span>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </Card>
                <div className="flex items-center gap-3">
                  <CustomButton
                    text="Done"
                    width="w-fit"
                    bgColor="bg-trasnparent"
                    border="border"
                    textColor="text-text-primary"
                    icon
                    onClick={() => handleDone(i)}
                  />
                  <CustomButton
                    text="Add Value"
                    textColor="text-white"
                    bgColor="bg-black-pearl"
                    width="w-fit"
                    onClick={() => {
                      update(i, {
                        ...getValues().variables[i],
                        values: getValues().variables[i].values.concat({
                          value: "",
                        }),
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        <CustomButton
          text="Add Another Variable"
          icon={<Plus color="text-blue-sky" />}
          width="w-fit"
          bgColor="bg-transparent"
          className="shadow-none"
          textColor="text-text-link"
          onClick={addVariable}
        />
      </React.Fragment>
    );
  }
);

export default VariableItems2;

// sample usage
// import VariableItems2 from "./path/to/VariableItems2.jsx";

// <VariableItems2 />
