import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  InputGroup,
  SelectGroup,
  CustomButton,
  CustomFileUploader,
} from "../../../../../common/components";
import { QrCodeIcon } from "../../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  getAllItems,
} from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/ItemsModal/ItemsModalSlice";
import { useTranslation } from "react-i18next";

/**
 * SimpleItems component.
 * React component for managing variable items with dynamic form fields.
 *
 * @returns {JSX.Element} JSX code for rendering the SimpleItems component.
 */

const SimpleItems = forwardRef(
  ({ storesList, categoryList, setIsSubmitting }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // get uploaded file from the redux store data
    const uploadFileData = useSelector((state) => state.uploadFile);

    // validation schema for add/edit simple item form
    const simpleItemSchema = z.object({
      englishname: z
        .string({
          required_error:
            "client_dashboard.directory.items.simple_item.validations.englishname_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.simple_item.validations.englishname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.simple_item.validations.englishname_error_max"
        ),
      arabicname: z
        .string({
          required_error:
            "client_dashboard.directory.items.simple_item.validations.arabicname_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.simple_item.validations.arabicname_error_min"
        )
        .max(
          100,
          "client_dashboard.directory.items.simple_item.validations.arabicname_error_max"
        ),
      description: z
        .string({
          required_error:
            "client_dashboard.directory.items.simple_item.validations.description_error",
        })
        .min(
          3,
          "client_dashboard.directory.items.simple_item.validations.description_error_min"
        )
        .max(
          500,
          "client_dashboard.directory.items.simple_item.validations.description_error_max"
        ),
      store: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.simple_item.validations.store_error"
        ),
      itemcost: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.simple_item.validations.itemcost_error"
        )
        .max(1000000, {
          message:
            "client_dashboard.directory.items.simple_item.validations.itemcost_error_max",
        }),
      category: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.simple_item.validations.category_error"
        ),
      saleprice: z.coerce
        .number()
        .min(
          1,
          "client_dashboard.directory.items.simple_item.validations.saleprice_error"
        )
        .max(1000000, {
          message:
            "client_dashboard.directory.items.simple_item.validations.saleprice_error_max",
        }),
    });

    // Form handling using react-hook-form
    /**
     * Object destructuring to manage form functionality with react-hook-form.
     * Provides functions and objects for form input validation and usage.
     *
     * @property {Function} register - Function to register form inputs for validation and usage.
     * @property {Function} handleSubmit - Function to handle form submission.
     * @property {Object} formState - Object holding form validation errors.
     * @property {Function} setValues - Function to set form input values.
     */
    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange", // Setting the form mode to trigger validation on input change
      resolver: zodResolver(simpleItemSchema),
    });

    useEffect(() => {
      setIsSubmitting(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit((data) => {
        const payload = {
          type: "SIMPLE",
          image: uploadFileData?.uploadSingleFile?.data?.url,
          englishName: data?.englishname,
          barcode: data?.barcode,
          arabicName: data?.arabicname,
          description: data?.description,
          quantity: 300,
          storeId: +data?.store,
          categoryId: +data?.category,
          itemCost: +data?.itemcost,
          salePrice: +data?.saleprice,
        };

        return dispatch(
          addItem({
            payload: payload,
            successCallBack: onItemUpdate,
          })
        );
      }),
    }));

    const onItemUpdate = (data) => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      dispatch(getAllItems({ payload }));
      dispatch(closeModal());
    };

    return (
      <React.Fragment>
        <CustomFileUploader
          isLoading={uploadFileData?.uploadSingleFile?.isLoading}
        />
        {/* name */}
        <InputGroup
          labelText={
            "client_dashboard.directory.items.simple_item.english_name"
          }
          htmlFor="englishname"
          inputName="englishname"
          placeholder={
            "client_dashboard.directory.items.simple_item.enter_english_name"
          }
          register={register}
          errors={errors}
        />
        {/* arabic name */}
        <InputGroup
          labelText={"client_dashboard.directory.items.simple_item.arabic_name"}
          htmlFor="arabicname"
          inputName="arabicname"
          placeholder={
            "client_dashboard.directory.items.simple_item.enter_arabic_name"
          }
          register={register}
          errors={errors}
        />
        {/* description */}
        <InputGroup
          labelText={"client_dashboard.directory.items.simple_item.description"}
          htmlFor="description"
          inputName="description"
          placeholder={
            "client_dashboard.directory.items.simple_item.enter_description"
          }
          register={register}
          errors={errors}
        />
        {/* store */}
        <SelectGroup
          labelText={
            "client_dashboard.directory.items.simple_item.select_store"
          }
          htmlFor="store"
          inputName="store"
          options={[
            {
              value: "",
              label:
                "client_dashboard.directory.items.simple_item.select_store",
            },
            ...storesList?.map((option) => ({
              value: option?.id,
              label: option?.branchName,
            })),
          ]}
          className="rtl:bg-left"
          register={register}
          errors={errors}
        />
        {/* item cost */}
        <InputGroup
          labelText={"client_dashboard.directory.items.simple_item.item_cost"}
          htmlFor="itemcost"
          inputName="itemcost"
          placeholder={
            "client_dashboard.directory.items.simple_item.enter_item_cost"
          }
          register={register}
          errors={errors}
        />
        {/* category */}
        <SelectGroup
          labelText={
            "client_dashboard.directory.items.simple_item.select_category"
          }
          htmlFor="category"
          inputName="category"
          className="rtl:bg-left"
          options={[
            {
              value: "",
              label:
                "client_dashboard.directory.items.simple_item.select_category",
            },
            ...categoryList?.map((option) => ({
              value: option?.id,
              label: option?.englishName,
            })),
          ]}
          register={register}
          errors={errors}
        />
        {/* sale price */}
        <InputGroup
          labelText={"client_dashboard.directory.items.simple_item.sale_price"}
          htmlFor="saleprice"
          inputName="saleprice"
          placeholder={
            "client_dashboard.directory.items.simple_item.enter_sale_price"
          }
          register={register}
          errors={errors}
        />

        <div className="flex items-center gap-10 !mt-4">
          <CustomButton
            text={
              "client_dashboard.directory.items.simple_item.create_barcode_num"
            }
            icon={<QrCodeIcon />}
            textColor="text-text-primary"
            width="w-fit"
            border="border"
            padding="p-2"
          />
          <InputGroup
            htmlFor="barcode"
            inputName="barcode"
            placeholder={
              "client_dashboard.directory.items.simple_item.enter_barcode"
            }
            register={register}
            errors={errors}
            className="flex-1"
          />
        </div>
      </React.Fragment>
    );
  }
);

export default SimpleItems;

// sample usage
// import SimpleItems from "./path/to/SimpleItems.jsx";

// <SimpleItems />
