import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { Card, InputGroup } from "../../../../../common/components";
import { ArrowDown, ArrowUp } from "../../../../../common/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  getAllItems,
  getSingleItem,
  updateItem,
} from "../../../../../../features/Items/ItemsSlice";
import { closeModal } from "../../../../../../features/ItemsModal/ItemsModalSlice";

function generateVariableCombinations(attributes, variableItems) {
  const _variableItems = JSON.parse(JSON.stringify(variableItems));
  const combinations = [];

  // Helper function to generate combinations recursively
  function generate(currentCombination, index) {
    if (index === attributes.length) {
      const _currentCombination = currentCombination.map((c) => c.value);
      const element = _variableItems.find((v) =>
        v.values.every((va) => _currentCombination.includes(va.value))
      );

      if (element) {
        combinations.push(element);
      } else {
        combinations.push({
          costPrice: "", // Set your cost price here
          salePrice: "", // Set your sale price here
          values: currentCombination,
        });
      }
      return;
    }

    const attribute = attributes[index];
    for (const value of attribute.values) {
      const updatedCombination = [
        ...currentCombination,
        { name: attribute.name, value },
      ];
      generate(updatedCombination, index + 1);
    }
  }

  generate([], 0); // Start with an empty combination at index 0
  return combinations;
}
const VariableOptionsForm = forwardRef(
  ({ singleItem, type = "add", setIsSubmitting }, ref) => {
    const [expanded, setExpanded] = useState({});
    const dispatch = useDispatch();
    const { variableOptions } = useSelector((state) => state.itemsModal);
    const variables = variableOptions?.variableItem || [];
    const attributes = variables?.map((variable) => {
      const name = variable.variablename.toLowerCase(); // Convert variable name to lowercase
      const values = variable?.values.map((valueObj) => valueObj.value); // Extract values from objects
      return { name, values };
    });
    const itemId = singleItem?.id;
    const vItemId = singleItem?.type === "VARIABLE" && singleItem?.vItemId;

    const combinations = useMemo(
      () =>
        generateVariableCombinations(
          attributes,
          (singleItem?.variableItem || []).map((v) => ({
            ...v,
            values: Object.entries(v.variations).map(([name, value]) => ({
              name,
              value,
            })),
            variations: undefined,
          }))
        ).reduce((groups, entry) => {
          const attributeValue = entry?.values[0]?.value;
          if (!groups[attributeValue]) groups[attributeValue] = [];
          groups[attributeValue].push(entry);
          return groups;
        }, {}),
      [attributes, variableOptions]
    );

    // useEffect(() => {
    //   setExpanded(
    //     Object.fromEntries(
    //       Object.entries(combinations).map(([key, val]) => [key, true])
    //     )
    //   );
    // }, []);

    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm({
      mode: "onChange",
      defaultValues: combinations,
    });

    useEffect(() => {
      setIsSubmitting(isSubmitting);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const onSubmit = (data) => {
      const payload = {
        ...variableOptions,
        itemCost: 0,
        salePrice: 0,
        variableItem: Object.values(data)
          .flat()
          .map((v) => ({
            ...v,
            costPrice: +v.costPrice,
            salePrice: +v.salePrice,
          })),
      };
      if (type === "add") {
        return dispatch(
          addItem({ payload: payload, successCallBack: onItemUpdate })
        );
      }
      return dispatch(
        updateItem({
          itemId: singleItem?.id,
          payload: payload,
          successCallBack: onItemUpdate,
        })
      );
    };

    // Expose handleSubmit function to parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: handleSubmit(onSubmit),
    }));

    const onItemUpdate = (data) => {
      const payload = {
        page: 1,
        pageSize: 10,
        sortColumn: "id",
        order: {
          id: "DESC",
        },
        condition: {},
        attributes: {},
      };
      dispatch(getAllItems({ payload }));
      dispatch(
        getSingleItem({
          itemId,
          vItemId,
        })
      );
      dispatch(closeModal());
    };

    const toggleExpand = (size) => {
      setExpanded((prev) => ({ ...prev, [size]: !prev[size] }));
    };

    return (
      <React.Fragment>
        {variables[0]?.values?.length > 0 && (
          <form className="space-y-4">
            {Object.entries(combinations)?.map(([base, value], index) => (
              <Card key={base + index} className="space-y-4">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleExpand(base)}
                >
                  <span className="font-bold text-lg">{base}</span>
                  <div className="flex items-center">
                    <span className="mr-16">{value?.length} Values</span>
                    {!expanded[base] ? (
                      <ArrowUp className="h-5 w-5" />
                    ) : (
                      <ArrowDown className="h-5 w-5" />
                    )}
                  </div>
                </div>
                {value?.length > 0 &&
                  React.Children.toArray(
                    value?.map((val, i) => {
                      const combinationKey = val?.values
                        .map((value) => value?.value)
                        .filter((value) => value !== base)
                        .join(", ");
                      return (
                        <div
                          className={`flex flex-col mb-4 gap-4 ${
                            !expanded[base] ? "block" : "hidden"
                          }`}
                        >
                          {i === 0 && (
                            <div className="flex mb-2 gap-4">
                              <div className="flex-1">
                                <label>Values</label>
                              </div>
                              <div className="flex-1">
                                <label>Price</label>
                              </div>
                              <div className="flex-1">
                                <label>Cost</label>
                              </div>
                            </div>
                          )}
                          <div className="flex gap-4 items-center">
                            <div className="flex-1">
                              <label>{combinationKey}</label>
                            </div>

                            <div className="flex-1">
                              <InputGroup
                                inputName={`${base}.${i}.salePrice`}
                                placeholder="Enter Price"
                                register={register}
                                errors={errors}
                              />
                            </div>

                            <div className="flex-1">
                              <InputGroup
                                inputName={`${base}.${i}.costPrice`}
                                placeholder="Enter Cost"
                                register={register}
                                errors={errors}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
              </Card>
            ))}
          </form>
        )}
      </React.Fragment>
    );
  }
);

export default VariableOptionsForm;
