import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleClientProfile,
  updateClientProfile,
} from "../../../../../../features/Clients/clientSlice";
import { getAllStaffUsers } from "../../../../../../features/AdminDashboardCards/AdminDashboardCardsSlice";

export const useEditClient = ({
  clientId,
  isModalOpen,
  setModalOpen,
  setUpdateClient,
}) => {
  const dispatch = useDispatch();
  const [clientDetails, setClientDetails] = useState({});

  useEffect(() => {
    dispatch(
      getSingleClientProfile({
        profileId: clientId,
        successCallBack: onGetSingleClientProfileSuccess,
      })
    );
  }, [clientId, dispatch]);

  // Validation schema for business info
  const businessInfoSchema = z.object({
    fullName: z
      .string()
      .min(3, "Full name must be at least 3 characters long!")
      .max(100, "Full name must not exceed 100 characters!"),
    arabicName: z
      .string()
      .min(3, "Arabic name must be at least 3 characters long!")
      .max(100, "Arabic name must not exceed 100 characters!"),
    commercialregister: z
      .string()
      .min(3, "Commercial register must be at least 3 characters long!")
      .max(100, "Commercial register must not exceed 100 characters!"),
    companyname: z
      .string()
      .min(3, "Company name must be at least 3 characters long!")
      .max(100, "Company name must not exceed 100 characters!"),
    companyaddress: z
      .string()
      .min(3, "Company address must be at least 3 characters long!")
      .max(100, "Company address must not exceed 100 characters!"),
    phoneNumber: z
      .string()
      .min(3, "Phone number must be at least 3 digits long!")
      .max(15, "Phone number should not exceed 15 digits!")
      .regex(
        /^\+\d+$/,
        "Phone number must start with + and be followed by numeric digits!"
      ),
    website: z
      .string()
      .min(3, "Website must be at least 3 characters long!")
      .max(100, "Website must not exceed 100 characters!"),
    account: z.string().optional(),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(businessInfoSchema),
  });

  const handleCancel = () => {
    setModalOpen(false);
  };

  const onGetSingleClientProfileSuccess = (response) => {
    setClientDetails(response);
  };

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: { id: "DESC" },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStaffUsers({ payload }));
  }, []);

  const { data: staffUser } = useSelector(
    (state) => state.dashboard.getAllStaffUsers
  );

  useEffect(() => {
    if (clientDetails && isModalOpen) {
      setValue("fullName", clientDetails.profile?.fullName);
      setValue("arabicName", clientDetails.profile?.arabicName);
      setValue("phoneNumber", clientDetails.profile?.phoneNumber);
      setValue("email", clientDetails.email);
      setValue("account", clientDetails.subscription?.managedBy?.id ?? "");
      setValue("companyname", clientDetails.businessDetail?.companyName);
      setValue("companyaddress", clientDetails.businessDetail?.companyAddress);
      setValue("website", clientDetails.businessDetail?.website);
      setValue(
        "commercialregister",
        clientDetails.businessDetail?.commercialRegister
      );
    }
  }, [clientDetails, isModalOpen, setValue]);

  const staffUserOptions = [
    { value: "", label: "Select Account Manager" },
    ...(staffUser?.filter((user) => user.role === "ACCOUNT_MANAGER").map((user) => ({
      value: user.id,
      label: user.fullName,
    })) || []),
  ];

  const onSubmit = (transformedPayload) => {
    // Transforming the original payload to the required structure
    const data = {
      fullName: transformedPayload.fullName,
      phoneNumber: transformedPayload.phoneNumber,
      arabicName: transformedPayload.arabicName,
      companyName: transformedPayload.companyname,
      companyAddress: transformedPayload.companyaddress,
      website: transformedPayload.website,
      commercialRegister: transformedPayload.commercialregister,
      managedBy: transformedPayload.account,
      vatNumber: "1234567",
    };

    // Dispatch updateClientProfile and then dispatch getClientProfile on success
    dispatch(
      updateClientProfile({
        profileId: clientId,
        payload: data,
      })
    )
      .then(() => {
        setUpdateClient((prev) => !prev);
        setModalOpen(false);
      })
      .catch((error) => {
        console.error("Update profile failed:", error);
      });
  };

  return {
    register,
    errors,
    onSubmit,
    handleCancel,
    handleSubmit,
    isDirty,
    staffUserOptions,
  };
};

export default useEditClient;
