import React, { useRef, useEffect, useState } from "react";
import { Card, Loader } from "../../../../common/components";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getClientSalesCost } from "../../../../../features/ClientDashboard/ClientDashboardSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";

function SalesAndCost() {
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // January 1st of the current year
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const { data: saleCost, isLoading } = useSelector(
    (state) => state.clientDashboard.getClientSalesCost
  );

  const totalSales = saleCost?.sales?.totalSale;
  const totalCost = saleCost?.expenses[0]?.totalExpense;

  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  const monthNames = [];

  for (let month = startMonth; month <= endMonth; month++) {
    const date = new Date(currentYear, month);
    const monthName = date.toLocaleString("default", { month: "long" });
    monthNames.push(monthName);
  }

  /**
   * Handles the api call for getting all sales and cost
   * This function can be asynchronous for tasks like API calls.
   *
   *   @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
        }
   */

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
    };
    dispatch(getClientSalesCost({ payload }));
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradientSales = ctx.createLinearGradient(0, 0, 0, 400);
      gradientSales.addColorStop(0, "rgba(46, 144, 250, 0.2)");
      gradientSales.addColorStop(1, "rgba(46, 144, 250, 0.00)");

      const gradientCosts = ctx.createLinearGradient(0, 0, 0, 400);
      gradientCosts.addColorStop(0, "rgba(43, 227, 183, 0.2)");
      gradientCosts.addColorStop(1, "rgba(43, 227, 183, 0.00)");

      chart.data.datasets[0].backgroundColor = gradientSales;
      chart.data.datasets[1].backgroundColor = gradientCosts;
      chart.update();
    }
  }, [saleCost]);

  const sortedSalesData = saleCost?.sales?.sales
    .map((sale) => ({
      ...sale,
      date: new Date(sale?.saleDate), // Assuming saleDate is in 'YYYY-MM-DD' format
    }))
    .sort((a, b) => a.date - b.date) // Sort by date
    .map((sale) => ({
      month: sale.month.split(" ")[0], // Extract the month name
      monthlySale: sale.monthlySale,
    }));
  const sortedCostData = saleCost?.expenses?.flatMap((item) =>
    item?.expenseForClientOnTimeBasis
      .map((cost) => ({
        ...cost,
        date: new Date(cost?.supplyDate), // Assuming saleDate is in 'YYYY-MM-DD' format
      }))
      .sort((a, b) => a.date - b.date) // Sort by date
      .flatMap((sale) => ({
        month: sale.month.split(" ")[0], // Extract the month name
        monthlyExpense: sale.monthlyExpense,
      }))
  );

  const data = {
    labels: monthNames,
    datasets: [
      {
        label: "Sales",
        data: sortedSalesData?.map((data) => data?.monthlySale),
        borderColor: "#2E90FA", // Match the start of gradient
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointHitRadius: 0,
        lineTension: 0,
        yAxisID: "y",
      },
      {
        label: "Costs",
        data: sortedCostData?.map((data) => data?.monthlyExpense),
        borderColor: "#2BE3B7", // Match the start of gradient
        fill: true,
        yAxisID: "y",
        pointRadius: 0,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        // Primary y-axis for Sales
        type: "linear",
        display: true,
        position: "left",
        grid: {
          border: "1px solid black",
          color: "#efefef",
        },
        ticks: {
          padding: 1,
        },
      },
    },
    elements: {
      line: {
        tension: 0, // This makes the line sharp rather than curved
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
  };

  return (
    <Card className="!mt-4 overflow-auto">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text-primary">
          Sales and Cost
        </h2>
        <span className="flex justify-between gap-2">
          <DateRangePicker
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            initialSelectedDates={[startDate, endDate]}
            position="end-0"
          />
        </span>
      </div>
      <div className="flex gap-12 items-center bg-white my-4">
        <div>
          <h4 className="text-sm font-normal text-text-primary">Total Sales</h4>
          <span className="text-2xl font-semibold flex items-center">
            {isLoading ? <Loader margin="mt-2" /> : `${totalSales ?? 0} SAR`}
          </span>
        </div>
        <div className="px-4">
          <h4 className="text-sm font-normal text-text-primary">Total Cost</h4>
          <span className="text-2xl font-semibold flex items-center">
            {isLoading ? <Loader margin="mt-2" /> : `${totalCost ?? 0} SAR`}
          </span>
        </div>
      </div>
      <div>
        <Line ref={chartRef} data={data} options={options} />
      </div>
    </Card>
  );
}

export default SalesAndCost;
