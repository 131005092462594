import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  deleteSupplier,
  getAllSuppliers,
  getSingleSupplier,
  updateSupplier,
  addSupplier,
} from "../../../../../features/Suppliers/SuppliersSlice";
import { closeModal } from "../../../../../features/Modal/ModalSlice";
import { generatePayloadCondition } from "../../../../../helpers/generatePayloadCondition";

/**
 * Custom hook for handling customers related functionalities.
 *
 * @returns {Object}
 *   customerList: Array containing customer information for all customers.
 *   isLoading: Data loading state for all customers.
 *   singleCustomer: Object containing customer information.
 *   singleCustomerLoading: Data loading state for single customer information.
 */
const useSuppliersList = (supplierId) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { query } = useSelector((state) => state.search);

  const [currentPage, setCurrentPage] = useState(1);

  // get single customer information from the store
  const { data: singleSupplier, isLoading: singleCustomerLoading } =
    useSelector((state) => state.suppliers.getSingleSupplier);
  // get supplier list for all suplliers from the store
  const { data: suppliersList, isLoading: supplierListLoading } = useSelector(
    (state) => state.suppliers.getAllSuppliers
  );

  const supplierDetail = singleSupplier
    ? {
        name: singleSupplier?.name ?? "-",
        company: singleSupplier?.companyName ?? "-",
        "Phone Number": singleSupplier?.phoneNumber ?? "-",
        Email: singleSupplier?.email ?? "-",
        Address: singleSupplier?.address ?? "-",
        "Total Purchase": singleSupplier?.totalPurchase ?? "20,000 SAR",
        "VAT Number": singleSupplier?.vatNumber ?? "-",
      }
    : null;

  // validation schema for add/edit customer form
  const addEditCustomerSchema = z.object({
    englishname: z
      .string()
      .min(3, "client_dashboard.directory.supplier.validations.englishname")
      .max(
        100,
        "client_dashboard.directory.supplier.validations.name_error_max"
      ),

    email: z
      .string()
      .min(10, "client_dashboard.directory.supplier.validations.email_error")
      .email(
        "client_dashboard.directory.supplier.validations.email_error_valid"
      ),
    phonenumber: z
      .string()
      .min(3, "client_dashboard.directory.supplier.validations.phone_error")
      .max(
        20,
        "client_dashboard.directory.supplier.validations.phone_error_max"
      )
      .regex(
        /^\+\d+$/,
        "client_dashboard.directory.supplier.validations.phone_error_regex"
      ),
    address: z
      .string()
      .min(4, "client_dashboard.directory.supplier.validations.error_address")
      .max(
        100,
        "client_dashboard.directory.supplier.validations.error_address_max"
      ),
    company: z
      .string()
      .min(4, "client_dashboard.directory.supplier.validations.company_error")
      .max(
        100,
        "client_dashboard.directory.supplier.validations.company_error_max"
      ),

    vatnumber: z
      .string()
      .length(15, "client_dashboard.directory.supplier.validations.vat_error"),
  });

  // Form handling using react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(addEditCustomerSchema),
  });

  const onSubmit = async (data) => {
    const payload = {
      name: data?.englishname,
      companyName: data?.company,
      email: data?.email,
      phoneNumber: data?.phonenumber,
      address: data?.address,
      vatNumber: data?.vatnumber?.toString(),
      gender: "MALE",
    };
    if (supplierId && modalState?.type === "edit") {
      return dispatch(
        updateSupplier({
          supplierId,
          payload,
          successCallBack: onSupplierUpdate,
        })
      );
    } else {
      return dispatch(
        addSupplier({
          payload,
          successCallBack: onSupplierUpdate,
        })
      );
    }
  };

  const onSupplierUpdate = (data) => {
    const payload = {
      page: 1,
      pageSize: 10,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllSuppliers({ payload }));
    dispatch(closeModal());
  };

  /**
   * Handles the api call for getting all customers
   * This function can be asynchronous for tasks like API calls.
   *
   *  @param {object} payload
   *    page: current page
   *    pageSize: number of pages
   *    sortColumn: column id for sorting customers
   *    order: order for sorting customers by asc or desc
   *    condition: {}
   *    attributes:{}
   */

  useEffect(() => {
    let searchParams = ["name", "email"];
    const payload = {
      page: currentPage,
      pageSize: 10,
      sortColumn: "id",
      order: { id: "DESC" },
      condition: generatePayloadCondition(searchParams, query),
      attributes: {},
    };

    dispatch(
      getAllSuppliers({
        payload,
        successCallBack: onGetAllSuppliersSuccess,
      })
    );
  }, [currentPage, query, dispatch]);

  const onGetAllSuppliersSuccess = (response) => {};

  const deleteCustomerEntry = () => {
    if (supplierId) {
      dispatch(
        deleteSupplier({ supplierId, successCallBack: onSupplierUpdate })
      );
    }
  };

  useEffect(() => {
    if (supplierId) {
      dispatch(getSingleSupplier({ supplierId }));
    }
  }, [dispatch, supplierId]);

  return {
    suppliersList,
    supplierListLoading,
    singleCustomerLoading,
    supplierDetail,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    setValue,
    deleteCustomer: deleteCustomerEntry,
    watch,
    currentPage,
    setCurrentPage,
    clearErrors,
    isSubmitting,
  };
};

export default useSuppliersList;
