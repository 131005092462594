import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../features/Modal/ModalSlice";
import { Plus } from "../../../../../common/Icons/fontawesome";
import Loader from "../../../../../common/components/Loader/Loader";
import Breadcrumb from "../../../../../common/components/BreadCrumb/BreadCrumb";
import CustomButton from "../../../../../common/components/Button/Button";
import CustomTable from "../../../../../common/components/CustomTable/CustomTable";
import Layout from "../../../Layout/Layout";
import CustomModal from "../../../../../common/components/CustomModel/CustomModel";
import DetailCard from "../../../../../common/components/DetailCard/DetailCard";
import usePromotionsList from "./usePromotionsList";
import AddEditPromotions from "../AddEditPromotions/AddEditPromotions";
import { EditIcon, EyeIcon, TrashIcon } from "../../../../../common/Icons";
import PaginationBar from "../../../../../common/components/PaginationBar/PaginationBar";
import { useTranslation } from "react-i18next";

/**
 * User List component
 * Renders table of users list
 *
 * @returns {JSX.Element} JSX code for rendering the user list table
 */

export default function PromotionsList() {
  const { t } = useTranslation();
  const [isInputChanged, setIsInputChanged] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);

  const {
    promotionList,
    customerDetail,
    register,
    errors,
    itemList,
    handleSubmit,
    setValue,
    deleteUser,
    promotionListLoading,
    singlePromotionLoading,
    storesList,
    watch,
    currentPage,
    clearErrors,
    setCurrentPage,
    control,
    singlePromotion
  } = usePromotionsList(selectedItem?.id);

  const handleViewPromotion = (type) => {
    dispatch(
      openModal({
        title: `Promotion ${type}`,
        type: type,
        editable: true,
      })
    );
  };

  const handlePromotionActions = (type) => {
    if (type === "add") setSelectedItem(null);
    clearErrors();
    dispatch(
      openModal({
        title: `${type} Promotion`,
        type: type,
      })
    );
  };

  return (
    <Layout>
      {/* header */}
      <div className="flex items-center justify-between">
        <Breadcrumb pages={pages} />
        <CustomButton
          text={t("client_dashboard.management.promotions.add_promotions")}
          width="w-fit"
          bgColor="!bg-surface-primary"
          hoverTextColor="text-white"
          className="hover:!text-white"
          icon={<Plus color="white" />}
          onClick={() => {
            handlePromotionActions("add");
          }}
        />
      </div>
      <div className="h-[calc(100vh_-_25vh)] flex flex-col justify-between">
        {/* user list table */}
        <CustomTable
          tableHeader={tableHeader}
          tableBody={promotionList}
          setSelectedItem={setSelectedItem}
          loading={promotionListLoading}
          dropdownOptions={[
            {
              text: t("buttons.show_details"),
              icon: <EyeIcon />,

              onClick: () => handleViewPromotion("detail"),
            },
            {
              text: t("buttons.edit"),
              icon: <EditIcon className="w-6" />,

              onClick: () => handlePromotionActions("edit"),
            },
            {
              text: t("buttons.delete"),
              textColor: "text-red-500",
              icon: <TrashIcon />,
              onClick: () => handlePromotionActions("delete"),
            },
          ]}
        />
        <PaginationBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalRecords={promotionList ? promotionList?.length : 0}
        />
      </div>

      {/* modal */}
      <CustomModal
        onAdd={handleSubmit}
        onEdit={() => handlePromotionActions("edit")}
        onDelete={() => deleteUser("delete")}
        isInputChanged={isInputChanged}
      >
        {/* conditionally render modal for different user actions */}
        {(modalState?.type === "edit" || modalState?.type === "add") && (
          <AddEditPromotions
            user={selectedItem ? singlePromotion : null}
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            storesList={storesList}
            itemList={itemList ?? []}
            setIsInputChanged={setIsInputChanged}
            control={control}
          />
        )}
        {/* detail modal */}
        {modalState?.type === "detail" &&
          (singlePromotionLoading ? (
            <Loader />
          ) : (
            <DetailCard detail={customerDetail} />
          ))}
        {/* delete modal */}
        {modalState?.type === "delete" && (
          <p className="text-text-primary text-base font-normal">
            Are you sure you want to delete this Promotion?{" "}
          </p>
        )}
      </CustomModal>
    </Layout>
  );
}
// breadcrumbs pages
const pages = [
  { name: "client_dashboard.management.name", href: "#", current: false },
  {
    name: "client_dashboard.management.promotions.name",
    href: "#",
    current: true,
  },
];
// table headings for users table

const tableHeader = [
  { label: "Promotion Name", key: "name" },
  { label: "Start Date", key: "startDate", type: "date" },
  { label: "End Date", key: "endDate", type: "date" },
  { label: "Type", key: "type" },
  { label: "Store", key: "store.branchName" },
  {
    label: "Status",
    key: "status",
    bgColorKey: {
      ACTIVE: "bg-success-50 text-positive-600",
      INACTIVE: "bg-danger-100 text-danger-600",
    },
  },
];
