import React, { useEffect } from "react";
import { useFullPay } from "./useFullPay";
import FullPayByPoints from "./FullPayByPoints";
import FullPayByCard from "./FullPayByCard";
import FullPayByDeliveryOrder from "./FullPayByDeliveryOrder";
import {
  Card,
  CurrencySign,
  Truck,
  VerticalBlackCoin,
} from "../../../../common/Icons";
import { useTranslation } from "react-i18next";
import { CustomButton, Loader } from "../../../../common/components";

function FullPay({
  setIsModalOpen,
  setCheckOutVisible,
  setOrderCheckoutPaid,
  setIsCheckoutModalOpen,
}) {
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentMethodsListLoading,
    paymentMethodsList,
    total,
    orderType,
    totalAmount,
    taxAmount,
    discountAmount,
    handleCheckout,
  } = useFullPay(
    setCheckOutVisible,
    setOrderCheckoutPaid,
    setIsCheckoutModalOpen
  );

  const { t } = useTranslation();

  const iconMapping = {
    CASH: CurrencySign,
    CARD: Card,
    POINTS: VerticalBlackCoin,
    DELIVERY_COMPANIES: Truck,
  };

  const componentMapping = {
    CASH: null,
    CARD: FullPayByCard,
    POINTS: FullPayByPoints,
    DELIVERY_COMPANIES: FullPayByDeliveryOrder,
  };

  const formatPaymentMethodType = (type) => {
    return type
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderSelectedPaymentMethod = () => {
    const PaymentComponent = componentMapping[selectedPaymentMethod];
    return PaymentComponent ? <PaymentComponent /> : null;
  };

  useEffect(() => {
    if (paymentMethodsList && paymentMethodsList.length > 0) {
      setSelectedPaymentMethod(paymentMethodsList[1].type);
    }
  }, [paymentMethodsList, setSelectedPaymentMethod]);

  if (paymentMethodsListLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <p className="my-2 text-sm font-medium">Choose Payment Method</p>
      <div className="grid grid-cols-2 gap-4 mb-2">
        {paymentMethodsList?.map(({ id, type }) => {
          const IconComponent = iconMapping[type];
          const isDisabled =
            orderType === "DINE_IN" && type === "DELIVERY_COMPANIES";
          return (
            <div
              key={id}
              className={`flex items-center justify-between border p-4 rounded-lg ${
                selectedPaymentMethod === type && !isDisabled
                  ? "border-blue-500 bg-blue-100"
                  : "border-gray-300 bg-white"
              } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={() => !isDisabled && setSelectedPaymentMethod(type)}
            >
              <div className="flex items-center gap-2">
                {IconComponent && (
                  <IconComponent
                    className={`text-lg ${
                      selectedPaymentMethod === type && !isDisabled
                        ? "text-blue-600"
                        : "text-text-primary"
                    }`}
                  />
                )}
                <span
                  className={`ml-2 ${
                    selectedPaymentMethod === type && !isDisabled
                      ? "text-blue-700 font-medium"
                      : "text-text-primary font-medium"
                  }`}
                >
                  {formatPaymentMethodType(type)}
                </span>
              </div>
              <div
                className={`h-5 w-5 rounded-full border-2 p-1 ${
                  selectedPaymentMethod === type && !isDisabled
                    ? "border-blue-500 bg-blue-500"
                    : "border-gray-300"
                }`}
              >
                {selectedPaymentMethod === type && !isDisabled && (
                  <div className="h-2 w-2 bg-white rounded-full"></div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {renderSelectedPaymentMethod()}
      {selectedPaymentMethod !== "DELIVERY_COMPANIES" && (
        <>
          <div className="bg-white rounded-lg mt-[35px] p-2 w-full">
            <hr className="border mb-2" />
            <div className="flex justify-between pb-2">
              <span className="text-sm text-gray-500">
                {t("pos_dashboard.sub_total")}
              </span>
              <span className="text-sm font-medium">{totalAmount ?? "0"} SAR</span>
            </div>
            <div className="flex justify-between pb-2">
              <span className="text-sm text-gray-500">
                {t("pos_dashboard.discount")}
              </span>
              <span className="text-sm font-medium">{discountAmount ?? "0"} SAR</span>
            </div>
            <div className="flex justify-between pb-2">
              <span className="text-sm text-gray-500">
                {t("pos_dashboard.vat")}
              </span>
              <span className="text-sm font-medium">{taxAmount ?? "0"} SAR</span>
            </div>
            <div className="flex justify-between pb-2">
              <span className="text-sm text-gray-500">
                {t("pos_dashboard.total")}
              </span>
              <span className="text-sm font-medium">{total ?? "0"} SAR</span>
            </div>
            <hr className="border mb-2" />
          </div>
        </>
      )}
      <div className="w-full pt-2">
        <CustomButton
          text={t("pos_dashboard.checkout")}
          type="button"
          onClick={handleCheckout}
        />
      </div>
    </div>
  );
}

export default FullPay;
