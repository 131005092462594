import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addPosOrder,
  handleCheckoutData,
  updatePosOrder,
} from "../../../../features/PosOrder/PosOrderSlice";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../common/utils/showToast.util";

const OrderTabs = [
  { label: "Dine In", value: "DINE_IN" },
  { label: "Takeaway", value: "TAKEAWAY" },
];

const useOrderDetail = (selectedItems, orderDuplicateData) => {
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(
    OrderTabs.find(
      (option) => option.value === orderDuplicateData?.orderType
    ) ?? OrderTabs[0]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [saveOrderModalVisible, setSaveOrderModalVisible] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [itemTotals, setItemTotals] = useState({});
  const [checkOutVisible, setCheckOutVisible] = useState(false);
  const [isCheckoutModelOpen, setIsCheckoutModelOpen] = useState(false);
  const [isChooseDiscountModalOpen, setIsChooseDiscountModalOpen] =
    useState(false);
  const [orderCheckoutPaid, setOrderCheckoutPaid] = useState(null);
  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const closeCheckoutModal = () => setIsCheckoutModelOpen(false);
  const dispatch = useDispatch();
  const TableModal = () => setIsTableOpen(!isTableOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [selectedCustomer, setSelectedCustomer] = useState(
    orderDuplicateData?.orderData?.customer ?? null
  );
  const [selectedTables, setSelectedTables] = useState(
    orderDuplicateData?.table ?? null
  );
  const { t } = useTranslation();
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const branchName = storedUserData?.user?.store?.branchName;
  const fullName = storedUserData?.user?.fullName;
  const storeId = storedUserData?.user?.store?.id;

  const [checkoutData, setCheckoutData] = useState(null);
  const shouldShowButton = (buttonType) => {
    return activeTab !== "TAKEAWAY";
  };

  const updateItemTotal = (itemId, total) => {
    setItemTotals((prev) => ({ ...prev, [itemId]: total }));
  };

  const subtotal = Object.values(itemTotals).reduce(
    (acc, total) => acc + total,
    0
  );
  const VAT_RATE = 0.15;
  const vat = subtotal * VAT_RATE;
  const grandTotal = subtotal + vat;

  // Function to handle selection of promotion and closing the modal
  const handleSavePromotion = (newPromotion) => {
    setSelectedPromotions((prevPromotions) => {
      // If the promotion already exists, replace it; otherwise, add it
      const index = prevPromotions.findIndex((p) => p.id === newPromotion.id);
      if (index > -1) {
        const updatedPromotions = [...prevPromotions];
        updatedPromotions[index] = newPromotion;
        return updatedPromotions;
      }
      return [...prevPromotions, newPromotion];
    });
    setIsChooseDiscountModalOpen(false);
  };

  const handleDeletePromotion = (promotionId) => {
    setSelectedPromotions((prevPromotions) =>
      prevPromotions.filter((promotion) => promotion.id !== promotionId)
    );
  };

  const handleChangeDiscount = (promotionId) => {
    setIsChooseDiscountModalOpen(true);
  };

  const openChooseDiscountModal = () => {
    setIsChooseDiscountModalOpen(true);
  };
  const closeChooseDiscountModal = () => {
    setIsChooseDiscountModalOpen(false);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(false);
  };

  // Function to remove the selected customer
  const handleRemoveCustomer = () => {
    setSelectedCustomer(null);
  };

  // Function to remove the selected tables
  const handleRemoveTables = () => {
    setSelectedTables(null);
  };

  const toggleSaveOrderModal = () => {
    let missingElements = [];

    if (selectedItems.length === 0) {
      missingElements.push("Item");
    }
    if (selectedCustomer === null) {
      missingElements.push("Customer");
    }
    if (activeTab.value === "DINE_IN" && selectedTables === null) {
      missingElements.push("Table");
    }

    // Construct and show error message if any elements are missing
    if (missingElements.length > 0) {
      const formattedMessage = `Please add ${missingElements.join(", ")}`;
      return showToast(formattedMessage, "error");
    }
    // Only toggle the modal visibility if all conditions are met
    setSaveOrderModalVisible(!saveOrderModalVisible);
  }


  const toggleCheckOutVisible = () => {
    let missingElements = [];

    if (selectedItems.length === 0) {
      missingElements.push("Item");
    }
    if (selectedCustomer === null) {
      missingElements.push("Customer");
    }
    if (activeTab.value === "DINE_IN" && selectedTables === null) {
      missingElements.push("Table");
    }

    // Construct and show error message if any elements are missing
    if (missingElements.length > 0) {
      const formattedMessage = `Please add ${missingElements.join(", ")}`;
      return showToast(formattedMessage, "error");
    }

    // Proceed to toggle checkout visibility only if all conditions are met
    if (!checkOutVisible) {
      const payload = {
        tableId: selectedTables?.id || undefined,
        customerId: selectedCustomer?.id,
        storeId,
        orderType: activeTab?.value,
        orderedItems: selectedItems.map((s) => ({
          quantity: +s?.quantity || 1,
          note: s?.note || "",
          itemId: orderDuplicateData ? s?.item?.id : s?.id,
          vItemId: s?.vItemId || undefined,
        })),
      };
      if (state?.type === "edit") {
        dispatch(
          updatePosOrder({
            orderId: state?.id,
            payload,
            successCallBack: handleSuccess,
          })
        );
      } else if (state?.type === "duplicate") {
        // Handle duplicate scenario if needed
      } else {
        dispatch(addPosOrder({ payload, successCallBack: handleSuccess }));
      }
    }
  };




  const handleSuccess = (response) => {
    setCheckOutVisible(!checkOutVisible);
    setCheckoutData(response?.data?.data);
    dispatch(handleCheckoutData(response?.data?.data));
  };

  return {
    closeChooseDiscountModal,
    openChooseDiscountModal,
    handleChangeDiscount,
    handleDeletePromotion,
    handleSavePromotion,
    shouldShowButton,
    toggleCheckOutVisible,
    toggleSaveOrderModal,
    toggleModal,
    closeCheckoutModal,
    selectedPromotions,
    isCheckoutModelOpen,
    isChooseDiscountModalOpen,
    setActiveTab,
    useOrderDetail,
    saveOrderModalVisible,
    activeTab,
    setIsCheckoutModelOpen,
    checkOutVisible,
    setIsChooseDiscountModalOpen,
    isModalOpen,
    OrderTabs,
    TableModal,
    isTableOpen,
    setIsTableOpen,
    setIsModalOpen, orderCheckoutPaid, setOrderCheckoutPaid,
    handleRemoveTables,
    handleRemoveCustomer,
    handleSelectCustomer,
    selectedTables,
    selectedCustomer,
    setSelectedTables,
    setSelectedCustomer,
    vat,
    setCheckOutVisible,
    grandTotal,
    subtotal,
    updateItemTotal,
    storeId,
    branchName,
    fullName,
    currentDate,
    checkoutData,
    isCheckoutModalOpen, setIsCheckoutModalOpen
  };
};

export default useOrderDetail;
