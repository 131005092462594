import React from "react";
import Navbar from "../common/components/Navbar/Navbar";
import Footer from "../common/components/Footer/Footer";
import { SignUpMainImage } from "../../assets/images";
import { useConfirmEmail } from "./useConfirmEmail";
import { EnvelopIcon } from "../common/Icons";
import CustomButton from "../common/components/Button/Button";
import { useTranslation } from "react-i18next";

/**
 * ConfirmEmail component.
 * Renders a form to confirm the user's email by entering a code received via email.
 *
 * @returns {JSX.Element} JSX code for rendering the ConfirmEmail component.
 */

const ConfirmEmail = () => {
  const { t } = useTranslation();
  const {
    code,
    register,
    handleSubmit,
    handleInputChange,
    onSubmit,
    email,
    isSubmitting,
    resendOtp,
  } = useConfirmEmail();

  return (
    <div className="flex max-h-screen overflow-hidden">
      <div className="w-full flex flex-col justify-between">
        <Navbar language />
        <div className="lg:max-w-[480px] mx-auto">
          {/* envelop icon */}
          <EnvelopIcon className="mx-auto" />
          {/* heading */}
          <h2 className="text-3xl font-semibold my-3 text-center text-text-primary">
            {t(`confirm_email.title`)}
          </h2>
          <p className="text-text-secondary text-base font-normal text-center">
            {t(`confirm_email.instructions`, { email })}
          </p>
          {/* form */}
          <form className="space-y-4 !mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center gap-3 mb-4">
              {code.map((value, index) => (
                <input
                  key={index}
                  {...register(`code${index}`, { required: true })}
                  type="text"
                  maxLength="1"
                  value={value}
                  onChange={(e) => handleInputChange(e.target, index)}
                  className="max-w-20 py-4 w-full border border-border-primary text-center text-xl font-semibold text-text-primary form-input rounded-lg"
                />
              ))}
            </div>
            <div className="flex justify-center">
              <CustomButton
                text={t(`buttons.continue`)}
                disabled={code.some((value) => value === "")}
                type="submit"
                className="mt-3"
                isLoading={isSubmitting}
              />
            </div>
            <div className="text-base text-text-secondary text-center !mt-10 flex items-center justify-center">
              {t(`confirm_email.didnt_receive_code`)}
              <CustomButton
                text={t(`confirm_email.resend_code`)}
                textColor="text-text-link"
                className="hover:underline ml-1"
                width="fit-content"
                bgColor="transparent"
                padding="p-0"
                hoverBgColor="bg-white"
                hoverTextColor="text-text-link"
                onClick={resendOtp}
              />
            </div>
          </form>
        </div>

        <Footer />
      </div>

      <div className="lg:min-w-[640px] hidden lg:block bg-bright-cerulean">
        <h3 className="text-center text-white text-3xl font-bold leading-[48px] mt-28">
          {t(`confirm_email.image_heading`)}
        </h3>
        <p className="text-white text-base text-center font-normal leading-normal m-3">
          {t(`confirm_email.image_description`)}
        </p>
        <div>
          <img
            src={SignUpMainImage}
            alt="Login"
            className="rounded-r-2xl h-full mt-14 mb-14 ml-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;

// Sample Usage:
// import ConfirmEmail from "./path/to/ConfirmEmail"
// <ConfirmEmail />
