import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePosOrder,
  getAllPosOrders,
  handleDuplicateData,
  returnPosOrder,
} from "../../../../features/PosOrder/PosOrderSlice";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const headers = [
  { label: "Invoice No", key: "invoiceno" },
  { label: "Delivery Order No", key: "deleiveryorderno" },
  { label: "Invoice Date", key: "invoicedate" },
  { label: "Created by", key: "createdby" },
  { label: "Customer", key: "customer" },
  { label: "Store", key: "store" },
  { label: "Table Number", key: "tablenumber" },
  { label: "Tax Amount", key: "taxamount" },
  { label: "Total", key: "total" },
  { label: "Payment Method", key: "paymentmethod" },
  { label: "Status", key: "status" },
];

const dropdownOptions = [
  { label: "Checkout", value: "checkout", toShow: ["ON_PROCESS", "SAVED"] },
  { label: "View", value: "view", toShow: ["COMPLETED", "REFUND"] },
  { label: "Refund", value: "refund", toShow: ["COMPLETED"] },
  { label: "Duplicate", value: "duplicate", toShow: ["Completed"] },
];

export const usePosProcessingOrder = (setSelectedItem) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState("All");
  const [tableBody, setTableBody] = useState([]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const { data: orderList, isLoading: orderListLoading } = useSelector(
    (state) => state.posOrder.getAllPosOrders
  );
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const parsedData = localStorage.getItem("user");
  const storedUserData = JSON.parse(parsedData);
  const branchName = storedUserData?.user?.store?.branchName;
  const fullName = storedUserData?.user?.fullName;

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllPosOrders({ payload }));
  }, [dispatch]);

  // Fetch all POS orders again after successful deletion
  const successDeleteOrder = () => {
    dispatch(getAllPosOrders());
  };

  // Exclude 'SAVED' from all tabs and handle the filtering based on the selected tab
  useEffect(() => {
    let filteredOrders;
    if (filterStatus === "All") {
      filteredOrders = orderList?.filter((order) => order?.status !== "SAVED");
    } else {
      filteredOrders = orderList?.filter(
        (order) => order?.status === filterStatus
      );
    }

    if (searchText) {
      filteredOrders = filteredOrders?.filter((order) => {
        return headers.some((header) => {
          const value = order[header.key];
          return (
            value && value.toString().toLowerCase().includes(searchText.toLowerCase())
          );
        });
      });
    }

    const mappedOrders = filteredOrders?.map((order) => ({
      ...order,
      invoiceno: order.invoiceNumber,
      deleiveryorderno: order.dailyOrderNumber,
      invoicedate: order.invoiceDate,
      createdby: order.createdBy || "-",
      customer: order.customer?.name || "-",
      store: order.store?.branchName || "-",
      tablenumber: order.table?.position || "-",
      taxamount: order.taxAmount || "-",
      total: order.totalAmount || "-",
      paymentmethod: order.paymentMethod || "-",
      status: order.status
        ? order.status
            .split("_")
            .map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")
        : "-",
      orderData: order,
    }));

    setTableBody(mappedOrders);
  }, [orderList, filterStatus, searchText]);

  const openModal = (order) => {
    setSelectedItem(order);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedItem(null);
  };

  const handleTabChange = (selectedTab) => {
    // Convert the tab text to the expected status text
    const statusMap = {
      All: "All",
      "On Process": "ON_PROCESS",
      Refund: "REFUND",
      Completed: "COMPLETED",
    };
    setFilterStatus(statusMap[selectedTab]);
  };

  const duplicateOrder = (order) => {
    dispatch(handleDuplicateData(order));
    navigate("/pos/dashboard", { state: { type: "duplicate", id: order.id } });
  };

  const deleteOrder = (order) => {
    const { id: orderId } = order;
    dispatch(deletePosOrder({ orderId, successCallBack: successDeleteOrder }));
  };

  const refundOrder = (order) => {
    const { id: orderId } = order;
    dispatch(returnPosOrder({ orderId, successCallBack: successDeleteOrder }));
  };

  const dropdownOptionsWithActions = dropdownOptions?.map((option) => ({
    ...option,
    action: (order) => {
      if (option.value === "checkout") {
        openModal(order);
      } else if (option.value === "duplicate") {
        return duplicateOrder(order);
      } else if (option.value === "view") {
        openModal(order);
      } else if (option.value === "delete") {
        return deleteOrder(order);
      } else if (option.value === "refund") {
        return refundOrder(order);
      }
    },
  }));

  return {
    isOpen,
    tableBody,
    closeModal,
    openModal,
    dropdownOptions,
    headers,
    handleTabChange,
    filterStatus,
    dropdownOptionsWithActions,
    orderListLoading,
    fullName,
    branchName,
    currentDate,
    refundOrder,
    setSearchText,
    duplicateOrder,
    
  };
};
