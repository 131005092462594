import React, { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import useClientsSale from "./useClientsSale";
import { Loader } from "../../../../common/components";

const ClientsSale = () => {
  const chartRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const { salesData, cardListLoading } = useClientsSale(startDate, endDate);

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, "rgba(46, 144, 250, 0.29)");
      gradient.addColorStop(1, "rgba(46, 144, 250, 0.29)");
      chart.data.datasets[0].backgroundColor = gradient;
      chart.update();
    }
  }, [salesData]);

  const totalSale = salesData?.find(item => item.totalSale)?.totalSale;
  const monthlySales = salesData?.filter(item => !item.totalSale);

  const labels = monthlySales?.map(sale => `${sale.monthName} ${sale.year}`) || [];
  const data = monthlySales?.map(sale => sale.monthlySale) || [];

  const chartData = {
    labels: labels.length > 0 ? labels : ["No Data"],
    datasets: [
      {
        label: "Sales",
        data: data.length > 0 ? data : [0],
        fill: true,
        borderColor: "#2E90FA",
        backgroundColor: "rgba(46, 144, 250, 0.1)", // Adjust the gradient color here
        borderWidth: 2,
        tension: 0.1,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointBackgroundColor: "#2E90FA", // Color for the points
        pointBorderColor: "#2E90FA",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 12,
          boxWidth: 6,
          boxHeight: 6,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const sale = context.raw;
            return `${sale} SAR`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          drawOnChartArea: true,
          color: function (context) {
            if (context.tick.value === 0) {
              return "#000000";
            } else {
              return "rgba(0, 0, 0, 0.1)";
            }
          },
          borderDash: [5, 5],
          lineWidth: 1,
          drawTicks: false,
          tickLength: 4,
        },
        ticks: {
          stepSize: 100,
          padding: 10,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 10,
        },
      },
    },
  };

  if (cardListLoading) {
    return (
      <div className="items-center flex px-[700px] py-12">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center space-x-4 sticky mt-2 ">
        <p className="flex items-center flex-grow text-text-primary font-medium text-base">
          Sales{" "}
        </p>
        <CustomDatePicker
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={(selection) => {
            handleDateChange(selection);
          }}
        />
      </div>
      <p className="text-sm font-normal">Total Sales</p>
      <p className="text-xl font-medium">{totalSale ?? "0"} SAR</p>
      
      <div className="w-full h-52">
        <Line ref={chartRef} data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ClientsSale;
