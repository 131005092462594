import React, { useEffect, useMemo } from "react";
import InputGroup from "../../../../../common/components/InputGroup/InputGroup";
import { CustomFileUploader } from "../../../../../common/components";
import { checkInputChanges } from "../../../../../../helpers/checkInputChanges";
import { useTranslation } from "react-i18next";

/**
 * AddEditCategory component.
 * Renders a form to add or edit a category
 *
 * @returns {JSX.Element} JSX code for rendering the AddEditCategory component
 */
const AddEditCategory = ({
  category,
  register,
  errors,
  setValue,
  watch,
  setIsInputChanged,
}) => {
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => ({
      englishname: category?.englishName ?? "",
      arabicname: category?.arabicName ?? "",
      accesstopos: category?.isVisibleToPos ?? false,
    }),
    [category]
  );

  useEffect(() => {
    // set the default values for each field to edit customer using 'setValue' method of react-hook-form
    defaultValues &&
      Object.keys(defaultValues).forEach((key) =>
        setValue(key, defaultValues[key])
      );
  }, [category, setValue, defaultValues]);

  // set currentValues to track changes in the input fields using watch method
  const currentValues = watch();

  useEffect(() => {
    // Check if the current state is different from the initial state
    setIsInputChanged(checkInputChanges(currentValues, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues, defaultValues]);

  return (
    <React.Fragment>
      <CustomFileUploader selected={category ? category?.image : null} />

      {/* englis name */}
      <InputGroup
        labelText={t("client_dashboard.directory.category.english_name")}
        htmlFor="englishname"
        inputName="englishname"
        placeholder={t(
          "client_dashboard.directory.category.enter_english_name"
        )}
        register={register}
        errors={errors}
      />
      {/* arabic name */}
      <InputGroup
        labelText={t("client_dashboard.directory.category.arabic_name")}
        htmlFor="arabicname"
        inputName="arabicname"
        placeholder={t("client_dashboard.directory.category.enter_arabic_name")}
        register={register}
        errors={errors}
      />
      {/* access to pos */}
      <div className="flex items-center gap-2">
        <InputGroup
          inputName="accesstopos"
          inputType="checkbox"
          register={register}
          errors={errors}
          className="!leading-none"
        />
        <label
          htmlFor="accesstopos"
          className="block text-sm font-normal text-text-primary leading-none"
        >
          {t("client_dashboard.directory.category.view_this_category")}
        </label>
      </div>
    </React.Fragment>
  );
};

export default AddEditCategory;
