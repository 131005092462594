import React, { useEffect, useState } from "react";
import { MultiSelecCheckbox } from "../../../../common/components";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import { getClientSalesByItems } from "../../../../../features/ClientDashboard/ClientDashboardSlice";
import { useForm, Controller } from "react-hook-form";
import { getAllItems } from "../../../../../features/Items/ItemsSlice";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";

// Register the chart components you need
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function SalesByItems() {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // January 1st of the current year
  const [endDate, setEndDate] = useState(new Date());
  const { data: sales, isLoading } = useSelector(
    (state) => state.clientDashboard.getClientSalesByItems
  );
  // get item list for all items from the store
  const { data: itemList } = useSelector((state) => state.items.getAllItems);

  const monthNames = sales?.map((item) => item?.month);

  const itemNames = sales?.map((data) =>
    data?.data?.flatMap((item) => item.itemEnglishName)
  );

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   */
  const { control, setValue, watch } = useForm({
    defaultValues: { selectedItems: [] },
  });

  const selectedItems = watch("selectedItems");

  /**
   * Handles the api call for getting all sales and cost
   * This function can be asynchronous for tasks like API calls.
   *
   *   @param {object} payload
        {
            "startDate": "2024-02-01",
            "endDate": "2024-02-09"
            "ids": [1,2,3]
        }
   */

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
      ids: selectedItems,
    };
    dispatch(getClientSalesByItems({ payload }));
  }, [startDate, endDate, dispatch, selectedItems]);

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllItems({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (selection) => {
    if (selection) {
      setStartDate(selection?.startDate);
      setEndDate(selection?.endDate);
    }
  };

  function createChartData(itemsMap, monthNames) {
    const datasets = Object.values(itemsMap); // Convert item data into an array
    return {
      labels: monthNames,
      datasets: datasets,
    };
  }

  function prepareChartData(sales, monthNames) {
    const itemsMap = {};

    // Initialize items map with all items and months set to zero sales
    sales?.forEach((month, index) => {
      month.data?.forEach((sale) => {
        if (sale.itemId && !itemsMap[sale.itemId]) {
          itemsMap[sale.itemId] = {
            label: sale.itemEnglishName || sale.itemArabicName,
            data: new Array(monthNames.length).fill(0),
            backgroundColor: getRandomColor(),
            borderRadius: 8,
          };
        }
      });
    });

    // Fill in sales data for each item and month
    sales?.forEach((month, index) => {
      month.data?.forEach((sale) => {
        if (sale.itemId && itemsMap[sale.itemId]) {
          itemsMap[sale.itemId].data[index] = sale.monthlyTotal;
        }
      });
    });

    return createChartData(itemsMap, monthNames);
  }

  function getRandomColor() {
    const colors = ["#53B1FD", "#2BE3B7", "#92E4FE", "#2E90FA"]; // Define more colors as needed
    return colors[Math.floor(Math.random() * colors.length)];
  }

  // Example of how to use this function
  const chartData = prepareChartData(sales, monthNames);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 10, // set checkbox width
          boxHeight: 10, // set checkbox height
          usePointStyle: true, // use circle instead of square
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawBorder: false, // This will hide the border
        },
      },
    },
  };

  const data = {
    labels: monthNames,
    datasets: chartData?.datasets,
  };

  return (
    <div className=" border rounded-lg p-2 !mt-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text-primary">
          Sales by Items
        </h2>
        <span className="flex justify-between gap-2">
          <Controller
            control={control}
            name="selectedItems"
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <MultiSelecCheckbox
                  options={itemList?.map((option) => ({
                    value: option?.vItemId || option?.id,
                    label: option?.slug || option?.englishName,
                  }))}
                  placeholder="Items"
                  error={error}
                  value={value}
                  onChange={(val) => {
                    setValue("selectedItems", val, { shouldValidate: true });
                  }}
                />
              );
            }}
          />
          <DateRangePicker
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            initialSelectedDates={[startDate, endDate]}
          />
        </span>
      </div>{" "}
      <div className="h-auto w-auto">
        {" "}
        {/* Adjust height and width as needed */}
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}

export default SalesByItems;
