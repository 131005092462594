import React, { useState } from "react";
import { PosLoginBgImage } from "../../../../assets/images";
import { AppleStore, GooglePlay, RightArrow } from "../../../common/Icons";
import Navbar from "../../../common/components/Navbar/Navbar";
import CustomButton from "../../../common/components/Button/Button";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import Footer from "../../../common/components/Footer/Footer";
import { usePosLoginEmail } from "./usePosLoginEmail";
import { Eye, EyeSlash } from "../../../common/Icons/fontawesome";
import { useTranslation } from "react-i18next";
import { Menu, MenuButton, MenuItem } from "@headlessui/react";
import { LanguageSwitcher } from "../../../common/components/LanguageSwitcher/LanguageSwitcher";

const PosLoginEmail = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, errors, handleButtonClick } =
    usePosLoginEmail();

  return (
    <div className="flex min-h-screen">
      {/* image side */}
      <div className="w-2/4 bg-bright-cerulean pb-10 lg:block  flex flex-col">
        {/* language dropdown */}
        <div className="  mt-3  w-fit pl-32 rtl:pr-12">
          <Menu as="div" className="">
            <MenuButton className="flex items-center">
              <MenuItem className="absolute px-4 end-0  z-10 mt-2.5 min-w-[120px] max-w-[120px] origin-top-right rounded-md bg-white py-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <LanguageSwitcher />
              </MenuItem>
            </MenuButton>
          </Menu>
        </div>
        {/* image */}
        <div>
          <img
            src={PosLoginBgImage}
            alt="LoginImage"
            className="ml-10 mt-20 mb-4  h-[620px] left-24 right-0  w-[100%]"
          />
        </div>
        <div className="flex flex-col  items-center   ">
          <span className="text-white text-lg font-medium mb-3">
            {t("pos_dashboard.download_our_app")}
          </span>
          <div className="flex items-center gap-3">
            <AppleStore />
            <GooglePlay />
          </div>
        </div>
      </div>

      {/* email form */}
      <div className="w-2/4  flex flex-col justify-between lg:px-0 px-4 z-10 relative bg-white">
        <div>
          <Navbar />
          <div className="lg:max-w-[400px] mx-auto mt-14">
            <h2 className="text-3xl font-semibold text-text-primary text-center">
              {t("pos_dashboard.login_account")}
            </h2>
            {/* form */}
            <div className="w-[400px]">
              <form onSubmit={handleSubmit} className="space-y-4 !mt-6">
                {/* email */}
                <InputGroup
                  htmlfor="email"
                  labelText={t("pos_dashboard.pos_email")}
                  inputName="email"
                  placeholder="Email"
                  inputType="email"
                  register={register}
                  errors={errors}
                />

                {/* password */}
                <InputGroup
                  htmlfor="password"
                  labelText={t("pos_dashboard.pos_password")}
                  inputName="password"
                  placeholder="Password"
                  inputType={showPassword ? "text" : "password"}
                  register={register}
                  errors={errors}
                >
                  <span
                    className="cursor-pointer absolute end-3 top-8"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? (
                      <Eye color="gray-700" />
                    ) : (
                      <EyeSlash color="gray-700" />
                    )}
                  </span>
                </InputGroup>

                <div className="flex items-center justify-between  ">
                  <div className="flex items-center gap-1  ">
                    <InputGroup
                      htmlfor="remember"
                      inputName="remember"
                      inputType="checkbox"
                      register={register}
                      errors={errors}
                      className="h-2 mr-1"
                    />
                    <label
                      htmlFor="remember"
                      className="mt-4 block text-xs font-normal text-text-primary"
                    >
                      {t("pos_dashboard.pos_remember_me")}
                    </label>
                  </div>
                </div>
                <CustomButton
                  text={t("pos_dashboard.pos_login")}
                  type="submit"
                />
              </form>

              {/* Toggle between the different login forms */}
              <div className="text-center mt-32 mb-40 flex flex-col">
                <CustomButton
                text={t("pos_dashboard.use_code")}
                onClick={handleButtonClick}
                  bgColor="bg-transparent"
                  textColor="text-text-link"
                  borderRadius="none"
                  hoverBgColor="text-blue-800"
                  icon={<RightArrow />}
                  iconPosition="after"
                  className="!shadow-none"
                />
              </div>
            </div>
            <span className="text-sm text-text-primary font-normal justify-center items-center  flex mt-8">
              {t("footer.copyright")} © 2023 TagDev.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosLoginEmail;
