import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../../context/UserContext";
import { setSearchQuery } from "../../../../features/GlobalSearch/GlobalSearchSlice";
import useDebounce from "../../../../hooks/useDebounce";
import { SignoutIcon, ToggleIcon } from "../../../common/Icons";
import CustomSearch from "../../../common/components/CustomSearch/CustomSearch";
import { LanguageSwitcher } from "../../../common/components/LanguageSwitcher/LanguageSwitcher";

/**
 * Navbar component.
 * Renders a navigation bar including search, profile dropdown, and sidebar toggler.
 *
 * @param {Object} props - Props containing sidebarOpen and setSidebarOpen functions.
 * @returns {JSX.Element} JSX code for rendering the Navbar component.
 */

const Navbar = ({ setSidebarOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, userProfile } = useUserContext();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  // Effect to dispatch the debounced search value
  useEffect(() => {
    dispatch(setSearchQuery(debouncedSearchValue));
  }, [debouncedSearchValue, dispatch]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="sticky top-0 z-40 space-x-2 flex py-4 px-6 shrink-0 items-center border-b border-neutral-200 bg-white">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <ToggleIcon strokeColor />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          <CustomSearch
            placeholder="Search..."
            inputName="search"
            paddingY="py-1"
            onChange={handleInputChange}
          />
        </form>
        <div className="flex items-center gap-x-3">
          <Menu as="div" className="relative">
            <MenuButton className="flex items-center">
              <span className="sr-only">Open user menu</span>

              <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src={userProfile?.profilePic}
                alt="Profile"
              />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute px-4 end-0 z-10 mt-2.5 min-w-[280px] origin-top-right rounded-md bg-white py-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <MenuItem>
                  <img
                    className="h-14 mx-auto w-14 rounded-full bg-gray-50"
                    src={userProfile?.profilePic}
                    alt="Profile"
                  />
                </MenuItem>
                <MenuItem className="text-center">
                  <h6 className="text-lg font-semibold text-center mx-auto w-fit">
                    {userProfile?.fullName || "User Name"}
                  </h6>
                </MenuItem>
                <MenuItem className="text-center mb-2">
                  <h6 className="text-base to-text-secondary">
                    {user?.email || "User Email"}
                  </h6>
                </MenuItem>
                <MenuItem className="text-center mb-2">
                  <Link to="/management/settings">
                    <div className="flex items-center gap-3 border border-border-primary rounded-lg py-2 pl-4 pr-5 justify-center w-fit mx-auto">
                      <span>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75Z"
                            stroke="#1A1A1A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                      </span>
                      <span className="text-text-primary font-medium text-sm">
                        Go to Settings
                      </span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem className="mt-2">
                  <LanguageSwitcher />
                </MenuItem>
                <hr className="mt-2" />
                {userNavigation.map((item) => (
                  <MenuItem key={item.name}>
                    <Link to={item?.href}>
                      <span className="text-red-700 flex items-center gap-2 mt-2">
                        <SignoutIcon /> {t("buttons.signout")}
                      </span>
                    </Link>
                  </MenuItem>
                ))}
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const userNavigation = [{ name: "Sign out", href: "/logout" }];

// Sample Usage:
// import Navbar from "path/to/Navbar";
// <Navbar sidebarOpen={true} setSidebarOpen={() => {}} />
