import React from "react";
import ProfileLayout from "../Layout";
import CustomButton from "../../../common/components/Button/Button";
import useSetPlan from "./useSetPlan";
import InputGroup from "../../../common/components/InputGroup/InputGroup";
import FinishScreen from "../FinishScreen/FinishScreen";
import { Card, TamaraIcon } from "../../../common/Icons";
import { useTranslation } from "react-i18next";
import SelectPlanCards from "./SelectPlanCards";
import InputLabel from "../../../common/components/InputLabel/InputLabel";
import DateRangePicker from "../../../common/components/DateRangePicker/DateRangePicker";

export const SetPlan = () => {
  const { t } = useTranslation();
  let selectedOption = "Card";

  const handleOptionChange = () => {};

  const {
    showFinish,
    handleSubmit,
    setBranchCount,
    branchCount,
    setActiveTab,
    activeTab,
    setSelectedPlan,
    selectedPlan,
    additionalBranchPrice,
    totalPrice,
    tax,
    totalPriceBeforeTax,
    discountPrice,
    register,
    errors,
    apiError,
  } = useSetPlan();
  return (
    <div>
      <form>
        {showFinish ? (
          <FinishScreen />
        ) : (
          <ProfileLayout activePage={t("stepper.setup_your_plan")}>
            <div className="bg-white border rounded-lg p-4 mx-auto xl:w-2/3 w-11/12 mt-12">
              <div className="space-y-4 !mt-2">
                <SelectPlanCards
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  branchCount={branchCount}
                  setBranchCount={setBranchCount}
                  additionalBranchPrice={additionalBranchPrice}
                />
                <hr className="border-t-2 border-gray-100 px-2 my-4" />
                <div className="flex flex-col">
                  <InputLabel
                    className="text-text-primary !font-semibold !text-base"
                    htmlfor="payment method"
                    text="Payment Method"
                  />
                  <div className="flex mt-3 gap-3">
                    {paymentTypes?.map((item, index) => (
                      <div
                        key={item?.value}
                        className={`px-3 py-1 flex items-center border gap-4 rounded-lg relative max-w-[400px] w-full ${
                          selectedOption === item.value &&
                          "border-sky-blue bg-info-50"
                        } `}
                      >
                        <div
                          onClick={() => handleOptionChange(item?.value)}
                          className="absolute inset-0 bg-transparent z-20 cursor-pointer"
                        />

                        <item.icon
                          stroke={selectedOption === item.value}
                          className="!w-8 !h-8"
                        />

                        <InputGroup
                          inputName="simple"
                          labelText={item?.name}
                          inputType="radio"
                          className="flex items-center justify-between w-full !space-y-0"
                          padding="p-0"
                          checked={selectedOption === item?.value}
                          onChange={handleOptionChange}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col w-full mt-4">
                  {/* Discount Code */}
                  <InputGroup
                    htmlfor="card"
                    labelText="Card Number"
                    inputName="cardNumber"
                    placeholder="Enter Card Number"
                    inputType="text"
                    register={register}
                    errors={errors}
                  />

                  <div className="flex w-full gap-3 mt-4">
                    <div className="w-full">
                      <DateRangePicker dropdownIcon={false} />
                    </div>

                    <InputGroup
                      htmlfor="cvv"
                      inputName="cvv"
                      placeholder="CVV"
                      className="w-full"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <InputGroup
                    htmlfor="name"
                    labelText="Discount"
                    inputName="discount"
                    placeholder="Enter Code"
                    className="mt-4"
                    // register={register}
                    // errors={errors}
                  />
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg p-6 mx-auto mt-8 xl:w-2/3 w-11/12">
              <h4 className="text-text-primary font-semibold mb-5">Summary</h4>
              <div className="flex flex-col w-full mt-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-text-secondary font-normal text-base">
                    Total Before Tax
                  </span>
                  <span className="text-text-primary text-2xs font-semibold">
                    {totalPriceBeforeTax ?? "0"} SAR
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-text-secondary font-normal text-base">
                    Total Discount
                  </span>
                  <span className="text-text-primary text-2xs font-semibold">
                    {discountPrice ?? "0"} SAR
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-text-secondary font-normal text-base">
                    Tax Amount
                  </span>
                  <span className="text-text-primary text-2xs font-semibold">
                    {tax ?? "0"} SAR
                  </span>
                </div>
                <div className="flex justify-between items-center mb-2">
                  <span className="text-text-primary font-semibold">
                    Subtotal
                  </span>
                  <span className="text-text-link text-lg font-semibold">
                    {totalPrice ?? "0"} SAR
                  </span>
                </div>
              </div>

              <hr className="border-t-2 border-gray-100 px-2 my-4" />
              <span className=" text-text-secondary text-sm font-normal">
                By confirming your subscription, you allow TagDev co to charge
                your card for this payment and future payments in accordance
                with their terms. You can always cancel your subscription.
              </span>

              <CustomButton
                text="Confirm Subscription to January 20, 2024"
                type="submit"
                className="mt-6"
                onClick={handleSubmit}
              />
              {apiError.length > 0 && (
                <div>
                  <h4>Errors:</h4>
                  <ul>
                    {apiError.map((msg, index) => (
                      <li key={index}>{msg}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </ProfileLayout>
        )}
      </form>
    </div>
  );
};
const paymentTypes = [
  { name: "Card", value: "Card", icon: Card },
  { name: "Tamara", value: "Tamara", icon: TamaraIcon },
];
