import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

/**
 * Layout component.
 * Renders the main layout structure including Navbar, Sidebar, and child components.
 *
 * @param {Object} children - The child components to be rendered within the layout.
 * @returns {JSX.Element} JSX code for rendering the Layout component.
 */

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="lg:ps-[265px]">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="p-6">{children}</main>
      </div>
    </div>
  );
};

export default Layout;

// sample usage
// import Layout from "./path/to/layout"
// <Layout></Layout>
