import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { MultiSelecCheckbox, SelectGroup } from "../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import { getAllStores } from "../../../../../features/Stores/StoresSlice";
import CustomDatePicker from "../../../../common/components/CustomDatePicker/CustomDatePicker";
import { Controller, useForm } from "react-hook-form";
import { getClientSalesByStores } from "../../../../../features/ClientDashboard/ClientDashboardSlice";
import { formatDateTime } from "../../../../../helpers/dateFormatter";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";

function SalesByStores() {
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // January 1st of the current year
  const [endDate, setEndDate] = useState(new Date());

  const { data: sales, isLoading } = useSelector(
    (state) => state.clientDashboard.getClientSalesByStores
  );
  // get store list for all stores from the redux store
  const { data: storesList, isLoading: storeListLoading } = useSelector(
    (state) => state.stores.getAllStores
  );

  const storeNames = sales?.map((store) => store?.branchName) ?? [];

  const totalSales = sales?.map((store) => store?.total) ?? [];

  // Form handling using react-hook-form
  /**
   * Object destructuring to manage form functionality with react-hook-form.
   * Provides functions and objects for form input validation and usage.
   *
   * @property {Function} register - Function to register form inputs for validation and usage.
   * @property {Function} handleSubmit - Function to handle form submission.
   * @property {Object} formState - Object holding form validation errors.
   */
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { selectedStores: [] } });

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedStores = watch("selectedStores");

  useEffect(() => {
    const payload = {
      startDate: formatDateTime(startDate, "yyyy-mm-dd"),
      endDate: formatDateTime(endDate, "yyyy-mm-dd"),
      ids: selectedStores,
    };
    dispatch(getClientSalesByStores({ payload }));
  }, [startDate, endDate, dispatch, selectedStores]);

  const data = {
    labels: storeNames,
    datasets: [
      {
        data: totalSales,
        backgroundColor: [
          "#2E90FA", // Tailwind sky-400
          "#53B1FD", // Tailwind sky-500
          "#2BE3B7", // Tailwind sky-600
          "#92E4FE", // Tailwind sky-700
          "#5BBAC0", // Tailwind sky-800
          "#6FFFBA", // Tailwind sky-900
        ],
        borderWidth: 0, // Set border width to 0 to remove gaps
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  useEffect(() => {
    const payload = {
      page: 1,
      pageSize: 1000,
      sortColumn: "id",
      order: {
        id: "DESC",
      },
      condition: {},
      attributes: {},
    };
    if (!storesList) dispatch(getAllStores({ payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2 className="font-semibold text-xl text-center">Sales by Stores</h2>
      <div className="flex items-center justify-between mt-2">
        <DateRangePicker
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          initialSelectedDates={[startDate, endDate]}
          dateFormat="mmm dd"
        />
        <span className="flex justify-between gap-2">
          <Controller
            control={control}
            name="selectedStores"
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <MultiSelecCheckbox
                  options={storesList?.map((option) => ({
                    value: option?.id,
                    label: option?.branchName,
                  }))}
                  placeholder="Stores"
                  error={error}
                  value={value}
                  onChange={(val) => {
                    setValue("selectedStores", val, { shouldValidate: true });
                  }}
                />
              );
            }}
          />
        </span>
      </div>
      <div className="p-4 bg-white">
        <div className="flex flex-col items-center">
          <div className="w-48 h-48">
            <Pie data={data} options={options} />
          </div>
          <div className="mt-4 grid grid-cols-3 gap-1 w-full">
            {data?.labels?.map((label, index) => (
              <div key={label} className="flex flex-col space-x-0">
                <div className="flex items-center">
                  <div
                    className="w-3 h-3 rounded-full mr-2"
                    style={{
                      backgroundColor: data?.datasets[0].backgroundColor[index],
                    }}
                  />
                  <span className="text-sm font-medium text-text-secondary">
                    {label}
                  </span>
                </div>

                <span className="text-sm font-semibold pl-5">
                  {data.datasets[0].data[index]} SAR
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesByStores;
